import { useNavigate } from '../../../../../../src/hooks';
import { GridComponent, ResponseType } from '../../../../../store/slices';
import { apiFetch } from '../../../../../utils/fetchUtils';

type RedirectActionResponse = { url: string; type: ResponseType.Redirect };

export const useWarningDialog = () => {
    const { apiContextRoot, apiContextPath } = window.Props as Record<string, string>;
    const { navigate } = useNavigate();

    const dialogAction = async (
        actionName: string,
        component: GridComponent,
        data: Record<string, Record<string, string>> | string,
    ): Promise<ResponseType> => {
        const url = addPageParameters(
            `${apiContextRoot}${apiContextPath}/view/component/GridComponent/sourceAction?dataSource=${component.dataSource}&actionName=${actionName}`,
        );
        const response = await apiFetch<RedirectActionResponse>(url, data, undefined, {
            credentials: 'include',
            mode: 'cors',
        });
        if (response.type == ResponseType.Redirect) {
            if (response.url) {
                window.location.href = response.url;
            } else {
                navigate({ to: location.href });
                return response.type;
            }
        }
        return ResponseType.Error;
    };

    const addPageParameters = (url: string): string => {
        const queryParameters = new URLSearchParams(window.location.search);
        return `${url}&${queryParameters.toString()}`;
    };

    return {
        dialogAction,
    };
};
