import { factory } from '../common';
import { sleep } from '../common';
import { QueueItemProcess, QueueItemStatus } from './enums';
import { IQueueServiceInterface, IQueueItem, IQueueConfig, IServiceMethod } from './queueManager.interface';
import { myContainer } from './queueManager.module';

export { IQueueServiceInterface, IQueueConfig, IQueueItem, IServiceMethod, QueueItemStatus, QueueItemProcess };

const log = factory.getLogger('QueueManagerAPI');
log.debug('entering queueManagerAPI webpack');

export const getQueueManager = async (databaseName: string, secretKey: string): Promise<IQueueServiceInterface> => {
    log.debug('entering getQueueManager function');

    try {
        const queueContainer = myContainer.get<IQueueServiceInterface>('QueueManager');
        await queueContainer.dbPromise(databaseName, secretKey);
        await queueContainer.createCustomLeaderElection();
        return queueContainer;
    } catch (err) {
        log.error(`Error in creating queue Manager${String(err)}`);
        throw Error('Error in creating queue Manager');
    }
};

// TODO: revisit, queue manager must not rely on any timeouts
const MAGIC_DELAY = 6000;
export const getTestQueueManager = async (
    databaseName: string,
    secretKey: string,
    delay = MAGIC_DELAY,
): Promise<IQueueServiceInterface> => {
    const instance = await getQueueManager(databaseName, secretKey);
    await sleep(delay);
    return instance;
};
