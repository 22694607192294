import { CHMessagingScope } from '@wk/elm-uui-context-handler';
import * as H from 'history';
import { merge } from 'lodash';
import { UseFormMethods } from 'react-hook-form/dist/types/form';
import { trackPromise } from 'react-promise-tracker';
import { UUIHistory } from '../../../hooks';
import { IApplicationUrls, IAppResources } from '../../../reducers/types';
import { AppDispatch } from '../../../store';
import {
    markRefreshUUIForEntityAsProcessed,
    openItemScreenDialog,
    refreshUuiForEntity,
    removeLastItemScreenDialog,
    setPageState,
    showNotification,
    suppressNextRefresh,
} from '../../../store/slices';
import { apiFetch, ERROR_403_MESSAGE, ERROR_404_MESSAGE } from '../../../utils/fetchUtils';
import { determineRedirectUrl, getCLQuickFileVisibility, showConfirmationDialog } from '../../common/screenHelpers';
import { ScreenMode, ScreenRenderingStyle, UUIHistoryItemState } from '../../common/types';
import {
    handleClFileDownload,
    isContextLayerOperation,
    performItemScreenContextLayerOperation,
} from '../../contextLayerService/contextLayerHelpers';
import { clOpenDesktopLink, isOC } from '../../contextLayerService/contextLayerService';
import { messageBusDispatch } from '../../contextLayerService/messageBusService';
import { IContextLayerInfo, IParentItemInfo } from '../../listScreen/types';
import { BREADCRUMB_FIELD_NAME_PREFIX } from '../controlTypes/dropdownTreeControl';
import { IItemScreenProps } from '../itemScreen';
import { getItemScreenPropsFromUrl, META_FIELD_NAME_PREFIX } from '../itemScreenHelpers';
import {
    FieldValue,
    IErrorMessages,
    IErrorMessagesWithPathKeys,
    IItemScreenDataElement,
    IItemScreenInitialValues,
    IItemScreenJSON,
    IItemScreenOperation,
    IItemScreenState,
    IPerformOperationResponse,
    ItemScreenDispatch,
} from '../types';

// eslint-disable-next-line max-lines-per-function
export const dispatchInitializeItemScreen = (
    url: string,
    mode: ScreenMode,
    itemScreenDispatch: ItemScreenDispatch,
    dispatch: AppDispatch,
    isMounted: React.MutableRefObject<boolean>,
    parentItemInfo: IParentItemInfo | undefined,
    contextLayerInfo: IContextLayerInfo | undefined,
    renderingStyle: ScreenRenderingStyle,
    initialValues: IItemScreenInitialValues | undefined,
    itemScreenHistoryObject: UUIHistoryItemState | undefined,
    history: UUIHistory | undefined,
    v3HomeUrl: string | undefined,
    entityTypeId: string | undefined,
    entityInstanceId: string | undefined,
): void => {
    apiFetch<IItemScreenJSON>(url).then(handleInitialize).catch(handleError);

    function handleInitialize(itemScreenJson: IItemScreenJSON) {
        // bail if user has navigated away
        if (!isMounted.current) {
            return;
        }
        setInitialValues(itemScreenJson, initialValues);
        itemScreenDispatch({
            type: 'InitializeItemScreen',
            itemScreenJson,
            parentItemInfo,
            contextLayerInfo,
            mode,
            renderingStyle,
            viewState: itemScreenHistoryObject?.viewState,
        });
        initializeQuickFile(itemScreenJson, itemScreenDispatch, isMounted);
        if (!renderingStyle || renderingStyle === 'normal') {
            dispatch(setPageState({ title: itemScreenJson.metadata.screenDisplayName }));
        }
    } // handleInitialize

    function handleError(error: Error) {
        if (error.message.includes(ERROR_403_MESSAGE) && history && v3HomeUrl) {
            // redirect the user back home && close any item dialogs if present
            dispatch(removeLastItemScreenDialog());
            history.replace(v3HomeUrl);
        } else if (error.message.includes(ERROR_404_MESSAGE) && history) {
            // close any item dialogs if present
            dispatch(removeLastItemScreenDialog());

            if (isOC()) {
                // To refresh other instances in all stale cases and current instance in popup case
                messageBusDispatch({
                    type: 'RefreshUUIForEntity',
                    scope: renderingStyle === 'popup' ? CHMessagingScope.AllInstances : CHMessagingScope.OtherInstances,
                    message: {
                        isEntityDeleted: true,
                        entityTypeId: entityTypeId || '',
                        entityInstanceId: entityInstanceId,
                        parentItemInfo: parentItemInfo,
                    },
                });
            } else {
                location.reload();
            }
            // Go one step back in history from summary page
            if (renderingStyle === 'normal') {
                if (history.length > 1) {
                    history.goBack();
                }
            }
        }
    } // handleError
};

/**
 * @returns Promise with true or false ... true means operation was executed successfully with no validations.
 */
// eslint-disable-next-line max-lines-per-function
export const dispatchPerformOperation = async (
    operation: IItemScreenOperation,
    itemScreenState: IItemScreenState,
    dispatch: AppDispatch,
    applicationUrls: IApplicationUrls,
    appResources: IAppResources,
    isMounted: React.MutableRefObject<boolean>,
    formMethods: UseFormMethods,
    history: H.History,
): Promise<boolean> => {
    if (operation.confirmationMessage) {
        const shouldProceed = await showConfirmationDialog(dispatch, operation.confirmationMessage, appResources);
        if (!shouldProceed) {
            return Promise.resolve(false);
        }
    }

    if (operation.desktopLink && isOC()) {
        return new Promise<boolean>((resolve) => {
            clOpenDesktopLink(operation.desktopLink!).then(() => resolve(true));
        });
    }

    if (operation.targetScreenRenderingStyle?.toLowerCase() === 'popup' && !isContextLayerOperation(operation)) {
        dispatch(
            openItemScreenDialog({
                itemScreen: {
                    ...getItemScreenProps(operation, itemScreenState),
                    popupTitle: operation.targetScreenName,
                    operationContext: operation.operationContext,
                    entityTypeId: itemScreenState.itemScreenJson!.metadata.entityId.toString(),
                    onClosePopupInline: () => {
                        dispatch(removeLastItemScreenDialog());
                        dispatch(
                            refreshUuiForEntity({
                                refreshUUIForEntity: {
                                    entityTypeId: itemScreenState.itemScreenJson!.metadata.entityId.toString(),
                                    entityInstanceId: itemScreenState.itemScreenJson!.item.id!.toString(),
                                    parentItemInfo: itemScreenState.parentItemInfo,
                                },
                            }),
                        );
                    },
                },
            }),
        );
        return Promise.resolve(true);
    }

    const performOperationUrl = getPerformOperationUrl(applicationUrls, itemScreenState, operation);

    const redirectToTargetScreen = (currentScreenId: number) => {
        const redirectUrl = determineRedirectUrl({
            operation,
            applicationUrls,
            parentItemInfo: itemScreenState.parentItemInfo,
            entityInstanceId: itemScreenState.itemScreenJson!.item.id as unknown as number,
        });
        if (redirectUrl) {
            const itemScreenHistoryState = {
                itemScreen: {
                    viewState: {
                        activeTab: itemScreenState.activeTab,
                        visitedTabs: itemScreenState.visitedTabs,
                    },
                },
            };

            // if target screen equals current screen, do a history replace so that the back chevron behaves correctly
            if (currentScreenId === operation.targetScreenId) {
                history.replace(redirectUrl, itemScreenHistoryState);
            } else {
                history.push(redirectUrl, itemScreenHistoryState);
            }
        }
    };

    const valueHasChanged = (fieldName: string, currentFieldValue: string) => {
        if (itemScreenState.mode === 'add') {
            return true;
        }
        if (isMoneyCurrencyField(fieldName)) {
            return getFieldByFieldName(getMoneyFieldName(fieldName)).currency !== currentFieldValue;
        } else if (isMoneyAmountField(fieldName)) {
            return getFieldByFieldName(getMoneyFieldName(fieldName)).inputValue !== currentFieldValue;
        } else if (isDateOrDateTimeField(fieldName, itemScreenState)) {
            return getFieldByFieldName(fieldName).displayValue !== currentFieldValue;
        } else {
            return getFieldByFieldName(fieldName).inputValue !== currentFieldValue;
        }
    };

    const createOperationPostBody = () => {
        const fieldsToSubmit: Record<string, FieldValue> = {};
        // get the current field values from the form
        const currentFieldValues = formMethods.getValues();
        // loop over all fields that have changed since load and craft POST json object
        Object.keys(currentFieldValues).forEach((fieldName: string) => {
            if (fieldName.indexOf(META_FIELD_NAME_PREFIX) === 0) {
                return;
            }
            if (!valueHasChanged(fieldName, currentFieldValues[fieldName].toString())) {
                return;
            }

            if (isMoneyField(fieldName)) {
                fieldsToSubmit[getMoneyFieldName(fieldName)] = {
                    value: getMoneyAmountValue(fieldName, currentFieldValues),
                    currency: getMoneyCurrencyValue(fieldName, currentFieldValues),
                }; // ex: { invoiceNumber: { value: 'inv-1' }, total: { value: '200' , currency:'USD'}
            } else if (isMultiSelectField(fieldName, itemScreenState)) {
                // if fieldName include '[]' in the json ex: { name:grants[] }
                if (isFieldNameHasArray(fieldName, itemScreenState)) {
                    fieldsToSubmit[fieldName + '[]'] = { value: currentFieldValues[fieldName] };
                } else {
                    fieldsToSubmit[fieldName] = { value: currentFieldValues[fieldName] };
                }
            } else {
                fieldsToSubmit[fieldName] = { value: currentFieldValues[fieldName] }; // ex: { matterName: { value: 'Cooper' }, matterNumber: { value: 'MAT123' }}
            }
        });
        return fieldsToSubmit;
    };

    /**
     * handles the validation messages
     * @param errorsAndWarnings
     * @returns true if there are validation messages, or false if there are no errors/warnings.
     */
    const handleItemScreenValidations = (
        errorsAndWarnings: IPerformOperationResponse,
        operation: IItemScreenOperation,
    ): boolean => {
        const hasValidationErrors = handleValidationErrorsAndWarnings(
            itemScreenState,
            formMethods,
            errorsAndWarnings,
            appResources,
        );
        if (!hasValidationErrors) {
            formMethods.reset();
            if (errorsAndWarnings.successMessage) {
                const message = errorsAndWarnings.successMessage;
                dispatch(
                    showNotification({
                        notification: {
                            message,
                            options: {
                                variant: 'success',
                                persist: false,
                            },
                        },
                    }),
                );
            }

            if (!operation.noOp) {
                messageBusDispatch({
                    type: 'RefreshUUIForEntity',
                    scope: CHMessagingScope.OtherInstances,
                    message: {
                        isEntityDeleted: operation.isBrowserBackOnComplete,
                        entityTypeId: itemScreenState.itemScreenJson!.metadata.entityId.toString(),
                        entityInstanceId:
                            // only send entityInstanceId for edits
                            itemScreenState.mode !== 'add'
                                ? itemScreenState.itemScreenJson?.item.id?.toString()
                                : undefined,
                        parentItemInfo:
                            itemScreenState.parentItemInfo !== undefined ? itemScreenState.parentItemInfo : undefined,
                    },
                });
            }

            // if the operation is a delete, go back to the parent record itemscreen
            if (operation.isBrowserBackOnComplete === true) {
                if (history.length > 1) {
                    history.goBack();
                }
                return false;
            }
            redirectToTargetScreen(itemScreenState.itemScreenJson!.metadata.screenId);
            return false;
        }
        return true;
    };

    const getFieldByFieldName = (fieldName: string): IItemScreenDataElement =>
        (itemScreenState.itemScreenJson!.item[fieldName] ||
            itemScreenState.itemScreenJson!.item[fieldName + '[]']) as IItemScreenDataElement;

    const getBreadCrumbsFromFolderPicker = (): string | undefined => {
        const currentFieldValues = formMethods.getValues();
        let breadcrumbs: string | undefined;
        Object.keys(currentFieldValues).forEach((fieldName: string) => {
            if (fieldName.indexOf(BREADCRUMB_FIELD_NAME_PREFIX) >= 0) {
                breadcrumbs = currentFieldValues[fieldName].toString();
            }
        });
        return breadcrumbs;
    };

    formMethods.clearErrors();
    // eslint-disable-next-line max-lines-per-function
    return new Promise<boolean>((resolve) => {
        if (isContextLayerOperation(operation)) {
            if (operation.isBrowserBackOnComplete) {
                dispatch(suppressNextRefresh());
            }
            void trackPromise(
                performItemScreenContextLayerOperation(
                    operation,
                    itemScreenState,
                    appResources.username,
                    createOperationPostBody(),
                    getBreadCrumbsFromFolderPicker(),
                ),
            ).then((response) => {
                const responseObj = response;
                // @ts-ignore
                if (responseObj && responseObj.response?.statusInfo) {
                    // @ts-ignore
                    const statusInfo: IPerformOperationResponse = responseObj.response.statusInfo;
                    const hasValidations = handleItemScreenValidations(statusInfo, operation);
                    resolve(!hasValidations);
                } else {
                    const isOperationSuccessful =
                        !response || (response as Response).ok === undefined || (response as Response).ok;
                    if (operation.isBrowserBackOnComplete) {
                        if (isOperationSuccessful) {
                            window.history.back();
                        } else {
                            dispatch(markRefreshUUIForEntityAsProcessed());
                        }
                        resolve(isOperationSuccessful);
                        return;
                    }
                    if (operation.type == 'File') {
                        handleClFileDownload(response as Response);
                        formMethods.reset();
                    } else {
                        redirectToTargetScreen(itemScreenState.itemScreenJson!.metadata.screenId);
                    }
                    resolve(isOperationSuccessful);
                }
            });
        } else {
            apiFetch<IPerformOperationResponse>(performOperationUrl, createOperationPostBody())
                .then((errorsAndWarnings) => {
                    if (!isMounted.current) {
                        return;
                    }
                    const hasValidations = handleItemScreenValidations(errorsAndWarnings, operation);
                    resolve(!hasValidations);
                })
                .catch((error) => {
                    if (error.message.includes(ERROR_404_MESSAGE)) {
                        if (operation.isBrowserBackOnComplete) {
                            window.history.back();
                        }
                        if (isOC()) {
                            // To refresh other instances in stale cases
                            messageBusDispatch({
                                type: 'RefreshUUIForEntity',
                                scope: CHMessagingScope.OtherInstances,
                                message: {
                                    isEntityDeleted: operation.isBrowserBackOnComplete,
                                    entityTypeId: itemScreenState.itemScreenJson!.metadata.entityId.toString(),
                                    entityInstanceId:
                                        // only send entityInstanceId for edits
                                        itemScreenState.mode !== 'add'
                                            ? itemScreenState.itemScreenJson?.item.id?.toString()
                                            : undefined,
                                    parentItemInfo:
                                        itemScreenState.parentItemInfo !== undefined
                                            ? itemScreenState.parentItemInfo
                                            : undefined,
                                },
                            });
                        }
                    }
                });
        }
    });
};

// private functions
const getItemScreenProps = (operation: IItemScreenOperation, itemScreenState: IItemScreenState): IItemScreenProps =>
    operation.linkUrl
        ? { ...getItemScreenPropsFromUrl(operation.linkUrl) }
        : {
              screenId: operation.targetScreenId!,
              mode: operation.targetScreenMode!.toLowerCase() as ScreenMode,
              parentItemInfo: itemScreenState.parentItemInfo,
              entityInstanceId: itemScreenState.itemScreenJson!.item.id as unknown as number,
          };

const isMoneyField = (fieldName: string) => {
    return isMoneyCurrencyField(fieldName) || isMoneyAmountField(fieldName);
};

const isMoneyCurrencyField = (fieldName: string) => fieldName.indexOf('_currency') !== -1;

const isMoneyAmountField = (fieldName: string) => fieldName.indexOf('_amount') !== -1;

const getMoneyFieldName = (fieldName: string) => fieldName.split('_')[0];

const getMoneyAmountValue = (
    fieldName: string,
    currentFieldValues: {
        [x: string]: any;
    },
) => {
    return currentFieldValues[`${getMoneyFieldName(fieldName)}_amount`];
};

const getMoneyCurrencyValue = (
    fieldName: string,
    currentFieldValues: {
        [x: string]: any;
    },
) => {
    return currentFieldValues[`${getMoneyFieldName(fieldName)}_currency`];
};

const isDateOrDateTimeField = (fieldName: string, itemScreenState: IItemScreenState) => {
    const fieldControlType = itemScreenState.itemScreenJson?.metadata.fields.find(
        (f) => f.name === fieldName,
    )?.controlType;
    return ['Date Picker', 'Date/Time Picker'].includes(fieldControlType!);
};

const isMultiSelectField = (fieldName: string, itemScreenState: IItemScreenState) => {
    const fieldControlType = itemScreenState.itemScreenJson?.metadata.fields.find(
        (f) => f.name === fieldName || f.name === fieldName + '[]',
    )?.controlType;
    return ['Multi-Autocomplete'].includes(fieldControlType!);
};

const isFieldNameHasArray = (fieldName: string, itemScreenState: IItemScreenState) => {
    const autoCompletefieldName = itemScreenState.itemScreenJson?.metadata.fields.find(
        (f) => f.name === fieldName || f.name === fieldName + '[]',
    )?.name;
    return autoCompletefieldName?.includes('[]');
};

const handleValidationErrorsAndWarnings = (
    itemScreenState: IItemScreenState,
    formMethods: UseFormMethods,
    errorsAndWarnings: IPerformOperationResponse,
    appResources: IAppResources,
): boolean => {
    if (!errorsAndWarnings.errors && !errorsAndWarnings.warnings) {
        return false;
    }
    const fieldErrors = getErrorsOrWarningsCollection(itemScreenState, errorsAndWarnings.errors, false, appResources);
    const fieldWarnings = getErrorsOrWarningsCollection(
        itemScreenState,
        errorsAndWarnings.warnings,
        true,
        appResources,
    );
    // get the unique keys (fieldNames) across both errors and warnings so we can iterate over it
    const uniqueFieldNameKeys = Object.keys({ ...fieldErrors, ...fieldWarnings });
    uniqueFieldNameKeys.map((fieldName) => {
        formMethods.setError(fieldName, {
            types: { errors: fieldErrors[fieldName] || [], warnings: fieldWarnings[fieldName] || [] },
        });
    });
    return true;
};

const getErrorsOrWarningsCollection = (
    itemScreenState: IItemScreenState,
    errorsOrWarnings: IErrorMessages | undefined,
    checkWarnings: boolean,
    appResources: IAppResources,
): IErrorMessagesWithPathKeys => {
    const fieldErrors: IErrorMessagesWithPathKeys = {};
    if (!errorsOrWarnings) {
        return fieldErrors;
    }
    Object.values(errorsOrWarnings).map((errorOrWarning) => {
        // errors and warnings contains the field path, not the field name, so looking up the name by the path here.
        // if the field path is blank, then it's a form level error, so just let it through.
        let fieldPath = errorOrWarning[0];
        let fieldName = errorOrWarning[0];
        if (fieldPath !== '') {
            if (fieldPath.endsWith('.amount')) {
                // this is a money field ... chop off .amount
                fieldPath = fieldPath.substring(0, fieldPath.length - 7);
            }
            const field = itemScreenState.itemScreenJson!.metadata.fields.find((f) => f.path === fieldPath)!;

            if (!field) {
                // Non-visible field, so make error/warning appear as a form level error/warning.
                const msgPrefixType = checkWarnings ? 'Warning' : 'Error';
                const msgPrefix = appResources.validationMsgPrefixOnNonDisplayedField
                    .replace('{0}', msgPrefixType)
                    .replace('{1}', fieldPath);

                errorOrWarning[1] = msgPrefix + ' - ' + errorOrWarning[1];
                fieldName = '';
            } else {
                fieldName = field.name;
                // strip off [] ... this is a workaround for Passport since we cannot remove [] on the server and react hook form
                // will treat fieldNames ending with [] as array fields which is bad
                if (fieldName.endsWith('[]')) {
                    // multi auto complete
                    fieldName = fieldName.substring(0, fieldName.length - 2);
                }
                if (field.controlType === 'Money') {
                    fieldName = fieldName + '_amount';
                }
            }
        }
        if (fieldErrors[fieldName]) {
            fieldErrors[fieldName].push(errorOrWarning[1]);
        } else {
            fieldErrors[fieldName] = [errorOrWarning[1]];
        }
    });
    return fieldErrors;
};

const getPerformOperationUrl = (
    applicationUrls: IApplicationUrls,
    itemScreenState: IItemScreenState,
    operation: IItemScreenOperation,
): string => {
    const apiPath = Props['apiContextRoot'] + Props['apiContextPath'];
    const itemScreenJson = itemScreenState.itemScreenJson!;
    let screenURL =
        apiPath +
        applicationUrls.performItemScreenOperationPath
            .replace('{screenId}', itemScreenJson.metadata.screenId.toString())
            .replace('{entityInstanceId}', itemScreenJson.item.id ? itemScreenJson.item.id.toString() : '')
            .replace('{operationId}', operation.id.toString())
            .replace('{mode}', itemScreenState.mode || '')
            .replace('{parentInstanceId}', itemScreenState.parentItemInfo?.parentInstanceId?.toString() || '')
            .replace('{parentEntityId}', itemScreenState.parentItemInfo?.parentEntityId?.toString() || '')
            .replace('{parentFieldName}', itemScreenState.parentItemInfo?.parentFieldName || '');
    if (operation.operationContext) {
        screenURL += '&operationContext=' + operation.operationContext;
    }
    return screenURL;
};

const setInitialValues = (itemScreenJson: IItemScreenJSON, initialValues?: IItemScreenInitialValues) => {
    if (initialValues) {
        merge(itemScreenJson.item, initialValues);
    }
};

const initializeQuickFile = (
    itemScreenJson: IItemScreenJSON,
    itemScreenDispatch: ItemScreenDispatch,
    isMounted: React.MutableRefObject<boolean>,
) => {
    getCLQuickFileVisibility(
        false,
        itemScreenJson.metadata?.enableQFDocuments || false,
        itemScreenJson.metadata?.enableQFEmails || false,
    )?.then((qfInd) => {
        if (!isMounted.current) {
            return;
        }
        itemScreenDispatch({
            type: 'InitializeQF',
            enableQFIcon: qfInd?.enableQuickFile || false,
            docTypeForQF: qfInd?.entityType,
        });
    });
};
