import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clPackage from '@wk/elm-uui-context-handler/package.json';
import docComponentPackage from '@wk/elm-uui-doc-component/package.json';
import React, { useEffect, useState } from 'react';
import clientPackage from '../../../../../package.json';
import { AppTheme } from '../../../../components/app';
import { FormTextControl } from '../../../../components/common/formTextControl';
import { PageHeader } from '../../../../components/common/pageHeader/pageHeader';
import { PageFooter } from '../../../../components/core/pageFooter';
import { useAppSelector } from '../../../../store';
import { getAppResources } from '../../../../store/slices';
import { config } from '../../config';
import { getUserManager } from '../../infrastructure/oidc';

interface AboutData {
    t360version?: string;
    networkName?: string;
    loginName?: string;
    date?: string;
}

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        ...theme.styles.page.root,
    },
    header: {
        ...theme.styles.page.header,
    },
    content: {
        ...theme.styles.page.content,
        flexDirection: 'column',
    },
}));

export const About = () => {
    const classes = useStyles();
    const [data, setData] = useState<AboutData>({});
    const { date = '', loginName = '', networkName = '', t360version = '' } = data;
    const { appName, helpPageAbout } = useAppSelector(getAppResources);
    const title = helpPageAbout.replace('{appName}', appName);

    useEffect(() => {
        const initUserInfo = async () => {
            const userInfo = await getUserManager().getUser();
            const url = `${config.get('REACT_APP_API_URL')}/api/v4/views/helpservice/about`;
            const res = await fetch(url, {
                method: 'GET',
                headers: { Authorization: `Bearer ${String(userInfo?.access_token)}` },
            });

            const { AboutInfo: info } = (await res.json()) as { AboutInfo: AboutData };
            setData(info);
        };

        void initUserInfo();
    }, []);

    return (
        <>
            <div className={classes.root}>
                <PageHeader classes={{ root: classes.header }} title={title} />
                <div className={classes.content}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormTextControl title="T360 Version" content={t360version} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormTextControl title="UI Version" content={clientPackage.version} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormTextControl title="CL Version" content={clPackage.version} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormTextControl title="DC Version" content={docComponentPackage.version} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormTextControl title="Network Name" content={networkName} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormTextControl title="Login Name" content={loginName} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextControl title="Date" content={date} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextControl title="URL" content={config.get('REACT_APP_UI_URL')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextControl title="User Agent" content={window.navigator.userAgent} />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <PageFooter />
        </>
    );
};
