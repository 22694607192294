import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { VFC, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLogOut } from '../../../hooks';
import { useAppSelector } from '../../../store';
import WkWarningIcon from '../../icons/wkWarningIcon';
import { AppTheme } from '../appTheme';
import { useRemainingTime } from './hooks';

const useStyles = makeStyles<AppTheme>((theme) => {
    return {
        header: {
            backgroundColor: theme.palette.warning.dark,
            padding: theme.spacing(1.5, 2),
        },
        dialogTitle: {
            fontWeight: 500,
            color: theme.palette.text.secondary,
        },
        dialogMessage: {
            padding: theme.spacing(2, 1.5, 2, 2),
            display: 'inline-flex',
        },
        dialogContentText: {
            fontSize: theme.typography.subtitle1.fontSize,
            color: theme.palette.grey[900],
            whiteSpace: 'pre-line',
            marginBottom: 0,
        },
        dialogPaper: {
            border: `1px solid ${theme.palette.warning.dark}`,
        },
        dialogActions: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.secondary,
        },
        warningIcon: {
            fontSize: 28,
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            color: theme.palette.warning.dark,
        },

        dialogContents: {
            display: 'inline-flex',
        },

        containedButton: {
            marginLeft: theme.spacing(2),
        },
    };
});

export const InactivityAlertDialog: VFC = () => {
    const { inactivityWarningTimeout, sessionTimeout } = useAppSelector(
        (state) => state.app.hybridSettings.inactivitySettings,
    );
    const promptTimeout = inactivityWarningTimeout * 60 * 1000;
    const timeout = (sessionTimeout - inactivityWarningTimeout) * 60 * 1000;
    const classes = useStyles();
    const logout = useLogOut();
    const [timeoutDialogOpen, setTimeoutDialogOpen] = useState(false);

    const onIdle = (): void => {
        setTimeoutDialogOpen(false);
        handleLogout();
    };

    const onPrompt = (): void => {
        setTimeoutDialogOpen(true);
    };

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { reset, getRemainingTime } = useIdleTimer({
        timeout,
        promptTimeout,
        onIdle,
        onPrompt,
        crossTab: true,
        syncTimers: 500,
    });

    const handleLogout = (): void => {
        setTimeoutDialogOpen(false);
        logout();
    };

    const staySignedIn = (): void => {
        setTimeoutDialogOpen(false);
        reset();
    };

    const handleContinue = staySignedIn;

    const handleClose = staySignedIn;

    const { remainingTime } = useRemainingTime(getRemainingTime, 1000);

    const formatSeconds = (secs: number): string => {
        const pad = (n: number, t: string, show: boolean): string => {
            const numericPart = n < 10 ? `0${n}` : n;
            return show ? `${numericPart}${t}` : '';
        };

        const h = Math.floor(secs / 3600);
        const m = Math.floor(secs / 60) - h * 60;
        const s = Math.floor(secs - h * 3600 - m * 60);

        return `${pad(h, 'h ', Boolean(h))}${pad(m, 'm ', Boolean(h) || Boolean(m))}${pad(s, 's', true)}`;
    };

    const inactivityTimer = formatSeconds(remainingTime / 1000);

    const message = `You will be logged out in <b>${inactivityTimer}</b> due to inactivity.

                     Choose from the options below to stay logged in or log out immediately.`;

    return (
        <Dialog open={timeoutDialogOpen} onClose={handleClose} maxWidth="xs" classes={{ paper: classes.dialogPaper }}>
            <DialogTitle classes={{ root: classes.header }}>
                <Typography variant="h5" classes={{ root: classes.dialogTitle }}>
                    Session Timeout
                </Typography>
            </DialogTitle>
            <div className={classes.dialogContents}>
                <WkWarningIcon classes={{ root: classes.warningIcon }} />
                <DialogContent classes={{ root: classes.dialogMessage }}>
                    <DialogContentText
                        classes={{ root: classes.dialogContentText }}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                </DialogContent>
            </div>
            <DialogActions disableSpacing classes={{ root: classes.dialogActions }}>
                <Button disableRipple variant="outlined" color="primary" onClick={handleLogout}>
                    Log Out
                </Button>

                <Button
                    disableRipple
                    color="primary"
                    variant="contained"
                    onClick={handleContinue}
                    classes={{ root: classes.containedButton }}>
                    Stay Logged In
                </Button>
            </DialogActions>
        </Dialog>
    );
};
