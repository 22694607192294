import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkPdfIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkPdfIcon" viewBox="0 0 16 16" {...props}>
        <g data-name="Group 3035" transform="translate(-1862 -1019)">
            <rect
                data-name="Rectangle 1065"
                width="16"
                height="16"
                rx="1"
                transform="translate(1862 1019)"
                fill="#ff2116"
            />
            <path
                d="M1865.229 1031.699c-.587-.587.048-1.393 1.771-2.249l1.084-.539.422-.924a35.89 35.89 0 00.77-1.843l.348-.919-.24-.68a4.858 4.858 0 01-.213-2.544c.253-.612 1.084-.549 1.412.107a4.144 4.144 0 01-.074 2.61l-.249.959.22.373a12.948 12.948 0 00.784 1.082l.584.726.726-.095c2.308-.3 3.1.211 3.1.944 0 .926-1.812 1-3.334-.066a4.617 4.617 0 01-.577-.479s-.953.194-1.423.321c-.484.131-.726.212-1.436.452 0 0-.249.362-.411.624a6.724 6.724 0 01-1.812 2.083 1.235 1.235 0 01-1.452.057zm.921-.329a6.944 6.944 0 001.459-1.726l.187-.3-.852.428c-1.316.662-1.918 1.285-1.6 1.662.176.212.386.194.81-.068zm8.547-2.4a.5.5 0 00-.089-.865 2.4 2.4 0 00-1.25-.161 16 16 0 00-1.305.15s.4.277.578.378a7.681 7.681 0 001.231.514c.414.127.653.113.835-.016zm-3.436-1.428a8.434 8.434 0 01-.738-.952 5.306 5.306 0 01-.413-.616s-.2.647-.366 1.037l-.515 1.273-.149.289s.794-.26 1.2-.366c.428-.112 1.3-.283 1.3-.283zm-1.107-4.441a1.865 1.865 0 00-.063-1.046c-.372-.407-.822-.068-.746.9a6.6 6.6 0 00.215 1.225l.2.624.138-.47a12.33 12.33 0 00.259-1.234z"
                fill="#fff"
                stroke="#fff"
                strokeWidth=".5"
            />
        </g>
    </SvgIcon>
);

export default WkPdfIcon;
