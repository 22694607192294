import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';

export const WkExcel = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon data-testid="wkExcel" viewBox="-3 -3 21 21" {...props} ref={ref}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 14.3333L9.06667 16V0L0 1.66667V14.3333ZM1.06667 2.53333L8 1.26667V14.7333L1.06667 13.4667V2.53333ZM16 2.13333V13.8667H10.1333V12.8H15.0667V3.2H10.1333V2.13333H16ZM13.8667 11.7333H10.1333V10.6667H13.8667V11.7333ZM13.8667 9.6H10.1333V8.53333H13.8667V9.6ZM13.8667 7.46667H10.1333V6.4H13.8667V7.46667ZM13.8667 5.33333H10.1333V4.26667H13.8667V5.33333ZM7.2 4.8L5.2 8L7.2 11.2H5.86667L4.53333 9.06667L3.2 11.2H1.86667L3.86667 8L1.86667 4.8H3.2L4.53333 6.93333L5.86667 4.8H7.2Z"
            fill="currentColor"
        />
    </SvgIcon>
));

WkExcel.displayName = 'WKExcel';
