import { SvgIconProps } from '@mui/material';
import React from 'react';

const WkZoomOutIcon: React.FC<SvgIconProps> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
        <g transform="translate(-1180.5 -361)">
            <line
                style={{ stroke: '#005b92', strokeWidth: '2px', fill: 'none' }}
                x2="16"
                transform="translate(1180.5 369.5)"
            />
            <rect style={{ fill: 'none' }} width="16" height="16" transform="translate(1181 361)" />
        </g>
    </svg>
);

export default WkZoomOutIcon;
