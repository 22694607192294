import { apiFetch } from '../../../../../utils/fetchUtils';

export const useModifyAdjustmenthook = () => {
    const { apiContextRoot, apiContextPath } = window.Props;
    const apiBaseUrl = `${String(apiContextRoot)}${String(apiContextPath).replace("views", "")}`;
    const params = new URLSearchParams(window.location.search)
    const invoiceId = params.get('matter_invoice_id');


    const modifyData = async (lineItemId: string, actionParameters: any): Promise<any> => {
        const url = `${apiBaseUrl}invoices/${invoiceId}/lineitems/${lineItemId}/modify`;

        const amount = {
            Amount: actionParameters.AdjustMentAmount,
            CurrencyCode: 'USD'
        };

        const data = {
            BillCodeGroupBillCodeId: actionParameters.BillCodeGroupBillCodeId,
            Narrative: actionParameters.Narrative,
            Amount: amount,
            IsNegative: actionParameters.IsNegative,
        };
        return await apiFetch<any>(url, data);
    };

    const getModifyMetaData = async (lineItemId: string) => {
        const url = `${apiBaseUrl}action/${invoiceId}/getModifyMetaData/${lineItemId}`;
        const response = await apiFetch(url);
        return response;
    };

    return { modifyData, getModifyMetaData };
};