import React, { useEffect } from 'react';
import { manuallyDecrementPromiseCounter } from 'react-promise-tracker';
import useIsMounted from '../../../hooks/useIsMounted';
import { useAppSelector } from '../../../store';
import { getApplicationUrls } from '../../../store/slices';
import TransferBasket from '../../transferBasket/transferBasket';
import { ITransferBasketItem } from '../../transferBasket/types';
import { useListScreenState } from '../context/listScreenContext';
import { dispatchInitializeColumnPicker, dispatchToggleColumnPickerTreeNode } from './context/columnPickerReducer';
import { useListScreenEditDialogDispatch, useListScreenEditDialogState } from './context/listScreenEditDialogContext';
import { IColumnPickerState } from './types';

const ColumnPicker: React.FC = () => {
    const columnPickerState = useListScreenEditDialogState((s) => s.columnPicker) as IColumnPickerState;
    const listScreenState = useListScreenState();
    const metadata = listScreenState.metadata!;
    const dispatch = useListScreenEditDialogDispatch();
    const isMounted = useIsMounted();

    const applicationUrls = useAppSelector(getApplicationUrls);
    const childAttributesURL = `${String(Props['apiContextRoot'])}${String(Props['apiContextPath'])}${
        applicationUrls.listScreenChildAttributesPath
    }`;

    useEffect(() => {
        if (listScreenState.listScreenEditDialogOpen) {
            dispatchInitializeColumnPicker(dispatch, metadata, isMounted, childAttributesURL);
        }
        return () => {
            manuallyDecrementPromiseCounter();
        };
    }, [childAttributesURL, dispatch, isMounted, listScreenState.listScreenEditDialogOpen, metadata]);

    const onToggleTreeNode = (treeItemClicked: ITransferBasketItem, expanded: boolean) => {
        if (listScreenState.listScreenEditDialogOpen) {
            dispatchToggleColumnPickerTreeNode(
                treeItemClicked,
                expanded,
                columnPickerState,
                metadata,
                dispatch,
                isMounted,
                childAttributesURL,
            );
        }
    };

    return (
        <TransferBasket
            transferBasketItems={columnPickerState.transferBasketItems}
            onToggleTreeNode={onToggleTreeNode}
            saveTransferBasketItems={(transferBasketItems) =>
                dispatch({ type: 'SaveColumnPickerItems', transferBasketItems })
            }
        />
    );
};

export default ColumnPicker;
