import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';

export const WkTaskPersonal: React.FC<SvgIconProps> = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon data-testid="wkTaskPersonal" fill="none" viewBox="0 0 16 17" {...props} ref={ref}>
        <path d="M5.62695 6.4646V5.38184H11.2149V6.4646H5.62695Z" fill="#353535" />
        <path
            d="M3.33729 13.7946C2.71135 13.7892 2.22802 13.2915 2.23644 12.6614C2.24443 12.0506 2.74501 11.5601 3.35706 11.5635C3.97626 11.5669 4.476 12.0708 4.47474 12.6904C4.47347 13.3025 3.96154 13.7997 3.33729 13.7946Z"
            fill="currentColor"
        />
        <path
            d="M3.35969 10.42C2.73334 10.4221 2.24496 9.93707 2.23739 9.30609C2.22982 8.69025 2.7346 8.18631 3.35759 8.18799C3.96586 8.18925 4.4698 8.68731 4.47485 9.29263C4.48032 9.90721 3.97469 10.4183 3.35927 10.4204L3.35969 10.42Z"
            fill="currentColor"
        />
        <path
            d="M4.47473 5.92645C4.47263 6.53724 3.95102 7.05591 3.34612 7.04749C2.72481 7.03908 2.22802 6.53177 2.23643 5.91467C2.24485 5.30094 2.73996 4.80667 3.34906 4.80372C3.96112 4.80078 4.47642 5.31524 4.47431 5.92645H4.47473Z"
            fill="currentColor"
        />
        <path
            d="M7.90325 14.9216H1.10464V2.55566H2.2282V3.64642H5.0264V2.56029H8.43033V3.64978H11.2222V2.56197H12.3576V7.9699C12.753 8.04982 13.1337 8.21766 13.4635 8.47468V1.42326H11.2256V0.308105H8.4257V1.41947H5.02766V0.311891H2.22863V1.43924H0V16.0401H7.76401C7.73962 15.7305 7.77916 15.3398 7.90325 14.9212V14.9216ZM9.53202 1.42999H10.1045V2.55903C9.94931 2.55903 9.79199 2.56702 9.63677 2.55356C9.59891 2.55019 9.53749 2.47868 9.53665 2.43704C9.52824 2.11061 9.53202 1.78376 9.53202 1.43041V1.42999ZM3.35724 1.42873H3.91587V2.54809H3.35724V1.42873Z"
            fill="currentColor"
        />
        <path
            d="M5.62305 8.7666V9.85315H9.29536C9.40431 9.44217 9.60496 9.06653 9.86997 8.7666H5.62305Z"
            fill="currentColor"
        />
        <path
            d="M5.4248 12.1362V13.2228H8.42575C8.42575 13.2228 8.64365 12.8905 8.79803 12.7205C8.93516 12.5691 9.36044 12.1362 9.36044 12.1362H5.4248Z"
            fill="currentColor"
        />
        <path
            d="M10.3895 12.6265C9.23357 11.4365 9.56715 9.75553 10.4829 8.95376C11.4117 8.14106 12.8634 8.14484 13.8023 8.97395C14.6882 9.75637 15.0293 11.4773 13.8721 12.6223C15.2249 13.3652 15.9359 14.503 16.0006 16.0611H8.29046C8.20928 15.158 8.71532 13.4905 10.3899 12.6261L10.3895 12.6265ZM15.4008 15.5185C15.4046 15.4958 15.41 15.4844 15.4079 15.4743C15.1618 14.3478 14.5157 13.5393 13.4712 13.0543C13.4023 13.0223 13.2899 13.0366 13.2159 13.0694C12.4983 13.3875 11.7815 13.3992 11.068 13.0694C10.9498 13.0148 10.8577 13.0143 10.7454 13.0673C9.96256 13.4371 9.39973 14.0218 9.05647 14.8122C8.96056 15.0326 8.9063 15.2716 8.82764 15.5185H15.4012H15.4008ZM10.1897 10.8109C10.1826 11.871 11.0382 12.7586 12.0759 12.7666C13.178 12.7754 14.0597 11.9362 14.0686 10.8703C14.0778 9.76394 13.2357 8.89108 12.1512 8.88309C11.0676 8.8751 10.1973 9.73029 10.1897 10.8105V10.8109Z"
            fill="currentColor"
        />
    </SvgIcon>
));

WkTaskPersonal.displayName = 'WkTaskPersonal';
