export const SEARCH_CHART_WORD = 'chart';
export const CHART_ADJUST = 2;

export const MESSAGES = {
    NO_ACTIVE_PAGE: 'No active Power BI page available for the specified report. Ensure a valid page is active.',
    NO_VISUALS: 'No visuals found in the report. Verify that the report contains visuals.',
    NO_CHARTS: 'No charts found in the Power BI report. Ensure the report has charts to display.',
    NO_MATCHING_VISUAL: `No visual matching "${SEARCH_CHART_WORD}" found in the active page.`,
    NO_FILTER_DATA: 'There is no PowerBi Filter data available.',
};
