import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { ClickAwayListener, Fade, InputAdornment, Paper, Popper } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { bindToggle, bindPopper } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { UUIInput } from '../../common/uuiInput';
import { getValidationMessagesForField, META_FIELD_NAME_PREFIX } from '../itemScreenHelpers';
import { IFolder, IItemScreenDataElement, IItemScreenField } from '../types';
import { ControlLabel } from './controlLabel';
import css from './dropdownTreeControl.module.scss';
import ReadOnlyControl from './readOnlyControl';

interface IDropdownTreeControl {
    field: IItemScreenField;
    fieldData: IItemScreenDataElement;
    readOnly: boolean;
}

export const BREADCRUMB_FIELD_NAME_PREFIX = META_FIELD_NAME_PREFIX + 'breadcrumbs__';

export const DropdownTreeControl: React.FC<IDropdownTreeControl> = ({ field, fieldData, readOnly }) => {
    const initialValueId = fieldData.inputValue;
    const { register, setValue, errors } = useFormContext();
    const inputRef = useRef<HTMLInputElement>(null);
    const treeViewRef = useRef<HTMLDivElement>(null);
    const [treeValues] = useState<IFolder | undefined>(field.folderOptions);
    // this is so the component gets rerendered when the viewport size changes to recalculate the width of the dropdown
    useWindowWidth(100);
    const [selectedFolder, setSelectedFolder] = useState<IFolder>({
        name: '',
        id: '',
        children: [],
    });
    const BREADCRUMB_FIELD_NAME = BREADCRUMB_FIELD_NAME_PREFIX + field.name;
    const dropDownTreeControlPopper = usePopupState({
        variant: 'popper',
        popupId: `dropdown-tree-control-${field.name}`,
    });

    const getSelectedFolder = (keyToFind: string, valToFind: string | undefined): IFolder | undefined => {
        let selectedFolder: IFolder | undefined;
        treeValues &&
            JSON.stringify(treeValues, (_, nestedValue) => {
                if (nestedValue) {
                    if (nestedValue[keyToFind] === valToFind) {
                        selectedFolder = nestedValue;
                    }
                }
                return nestedValue;
            });
        return selectedFolder || treeValues;
    };

    const defaultSelected = getSelectedFolder('id', initialValueId);
    const [tempSelectedFolder, setTempSelectedFolder] = useState<string[]>(
        defaultSelected?.id ? [defaultSelected.id] : [],
    );

    const [expandedList, setExpandedList] = useState<string[]>(() => {
        const list: string[] = [];
        if (readOnly || !treeValues) {
            return list;
        }
        const getHasChildrenList = (tv: IFolder) => {
            if (tv.children && tv.children?.length > 0) {
                list.push(tv.id);
                tv.children.map(getHasChildrenList);
            }
        };
        getHasChildrenList(treeValues);
        return list;
    });

    // scroll the selected item into view
    const scrollSelectedFolderIntoView = () => {
        const folderToFocus = selectedFolder.id || defaultSelected?.id;
        if (folderToFocus) {
            const selectedElem = document.getElementById(`treeItemId-${folderToFocus}`);
            selectedElem?.focus();
            selectedElem?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    };

    const updateSelectedBreadcrumb = useCallback(
        (folder: IFolder): void => {
            const searchForFolderRecusively = (
                folder: IFolder,
                folderToSearch: IFolder,
                breadcrumbArr: string[],
            ): string => {
                let i, currentChild, result;
                if (!folderToSearch || !folder) {
                    return '';
                }
                if (folder.id === folderToSearch.id) {
                    return folderToSearch.name;
                } else {
                    const children = folderToSearch.children || [];
                    for (i = 0; i < children.length; i++) {
                        currentChild = children[i];
                        result = searchForFolderRecusively(folder, currentChild, breadcrumbArr);
                        // Return the result if the folder has been found
                        if (result !== '') {
                            breadcrumbArr.push(folderToSearch.name);
                            return result;
                        }
                    }
                    // The node has not been found and we have no more options
                    return '';
                }
            };

            const breadcrumbs: string[] = [];
            if (folder && treeValues) {
                searchForFolderRecusively(folder, treeValues, breadcrumbs);
                breadcrumbs.reverse();
                breadcrumbs.push(folder.name);
                setValue(BREADCRUMB_FIELD_NAME, breadcrumbs.join(' > '));
            }
        },
        [BREADCRUMB_FIELD_NAME, setValue, treeValues],
    );

    // register to react-hook-forms
    useEffect(() => {
        if (!readOnly) {
            register({ name: field.name });
            register({ name: BREADCRUMB_FIELD_NAME });
            if (defaultSelected) {
                setValue(field.name, defaultSelected?.id);
                updateSelectedBreadcrumb(defaultSelected);
            }
        }
    }, [
        register,
        setValue,
        field.name,
        fieldData.displayValue,
        defaultSelected?.id,
        readOnly,
        updateSelectedBreadcrumb,
        BREADCRUMB_FIELD_NAME,
        defaultSelected,
    ]);

    if (readOnly) {
        return <ReadOnlyControl field={field} fieldData={fieldData} />;
    }

    const selectItem = (nodeId: string) => {
        const selectedFolder = getSelectedFolder('id', nodeId);
        if (selectedFolder) {
            setSelectedFolder(selectedFolder);
            setTempSelectedFolder([selectedFolder.id]);
            updateSelectedBreadcrumb(selectedFolder);
            setValue(field.name, nodeId);
        }
        dropDownTreeControlPopper.close();
    };

    const handleItemSelect = (event: React.MouseEvent, nodeId: string) => {
        event.preventDefault();
        if (nodeId) {
            selectItem(nodeId);
        }
    };

    const handleItemSelectWithKey = (event: React.KeyboardEvent, nodeId: string) => {
        event.preventDefault();
        if (event.key === 'Enter' && nodeId) {
            selectItem(nodeId);
        }
    };

    const handleFocus = (e: React.FocusEvent) => {
        if (treeViewRef.current) {
            treeViewRef.current.scrollLeft = e.target.clientLeft;
        }
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const handleToggle = (e: any, nodeIds: string[]) => {
        if (!e.key || e.key !== 'Enter') {
            setExpandedList(nodeIds);
        }
    };

    const StyledTreeItem = (props: any) => (
        <TreeItem
            {...props}
            classes={{
                group: css.group,
                root: css.treeItemRoot,
                label: css.treeItemlabel,
                selected: css.selectedTreeItem,
                content: css.treeItemContent,
            }}
            onLabelClick={(e: React.MouseEvent) => handleItemSelect(e, props.nodeId)}
            onKeyDown={(e: React.KeyboardEvent) => handleItemSelectWithKey(e, props.nodeId)}
            id={'treeItemId-' + props.nodeId}
            data-testid={'treeview-item-id-' + props.nodeId}
            onFocus={handleFocus}
        />
    );

    const renderTree = (node: IFolder | undefined) => {
        return (
            node && (
                <StyledTreeItem key={node.id} nodeId={node.id} label={node.name}>
                    {Array.isArray(node.children) ? node.children.map((node: IFolder) => renderTree(node)) : null}
                </StyledTreeItem>
            )
        );
    };

    const onKeyDown = (event: any) => {
        if (
            event &&
            (event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 13) &&
            !dropDownTreeControlPopper.isOpen
        ) {
            dropDownTreeControlPopper.open(inputRef.current);
        } else if (event.key === 'Tab') {
            dropDownTreeControlPopper.close();
        } else {
            return;
        }
    };

    return (
        <ControlLabel
            field={field}
            control={
                <>
                    <UUIInput
                        {...bindToggle(dropDownTreeControlPopper)}
                        id={field.name}
                        name={field.name}
                        value={selectedFolder?.name || defaultSelected?.name}
                        ref={inputRef}
                        data-testid="dropdownTreeControl"
                        inputProps={{
                            readOnly: true,
                            className: css.folderText,
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton aria-label="arrow" size="small" className={css.endAdornment} disableRipple>
                                    {dropDownTreeControlPopper.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                        classes={{
                            focused: css.inputFocused,
                            input: css.inputNative,
                            root: css.textFieldRoot,
                        }}
                        onKeyDown={onKeyDown}
                        error={getValidationMessagesForField(field, errors, 'errors').length > 0}
                        warning={getValidationMessagesForField(field, errors, 'warnings').length > 0}
                    />
                    <Popper
                        {...bindPopper(dropDownTreeControlPopper)}
                        transition
                        style={{
                            width: inputRef.current ? inputRef.current.clientWidth : 'auto',
                            zIndex: 99999,
                        }}>
                        {({ TransitionProps }) => (
                            <Fade
                                {...TransitionProps}
                                onEntered={scrollSelectedFolderIntoView}
                                onExited={() => {
                                    TransitionProps?.onExited();
                                    const inputElement = inputRef.current?.firstElementChild as
                                        | HTMLElement
                                        | null
                                        | undefined;
                                    inputElement?.focus();
                                }}>
                                <Paper elevation={3}>
                                    <ClickAwayListener onClickAway={() => dropDownTreeControlPopper.close()}>
                                        <TreeView
                                            data-testid="dropdownTreeView"
                                            aria-label="treeview"
                                            className={css.root}
                                            expanded={expandedList}
                                            onNodeToggle={handleToggle}
                                            selected={tempSelectedFolder}
                                            defaultCollapseIcon={
                                                <KeyboardArrowUpIcon
                                                    className={css.arrowUpIcon}
                                                    data-testid="caretUpIcon"
                                                />
                                            }
                                            defaultExpandIcon={
                                                <KeyboardArrowDownIcon
                                                    className={css.arrowUpIcon}
                                                    data-testid="caretDownIcon"
                                                />
                                            }
                                            defaultEndIcon={<FiberManualRecordIcon style={{ fontSize: 8 }} />}
                                            ref={treeViewRef}>
                                            {renderTree(treeValues)}
                                        </TreeView>
                                    </ClickAwayListener>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </>
            }
        />
    );
};
export default DropdownTreeControl;
