/* eslint-disable max-lines-per-function */
import { Collapse, Grid } from '@mui/material';
import { ICHUploadResponse } from '@wk/elm-uui-context-handler';
import React, { useEffect, useRef, useState } from 'react';
import useCancellablePromise from '../../hooks/useCancellablePromise';
import { useAppSelector } from '../../store';
import { getAppResources } from '../../store/slices';
import FileDragAndDrop from '../common/fileDragAndDrop';
import UUIButton from '../common/uuiButton';
import { UUITooltip } from '../common/uuiTooltip';
import { handleContextLayerPerformFileDragAndDrop } from '../contextLayerService/contextLayerHelpers';
import { isOC } from '../contextLayerService/contextLayerService';
import { useItemScreenState } from './context/itemScreenContext';
import { getFieldRows, isSection, isTabStyleAccounting } from './itemScreenCommon';
import ItemScreenFieldRow from './itemScreenFieldRow';
// eslint-disable-next-line css-modules/no-unused-class
import css from './itemScreenView.module.scss';
import { FieldOrSection, ISectionEntry } from './tabs/types';
import { IItemScreenField } from './types';

interface IItemScreenViewProps {
    sections: ISectionEntry[];
    isTopSummaryView?: boolean;
    enableShowMore?: boolean;
    stickyTopPosition?: number;
    tabIndex?: number;
    parentTabIndex?: number;
}

const ItemScreenView: React.FC<IItemScreenViewProps> = ({
    sections,
    isTopSummaryView,
    enableShowMore,
    stickyTopPosition,
    tabIndex,
    parentTabIndex,
}) => {
    const appResources = useAppSelector(getAppResources);
    const itemScreenState = useItemScreenState();
    const itemScreenJson = itemScreenState.itemScreenJson!;
    const [open, setOpen] = useState(false);
    const [hasFocusedOnLoad, setHasFocusedOnLoad] = useState(false);
    const [showMoreShouldBeDisplayed, setShowMoreShouldBeDisplayed] = useState(!!enableShowMore);
    const viewRef = useRef<HTMLDivElement>(null);
    const collapseRef = useRef<HTMLDivElement>(null);
    const openRef = useRef<boolean>(false);
    const COLLAPSED_HEIGHT = 128;
    const handleDrawerToggle = () => {
        // This is here simply to trigger a state change to force re-render on toggle
        setOpen(!open);

        // This is what actually contains the open/close state (it is consistently accurate)
        openRef.current = !openRef.current;
    };
    const cancellablePromise = useCancellablePromise<ICHUploadResponse | ICHUploadResponse[]>();

    useEffect(() => {
        if (itemScreenState.mode === 'show') {
            return;
        }
        if (viewRef.current && !hasFocusedOnLoad) {
            const itemScreenFields = viewRef.current.querySelectorAll<HTMLInputElement>('.itemScreenField input');
            if (itemScreenFields.length > 0) {
                itemScreenFields[0]?.focus();
                itemScreenFields[0]?.select();
                setHasFocusedOnLoad(true);
            }
        }
    }, [itemScreenState.mode, hasFocusedOnLoad]);

    // this measures the show more box and if the content is less than the collapsed height,
    // then we do not show the show more button
    useEffect(() => {
        const CLIENT_HEIGHT_BUFFER = 60;
        if (
            enableShowMore &&
            collapseRef.current &&
            // checking for 0 height is for Jest tests so that the show more button always shows (clientHeight is 0 when running Jest)
            collapseRef.current.clientHeight !== 0 &&
            collapseRef.current.clientHeight + CLIENT_HEIGHT_BUFFER >= collapseRef.current.scrollHeight
        ) {
            setShowMoreShouldBeDisplayed(false);
        }
    }, [enableShowMore]);

    if (sections.length === 0) {
        return null;
    }

    const fieldRows = getFieldRows(sections[0].fieldsAndSections);
    const isEmbeddedList =
        fieldRows.length === 1 && !isSection(fieldRows[0][0]) && fieldRows[0][0].controlType === 'Multi-Select List';

    const actionFileDropHandler = (filePaths: []) => {
        handleContextLayerPerformFileDragAndDrop({
            associatedEntityType: itemScreenJson.metadata.associatedEntityType || itemScreenJson.metadata.entityName,
            associatedEntityId: itemScreenJson.metadata.associatedEntityId || itemScreenJson.item.id!.toString(),
            associatedEntityTypeId:
                itemScreenJson.metadata.associatedEntityTypeId || itemScreenJson.metadata.entityId.toString(),
            cancellablePromise,
            entityTypeId: itemScreenJson.metadata.isDocumentEntity
                ? itemScreenJson.metadata.entityId.toString()
                : undefined,
            documentId: itemScreenJson.metadata.isDocumentEntity ? itemScreenJson.item.id!.toString() : undefined,
            folderArr: itemScreenJson.metadata.folderPath,
            loggedInUser: appResources.username,
            filePaths,
            associatedEntityName:
                itemScreenJson.metadata.associatedEntityName || itemScreenJson.metadata.objDisplayValue,
        });
    };

    const isDropZoneEnabled = () => {
        return (
            (isOC() &&
                itemScreenState.mode === 'show' &&
                isTopSummaryView &&
                itemScreenJson.metadata?.enableFileDropzone) ||
            false
        );
    };

    const common = (
        <FileDragAndDrop
            actionFileDropHandler={actionFileDropHandler}
            isFileDropZoneEnabled={isDropZoneEnabled()}
            isThreeLinesLabel={true}
            height={open && showMoreShouldBeDisplayed ? '100%' : COLLAPSED_HEIGHT.toString() + 'px'}
            areaLabel={itemScreenJson.metadata.pageTitle}>
            <div
                ref={viewRef}
                className={!isEmbeddedList ? css.itemScreenViewRoot : css.embeddedContainer}
                data-testid={`itemScreenView-${itemScreenState.mode}Mode`}>
                {sections.map((section, i) => (
                    <ItemScreenSection
                        key={i}
                        heading={section.sectionTitle}
                        section={section}
                        level={1}
                        stickyTopPosition={stickyTopPosition}
                        tabIndex={tabIndex}
                        parentTabIndex={parentTabIndex}
                    />
                ))}
            </div>
        </FileDragAndDrop>
    );
    return itemScreenState.mode === 'show' && showMoreShouldBeDisplayed ? (
        <>
            <Collapse in={openRef.current} collapsedSize={COLLAPSED_HEIGHT} timeout={750} ref={collapseRef}>
                {common}
            </Collapse>
            <div className={css.showMoreWrapper}>
                <UUIButton
                    onClick={handleDrawerToggle}
                    size="small"
                    color="primary"
                    classes={{ root: css.showButtonRoot }}>
                    {openRef.current ? appResources.itemscreenShowLess : appResources.itemscreenShowMore}
                </UUIButton>
            </div>
        </>
    ) : (
        common
    );
};

interface IItemScreenSection {
    level: number;
    heading: string;
    section: ISectionEntry;
    stickyTopPosition?: number;
    tabIndex?: number;
    parentTabIndex?: number;
}

const ItemScreenSection: React.FC<IItemScreenSection> = ({
    level,
    heading,
    section,
    stickyTopPosition,
    tabIndex,
    parentTabIndex,
}) => {
    let fieldsAndSections = section.fieldsAndSections;
    // if we are at the 3rd level, put each field on its own row.
    if (level >= 3) {
        fieldsAndSections = section.fieldsAndSections.map((f) => (isSection(f) ? f : { ...f, isNewLine: true }));
    }
    const fieldRows = getFieldRows(fieldsAndSections);
    const isEmbeddedList =
        fieldRows.length === 1 && !isSection(fieldRows[0][0]) && fieldRows[0][0].controlType === 'Multi-Select List';
    // remove {number} from end of heading if it is there
    const strippedHeading = heading.replace(/{.*}$/, '');
    return (
        <div className={isTabStyleAccounting(fieldRows[0][0]) ? css.sectionContainer : css[`sectionLevel${level}`]}>
            {strippedHeading &&
                (isTabStyleAccounting(fieldRows[0][0]) ? (
                    <h3 className={css.sectionHeading}>{strippedHeading}</h3>
                ) : (
                    <UUITooltip title={strippedHeading} placement="top-start" classes={{ tooltip: css.tooltip }}>
                        <ItemScreenSectionHeading level={level}>{strippedHeading}</ItemScreenSectionHeading>
                    </UUITooltip>
                ))}

            {fieldRows.map((fieldRow: FieldOrSection[], i) =>
                isSection(fieldRow[0]) ? (
                    <ItemScreenSection
                        key={i}
                        level={level + 1}
                        heading={fieldRow[0].sectionTitle}
                        section={fieldRow[0]}
                        tabIndex={tabIndex}
                        parentTabIndex={parentTabIndex}
                    />
                ) : (
                    <Grid
                        key={i}
                        container
                        spacing={isEmbeddedList ? 0 : 2}
                        className={!isEmbeddedList ? css[`gridContainerLevel${level}`] : css.embeddedContainer}>
                        <ItemScreenFieldRow
                            key={i}
                            fieldRow={fieldRow as IItemScreenField[]}
                            isEmbeddedList={isEmbeddedList}
                            stickyTopPosition={stickyTopPosition}
                            sectionLevel={level}
                            tabIndex={tabIndex}
                            parentTabIndex={parentTabIndex}
                        />
                    </Grid>
                ),
            )}
        </div>
    );
};

interface IItemScreenSectionHeading {
    level: number;
}

const ItemScreenSectionHeading: React.FC<IItemScreenSectionHeading> = ({ level, children }) => {
    switch (level) {
        case 1:
            return <h3 className={css.sectionGroupHeading}>{children}</h3>;
        case 2:
            return <h4 className={css.sectionGroupHeading}>{children}</h4>;
        case 3:
            return <h5 className={css.sectionGroupHeading}>{children}</h5>;
        default:
            throw new Error(`Invalid level '${level}' provided to ItemScreenSectionHeading`);
    }
};

export default ItemScreenView;
