import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useAppSelector } from '../../../store';
import { getAppResources } from '../../../store/slices';
import { UUIFlyoutMenu, UUIFlyoutMenuItem, UUIFlyoutMenuButton } from '../../common/uuiFlyoutMenu';
import WkCircleArrowIcon from '../../icons/wkCircleArrowIcon';
import { useListScreenDispatch, useListScreenState } from '../context/listScreenContext';
import { getSavedViewTabName } from '../listScreenHelpers';
import { ISavedView } from '../types';

interface ITabOptionsItemMoreMenu {
    currentItem: ISavedView;
    setSelectedTabId: (tabId: number) => void;
}

const TabOptionsItemMoreMenu: React.FC<ITabOptionsItemMoreMenu> = React.memo(({ currentItem, setSelectedTabId }) => {
    const popupState2 = usePopupState({ variant: 'popover', popupId: 'contextMenu' });
    const listScreenDispatch = useListScreenDispatch();
    const listScreenState = useListScreenState();
    const metadata = listScreenState.metadata!;
    const tabName = getSavedViewTabName(currentItem, metadata);
    const appResources = useAppSelector(getAppResources);

    const resetToDefaultClickHandler = () => {
        popupState2.close();
        listScreenDispatch({ type: 'OpenResetToDefaultDialog' });
    };

    const removeTabClickHandler = () => {
        setSelectedTabId(currentItem.id!);
        popupState2.close();
        listScreenDispatch({ type: 'OpenTabOptionsRemoveTabDialog' });
    };

    const displayRemoveTabOption = currentItem.isEditable && !currentItem.isUserDefault && !currentItem.isSystemDefault;
    const testIdName =
        tabName === listScreenState.metadata!.screenDisplayName && listScreenState.isEmbeddedList
            ? appResources.listscreenDefaultDropdownText
            : tabName;
    return displayRemoveTabOption || currentItem.isUserDefault ? (
        <UUIFlyoutMenu anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <UUIFlyoutMenuButton aria-label="operations" data-testid={`${testIdName}_more_icon`} />
            {currentItem.isUserDefault && (
                <UUIFlyoutMenuItem
                    data-testid={`${testIdName}_reset_icon`}
                    onClick={resetToDefaultClickHandler}
                    icon={<WkCircleArrowIcon />}>
                    {appResources.menuItemNameResetToDefault}
                </UUIFlyoutMenuItem>
            )}
            {displayRemoveTabOption && (
                <UUIFlyoutMenuItem
                    data-testid={`${testIdName}_delete_icon`}
                    onClick={removeTabClickHandler}
                    icon={<CloseSharpIcon />}>
                    {appResources.listscreenRemoveTab}
                </UUIFlyoutMenuItem>
            )}
        </UUIFlyoutMenu>
    ) : null;
});

export default TabOptionsItemMoreMenu;
