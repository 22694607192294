import { MD5 } from 'crypto-js';
import { useEffect } from 'react';
import { config } from '../target/t360/config';
import { getLogger } from '../utils/loggingService';

const logger = getLogger('script-hook');
type UseScriptProps = { functionName: string | null; functionBody: string | undefined };

export const useScript = ({ functionName, functionBody }: UseScriptProps): void => {
    useEffect(() => {
        if (!functionBody) {
            return;
        }

        const script = document.createElement('script');
        script.onerror = (...args): void => {
            const [, , , , error] = args;
            logger.error('External script loading failed.', error);
        };
        script.setAttribute('nonce', config.get('REACT_APP_CSP_NONCE'));

        const scriptContent = functionName ? `var ${functionName}=function(){${functionBody}}` : functionBody;

        const scriptText = document.createTextNode(scriptContent);
        script.appendChild(scriptText);
        document.body.appendChild(script);
        return () => {
            if (functionName && window[functionName]) {
                window[functionName] = undefined;
            }
            document.body.removeChild(script);
        };
    }, []);
};

export const useStyle = (styleBody: string | undefined): void => {
    useEffect(() => {
        if (!styleBody) {
            return;
        }
        const style = document.createElement('style');
        style.setAttribute('nonce', config.get('REACT_APP_CSP_NONCE'));
        const styleContent = styleBody;
        const styleText = document.createTextNode(styleContent);
        style.appendChild(styleText);
        document.body.appendChild(style);
        return () => {
            document.body.removeChild(style);
        };
    }, []);
};

export const wrapWithNonce = (html: string): string => {
    if (!html) {
        return '';
    }
    return `<span nonce="${config.get('REACT_APP_CSP_NONCE')}">${html}</span>`;
};

export const runScript = (clientScript: string): void => {
    const functionName = `clientScript_${MD5(clientScript).toString()}`;
    if (!window[functionName]) {
        const script = document.createElement('script');
        script.setAttribute('nonce', config.get('REACT_APP_CSP_NONCE'));
        script.appendChild(document.createTextNode(`var ${functionName}=function(){${clientScript}}`));
        document.body.appendChild(script);
    }
    window[functionName]();
};
