import { ClassNameMap } from '@mui/styles/withStyles';
import { Logger } from '@wk/elm-uui-common';
import classNames from 'classnames';
import { IReportEmbedConfiguration } from 'embed';
import { Report } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { VFC, useEffect, useState } from 'react';
import { EmbedTokenStatus } from '../../../../store/slices';
import { getLogger } from '../../../../utils/loggingService';
import { FullScreenOverlayIconEnum } from '../../../common/types';
import { MessageView } from '../messageView';

export type PowerBIEmbedProps = {
    fetchMethod: any;
    classes: ClassNameMap;
    embedConfig: IReportEmbedConfiguration;
    isMinimized: boolean;
    testId: string;
    tokenStatus: EmbedTokenStatus;
};

const logger = (): Logger => getLogger('PowerBiView');

export const PowerBIEmbedView: VFC<PowerBIEmbedProps> = (props) => {
    const { embedConfig, fetchMethod, classes, isMinimized, testId, tokenStatus } = props;
    const [report, setReport] = useState<Report | undefined>(undefined);
    const [reportRendered, setReportRendered] = useState(false);
    const powerBifailureMessage = [window.Props.powerBifailureMessage, window.Props.genericRetryMessage];

    useEffect(() => {
        if (!report || !reportRendered) {
            return;
        }

        void fetchMethod(report);
    }, [report, reportRendered]);

    const MessageViewComponent = (
        <div data-testid={`${testId}-errorMessageView`}>
            <MessageView
                heading={window.Props.errorOverlayHeading}
                icon={FullScreenOverlayIconEnum.EXCLAMATION}
                message={powerBifailureMessage}
                iconContainer={classes.iconContainer}
            />
        </div>
    );

    const PowerBIViewComponent = (
        <div data-testid={testId} className={classes.reportContainer}>
            {embedConfig.accessToken && embedConfig.embedUrl && (
                <PowerBIEmbed
                    cssClassName={classNames(classes.report, { [classes.hiddenReport]: isMinimized })}
                    embedConfig={embedConfig}
                    eventHandlers={
                        new Map([
                            [
                                'error',
                                (event): void => {
                                    if (!event) {
                                        return;
                                    }
                                    event.stopPropagation();
                                    logger().info(JSON.stringify(event.detail));
                                },
                            ],
                            [
                                'rendered',
                                (): void => {
                                    setReportRendered(true);
                                },
                            ],
                        ])
                    }
                    getEmbeddedComponent={(embeddedReport): void => {
                        setReport(embeddedReport as Report);
                    }}
                />
            )}
        </div>
    );

    if (tokenStatus === EmbedTokenStatus.Failed || tokenStatus === EmbedTokenStatus.NotAvailable) {
        return MessageViewComponent;
    } else {
        return PowerBIViewComponent;
    }
};
