import { ChatBubbleOutlineOutlined } from '@mui/icons-material';
import React, { HTMLAttributes, ReactElement, useEffect, useRef, VFC } from 'react';
import { config } from '../../../../target/t360/config';
import { getLogger } from '../../../../utils/loggingService';
import { ItemProps } from '../item';
import { WkChatOfflineWrapper } from './wkChatOfflineWrapper';

type NestedItemProps = Pick<ItemProps, 'icon' | 'iconTitle'> & Pick<HTMLAttributes<HTMLElement>, 'id' | 'style'>;

type InjectedLiveChatProps = {
    nestedItem: (props: NestedItemProps) => ReactElement;
};

export const InjectedLiveChat: VFC<InjectedLiveChatProps> = (props) => {
    const { nestedItem: NestedItem } = props;

    // TODO: Change the implementation to get these values.
    const { apiContextRoot, apiContextPath, liveChatPath } = window.Props;
    const scriptPath = `${String(apiContextRoot)}${String(apiContextPath)}${String(liveChatPath)}`;

    useEffect(() => {
        const liveChatApiScript = document.createElement('script');
        liveChatApiScript.onerror = (...args): void => {
            const [, , , , error] = args;
            getLogger('liveChat').error('Live Chat script loading failed.', error);
        };
        liveChatApiScript.setAttribute('id', 'liveChatApiScript');
        liveChatApiScript.setAttribute('nonce', config.get('REACT_APP_CSP_NONCE'));
        liveChatApiScript.setAttribute('src', scriptPath);
        document.body.appendChild(liveChatApiScript);

        return (): void => {
            document.body.removeChild(liveChatApiScript);
        };
    }, []);

    const { current: item } = useRef(
        <>
            <NestedItem
                id="wkIconOnline"
                icon={ChatBubbleOutlineOutlined}
                iconTitle="Live Chat"
                style={{ display: 'none' }}
            />
            {/* TODO: do we really need to disable cursor on offline chat? */}
            <NestedItem id="wkIconOffline" icon={WkChatOfflineWrapper} iconTitle="Live Chat Offline" />
        </>,
    );
    return item;
};
InjectedLiveChat.displayName = 'InjectedLiveChat';
