import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Link, Checkbox, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    DataGridPro,
    GridColDef,
    GridColumnHeaderParams,
    GridRenderCellParams,
    GridSortDirection,
    GridSortModel,
    GridValueGetterParams,
    DataGridProProps,
} from '@mui/x-data-grid-pro';
import classNames from 'classnames';
import React, { CSSProperties, forwardRef, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { wrapWithNonce } from '../../../../hooks';
import {
    GridActionButton,
    GridComponent,
    GridComponentControlType,
    GridComponentSourceViewData,
} from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { Tooltip } from '../../../common';
import { getIcon } from '../../../icons/iconStorage';
import { WkExcel } from '../../../icons/wkExcel';
import {
    Action,
    ConfirmActionPopup,
    ReviewActionType,
} from '../lineItemConfirmActionPopup/confirmActionPopup.component';
import { useConfirmAction } from '../lineItemConfirmActionPopup/hook/useConfirmAction.hook';
import { useModifyAdjustmenthook } from '../modifyAdjustmentActionPopup/hook/modifyAdjustment.hook';
import { ModifyAdjustmentPopup } from '../modifyAdjustmentActionPopup/modifyAdjustment.component';
import { useNoteAction } from '../noteActionPopup/hook/useNoteAction.hook';
import { NoteActionPopup, ButtonAction } from '../noteActionPopup/noteActionPopup.component';
import { UseTreeViewContent } from '../treeView/treeView.component';
import { useMultiNestingGridView } from './hooks/useMultiNestingGridview.hook';

declare global {
    interface Window {
        gridValues: any;
    }
}

export enum ViewAndTitleName {
    Adjustment = 'AdjustmentView|Adjustments and Credit,',
    Note = 'NoteView|Notes,',
}

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
        height: '100%',
        marginTop: '0px !important',
        minHeight: '69%',
        maxHeight: '96%',
    },
    grid: {
        flex: 1,
        fontSize: '1rem',
        minHeight: '50px',
        //marginTop: theme.spacing(1.5),
        borderWidth: '0px',
        borderTopWidth: '1px',
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid',
        // TODO: remove separator hide logic when turn on columns resize
        '& .MuiDataGrid-iconSeparator': {
            opacity: 0,
        },
        '& .MuiDataGrid-row:hover': {
            backgroundColor: 'transparent',
        },
    },
    gridHeaderCell: {
        display: 'flex',
        fontSize: '1rem',
        fontWeight: 500,
    },
    cellLink: {
        ...theme.styles.ellipsis,
        fontSize: 'inherit',
    },
    cellText: {
        ...theme.styles.ellipsis,
        color: '#474747',
    },
    sort: {
        '$grid .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover &': {
            opacity: '1',
            fill: 'none',
        },
    },
    sortDesc: {
        transform: 'rotate(180deg)',
    },
    pagesLabel: {
        textAlign: 'right',
        marginLeft: 'auto',
        marginRight: '16px',
    },
    gridButtonsArea: {
        textAlign: 'right',
        alignContent: 'right',
        float: 'right',
        marginLeft: 'auto',
    },
    gridIconButton: {
        padding: theme.spacing(0.75),
        color: theme.palette.primary.dark,
        minWidth: 'auto',
    },
    gridButton: {
        marginRight: '16px !important;',
        marginBottom: '16px !important;',
        lineHeight: '20px',
        boxShadow: 'none',
    },
    gridArea: {
        display: 'flex',
        flexDirection: 'row',
    },
    gridPager: {
        '& .MuiPaginationItem-text': {
            fontSize: '0.85rem',
            color: theme.palette.primary.dark,
        },
        '& .MuiPaginationItem-root': {
            minWidth: '16px',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: '#FFFFFF',
            color: 'black',
            fontWeight: 'bold',
        },
    },
    checkbox: {
        color: theme.palette.secondary.main,
        stroke: theme.palette.background.paper,
        strokeWidth: 1,
    },
    checkboxChecked: {
        color: `${theme.palette.action.active} !important`,
    },
    InvoiceLineItemsNegativeAdjustmentStyle: {
        ...theme.styles.ellipsis,
        color: '#CC0000 !important',
    },
    hideSortIcon: {
        '& .MuiDataGrid-sortIcon': {
            display: 'none',
        },
    },
    sortIconColor: {
        color: '#003d61',
    },
}));

type GridProps = {
    component: GridComponent;
    sharedStorage: Record<string, unknown>;
    classes?: {
        root?: string;
    };
    style?: CSSProperties;
    hasFilter: boolean;
    onAction?: any;
};

interface Filter {
    onLoad: string;
}

const HEADER_HEIGHT = 38;

// eslint-disable-next-line max-lines-per-function
export const MultiNestingGridView = forwardRef<HTMLDivElement, GridProps>((props, ref) => {
    const classes = useStyles();
    const [rows, setData] = useState<any[]>([]);
    const { component, classes: externalClasses, onAction, sharedStorage, hasFilter } = props;
    var [currentPage, setCurrentPage] = useState(1);
    const defaultSortColumn = component.columns.find((column) => column.isDefaultSort);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: defaultSortColumn?.code ?? '',
            sort: (defaultSortColumn?.defaultSortDirectionDesc ? 'desc' : 'asc') as GridSortDirection,
        },
    ]);
    // if grid contains checkboxes, here we store checked/unchecked state
    const [gridValues, setGridValues] = useState<Record<string, Record<string, string>>>({});
    const [maxRowCount, setMaxRowCount] = useState<number>();
    const [expandedRows, setExpandedRows] = React.useState<string[]>([]);
    const { saveData, getDescription } = useNoteAction();
    const { adjustData, rejectData, undoAdjustments, getMetaData } = useConfirmAction();
    const { modifyData, getModifyMetaData } = useModifyAdjustmenthook();
    const { getGridSource, download } = useMultiNestingGridView({
        component,
        sortModel,
        sharedStorage,
        hasFilter,
    });

    const fetchDataAndUpdateState = async () => {
        const result = await getGridSource(currentPage, sortModel);
        setData(rows.concat(result?.data));
        setMaxRowCount(result?.rowsCount);
    };

    useEffect(() => {
        const filter = sharedStorage[`grid.${component.dataSource}.filter`] as Filter | undefined;
        if (filter?.onLoad == 'false') {
            fetchDataAndUpdateStateFilter(false, sortModel);
            setCurrentPage(currentPage + 1);
        }
    }, [sharedStorage]);

    const fetchDataAndUpdateStateFilter = async (isSorting = false, newSortModel: GridSortModel) => {
        if (!isSorting) {
            currentPage = 1;
        }
        const result = await getGridSource(currentPage, newSortModel);
        setData(result?.data || []);
        setMaxRowCount(result?.rowsCount);
    };

    const onScrollEnd: DataGridProProps['onRowsScrollEnd'] = async () => {
        if (rows && rows.length == maxRowCount) {
            return;
        }
        fetchDataAndUpdateState();
        setCurrentPage(currentPage + 1);
    };

    const gridColumns: GridColDef[] = useMemo(() => {
        const renderCell = (params: GridRenderCellParams) => {
            const gridViewData = params.row[params.field] as GridComponentSourceViewData;
            switch (gridViewData.controlType) {
                case GridComponentControlType.ActionButtons:
                    return gridViewData.actionButtons?.map((button, index) => {
                        return (
                            <Button
                                key={index}
                                disableRipple
                                title={button.toolTip}
                                disabled={button.disabled}
                                classes={{
                                    root: classes.gridIconButton,
                                }}
                                onClick={() => handleButtonClick(button)}>
                                {getIcon(button.icon, { color: button.color, fontSize: button.size })}
                            </Button>
                        );
                    });
                case GridComponentControlType.CheckBox:
                    return (
                        <Checkbox
                            checked={Boolean(gridValues[gridViewData.code]?.[gridViewData.id])}
                            onChange={(event): void =>
                                handleCheckedBoxChange(event, gridViewData.code, gridViewData.id)
                            }
                            classes={{ root: classes.checkbox, checked: classes.checkboxChecked }}
                        />
                    );
                case GridComponentControlType.Link:
                    return (
                        <Tooltip title={gridViewData.toolTip ?? gridViewData.value} placement="top">
                            <Link
                                classes={{ root: classes.cellLink }}
                                component="button"
                                onClick={() => handleLinkClick(gridViewData.id, gridViewData.code)}>
                                {gridViewData.value}
                            </Link>
                        </Tooltip>
                    );
                case GridComponentControlType.Html:
                    return (
                        <Tooltip title={gridViewData.toolTip ?? gridViewData.value} placement="top">
                            <span dangerouslySetInnerHTML={{ __html: wrapWithNonce(gridViewData.html) }} />
                        </Tooltip>
                    );
                default:
                    return (
                        <span
                            className={classNames({
                                [classes.InvoiceLineItemsNegativeAdjustmentStyle]: gridViewData?.style,
                                [classes.cellText]: !gridViewData?.style,
                            })}
                            title={gridViewData.toolTip}>
                            {gridViewData.value}
                        </span>
                    );
            }
        };

        const handleCheckedBoxChange = (event: React.ChangeEvent<HTMLInputElement>, code: string, id: string): void => {
            setGridValues(() => {
                const newGridValues = { ...gridValues };
                if (!newGridValues[code]) {
                    newGridValues[code] = {};
                }
                if (event.target.checked) {
                    newGridValues[code][id] = 'true';
                } else {
                    delete newGridValues[code][id];
                }
                return newGridValues;
            });
        };

        const renderHeader = (params: GridColumnHeaderParams) => {
            const isSortedColumn = sortModel[0]?.field === params.colDef.field;
            const sortDirection = sortModel[0]?.sort;

            return (
                <Tooltip title={GetColumnTooltip(params.colDef, sortModel)}>
                    <div className={classes.gridHeaderCell}>
                        <span>{params.colDef.headerName}</span>
                        {isSortedColumn && sortDirection === 'asc' && (
                            <ArrowDropUp fontSize="large" className={classes.sortIconColor} />
                        )}
                        {isSortedColumn && sortDirection === 'desc' && (
                            <ArrowDropDown fontSize="large" className={classes.sortIconColor} />
                        )}
                    </div>
                </Tooltip>
            );
        };

        const valueGetter = (params: GridValueGetterParams): string | undefined => {
            const { value, link } = (params.row[params.field] as GridComponentSourceViewData) || {};
            return link || value;
        };

        const GetColumnTooltip = (column: GridColDef, sortModel: GridSortModel): JSX.Element => {
            const SORT_BY: string = 'Sort by: ';

            return (
                <div>
                    {column.sortable ? `${SORT_BY}${column.headerName}` : column.headerName}
                    {sortModel[0] && column.field?.toLowerCase() === sortModel[0].field && (
                        <>
                            <br />
                            Sorted - {sortModel[0].sort === 'asc' ? 'Ascending' : 'Descending'}
                        </>
                    )}
                </div>
            );
        };

        return component.columns.map((column) => {
            return {
                sortable: column.isSortable,
                field: column.code,
                headerName: column.name,
                disableColumnMenu: true,
                width: 140,
                renderHeader,
                renderCell,
                valueGetter,
            } as GridColDef;
        });
    }, [component.columns, gridValues, sortModel]);

    const handleSortChange = React.useCallback(
        (newSortModel: GridSortModel) => {
            if (newSortModel.length <= 0) {
                return;
            }
            setSortModel(newSortModel);
            fetchDataAndUpdateStateFilter(true, newSortModel);
        },
        [sharedStorage],
    );

    const handleOnDetailPanelExpandedRowIdsChange = (newExpandedRowIds: any) => {
        const rowids = newExpandedRowIds;
        setExpandedRows(rowids);
    };

    const OnClick = async (action: string, entityId: string, data?: any): Promise<void> => {
        if (action.includes('note')) {
            await saveData(action, entityId, data);
            updateGrid(entityId, action, data);
        } else {
            if (action == Action.Adjustment) {
                const result = await adjustData(entityId, data);
                data = { ...data, ...result };
            } else if (action == Action.Reject) {
                const result = await rejectData(entityId, data);
                data = { ...data, ...result };
            } else if (action == Action.UndoAll || action == Action.Undo) {
                const result = await undoAdjustments(entityId, action);
                data = { ...data, ...result };
            } else {
                const result = await modifyData(entityId, data);
                const description = { description: data.Narrative };
                data = { ...data, ...description, ...result };
            }
            updateGrid(entityId, action, data);
        }
    };

    const formatAmount = (amount: any, currencySymbol: any) => {
        let formattedAmount = parseFloat(amount.toFixed(2))
            .toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            .replace(/-\s*/, '');

        if (amount < 0) {
            return `(${currencySymbol}${formattedAmount})`;
        } else {
            return `${currencySymbol}${formattedAmount}`;
        }
    };

    const updateGrid = (entityId: any, action: string, data?: any) => {
        let updateParentGrid = false;
        let expandCollapse = false;
        let viewNameCount;
        setData((prevRows) => {
            const updatedRows = prevRows
                .map((row) => {
                    if (row.entityid.value === entityId) {
                        const existingViewName = row?.viewName?.value;
                        if (action.includes('note')) {
                            if (action == ButtonAction.AddNote) {
                                expandCollapse = true;
                                return {
                                    ...row,
                                    expand: { value: 'True' },
                                    viewName: {
                                        value: existingViewName?.includes(ViewAndTitleName.Note)
                                            ? existingViewName
                                            : `${existingViewName}${ViewAndTitleName.Note}`,
                                    },
                                };
                            } else if (action == ButtonAction.EditNote) {
                                return {
                                    ...row,
                                    description: {
                                        value: data,
                                        code: row.description.code,
                                        controlType: row.description.controlType,
                                        id: row.description.id,
                                    },
                                };
                            } else if (action == ButtonAction.DeleteNote) {
                                return null;
                            }
                        } else {
                            let negativeAdjustmentStyle;

                            if (data.ReviewerAdjustmentTotal.Amount < 0) {
                                negativeAdjustmentStyle = 'InvoiceLineItemsNegativeAdjustmentStyle';
                            }
                            let reviewerAdjustmentNewValue = formatAmount(
                                data.ReviewerAdjustmentTotal.Amount,
                                data.CurrencySymbol,
                            );

                            let newNetAmount = formatAmount(data.NetAmount.Amount, data.CurrencySymbol);

                            if (action == Action.ModifyAdjustment || action == Action.Undo) {
                                updateParentGrid = true;
                                if (action == Action.Undo) {
                                    return null;
                                }

                                let newAmount = formatAmount(
                                    (data.IsNegative ? -1 : 1) * data.AdjustMentAmount,
                                    data.CurrencySymbol,
                                );
                                return {
                                    ...row,
                                    amount: { value: newAmount },
                                    invoice_line_item_description: { value: data.description },
                                };
                            } else if (action == Action.UndoAll) {
                                expandCollapse = true;
                                viewNameCount = row.viewName.value.split(',').filter(Boolean).length;

                                return {
                                    ...row,
                                    action: {
                                        actionButtons: ActionButtons(row, true),
                                        controlType: row.action.controlType,
                                    },
                                    expand: { value: viewNameCount > 1 ? 'True' : 'False' },
                                    viewName: { value: row.viewName.value.replace(ViewAndTitleName.Adjustment, '') },
                                    reviewer_adjustment: {
                                        value: reviewerAdjustmentNewValue,
                                        style: negativeAdjustmentStyle,
                                    },
                                    total: { value: newNetAmount },
                                };
                            } else {
                                expandCollapse = true;
                                const isDisable = data.AdjustmentCount == 0 && data.CurrentReviewerAdjustmentCount == 0;
                                return {
                                    ...row,
                                    action: {
                                        actionButtons: ActionButtons(row, isDisable),
                                        controlType: row.action.controlType,
                                    },
                                    expand: { value: 'True' },
                                    reviewer_adjustment: {
                                        value: reviewerAdjustmentNewValue,
                                        style: negativeAdjustmentStyle,
                                    },
                                    total: { value: newNetAmount },
                                    viewName: {
                                        value: existingViewName?.includes(ViewAndTitleName.Adjustment)
                                            ? existingViewName
                                            : `${ViewAndTitleName.Adjustment}${existingViewName}`,
                                    },
                                };
                            }
                        }
                    }
                    return row;
                })
                .filter((row) => row !== null);
            if (updatedRows.length <= 0) {
                updateParentGrid = true;
            }
            return updatedRows;
        });

        if (expandCollapse) {
            let isExpand = false;
            setExpandedRows((prevExpandedRows) => {
                if (prevExpandedRows.includes(entityId)) {
                    isExpand = true;
                }
                const newRows = prevExpandedRows.filter((rowId) => rowId !== entityId);
                return newRows;
            });
            if (data.AdjustmentCount == 0 && data.CurrentReviewerAdjustmentCount == 0 && viewNameCount == 1) {
                isExpand = false;
            }
            if (isExpand) {
                setExpandedRows((prevExpandedRows) => {
                    return [...prevExpandedRows, entityId];
                });
            }
        }
        if (updateParentGrid) {
            const extendParams = { action: action };
            modifyParentGrid({ ...data, ...extendParams });
        }
    };

    const modifyParentGrid = (data: any) => {
        onAction && onAction(data);
    };
    const ActionButtons = (row: any, isDisable: boolean) => {
        const updatedActionButtons = row.action.actionButtons.map((btn: any) => {
            if (btn.action === Action.UndoAll) {
                return {
                    ...btn,
                    disabled: isDisable,
                };
            }
            return btn;
        });
        return updatedActionButtons;
    };

    const handleButtonClick = async (button: GridActionButton) => {
        const modalContainer = document.createElement('div');
        document.body.appendChild(modalContainer);

        if (button.action.includes('note')) {
            const data = button.action == ButtonAction.EditNote ? await getDescription(button.payload) : '';

            ReactDOM.render(
                <NoteActionPopup
                    action={button.action}
                    payload={button.payload}
                    modalcontainer={modalContainer}
                    data={data}
                    OkClick={OnClick}
                />,
                modalContainer,
            );
        }
        if (button.action == Action.Adjustment || button.action == Action.Reject) {
            let data = (await getMetaData()) as { [key: string]: any };
            data = { ...data, ...button.extendArgs };
            ReactDOM.render(
                <ConfirmActionPopup
                    action={button.action}
                    payload={button.payload}
                    modalcontainer={modalContainer}
                    OkClick={OnClick}
                    data={data}
                    ReviewAction={
                        button.action == Action.Adjustment
                            ? ReviewActionType.AdjustLineItem
                            : ReviewActionType.RejectLineItem
                    }
                />,
                modalContainer,
            );
        }
        if (button.action == Action.ModifyAdjustment) {
            let data = (await getModifyMetaData(button.payload)) as { [key: string]: any };
            ReactDOM.render(
                <ModifyAdjustmentPopup
                    action={button.action}
                    payload={button.payload}
                    modalcontainer={modalContainer}
                    OkClick={OnClick}
                    data={data}
                />,
                modalContainer,
            );
        }
        if (button.action == Action.UndoAll || button.action == Action.Undo) {
            let confirmMessage;
            if (button.action == Action.UndoAll) {
                confirmMessage = `Are you sure you want to ${button.toolTip.replace(
                    '"Fee"',
                    'this',
                )}?\r\n\r\nOnly your own review actions will be undone.`;
            } else {
                confirmMessage = `Are you sure you want to ${button.toolTip}`;
            }
            const confirmed = window.confirm(confirmMessage);
            if (!confirmed) {
                return;
            }
            let data = (await getModifyMetaData(button.payload)) as { [key: string]: any };
            OnClick(button.action, button.payload, data);
        }
        return null;
    };

    const handleLinkClick = async (payload: any, action: any) => {
        if (action == ButtonAction.ViewNote) {
            const data = await getDescription(payload);
            const modalContainer = document.createElement('div');
            document.body.appendChild(modalContainer);
            ReactDOM.render(
                <NoteActionPopup
                    action={action}
                    payload={payload}
                    modalcontainer={modalContainer}
                    data={data}
                    OkClick={OnClick}
                />,
                modalContainer,
            );
        }
        return null;
    };

    const onActionFromChildGridToUpdateParent = (parentId: any, data?: any) => {
        console.log(data);
        let isExpand = false;
        setData((prevRows) => {
            const updatedRows = prevRows.map((row) => {
                const viewNames = row.viewName.value.split(',').filter(Boolean);
                if (parentId == row.entityid.value) {
                    if (viewNames.length > 1) {
                        isExpand = true;
                    }
                    if (data.action == Action.ModifyAdjustment || data.action == Action.Undo) {
                        let negativeAdjustmentStyle;

                        if (data.ReviewerAdjustmentTotal.Amount < 0) {
                            negativeAdjustmentStyle = 'InvoiceLineItemsNegativeAdjustmentStyle';
                        }
                        let reviewerAdjustmentNewValue = formatAmount(
                            data.ReviewerAdjustmentTotal.Amount,
                            data.CurrencySymbol,
                        );
                        const isDisable = data.AdjustmentCount == 0 && data.CurrentReviewerAdjustmentCount == 0;
                        let adjustmentCount = data.AdjustmentCount;
                        let newNetAmount = formatAmount(data.NetAmount.Amount, data.CurrencySymbol);
                        return {
                            ...row,
                            reviewer_adjustment: { value: reviewerAdjustmentNewValue, style: negativeAdjustmentStyle },
                            action: {
                                actionButtons: ActionButtons(row, isDisable),
                                controlType: row.action.controlType,
                            },
                            total: { value: newNetAmount },
                            viewName: {
                                value:
                                    adjustmentCount > 0
                                        ? row.viewName.value
                                        : row.viewName.value.replace(ViewAndTitleName.Adjustment, ''),
                            },
                            expand: { value: viewNames.length > 1 || adjustmentCount > 0 ? 'True' : 'False' },
                        };
                    } else {
                        return {
                            ...row,
                            expand: { value: viewNames.length > 1 ? 'True' : 'False' },
                            viewName: { value: row.viewName.value.replace(ViewAndTitleName.Note, '') },
                        };
                    }
                }
                return row;
            });
            return updatedRows;
        });

        if (
            data.action == ButtonAction.DeleteNote ||
            (data.AdjustmentCount == 0 && data.CurrentReviewerAdjustmentCount == 0)
        ) {
            setExpandedRows((prevExpandedRows) => {
                const newRows = prevExpandedRows.filter((rowid) => rowid !== parentId);
                return newRows;
            });
            if (isExpand) {
                setExpandedRows((prevExpandedRows) => {
                    return [...prevExpandedRows, parentId];
                });
            }
        }
    };

    const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
        ({ row }) => {
            if (row.expand != undefined && row.expand.value == 'True') {
                return (
                    <UseTreeViewContent
                        row={row}
                        onActionFromChildGridToUpdateParent={(data: any) =>
                            onActionFromChildGridToUpdateParent(row.entityid.value, data)
                        }
                    />
                );
            }

            return null;
        },
        [component],
    );

    const headerArea = () => {
        window.gridValues = gridValues;
        return (
            <div className={classes.gridArea}>
                <div className={classes.gridButtonsArea}>
                    <Button
                        onClick={download}
                        disableRipple
                        data-testid="grid-export-to-excel-button"
                        classes={{
                            root: classes.gridIconButton,
                        }}>
                        <Tooltip title="Excel" placement="top" classes={{ tooltipPlacementTop: classes.buttonTooltip }}>
                            <WkExcel />
                        </Tooltip>
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <div
            data-testid="page-grid"
            className={classNames(classes.root, externalClasses?.root, classes.hideSortIcon)}
            ref={ref}>
            <DataGridPro
                classes={{
                    root: classes.grid,
                }}
                slots={{
                    toolbar: headerArea,
                }}
                rows={rows}
                columns={gridColumns}
                columnHeaderHeight={HEADER_HEIGHT}
                getRowHeight={() => component.rowHeight ?? 'auto'}
                disableRowSelectionOnClick
                disableColumnSelector
                hideFooterPagination
                hideFooter
                sortingMode="server"
                onSortModelChange={handleSortChange}
                sortModel={sortModel}
                getRowId={(row) => row?.entityid?.value ?? generateUniqueId}
                onRowsScrollEnd={onScrollEnd}
                disableColumnResize={true}
                columnVisibilityModel={{
                    expand: false,
                    entityid: false,
                    viewName: false,
                }}
                getDetailPanelContent={component.isNestedGrid ? getDetailPanelContent : undefined}
                getDetailPanelHeight={() => 'auto' as const}
                detailPanelExpandedRowIds={expandedRows}
                onDetailPanelExpandedRowIdsChange={handleOnDetailPanelExpandedRowIdsChange}
            />
        </div>
    );
});

MultiNestingGridView.displayName = 'GridView';

const generateUniqueId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
};
