import { getLogger } from '@wk/elm-uui-common';
import { IAuthenticationProvider } from '../../../components/authentication/types';
import { getUserManager, logOutPrimary } from './oidc';

const getLog = () => getLogger('t360.TokenAuthenticationProvider');

export const TokenAuthenticationProvider: IAuthenticationProvider = {
    addRequestAuthentication: async (init: RequestInit) => {
        const headers = init.headers ? new Headers(init.headers) : new Headers();

        const user = await getUserManager().getUser();
        if (user) {
            headers.append('Authorization', 'Bearer ' + user.access_token);
        }
        return { ...init, headers };
    },

    signOut: (signOutUrl: string, notifyOtherInstances?: boolean, isAutoLogout?: boolean) => {
        getLog().debug('signOut triggered by UUI');
        getLog().debug('signOutUrl: ' + signOutUrl);
        getLog().debug('notifyOtherInstances: ' + notifyOtherInstances);
        logOutPrimary(isAutoLogout);
    },
};
