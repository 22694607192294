import update from 'immutability-helper';
import { groupBy } from 'lodash';
import { isValuelessComparisonType } from '../filterPickerHelpers';
import { IFilterPickerState, ListScreenEditDialogAction, IFilterObj, IFiltersMap } from '../types';

export const filterPickerReducer = (
    state: IFilterPickerState,
    action: ListScreenEditDialogAction,
): IFilterPickerState => {
    switch (action.type) {
        case 'AddFilter': {
            return addFilter(
                state,
                action.entityId,
                action.attributeIds,
                action.path,
                action.attributeType,
                action.names,
                action.comparisonType,
            );
        }
        case 'RemoveFilterRow': {
            return removeFilterRow(state, action.filterToRemove, action.indexOfFilter);
        }
        case 'SaveFiltersMap': {
            return saveFiltersMap(state, action.filtersMap);
        }
        case 'UpdateFilterComparisonType': {
            return updateFilterComparisonType(
                state,
                action.filterToUpdate,
                action.indexOfFilter,
                action.newComparisonType,
            );
        }
        case 'UpdateFilterSearchValue': {
            return updateFilterSearchValue(
                state,
                action.filterToUpdate,
                action.indexOfFilter,
                action.newLeftValue,
                action.newRightValue,
            );
        }
        default: {
            return state;
        }
    }
};

const addFilter = (
    state: IFilterPickerState,
    entityId: number,
    attributeIds: number[],
    path: string,
    attributeType: string,
    names: string[],
    comparisonType: string,
) => {
    const newFilter: IFilterObj = {
        entityId,
        attributeIds,
        path,
        attributeType,
        comparisonType,
        names,
    };
    let updatedFiltersMap;
    if (state.filtersMap[getFilterKey(newFilter)]) {
        updatedFiltersMap = update(state.filtersMap, { [getFilterKey(newFilter)]: { $push: [newFilter] } });
    } else {
        updatedFiltersMap = { ...state.filtersMap, [getFilterKey(newFilter)]: [newFilter] };
    }
    return saveFiltersMap(state, updatedFiltersMap);
};

const removeFilterRow = (state: IFilterPickerState, filterToRemove: IFilterObj, indexOfFilter: number) => {
    const filterKey = getFilterKey(filterToRemove);
    let updatedFiltersMap;
    if (state.filtersMap[filterKey].length == 1) {
        updatedFiltersMap = update(state.filtersMap, { $unset: [filterKey] });
    } else {
        updatedFiltersMap = update(state.filtersMap, { [filterKey]: { $splice: [[indexOfFilter, 1]] } });
    }
    return saveFiltersMap(state, updatedFiltersMap);
};

const saveFiltersMap = (state: IFilterPickerState, filtersMap: IFiltersMap) => {
    return { ...state, filtersMap };
};

const updateFilterComparisonType = (
    state: IFilterPickerState,
    filterToUpdate: IFilterObj,
    indexOfFilter: number,
    // todo: strongly type comparison type
    newComparisonType: string,
) => {
    const isDateFilter = filterToUpdate.attributeType === 'Date' || filterToUpdate.attributeType === 'DateTime';
    const existingFilter = state.filtersMap[getFilterKey(filterToUpdate)][indexOfFilter];

    const calculateLeftValue = () => {
        let result = isDateFilter ? '' : existingFilter.leftValue;
        result = isValuelessComparisonType(newComparisonType) ? '' : result;
        return result;
    };

    const updatedFiltersMap = update(state.filtersMap, {
        [getFilterKey(filterToUpdate)]: {
            [indexOfFilter]: {
                $merge: {
                    comparisonType: newComparisonType,
                    leftValue: calculateLeftValue(),
                },
                $unset: newComparisonType === 'BETWEEN' ? ['leftValue', 'rightValue'] : ['leftDate', 'rightDate'],
            },
        },
    });

    return saveFiltersMap(state, updatedFiltersMap);
};

const updateFilterSearchValue = (
    state: IFilterPickerState,
    filterToUpdate: IFilterObj,
    indexOfFilter: number,
    newLeftValue: string | undefined,
    newRightValue?: string,
) => {
    let updatedFiltersMap;
    if (filterToUpdate.attributeType === 'Boolean') {
        newLeftValue = newLeftValue?.toString();
    }
    if (filterToUpdate.attributeType === 'Date' || filterToUpdate.attributeType === 'DateTime') {
        if (filterToUpdate.comparisonType === 'BETWEEN') {
            updatedFiltersMap = update(state.filtersMap, {
                [getFilterKey(filterToUpdate)]: {
                    [indexOfFilter]: {
                        $merge: {
                            leftDate: newLeftValue,
                            rightDate: newRightValue,
                        },
                        $unset: ['leftValue', 'rightValue'],
                    },
                },
            });
        } else {
            updatedFiltersMap = update(state.filtersMap, {
                [getFilterKey(filterToUpdate)]: {
                    [indexOfFilter]: {
                        $merge: {
                            leftValue: newLeftValue?.trim(),
                            rightValue: newRightValue,
                        },
                        $unset: ['leftDate', 'rightDate'],
                    },
                },
            });
        }
    } else {
        updatedFiltersMap = update(state.filtersMap, {
            [getFilterKey(filterToUpdate)]: {
                [indexOfFilter]: { $merge: { leftValue: newLeftValue?.toString() } },
            },
        });
    }
    return saveFiltersMap(state, updatedFiltersMap);
};

export const createFiltersMap = (filters: IFilterObj[]): IFiltersMap => {
    // group the array by the display value of the filter.
    return groupBy(filters, (filter) => getFilterKey(filter));
};

const getFilterKey = (filter: IFilterObj) => {
    return filter.names.join(' > ');
};

export const calculateFilters = (filterPicker: IFilterPickerState): IFilterObj[] => {
    const resultArray: IFilterObj[] = [];
    Object.values(filterPicker.filtersMap).map((filterObjArray) => {
        filterObjArray.map((filterObj) => {
            resultArray.push(filterObj);
        });
    });
    return resultArray;
};
