import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkBudgets = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkAddIcon" viewBox="0 0 25 25" {...props}>
        <defs>
            <clipPath id="wk-budgets-clipPath">
                <rect id="Rectangle_3425" data-name="Rectangle 3425" width="24" height="22.435" fill="currentColor" />
            </clipPath>
        </defs>
        <g id="Group_10973" data-name="Group 10973" transform="translate(-3216 -356)">
            <g id="Group_9350" data-name="Group 9350" transform="translate(3216 357)">
                <g
                    id="Group_9349"
                    data-name="Group 9349"
                    transform="translate(0 0)"
                    clipPath="url(#wk-budgets-clipPath)">
                    <path
                        id="Path_4998"
                        data-name="Path 4998"
                        d="M22.134,0H3.527A3.589,3.589,0,0,0,0,3.652V18.861a3.593,3.593,0,0,0,3.589,3.573H24V5.671H22.134ZM3.6,1.638H20.522V5.671H3.561A1.984,1.984,0,0,1,1.614,3.653l.125-.038H1.613A1.988,1.988,0,0,1,3.6,1.638M22.386,7.309V20.8H3.565a1.988,1.988,0,0,1-1.951-2.019V6.741l.191.113a3.207,3.207,0,0,0,1.8.455Z"
                        transform="translate(0 0)"
                        fill="currentColor"
                    />
                    <path
                        id="Path_4999"
                        data-name="Path 4999"
                        d="M16.567,13.95a1.763,1.763,0,0,0,1.7,1.74h.13a1.732,1.732,0,0,0,1.7-1.761,1.772,1.772,0,0,0-1.765-1.766h-.021a1.767,1.767,0,0,0-1.741,1.788"
                        transform="translate(0.174 0.128)"
                        fill="currentColor"
                    />
                </g>
            </g>
            <rect
                id="Rectangle_3442"
                data-name="Rectangle 3442"
                width="25"
                height="25"
                transform="translate(3216 356)"
                fill="none"
            />
        </g>
    </SvgIcon>
);
