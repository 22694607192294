import React, { forwardRef, useState, useEffect } from 'react';
import { TreeItem, TreeView } from '@mui/lab';
import { Box } from "@mui/material";
import { ComponentType, ContentHolder } from '../../../../store/slices';
import { MultiNestingGridView } from '../multiNestingGridView/multiNestingGridView.component';
import { getIcon } from '../../../icons/iconStorage';
import getTreeData from './hooks/treeView.hook';

interface UseTreeViewContentProps {
    row: any;
    onActionFromChildGridToUpdateParent: any;
}

export const UseTreeViewContent = forwardRef<HTMLDivElement, UseTreeViewContentProps>((props, ref) => {
    const [expandedItems, setExpandedItems] = useState<string[]>([]);
    const [pageData, setPageData] = useState<any>({});

    const handleToggle = (event: React.SyntheticEvent, nodeId: string[]) => {
        const labels = event.currentTarget.querySelectorAll('.MuiTreeItem-label');
        labels.forEach(label => {
            if (label.textContent) {
                label.textContent = label.textContent.includes('Hide')
                    ? label.textContent.replace('Hide', 'Show')
                    : label.textContent.replace('Show', 'Hide');
            }

        });
        setExpandedItems(nodeId);
    };

    const fetchDataForViewNames = async (nodeId: string) => {
        if (pageData[nodeId] && pageData[nodeId].components.length > 0) {
            return;
        }
        const pageState = await getTreeData(nodeId, props.row.entityid?.value);
        setPageData((prevData: any) => ({ ...prevData, [nodeId]: pageState }));
    };

    useEffect(() => {
        if (props.row && props.row.viewName && props.row.viewName.value) {
            const viewNameList = props.row.viewName.value.split(',').filter(Boolean);
            const allKeys = viewNameList.map((viewName: string) => viewName.split('|')[0].trim());
            setExpandedItems(allKeys);
            for (let i = 0; i < allKeys.length; i++) {
                fetchDataForViewNames(allKeys[i]);
            }

        }
    }, [props.row]);

    const renderTreeItem = (viewname: string) => {
        const viewNameList = viewname.split(',').filter(Boolean);
        return viewNameList.map((name: string) => (
            <TreeItem
                key={name.split('|')[0]}
                nodeId={name.split('|')[0]}
                label={'Hide ' + name.split('|')[1]}
                onClick={() => fetchDataForViewNames(name.split('|')[0])}
            >
                {pageData[name.split('|')[0]] && pageData[name.split('|')[0]].components
                    ?.filter((component: any) => component.contentHolder === ContentHolder.PageContent)
                    ?.map((component: any, index: number) => {
                        const { type } = component;
                        if (type === ComponentType.GridComponent) {
                            return (
                                <MultiNestingGridView
                                    component={component}
                                    sharedStorage={{}}
                                    key={index}
                                    hasFilter={false}
                                    onAction={props.onActionFromChildGridToUpdateParent}
                                />
                            );
                        }
                        return null;
                    })}
            </TreeItem>
        ));
    };

    return (
        <Box sx={{ py: 2, boxSizing: 'border-box', backgroundColor: 'rgba(25, 118, 210, 0.08)', borderStyle: 'ridge' }} ref={ref}>
            <TreeView defaultExpandIcon={<TreeIcon />} defaultCollapseIcon={<TreeIcon />} onNodeToggle={handleToggle} expanded={expandedItems} sx={{ backgroundColor: 'transparent' }}>
                {props.row && renderTreeItem(props.row.viewName.value)}
            </TreeView>
        </Box >
    );
});

const TreeIcon = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-around', cursor: 'pointer' }}>
            {getIcon('expand', { color: 'blue', fontSize: 25 })}
        </div>
    );
};