import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkFirstPageIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 12 12">
        <g transform="translate(-654.404 412.902)">
            <path d="M664.9-412.9l-6.3,6l6.2,6l0.7-0.7l-5.5-5.3l5.5-5.3L664.9-412.9z" />
            <path d="M661.5-412.9l-6.3,6l6.2,6l0.7-0.7l-5.5-5.3l5.5-5.3L661.5-412.9z" />
        </g>
    </SvgIcon>
);

export default WkFirstPageIcon;
