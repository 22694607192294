import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const wkDownloadIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkDownloadIcon" viewBox="0 0 24 24" {...props}>
        <path d="M16,16H0v-1.2h16V16z M8,13.5L8,13.5L2.5,8l0.9-0.9l4.1,4.1V0h1.2v11.3l4.1-4.1L13.5,8L8,13.5z" />
    </SvgIcon>
);

export default wkDownloadIcon;
