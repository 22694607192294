import { Checkbox, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useListScreenState } from './context/listScreenContext';
import ListScreenField from './listScreenField';
import { getColumnHeaderDisplayName } from './listScreenHelpers';
import ListScreenIcon from './listScreenIcon';
import ListScreenPager from './listScreenPager';
import css from './tableView.module.scss';

const TableView: React.FC = () => {
    const listScreenState = useListScreenState();
    const metadata = listScreenState.metadata!;
    const listData = listScreenState.listData!;

    return (
        <>
            <ListScreenPager />
            <Paper className={css.root}>
                <Table className={css.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox color="primary" checked={false} inputProps={{ 'aria-labelledby': '3333' }} />
                            </TableCell>
                            {metadata.columns.map((column, i) => (
                                <TableCell key={i} padding={column.iconName !== '' ? 'checkbox' : undefined}>
                                    {getColumnHeaderDisplayName(column)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listData.list.map((currentRow, i) => (
                            <TableRow key={i}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        color="primary"
                                        checked={false}
                                        inputProps={{ 'aria-labelledby': '2222' }}
                                    />
                                </TableCell>
                                {metadata.columns.map((column) => (
                                    <TableCell
                                        key={column.name}
                                        padding={column.iconName !== '' ? 'checkbox' : undefined}>
                                        {column.iconName !== '' ? (
                                            <IconButton aria-label="details" color="primary" size="large">
                                                {!currentRow.downloadFile && (
                                                    <ListScreenIcon iconName={column.iconName} />
                                                )}
                                            </IconButton>
                                        ) : (
                                            <ListScreenField
                                                row={currentRow}
                                                column={column}
                                                mode={listScreenState.mode}
                                            />
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </>
    );
};

export default TableView;
