import { Input, InputProps } from '@mui/material';
import { merge } from 'lodash';
import React from 'react';
import css from './uuiInput.module.scss';

interface UUIInputProps extends InputProps {
    warning?: boolean;
}

export const UUIInput = React.forwardRef<HTMLInputElement, UUIInputProps>((props, ref) => {
    // pull warning off of props so we can pass all the other props down to Input except warning since warning isn't a valid Input prop
    const { warning, ...inputProps } = props;
    const getRootClass = () => {
        if (props.readOnly) {
            return css.input;
        }
        if (props.error) {
            return css.editableInputWithError;
        }
        if (warning) {
            return css.editableInputWithWarning;
        }
        return css.editableInput;
    };

    const getFocusedClass = () => {
        if (props.readOnly) {
            return '';
        }
        if (props.error) {
            return css.editableInputFocusedWithError;
        }
        if (warning) {
            return css.editableInputFocusedWithWarning;
        }
        return css.editableInputFocused;
    };

    const inputClasses = merge({}, props.classes, {
        root: getRootClass() + ' ' + (props.classes?.root || ''),
        focused: getFocusedClass() + ' ' + (props.classes?.focused || ''),
        input: (props.readOnly ? css.readOnlyHtmlInput : css.htmlInput) + ' ' + (props.classes?.input || ''),
        inputMultiline:
            (props.readOnly ? css.readOnlyInputMultiline : css.inputMultiline) +
            ' ' +
            (props.classes?.inputMultiline || ''),
    });

    return (
        <Input
            {...inputProps}
            ref={ref}
            disableUnderline={true}
            onFocus={(e) => {
                if (props.readOnly && props.value && (props.value as string).length <= 255) {
                    e.target.select();
                } else if (!props.readOnly && props.onFocus) {
                    props.onFocus(e);
                }
            }}
            maxRows={20}
            classes={inputClasses}
            inputProps={{ ...props.inputProps, autoComplete: 'new-password' }}
        />
    );
});

UUIInput.displayName = 'UUIInput';
