import { Button, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { KeyboardEventHandler, KeyboardEvent, MouseEvent, useState, VFC } from 'react';
import { NotificationPlacement } from '../../../../reducers/types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { FavoriteType, selectFavoritesTree, showNotification } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { BaseDialog, BaseDialogProps } from '../../../common';
import { useFavorites } from '../../hooks';

const useStyles = makeStyles<AppTheme>(() => ({
    paper: {
        maxWidth: 560,
        width: '100%',
    },
    content: {
        boxSizing: 'content-box',
    },
    actions: {
        justifyContent: 'space-between',
    },
}));

export type CreateFolderProps = Omit<BaseDialogProps, 'title' | 'actions'> & {
    parentEntityId: number;
};

export const CreateFavoriteFolderDialog: VFC<CreateFolderProps> = (props) => {
    const classes = useStyles();
    const { parentEntityId, onClose, classes: externalClasses = {}, ...baseDialogProps } = props;
    const dispatch = useAppDispatch();
    const { createFavoriteFolder } = useFavorites();
    const [folderName, setFolderName] = useState('');
    const tree = useAppSelector(selectFavoritesTree());

    const handleCreateFolder = async (event: MouseEvent<HTMLButtonElement> | KeyboardEvent): Promise<void> => {
        if (!tree || !folderName) {
            return;
        }

        try {
            const folder = await createFavoriteFolder({
                parentId: parentEntityId,
                name: folderName,
                type: FavoriteType.Folder,
            });

            dispatch(
                showNotification({
                    notification: {
                        message: `"${folder.name}" folder has been created.`,
                        options: {
                            variant: 'success',
                        },
                        placement: NotificationPlacement.Top,
                    },
                }),
            );
        } catch (error) {
            // ignored // FIXME: fetch wrappers do a lot of non-generic stuff, requires refactoring
        }

        onClose?.(event, 'onAction');
    };

    const handlePressEnterAndSubmit: KeyboardEventHandler = async (event) => {
        if (event.key !== 'Enter') {
            return;
        }

        await handleCreateFolder(event);
    };

    return (
        <BaseDialog
            {...baseDialogProps}
            classes={{
                ...externalClasses,
                actions: classNames(classes.actions, externalClasses.actions),
                content: classNames(classes.content, externalClasses.content),
                paper: classNames(classes.paper, externalClasses.paper),
            }}
            onClose={onClose}
            title="Create Folder"
            actions={
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleCreateFolder}
                    disabled={!folderName}
                    disableRipple>
                    Create
                </Button>
            }>
            <FormControl variant="outlined">
                <InputLabel htmlFor="folder-name-input">Folder Name</InputLabel>
                <OutlinedInput
                    id="folder-name-input"
                    autoFocus
                    value={folderName}
                    placeholder="Enter folder name"
                    onChange={(event) => {
                        setFolderName(event.currentTarget.value);
                    }}
                    onKeyPress={handlePressEnterAndSubmit}
                />
            </FormControl>
        </BaseDialog>
    );
};
