import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkLastPageIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 12 12">
        <g transform="translate(-654.404 412.902)">
            <path d="M655.9-412.9l6.3,6l-6.2,6l-0.7-0.7l5.5-5.3l-5.5-5.3L655.9-412.9z" />
            <path d="M659.3-412.9l6.3,6l-6.2,6l-0.7-0.7l5.5-5.3l-5.5-5.3L659.3-412.9z" />
        </g>
    </SvgIcon>
);

export default WkLastPageIcon;
