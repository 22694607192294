import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkViewInDesktopIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkViewInDesktopIcon" viewBox="0 0 24 24" {...props}>
        <path
            d="M5.4,16H0v-5.4h1.4v3.1l4.4-4.4l0.9,0.9l-4.4,4.4h3.1C5.4,14.6,5.4,16,5.4,16z M10.2,6.6L10.2,6.6
	L9.4,5.8l4.4-4.4h-3.1V0H16v5.4h-1.4V2.2L10.2,6.6z"
        />
    </SvgIcon>
);

export default WkViewInDesktopIcon;
