import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const wkCloseIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkCloseIcon" viewBox="0 0 16 16" {...props}>
        <g transform="translate(-40 -344)">
            <rect width="16" height="16" transform="translate(40 344)" style={{ fill: 'none' }} />
            <g transform="translate(40 344)">
                <g transform="translate(0 0)">
                    <g transform="translate(0 0.016)">
                        <g transform="translate(0 0)">
                            <path
                                style={{ fill: '#ffffff' }}
                                d="M15.781.189a.646.646,0,0,0-.912,0L.191,14.867a.646.646,0,1,0,.912.912L15.781,1.1a.646.646,0,0,0,0-.912Z"
                                transform="translate(0.013 0)"
                            />
                        </g>
                    </g>
                    <g transform="translate(0 0)">
                        <path
                            style={{ fill: '#ffffff' }}
                            d="M15.781,14.87,1.1.192A.646.646,0,1,0,.191,1.1L14.869,15.782a.646.646,0,1,0,.912-.912Z"
                            transform="translate(0.013 0.012)"
                        />
                    </g>
                </g>
            </g>
        </g>
    </SvgIcon>
);

export default wkCloseIcon;
