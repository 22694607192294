import { merge } from 'lodash';
import React, { useReducer } from 'react';
import { createContext, useContextSelector, useContext } from 'use-context-selector';
import { IListScreenEditDialogState, ListScreenEditDialogDispatch, IFiltersMap } from '../types';
import { listScreenEditDialogReducer } from './listScreenEditDialogReducer';

const defaultInitialState: IListScreenEditDialogState = {
    dialog: {
        selectedTab: 0,
        visitedTabs: [0],
    },
    columnPicker: { transferBasketItems: [] },
    filterPicker: { filtersMap: {} as IFiltersMap },
};

const ListScreenEditDialogContext = createContext<IListScreenEditDialogState>({} as IListScreenEditDialogState);

const ListScreenEditDialogContextDispatchContext = createContext<ListScreenEditDialogDispatch | undefined>(undefined);

// initial state override should be used in jest tests only
interface IListScreenEditDialogProvider {
    initialStateOverride?: Partial<IListScreenEditDialogState>;
}

export const ListScreenEditDialogProvider: React.FC<IListScreenEditDialogProvider> = ({
    children,
    initialStateOverride,
}) => {
    const initialState = initialStateOverride
        ? merge({}, defaultInitialState, initialStateOverride)
        : defaultInitialState;
    const [state, dispatch] = useReducer(listScreenEditDialogReducer, initialState);
    return (
        <ListScreenEditDialogContext.Provider value={state}>
            <ListScreenEditDialogContextDispatchContext.Provider value={dispatch}>
                {children}
            </ListScreenEditDialogContextDispatchContext.Provider>
        </ListScreenEditDialogContext.Provider>
    );
};

export const useListScreenEditDialogState = (selector?: (value: IListScreenEditDialogState) => unknown): unknown => {
    const selectorToUse = selector ? selector : (s: IListScreenEditDialogState) => s;
    const context = useContextSelector(ListScreenEditDialogContext, selectorToUse);
    if (context === undefined) {
        throw new Error('useListScreenEditDialogState must be used within a ListScreenEditDialogProvider');
    }
    return context;
};

export const useListScreenEditDialogDispatch = (): ListScreenEditDialogDispatch => {
    const context = useContext(ListScreenEditDialogContextDispatchContext);
    if (context === undefined) {
        throw new Error('useListScreenEditDialogDispatch must be used within a ListScreenEditDialogProvider');
    }
    return context;
};
