import { Close as CloseIcon } from '@mui/icons-material';
import {
    DialogContent,
    DialogActions,
    Button,
    DialogTitle,
    DialogProps,
    Dialog,
    alpha,
    ButtonProps,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { omit } from 'lodash';
import React, { KeyboardEvent, MouseEvent, ReactElement, VFC } from 'react';
import { AppTheme } from '../../app';
import { Tooltip } from '../tooltip';

const useStyles = makeStyles<AppTheme>((theme) => ({
    title: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.success.dark,
        padding: theme.spacing(1.5, 2),
        fontWeight: 500,
        color: theme.palette.common.white,
        cursor: 'default',
    },
    titleTypography: {
        fontWeight: 500,
        color: theme.palette.common.white,
        cursor: 'default',
    },
    titleCloseButton: {
        position: 'absolute',
        padding: theme.spacing(1),
        right: theme.spacing(1),
        color: 'inherit',
        minWidth: 'auto',

        '&:hover': {
            backgroundColor: alpha(theme.palette.action.hover, 0.12),
        },
    },
    titleCloseTooltip: {
        marginBottom: 0,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),

        '$title + &': {
            padding: theme.spacing(2),
        },
    },
    actions: {
        padding: theme.spacing(1.5, 2),
        backgroundColor: theme.palette.background.secondary,
    },
}));

export type BaseDialogCloseReason = 'backdropClick' | 'escapeKeyDown' | 'onAction' | 'onCancel';

export type BaseDialogCloseEvent = MouseEvent<HTMLButtonElement> | KeyboardEvent;

export type BaseDialogProps = Omit<DialogProps, 'onClose'> & {
    title?: string | ReactElement;
    actions?: ReactElement;
    hideCancel?: boolean;
    onClose?: (event: BaseDialogCloseEvent, reason: BaseDialogCloseReason) => void;
    classes?: DialogProps['classes'] & {
        title?: string;
        titleTypography?: string;
        content?: string;
        actions?: string;
        cancel?: string;
    };
};

export const BaseDialog: VFC<BaseDialogProps> = (props) => {
    const classes = useStyles();
    const { title, actions, classes: externalClasses = {}, onClose, children, hideCancel, ...dialogProps } = props;
    const dialogClasses = omit(externalClasses, ['title', 'titleTypography', 'content', 'actions', 'cancel']);

    const close: ButtonProps['onClick'] = (event) => {
        onClose?.(event, 'onCancel');
    };

    return (
        <Dialog {...dialogProps} onClose={onClose} classes={dialogClasses}>
            {Boolean(title) && (
                <DialogTitle classes={{ root: classNames(classes.title, externalClasses.title) }}>
                    <span className={classNames(classes.titleTypography, externalClasses.titleTypography)}>
                        {title}
                    </span>
                    {!hideCancel && (
                        <Tooltip
                            title="Close"
                            placement="top"
                            classes={{ tooltipPlacementTop: classes.titleCloseTooltip }}>
                            <Button
                                onClick={close}
                                disableRipple
                                classes={{
                                    root: classes.titleCloseButton,
                                }}>
                                <CloseIcon />
                            </Button>
                        </Tooltip>
                    )}
                </DialogTitle>
            )}
            <DialogContent classes={{ root: classNames(classes.content, externalClasses.content) }}>
                {children}
            </DialogContent>

            <DialogActions classes={{ root: classNames(classes.actions, externalClasses.actions) }}>
                {actions}
                {!hideCancel && (
                    <Button
                        variant="outlined"
                        color="primary"
                        classes={{ root: externalClasses.cancel }}
                        onClick={close}
                        disableRipple>
                        {'Cancel'}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
