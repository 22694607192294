import { apiFetch, HttpMethod } from '../../../utils/fetchUtils';

export const useAnnouncements = () => {
    const { apiContextRoot, apiContextPath } = window.Props;
    const baseUrl = `${String(apiContextRoot)}${String(apiContextPath)}/announcement`;

    const markAnnouncementRead = (id: number): Promise<void> =>
        apiFetch<void>(`${baseUrl}/${id}`, undefined, {
            method: HttpMethod.Put,
        });

    return { markAnnouncementRead };
};
