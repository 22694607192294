import { Divider, MenuItem, MenuProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import { useUUIHistory } from '../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getAppResources, selectPageState, setPageScriptToExecute, setPrintToExecute } from '../../../../store/slices';
import { AppRoutes, AppTheme } from '../../../app';
import { Menu } from '../menu';

const useStyles = makeStyles<AppTheme>((theme) => ({
    menuItem: {
        color: theme.palette.primary.dark,
    },
    divider: {
        '&:last-child': {
            display: 'none',
        },
    },
}));

type HelpMenuProps = MenuProps;

export const HelpMenu = memo<HelpMenuProps>(function HelpMenu(props) {
    const { ...menuProps } = props;
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useUUIHistory();
    const helpMenu = useAppSelector((state) => state.app.hybridSettings.helpMenu || []);
    const pageScript = useAppSelector(selectPageState('pageScript'));
    const pagePrint = useAppSelector(selectPageState('pagePrint'));
    const {
        applicationLinkContact,
        applicationLinkAbout,
        applicationLinkPrint,
        applicationLinkEmail,
        legacyContextRoot,
    } = useAppSelector(getAppResources);

    const isPrintAvailable = pageScript?.printPage || pagePrint?.visible;

    const handlePrintClick = (): void => {
        if (pageScript?.printPage) {
            executePageScript(pageScript.printPage);
        } else if (pagePrint?.visible) {
            executePagePrint(pagePrint.visible);
        }
    };

    const executePageScript = (script?: string): void => {
        dispatch(setPageScriptToExecute({ script }));
    };

    const executePagePrint = (execute?: boolean): void => {
        dispatch(setPrintToExecute({ execute }));
    };

    return (
        <Menu {...menuProps} data-testid="flyout-help-menu">
            <MenuItem
                className={classes.menuItem}
                data-testid="contactLink"
                onClick={() => history.push(AppRoutes.Contacts)}>
                {applicationLinkContact}
            </MenuItem>
            <MenuItem
                className={classes.menuItem}
                data-testid="aboutLink"
                onClick={() => history.push(AppRoutes.About)}>
                {applicationLinkAbout}
            </MenuItem>
            {helpMenu.map((menuItem, index) => (
                <MenuItem
                    component="a"
                    href={`${menuItem.screenType === 'legacy' ? legacyContextRoot : ''}${menuItem.url}`}
                    key={index}
                    className={classes.menuItem}
                    data-testid={`help-menu-${menuItem.displayValue}-link`}
                    download>
                    {menuItem.displayValue}
                </MenuItem>
            ))}
            <Divider className={classes.divider} />
            {isPrintAvailable && (
                <MenuItem className={classes.menuItem} data-testid="printLink" onClick={handlePrintClick}>
                    {applicationLinkPrint}
                </MenuItem>
            )}
            {pageScript?.emailPage && (
                <MenuItem
                    className={classes.menuItem}
                    data-testid="emailLink"
                    onClick={(): void => executePageScript(pageScript.emailPage)}>
                    {applicationLinkEmail}
                </MenuItem>
            )}
        </Menu>
    );
});
