/* eslint-disable max-lines-per-function */
import { TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useUUIHistory, useUUILocation } from '../../../hooks';
import { useAppSelector } from '../../../store';
import { getAppResources, getApplicationUrls } from '../../../store/slices';
import ActionDialog from '../../common/actionDialog';
import { dispatchUpdateAndSelectSavedView, dispatchUpdateMetadataSavedView } from '../context/listScreenAsyncActions';
import { useListScreenDispatch, useListScreenState } from '../context/listScreenContext';
import { useRefreshList } from '../context/listScreenHooks';
import { saveTabAction, saveViewIsValid } from '../listScreenActionDialogHelpers';
import { generatePagePostObject, getSavedViewTabName, isEditsApplied, saveCurrentView } from '../listScreenHelpers';
import { ISavedView } from '../types';
import css from './tabOptionsActionDialog.module.scss';

interface ITabOptionsActionDialog {
    tabName: string | '';
    tabId: number;
}

export const TabOptionsActionDialog: React.FC<ITabOptionsActionDialog> = ({ tabName, tabId }) => {
    const applicationUrls = useAppSelector(getApplicationUrls);
    const appResources = useAppSelector(getAppResources);
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const refreshList = useRefreshList();
    const metadata = listScreenState.metadata!;
    const listData = listScreenState.listData!;
    const isEditsAppliedFlag = isEditsApplied(listScreenState.baselinePageData, metadata, listData.page);
    const currentTab = getSavedViewTabName(listData.page.savedView, metadata);
    const [newTabName, setNewTabName] = useState('');
    const [newTabNameError, setNewTabNameError] = useState('');
    const [renameInputIsValid, setRenameInputIsValid] = useState(true);
    const location = useUUILocation();
    const history = useUUIHistory();

    const handleNewTabChange = (event: any) => {
        setNewTabName(event.target.value);
    };

    const saveButtonClickHandler = () => {
        saveTabAction(
            listScreenState,
            listScreenDispatch,
            applicationUrls.applicationSavedViewUrl,
            listScreenState.dataUrl,
            undefined,
            location,
            history,
        );
    };

    const discardButtonClickHandler = () => {
        const baselinePageData = listScreenState.baselinePageData;
        if (baselinePageData) {
            const pageInfo = generatePagePostObject(listData.page, {
                'column-data': baselinePageData['column-data'],
                filters: baselinePageData.filters,
                sortInfo: baselinePageData.sortInfo,
                pageNumber: 1,
                savedView: listData.page.savedView,
            });
            refreshList({ postObject: pageInfo });
        }
    };

    const renameButtonClickHandler = () => {
        const errorValidation = saveViewIsValid(
            metadata,
            appResources,
            'saveAsNewTab',
            newTabName,
            tabId,
            listScreenState.isEmbeddedList,
        );
        const isSaveViewValid = errorValidation === '';
        errorValidation === appResources.dialogContentInputValidation ? setNewTabName('') : null;
        setNewTabNameError(errorValidation);
        setRenameInputIsValid(isSaveViewValid);

        if (!isSaveViewValid) {
            return;
        }

        setNewTabName('');
        setNewTabNameError('');

        const savedViewToUpdate = metadata.savedViews.find((sv) => sv.id === tabId)!;
        const savedViewPost: ISavedView = {
            ...savedViewToUpdate,
            name: newTabName,
        };

        saveCurrentView(
            savedViewPost,
            metadata.screenId,
            listScreenState.dataUrl,
            applicationUrls.applicationSavedViewUrl,
        ).then((json) => {
            const savedView = {
                ...savedViewPost,
                id: json.id,
                name: json.name,
            };
            if (listData.page.savedView.id === savedView.id) {
                dispatchUpdateAndSelectSavedView(listScreenState, listScreenDispatch, savedView, location, history);
            } else {
                dispatchUpdateMetadataSavedView(listScreenState, listScreenDispatch, savedView, location, history);
            }
        });
        listScreenDispatch({ type: 'CloseTabOptionsRenameDialog' });
    };

    return (
        <>
            {isEditsAppliedFlag && currentTab === tabName ? (
                <ActionDialog
                    open={listScreenState.tabOptionsRenameDialogOpen}
                    handleActionClose={() => listScreenDispatch({ type: 'CloseTabOptionsRenameDialog' })}
                    actionButtonClickHandler={saveButtonClickHandler}
                    title={appResources.dialogTitleSaveOrDiscard}
                    actionButtonText={appResources.buttonSave}
                    disableActionButton={false}
                    secondActionButtonText={appResources.buttonDiscard}
                    secondActionButtonClickHandler={discardButtonClickHandler}
                    data-testid="save-discard-dialog">
                    <Typography gutterBottom className={css.content}>
                        {appResources.dialogContentSaveOrDiscardMessage1.replace('{tabName}', tabName)}
                    </Typography>
                    <Typography gutterBottom noWrap>
                        {appResources.dialogContentSaveOrDiscardMessage2}
                    </Typography>
                </ActionDialog>
            ) : (
                <ActionDialog
                    open={listScreenState.tabOptionsRenameDialogOpen}
                    handleActionClose={() => listScreenDispatch({ type: 'CloseTabOptionsRenameDialog' })}
                    actionButtonClickHandler={renameButtonClickHandler}
                    title={appResources.dialogTitleRename.replace('{tabName}', tabName)}
                    actionButtonText={appResources.buttonRename}
                    disableActionButton={false}
                    data-testid="rename-dialog">
                    <Typography gutterBottom noWrap>
                        {appResources.dialogContentRename}
                    </Typography>
                    <TextField
                        InputProps={{ classes: { root: css.saveAsInputControls } }}
                        variant="outlined"
                        value={newTabName}
                        helperText={newTabNameError}
                        onChange={handleNewTabChange}
                        data-testid="rename-dialog-input"
                        FormHelperTextProps={{
                            className: css.renameTabHelperText,
                        }}
                        error={!renameInputIsValid ? true : false}
                    />
                </ActionDialog>
            )}
        </>
    );
};
