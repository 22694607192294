import { ExpandMore } from '@mui/icons-material';
import { Skeleton } from '@mui/lab';
import { Button, ListItemText, Menu } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { head } from 'lodash';
import React, { memo, MouseEvent, PropsWithChildren, useRef, useState } from 'react';
import { FavoriteEntity, FavoriteFolderEntity, FavoriteType } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { TextEllipsis } from '../../../common/textEllipsis';
import { WkFolderOpen, WkShortcut } from '../../../icons';
import { MultiLevelMenuItem } from '../../../menu';

const useStyles = makeStyles<AppTheme>((theme) => ({
    menu: {
        minWidth: 300,
        maxWidth: 450,
        maxHeight: 'calc(100% - 135px)',
    },
    skeleton: {
        color: 'transparent',
        whiteSpace: 'nowrap',
    },
    listItemText: {
        color: theme.palette.primary.dark,
        margin: 0,
    },
    buttonLabel: {
        ...theme.styles.ellipsis,
        display: 'initial',
        maxWidth: '10ch',

        [theme.breakpoints.up('md')]: {
            maxWidth: '17ch',
        },
    },
    buttonStartIcon: {
        marginLeft: theme.spacing(-0.25),
    },
    buttonEndIcon: {
        marginLeft: 0,
    },
    menuTitle: {
        wordBreak: 'break-word',
        display: 'inline-block',
        boxSizing: 'border-box',
        whiteSpace: 'pre-line',
        '&:focus': {
            outline: 'none',
        },
    },
    menuStartIcon: {
        color: theme.palette.primary.dark,
        marginRight: 4,
    },
    caretIcon: {
        color: theme.palette.primary.dark,
        marginLeft: theme.spacing(1.5),
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(180deg)',
    },
}));

export type DynamicMenuItemProps = PropsWithChildren<{
    classes?: {
        button?: string;
        buttonLabel?: string;
        menu?: string;
    };
    item: FavoriteEntity;
    onMenuClick?: (item: FavoriteEntity) => void;
    showIcon?: boolean;
    // todo: remove
    inProgress?: boolean;
}>;

// eslint-disable-next-line max-lines-per-function
export const FavoritesMenuItem = memo(function FavoritesMenuItemComponent(props: DynamicMenuItemProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const [expanded, setExpanded] = useState(new Set<FavoriteFolderEntity['id']>());
    const { item, onMenuClick, classes: externalClasses = {}, inProgress, showIcon } = props;
    const itemsRef = useRef(new Map<FavoriteEntity['id'] | undefined, HTMLLIElement | null>());

    const openMenu = (event: MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const onOpened = (): void => {
        if (item.type === FavoriteType.Folder) {
            const id = head(item.children)?.id;
            const itemEl = itemsRef.current.get(id);
            // TODO: check why it does not focus until keyboard nav is used,
            //  and then why it starts working for consequent mouse interactions
            itemEl?.focus();
        }
    };

    const closeMenu = (): void => {
        setAnchorEl(undefined);
    };

    const toggleMenu = ({ id }: FavoriteFolderEntity): void => {
        if (expanded.has(id)) {
            expanded.delete(id);
        } else {
            expanded.add(id);
        }

        setExpanded(new Set(expanded));
    };

    const handleMenuSelection = (item: FavoriteEntity): void => {
        if (item.type === FavoriteType.Folder && item.children?.length) {
            toggleMenu(item);
        } else {
            closeMenu();
        }

        onMenuClick?.(item);
    };

    const renderButton = (props: {
        onClick: (event: MouseEvent<HTMLButtonElement>) => void;
        item: FavoriteEntity;
        expandable?: boolean;
    }) => {
        const { item, expandable, onClick } = props;
        const content = (
            <TextEllipsis
                text={item.name}
                placement="bottom"
                classes={{ content: classNames(classes.buttonLabel, externalClasses.buttonLabel) }}
            />
        );

        return (
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={onClick}
                classes={{
                    root: externalClasses?.button,
                    startIcon: classes.buttonStartIcon,
                    endIcon: classes.buttonEndIcon,
                }}
                color="primary"
                startIcon={
                    showIcon &&
                    ((item.type === FavoriteType.Folder && <WkFolderOpen />) ||
                        (item.type === FavoriteType.Shortcut && <WkShortcut />))
                }
                endIcon={expandable && <ExpandMore />}
                disabled={inProgress}
                disableRipple>
                <>
                    {inProgress && <Skeleton className={classes.skeleton}>{content}</Skeleton>}
                    {!inProgress && content}
                </>
            </Button>
        );
    };

    const renderMenu = () => (
        <>
            {renderButton({ onClick: openMenu, item, expandable: true })}
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                variant={'menu'}
                TransitionProps={{
                    onEntered: () => onOpened(),
                }}
                classes={{
                    paper: classNames(classes.menu, externalClasses?.menu),
                }}>
                {item.type === FavoriteType.Folder &&
                    item.children?.map((childItem) => (
                        <MultiLevelMenuItem
                            key={childItem.id}
                            ref={(instance) => itemsRef.current.set(childItem.id, instance)}
                            getState={(item) => ({
                                id: item.id,
                                displayValue: item.name,
                                expanded: item.type === FavoriteType.Folder && expanded.has(item.id),
                                children: item.type === FavoriteType.Folder ? item.children : undefined,
                            })}
                            handleMenuSelection={handleMenuSelection}
                            item={childItem}
                            renderListItem={(item, { expanded }) => (
                                <>
                                    {item.type === FavoriteType.Folder && (
                                        <WkFolderOpen classes={{ root: classes.menuStartIcon }} />
                                    )}
                                    {item.type === FavoriteType.Shortcut && (
                                        <WkShortcut classes={{ root: classes.menuStartIcon }} />
                                    )}
                                    <ListItemText
                                        primary={item.name}
                                        classes={{
                                            root: classes.listItemText,
                                            primary: classes.menuTitle,
                                        }}
                                        data-testid={`${item.id}-menu-item`}
                                    />
                                    {item.type === FavoriteType.Folder && Boolean(item.children?.length) && (
                                        <ExpandMore
                                            classes={{
                                                root: classNames(classes.caretIcon, {
                                                    [classes.expanded]: expanded,
                                                }),
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        />
                    ))}
            </Menu>
        </>
    );

    return item.type === FavoriteType.Folder && item.children?.length
        ? renderMenu()
        : renderButton({
              onClick: () => handleMenuSelection(item),
              item,
          });
});
