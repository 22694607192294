import 'xss';
import { config } from './config.service';

declare global {
    interface Window {
        trustedTypes: {
            createPolicy: (type: string, options: object) => void;
        };
    }
}

// TODO: this makes trusted-types CSP almost useless, need to fix issues on T360 side
//  e.g. https://github.com/jquery/sizzle/pull/479
if (window.trustedTypes && window.trustedTypes.createPolicy) {
    window.trustedTypes.createPolicy('default', {
        createHTML: (str: string) => {
            const nonce = config.get('REACT_APP_CSP_NONCE');
            const hashCode = str.split('').reduce((a, b) => {
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                a = (a << 5) - a + b.charCodeAt(0);
                return a & a;
            }, 0);
            const allowedScriptsHash = [
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                -1326695601, // browser-sync
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                -1197612882, // browser-sync
            ];

            return allowedScriptsHash.includes(hashCode) || str.includes(nonce) ? str : filterXSS(str);
        },
        createScriptURL: (src: string) => src, // warning: this is unsafe!
        createScript: (src: string) => src, // warning: this is unsafe!
    });
}
