export interface UUIFetchInitialization {
    interceptResponse?: (response: Response) => void | Promise<void>;
    handleNetworkFailure?: () => void | Promise<void>;
}

let _initializationObject: UUIFetchInitialization;
export const UUIFetch = {
    initialize: (initializationObject: UUIFetchInitialization): void => {
        _initializationObject = initializationObject;
    },
    fetch: async (urlOrRequestObj: RequestInfo, fetchOptions?: RequestInit): Promise<Response> => {
        try {
            const response = await fetch(urlOrRequestObj, fetchOptions);
            await _initializationObject.interceptResponse?.(response.clone());
            return response;
        } catch (err) {
            await _initializationObject.handleNetworkFailure?.();
            throw err;
        }
    },
    fetchExternalApi: async (urlOrRequestObj: RequestInfo, fetchOptions?: RequestInit): Promise<Response> => {
        try {
            return await fetch(urlOrRequestObj, fetchOptions);
        } catch (err) {
            throw err;
        }
    },
    triggerNetworkDownHandler: (): void => {
        void _initializationObject.handleNetworkFailure?.();
    },
};