import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';

export const WkClear: React.FC<SvgIconProps> = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon data-testid="wkClear" viewBox="0 0 14 14" {...props} ref={ref}>
        <path
            d="M13.8738 1.20503L12.8323 0.167969L7.3761 5.62997L1.91062 0.167969L0.878418 1.20503L6.34018 6.66611L0.878418 12.13L1.91433 13.1652L7.3761 7.70411L7.54968 7.87587L12.8406 13.168L13.8784 12.1328L8.41387 6.67075L13.8738 1.20503Z"
            fill="currentColor"
        />
    </SvgIcon>
));

WkClear.displayName = 'WkClear';
