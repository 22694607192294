import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkPin = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkFolderOpen" viewBox="-2 -2 20 20" {...props}>
        <g id="Pin" transform="translate(-809.998 -1513)">
            <rect id="Rectangle_2775" x="810" y="1513" fill="none" width="16" height="16" />
            <g id="Icon_Open_tack" transform="translate(811.998 1513)">
                <path
                    id="Color"
                    fill="currentColor"
                    d="M6.2,16H5.1v-5.3H0c0.1-1.5,1-2.7,2.3-3.4V2.1c0-1.2,1-2.2,2.2-2.1c0,0,0,0,0,0h2.3
C8,0,9,0.9,9,2.1c0,0,0,0,0,0v5.1c1.3,0.7,2.2,1.9,2.3,3.4H6.2V16z M4.5,1.1c-0.6,0-1.1,0.4-1.1,1c0,0,0,0,0,0v5.7
c-0.8,0.3-1.5,0.9-2,1.7h8.5c-0.4-0.8-1.1-1.4-2-1.7V2.1c0-0.6-0.5-1.1-1.1-1.1c0,0,0,0,0,0H4.5z"
                />
            </g>
        </g>
    </SvgIcon>
);
