/* eslint-disable @typescript-eslint/no-magic-numbers */
import { AppTheme, StyleProps } from '../useAppTheme.hook';

export const page = (theme: AppTheme) => {
    const root: StyleProps = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(0, 2),
        backgroundColor: theme.palette.background.secondary,
    };

    const header: StyleProps = {
        padding: theme.spacing(1.5, 0),
    };

    const content: StyleProps = {
        ...theme.typography.body1,
        flex: 1,
        display: 'flex',
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
    };

    return { root, header, content };
};
