import { IDocTransformer } from '../../interfaces/transformer/type';
import { IActionableEntity } from '../../interfaces/actionEntity/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc } from '../../types';
import { T360AdapterAddDocumentActionableEntity } from '../actionableEntity/addDocumentActionableEntity';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { IAddDocument } from '../../docManager';
import { IAddDocument_T360 } from '../types';
import { removeDashes } from '../../utils/string.utils';

const log = factory.getLogger('T360AdapterAddDocumentEntityTransformer');

@injectable()
export class T360AdapterAddDocumentEntityTransformer implements IDocTransformer {
    transformIntoActionableEntity(
        nodeObj: IAddDocument,
        _matterDoc: IMatterDoc,
        _urlPattern: IDocOperationInvocation,
    ): IActionableEntity {
        log.debug('Calling transformIntoActionableEntity');

        let obj = {} as T360AdapterAddDocumentActionableEntity;
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc

        const nodeObj1 = nodeObj as IAddDocument_T360;
        obj = nodeObj.extendedProps as T360AdapterAddDocumentActionableEntity;

        if (nodeObj1.documentName) {
            obj.documentName = nodeObj1.name;
        }
        if (nodeObj1.documentType) {
            obj.documentType = nodeObj1.documentType;
        }
        if (nodeObj1.file) {
            obj.file = nodeObj1.file;
        }
        if (nodeObj1.editAccessType) {
            obj.editAccessType = nodeObj1.editAccessType;
        }

        if (nodeObj1.viewAccessType) {
            obj.viewAccessType = nodeObj1.viewAccessType;
        }
        if (nodeObj1?.extendedProps?.description) {
            obj.description = nodeObj.extendedProps.description;
        }
        if (nodeObj1?.extendedProps?.admittedMembers) {
            obj.admittedMembers = nodeObj1.extendedProps.admittedMembers;
        }

        if (nodeObj1.documentFolderId) {
            obj.documentFolderId = nodeObj1.documentFolderId;
        }
        if (nodeObj1?.extendedProps?.uploadGuid) {
            obj.uploadGuid = removeDashes(nodeObj1.extendedProps.uploadGuid);
        }

        if (obj.file && obj.file instanceof Buffer) {
            obj.contentType = 'text/plain';
        } else if (obj.file) {
            obj.contentType = obj.file.type;
        }

        return obj;
    }
}
