import { AppTheme, StyleProps } from '../useAppTheme.hook';

const outline = ({ color = 'currentColor' }): StyleProps => ({
    outlineColor: color,
    outlineStyle: 'solid',
    outlineWidth: 2,
    outlineOffset: -2,
});

const focus = ({ color = 'currentColor' }): StyleProps => ({
    ...outline({ color }),
    backgroundColor: 'inherit',
});

const ellipsis: StyleProps = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
};

const divider = (theme: AppTheme): StyleProps => ({
    marginBottom: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
});

export const common = {
    outline,
    focus,
    ellipsis,
    divider,
};
