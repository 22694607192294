import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { memo, PropsWithChildren } from 'react';
import { IMenuItem } from '../../../reducers/types';
import { AppTheme } from '../../app';
import { DynamicMenuItem } from './dynamicMenuItem';

const useStyles = makeStyles<AppTheme>((theme) => ({
    holder: {
        fontSize: theme.typography.fontSize,
    },
}));

export type DynamicMenuProps = PropsWithChildren<{
    classes?: {
        root?: string;
        button?: string;
        menu?: string;
    };
    items: IMenuItem[];
    onMenuClick?: (item: IMenuItem) => void;
    inProgress?: boolean;
}>;

export const DynamicMenu = memo(function DynamicMenuComponent(props: DynamicMenuProps) {
    const { items, onMenuClick, classes: externalClasses, inProgress } = props;
    const classes = useStyles();

    return (
        <div className={classNames(classes.className, externalClasses?.root)}>
            {items.map((item) => (
                <DynamicMenuItem
                    key={item.id}
                    classes={externalClasses}
                    item={item}
                    onMenuClick={onMenuClick}
                    inProgress={inProgress}
                />
            ))}
        </div>
    );
});
