import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const wkUndoCheckOutIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkUndoCheckOutIcon" viewBox="0 0 24 24" {...props}>
        <path
            d="M14.9,3l-0.7-0.7l-1.1,1L9.8,0H2.1v14.3l-0.9,0.9L1.9,16l0.6-0.6h2.9l0,0l-1.1-1.2H3.7l1.1-1.1L7,15.3L9.4,13
	l-0.7-0.7l-1.1,1.1v-3l4.8-4.8v8.6H9.5l-1,1.2h5.1v-11L14.9,3z M9.7,1.9l2,2h-2V1.9z M6.5,13.3l-1-0.9l1-1V13.3z M6.5,7v3L3.3,13
	V1.3h5.2v3.8h2.7L7.6,8.8V7H6.5z"
        />
    </SvgIcon>
);

export default wkUndoCheckOutIcon;
