import { ExpandMore } from '@mui/icons-material';
import { Skeleton } from '@mui/lab';
import { Button, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { memo, MouseEvent, PropsWithChildren, useEffect, useState } from 'react';
import { useAppSelector } from '../../../store';
import { FavoriteEntity, FavoriteType } from '../../../store/slices';
import { AppTheme } from '../../app';

export enum OpenDialogType {
    CreateFolder = 'createFolder',
    Move = 'move',
    Rename = 'rename',
    Remove = 'remove',
}

const useStyles = makeStyles<AppTheme, { mainMenuExpanded?: boolean }>((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row-reverse',
        gap: theme.spacing(2),
    },
    menu: {
        color: theme.palette.primary.dark,
    },
    button: {
        display: 'flex',
    },
    buttonIcon: ({ mainMenuExpanded }) => ({
        display: mainMenuExpanded ? 'none' : 'inherit',

        [theme.breakpoints.up('lg')]: {
            display: 'inherit',
        },
    }),
    skeleton: {
        color: 'transparent',
        whiteSpace: 'nowrap',
    },
    menuButtonIcon: {
        marginLeft: 0,
    },
}));

export type FavoritesActionsMenuProps = PropsWithChildren<{
    classes?: {
        root?: string;
        button?: string;
        menuButton?: string;
        menu?: string;
    };
    item?: FavoriteEntity;
    onAction: (type: OpenDialogType) => void;
    // TODO: remove
    inProgress?: boolean;
}>;

// eslint-disable-next-line max-lines-per-function
export const FavoritesActionsMenu = memo(function FavoritesMenuItemComponent(props: FavoritesActionsMenuProps) {
    const { mainMenuExpanded } = useAppSelector((state) => state.ui.persistState);
    const classes = useStyles({ mainMenuExpanded });
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const { onAction, classes: externalClasses = {}, inProgress, item } = props;
    const isMdSize = useMediaQuery<AppTheme>((theme) => theme.breakpoints.up('md'));

    useEffect(() => {
        if (isMdSize) {
            closeMenu();
        }
    }, [isMdSize]);

    const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(undefined);
    };

    const onClick = (type: OpenDialogType) => {
        closeMenu();
        onAction(type);
    };

    const renderMenuButton = () => {
        const content = <span>Actions</span>;

        return (
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={openMenu}
                classes={{
                    root: classNames(externalClasses?.menuButton),
                    endIcon: classes.menuButtonIcon,
                }}
                variant="outlined"
                color="primary"
                endIcon={<ExpandMore />}
                disabled={inProgress}
                disableRipple>
                <>
                    {inProgress && <Skeleton className={classes.skeleton}>{content}</Skeleton>}
                    {!inProgress && content}
                </>
            </Button>
        );
    };

    const renderCollapsedMenu = () => (
        <>
            {renderMenuButton()}
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                variant="menu"
                classes={{ paper: classNames(classes.menu, externalClasses?.menu) }}>
                <MenuItem
                    classes={{ root: classes.menuItemRoot }}
                    onClick={() => onClick(OpenDialogType.CreateFolder)}
                    disabled={item?.type !== FavoriteType.Folder}>
                    Create Folder
                </MenuItem>
                <MenuItem
                    classes={{ root: classes.menuItemRoot }}
                    onClick={() => onClick(OpenDialogType.Remove)}
                    disabled={!item?.parentId && item?.type !== FavoriteType.Shortcut}>
                    Delete
                </MenuItem>
                <MenuItem
                    classes={{ root: classes.menuItemRoot }}
                    onClick={() => onClick(OpenDialogType.Move)}
                    disabled={!item?.parentId || item?.type === FavoriteType.Shortcut}>
                    Move
                </MenuItem>
                <MenuItem
                    classes={{ root: classes.menuItemRoot }}
                    onClick={() => onClick(OpenDialogType.Rename)}
                    disabled={!item?.parentId || item?.type === FavoriteType.Shortcut}>
                    Rename
                </MenuItem>
            </Menu>
        </>
    );

    const renderActionButtons = () => (
        <>
            <Button
                variant="contained"
                color="primary"
                classes={{ root: classNames(classes.button, externalClasses.button) }}
                onClick={() => onClick(OpenDialogType.CreateFolder)}
                disabled={item?.type !== FavoriteType.Folder}
                disableRipple>
                Create Folder
            </Button>
            <Button
                variant="outlined"
                color="primary"
                classes={{ root: classNames(classes.button, externalClasses.button) }}
                onClick={() => onClick(OpenDialogType.Remove)}
                disabled={!item?.parentId && item?.type !== FavoriteType.Shortcut}
                disableRipple>
                Delete
            </Button>
            <Button
                variant="outlined"
                color="primary"
                classes={{ root: classNames(classes.button, externalClasses.button) }}
                onClick={() => onClick(OpenDialogType.Move)}
                disabled={!item?.parentId || item?.type === FavoriteType.Shortcut}
                disableRipple>
                Move
            </Button>
            <Button
                variant="outlined"
                color="primary"
                classes={{ root: classNames(classes.button, externalClasses.button) }}
                onClick={() => onClick(OpenDialogType.Rename)}
                disabled={!item?.parentId || item?.type === FavoriteType.Shortcut}
                disableRipple>
                Rename
            </Button>
        </>
    );

    return (
        <div className={classNames(classes.root)}>
            {isMdSize && renderActionButtons()}
            {!isMdSize && renderCollapsedMenu()}
        </div>
    );
});
