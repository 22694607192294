import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';

export const WkSort = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon data-testid="wkSortFill" viewBox="-2 -3 12.69 12.67" {...props} ref={ref}>
        <path d="M0.971596 0.5L5 6.13977L9.02841 0.500002L0.971596 0.5Z" stroke="currentColor" />
    </SvgIcon>
));

WkSort.displayName = 'WkSort';
