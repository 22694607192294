import { CHSupportedDocumentTypesEnum } from '../enum/enum';
import { IFieldValue } from '../services/interface';
import { IQueueItem } from '@wk/elm-uui-queuemanager';
import { DCResponse } from '@wk/elm-uui-doc-component';

export interface IContextInputType {
    associatedEntityName?: string;
    associatedEntityId: string;
    associatedEntityType: string;
    documentEntityType?: CHSupportedDocumentTypesEnum;
    entityTypeId?: string;
    associatedEntityTypeId?: string;
}

export interface ICHDocumentInputType extends IContextInputType {
    documentId?: string;
    name?: string;
    fileName?: string;
    folderId?: string;
    loggedInUser?: string;
    folderPath?: string;
}

export interface ICHCheckOut extends IContextInputType {
    documentId: string;
    loggedInUser?: string;

    uniqueIdFromBanner?: string;
}

export interface IAdmittedMembers {
    individualId: string;
    canEdit: boolean;
}

export interface ICHAddFolder extends IContextInputType {
    props?: { admittedMembers?: IAdmittedMembers[] };
    folderId?: string;
    viewAccessType?: string;
    editAccessType?: string;
    payload?: Record<string, IFieldValue>;
    documentId?: string;
}

export interface ICHShowDocument extends IContextInputType {
    documentId: string;
    documentName?: string;
    documentFileName?: string;
    loggedInUser: string;
    version?: string;
}

// Input interface for the DeleteEmail
export interface ICHDeleteEmail extends IContextInputType {
    documentId: string;
}

export interface ICHEditDocument extends IContextInputType {
    props?: { admittedMembers?: IAdmittedMembers[] };
    documentId: string;
    viewAccessType?: string;
    editAccessType?: string;
    name?: string;
    description?: string;
    payload?: Record<string, IFieldValue>;
}

// Input interface for the AddEmail
export interface ICHAddEmail extends IContextInputType {
    name?: string;
    data?: string;
    folderPath?: string;
    file?: Buffer | Blob | File;
    folderId?: string;
}
export interface ICHUploadType extends ICHDocumentInputType {
    props?: {
        comments?: string;
        contentType?: string;
        keepCheckedOut?: false;
        description?: string;
        isFolder?: string;
        isEmailMessage?: string;
        senderEmail?: string;
        sentTime?: string;
        senderName?: string;
        receivedTime?: string;
        emailRecipients?: string;
        emailSubject?: string;
        emailBody?: string;
        isAttachmentPresent?: string;
        uniqueId?: string;
    };
    data?: string;
    fileId?: string;
    filePaths?: Array<string>;
    payload?: Record<string, IFieldValue>;
    file?: Buffer | Blob | File;
    isQuickFile?: boolean;
    uniqueId?: string;
    isClose?: boolean;
    isDragAndDrop?: boolean;
    uploadGuid?: string;
    folderArr?: string[];
    groupId?: string;
    noOfItems?: number;
    uploadTime?: number;
    existingDocumentName?: string;
    isFlyoutNotified?: boolean;
    isSaveOrOverwrite?: boolean;
}

export interface ICHDeleteDocument extends IContextInputType {
    documentId: string;
    loggedInUser?: string;
}

export interface ICHRenameFolder extends IContextInputType {
    props: { admittedMembers?: IAdmittedMembers[] };
    documentId: string;
    name: string;
    viewAccessType?: string;
    editAccessType?: string;
    folderPath?: string;
}

export interface ICHRenameDocument extends IContextInputType {
    props: { admittedMembers?: IAdmittedMembers[] };
    documentId: string;
    name: string;
    viewAccessType?: string;
    editAccessType?: string;
    folderPath?: string;
    data?: string;
}

export interface ICHEditFolder extends IContextInputType {
    props?: { admittedMembers?: IAdmittedMembers[] };
    folderId?: string;
    name?: string;
    viewAccessType?: string;
    editAccessType?: string;
    payload?: Record<string, IFieldValue>;
}

export interface ICHDeleteFolder extends IContextInputType {
    documentId: string;
    loggedInUser?: string;
}

export interface ICHDownloadDocument extends IContextInputType {
    documentId?: string;
    loggedInUser?: string;
    version?: string;
    uniqueIdFromBanner?: string;
}

export interface ICHCancelCheckout extends IContextInputType {
    documentId: string;
    loggedInUser?: string;
    uniqueIdFromBanner?: string;
}

export interface ICHDocumentMetadata extends IContextInputType {
    version?: string;
    documentId?: string;
    loggedInUser?: string;
    folderId?: string;
}

export interface ICHDocumentMetadataFileName extends IContextInputType {
    documentId?: string;
    name?: string;
    fileName?: string;
    version?: string;
    folderId?: string;
    loggedInUser?: string;
}
export interface ICHScreenLaunchData {
    entityTye: string;
    documentType: string;
    docOperation: string;
    operationTrigger: string;
}

export interface ICHQuickFileIndicator {
    enableQuickFile: boolean;
    entityType: CHSupportedDocumentTypesEnum;
}

export interface ICHUploadResponse extends IResponse {
    queueItem?: IQueueItem;
}
export interface IResponse {
    response?: DCResponse;
    responseText?: string;
}

export type ICHResponse = Response | string | IResponse;

export enum IPropKeys {
    autoUpdateServerURL = 'autoUpdateURL',
    currentVersion = 'currentVersion',
}

export interface ICHProps {
    props: Record<IPropKeys, string>;
}

export type ICHDownloadDocMetaType = ICHDownloadDocument & ICHDocumentMetadata;
export type ICHCheckoutDocMetaType = ICHCheckOut & ICHDocumentMetadata;
export type ICHCancelCheckoutDocMetaType = ICHCancelCheckout & ICHDocumentMetadata;
export type ICHDeleteDocMetaType = ICHDeleteDocument & ICHDocumentMetadata;

export interface FileUploadControl {
    fileUploadControl: HTMLInputElement;
    fileUploadControlId: string;
}

export interface ICHHelpUrlResponse {
    helpDocumentUrl: string;
    accesstoken?: string;
}

export interface ICHValidateAddDocument extends IContextInputType {
    name?: string;
    documentFileName?: string;
    viewAccessType?: string;
    editAccessType?: string;
    comments?: string;
    document_folder_name?: string;
    default?: string;
    document_original_type?: string;
}

export interface ICHValidateCheckInDocument extends IContextInputType {
    name?: string;
    document_original_type?: string;
    documentFileName?: string;
    docId?: string;
    comments?: string;
    default?: string;
}
export interface IValidateAddDocument {
    documentName?: string;
    documentFileName?: string;
    name?: string;
    viewAccessType?: string;
    editAccessType?: string;
    documentFolderId?: string;
    description?: string;
    default?: string;
    documentType?: string;
}

export interface IValidateCheckInDocument {
    documentName?: string;
    documentType?: string;
    name?: string;
    documentFileName?: string;
    documentId?: string;
    comment?: string;
    default?: string;
}
export interface ICHValidateEditDocument extends IContextInputType {
    nameOnly?: string;
    comments?: string;
    editAccessType?: string;
    viewAccessType?: string;
    documentId?: string;
    default?: string;
}

export interface IValidateEditDocument {
    nameOnly?: string;
    editAccessType?: string;
    viewAccessType?: string;
    docId?: string;
    description?: string;
    default?: string;
}
export interface ICHValidateEditFolder extends IContextInputType {
    folder_edit_access_type_name?: string;
    folder_view_access_type_name?: string;
    document_folder_name?: string;
    folderId?: string;
    default?: string;
}

export interface IValidateEditFolder {
    editAccessType?: string;
    viewAccessType?: string;
    name?: string;
    documentFolderId?: string;
    default?: string;
}
export interface IValidateAddFolder {
    admittedMembers?: string;
    name?: string;
    documentFolderId: string;
    editAccessType: string;
    viewAccessType: string;
    default?: string;
}

export interface ICHValidateAddFolder extends IContextInputType {
    folderId?: string;
    document_folder_name?: string;
    documentFolderId: string;
    folder_edit_access_type_name: string;
    folder_view_access_type_name: string;
    admittedMembers: string;
    default?: string;
}
