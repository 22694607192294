import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkFolderOpen = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkFolderOpen" viewBox="-2 0 20 15" {...props}>
        <path
            id="Color"
            d="M13.866,13.866H0V0H6.4L7.466,1.067h6.4V3.2H16L13.867,13.866ZM3,4.267,1.333,12.8h11.6l1.733-8.533Zm-1.933-3.2V8.533L2.134,3.2H12.8V2.134H7L5.933,1.067Z"
            fill="currentColor"
        />
    </SvgIcon>
);
