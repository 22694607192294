import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Button, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { forwardRef, ForwardRefExoticComponent, HTMLAttributes, MouseEventHandler, ReactElement } from 'react';
import { AppTheme } from '../../../app';
import { Tooltip } from '../../../common';

const useStyles = makeStyles<AppTheme>((theme) => ({
    container: {
        position: 'relative',
        height: 32,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: theme.typography.subtitle1.fontSize,
        backgroundColor: 'transparent',
        padding: theme.spacing(0.75),
        fontFamily: 'inherit',
        minWidth: 'auto',

        '&, &:hover, &:focus': {
            color: theme.palette.common.white,
            textDecoration: 'none',
        },

        '&:hover': {
            backgroundColor: theme.palette.view.light,
        },
    },
    active: {
        backgroundColor: theme.palette.view.light,
    },
    startIcon: {
        margin: theme.spacing(0),
    },
    endIcon: {
        margin: theme.spacing(0, 0, 0, 0.5),
    },
    name: {
        fontSize: '0.875em',
        lineHeight: '20px',
        maxWidth: '17ch',
        ...theme.styles.ellipsis,
        marginLeft: theme.spacing(0.5),
    },
    '@media (max-width: 1024px)': {
        name: {
            display: 'none',
        },
    },
    arrow: {
        width: 10,
        height: 16,
    },
}));

export type ItemIconProps = {
    className?: string;
    viewBox?: string;
};

type ItemPropsForTest = {
    itemTestId?: string;
    itemIconTestId?: string;
};

export type ItemProps = {
    /**
     * Override the styles of toolbar item
     */
    classes?: {
        name?: string;
    };
    /**
     * Name of toolbar item.
     */
    name?: string;

    /**
     * Icon of toolbar item.
     */
    icon:
        | OverridableComponent<SvgIconTypeMap>
        | ((props: ItemIconProps) => ReactElement)
        | ForwardRefExoticComponent<ItemIconProps>;

    /**
     * Title for item icon.
     */
    iconTitle?: string;

    /**
     * Value indicating whether the component renders an arrow.
     */
    arrowed?: boolean;

    /**
     * Title for arrow icon.
     */
    arrowTitle?: string;

    /**
     * Value indicating whether the component is active.
     */
    active?: boolean;

    /**
     * Event handler that fires when a toolbar item is clicked.
     */
    onClick?: MouseEventHandler<HTMLElement>;
} & Pick<HTMLAttributes<HTMLElement>, 'id' | 'style'> &
    ItemPropsForTest;

export const Item = forwardRef<HTMLButtonElement, ItemProps>((props, ref) => {
    const {
        id,
        style,
        name,
        iconTitle = '',
        arrowTitle = '',
        icon: Icon,
        arrowed,
        active,
        itemTestId,
        itemIconTestId,
        onClick,
        classes: externalClasses,
    } = props;

    const classes = useStyles();

    return (
        <Button
            ref={ref}
            id={id}
            classes={{
                root: classNames(classes.container, { [classes.active]: active }),
                startIcon: classes.startIcon,
                endIcon: classes.endIcon,
            }}
            data-testid={itemTestId}
            onClick={onClick}
            style={style}
            disableRipple
            aria-label={`appBarButton ${iconTitle}`}
            startIcon={
                <Tooltip title={iconTitle} placement="bottom-end">
                    <Icon data-testid={itemIconTestId} />
                </Tooltip>
            }
            endIcon={
                arrowed && (
                    <Tooltip title={arrowTitle} placement="bottom-end">
                        <ArrowDropDown className={classes.arrow} viewBox="7 10 10 5" />
                    </Tooltip>
                )
            }>
            {name && <div className={classNames(classes.name, externalClasses?.name)}>{name}</div>}
        </Button>
    );
});
Item.displayName = 'Item';
Item.defaultProps = {
    arrowed: false,
    active: false,
};
