import CheckIcon from '@mui/icons-material/Check';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import React from 'react';
import TransferBasketPanel from './transferBasketPanel';
import css from './transferBasketTreeView.module.scss';
import { ITransferBasketItem } from './types';

export interface ITransferBasketTreeView {
    items: ITransferBasketItem[];
    onDoubleClickItem: (item: ITransferBasketItem) => void;
    onSelectItem: (item: ITransferBasketItem) => void;
    onToggleItem?: (treeItemClicked: ITransferBasketItem, expanded: boolean) => void;
    onBlur?: (e: React.FocusEvent) => void;
    fullHeight?: boolean;
}

const TransferBasketTreeView: React.FC<ITransferBasketTreeView> = ({
    items,
    onSelectItem,
    onToggleItem,
    onDoubleClickItem,
    onBlur,
    fullHeight,
}) => {
    const onNodeToggle = (nodeId: string, expanded: boolean) => {
        if (onToggleItem) {
            onToggleItem(items.find((td) => td.id.toString() === nodeId)!, expanded);
        }
    };

    return (
        <TransferBasketPanel fullHeight={fullHeight}>
            <TreeView
                onBlur={onBlur}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeToggle={(event, nodeIds) => {
                    const nodeIdClicked = (event.currentTarget as HTMLElement)
                        .querySelector('[data-nodeid]')!
                        .getAttribute('data-nodeid');
                    const expanded = nodeIds.indexOf(nodeIdClicked!) > -1;
                    onNodeToggle(nodeIdClicked!, expanded);
                    return false;
                }}>
                <TreeViewItems
                    allTreeData={items}
                    leafTreeData={items.filter((item) => !item.parentId)}
                    onSelect={onSelectItem}
                    onDoubleClick={onDoubleClickItem}
                />
            </TreeView>
        </TransferBasketPanel>
    );
};

interface ITreeViewItemsProps {
    allTreeData: ITransferBasketItem[];
    leafTreeData: ITransferBasketItem[];
    onSelect: (item: ITransferBasketItem) => void;
    onDoubleClick: (item: ITransferBasketItem) => void;
}

const TreeViewItems: React.FC<ITreeViewItemsProps> = ({ allTreeData, leafTreeData, onSelect, onDoubleClick }) => {
    const getDisplayValue = (treeData: ITransferBasketItem) => (
        <div data-nodeid={treeData.id}>
            {treeData.availableDisplayValue}
            {treeData.isSelected && treeData.availableDisplayValue !== treeData.selectedDisplayValue && (
                <span className={css.selectedDisplayValue}>({treeData.selectedDisplayValue})</span>
            )}
        </div>
    );

    return (
        <>
            {leafTreeData.map((td) => (
                <TreeItem
                    classes={{
                        root: css.treeItem,
                        iconContainer: css.iconContainer,
                        content: `${css.treeItemContent} ${
                            td.isSelected && !td.hasChildren ? css.selectedTreeItem : ''
                        }`,
                        label: css.treeItemLabel,
                        selected: css.currentlySelectedTreeItem,
                    }}
                    key={td.id}
                    nodeId={td.id.toString()}
                    label={
                        <>
                            {td.isSelected && (
                                <div data-testid="checkIcon" className={css.checkIconWrapper}>
                                    <CheckIcon className={css.checkIcon} />
                                </div>
                            )}
                            {getDisplayValue(td)}
                        </>
                    }
                    onClick={(e) => {
                        onSelect(td);
                        e.stopPropagation();
                    }}
                    onDoubleClick={(e) => {
                        if (!td.hasChildren) {
                            onDoubleClick(td);
                        }
                        e.stopPropagation();
                    }}>
                    {td.hasChildren ? (
                        allTreeData.filter((atd) => atd.parentId === td.id).length == 0 ? (
                            <TreeItem
                                classes={{ root: css.treeItem, content: css.treeItemContent, label: css.treeItemLabel }}
                                key={`${td.id}_loading`}
                                nodeId={`${td.id}_loading`}
                                label="Loading..."></TreeItem>
                        ) : (
                            <TreeViewItems
                                onSelect={onSelect}
                                allTreeData={allTreeData}
                                leafTreeData={allTreeData.filter((atd) => atd.parentId === td.id)}
                                onDoubleClick={onDoubleClick}
                            />
                        )
                    ) : null}
                </TreeItem>
            ))}
        </>
    );
};

export default TransferBasketTreeView;
