import { UUIFetch, getLogger } from '@wk/elm-uui-common';
import { EventType, publish, subscribe } from '@wk/elm-uui-context-handler';
import { config } from '../config';
import { getUserManager, logOutPrimary } from './oidc';

let isOidcDown = false;
let clientClockSkew:any = undefined;
const getLog = () => getLogger('t360.connectivityService');

export class ConnectivityService {
    static heartBeatFunction = async () => {
        await poll(async () => {
            getLog().debug('Starting polling attempt.');
            const pollApi = pollUrl(`${config.get('REACT_APP_API_URL')}/health`, 'Healthy');
            const pollIdS = pollUrl(`${config.get('REACT_APP_IDENTITY_URL')}/health`, 'Healthy');

            const apiAvailable = await pollApi;
            const idsAvailable = await pollIdS;
            getLog().debug(`API available: ${String(apiAvailable)}`);
            getLog().debug(`Identity Server available: ${String(idsAvailable)}`);

            return apiAvailable && idsAvailable;
        }, config.get('REACT_APP_CONNECTIVITY_POLLING_INTERVAL'));
    };
 
    static clientTimeDifference():any  {
        if(clientClockSkew==undefined)
        {
            try
            {
                var request = new XMLHttpRequest();
                request.open('GET', `${config.get('REACT_APP_IDENTITY_URL')}/health`, false);
                request.send();
                if(request.status == 200)
                {
                    const dateValue = request.getResponseHeader('Date');
                    if(dateValue!=null)
                    {
                        const serverTime:Date = new Date(dateValue);
                        const defaultClockSkew:number = 5;
                        const actualLocalTime:Date = new Date();
                        const timeDifference: number = actualLocalTime.getTime() - serverTime.getTime();
                        const skewMinutes = Math.floor((Math.abs(timeDifference)/1000)/60);
                        clientClockSkew = skewMinutes + defaultClockSkew;
                        return (clientClockSkew) * 60;
                    }
                }
            }
            catch(err){
                console.log("Error calculating clockSkew ",err);
            }
        }
        else
        {
            return clientClockSkew;
        }
    }

    static async subscribeToUnauthorizedEvent() {
        // Subscribing to 401 events. Need to sign out user
        subscribe(EventType.UNAUTHORIZED_ACCESS_ATTEMPT, async () => {
            getLog().debug('Received UNAUTHORIZED_ACCESS_ATTEMPT event. Logging out.');
            logOutPrimary();
        });
    }

    static async fireUnauthorizedEvent() {
        getLog().debug('Firing UNAUTHORIZED_ACCESS_ATTEMPT event.');
        publish({ name: EventType.UNAUTHORIZED_ACCESS_ATTEMPT });
    }

    static async handleOidcNetworkError(error: Error) {
        getLog().debug('Handling OIDC network error.');

        if (isOidcDown) {
            getLog().debug('OIDC network error already handled. Bypassing.');
            return true;
        }

        if (error.message && (error.message === 'Network Error' || error.message.indexOf('(503)') > -1)) {
            getLog().warn('OIDC network error detected.');

            // notifying UUI to render overlay and start polling
            UUIFetch.triggerNetworkDownHandler();

            // setting isOidcDown to prevent further issues handling
            // app will be reloaded anyway when network is restored
            isOidcDown = true;
            // no need to renew token. Token renewal will be restored after app reload
            await getUserManager().stopSilentRenew();

            return true;
        }

        return false;
    }
}

async function pollUrl(url: string, healthyStatus: string) {
    try {
        const response = await fetch(url);
        if (response.ok) {
            return (await response.text()) === healthyStatus;
        } else {
            return false;
        }
    } catch (err) {
        return false;
    }
}

async function poll<T>(fn: () => Promise<T>, ms: number): Promise<T> {
    let result = await fn();
    while (!result) {
        await wait(ms);
        result = await fn();
    }
    return result;
}

const wait = (ms = 5000) => {
    return new Promise<void>((resolve) => {
        setTimeout(resolve, ms);
    });
};
