import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    Menu,
    MenuItem,
    IconButton,
    PopoverOrigin,
    ListItemText,
    ListItemIcon,
    Tooltip,
    Select,
    SelectProps,
} from '@mui/material';
import { usePopupState, bindTrigger, bindMenu, PopupState } from 'material-ui-popup-state/hooks';
import React, { createContext, useContext, PropsWithChildren, ReactElement, useState, useRef, useEffect } from 'react';
import UUIButton from './uuiButton';
import css from './uuiFlyoutMenu.module.scss';
import { UUITooltip } from './uuiTooltip';

export interface IUUIFlyoutMenu {
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    rootClass?: string;
    isNarrow?: boolean;
    enableScrollbarForLongContent?: boolean;
}

interface IUUIFlyoutSelectMenu extends Pick<SelectProps, 'onChange' | 'value'> {
    className?: string;
    isNarrow?: boolean;
    name?: string;
}

export interface IUUIFlyoutMenuContext {
    popupState?: PopupState;
    hasIcons: boolean;
}

const UUIFlyoutMenuContext = createContext<IUUIFlyoutMenuContext | undefined>(undefined);

/**
 * Unified UI implementation of a flyout menu.
 *
 * There is also UUIFlyoutSelectMenu variant below UUIFlyoutMenu.
 *
 * If text is provided as a child of the UUIFlyoutMenuButton, then a regular button
 * will be rendered.  By default, a down arrow icon will be added to the right of
 * the text.  Optionally, an icon may be provided as a prop to UUIFlyoutMenuButton,
 * in which case that icon, rather than the down arrow, will be used to the right
 * of the text.
 *
 * If not text is provided as a child of UUIFlyoutMenuButton, then the kebob icon
 * will be used as the component that must be clicked to open the menu items.
 * Optionally, a different icon may be provided as a prop to UUIFlyoutMenuButton.
 *
 * All children other than the first child of this component should be instances
 * of UUIFlyoutMenuItem.  These will comprise the items in the flyout menu itself.
 * Each UUIFlyoutMenuItem may have an optional icon, which should be provided as
 * a prop.  Internally, the icons will be resized to 16x16 pixels in size, so there
 * is no need to size the icons when providing them to each item.
 *
 * @param props   The first child must be an instance of UUIFlyoutMenuButton.
 *                All other children must be instances of UUIFlyoutMenuItem.
 */
export const UUIFlyoutMenu: React.FC<IUUIFlyoutMenu> = (props) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'contextMenu' });
    const menuItemChildren = React.Children.toArray(props.children).slice(1);
    const hasIcons = menuItemChildren.find((child) => (child as ReactElement).props.icon) !== undefined;
    const menuContextObject = { popupState, hasIcons };

    useEffect(() => {
        const dragEnter = () => {
            popupState.close();
        };

        window.addEventListener('dragenter', dragEnter);

        return () => {
            window.removeEventListener('dragenter', dragEnter);
        };
    }, [popupState]);

    return (
        <UUIFlyoutMenuContext.Provider value={menuContextObject}>
            {React.Children.toArray(props.children)[0]}
            <Menu
                {...bindMenu(popupState)}
                anchorOrigin={props.anchorOrigin || { vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={props.transformOrigin}
                className={menuItemChildren?.length > 0 ? '' : css.flyoutMenuHidden}
                classes={{
                    list: props.isNarrow
                        ? css.flyoutMenuListNarrow
                        : props.enableScrollbarForLongContent
                        ? css.enableScrollbarFlyoutList
                        : css.flyoutMenuList,
                }}
                PaperProps={{ square: true }}
                PopoverClasses={{
                    paper: props.enableScrollbarForLongContent ? css.enableScrollbarFlyoutPaper : css.flyoutMenuPaper,
                }}
                data-testid="flyout-menu"
                style={props.enableScrollbarForLongContent ? { paddingRight: '16px', paddingLeft: '16px' } : undefined}>
                {menuItemChildren}
            </Menu>
        </UUIFlyoutMenuContext.Provider>
    );
};

/*
 * For the UUIFlyoutSelectMenu variant, there is no UUIFlyoutMenuButton required
 * Just wrap this around UUIFlyoutMenuItems
 * Each UUIFlyoutMenuItem must be provided a value prop and that is what will be passed
 * to the onChange handler
 */

export const UUIFlyoutSelectMenu: React.FC<IUUIFlyoutSelectMenu> = (props) => {
    const menuItemChildren = React.Children.toArray(props.children);
    const hasIcons = menuItemChildren.find((child) => (child as ReactElement).props.icon) !== undefined;
    const menuContextObject = { hasIcons };
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const handleTooltip = (bool: React.SetStateAction<boolean>) => {
        setTooltipOpen(bool);
    };

    const selectRef = useRef<HTMLDivElement>();

    const handleFocusExisted = () => {
        const selectElement: HTMLDivElement | null = selectRef.current!.querySelector(`.${css.flyoutSelectMenuRoot}`);
        if (selectElement) {
            selectElement.blur();
        }
    };
    return (
        <UUIFlyoutMenuContext.Provider value={menuContextObject}>
            <Tooltip
                title={`${props.name}`}
                placement="top"
                classes={{ tooltip: css.tooltip }}
                enterDelay={500}
                open={tooltipOpen}>
                <Select
                    variant="standard"
                    classes={{ select: css.flyoutSelectMenuRoot, icon: css.flyoutSelectIcon }}
                    className={props.className}
                    value={props.value}
                    onChange={props.onChange}
                    ref={selectRef}
                    MenuProps={{
                        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        },
                        classes: { list: props.isNarrow ? css.flyoutMenuListNarrow : css.flyoutMenuList },
                        PaperProps: { square: true },
                        PopoverClasses: { paper: css.flyoutMenuPaper },
                        TransitionProps: {
                            onExited: handleFocusExisted,
                        },
                    }}
                    disableUnderline
                    data-testid={`flyout-select-menu-${props.name}`}
                    onMouseEnter={() => {
                        handleTooltip(true);
                    }}
                    onMouseLeave={() => {
                        handleTooltip(false);
                    }}
                    onBlur={() => {
                        handleTooltip(false);
                    }}
                    onOpen={() => {
                        handleTooltip(false);
                    }}
                    onClose={() => {
                        handleTooltip(false);
                    }}>
                    {props.children}
                </Select>
            </Tooltip>
        </UUIFlyoutMenuContext.Provider>
    );
};

interface IUUIFlyoutMenuButton {
    'aria-label'?: string;
    'data-testid'?: string;
    size?: 'small' | 'medium';
    icon?: React.ReactElement;
    edge?: false | 'end' | 'start';
    children?: any;
    rootClass?: string;
}

export const UUIFlyoutMenuButton = React.forwardRef<HTMLButtonElement, IUUIFlyoutMenuButton>((props, ref) => {
    const uuiFlyoutMenuContext = useContext(UUIFlyoutMenuContext)!;
    return props.children ? (
        <UUIButton
            ref={ref}
            variant="outlined"
            color="primary"
            data-testid={props['data-testid']}
            classes={{ root: props.rootClass }}
            {...bindTrigger(uuiFlyoutMenuContext.popupState!)}>
            {props.children}
            {props.icon || <KeyboardArrowDownIcon />}
        </UUIButton>
    ) : (
        <IconButton
            size={props.size}
            edge={props.edge}
            className={uuiFlyoutMenuContext.popupState!.isOpen ? css.flyoutMenuButtonActive : css.flyoutMenuIcon}
            aria-label={props['aria-label'] || 'more'}
            color="primary"
            data-testid={props['data-testid']}
            {...bindTrigger(uuiFlyoutMenuContext.popupState!)}>
            {props.icon || <MoreVertIcon />}
        </IconButton>
    );
});

UUIFlyoutMenuButton.displayName = 'UUIFlyoutMenuButton';

interface IUUIFlyoutMenuItem {
    onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    icon?: React.ReactElement;
    'data-testid'?: string;
    disabled?: boolean;
    tooltipText?: string;
    isWordWrapped?: boolean;
    value?: string | number;
    tabIndex?: number;
}

export const UUIFlyoutMenuItem = React.forwardRef<HTMLLIElement, PropsWithChildren<IUUIFlyoutMenuItem>>(
    (props, ref) => {
        const uuiFlyoutMenuContext = useContext(UUIFlyoutMenuContext)!;

        const onMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            if (props.onClick) {
                props.onClick(event);
            }
            uuiFlyoutMenuContext.popupState?.close();
        };

        const clonedIcon = props.icon
            ? React.cloneElement(props.icon, {
                  className: props.icon.props.className ? props.icon.props.className : css.flyoutMenuItemIcon,
              })
            : undefined;

        return (
            <MenuItem
                data-testid={props['data-testid']}
                ref={ref}
                value={props.value}
                dense
                className={
                    !props.icon && uuiFlyoutMenuContext.hasIcons
                        ? css.flyoutMenuItemWithExtraSpace
                        : props.isWordWrapped
                        ? css.flyoutMenuItemWordWrap
                        : css.flyoutMenuItem
                }
                onClick={onMenuItemClick}
                disabled={props.disabled}>
                {clonedIcon && <ListItemIcon className={css.menuIcon}>{clonedIcon}</ListItemIcon>}

                <ListItemText className={css.listItemTextContainer}>
                    <UUITooltip title={`${props.children}`} placement="top" enterDelay={500}>
                        <div className={css.listItemText}>{props.children}</div>
                    </UUITooltip>
                </ListItemText>
            </MenuItem>
        );
    },
);

UUIFlyoutMenuItem.displayName = 'UUIFlyoutMenuItem';

interface IUUIFlyoutBreadcrumbButton {
    'aria-label'?: string;
    'data-testid'?: string;
    id?: string;
    size?: 'small' | 'medium';
    icon?: React.ReactElement;
    edge?: false | 'end' | 'start';
    children?: any;
    redirect?: () => void;
}

export const UUIFlyoutBreadcrumbButton = React.forwardRef<HTMLButtonElement, IUUIFlyoutBreadcrumbButton>(
    (props, ref) => {
        const uuiFlyoutMenuContext = useContext(UUIFlyoutMenuContext)!;

        const onBtnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            const rootCrumb = document.getElementById('rootBreadCrumb');
            const ellipsisSVG = document.getElementById('ellipsisSVG');
            if (rootCrumb && ellipsisSVG) {
                const popupState = uuiFlyoutMenuContext.popupState;
                if (popupState && !popupState.isOpen) {
                    popupState.open(event);
                }
            } else {
                props.redirect?.();
            }
        };

        return (
            <UUIButton
                ref={ref}
                color="primary"
                data-testid={props['data-testid']}
                id={props['id']}
                classes={{ root: css.flyoutBreadcrumbButton }}
                onClick={(event) => onBtnClick(event)}>
                {props.children}
            </UUIButton>
        );
    },
);

UUIFlyoutBreadcrumbButton.displayName = 'UUIFlyoutBreadcrumbButton';
