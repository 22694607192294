import { IValidationTransformer } from './types';
import { ICHValidateAddFolder, IValidateAddFolder } from '../interfaces/operations';
import 'reflect-metadata';
import { injectable } from 'inversify';

@injectable()
export class CtxValidateAddFolderTransformer implements IValidationTransformer {
    data!: IValidateAddFolder;

    transform(): ICHValidateAddFolder {
        const nodeObj = {} as ICHValidateAddFolder;
        if (this.data?.name) {
            nodeObj.document_folder_name = this.data?.name;
        }

        if (this.data?.documentFolderId) {
            nodeObj.folderId = this.data?.documentFolderId;
        }

        if (this.data?.editAccessType) {
            nodeObj.folder_edit_access_type_name = this.data?.editAccessType;
        }

        if (this.data?.viewAccessType) {
            nodeObj.folder_view_access_type_name = this.data?.viewAccessType;
        }

        if (this.data?.admittedMembers) {
            nodeObj.admittedMembers = this.data?.admittedMembers;
        }

        if (this.data?.default) {
            nodeObj.default = this.data.default;
        }
        return nodeObj;
    }
}
