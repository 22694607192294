import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';
import { INotification } from '../../reducers/types';

type Notification = INotification;
export type NotificationsState = Notification[];

const initialState: NotificationsState = [];

const notifications = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        showNotification: (
            state,
            action: PayloadAction<{ notification: Omit<Notification, 'key'> & Partial<Pick<Notification, 'key'>> }>,
        ) => {
            const { key = uniqueId(Date.now().toString()), ...notificationSource } = action.payload.notification;
            state.push({ key, ...notificationSource });
        },
        dismissNotification: (state, action: PayloadAction<{ key: string }>) => {
            const notification = state.find((n) => n.key === action.payload.key);
            if (notification) {
                notification.dismissed = true;
            }
        },
        removeNotification: (state, action: PayloadAction<{ key: string }>) =>
            state.filter((n) => n.key !== action.payload.key),
    },
});

// Actions
export type NotificationsActions = TakeActions<typeof notifications.actions>;
export const { showNotification, dismissNotification, removeNotification } = notifications.actions;
export const notificationsReducer = notifications.reducer;
