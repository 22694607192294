import { Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import { NotificationPlacement } from '../../../../reducers/types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { ComponentType, isLayoutComponent, selectPageState, showNotification } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { useCurrencyToggle } from './currencyToggle.hook';

const useStyles = makeStyles<AppTheme>((theme) => ({
    currency: {
        display: 'flex',
        alignItems: 'center',
    },
    currencyLabel: {
        fontSize: theme.typography.body2.fontSize,
        color: theme.palette.primary.dark,
    },
    track: {
        height: 4,
        opacity: `1 !important`,
        backgroundColor: theme.palette.grey[500],
    },
    thumb: {
        border: `1px solid ${theme.palette.divider}`,
        color: theme.palette.primary.contrastText,
    },
    switch: {
        alignItems: 'center',
    },
}));

export const CurrencyToggle = memo(function CurrencyToggle() {
    const currency = useAppSelector(selectPageState('currency'));
    const classes = useStyles();
    const { toggleCurrency } = useCurrencyToggle();
    const homePageComponents = useAppSelector(selectPageState('data'))?.components;
    const dispatch = useAppDispatch();
    let toastMessage: string =
        'Original currency cannot be applied to the following charts with aggregated data:' + '<br />';

    const showCurrencyToastMessage = (): void => {
        const powerbiComponent = homePageComponents
            ?.find(isLayoutComponent)
            ?.components?.filter((component) => component.type == ComponentType.LayoutPowerBiComponent);

        powerbiComponent?.forEach((el) => (toastMessage = toastMessage + '<br />' + el.title));
        if (powerbiComponent && powerbiComponent.length > 0) {
            dispatch(
                showNotification({
                    notification: {
                        message: toastMessage,
                        options: {
                            variant: 'info',
                        },
                        placement: NotificationPlacement.Top,
                    },
                }),
            );
        }
    };
    const toggleElement = document.querySelector('.PrivateSwitchBase-input') as HTMLInputElement;
    const currencyChecked = currency?.enabled ?? toggleElement?.checked ?? false;

    return (
        <>
            {currency && (
                <label className={classes.currency}>
                    <div className={classes.currencyLabel}>{currency.origCode}</div>
                    <Switch
                        classes={{
                            track: classes.track,
                            thumb: classes.thumb,
                            root: classes.switch,
                        }}
                        size="small"
                        title="Currency Toggle"
                        data-testid="currency-toggle"
                        onChange={async (_, checked: boolean): Promise<void> => {
                            await toggleCurrency(String(checked ? currency.preference : currency.origCode), checked);

                            if (!checked) {
                                showCurrencyToastMessage();
                            }
                        }}
                        checked={currencyChecked}
                        color={'primary'}
                    />
                    <div className={classes.currencyLabel}>{currency.preference}</div>
                </label>
            )}
        </>
    );
});
