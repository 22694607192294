import update from 'immutability-helper';
import { getActiveParentAndSubTabMeta, isActiveTabASubTab } from '../itemScreenHelpers';
import { IItemScreenState } from '../types';
import { ItemScreenAction } from '../types';

export const itemScreenReducer = (state: IItemScreenState, action: ItemScreenAction): IItemScreenState => {
    switch (action.type) {
        // INITIALIZE
        case 'InitializeItemScreen':
            return {
                ...state,
                itemScreenJson: action.itemScreenJson,
                parentItemInfo: action.parentItemInfo,
                contextLayerInfo: action.contextLayerInfo,
                mode: action.mode,
                renderingStyle: action.renderingStyle,
            };
        case 'InitializeQF':
            return {
                ...state,
                enableQFIcon: action.enableQFIcon,
                docTypeForQF: action.docTypeForQF,
            };
        case 'SetActiveTab': {
            let updatedVisitedTabs = state.visitedTabs;
            // then add the new tab if it's not already in there.
            if (!state.visitedTabs.find((vt) => vt.index === action.tabIndex)) {
                updatedVisitedTabs = updatedVisitedTabs.concat([{ index: action.tabIndex }]);
            }

            return {
                ...state,
                activeTab: action.tabIndex,
                visitedTabs: updatedVisitedTabs,
                viewState: {
                    ...state.viewState,
                    activeTab: action.tabIndex,
                    visitedTabs: [{ index: action.tabIndex }],
                },
            };
        }
        case 'SetActiveSubTab': {
            // find visitedSubTabs array and initialize it to a blank array if needed
            let updatedVisitedSubTabs = getActiveParentAndSubTabMeta(state)[0]?.visitedSubTabs || [];

            // then add the new subtab if it's not already in there
            if (!updatedVisitedSubTabs.find((vs) => vs.index === action.tabIndex)) {
                updatedVisitedSubTabs = updatedVisitedSubTabs.concat([{ index: action.tabIndex }]);
            }
            return update(state, {
                visitedTabs: {
                    [state.visitedTabs.findIndex((vt) => vt.index === state.activeTab)]: {
                        $merge: { activeSubTab: action.tabIndex, visitedSubTabs: updatedVisitedSubTabs },
                    },
                },
                viewState: {
                    $set: {
                        activeTab: state.activeTab,
                        visitedTabs: [
                            {
                                index: state.activeTab,
                                activeSubTab: action.tabIndex,
                                visitedSubTabs: [
                                    {
                                        index: action.tabIndex,
                                        activeSubTab: action.tabIndex,
                                        visitedSubTabs: [{ index: action.tabIndex }],
                                    },
                                ],
                            },
                        ],
                    },
                },
            });
        }

        case 'SetTabEmbeddedListMetadata': {
            const [parentTabIndex, subTabIndex] = getActiveTabAndSubTabIndexes(state);
            if (isActiveTabASubTab(state)) {
                return update(state, {
                    visitedTabs: {
                        [parentTabIndex!]: {
                            visitedSubTabs: {
                                [subTabIndex!]: {
                                    $merge: { ...action.metadata },
                                },
                            },
                        },
                    },
                });
            } else {
                return update(state, {
                    visitedTabs: {
                        [parentTabIndex!]: {
                            $merge: { ...action.metadata },
                        },
                    },
                });
            }
        }
        default:
            return state;
    }
};

export const getActiveTabAndSubTabIndexes = (state: IItemScreenState): (number | undefined)[] => {
    const tabIndex = state.visitedTabs.findIndex((vt) => vt.index === state.activeTab);
    const subTabIndex = state.visitedTabs[tabIndex].visitedSubTabs?.findIndex(
        (vt) => vt.index === getActiveParentAndSubTabMeta(state)[0]?.activeSubTab,
    );
    return [tabIndex, subTabIndex];
};
