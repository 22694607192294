import { useAppDispatch } from '../../../../store';
import { setPageCurrencyState } from '../../../../store/slices';
import { apiFetch } from '../../../../utils/fetchUtils';

interface CurrencyToggleHookState {
    toggleCurrency: (code: string, enabled: boolean) => Promise<void>;
}

export const useCurrencyToggle = (): CurrencyToggleHookState => {
    const dispatch = useAppDispatch();

    const toggleCurrency = async (code: string, enabled: boolean): Promise<void> => {
        const { apiContextRoot, apiContextPath, toggleCurrencyPath } = window.Props;
        await apiFetch<void>(
            `${String(apiContextRoot)}${String(apiContextPath)}${String(toggleCurrencyPath)}/${code}`,
            '{}',
            { responseCallbackFn: () => updateCurrency(enabled) },
        );
    };

    const updateCurrency = (enabled: boolean): void => {
        dispatch(setPageCurrencyState({ enabled }));
    };

    return { toggleCurrency };
};
