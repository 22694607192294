import { Close as CloseIcon } from '@mui/icons-material';
import {
    Backdrop,
    Button,
    Divider,
    Drawer,
    DrawerProps,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { Fragment, memo, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { IMenuItem } from '../../../reducers/types';
import { AppTheme, StyleProps } from '../../app';
import { Tooltip } from '../../common';
import { ShortcutAction } from '../../favorites';

type PaneMenuClasses = DrawerProps['classes'] & { backdrop?: string; section?: string; content?: string };

// eslint-disable-next-line max-lines-per-function
const useStyles = makeStyles<AppTheme, { hasScroll?: boolean }>((theme) => {
    const onListItemFocus = (styles: StyleProps) => {
        return {
            '$listItem:hover + $listItemAction &, $listItem.Mui-focusVisible + $listItemAction &': styles,
        };
    };

    return {
        root: {
            position: 'relative',
        },
        panePaper: ({ hasScroll }) => ({
            position: 'absolute',
            left: 0,
            zIndex: theme.zIndex.drawer - 1,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(!hasScroll ? 1 : 0),
            overflowY: 'auto',
            ...(theme.styles.scrollbar.default as StyleProps),
        }),
        paneHeader: {
            display: 'flex',
            position: 'sticky',
            top: 0,
            backgroundColor: 'inherit',
            zIndex: 1,
        },
        paneHeaderContent: {
            display: 'flex',
            alignItems: 'center',
            flexBasis: '100%',
            overflow: 'hidden',
            position: 'relative',
        },
        backdrop: {
            zIndex: theme.zIndex.drawer - 1,
            position: 'absolute',
        },
        title: {
            fontSize: 16,
            lineHeight: '22px',
            fontWeight: 600,
            padding: theme.spacing(1.5, 2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        closeButton: {
            position: 'absolute',
            right: 0,
            padding: theme.spacing(0.75),
            color: 'inherit',
            minWidth: 'auto',
        },
        closeTooltip: {
            marginBottom: theme.spacing(1),
        },
        sectionHeader: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        sectionTitle: {
            fontWeight: 600,
            padding: theme.spacing(0.75, 2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        list: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        listItem: {
            padding: theme.spacing(0.25, 4, 0.25, 2),
        },
        listItemActive: {
            fontWeight: 600,
            backgroundColor: theme.palette.action.hover,
            '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                borderLeft: `5px solid ${theme.palette.success.light}`,
            },
        },
        listItemText: {
            fontWeight: 'inherit',
        },
        listItemAction: {
            position: 'absolute',
            right: theme.spacing(0),
        },
        shortcutAction: {
            ...onListItemFocus({
                visibility: 'visible',
            }),
        },
        shortcut: {
            ...onListItemFocus({
                display: 'none',
            }),
        },
        dividerHeader: {
            margin: theme.spacing(0, 4, 1, 0),
            borderColor: theme.palette.grey[300],
            position: 'sticky',
            top: 46,
        },
        divider: {
            margin: theme.spacing(1, 4, 1, 0),
            borderColor: theme.palette.grey[300],

            '&:first-child': {
                display: 'none',
            },
        },
    };
});

type PaneMenuOptions = PropsWithChildren<{
    classes?: PaneMenuClasses;
    data?: IMenuItem;
    selected?: (item: IMenuItem) => boolean;
    open?: boolean;
    onClose?: () => void;
    onMenuSelection?: (item: IMenuItem) => void;
}>;

// eslint-disable-next-line max-lines-per-function
export const PaneMenu = memo(function PaneMenuComponent(props: PaneMenuOptions) {
    const { classes: externalClasses = {}, data, open = false, onClose, onMenuSelection, selected } = props;
    const { backdrop: backdropClass, section: sectionClass, ...drawerClasses } = externalClasses;
    const [hasScroll, setHasScroll] = useState<boolean>();
    const classes = useStyles({ hasScroll });
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const { scrollHeight = 0, clientHeight = 0 } = contentRef.current || {};

        contentRef.current?.scrollTo({ top: 0 });
        setHasScroll(scrollHeight > clientHeight);
    }, [data, contentRef]);

    const onCloseHandler = () => {
        onClose?.();
    };

    function renderMenuItem(item: IMenuItem) {
        const panelMenuTooltip = item.isNewWindow ? 'Opens in a new tab' : '';
        return (
            <ListItem
                data-testid="pane-menu-item"
                classes={{
                    root: classNames(classes.listItem, {
                        [classes.listItemActive]: selected?.(item),
                    }),
                }}
                button
                key={item.id || item.name}
                onClick={() => onMenuSelection?.(item)}>
                <Tooltip title={panelMenuTooltip} placement="bottom">
                    <ListItemText primary={item.displayValue} classes={{ primary: classes.listItemText }} />
                </Tooltip>
                <ListItemSecondaryAction classes={{ root: classes.listItemAction }}>
                    <ShortcutAction
                        item={item}
                        classes={{
                            shortcut: classes.shortcut,
                            action: classes.shortcutAction,
                        }}
                    />
                </ListItemSecondaryAction>
            </ListItem>
        );
    }

    function renderSection(section: IMenuItem) {
        return (
            <>
                <Divider data-testid="pane-menu-divider" classes={{ root: classes.divider }} />
                <div className={classNames(classes.sectionHeader, sectionClass)}>
                    <Typography
                        data-testid="pane-menu-section"
                        classes={{ root: classes.sectionTitle }}
                        variant="body1">
                        {section?.displayValue}
                    </Typography>
                </div>
                <List classes={{ root: classNames(classes.list, sectionClass) }}>
                    {section.children?.map((item) => renderMenuItem(item))}
                </List>
            </>
        );
    }

    function renderSingleItem(section: IMenuItem) {
        return <List classes={{ root: classNames(classes.list) }}>{renderMenuItem(section)}</List>;
    }

    return (
        <>
            <Backdrop
                data-testid="pane-menu-backdrop"
                open={open}
                onClick={onCloseHandler}
                classes={{ root: classNames(classes.backdrop, backdropClass) }}
            />
            <Drawer
                variant="persistent"
                anchor="left"
                open={open}
                PaperProps={{ ref: contentRef }}
                classes={{
                    ...drawerClasses,
                    root: classNames(classes.root, externalClasses?.root),
                    paper: classNames(classes.panePaper, externalClasses?.paper),
                }}>
                <div className={classes.paneHeader}>
                    <div className={classes.paneHeaderContent}>
                        <Typography className={classes.title} variant="body1">
                            {data?.displayValue}
                        </Typography>

                        <Button
                            onClick={onCloseHandler}
                            disableFocusRipple
                            data-testid="pane-menu-close-button"
                            classes={{
                                root: classes.closeButton,
                            }}>
                            <Tooltip
                                title="Close"
                                placement="top"
                                classes={{ tooltipPlacementTop: classes.closeTooltip }}>
                                <CloseIcon />
                            </Tooltip>
                        </Button>
                    </div>
                </div>
                <Divider data-testid="pane-menu-divider" classes={{ root: classes.dividerHeader }} />
                <div className={classNames(externalClasses?.content)}>
                    {data?.children?.map((section) => (
                        <Fragment key={section.id || section.name}>
                            {!section.children?.length && renderSingleItem(section)}
                            {Boolean(section.children?.length) && renderSection(section)}
                        </Fragment>
                    ))}
                </div>
            </Drawer>
        </>
    );
});
