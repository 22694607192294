import { getMaxLogMessageLength, initializeLoggerFactory } from '@wk/elm-uui-common';
import { ComponentType, PropsWithChildren, ReactElement } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Reducer } from 'redux';
import { App } from './app';
import { setAuthenticationProvider } from './components/authentication/AuthenticationProviderService';
import { DefaultAuthenticationProvider } from './components/authentication/DefaultAuthenticationProvider';
import { IAuthenticationProvider } from './components/authentication/types';
import { UUIHistory } from './hooks/useUUIHistory';
import { loadApp } from './initializeCommon';
import { AppStore } from './reducers/types';
import { AppDispatch } from './store';
import { readLogLevelFromLocalStorage } from './utils/loggingService';
import {
    loadLocationDataFromOC,
    purgeOldOCLocationHistory,
    restoreLocationDataForOC,
    setupOCLocationService,
} from './utils/ocLocationService';

export interface AppProps {
    apiContextRoot: string;
    apiContextPath: string;
    uiContextRoot: string;
    legacyContextRoot: string;
    bffContextRoot: string;
    staticContext: string;
    token: string;
    initializationPath: string;
    iconPath: string;
    basePath?: string;
    timeoutPath: string;
    pageNotFoundParam: string;
}

export type AppProviderProps = PropsWithChildren<Record<string, unknown>>;

export interface CustomComponents {
    CustomAboutComponent?: ComponentType<unknown>;
    CustomContactsComponent?: ComponentType<unknown>;
    AppProvider: (props: AppProviderProps) => ReactElement<AppProviderProps>;
}

export interface Configuration {
    isSingleTabSavedViewMode?: boolean;
    heartBeatFunction?: () => Promise<void>;
    minRefreshUUIDebounceWaitTime?: number;
    maxRefreshUUIDebounceWaitTime?: number;
    minRefreshUUIThrottleWaitTime?: number;
    maxRefreshUUIThrottleWaitTime?: number;
}

export type InitializeAppCallback = (dispatch: AppDispatch, history: UUIHistory) => Promise<void>;

interface InitializationOptions {
    applicationReducer?: Reducer<AppStore>;
    UUICustomComponents: CustomComponents;
    UUIConfiguration?: Configuration;
    applicationAuthProvider?: IAuthenticationProvider;
    initializeAppCallback?: InitializeAppCallback;
}

export function initialize(initializationOptions: InitializationOptions): void {
    const defaultConfig = {
        applicationReducer: (state: AppStore): AppStore => state,
        UUIConfiguration: { isSingleTabSavedViewMode: false },
        applicationAuthProvider: DefaultAuthenticationProvider,
    };

    const {
        UUICustomComponents,
        applicationAuthProvider = defaultConfig.applicationAuthProvider,
        initializeAppCallback,
    } = initializationOptions;

    setAuthenticationProvider(applicationAuthProvider);

    initializeLoggerFactory(readLogLevelFromLocalStorage(), getMaxLogMessageLength());

    window.addEventListener('mousedown', () => {
        restoreLocationDataForOC();
    });

    purgeOldOCLocationHistory();
    setupOCLocationService();
    loadLocationDataFromOC();

    loadApp(UUICustomComponents, App, initializeAppCallback);
}
