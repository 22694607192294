import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAppsMenu } from '../../reducers/types';

export type AppsMenuState = IAppsMenu;

const initialState: AppsMenuState = {
    activeContext: 0,
    passportContexts: [],
};

const appsMenu = createSlice({
    name: 'apps-menu',
    initialState,
    reducers: {
        setAppsMenuState: (_, action: PayloadAction<{ state: AppsMenuState }>) => action.payload.state,
        setActiveContextId: (state, action: PayloadAction<{ context: number }>) => {
            state.activeContext = action.payload.context;
        },
    },
});

// Actions
export type AppsMenuActions = TakeActions<typeof appsMenu.actions>;
export const { setAppsMenuState, setActiveContextId } = appsMenu.actions;
export const appsMenuReducer = appsMenu.reducer;
