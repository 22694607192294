import { Link, Button, Checkbox, SvgIconProps, Pagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    DataGrid,
    GridSortModel,
    GridColDef,
    GridColumnHeaderParams,
    GridRenderCellParams,
    GridValueGetterParams,
    GridSortDirection,
} from '@mui/x-data-grid';
import { useAppDispatch } from '../../../../store';
import classNames from 'classnames';
import React, { CSSProperties, forwardRef, useMemo, useState } from 'react';
import { wrapWithNonce, useNavigate, useScript, useStyle, useUniqueId } from '../../../../hooks';
import { IMenuItem, NotificationPlacement } from '../../../../reducers/types';
import {
    GridActionButton,
    GridComponent,
    GridComponentButton,
    GridComponentControlType,
    GridComponentSourceViewData,
    ResponseType,
    showNotification,
    WarningActionResponse,
} from '../../../../store/slices';
import { AppTheme } from '../../../app';
// import { InactivityAlertDialog } from '../../../app/inactivityAlertDialog';
import { Tooltip } from '../../../common';
import { ActionType, TargetScreenType } from '../../../common/types';
import { WkSort, WkExcel } from '../../../icons';
import { getIcon } from '../../../icons/iconStorage';
import { WarningAlertDialog } from '../WarningAlertDialog';
import { useGridView } from './hooks';
import { FirstPage, LastPage, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

declare global {
    interface Window {
        gridValues: any;
    }
}

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
        height: '100%',
        marginTop: '0px !important',
    },
    grid: {
        flex: 1,
        fontSize: '1rem',
        minHeight: '400px',
        marginTop: theme.spacing(1.5),
        borderWidth: '0px',
        borderTopWidth: '1px',
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid',
        // TODO: remove separator hide logic when turn on columns resize
        '& .MuiDataGrid-iconSeparator': {
            opacity: 0,
        },
        '& .MuiDataGrid-gridRow-delimiter': {
            borderWidth: '0px',
            borderBottomWidth: '1px',
            borderColor: theme.palette.grey[300],
            borderStyle: 'solid',
        },
    },
    gridHeaderCell: {
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        lineHeight: '1.25em',
        fontSize: '1rem',
        fontWeight: 500,
    },
    cellLink: {
        ...theme.styles.ellipsis,
        fontSize: 'inherit',
    },
    cellText: {
        ...theme.styles.ellipsis,
        color: theme.palette.grey[600],
    },
    sort: {
        '$grid .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover &': {
            opacity: '1',
            fill: 'none',
        },
    },
    sortDesc: {
        transform: 'rotate(180deg)',
    },
    pagesLabel: {
        textAlign: 'right',
        marginLeft: 'auto',
        marginRight: '16px',
    },
    gridButtonsArea: {
        textAlign: 'right',
        alignContent: 'right',
        float: 'right',
        marginLeft: 'auto',
    },
    gridIconButton: {
        padding: theme.spacing(0.75),
        color: theme.palette.primary.dark,
        minWidth: 'auto',
    },
    gridButton: {
        marginRight: '16px !important;',
        marginBottom: '16px !important;',
        lineHeight: '20px',
        boxShadow: 'none',
    },
    gridArea: {
        display: 'flex',
        flexDirection: 'row',
    },
    gridPager: {
        '& .MuiPaginationItem-text': {
            fontSize: '0.85rem',
            color: theme.palette.primary.dark,
        },
        '& .MuiPaginationItem-root': {
            minWidth: '16px',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: '#FFFFFF',
            color: 'black',
            fontWeight: 'bold',
        },
    },
    checkbox: {
        color: theme.palette.secondary.main,
        stroke: theme.palette.background.paper,
        strokeWidth: 1,
    },
    checkboxChecked: {
        color: `${theme.palette.action.active} !important`,
    },
    arrowSpace: {
        marginRight: 10,
    },
    ellipsis: {
        marginRight: 2,
        verticalAlign: 'middle',
        lineHeight: '1.2',
        fontSize: '0.85rem',
    },  
    paginationItem: {
        cursor: 'pointer',
        marginRight: 10,
        color: '#005B92',
        fontWeight: 'normal',
        verticalAlign: 'middle',
        lineHeight: '1.2',
        fontSize: '0.90rem',
    },
    paginationItemSpecial: {
        marginRight: 1,
        fontWeight: 'bold',
    },
    selectedPaginationItem: {
        color: 'black',
        fontWeight: 'bold',
        cursor: 'default',
    },
}));

type GridProps = {
    component: GridComponent;
    sharedStorage: Record<string, unknown>;
    classes?: {
        root?: string;
    };
    style?: CSSProperties;
    hasFilter: boolean;
};

const HEADER_HEIGHT = 38;

// eslint-disable-next-line max-lines-per-function
export const GridView = forwardRef<HTMLDivElement, GridProps>((props, ref) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { component, classes: externalClasses, sharedStorage, style, hasFilter } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const defaultSortColumn = component.columns.find((column) => column.isDefaultSort);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: defaultSortColumn?.code ?? '',
            sort: (defaultSortColumn?.defaultSortDirectionDesc ? 'desc' : 'asc') as GridSortDirection,
        },
    ]);
    // if grid contains checkboxes, here we store checked/unchecked state
    const [gridValues, setGridValues] = useState<Record<string, Record<string, string>>>({});
    const {
        data = [],
        columns,
        rowsCount,
        pagesCount,
        clientScript,
        clientStyle,
        download,
        gridAction,
    } = useGridView({ component, currentPage, sortModel, sharedStorage, hasFilter });
    const [warningResponse, setWarningResponse] = useState<WarningActionResponse>({
        message: '',
        url: '',
        type: ResponseType.None,
        payload: ''
    } as WarningActionResponse);
    const { navigate } = useNavigate();
    const gridColumns: GridColDef[] = useMemo(() => {
        const renderCell = (params: GridRenderCellParams) => {
            const gridViewData = params.row[params.field] as GridComponentSourceViewData;
            switch (gridViewData.controlType) {
                case GridComponentControlType.ActionButtons:
                    return gridViewData.actionButtons?.map((button, index) => {
                        return (
                            <Button
                                key={index}
                                disableRipple
                                classes={{
                                    root: classes.gridIconButton,
                                }}
                                onClick={() => handleButtonClickDeleteCancel(button, button.action)}>
                                <Tooltip title={button.toolTip} placement="top">
                                    {getIcon(button.icon, { color: button.color, fontSize: button.size })}
                                </Tooltip>
                            </Button>
                        );
                    });
                case GridComponentControlType.CheckBox:
                    return (
                        <Checkbox
                            checked={Boolean(gridValues[gridViewData.code]?.[gridViewData.id])}
                            onChange={(event): void =>
                                handleCheckedBoxChange(event, gridViewData.code, gridViewData.id)
                            }
                            classes={{ root: classes.checkbox, checked: classes.checkboxChecked }}
                        />
                    );
                case GridComponentControlType.Link:
                    return (
                        <Tooltip title={gridViewData.toolTip ?? gridViewData.value} placement="top">
                            <Link
                                classes={{ root: classes.cellLink }}
                                component="button"
                                onClick={() => {
                                    if (gridViewData.link.startsWith('http')) {
                                        window.open(gridViewData.link, '_blank');
                                    } else {
                                        navigate({
                                            menuItem: {
                                                screenType: TargetScreenType.Legacy,
                                                url: gridViewData.link,
                                                id: 0,
                                            } as IMenuItem,
                                        });
                                    }
                                }}>
                                {gridViewData.value}
                            </Link>
                        </Tooltip>
                    );
                case GridComponentControlType.Html:
                    return (
                        <Tooltip title={gridViewData.toolTip ?? gridViewData.value} placement="top">
                            <span dangerouslySetInnerHTML={{ __html: wrapWithNonce(gridViewData.html) }} />
                        </Tooltip>
                    );
                default:
                    return (
                        <Tooltip title={gridViewData.toolTip ?? gridViewData.value} placement="top">
                            <span className={classes.cellText}>{gridViewData.value}</span>
                        </Tooltip>
                    );
            }
        };

        const handleCheckedBoxChange = (event: React.ChangeEvent<HTMLInputElement>, code: string, id: string): void => {
            setGridValues(() => {
                const newGridValues = { ...gridValues };
                if (!newGridValues[code]) {
                    newGridValues[code] = {};
                }
                if (event.target.checked) {
                    newGridValues[code][id] = 'true';
                } else {
                    delete newGridValues[code][id];
                }
                return newGridValues;
            });
        };

        const renderHeader = (params: GridColumnHeaderParams) => { 
            return <Tooltip title={GetColumnTooltip(params.colDef, sortModel)}>
             <span className={classes.gridHeaderCell}>{params.colDef.headerName}</span>
             </Tooltip>;
        };

        const valueGetter = (params: GridValueGetterParams): string | undefined => {
            const { value, link } = (params.row[params.field] as GridComponentSourceViewData) || {};
            return link || value;
        };

        const GetColumnTooltip = (column: GridColDef, sortModel: GridSortModel): JSX.Element => {
            const SORT_BY: string = "Sort by: ";
            
            return (
                <div>
                    {column.sortable ? `${SORT_BY}${column.headerName}` : column.headerName}
                    {sortModel[0] && column.field?.toLowerCase() === sortModel[0].field && (
                        <>
                            <br />
                            Sorted - {sortModel[0].sort === "asc" ? "Ascending" : "Descending"}
                        </>
                    )}
                </div>
            );
        };

        return columns.map((column) => {         
            return {
                sortable: column.isSortable,
                field: column.key,
                headerName: column.name,
                disableColumnMenu: true,
                flex: column.widthPercent ?? 30,
                renderHeader,
                renderCell,
                valueGetter,
            } as GridColDef;
        });
    }, [component.columns, gridValues, sortModel]);

    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    const handleSortChange = React.useCallback((sortModel: GridSortModel) => {
        setSortModel(sortModel);
    }, []);

    const handleButtonClick = async (button: GridComponentButton, clientButtonActionName: string) => {
        switch (button.actionType) {
            case ActionType.Client:
                window[clientButtonActionName]();
                break;
            default: {
                const response = await gridAction(button.action, gridValues);
                if (response && response.type == ResponseType.Warning) {
                   setWarningResponse(response);
                }
                else if (response && response.type == ResponseType.WarningToast){
                    dispatch(
                        showNotification({
                            notification: {
                                message: response.message,
                                options: {
                                    variant: 'warning',
                                    persist: true,
                                },
                                placement: NotificationPlacement.Top,
                            },
                        }),
                    );
                }
                break;
            }
        }
    };

    const handleButtonClickDeleteCancel = async (button: GridActionButton, clientButtonActionName: string) => {
        switch (button.actionType) {
            case ActionType.Client:
                window[clientButtonActionName]();
                break;
            default: {
                const response = await gridAction(button.action, button.payload);
                if (response && response.type == ResponseType.Warning) {
                    response.payload = button.payload
                    setWarningResponse(response);
                }
                break;
            }
        }
    };

    const sortIcon = (order?: 'asc' | 'desc') => {
        return function SortIcon(props: SvgIconProps) {
            return (
                <WkSort
                    {...props}
                    className={classNames(props.className, classes.sort, { [classes.sortDesc]: order === 'desc' })}
                />
            );
        };
    };

    interface PaginationItem {
        type: string;
        page: number | null;
        selected?: boolean;
      }
      
      function getPageTooltip(item: PaginationItem): string {
        switch (item.type) {
          case 'page':
            return `${item.page}`;
          case 'first':
            return 'First page';
          case 'last':
            return 'Last page';
          case 'previous':
            return 'Previous page';
          case 'next':
            return 'Next page';
          default:
            return '';
        }
      }
      
      function renderPaginationItem(item: PaginationItem): JSX.Element {
        const iconMap: { [key: string]: JSX.Element } = {
          first: <FirstPage />,
          last: <LastPage />,
          previous: <KeyboardArrowLeft />,
          next: <KeyboardArrowRight />,
        };
      
        const icon = iconMap[item.type];
 
  if ((item.type === 'previous' || item.type === 'first') && currentPage === 1) {
    return <span className={classes.arrowSpace}></span>;
  }

 
  if ((item.type === 'next' || item.type === 'last') && currentPage === pagesCount) {
    return <></>;
  }
  if (item.type === 'start-ellipsis' || item.type === 'end-ellipsis') {
    return <span className={classes.ellipsis}>...</span>;
  }
  let paginationItemClasses = [classes.paginationItem];
  if (item.type === 'previous' || item.type === 'next' || item.type === 'first' || item.type === 'last') {
      paginationItemClasses.push(classes.paginationItemSpecial);
  }
  if (item.selected) {
      paginationItemClasses.push(classes.selectedPaginationItem);
  }
  const handleHover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!item.selected) {
        (event.currentTarget as HTMLDivElement).style.textDecoration = 'underline';
    }
    };

    const handleMouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        (event.currentTarget as HTMLDivElement).style.textDecoration = 'none';
    };
        return (
          <span
            onClick={(e) => handlePageItemClick(e, item)}
            className={paginationItemClasses.join(' ')}            
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}>
            {icon && React.cloneElement(icon, { style: { fontSize: '1.2rem', verticalAlign: 'middle' } })}
            {item.type === 'page' && item.page}
          </span>
        );
      }
      
      function handlePageItemClick(event: React.MouseEvent<HTMLSpanElement, MouseEvent>, item: PaginationItem): void {
        switch (item.type) {
            case 'first':
              handlePageChange(event, 1);
              break;
            case 'last':
              handlePageChange(event, pagesCount);
              break;
            case 'previous':
              handlePageChange(event, currentPage - 1);
              break;
            case 'next':
              handlePageChange(event, currentPage + 1);
              break;
            default:
                if (item.page !== null) {
                    handlePageChange(event, item.page);
                  }
              break;
          }
      }
    const headerArea = () => {
        window.gridValues = gridValues;
        return (
            <div className={classes.gridArea}>
                <Pagination
                    page={currentPage}
                    size="small"
                    count={pagesCount}
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    onChange={handlePageChange}
                    className={classes.gridPager}
                    renderItem={(item) => (
                        <Tooltip title={!item.selected ? getPageTooltip(item) : ''} key={item.page}>
                            {renderPaginationItem(item)}
                        </Tooltip>
                      )}
                      boundaryCount={2}
                />
                <div className={classes.gridButtonsArea}>
                    <Button
                        onClick={download}
                        disableRipple
                        data-testid="grid-export-to-excel-button"
                        classes={{
                            root: classes.gridIconButton,
                        }}>
                        <Tooltip title="Excel" placement="top" classes={{ tooltipPlacementTop: classes.buttonTooltip }}>
                            <WkExcel />
                        </Tooltip>
                    </Button>
                </div>
            </div>
        );
    };

    const footerArea = () => {
        useScript({ functionName: null, functionBody: clientScript });
        useStyle(clientStyle);
        return (
            <>
                <div className={classes.gridArea}>
                    <Pagination
                        page={currentPage}
                        size="small"
                        count={pagesCount}
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        onChange={handlePageChange}
                        className={classes.gridPager}
                        renderItem={(item) => (
                            <Tooltip title={!item.selected ? getPageTooltip(item) : ''} key={item.page}>
                                {renderPaginationItem(item)}
                            </Tooltip>
                          )}
                          boundaryCount={2}
                    />
                    <div className={classes.pagesLabel}>
                        Records: {rowsCount} / Pages: {pagesCount}
                    </div>
                </div>
                <div className={classes.gridButtonsArea}>
                    {component.buttons.map((button, index) => {
                        const clientButtonActionName = useUniqueId('clientButtonAction');
                        if (button.actionType == ActionType.Client) {
                            useScript({ functionName: clientButtonActionName, functionBody: button.action });
                        }
                        return (
                            <Tooltip title={button.text}>
                            <Button
                                disableRipple
                                variant={button.style}
                                key={index}
                                color="primary"
                                className={classes.gridButton}
                                onClick={() => handleButtonClick(button, clientButtonActionName)}>
                                {button.text}
                            </Button>
                            </Tooltip>
                        );
                    })}
                </div>
            </>
        );
    };

    return (
        <div
            data-testid="page-grid"
            className={classNames(classes.root, externalClasses?.root)}
            style={style}
            ref={ref}>
            <DataGrid
                classes={{
                    root: classes.grid,
                }}
                slots={{
                    columnSortedAscendingIcon: sortIcon(),
                    columnSortedDescendingIcon: sortIcon('desc'),
                    footer: footerArea,
                    toolbar: headerArea,
                }}
                rows={data}
                columns={gridColumns}
                columnHeaderHeight={HEADER_HEIGHT}
                getRowHeight={() => component.rowHeight ?? 'auto'}
                disableRowSelectionOnClick
                disableColumnSelector
                hideFooterPagination
                sortingMode="server"
                onSortModelChange={handleSortChange}
                sortModel={sortModel}
                getRowClassName={(param) =>
                    `MuiDataGrid-gridRow-${
                        ((param.id as string).split('-')[0] as unknown as number) % 3 === 0 ? 'delimiter' : 'none'
                    }`
                }
            />

            <WarningAlertDialog
                warningActionResponse={warningResponse}
                components={component}
                gridValues={gridValues}
            />
        </div>
    );
});

GridView.displayName = 'GridView';
