import { Box, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { some } from 'lodash';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { runScript } from '../../hooks/useScript';
import { IMenuItem } from '../../reducers/types';
import { LegacyActionType } from '../../reducers/types.legacy';
import { useAppDispatch } from '../../store';
import {
    ComponentType,
    PageComponent,
    DialogIds,
    isLayoutComponent,
    LayoutComponent,
    ViewScreenPageStyle,
    ContentHolder,
    setPrintToExecute,
} from '../../store/slices';
import { config } from '../../target/t360/config';
import { AnnouncementsPane } from '../announcements';
import { AppTheme } from '../app';
import { CurrencyToggle } from '../common/pageHeader/currency/currencyToggle.component';
import { PageHeader } from '../common/pageHeader/pageHeader';
import { PageFooter } from '../core/pageFooter';
import { CustomizeGadgetsDialog, SelectedGadget } from '../home/gadgets/customize/customizeGadgetsDialog';
import { useViewScreen, useJsCommunication } from './hooks';
import {
    LayoutView,
    GridView,
    FilterView,
    SummaryView,
    FacetMenuView,
    DashboardPowerBiView,
    CustomPrintHandle,
} from './views';

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        ...theme.styles.page.root,
        overflowY: 'auto',
    },
    header: {
        ...theme.styles.page.header,
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    homeContent: {
        ...theme.styles.page.content,
        flexDirection: 'column',
        opacity: 0,
        padding: 'initial',
        border: 'none',
        backgroundColor: 'transparent',
        zIndex: 0,
        minHeight: 0,

        '&>*:not(:first-child)': {
            marginTop: theme.spacing(2),
        },
    },
    regularContent: {
        ...theme.styles.page.content,
        flexDirection: 'column',
        opacity: 0,
        padding: 'initial',
        borderWidth: '1px',
        borderColor: '#DADADA',
        borderStyle: 'solid',
        backgroundColor: 'white',
        minHeight: 0,
        minWidth: 1000,

        '&>*:not(:first-child)': {
            marginTop: theme.spacing(2),
        },
    },
    contentLoaded: {
        opacity: 1,
    },
    headerLinkContainer: {
        paddingRight: theme.spacing(2),
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        marginRight: theme.spacing(2),
    },
    headerLink: {
        cursor: 'pointer',
        fontSize: theme.typography.fontSize,
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
}));

// eslint-disable-next-line max-lines-per-function
export const ViewScreen: FC = () => {
    const classes = useStyles();
    const { isLoading, pageState, layout, updateLayoutPreferences, updateGadgetCatalogAccess, updatePageData } =
        useViewScreen();
    const [dialogId, setDialogId] = useState<DialogIds | undefined>(undefined);
    const { pageMenu, title, data: pageData, pagePrint } = pageState;
    const [sharedStorage, setSharedStorage] = useState<Record<string, unknown>>({});
    const printContentRef = useRef<HTMLDivElement>(null);
    const layoutViewRef = useRef<CustomPrintHandle | null>(null);
    const dispatch = useAppDispatch();

    const handlePrint = useReactToPrint({
        removeAfterPrint: true,
        nonce: config.get('REACT_APP_CSP_NONCE'),
        print: pageData?.components?.find(isLayoutComponent) ? layoutViewRef.current?.customToPrint : undefined,
        onBeforePrint: () => {
            const printWindow = document.getElementById('printWindow') as HTMLIFrameElement;
            const printContent = printWindow.contentDocument ?? printWindow.contentWindow?.document ?? undefined;

            while (printContent?.body?.firstChild) {
                const { firstChild } = printContent.body;
                if (firstChild.nodeType === 3) {
                    printContent.body.removeChild(firstChild);
                } else {
                    break;
                }
            }
        },
    });

    useJsCommunication();

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        if (!pagePrint?.execute) {
            return;
        }
        handlePrint?.(null, () => printContentRef.current);
        dispatch(setPrintToExecute({ execute: false }));
    }, [pagePrint?.execute]);

    const onCloseDialog = (): void => {
        setDialogId(undefined);
    };
    const openDialog = (dialogId: DialogIds | undefined): void => {
        setDialogId(dialogId);
    };

    const onComponentChange = ({ component }: { component: PageComponent }): void => {
        const updatedData = {
            ...pageData,
            components: pageData?.components?.map((item) => (item.id === component.id && component) || item),
        };
        updatePageData(updatedData);

        if (isLayoutComponent(component)) {
            void updateLayoutPreferences(component, component.components);
        }
    };

    const onSetSharedStorage = (key: string, value: unknown): void => {
        const newSharedStorage = { ...sharedStorage };
        newSharedStorage[key] = value;
        setSharedStorage(newSharedStorage);
    };

    const handleLayoutChange = async (layout: LayoutComponent, selectedGadgets: SelectedGadget[]): Promise<void> => {
        setDialogId(undefined);
        await updateLayoutPreferences(layout, selectedGadgets, true);
    };

    const handleUseDefaultSelection = async (layout: LayoutComponent): Promise<void> => {
        await updateGadgetCatalogAccess(layout.catalogName);
    };

    const renderComponents = (contentHolder: ContentHolder): JSX.Element => (
        <>
            {pageData?.components
                ?.filter((component) => component.contentHolder === contentHolder)
                ?.map((component, index) => {
                    const { type } = component;
                    if (type === ComponentType.FacetMenuComponent) {
                        return <FacetMenuView component={component} key={index} />;
                    }
                    if (type === ComponentType.SummaryComponent) {
                        return <SummaryView component={component} key={index} />;
                    }
                    if (type === ComponentType.GridComponent) {
                        return (
                            <GridView
                                component={component}
                                sharedStorage={sharedStorage}
                                key={index}
                                hasFilter={Boolean(
                                    pageData.components?.find(
                                        (item) =>
                                            item.type === ComponentType.FilterComponent &&
                                            item.dataSource === component.dataSource,
                                    ),
                                )}
                            />
                        );
                    }
                    if (type === ComponentType.FilterComponent) {
                        return <FilterView component={component} onSetSharedStorage={onSetSharedStorage} key={index} />;
                    }
                    if (type === ComponentType.AnnouncementsComponent) {
                        return <AnnouncementsPane announcements={component?.data || []} key={index} />;
                    }
                    if (type === ComponentType.LayoutComponent) {
                        return (
                            <LayoutView
                                component={component}
                                onChange={onComponentChange}
                                key={index}
                                ref={layoutViewRef}
                            />
                        );
                    }
                    if (type === ComponentType.DashboardPowerBiComponent) {
                        return <DashboardPowerBiView component={component} key={index} />;
                    }
                    return;
                })}
        </>
    );

    const renderPageContent = (): JSX.Element => (
        <div
            ref={printContentRef}
            className={classNames(
                pageData?.pageStyle === ViewScreenPageStyle.Regular ? classes.regularContent : classes.homeContent,
                {
                    [classes.contentLoaded]: !isLoading,
                },
            )}>
            {renderComponents(ContentHolder.PageContent)}
        </div>
    );

    const onPageMenuItemClick = (item: IMenuItem) => {
        const { children, legacyActionDetails, url, isNewWindow } = item;
        if (!legacyActionDetails) {
            return;
        }
        const { clientActionScript, actionType } = legacyActionDetails;
        if (children?.length) {
            return;
        }
        if (isNewWindow) {
            window.open(url, '_blank');
            return;
        }
        if (actionType === LegacyActionType.Client && clientActionScript) {
            runScript(clientActionScript);
        }
    };

    const hasLeftPanel = useMemo(
        () => some(pageData?.components, { contentHolder: ContentHolder.LeftPanel }),
        [pageData?.components],
    );

    return (
        <>
            <div className={classes.root}>
                <PageHeader
                    classes={{ root: classes.header }}
                    title={title}
                    pageMenu={pageMenu}
                    onPageMenuItemClick={onPageMenuItemClick}
                    inProgress={isLoading}>
                    {!isLoading &&
                        pageData?.pageHeaderLinks?.map(
                            (item, index) =>
                                item.dialogId && (
                                    <Box className={classes.headerLinkContainer} key={index}>
                                        <Link
                                            classes={{ root: classes.headerLink }}
                                            onClick={(): void => {
                                                openDialog(item.dialogId);
                                            }}>
                                            {item.name}
                                        </Link>
                                    </Box>
                                ),
                        )}
                    {!isLoading && <CurrencyToggle />}
                </PageHeader>
                {hasLeftPanel ? (
                    <div className={classes.rowContainer}>
                        <div>{renderComponents(ContentHolder.LeftPanel)}</div>
                        {renderPageContent()}
                    </div>
                ) : (
                    <>{renderPageContent()} </>
                )}
                {layout && (
                    <CustomizeGadgetsDialog
                        component={layout}
                        open={dialogId === DialogIds.CustomizeGadget}
                        onClose={onCloseDialog}
                        onChange={handleLayoutChange}
                        onUseDefault={handleUseDefaultSelection}
                    />
                )}
            </div>
            <PageFooter />
        </>
    );
};
