import { FormControl, Grid, Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { AppTheme } from '../../../../components/app';
import { PageHeader } from '../../../../components/common/pageHeader/pageHeader';
import { PageFooter } from '../../../../components/core/pageFooter';
import { useAppSelector } from '../../../../store';
import { getAppResources } from '../../../../store/slices';

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        ...theme.styles.page.root,
    },
    header: {
        ...theme.styles.page.header,
    },
    content: {
        ...theme.styles.page.content,
        flexDirection: 'column',
        overflowY: `auto`,
        flexBasis: 0,
    },
    subText: {
        color: theme.palette.grey[600],
        fontSize: theme.typography.caption.fontSize,
        width: '100%',
        pointerEvents: 'none',
        transformOrigin: 'top left',
    },
    boldTitleText: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: 600,
        width: '100%',
        pointerEvents: 'none',
    },
    boldContentText: {
        color: theme.palette.text.secondary,
        fontWeight: 500,
        width: '100%',
        pointerEvents: 'none',
    },
    standardText: {
        color: theme.palette.text.secondary,
        width: '100%',
        pointerEvents: 'none',
    },
    formRoot: {
        marginBottom: 20,
    },
}));

// eslint-disable-next-line max-lines-per-function
export const Contacts = () => {
    const classes = useStyles();
    const { appName, helpPageContactLink } = useAppSelector(getAppResources);
    const title = helpPageContactLink.replace('{appName}', appName);

    return (
        <>
            <div className={classes.root}>
                <PageHeader classes={{ root: classes.header }} title={title} />
                <div className={classes.content}>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl className={classes.formRoot}>
                                <Typography className={classes.boldTitleText}>
                                    Wolters Kluwer ELM Solutions Client Care Center Support Hours
                                </Typography>
                                <Typography className={classes.standardText}>
                                    Available 24 hours* Mon-Fri, excluding all holidays observed by Wolters Kluwer ELM
                                    Solutions Inc.
                                </Typography>
                                <Typography className={classes.standardText}>
                                    *If the Client Network has Global Service coverage, service begins at 8:00 PM ET on
                                    Sunday.
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formRoot}>
                                <Typography className={classes.boldTitleText}>
                                    All Corporate Law Department and Insurance Users
                                </Typography>
                                <Typography className={classes.subText}>Phone</Typography>
                                <Typography className={classes.boldContentText}>
                                    Contact the Product Support Team at (860) 240-9059 or toll free using the numbers
                                    below.
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formRoot}>
                                <Typography className={classes.subText}>Email</Typography>
                                <Grid item>
                                    <Link href="mailto:T360Support@wolterskluwer.com">
                                        T360Support@wolterskluwer.com
                                    </Link>
                                </Grid>
                                <Typography className={classes.standardText}>
                                    To help ensure faster resolution, please include any examples and steps to re-create
                                    the issue you are experiencing. Feel free to include screen captures if they help in
                                    describing the issue.
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formRoot}>
                                <Typography className={classes.boldTitleText}>All Law Firms and Vendors</Typography>
                                <Typography className={classes.subText}>Phone</Typography>
                                <Typography className={classes.boldContentText}>
                                    Contact the Vendor Support Team at (860) 549-8795 or toll free using the numbers
                                    below.
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formRoot}>
                                <Typography className={classes.subText}>Email</Typography>
                                <Grid item>
                                    <Link href="mailto:T360Support@wolterskluwer.com">
                                        T360Support@wolterskluwer.com
                                    </Link>
                                </Grid>
                                <Typography className={classes.standardText}>
                                    To help ensure faster resolution, please include the name of the T360 client you are
                                    billing. For invoicing issues, include the TyMetrix Transaction Number found in the
                                    invoice submission report email you received from T360.
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formRoot}>
                                <Typography className={classes.subText}>Mail (USPS)</Typography>
                                <Typography className={classes.boldContentText}>
                                    Wolters Kluwer ELM Solutions
                                </Typography>
                                <Typography className={classes.boldContentText}>
                                    115 Glastonbury Blvd., Glastonbury, CT 06033
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formRoot}>
                                <Typography className={classes.subText}>Phone</Typography>
                                <Typography className={classes.boldContentText}>
                                    (860) 549-8795 or toll free using the numbers below
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formRoot}>
                                <Typography className={classes.subText}>Fax</Typography>
                                <Typography className={classes.boldContentText}>(860) 549-5149</Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formRoot}>
                                <Typography className={classes.boldTitleText}>
                                    Client Care Center Toll Free Phone Support
                                </Typography>
                                <Typography className={classes.standardText}>
                                    The below toll free numbers have been established for our corporate and law firm
                                    customers to reach our Client Care Center. Please use the number next to the country
                                    from which you are calling.
                                </Typography>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldTitleText}>Argentina</Typography>
                            <Typography className={classes.subText}>Phone</Typography>
                            <Typography className={classes.boldContentText}>08002661656</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldTitleText}>Brazil</Typography>
                            <Typography className={classes.subText}>Phone</Typography>
                            <Typography className={classes.boldContentText}>08000474516</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldTitleText}>China</Typography>
                            <Typography className={classes.subText}>Phone</Typography>
                            <Typography className={classes.boldContentText}>4009985092</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldTitleText}>France</Typography>
                            <Typography className={classes.subText}>Phone</Typography>
                            <Typography className={classes.boldContentText}>0805542572</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldTitleText}>Germany</Typography>
                            <Typography className={classes.subText}>Phone</Typography>
                            <Typography className={classes.boldContentText}>08007239763</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldTitleText}>India</Typography>
                            <Typography className={classes.subText}>Phone</Typography>
                            <Typography className={classes.boldContentText}>0008004405201</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldTitleText}>Italy</Typography>
                            <Typography className={classes.subText}>Phone</Typography>
                            <Typography className={classes.boldContentText}>800931563</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldTitleText}>Netherlands</Typography>
                            <Typography className={classes.subText}>Phone</Typography>
                            <Typography className={classes.boldContentText}>08003949029</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldTitleText}>Saudi Arabia</Typography>
                            <Typography className={classes.subText}>Phone</Typography>
                            <Typography className={classes.boldContentText}>8008449449</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldTitleText}>United Kingdom</Typography>
                            <Typography className={classes.subText}>Phone</Typography>
                            <Typography className={classes.boldContentText}>08000284036</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.boldTitleText}>United States/Canada</Typography>
                            <Typography className={classes.subText}>Phone</Typography>
                            <Typography className={classes.boldContentText}>(877) 333-2568</Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <PageFooter />
        </>
    );
};
