import { IValidationTransformer } from './types';
import { ICHValidateEditDocument, IValidateEditDocument } from '../interfaces/operations';

import 'reflect-metadata';
import { injectable } from 'inversify';
import { factory } from '../configLog4J';
const log = factory.getLogger('CtxValidateEditDocumentTransformer');

@injectable()
export class CtxValidateEditDocumentTransformer implements IValidationTransformer {
    data!: IValidateEditDocument;

    transform(): ICHValidateEditDocument {
        const nodeObj = {} as ICHValidateEditDocument;

        if (this.data?.nameOnly) {
            nodeObj.nameOnly = this.data?.nameOnly;
        }
        if (this.data?.description) {
            nodeObj.comments = this.data?.description;
        }
        if (this.data?.editAccessType) {
            nodeObj.editAccessType = this.data?.editAccessType;
        }
        if (this.data?.viewAccessType) {
            nodeObj.viewAccessType = this.data?.viewAccessType;
        }
        if (this.data?.docId) {
            nodeObj.documentId = this.data?.docId;
        }
        if (this.data?.default) {
            nodeObj.default = this.data.default;
        }

        log.debug('printing nodeObj: ' + JSON.stringify(nodeObj));
        return nodeObj;
    }
}
