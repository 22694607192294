import { createSlice } from '@reduxjs/toolkit';

export interface UserScreenState {
    isUserScreenDialogOpen: boolean;
    userScreenDialogKey: number;
}

const initialState: UserScreenState = {
    isUserScreenDialogOpen: false,
    userScreenDialogKey: 0,
};

const userScreen = createSlice({
    name: 'user-screen',
    initialState,
    reducers: {
        openUserScreenDialog: (state) => {
            state.isUserScreenDialogOpen = true;
            // TODO: Change date to uuid.v4()
            state.userScreenDialogKey = new Date().getTime();
        },
        closeUserScreenDialog: (state) => {
            state.isUserScreenDialogOpen = false;
            state.userScreenDialogKey = 0;
        },
    },
});

// Actions
export type UserScreenActions = TakeActions<typeof userScreen.actions>;
export const { openUserScreenDialog, closeUserScreenDialog } = userScreen.actions;
export const userScreenReducer = userScreen.reducer;
