import queryString from 'query-string';
import { DeepMap, FieldError } from 'react-hook-form';
import { ScreenMode } from '../common/types';
import { isOC } from '../contextLayerService/contextLayerService';
import { IParentItemInfo } from '../listScreen/types';
import { IItemScreenProps } from './itemScreen';
import {
    IItemScreenField,
    IItemScreenJSON,
    IItemScreenOperation,
    IItemScreenState,
    IItemScreenTabMetadata,
} from './types';

export const META_FIELD_NAME_PREFIX = '__';

export const getOperationsForMode = (operations: IItemScreenOperation[], mode?: string): IItemScreenOperation[] => {
    return operations.filter((operation) => {
        const isShowAndNotOC = operation.displayName === 'Show' && !isOC();
        if (mode === 'show') {
            return isShowAndNotOC ? false : operation.isDisplayOnShow;
        } else if (mode === 'edit') {
            return isShowAndNotOC ? false : operation.isDisplayOnEdit;
        }
        return operation.isDisplayOnAdd;
    });
};

export const getItemScreenPropsFromUrl = (url: string): IItemScreenProps => {
    const qs = queryString.parse(queryString.extract(url)) as Record<string, string>;
    const screenId = parseInt(qs.id || qs.screenId);
    return {
        screenId,
        entityInstanceId: qs.entityInstanceId ? parseInt(qs.entityInstanceId) : undefined,
        filterDefinitionId: qs.filterDefinitionId ? parseInt(qs.filterDefinitionId) : undefined,
        parentItemInfo: getParentItemInfo(qs),
        mode: getMode(qs),
        selectedRowId: qs.selectedRowId ? parseInt(qs.selectedRowId) : undefined,
        entityTypeId: qs.entityTypeId ? qs.entityTypeId : undefined,
    };
};

export const getReactHookFormFieldName = (field: IItemScreenField, isForCurrencyControlOnMoney = false): string => {
    let reactHookFormFieldName = field.name;
    if (isForCurrencyControlOnMoney) {
        reactHookFormFieldName += '_currency';
    } else if (field.controlType === 'Money') {
        reactHookFormFieldName += '_amount';
    } else if (field.controlType === 'Multi-Autocomplete') {
        reactHookFormFieldName = field.name.replace('[]', '');
    }
    return reactHookFormFieldName;
};

export const getValidationMessagesForField = (
    field: IItemScreenField,
    errors: DeepMap<Record<string, any>, FieldError>,
    type: 'errors' | 'warnings',
): string[] => {
    return errors[getReactHookFormFieldName(field)]?.types[type] || [];
};

// item screen tabs helpers

export const getActiveParentAndSubTabMeta = (state: IItemScreenState): (IItemScreenTabMetadata | undefined)[] => {
    const parentTabMeta = state.visitedTabs?.find((vt) => vt.index === state.activeTab);
    const subTabMeta = parentTabMeta?.visitedSubTabs?.find((vst) => vst.index === parentTabMeta.activeSubTab);
    return [parentTabMeta, subTabMeta];
};

export const isActiveTabASubTab = (state: IItemScreenState): boolean => {
    const [, activeSubTab] = getActiveParentAndSubTabMeta(state);
    return activeSubTab !== undefined;
};

export const getParentTabMetaByIndex = (
    state?: IItemScreenState,
    index?: number,
): IItemScreenTabMetadata | undefined => {
    return state?.visitedTabs.find((vt) => index === vt.index);
};

export const generateParentItemInfoObject = (
    itemScreenJson: IItemScreenJSON,
    parentFieldName: string,
): IParentItemInfo => {
    return {
        parentEntityId: itemScreenJson.metadata.entityId.toString(),
        parentInstanceId: (itemScreenJson.item.id as unknown as number).toString(),
        parentInstanceDisplayName: itemScreenJson.metadata.objDisplayValue,
        parentFieldName,
        parentEntityName: itemScreenJson.metadata.entityName,
    };
};

// private methods

const getParentItemInfo = (qs: any): IParentItemInfo | undefined => {
    if (!qs.parentEntityId) {
        return undefined;
    }
    return {
        parentEntityId: qs.parentEntityId,
        parentInstanceId: qs.parentInstanceId,
        parentFieldName: qs.parentFieldName,
        parentEntityName: qs.parentEntityName,
        parentInstanceDisplayName: '',
    };
};

const getMode = (qs: any): ScreenMode => {
    if (qs.mode) {
        return qs.mode.toLowerCase();
    }
    if (qs.entityInstanceId || qs.filterDefinitionId) {
        return 'edit';
    }
    return 'add';
};
