import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import composeRefs from '@seznam/compose-react-refs';
import { debounce } from 'lodash';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useIsMounted from '../../../hooks/useIsMounted';
import { useAppSelector } from '../../../store';
import { getAppResources } from '../../../store/slices';
import UUIButton from '../../common/uuiButton';
import { UUIInput } from '../../common/uuiInput';
import WkWarningIcon from '../../icons/wkWarningIcon';
import { getReactHookFormFieldName, getValidationMessagesForField } from '../itemScreenHelpers';
import { ControlTypeProps } from '../types';
import { ControlLabel } from './controlLabel';
import css from './singleOrMultiLineText.module.scss';

const SingleOrMultiLineText: React.FC<ControlTypeProps> = ({ field, fieldData, readOnly }) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [inputText, setInputText] = useState({ value: fieldData.displayValue || '', shortenedBeforePaste: false });
    const [enableModal, setEnableModal] = useState(false);
    const { register, errors } = useFormContext();
    const isMounted = useIsMounted();
    const appResources = useAppSelector(getAppResources);

    useLayoutEffect(() => {
        const measureTextAreaHeight = debounce(() => {
            if (!isMounted.current) {
                return;
            }
            const OFFSET_HEIGHT_BUFFER = 2;
            if (
                field.controlType === 'Multi Line Text' &&
                readOnly &&
                textAreaRef &&
                textAreaRef.current &&
                textAreaRef.current.scrollHeight - (textAreaRef.current.offsetHeight + OFFSET_HEIGHT_BUFFER) > 0
            ) {
                setEnableModal(true);
            } else {
                setEnableModal(false);
            }
        }, 100);
        window.addEventListener('resize', measureTextAreaHeight);
        measureTextAreaHeight();
        return () => window.removeEventListener('resize', measureTextAreaHeight);
    }, [textAreaRef, setEnableModal, field, readOnly, isMounted]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        const inputValue = event.target.value || '';
        if (inputValue.length > field.size) {
            setInputText({ value: inputValue.substring(0, field.size), shortenedBeforePaste: false });
            return;
        }
        setInputText({ ...inputText, value: inputValue });
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        event.preventDefault();

        const { value, selectionStart, selectionEnd } = event.currentTarget;
        const pastedText: string = event.clipboardData.getData('text');
        const inputValue = `${value.substring(0, selectionStart ?? 0)}${pastedText}${value.substring(
            selectionEnd ?? 0,
            value.length,
        )}`;
        setInputText({
            value: inputValue.substring(0, field.size),
            shortenedBeforePaste: inputValue.length > field.size,
        });
    };
    return (
        <>
            <ControlLabel
                data-testid="singleOrMultiLineTextField-testId"
                field={field}
                control={
                    <>
                        <UUIInput
                            id={getReactHookFormFieldName(field)}
                            name={getReactHookFormFieldName(field)}
                            inputRef={readOnly ? textAreaRef : composeRefs(textAreaRef, register)}
                            value={inputText.value}
                            multiline={readOnly || field.controlType === 'Multi Line Text'}
                            onChange={handleChange}
                            readOnly={readOnly}
                            className={readOnly && enableModal ? css.expandableInput : undefined}
                            error={getValidationMessagesForField(field, errors, 'errors').length > 0}
                            warning={getValidationMessagesForField(field, errors, 'warnings').length > 0}
                            classes={{
                                multiline: inputText.shortenedBeforePaste ? css.outline : undefined,
                                root: inputText.shortenedBeforePaste ? css.outline : undefined,
                            }}
                            inputProps={{
                                onPaste: handlePaste,
                                'data-testid': field.displayName,
                            }}
                        />
                        {!readOnly &&
                        (field.controlType === 'Multi Line Text' || field.controlType === 'Single Line Text') ? (
                            <div className={css.counterText}>
                                {inputText.shortenedBeforePaste ? (
                                    <div className={css.charLimitWarningContainer} data-testid={'charLimitWarning'}>
                                        <WkWarningIcon className={css.wkWarningIcon} />
                                        <span className={css.charLimitWarningMsg}>
                                            {appResources.charactersLimitReachedWarning.replace(
                                                '{count}',
                                                `${field.size}`,
                                            )}
                                        </span>
                                    </div>
                                ) : (
                                    <>
                                        {field.controlType === 'Multi Line Text' &&
                                            appResources.charactersRemainingText.replace(
                                                '{count}',
                                                `${field.size - inputText.value.length}`,
                                            )}
                                    </>
                                )}
                            </div>
                        ) : null}
                    </>
                }
            />
            {enableModal && <MultiLineTextModal title={field.displayName} content={inputText.value} />}
        </>
    );
};

interface IMultiLineTextModal {
    title: string;
    content: string;
}

const MultiLineTextModal: React.FC<IMultiLineTextModal> = ({ title, content }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const appResources = useAppSelector(getAppResources);

    return (
        <div className={css.readMoreButtonContainer}>
            <UUIButton size="small" color="primary" className={css.readMoreButton} onClick={() => setDialogOpen(true)}>
                ...{appResources.itemscreenReadMore}
            </UUIButton>
            <Dialog
                open={dialogOpen}
                fullWidth
                onClose={() => setDialogOpen(false)}
                aria-labelledby="textareadialog-title">
                <DialogTitle id="textareadialog-title" className={css.dialogTitle}>
                    <Typography variant="h6">{title}</Typography>
                    <IconButton
                        className={css.dialogCloseButton}
                        aria-label={appResources.close}
                        onClick={() => setDialogOpen(false)}
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ paddingTop: 0 }}>
                    <TextField
                        defaultValue={content}
                        multiline={true}
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            className: css.dialogText,
                        }}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default SingleOrMultiLineText;
