/* eslint-disable @typescript-eslint/no-magic-numbers */
import { ScrollbarColorProperty, ScrollbarWidthProperty } from 'csstype';
import { AppTheme } from '../useAppTheme.hook';

/* Note: Webkit properties are only for Webkit based browsers (e.g., Chrome, Edge, Opera, Safari, all browsers on iOS etc)
    scrollbarWidth & scrollbarColor are the only available standardized styles which supports non-webkit based browsers like Mozilla.*/
type CustomScrollBar = {
    scrollbarWidth: ScrollbarWidthProperty;
    scrollbarColor: ScrollbarColorProperty;
    transition: string;
    '&:hover': {
        scrollbarWidth: string;
    };
    '&:not(:hover)::-webkit-scrollbar-thumb': {
        background: string;
        scrollbarWidth: string;
    };
    '&::-webkit-scrollbar': { width: string | number };
    '&::-webkit-scrollbar-thumb': {
        borderRadius: number;
        transition: string;
        background: string;
    };
};

export const scrollbar = (theme: AppTheme) => {
    const defaultColor = '#C1C1C1';
    const primaryColor = '#00000066';

    const customizeScrollbar = (color: string): CustomScrollBar => ({
        scrollbarWidth: 'none',
        scrollbarColor: `${color} transparent`,
        transition: 'scrollbar-color .3s',
        '&::-webkit-scrollbar': {
            width: theme.spacing(1),
        },
        '&:hover': {
            scrollbarWidth: 'thin',
        },

        '&:not(:hover)::-webkit-scrollbar-thumb': {
            background: 'transparent',
            scrollbarWidth: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: 7,
            transition: 'background 0.3s',
            background: color,
        },
    });

    return { default: customizeScrollbar(defaultColor), primary: customizeScrollbar(primaryColor) };
};
