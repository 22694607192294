import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { FC } from 'react';
import { AppTheme } from '../../../../app';

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        border: `1px solid ${theme.palette.grey[500]}`,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: parseInt(theme.spacing()) + 1,
        boxSizing: 'border-box',
        lineHeight: '28px',

        '&:hover': {
            borderColor: theme.palette.primary.dark,
            borderWidth: 2,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: theme.spacing(),
        },

        '&:focus': {
            borderColor: theme.palette.common.black,
            borderWidth: 2,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: theme.spacing(),
            backgroundColor: 'transparent',
        },
    },
    icon: {
        top: 0,
        bottom: 0,
        margin: 'auto 0',
        right: `${theme.spacing()} !important`,
    },
}));

type SelectProps = Omit<MuiSelectProps, 'IconComponent' | 'disableUnderline'>;

export const Select: FC<SelectProps> = (props) => {
    const classes = useStyles();
    const { classes: externalClasses = {}, ...selectProps } = props;

    return (
        <MuiSelect
            variant="standard"
            classes={{
                ...externalClasses,
                select: classNames(classes.root, externalClasses.select),
                icon: classNames(classes.icon, externalClasses.icon),
            }}
            IconComponent={ExpandMoreIcon}
            disableUnderline
            {...selectProps}
        />
    );
};
