import { useState, useEffect } from 'react';
import { IIdleTimer } from 'react-idle-timer';

export const useRemainingTime = <Callback extends IIdleTimer['getRemainingTime']>(
    fn: Callback,
    timeout: number,
): { remainingTime: number } => {
    const [remainingTime, setRemainingTime] = useState(fn);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRemainingTime(fn());
        }, timeout);
        return (): void => clearInterval(intervalId);
    }, []);

    return { remainingTime };
};
