import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { AppTheme } from '../../../../app';

const useStyles = makeStyles<AppTheme>((appTheme) => ({
    root: {
        '& .MuiPaper-root': {
            borderWidth: '0px',
        },
    },
    accordion: {
        border: `1px solid ${appTheme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    summary: {
        backgroundColor: appTheme.palette.background.default,
        minHeight: '36px',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(0deg)',
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            transform: 'rotate(270deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: appTheme.spacing(1),
        },
        '& .MuiTypography-root': {
            fontFamily: appTheme.typography.fontFamily,
            fontSize: 13,
            fontWeight: '500',
            letterSpacing: 'normal',
            color: appTheme.palette.action.active,
        },
        '& .MuiSvgIcon-root': {
            color: appTheme.palette.action.active,
        },
    },
    details: {
        padding: appTheme.spacing(3),
        borderTop: `1px solid ${appTheme.palette.grey[300]}`,
        paddingLeft: '0px',
        paddingRight: '0px',
    },
}));

// const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
//     () => {
//         const appTheme = useAppTheme();
//         return {
//             border: `1px solid ${appTheme.palette.divider}`,
//             '&:not(:last-child)': {
//                 borderBottom: 0,
//             },
//             '&:before': {
//                 display: 'none',
//             },
//         };
//     },
// );
//
// const AccordionSummary = styled((props: AccordionSummaryProps) => (
//     <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
// ))(() => {
//     const appTheme = useAppTheme();
//     return {
//         backgroundColor: appTheme.palette.background.default,
//         minHeight: '36px',
//         flexDirection: 'row-reverse',
//         '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//             transform: 'rotate(0deg)',
//         },
//         '& .MuiAccordionSummary-expandIconWrapper': {
//             transform: 'rotate(270deg)',
//         },
//         '& .MuiAccordionSummary-content': {
//             marginLeft: appTheme.spacing(1),
//         },
//         '& .MuiTypography-root': {
//             fontFamily: appTheme.typography.fontFamily,
//             fontSize: 13,
//             fontWeight: '500',
//             letterSpacing: 'normal',
//             color: appTheme.palette.action.active,
//         },
//         '& .MuiSvgIcon-root': {
//             color: appTheme.palette.action.active,
//         },
//     };
// });
//
// const AccordionDetails = styled(MuiAccordionDetails)(() => {
//     const appTheme = useAppTheme();
//     return {
//         padding: appTheme.spacing(3),
//         borderTop: `1px solid ${appTheme.palette.grey[300]}`,
//         paddingLeft: '0px',
//         paddingRight: '0px',
//     };
// });

export type FieldProps = {
    isExpanded?: boolean;
    title: string;
};

export const SummaryAccordion: FC<FieldProps> = (props) => {
    const { isExpanded, children, title } = props;
    const [expanded, setExpanded] = React.useState(isExpanded);
    const classes = useStyles();

    const handleChange = (newExpanded: boolean): void => {
        setExpanded(newExpanded);
    };

    return (
        <div className={classes.root}>
            <Accordion
                classes={{ root: classes.accordion }}
                disableGutters
                elevation={0}
                square
                expanded={expanded}
                onChange={(): void => handleChange(!expanded)}>
                <AccordionSummary
                    classes={{ root: classes.summary }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-content"
                    id="panel-header">
                    <Typography>{expanded ? `Hide ${title}` : `Show ${title}`}</Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.details }}>
                    <Typography component={'span'}>{children}</Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};
