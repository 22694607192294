import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkErrorIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="WkErrorIcon" viewBox="0 0 16 16" {...props} style={{ color: '#AC1822' }}>
        <g>
            <path
                id="Color"
                d="M11.4,16H4.6L0,11.4V4.6L4.6,0h6.9L16,4.6v6.9L11.4,16z M8,8.8L8,8.8l2.6,2.6l0.8-0.8L8.8,8
		l2.6-2.6l-0.8-0.8L8,7.2L5.4,4.6L4.6,5.4L7.2,8l-2.6,2.6l0.8,0.8L8,8.8z"
            />
            <rect style={{ fill: 'none' }} width="16" height="16" />
        </g>
    </SvgIcon>
);

export default WkErrorIcon;
