import React from 'react';
import CheckBoxControl from './controlTypes/checkBoxControl';
import CurrencyControl from './controlTypes/currencyControl';
import DateTimePickerControl from './controlTypes/dateTimePicker';
import { DropdownListControl } from './controlTypes/dropdownListControl';
import DropdownTreeControl from './controlTypes/dropdownTreeControl';
import MoneyControl from './controlTypes/moneyControl';
import MultiSelectAutoComplete from './controlTypes/multiSelectAutoComplete';
import MultiSelectList from './controlTypes/multiSelectList';
import { RadioAmountChangerControl } from './controlTypes/radioAmountChangerControl';
import ReadOnlyControl from './controlTypes/readOnlyControl';
import { SingleAutoComplete } from './controlTypes/singleAutocomplete';
import SingleOrMultiLineText from './controlTypes/singleOrMultiLineText';
import { ControlType, ControlTypeProps } from './types';

const ItemScreenField: React.FC<ControlTypeProps> = ({
    field,
    fieldData,
    mode,
    stickyTopPosition,
    tabIndex,
    parentTabIndex,
}) => {
    if (!fieldData && field.controlType !== 'Multi-Select List') {
        return null;
    }

    const getControl = (controlType: ControlType) => {
        const readonly = mode === 'show' || field.readonly;
        switch (controlType) {
            case 'Single Line Text':
            case 'Multi Line Text':
                return <SingleOrMultiLineText field={field} fieldData={fieldData} readOnly={readonly} />;
            case 'Multi-Select List':
                return (
                    <MultiSelectList
                        field={field}
                        fieldData={fieldData}
                        mode={mode}
                        stickyTopPosition={stickyTopPosition}
                        tabIndex={tabIndex}
                        parentTabIndex={parentTabIndex}
                    />
                );
            case 'CheckBox':
                return <CheckBoxControl field={field} fieldData={fieldData} readOnly={readonly} />;
            case 'Dropdown List':
                return <DropdownListControl field={field} fieldData={fieldData} readOnly={readonly} />;
            case 'Single-Autocomplete':
                return <SingleAutoComplete field={field} fieldData={fieldData} readOnly={readonly} />;
            case 'Money':
                return <MoneyControl field={field} fieldData={fieldData} readOnly={readonly} />;
            case 'Currency':
                return <CurrencyControl field={field} fieldData={fieldData} readOnly={readonly} />;
            case 'Date Picker':
            case 'Date/Time Picker':
                return <DateTimePickerControl field={field} fieldData={fieldData} readOnly={readonly} />;
            case 'Multi-Autocomplete':
                return <MultiSelectAutoComplete field={field} fieldData={fieldData} readOnly={readonly} />;
            case 'FolderPicker':
                return <DropdownTreeControl field={field} fieldData={fieldData} readOnly={readonly} />;
            case 'AmountPicker':
                return <RadioAmountChangerControl field={field} fieldData={fieldData} readOnly={readonly} />;
            default:
                // default to readonly for unsupported controlTypes
                return <ReadOnlyControl field={field} fieldData={fieldData} />;
        }
    };

    return getControl(field.controlType);
};

export default ItemScreenField;
