import { DividerProps, Divider, useForkRef } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { cloneElement, forwardRef, ReactElement, Ref, useCallback, useEffect, useRef, useState } from 'react';
import { AppTheme } from '../../app';

const RESIZER_WIDTH = 80;
const RESIZER_HEIGHT = 6;

const useStyles = makeStyles<AppTheme, ResizerProps>((theme) => ({
    root: {
        position: 'relative',
        opacity: 0,
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.short,
        }),

        '&:hover, &:active': {
            opacity: 1,

            '& $dragItem': {
                display: 'flex',
            },
        },
    },
    disabled: {
        visibility: 'hidden',
    },
    hidden: {
        display: 'none',
    },
    isResizerRestricted: {
        '&:active': {
            '&:after': {
                borderColor: '#f6b1b6 !important',
            },
        },
    },
    divider: {
        height: theme.spacing(2),
        cursor: 'ns-resize',
        borderColor: 'transparent',
        border: 0,

        '&:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: 0,
            width: '100%',
            borderTop: `1px solid ${theme.palette.grey[300]}`,
        },
    },
    dividerVertical: {
        width: theme.spacing(2),
        height: '100%',
        cursor: 'ew-resize',
        border: 0,

        '&:after': {
            top: 0,
            left: '33%',
            width: 'unset',
            height: '100%',
            borderTop: 'none',
            borderLeft: `6px solid ${theme.palette.grey[300]}`,
        },
    },
    image: {
        fontSize: 24,
    },
    dragItem: {
        transform: 'translateY(-50%) rotate(0deg)',
        backgroundColor: theme.palette.grey[300],
        position: 'absolute',
        top: '50%',
        left: 0,
        marginLeft: -(RESIZER_WIDTH / 2),
        height: RESIZER_HEIGHT,
        width: RESIZER_WIDTH,
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'ns-resize',

        '$dividerVertical + &': {
            top: RESIZER_WIDTH,
            marginLeft: 0,
            marginTop: -(RESIZER_HEIGHT / 2),
            left: '50%',
            transform: 'translateX(-50%) rotate(90deg)',
            cursor: 'ew-resize',
        },
    },
    resizable: {
        '.resizer-active-vertical &, .resizer-active-horizontal &': {
            '&, & iframe, & *': {
                userSelect: 'none !important',
                pointerEvents: 'none !important',
            },
        },
    },
    '@global': {
        '.resizer-active-vertical *': {
            cursor: 'ew-resize !important',
        },
        '.resizer-active-horizontal *': {
            cursor: 'ns-resize !important',
        },
    },
}));

export interface ResizerChangeParams {
    offset: number;
    movement: number;
    orientation: DividerProps['orientation'];
}

export type ResizerProps = Omit<DividerProps, 'onChange'> & {
    disabled?: boolean;
    hidden?: boolean;
    classes?: DividerProps['classes'] & { divider?: string; dragItem?: string };
    onChange: (params: ResizerChangeParams) => void;
    children: ReactElement;
    isResizerRestricted?: boolean;
};

// eslint-disable-next-line max-lines-per-function
export const Resizer = forwardRef<HTMLHRElement, ResizerProps>((props, ref) => {
    const {
        children,
        classes: externalClasses,
        onChange,
        orientation = 'horizontal',
        disabled,
        hidden,
        isResizerRestricted,
        style,
        ...dividerProps
    } = props;
    const classes = useStyles(props);
    const resizableAreaRef = useRef<HTMLElement>(null);
    const childrenProps = {
        ref: useForkRef(children?.props?.['ref'] as Ref<HTMLElement>, resizableAreaRef),
        className: classNames(children?.props?.['className'] as string, classes.resizable),
    };
    const dragRef = useRef<HTMLSpanElement>(null);
    const rootRef = useRef<HTMLSpanElement>(null);
    const [dragPosition, setDragPosition] = useState<{ left?: number; top?: number }>();
    const [active, setActive] = useState<boolean>();

    const handleMouseDown = () => {
        setActive(true);
    };

    const handleMouseMove = useCallback(
        (event: MouseEvent) => {
            const { clientX, clientY, movementX, movementY } = event;
            const rect = resizableAreaRef.current?.getBoundingClientRect();
            const offset = orientation === 'vertical' ? clientX - (rect?.left || 0) : clientY - (rect?.top || 0);
            const movement = orientation === 'vertical' ? movementX : movementY;
            onChange({ offset, movement, orientation });
            event.preventDefault();
            event.stopPropagation();
        },
        [orientation, onChange],
    );

    useEffect(() => {
        if (!active) {
            return;
        }

        const stopMouseHandle = () => {
            document.removeEventListener('mouseup', handleMouseUp, { capture: true });
            document.removeEventListener('mousemove', handleMouseMove, { capture: true });
            document.body.classList.remove('resizer-active-vertical');
            document.body.classList.remove('resizer-active-horizontal');
        };

        const handleMouseUp = () => {
            setActive(false);
            stopMouseHandle();
        };

        document.addEventListener('mouseup', handleMouseUp, { capture: true });
        document.addEventListener('mousemove', handleMouseMove, { capture: true });
        document.body.classList.add(
            orientation === 'horizontal' ? 'resizer-active-horizontal' : 'resizer-active-vertical',
        );

        return () => {
            stopMouseHandle();
        };
    }, [active, handleMouseMove]);

    const handleMouseOverMove = useCallback(
        (event: React.MouseEvent) => {
            if (!dragRef.current || !rootRef.current) {
                return;
            }

            const dragRect = rootRef.current.getBoundingClientRect();
            const sideSize = RESIZER_WIDTH / 2;

            if (orientation === 'horizontal') {
                let position = event.clientX - dragRect.left;
                if (position < sideSize) {
                    position = sideSize;
                }

                if (position > dragRect.width - RESIZER_WIDTH / 2) {
                    position = dragRect.width - RESIZER_WIDTH / 2;
                }

                setDragPosition({ left: position });
            }

            if (orientation === 'vertical') {
                let position = event.clientY - dragRect.top;
                if (position < sideSize) {
                    position = sideSize;
                }

                if (position > dragRect.height - RESIZER_WIDTH / 2) {
                    position = dragRect.height - RESIZER_WIDTH / 2;
                }

                setDragPosition({ top: position });
            }
        },
        [dragRef.current, orientation],
    );

    return (
        <>
            {children && !disabled && !hidden ? cloneElement(children, childrenProps) : children}
            <span
                ref={rootRef}
                style={style}
                className={classNames(classes.root, externalClasses?.root, {
                    [classes.disabled]: disabled,
                    [classes.hidden]: hidden,
                })}
                onMouseMove={handleMouseOverMove}
                onMouseDown={handleMouseDown}>
                <span>
                    <Divider
                        ref={ref}
                        classes={{
                            ...externalClasses,
                            root: classNames(classes.divider, externalClasses?.divider),
                            vertical: classNames(classes.dividerVertical, externalClasses?.vertical, {
                                [classes.isResizerRestricted]: isResizerRestricted,
                            }),
                        }}
                        orientation={orientation}
                        aria-orientation={orientation}
                        role="separator"
                        {...dividerProps}
                    />
                    {orientation !== 'vertical' && (
                        <span
                            ref={dragRef}
                            style={dragPosition}
                            className={classNames(classes.dragItem, externalClasses?.dragItem)}
                        />
                    )}
                </span>
            </span>
        </>
    );
});

Resizer.displayName = 'Resizer';
