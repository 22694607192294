import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { Fragment, memo, PropsWithChildren, useMemo } from 'react';
import { useMediaQueryExecute } from '../../../hooks/useMediaQueryExecute';
import { useAppSelector } from '../../../store';
import { FavoriteEntity, FavoriteFolderEntity, FavoriteType, selectPersistState } from '../../../store/slices';
import { AppTheme } from '../../app';
import { FavoritesMenuItem } from './favoritesMenuItem';

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        display: 'flex',
    },
    divider: {
        height: 'auto',
        margin: theme.spacing(1, 0),
        borderColor: theme.palette.primary.dark,
        opacity: 0.5,

        '&:last-child': {
            display: 'none',
        },
    },
    moreButton: {
        flex: 'none',
    },
    moreButtonLabel: {
        maxWidth: 'none',
    },
}));

type FavoritesMenuProps = PropsWithChildren<{
    classes?: {
        root?: string;
        button?: string;
        menu?: string;
    };
    tree?: FavoriteEntity;
    onClick?: (item: FavoriteEntity) => void;
}>;

export const FavoritesMenu = memo(function FavoritesMenuComponent(props: FavoritesMenuProps) {
    const classes = useStyles();
    const { tree, onClick, classes: externalClasses = {} } = props;
    const mainMenuExpanded = useAppSelector(selectPersistState('mainMenuExpanded'));
    const pinned = useAppSelector(selectPersistState('favoritesBarPinned'));
    const visibleItems = useMediaQueryExecute<number>(
        {
            default: () => 0,
            md: () => (pinned && mainMenuExpanded ? 3 : 4),
            lg: () => (pinned && mainMenuExpanded ? 5 : 6),
            xl: () => (pinned && mainMenuExpanded ? 8 : 9),
        },
        [mainMenuExpanded],
    );

    const { items, more } = useMemo(() => {
        if (tree?.type !== FavoriteType.Folder || !tree.children?.length) {
            return {};
        }

        const items = tree.children.slice(0, visibleItems);
        const moreItems = tree.children.slice(visibleItems);
        const more: FavoriteFolderEntity | undefined = (Boolean(moreItems?.length) || undefined) && {
            id: Math.min(),
            name: `${visibleItems ? 'More' : 'Favorites'} (${moreItems.length})`,
            type: FavoriteType.Folder,
            children: moreItems,
        };
        return { items, more };
    }, [tree, visibleItems]);

    const onMenuClick = (item: FavoriteEntity) => {
        onClick?.(item);
    };

    return (
        <div className={classNames(classes.root, externalClasses?.root)}>
            {items?.map((item) => (
                <Fragment key={item.id}>
                    <FavoritesMenuItem classes={externalClasses} showIcon item={item} onMenuClick={onMenuClick} />
                    <Divider orientation="vertical" classes={{ root: classes.divider }} />
                </Fragment>
            ))}
            {more && (
                <FavoritesMenuItem
                    key={more?.id}
                    classes={{
                        ...externalClasses,
                        button: classNames(classes.moreButton, externalClasses.button),
                        buttonLabel: classes.moreButtonLabel,
                    }}
                    item={more}
                    onMenuClick={onMenuClick}
                />
            )}
        </div>
    );
});
