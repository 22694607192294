import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkCircleArrowIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 30 30" data-testid="WkCircleArrowIcon" {...props}>
        <path d="M1.2,1.1h2.3v4.3c5.3-6.3,14.7-7.2,21.1-1.9s7.2,14.7,1.9,21.1s-14.7,7.2-21.1,1.9C2,23.6,0,19.4,0,15h2.3C2.4,22,8,27.6,15,27.6S27.7,22,27.7,15S22.1,2.3,15,2.3c0,0,0,0,0,0C10.7,2.3,6.7,4.4,4.4,8h6.1v2.3H1.2V1.1z" />
    </SvgIcon>
);

export default WkCircleArrowIcon;
