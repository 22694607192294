import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkHelpIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="WkHelpIcon" viewBox="0 0 24 24" {...props}>
        <g transform="translate(0 0)">
            <path
                style={{ fill: '#05659f' }}
                d="M7.978,16a8.03,8.03,0,1,1,5.741-2.45c-.035.036-.071.071-.107.107A7.921,7.921,0,0,1,7.978,16Zm0-14.892a6.945,6.945,0,1,0,4.94,2.1l-.062-.062A6.861,6.861,0,0,0,7.978,1.108Zm.537,11.743H7.442V11.03H8.515Zm0-3.219H7.442A3.163,3.163,0,0,1,8.716,6.878,1.911,1.911,0,0,0,9.588,5.34c0-.592-.722-1.074-1.61-1.074a1.877,1.877,0,0,0-1.248.4,1.532,1.532,0,0,0-.495,1.207H5.162A2.606,2.606,0,0,1,7.68,3.186a2.7,2.7,0,0,1,.3.007,3.054,3.054,0,0,1,1.895.621,1.946,1.946,0,0,1,.788,1.526A2.69,2.69,0,0,1,9.55,7.547,2.43,2.43,0,0,0,8.515,9.632Z"
            />
            <rect style={{ fill: 'none' }} width="16" height="16" />
        </g>
    </SvgIcon>
);

export default WkHelpIcon;
