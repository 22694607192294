import { IAddDocument, IAddEmail, ICheckIn, IMatterDoc, ITenantInfo, IUploadType, IUserProfile } from '../types';
import convert from 'xml-js';
import { getAPIServer, getNetworkId, getUserId_T360 } from '../t360Adapter/utils/utils';
import { IAdapterInfo } from '../dmInfoHandler/types';
import { getUserId_JR } from '../jRAdapter/utils/utils';
import { IAddDocument_T360, IWindowProps } from '../t360Adapter/types';
import { isT360 } from './shared.utils';

export const getTenantId = async (): Promise<string> => {
    if (isT360()) {
        return getNetworkId();
    } else {
        return '1';
    }
};

export const getHostName = (): string => {
    if (isT360()) {
        const windProps = (window as unknown as IWindowProps).Props;
        const arrayElem = windProps['apiContextRoot'].match(/http[s]?:\/\/[^/]*/);
        if (arrayElem && arrayElem?.length > 0) {
            return arrayElem[0];
        } else {
            throw new Error('Not able to find HostName');
        }
    } else {
        return window.location.origin;
    }
};

/**
 * Ti remove the extension from fileName
 * @param name fileName
 */
export const getFileNameWithoutExtension = (name: string): string => {
    if (name.indexOf('.') > 0) {
        return name.substr(0, name.lastIndexOf('.'));
    } else {
        return name;
    }
};

export const baseUrl = (): string => {
    if (isT360()) {
        return getAPIServer();
    } else {
        return window.location.origin + '/' + window.location.href.split('/')[3];
    }
};

export const getBaseUrl = (): string => {
    return window.location.origin;
};

export const getUserId = async (): Promise<string> => {
    if (isT360()) {
        return (await getUserId_T360()).toString();
    } else {
        return (await getUserId_JR()).toString();
    }
};

// FIXME: remove this and related unused methods? most of them exist only for this (except getBaseUrl probably)
export const getUserProfileObj = async (): Promise<IUserProfile> => {
    const userProfileObj = {} as IUserProfile;
    userProfileObj.baseUrl = getBaseUrl();
    userProfileObj.userId = await getUserId();
    userProfileObj.tenantInfo = await getTenantInfo();
    return userProfileObj;
};

export const getUniqueIdForDoc = async (nodeObj: IUploadType, dmInfoObj: IAdapterInfo): Promise<string> => {
    const dm = dmInfoObj.adapter.slice(0, 1).toUpperCase();
    const tenantInfo = await getTenantId();
    let folderId = '1';
    let idorName = '';

    const addDocument = nodeObj as IAddDocument;
    const checkinDoc = nodeObj as ICheckIn;
    const addEmailDoc = nodeObj as IAddEmail;

    const addDocument_t360 = addDocument as IAddDocument_T360;
    if (addDocument_t360?.documentFolderId) {
        folderId = addDocument_t360.documentFolderId;
    } else if (addDocument?.folderPath) {
        folderId = addDocument.folderPath;
    }

    // TO DO understand whether it is document name
    let docType = '_D';
    if (checkinDoc?.docId) {
        idorName = checkinDoc.docId;
    } else if (addDocument?.name) {
        idorName = addDocument.name;
    }

    // If Email
    if (addEmailDoc.extendedProps.emailBody || addEmailDoc.extendedProps.emailSubject) {
        docType = '_E';
        if (addEmailDoc?.extendedProps?.uniqueId) {
            idorName = addEmailDoc?.extendedProps?.uniqueId;
        }
    }

    const returnString = dm + tenantInfo + '_M' + nodeObj.entityContext.id + '_F' + folderId + docType + idorName;
    return returnString;
};

export const getTenantInfo = async (): Promise<ITenantInfo> => {
    if (isT360()) {
        return { id: await getNetworkId(), name: 'T360_tenant' };
    } else {
        // for all other dms other than T360
        return { id: '1', name: 'JR_tenant' };
    }
};

const MapmatterDetails = {
    longMatterName: 'relatedEntityName',
    primaryInternalPerson: 'picId',
    practiceAreaBusinessUnit: 'pabuId',
    id: 'id',
};

export interface IMapMetadataFolder {
    id: stringNull;
}

type stringNull = string | null;
export interface IMapMetdata {
    id: stringNull;
    documentFileName: stringNull;
    webDavPath: stringNull;
    parentLink: stringNull;
    documentSize: stringNull;
    name: stringNull;
    description: stringNull;
    createdBy: stringNull;
    creationDate: stringNull;
    lastModifiedBy: stringNull;
    lastModificationDate: stringNull;
    changeToken: stringNull;
    type: stringNull;
    baseTypeId: stringNull;
    isFolder: stringNull;
    parentId: stringNull;
    path: stringNull;
    checkInComment: stringNull;
    contentStreamId: stringNull;
    contentStreamFileName: stringNull;
    contentStreamLength: stringNull;
    contentStreamMimeType: stringNull;
    isImmutable: stringNull;
    versionLabel: stringNull;
    versionSeriesId: stringNull;
    isVersionSeriesCheckedOut: stringNull;
    versionSeriesCheckedOutBy: stringNull;
    versionSeriesCheckedOutId: stringNull;
    isLatestMajorVersion: stringNull;
    isLatestVersion: stringNull;
    isMajorVersion: stringNull;
    isPrivateWorkingCopy: stringNull;
    isVersionable: stringNull;
    isVersionSeriesPrivateWorkingCopy: stringNull;
    contentFile: stringNull;
    relatedEntityType: stringNull;
    relatedEntityName: stringNull;
    relatedEntityId: stringNull;
    isArchived: stringNull;
    archivedDate: stringNull;
    pabuId: stringNull;
    picId: stringNull;
    isEmailMessage: stringNull;
    isEmailRoot: stringNull;
    senderEmailAddress: stringNull;
    emailSubject: stringNull;
    senderName: stringNull;
    emailRecipients: stringNull;
    isAttachmentPresent: stringNull;
    emailBody: stringNull;
    receivedTime: stringNull;
    sentTime: stringNull;
    uniqueId: stringNull;
    docStorId: stringNull;
    contentSearch: stringNull;
    isRecursiveSearch: stringNull;
    immediateParentId: stringNull;
    fileKey: stringNull;
    accessToken: stringNull;
    downloadUrl: stringNull;
    latestVersion: stringNull;
    networkId: stringNull;
}

export const mapMetdata: IMapMetdata = {
    id: '',
    documentFileName: '',
    webDavPath: '',
    parentLink: '',
    documentSize: '',
    name: '',
    description: '',
    createdBy: '',
    creationDate: '',
    lastModifiedBy: '',
    lastModificationDate: '',
    changeToken: '',
    type: '',
    baseTypeId: '',
    isFolder: '',
    parentId: '',
    path: '',
    checkInComment: '',
    contentStreamId: '',
    contentStreamFileName: '',
    contentStreamLength: '',
    contentStreamMimeType: '',
    isImmutable: '',
    versionLabel: '',
    versionSeriesId: '',
    isVersionSeriesCheckedOut: '',
    versionSeriesCheckedOutBy: '',
    versionSeriesCheckedOutId: '',
    isLatestMajorVersion: '',
    isLatestVersion: '',
    isMajorVersion: '',
    isPrivateWorkingCopy: '',
    isVersionable: '',
    isVersionSeriesPrivateWorkingCopy: '',
    contentFile: '',
    relatedEntityType: '',
    relatedEntityName: '',
    relatedEntityId: '',
    isArchived: '',
    archivedDate: '',
    pabuId: '',
    picId: '',
    isEmailMessage: '',
    isEmailRoot: '',
    senderEmailAddress: '',
    emailSubject: '',
    senderName: '',
    emailRecipients: '',
    isAttachmentPresent: '',
    emailBody: '',
    receivedTime: '',
    sentTime: '',
    uniqueId: '',
    docStorId: '',
    contentSearch: '',
    isRecursiveSearch: '',
    immediateParentId: '',
    fileKey: '',
    accessToken: '',
    downloadUrl: '',
    latestVersion: '',
    networkId: '',
};

export const mapMetdataFolder: IMapMetadataFolder = {
    id: '',
};

export function convertToMapMetaData(map: IMapMetdata, jsonOutput: Record<string, string>): IMapMetdata {
    const finalMap = Object.assign({}, mapMetdata);

    for (const key in mapMetdata) {
        if (map[key] != null) {
            finalMap[key] = jsonOutput[map[key]];
        }
    }

    return finalMap;
}

export function convertToMapMetaDataFolder(
    map: IMapMetadataFolder,
    jsonOutput: Record<string, string>,
): IMapMetadataFolder {
    const finalMap = Object.assign({}, mapMetdataFolder);

    for (const key in mapMetdata) {
        if (map[key] != null) {
            finalMap[key] = jsonOutput[map[key]];
        }
    }

    return finalMap;
}

export const getJsonObjFromXmlData: (xmlData: string) => IMatterDoc = (xmlData) => {
    const data = convert.xml2json(xmlData);

    const dataObj = JSON.parse(data);
    const arrayObj = dataObj['elements'][0]['elements'][0]['elements'];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {};
    arrayObj.forEach((objVal: { [x: string]: { [x: string]: string | number } }) => {
        let value;

        if (!('elements' in objVal)) {
            value = objVal['attributes']['value'];
        } else {
            value = objVal['elements'][0]['attributes']['href'].replace(/.*\//, '');
        }
        obj[MapmatterDetails[objVal['attributes']['name']]] = value;
    });

    return obj;
};

// Disabling builk as it is added in CL
// export const bulkOperation = async (nodeObj: IDocServiceRequest[], operation: any): Promise<Response[]> => {
//   const respList: Promise<Response>[] = [];
//   nodeObj.forEach((req) => respList.push(operation(req)));
//   // @ts-ignore
//   await Promise.allSettled(respList);
//   const responseList: Response[] = [];
//   respList.forEach(async (resp) => {
//     responseList.push(await resp);
//   });
//   return responseList;
// };

export const pathMapDetails = {
    Matter: 'M',
    Organization: 'O',
    Invoice: 'I',
    Person: 'P',
};

/**
 * To convert Blob or Buffer to  base64
 */
export const toBase64 = (file: Blob | Buffer): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        if (file instanceof Blob || file instanceof File) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve((<string>reader?.result).replace('data:', '').replace(/^.+,/, ''));
            };
            reader.onerror = (error) => reject(error);
        } else if (file instanceof Buffer) {
            resolve(file.toString('base64'));
        } else {
            reject(undefined);
        }
    });
