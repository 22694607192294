import { Button, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import React, { MouseEvent, useEffect, useMemo, useState, VFC } from 'react';
import { NotificationPlacement } from '../../../../reducers/types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { FavoriteEntity, FavoriteType, selectFavoritesTree, showNotification } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { BaseDialog, BaseDialogProps } from '../../../common';
import { getFavoriteOrFolder } from '../../favoritesTree.utils';
import { useFavorites } from '../../hooks';

const useStyles = makeStyles<AppTheme>(() => ({
    paper: {
        maxWidth: 560,
        width: '100%',
    },
    content: {
        boxSizing: 'content-box',
    },
    actions: {
        justifyContent: 'space-between',
    },
}));

export type RenameFavoriteDialogProps = Omit<BaseDialogProps, 'title' | 'actions'> & {
    entityId: FavoriteEntity['id'];
};

export const RenameFavoriteDialog: VFC<RenameFavoriteDialogProps> = (props) => {
    const classes = useStyles();
    const { entityId, onClose, classes: externalClasses = {}, ...baseDialogProps } = props;
    const dispatch = useAppDispatch();
    const { renameFavorite } = useFavorites();
    const tree = useAppSelector(selectFavoritesTree());
    const entity = useMemo(() => getFavoriteOrFolder(tree, entityId), [tree, entityId]);
    const [name, setName] = useState<string>();
    const getFormattedEntityName = (entity: FavoriteEntity): string =>
        `"${entity.name}"${entity.type === FavoriteType.Folder ? ' folder' : ''}`;

    useEffect(() => {
        setName(entity?.name);
    }, [entity]);

    const handleRename = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        if (!tree || !entity || !name) {
            return;
        }

        try {
            const renamedItem = await renameFavorite(entity, name);

            dispatch(
                showNotification({
                    notification: {
                        message: `${getFormattedEntityName(renamedItem)} has been renamed.`,
                        options: {
                            variant: 'success',
                        },
                        placement: NotificationPlacement.Top,
                    },
                }),
            );
        } catch (error) {
            // ignored // FIXME: fetch wrappers do a lot of non-generic stuff, requires refactoring
        }

        onClose?.(event, 'onAction');
    };

    return (
        <BaseDialog
            {...baseDialogProps}
            classes={{
                ...externalClasses,
                actions: classNames(classes.actions, externalClasses.actions),
                content: classNames(classes.content, externalClasses.content),
                paper: classNames(classes.paper, externalClasses.paper),
            }}
            onClose={onClose}
            title={`Rename  ${capitalize(entity?.type)}`}
            actions={
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleRename}
                    disabled={!name || name === entity?.name}
                    disableRipple>
                    Rename
                </Button>
            }>
            <FormControl variant="outlined">
                <InputLabel htmlFor="component-name">Name</InputLabel>
                <OutlinedInput
                    id="component-name"
                    autoFocus
                    value={name}
                    onChange={(event) => {
                        setName(event.currentTarget.value);
                    }}
                />
            </FormControl>
        </BaseDialog>
    );
};
