import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRefreshUUIForEntity } from '@wk/elm-uui-context-handler';
import { uniqueId } from 'lodash';
import { IMessageBus } from '../../reducers/types';

export type MessageBusState = IMessageBus;

const initialState: MessageBusState = {};

const messageBus = createSlice({
    name: 'message-bus',
    initialState,
    reducers: {
        refreshUuiForEntity: (state, action: PayloadAction<{ refreshUUIForEntity: IRefreshUUIForEntity }>) => {
            state.refreshUUIForEntityIsProcessed = false;
            state.refreshUUIForEntity = {
                ...action.payload.refreshUUIForEntity,
                key: uniqueId('RefreshUUIKey_'),
            };
        },
        markRefreshUUIForEntityAsProcessed: (state) => {
            state.refreshUUIForEntityIsProcessed = true;
            state.suppressNextRefresh = false;
        },
        suppressNextRefresh: (state) => {
            state.suppressNextRefresh = true;
        },
    },
});

// Actions & Reducer
export type MessageBusActions = TakeActions<typeof messageBus.actions>;
export const { refreshUuiForEntity, markRefreshUUIForEntityAsProcessed, suppressNextRefresh } = messageBus.actions;
export const messageBusReducer = messageBus.reducer;
