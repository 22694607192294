import { createAction, createListenerMiddleware } from '@reduxjs/toolkit';
import { AppStore, IMenuItem } from '../../reducers/types';
import { FavoriteEntity, FavoriteType, setAppsMenuState, setAppState, setFavorites } from '../slices';

export const initializeApp = createListenerMiddleware();

export const initialize = createAction<{
    externalState: AppStore;
}>('application/INITIALIZE');

initializeApp.startListening({
    actionCreator: initialize,
    effect: (action, { dispatch }) => {
        const { appsMenu, bookmarks, menuShortcuts = [], megaMenu, ...appState } = action.payload.externalState;

        const mapToFavorite = (items: IMenuItem[], shortcuts: string[], parentId: number): FavoriteEntity[] => {
            return items.reduce((agg, item) => {
                const { name, code, children } = item;
                if (code && shortcuts.includes(code)) {
                    agg.push({ id: code, parentId, name, type: FavoriteType.Shortcut, data: item });
                }

                if (children?.length) {
                    agg.push(...mapToFavorite(children, shortcuts, parentId));
                }

                return agg;
            }, [] as FavoriteEntity[]);
        };

        const favorites = bookmarks.type === FavoriteType.Folder && {
            ...bookmarks,
            name: 'Favorites',
            children: [...(bookmarks?.children || []), ...mapToFavorite(megaMenu, menuShortcuts, bookmarks.id)],
        };

        dispatch(setAppsMenuState({ state: appsMenu }));
        dispatch(setAppState({ state: { ...appState, megaMenu } }));
        if (favorites) {
            dispatch(setFavorites({ rootFavorite: favorites }));
        }
    },
});
