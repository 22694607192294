import * as H from 'history';
import { useHistory } from 'react-router-dom';
import { UUIHistoryState } from '../components/common/types';

export type UUIHistory = H.History<UUIHistoryState>;

export const useUUIHistory = (): UUIHistory => {
    const history = useHistory<UUIHistoryState>() as UUIHistory;
    return history;
};
