import { Tooltip, TooltipProps } from '@mui/material';
import React, { useState } from 'react';
import { useRef } from 'react';
import css from './uuiTooltip.module.scss';

interface IUUITooltip extends TooltipProps {
    alwaysShowTooltipOnHover?: boolean;
}

export const UUITooltip: React.FC<IUUITooltip> = (props) => {
    const [open, setOpen] = useState(false);
    const { alwaysShowTooltipOnHover, ...tooltipProps } = props;
    const tooltipRef = useRef<HTMLDivElement>(null);

    return (
        <Tooltip
            {...tooltipProps}
            open={open}
            onOpen={() => {
                if (open) {
                    return;
                }
                const tooltipChild = tooltipRef.current?.children[0] as HTMLElement;
                if (alwaysShowTooltipOnHover || !tooltipChild || tooltipChild.offsetWidth < tooltipChild.scrollWidth) {
                    setOpen(true);
                }
            }}
            onClose={() => setOpen(false)}
            title={props.title}
            classes={{ tooltip: css.tooltip, ...props.classes }}
            ref={tooltipRef}>
            <UUITooltipComponentWrapper>{props.children}</UUITooltipComponentWrapper>
        </Tooltip>
    );
};

const UUITooltipComponentWrapper = React.forwardRef<HTMLDivElement, React.PropsWithChildren<unknown>>(
    (props, ref: React.Ref<HTMLDivElement>) => (
        <div {...props} className={css.uuiTooltip} ref={ref}>
            {props.children}
        </div>
    ),
);

UUITooltipComponentWrapper.displayName = 'UUITooltipComponentWrapper';
