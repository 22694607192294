import { getLogger as elmGetLogger, Logger } from '@wk/elm-core-services';

export function getLogger(loggerName: string): Logger {
    const logger = elmGetLogger('UUI.client')(loggerName);

    logger.setLevel('error');

    return logger;
}

export function getEnvValue(key: string): string {
    return process.env[key] || '';
}
