import { makeStyles } from '@mui/styles';
import React, { VFC, useEffect, useRef, useState } from 'react';
import { AppTheme } from '../../../app/appTheme';
import { Tooltip } from '../../../common';

const useStyles = makeStyles<AppTheme>((theme) => ({
    networkLabelContent: {
        fontSize: 18,
        marginLeft: theme.spacing(2),
        lineHeight: '30px',
    },
    dvContainer: {
        borderLeft: `1px solid #FFFFFF`,
        marginLeft: theme.spacing(2),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        flex: 'auto',
    },
    tooltipPlacementBottom: {
        marginTop: -parseInt(theme.spacing(1)),
    },
}));

type NetworkLabelProps = {
    networkLabel: string;
};
export const NetworkLabel: VFC<NetworkLabelProps> = (props) => {
    const { networkLabel } = props;
    const classes = useStyles();
    const dvContainerRef = useRef<HTMLDivElement>(null);
    const [isTextOverflowing, setIsTextOverflowing] = useState(false);

    useEffect(() => {
        const isOverflowing =
        dvContainerRef.current && dvContainerRef.current.clientWidth < dvContainerRef.current.scrollWidth;
        setIsTextOverflowing(isOverflowing || false);
    }, [networkLabel]);

    return isTextOverflowing ? (
        <Tooltip
            title={networkLabel}
            classes={{ tooltipPlacementBottom: classes.tooltipPlacementBottom }}>
            <div ref={dvContainerRef} data-testid="container-network-label" className={classes.dvContainer}>
                <span
                    aria-label={networkLabel}
                    data-testid="network-label-content"
                    className={classes.networkLabelContent}>
                    {networkLabel}
                </span>
            </div>
        </Tooltip>
    ) : (
        <div ref={dvContainerRef} data-testid="container-network-label" className={classes.dvContainer}>
            <Tooltip
                title={networkLabel}
                classes={{ tooltipPlacementBottom: classes.tooltipPlacementBottom }}>
                <span
                    aria-label={networkLabel}
                    data-testid="network-label-content"
                    className={classes.networkLabelContent}>
                    {networkLabel}
                </span>
            </Tooltip>
        </div>
    );
};
