import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';

export const WkInfo = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon data-testid="wkInfo" viewBox="-3 -3 21 21" {...props} ref={ref}>
        <path
            d="M7.99982 15.986C6.15054 15.9876 4.35793 15.3479 2.92745 14.1759C1.49696 13.0039 0.517112 11.3722 0.154864 9.55872C-0.207384 7.74527 0.0703832 5.86232 0.940835 4.23071C1.81129 2.5991 3.22056 1.3198 4.92853 0.610795C6.63649 -0.0982109 8.53746 -0.19305 10.3075 0.342437C12.0776 0.877924 13.6072 2.0106 14.6357 3.54747C15.6642 5.08434 16.128 6.93029 15.9481 8.77079C15.7681 10.6113 14.9556 12.3325 13.6488 13.641C12.9076 14.384 12.0271 14.9736 11.0578 15.376C10.0885 15.7784 9.04934 15.9856 7.99982 15.986ZM7.99982 1.065C6.40227 1.05709 4.85148 1.60378 3.61206 2.61177C2.37264 3.61977 1.5214 5.02661 1.20358 6.59225C0.885767 8.15788 1.12107 9.78529 1.86935 11.1968C2.61763 12.6083 3.83251 13.7164 5.30669 14.332C6.78088 14.9476 8.42301 15.0327 9.95288 14.5726C11.4828 14.1125 12.8056 13.1357 13.6956 11.8091C14.5857 10.4824 14.9878 8.888 14.8334 7.29792C14.6789 5.70783 13.9775 4.2206 12.8488 3.09C12.2181 2.44399 11.4636 1.93172 10.6305 1.5838C9.79737 1.23588 8.90266 1.05943 7.99982 1.065ZM8.53282 12.789H7.46682V6.394H8.53282V12.789ZM8.53282 5.329H7.46682V3.197H8.53282V5.329Z"
            fill="currentColor"
        />
    </SvgIcon>
));

WkInfo.displayName = 'WkInfo';
