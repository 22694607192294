import { useEffect } from 'react';
import { useUUIHistory } from '../../../hooks';

enum JsMessageType {
    OpenPage = 'openPage',
}

interface JsMessageEvent {
    messageType: JsMessageType;
    payLoad?: string;
}

export const useJsCommunication = () => {
    const history = useUUIHistory();

    const onPostMessage = (event: MessageEvent<JsMessageEvent>) => {
        const { messageType, payLoad } = event.data;
        if (messageType === JsMessageType.OpenPage && payLoad) {
            history.push(payLoad);
        }
    };

    useEffect(() => {
        window.addEventListener('message', onPostMessage, false);

        return () => {
            window.removeEventListener('message', onPostMessage, false);
        };
    }, []);
};
