import { Link, SvgIconProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    DataGridPro,
    GridColDef,
    GridColumnHeaderParams,
    GridRenderCellParams,
    GridSortDirection,
    GridSortItem,
    GridSortModel,
    GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import classNames from 'classnames';
import { find, head, isEmpty, isNil, last } from 'lodash';
import React, { CSSProperties, forwardRef, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from '../../../../hooks';
import { IMenuItem } from '../../../../reducers/types';
import { useAppSelector, useAppDispatch } from '../../../../store';
import { LayoutGridComponent, selectSortItem, setGridSortByCode } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { Tooltip } from '../../../common';
import { TextEllipsis } from '../../../common/textEllipsis';
import { TargetScreenType } from '../../../common/types';
import { WkSort } from '../../../icons';
import { WkPriority } from '../../../icons/wkPriorityIcon';
import { WkTaskDefault } from '../../../icons/wkTaskDefaultIcon';
import { WkTaskPersonal } from '../../../icons/wkTaskPersonalIcon';
import { ViewHeader } from '../viewHeader';
import {
    HEADER_HEIGHT,
    ROW_HEIGHT,
    FLEX_WIDTH,
    LayoutGridViewData,
    SortOrder,
    MAX_COLUMN_WIDTH,
    MIN_COLUMN_WIDTH,
} from './common';
import { useLayoutGridView, useGridColumnResize } from './hooks';

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    grid: {
        flex: 1,
        fontSize: '1rem',
        marginTop: theme.spacing(2),
        minWidth: 50,

        // TODO: remove separator hide logic when turn on columns resize
        '& .MuiDataGrid-iconSeparator': {
            opacity: 0,
        },

        // * This style This style is necessary to solve the problem with blank space when we move vertical resizer handle to the right.
        '& .MuiDataGrid-main': {
            overflow: 'hidden',
        },

        '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: theme.palette.background.secondary,
            borderBottom: 'none',
            minHeight: 'unset',
            maxHeight: 'unset',
            lineHeight: 'unset !important',
        },

        '& .MuiDataGrid-columnHeader': {
            color: theme.palette.text.secondary,
            fontSize: theme.typography.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
            padding: theme.spacing(1.5, 0, 1.5, 2),

            '&:focus': { outline: 'none' },

            '&:first-child': {
                paddingLeft: theme.spacing(2),
            },
        },

        '& .MuiDataGrid-cell': {
            padding: theme.spacing(1, 0, 1, 2),

            '&:first-child': {
                paddingLeft: theme.spacing(2),
            },
        },

        '& .MuiDataGrid-columnHeaderTitleContainer': {
            gap: theme.spacing(),
        },
    },
    cellLink: {
        fontFamily: 'inherit',
        fontSize: 'inherit',
        textDecoration: 'none',
        ...theme.styles.ellipsis,
    },
    cellText: {
        ...theme.styles.ellipsis,
    },
    sort: {
        '$grid .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover &': {
            opacity: '1',
            fill: 'none',
        },
    },
    sortAsc: {
        transform: 'rotate(180deg)',
    },
    lowPriorityIcon: {
        fontSize: 17,
        color: theme.palette.primary.main,
    },
    mediumPriorityIcon: {
        fontSize: 17,
        color: theme.palette.warning.dark,
    },
    highPriorityIcon: {
        fontSize: 17,
        color: theme.palette.error.main,
    },
    taskIconDefault: {
        fontSize: 16,
        color: theme.palette.secondary.main,
    },
    taskIconPersonal: {
        fontSize: 16,
        color: theme.palette.secondary.main,
    },
}));

type LayoutGridProps = {
    component: LayoutGridComponent;
    classes?: {
        root?: string;
    };
    className?: string;
    style?: CSSProperties;
    onClose?: (props: { event: MouseEvent<HTMLButtonElement> }) => void;
    onChange?: (props: { event: MouseEvent<HTMLButtonElement>; minimized: boolean }) => void;
};

const enum Priority {
    Low = 'priority.low',
    Medium = 'priority.medium',
    High = 'priority.high',
}

// eslint-disable-next-line max-lines-per-function
export const LayoutGridView = forwardRef<HTMLDivElement, LayoutGridProps>((props, ref) => {
    const classes = useStyles();
    const { component, classes: externalClasses, className, style, onClose, onChange } = props;
    const defaultColumn = useMemo(() => find(component.columns, 'sort'), [component.columns]);
    const { navigate } = useNavigate();
    const dispatch = useAppDispatch();
    const sortItem = useAppSelector(selectSortItem(component?.code));
    const defaultSortModel = useMemo(() => {
        if (sortItem && !isEmpty(sortItem)) {
            return [sortItem as GridSortItem];
        }
        if (!isNil(defaultColumn)) {
            return [
                {
                    field: defaultColumn.fields,
                    sort: defaultColumn.sort as GridSortDirection,
                },
            ];
        }
        return [];
    }, [sortItem, defaultColumn]);

    const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel);
    const defaultsortItem = head(defaultSortModel);
    const {
        data = [],
        columns,
        download,
        getLayoutGrid,
    } = useLayoutGridView({
        component,
        sortItem: defaultsortItem
            ? {
                  field: defaultsortItem.field,
                  sort: defaultsortItem.sort as GridSortDirection,
              }
            : undefined,
    });

    const handleSortChange = async (model: GridSortModel): Promise<void> => {
        setSortModel(model);
        const sortItem = head(model);
        if (!isNil(component)) {
            dispatch(setGridSortByCode({ code: component.code, sortItem: sortItem ?? {} }));
        }

        await getLayoutGrid(component.dataSource, sortItem?.field, sortItem?.sort);
    };

    const getIconComponent = (iconKey: string): JSX.Element => {
        switch (iconKey) {
            case Priority.Low:
            case Priority.Medium:
            case Priority.High:
                return (
                    <WkPriority
                        classes={{
                            root: classNames({
                                [classes.highPriorityIcon]: iconKey === Priority.High,
                                [classes.mediumPriorityIcon]: iconKey === Priority.Medium,
                                [classes.lowPriorityIcon]: iconKey === Priority.Low,
                            }),
                        }}
                    />
                );
            case 'task.default':
                return <WkTaskDefault className={classes.taskIconDefault} />;
            case 'task.personal':
                return <WkTaskPersonal className={classes.taskIconPersonal} />;
            default:
                return <></>;
        }
    };

    const close = (event: MouseEvent<HTMLButtonElement>): void => {
        onClose?.({ event });
    };

    const collapse = (event: MouseEvent<HTMLButtonElement>): void => {
        onChange?.({ event, minimized: true });
    };

    const expand = (event: MouseEvent<HTMLButtonElement>): void => {
        onChange?.({ event, minimized: false });
    };

    // TODO: We need to figure out why, when we use useMemo, resizing doesn't work correctly in production, but works in local.
    const gridColumns: GridColDef[] = useMemo(() => {
        const renderCell = (params: GridRenderCellParams): JSX.Element | undefined => {
            const { value, link, icon } = params.row[params.field] as LayoutGridViewData;
            const tooltipText =
                (icon === Priority.High && 'High') ||
                (icon === Priority.Medium && 'Medium') ||
                (icon === Priority.Low && 'Low') ||
                value;

            return (
                <Tooltip title={tooltipText?.trim()} placement="top">
                    {link ? (
                        <Link
                            classes={{ root: classes.cellLink }}
                            component="button"
                            onClick={(): void => {
                                navigate({
                                    // TODO: set proper id base on menu items match
                                    menuItem: {
                                        screenType: TargetScreenType.Legacy,
                                        url: link,
                                        id: 0,
                                    } as IMenuItem,
                                });
                            }}>
                            {value}
                        </Link>
                    ) : !icon ? (
                        <span className={classes.cellText}>{value?.trim() || '-'}</span>
                    ) : (
                        getIconComponent(icon)
                    )}
                </Tooltip>
            );
        };

        const renderHeader = (params: GridColumnHeaderParams): JSX.Element => {
            return <TextEllipsis text={params.colDef.headerName ?? ''} />;
        };

        const valueGetter = (params: GridValueGetterParams): string | undefined => {
            const { value, link } = (params.row[params.field] as LayoutGridViewData) || {};
            return link || value;
        };

        return columns.map((column) => {
            const flex = last(columns) === column || !column.width ? FLEX_WIDTH : undefined;

            return {
                field: column.key,
                headerName: column.name,
                disableColumnMenu: true,
                flex,
                width: column.width,
                minWidth: MIN_COLUMN_WIDTH,
                maxWidth: MAX_COLUMN_WIDTH,
                renderHeader,
                renderCell,
                valueGetter,
            } as GridColDef;
        });
    }, [component.columns]);

    const sortIcon = (order: SortOrder = SortOrder.Asc) => {
        return function SortIcon(props: SvgIconProps): JSX.Element {
            return (
                <WkSort
                    width={10}
                    height={7}
                    {...props}
                    className={classNames(props.className, classes.sort, {
                        [classes.sortAsc]: order === SortOrder.Asc,
                    })}
                />
            );
        };
    };

    const { gridColumnResize } = useGridColumnResize();
    const [gridViewColumns, setGridViewColumns] = useState(columns);

    const handleColumnWidthChange = useCallback(
        async (newColumnWidth) => {
            const updatedColumnWidth = await gridColumnResize(
                component.dataSource,
                newColumnWidth.colDef.field as string,
                newColumnWidth.width as number,
                gridViewColumns,
            );
            setGridViewColumns(updatedColumnWidth);
        },
        [gridColumnResize],
    );

    const { title, description, buttons, isMinimized } = component;

    return (
        <div
            data-testid="layout-gadget-grid"
            className={classNames(className, classes.root, externalClasses?.root)}
            style={style}
            ref={ref}>
            <ViewHeader
                title={title}
                description={description}
                buttons={buttons}
                isMinimized={isMinimized}
                onClose={close}
                onCollapse={collapse}
                onExpand={expand}
                onDownloadExcel={download}
            />
            {!component.isMinimized && (
                <DataGridPro
                    classes={{
                        root: classes.grid,
                    }}
                    sortModel={sortModel}
                    onSortModelChange={handleSortChange}
                    slots={{
                        columnSortedAscendingIcon: sortIcon(),
                        columnSortedDescendingIcon: sortIcon(SortOrder.Desc),
                    }}
                    rows={data}
                    columns={gridColumns}
                    columnHeaderHeight={HEADER_HEIGHT}
                    rowHeight={ROW_HEIGHT}
                    sortingMode="server"
                    disableRowSelectionOnClick
                    disableColumnSelector
                    hideFooterPagination
                    hideFooter
                    disableAutosize={true}
                    onColumnWidthChange={handleColumnWidthChange}
                    disableColumnReorder
                    initialState={{ pinnedColumns: { left: component.pinnedColumn } }}
                />
            )}
        </div>
    );
});

LayoutGridView.displayName = 'LayoutGridView';
