import { Button as MuiButton, ButtonProps } from '@mui/material';
import { merge } from 'lodash';
import React from 'react';
import css from './uuiButton.module.scss';

const UUIButton = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const buttonProps = merge({}, props, { disableElevation: true, disableRipple: true });
    let buttonClasses;
    if (props.classes?.outlined || props.classes?.contained || props.classes?.root) {
        buttonClasses = props.classes;
    } else if (props.variant == 'outlined') {
        buttonClasses = merge({}, props.classes, { outlined: css.buttonOutlined });
    } else if (props.variant == 'contained') {
        buttonClasses = merge({}, props.classes, {
            contained: css.buttonBase,
            containedSizeLarge: css.containedButton.length > 2 ? css.buttons : css.containedButton,
        });
    } else {
        buttonClasses = merge({}, props.classes, { root: css.buttonBase });
    }

    return (
        <MuiButton ref={ref} classes={buttonClasses} {...buttonProps} className={css.button}>
            {props.children}
        </MuiButton>
    );
});

export default UUIButton;

UUIButton.displayName = 'UUIButton';
