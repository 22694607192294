import { RefObject, useEffect } from 'react';
import { useQuery, useUUIHistory } from '../../../hooks';
import { useAppSelector } from '../../../store';
import { getApplicationUrls, getAppResources } from '../../../store/slices';
import { clearLocationDataForOC } from '../../../utils/ocLocationService';
import { getAuthenticationProvider } from '../../authentication/AuthenticationProviderService';
import { determineRedirectUrl } from '../../common/screenHelpers';
import { IBaseOperation, TargetScreenType } from '../../common/types';
import { clGetQueue } from '../../contextLayerService/contextLayerService';
import { setPrintScript,setBookmarkPath } from '../../../store/slices';
import { useAppDispatch } from '../../../store';
import {LegacyPageMetadata} from './useExternalUIState.hook'
import { apiFetch } from '../../../utils/fetchUtils';

enum ExternalMessageType {
    WrongAccessToken = 'wrongAccessToken',
    OpenItemPage = 'openSpaItemPage',
    OpenListPage = 'openSpaListPage',
    OpenHybridPage = 'openHybridPage',
    OpenViewPage = 'openViewPage',
    UpdateHelpMenuForFacets = 'updateHelpMenuForFacets',
    UpdateBookMarkTitle = 'updateBookMarkTitle'
}

interface ExternalMessageEvent {
    messageType: ExternalMessageType;
    payLoad?: {
        screenId?: number;
        entityInstanceId?: number;
        url?: string;
        screenNameId?: string;
        parameters?: Record<string, string>;
        pageScript?:string;
        bookmarkPath?:string;
        pageTitle?:string;
    };
}

type UseExternalUICommunicationProps = {
    iframeRef: RefObject<HTMLIFrameElement>;
};

// eslint-disable-next-line max-lines-per-function
export const useExternalUICommunication = (props: UseExternalUICommunicationProps) => {
    const { iframeRef } = props;
    const applicationUrls = useAppSelector(getApplicationUrls);
    const { logoutUrl } = useAppSelector(getAppResources);
    const history = useUUIHistory();
    const { screenId } = useQuery<{ screenId: string }>();
	const dispatch = useAppDispatch();
    const logOut = async (): Promise<void> => {
        const queueManager = await clGetQueue();
        queueManager?.setQueuePaused();
        clearLocationDataForOC();
        const authProvider = getAuthenticationProvider();
        authProvider.signOut(logoutUrl, true);
    };

    const redirectToPage = ({ messageType, payLoad }: ExternalMessageEvent) => {
        let url: string | undefined;

        if (messageType === ExternalMessageType.OpenItemPage) {
            url = determineRedirectUrl({
                operation: {
                    targetScreenType: TargetScreenType.Item,
                    targetScreenId: payLoad?.screenId,
                    targetScreenMode: 'Show',
                } as IBaseOperation,
                entityInstanceId: payLoad?.entityInstanceId,
                applicationUrls,
            });
        }

        if (messageType === ExternalMessageType.OpenListPage) {
            url = determineRedirectUrl({
                operation: {
                    targetScreenType: TargetScreenType.List,
                    targetScreenId: payLoad?.screenId,
                } as IBaseOperation,
                applicationUrls,
            });
        }

        if (messageType === ExternalMessageType.OpenHybridPage && payLoad?.url) {            
            url = determineRedirectUrl({
                operation: {
                    targetScreenType: TargetScreenType.Legacy,
                    targetScreenId: screenId,
                    targetScreenMode: 'Show',
                    url: encodeURIComponent(payLoad.url),
                } as IBaseOperation,
                applicationUrls,
            });
        }

        if (messageType === ExternalMessageType.OpenViewPage && payLoad?.screenNameId) {
            url = determineRedirectUrl({
                operation: {
                    targetScreenType: TargetScreenType.View,
                    targetScreenName: payLoad.screenNameId,
                    targetScreenMode: 'Show',
                    url: payLoad.parameters && new URLSearchParams(payLoad.parameters).toString(),
                } as IBaseOperation,
                applicationUrls,
            });
        }

        if (url) {
            history.push(url);
        }
    };

    const { legacyContextRoot } = useAppSelector(getAppResources);
    const dispatchUrlChange = async (url?: string, bookmarkPath?: string): Promise<void> => {
        if (!url) {
            return;
        }
        const metadataUrl = new URL(url);
        const { searchParams } = metadataUrl;
        searchParams.set('spaRequestType', 'pageMetadata');
        searchParams.set('_c', 'n');
        metadataUrl.search = searchParams.toString();
        const facetPageState = await getPageScriptFromUrl(metadataUrl.toString());
        const {pageScript, pageScriptUrl} = facetPageState;
        if(pageScriptUrl){
            if(pageScript && pageScript.printPage){
                const facetPrintContent = pageScript.printPage.replace(/\s/g, '');
                if(facetPrintContent?.includes('functionprintPage(){returnfalse;};printPage();')){
                    const summaryPageState = await getPageScriptFromUrl(`${legacyContextRoot}${pageScriptUrl}`);
                    pageScript.printPage = summaryPageState?.pageScript?.printPage;
                }
            }
        }
        if(pageScript.printPage){
            dispatch(setPrintScript(pageScript.printPage));
        }
        if(bookmarkPath){
            dispatch(setBookmarkPath({
                title:undefined,
                bookmarkPath:bookmarkPath
            }));
        }
    };

    const getPageScriptFromUrl = async (url: string): Promise<LegacyPageMetadata> => {
        return await apiFetch<LegacyPageMetadata>(url, { skipTracking: true });
    };
    
    
    const onPostMessage = (event: MessageEvent<ExternalMessageEvent>) => {
        const { messageType, payLoad } = event.data;

        if (messageType === ExternalMessageType.WrongAccessToken) {
            void logOut();
        }

        if (
            messageType === ExternalMessageType.OpenItemPage ||
            messageType === ExternalMessageType.OpenListPage ||
            messageType === ExternalMessageType.OpenHybridPage ||
            messageType === ExternalMessageType.OpenViewPage
        ) {
            redirectToPage({ messageType, payLoad });
        }
        else if (messageType === ExternalMessageType.UpdateHelpMenuForFacets && payLoad?.url) {
            dispatchUrlChange(payLoad.url,payLoad.bookmarkPath);
        }
        else if (messageType === ExternalMessageType.UpdateBookMarkTitle && payLoad?.pageTitle) {
            dispatch(setBookmarkPath({
                title:payLoad.pageTitle,
                bookmarkPath:undefined
            }));
        }
    };

    useEffect(() => {
        window.addEventListener('message', onPostMessage, false);

        return () => {
            window.removeEventListener('message', onPostMessage, false);
        };
    }, []);

    const send = <TMessage = unknown, TOptions extends WindowPostMessageOptions = WindowPostMessageOptions>(
        message: TMessage,
        options?: TOptions,
    ) => {
        const { targetOrigin = '*', ...rest } = options || {};
        iframeRef.current?.contentWindow?.postMessage(message, { targetOrigin, ...rest });
    };

    return {
        send,
    };
};
