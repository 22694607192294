/* eslint-disable max-lines-per-function */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { CSSProperties, forwardRef, useState } from 'react';
import { useNavigate } from '../../../../hooks';
import { IMenuItem } from '../../../../reducers/types';
import { FacetMenuComponent } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { WkDoubleArrowRight } from '../../../icons';
import { FacetMenuComponentSourceFacetsViewData, useFacetMenuView } from './hooks';

type FacetMenuProps = {
    component: FacetMenuComponent;
    classes?: {
        root?: string;
    };
    style?: CSSProperties;
    selectedCode?: string;
};

const useStyles = makeStyles<AppTheme>((theme) => ({
    menuButton: {
        float: 'right',
    },
    menuButtonIcon: {
        transform: 'rotate(0deg) scale(0.8)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    menuButtonIconExpanded: {
        transform: 'rotate(180deg) scale(0.8)',
    },
    menuView: {
        width: '192px',
        backgroundColor: '#F6F6F6',
        overflowY: 'auto',

        '& #secondary-facets-expand-anchor': {
            fontSize: '12px',
        },

        '& #facet-list-display-toggle': {
            height: '12px',
            margin: '10px 10px 0 0',
            cursor: 'pointer',
        },

        '& #secondary-facet-container': {
            overflow: 'visible',
            position: 'static',
        },
    },
    menuContainer: {
        margin: '10px 0 0 10px',
        fontSize: '14px',
        listStyle: 'none',
        padding: '0',

        '& li': {
            float: 'right',
            listStyle: 'none',
            width: '100%',
            lineHeight: '30px',
            verticalAlign: 'middle',
            margin: '0 0 5px 10px',
            textAlign: 'left',
            backgroundColor: '#F6F6F6',

            '& a, a:link, a:visited, a:focus': {
                padding: '7px 10px 7px 10px',
                margin: '0 0',
                display: 'block',
                color: '#005b92',
                fontSize: '1.0em',
                lineHeight: '1.1em',
                borderRadius: '4px 0 0 4px',
                textAlign: 'left',
                wordWrap: 'break-word',

                '&:hover': {
                    color: '#003d61',
                },
            },

            '& a:hover, a:focus': {
                backgroundColor: '#F2F8FC',
                textDecoration: 'none',
            },

            '&.selected': {
                borderLeft: '5px solid #85BC20',

                '& a, a:hover, a:focus': {
                    color: '#353535',
                    backgroundColor: '#FFF',
                },
            },
        },
    },
    menuExpandContainer: {
        margin: '10px 0 10px 10px',
        listStyle: 'none',
    },
    menuViewCollapsed: {
        width: '50px',
        backgroundColor: '#e3f5fc',
    },

    menuContainerCollapsed: {
        '& li': {
            height: '100px',
            margin: '0',
            padding: '0',
            position: 'relative',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '30px',
            backgroundColor: '#e3f5fc',

            '& a, a:link': {
                margin: '0',
                padding: '0 6px',
                position: 'relative',
                top: '35px',
                width: '88px',
                height: '30px',
                fontSize: '11px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                transform: 'rotate(-90deg)',
                borderRadius: '0',
                lineHeight: '26px',
                left: '-35px',

                '&:hover': {
                    backgroundColor: '#e9e9e9',
                },

                '&.selected': {
                    '& a, a:hover': {
                        backgroundColor: 'white',
                    },
                },
            },
        },
    },
    chevron: {
        color: theme.palette.action.active,
    },
}));

export const FacetMenuView = forwardRef<HTMLDivElement, FacetMenuProps>((props, ref) => {
    const classes = useStyles();
    const { component, selectedCode } = props;
    const { navigate } = useNavigate();
    const [secondaryFacetsExpanded, setSecondaryFacetsExpanded] = useState(false);
    const [_code, setSelectedCode] = useState(selectedCode);
    const { data, toggleCollapse, isCollapsed } = useFacetMenuView({ component, selectedCode });

    const renderLink = (data: FacetMenuComponentSourceFacetsViewData): JSX.Element => {
        return (
            <Link
                href="javascript:void(0);"
                title={data.title}
                onClick={(): void => {
                    handleSelectedCode(data.code);
                    navigate({
                        menuItem: {
                            screenType: data.screenType,
                            url: data.url,
                            id: 0,
                        } as IMenuItem,
                    });
                }}>
                {data.name}
            </Link>
        );
    };

    const anySecondaryFacets = data?.secondaryFacets && data?.secondaryFacets.length > 0;

    const chevronRight = (): JSX.Element => <ChevronRightIcon className={classes.chevron} />;
    const chevronDown = (): JSX.Element => <ExpandMoreIcon className={classes.chevron} />;

    const renderExpandAnchor = (): JSX.Element => {
        return (
            <Link
                id="secondary-facets-expand-anchor"
                href="javascript:void(0);"
                onClick={handleSecondaryFacetsExpand}
                title="Expand or Contract the list of additional Facets">
                {secondaryFacetsExpanded ? chevronDown() : chevronRight()}
                {secondaryFacetsExpanded ? 'View Less' : 'View More'}
            </Link>
        );
    };

    const renderExpandMenu = (): JSX.Element => {
        return (
            <Link
                href="javascript:void(0);"
                title={isCollapsed ? 'Expand Menu' : 'Collapse Menu'}
                onClick={handleMenuExpanded}
                classes={{ root: classes.menuButton }}>
                <WkDoubleArrowRight
                    className={classNames(classes.menuButtonIcon, { [classes.menuButtonIconExpanded]: !isCollapsed })}
                />
            </Link>
        );
    };

    const handleSecondaryFacetsExpand = (): void => {
        setSecondaryFacetsExpanded((secondaryFacetsExpanded) => !secondaryFacetsExpanded);
    };

    const handleMenuExpanded = async (): Promise<void> => {
        await toggleCollapse();
    };

    const handleSelectedCode = (code: string): void => {
        setSelectedCode(code);
    };

    return (
        <div
            data-testid="facet-menu-view"
            ref={ref}
            className={classNames(classes.menuView, { [classes.menuViewCollapsed]: isCollapsed })}>
            <div id="facet-list-display-toggle">{renderExpandMenu()}</div>
            <div id="primary-facet-container">
                <ul className={classNames(classes.menuContainer, { [classes.menuContainerCollapsed]: isCollapsed })}>
                    {data?.primaryFacets.map((d, index) => {
                        return (
                            <React.Fragment key={index}>
                                <li className={d.code === _code || d.isSelected ? 'selected' : ''}>{renderLink(d)}</li>
                            </React.Fragment>
                        );
                    })}
                    {!isCollapsed && anySecondaryFacets && <li>{renderExpandAnchor()}</li>}
                </ul>
            </div>
            {!isCollapsed && secondaryFacetsExpanded && anySecondaryFacets && (
                <div id="secondary-facet-container">
                    <ul
                        className={classNames(classes.menuContainer, classes.menuExpandContainer, {
                            [classes.menuContainerCollapsed]: isCollapsed,
                        })}>
                        {data?.secondaryFacets.map((d, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <li className={d.code === _code || d.isSelected ? 'selected' : ''}>
                                        {renderLink(d)}
                                    </li>
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
});

FacetMenuView.displayName = 'FacetMenuView';
