import { Grid } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useItemScreenState } from './context/itemScreenContext';
import ItemScreenAccountingField from './itemScreenAccountingField';
import { isTabStyleAccounting } from './itemScreenCommon';
import ItemScreenField from './itemScreenField';
import css from './itemScreenFieldRow.module.scss';
import { IItemScreenDataElement, ControlType, IItemScreenField } from './types';

interface IItemScreenFieldRowProps {
    fieldRow: IItemScreenField[];
    isEmbeddedList: boolean;
    stickyTopPosition?: number;
    tabIndex?: number;
    parentTabIndex?: number;
    sectionLevel: number;
}

const ItemScreenFieldRow: React.FC<IItemScreenFieldRowProps> = ({
    fieldRow,
    isEmbeddedList,
    stickyTopPosition,
    tabIndex,
    parentTabIndex,
    sectionLevel,
}) => {
    const itemScreenState = useItemScreenState();

    const isOnlyOneFieldOnThisRow = fieldRow.length === 1;

    const isFullWidthControl = (controlType: ControlType) => {
        return (
            isOnlyOneFieldOnThisRow ||
            controlType === 'Multi Line Text' ||
            controlType === 'Multi-Select List' ||
            controlType === 'Multi-Autocomplete'
        );
    };

    return (
        <Grid
            item
            xs={12}
            container
            spacing={isEmbeddedList ? 0 : 2}
            className={`fieldRow ${sectionLevel === 3 ? css.level3FieldRow : ''}`}>
            {fieldRow.map((field, i) => (
                <Grid
                    key={i}
                    item
                    xs={isFullWidthControl(field.controlType) || isTabStyleAccounting(field) ? 12 : 6}
                    className={classNames({
                        [css.fieldRowContainer]: isTabStyleAccounting(field),
                        itemScreenField: !isTabStyleAccounting(field),
                        [css.level3FieldRow]: !isTabStyleAccounting(field) && sectionLevel === 3,
                    })}>
                    {isTabStyleAccounting(field) ? (
                        <ItemScreenAccountingField
                            field={field}
                            fieldData={itemScreenState.itemScreenJson!.item[field.name] as IItemScreenDataElement}
                        />
                    ) : (
                        <ItemScreenField
                            field={field}
                            mode={itemScreenState.mode}
                            fieldData={itemScreenState.itemScreenJson!.item[field.name] as IItemScreenDataElement}
                            stickyTopPosition={stickyTopPosition}
                            tabIndex={tabIndex}
                            parentTabIndex={parentTabIndex}
                        />
                    )}
                </Grid>
            ))}
        </Grid>
    );
};

export default ItemScreenFieldRow;
