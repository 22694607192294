import { parse, ParsedQuery, ParseOptions } from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type UseQueryProps = ParseOptions & {
    isGlobal?: boolean;
};

export function useQuery<T extends ParsedQuery<string | boolean | number>>(props: UseQueryProps = {}): Partial<T> {
    const { isGlobal, ...options } = props;
    const { search } = isGlobal ? location : useLocation();

    return useMemo(() => {
        return parse(search, options) as T;
    }, [search]);
}
