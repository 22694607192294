import { useAppSelector } from '../store';
import { getAppResources } from '../store/slices';
import { getActivePassportContext, getPassportContextDefaultLandingPageUrl } from '../utils/passportContextHelper';

export const useHomePageUrl = (): string => {
    const appsMenu = useAppSelector((state) => state.appsMenu);
    const appResources = useAppSelector(getAppResources);
    try {
        return getPassportContextDefaultLandingPageUrl(getActivePassportContext(appsMenu), appResources.v3HomeUrl);
    } catch (error) {
        return '';
    }
};
