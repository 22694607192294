import React, { useState, useEffect } from 'react';
import { config } from '../../../target/t360/config/index';

interface HealthStatus {
    status: string;
    data: {
        [key: string]: string;
    };
}

export const CheckApiHealthPage: React.FC = () => {
    const [status, setStatus] = useState<HealthStatus>({
        status: 'Checking...',
        data: {
            IdentityProvider: 'Checking...',
            WebApi: 'Checking...',
            EnterpriseWebReskin: 'Checking...',
            BFFServices: 'Checking...',
        },
    });

    const checkHealth = async (url: string, serverName: string) => {
        try {
            const response = await fetch(url);
            const isUnHealthyStatus = response.status === 404 || (response.status >= 500 && response.status < 600);
            const serverStatus = !isUnHealthyStatus ? 'Healthy' : 'UnHealthy';

            setStatus((prevStatus) => {
                const updatedData = {
                    ...prevStatus.data,
                    [serverName]: serverStatus,
                };

                const values = Object.values(updatedData);
                const hasUnhealthy = values.some((s) => s === 'UnHealthy');
                const allHealthy = values.every((s) => s === 'Healthy');

                let globalStatus = 'Checking...';
                if (hasUnhealthy) {
                    globalStatus = 'UnHealthy';
                } else if (allHealthy) {
                    globalStatus = 'Healthy';
                }

                return {
                    status: globalStatus,
                    data: updatedData,
                };
            });
        } catch (error) {
            setStatus((prevStatus) => {
                const updatedData = {
                    ...prevStatus.data,
                    [serverName]: 'UnHealthy',
                };

                return {
                    status: 'UnHealthy',
                    data: updatedData,
                };
            });
        }
    };

    useEffect(() => {
        checkHealth(`${config.get('REACT_APP_IDENTITY_URL')}/health`, 'IdentityProvider');
        checkHealth(`${config.get('REACT_APP_API_URL')}/health`, 'WebApi');
        checkHealth(`${config.get('REACT_APP_LEGACY_URL')}/health`, 'EnterpriseWebReskin');
        checkHealth(`${config.get('REACT_APP_BFF_URL')}/health`, 'BFFServices');
    }, []);

    return (
        <div>
            <pre>{JSON.stringify(status, null, 2)}</pre>
        </div>
    );
};
