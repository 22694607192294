import { Button, FormControl, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { capitalize, head } from 'lodash';
import React, { MouseEvent, useEffect, useMemo, useState, VFC } from 'react';
import { NotificationPlacement } from '../../../../reducers/types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
    FavoriteEntity,
    FavoriteFolderEntity,
    FavoriteType,
    selectFavoritesTree,
    showNotification,
} from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { BaseDialog, BaseDialogProps } from '../../../common';
import { getFavoriteOrFolder } from '../../favoritesTree.utils';
import { FavoritesView } from '../../favoritesView';
import { useFavorites } from '../../hooks';

const useStyles = makeStyles<AppTheme>((theme) => ({
    paper: {
        maxWidth: 560,
        width: '100%',
    },
    content: {
        boxSizing: 'content-box',
    },
    favoritesView: {
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        minHeight: 200,
        maxHeight: 400,
        flex: 1,
        flexBasis: 0,
        width: '100%',
    },
    actions: {
        justifyContent: 'space-between',
    },
}));

export type MoveFavoriteDialogProps = Omit<BaseDialogProps, 'title' | 'actions'> & {
    entityId: FavoriteEntity['id'];
};

export const MoveFavoriteDialog: VFC<MoveFavoriteDialogProps> = (props) => {
    const classes = useStyles();
    const { entityId, onClose, classes: externalClasses = {}, ...baseDialogProps } = props;
    const dispatch = useAppDispatch();
    const { moveFavorite } = useFavorites();
    const tree = useAppSelector(selectFavoritesTree());
    const [selectedId, setSelectedId] = useState<string>();
    const [initialFolder, setInitialFolder] = useState<FavoriteFolderEntity>();
    const [expanded, setExpanded] = useState<string[]>();
    const entity = useMemo(() => getFavoriteOrFolder(tree, entityId), [tree, entityId]);
    const selected = useMemo(() => getFavoriteOrFolder(tree, selectedId), [tree, selectedId]);
    const getFormattedEntityName = (entity: FavoriteEntity): string =>
        `"${entity.name}"${entity.type === FavoriteType.Folder ? ' folder' : ''}`;

    const disabled = useMemo(() => {
        const inEntitySubTree = getFavoriteOrFolder(entity, selectedId);

        return Boolean(!selectedId || selectedId === initialFolder?.id.toString() || inEntitySubTree);
    }, [tree, entity, selectedId, initialFolder]);

    useEffect(() => {
        const getParentFolders = (item?: FavoriteEntity): FavoriteFolderEntity[] => {
            const parent = item?.parentId && getFavoriteOrFolder(tree, item.parentId);

            if (!parent) {
                return [];
            }

            if (parent.type === FavoriteType.Folder) {
                return [parent, ...getParentFolders(parent)];
            }

            return getParentFolders(parent);
        };

        const parentFolders = getParentFolders(entity);

        setInitialFolder(head(parentFolders));
        setExpanded(parentFolders.map(({ id }) => String(id)));
    }, [tree, entity]);

    const onNodeToggle = (ids: string[]): void => {
        setExpanded(ids);
    };

    const handleMove = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        if (!tree || selected?.type !== FavoriteType.Folder || !entity) {
            return;
        }

        try {
            const movedEntity = await moveFavorite(entity, selected.id);
            const message = `${getFormattedEntityName(movedEntity)} has been moved to the "${selected.name}" folder.`;
            dispatch(
                showNotification({
                    notification: {
                        message,
                        options: {
                            variant: 'success',
                        },
                        placement: NotificationPlacement.Top,
                    },
                }),
            );
        } catch (error) {
            // ignored // FIXME: fetch wrappers do a lot of non-generic stuff, requires refactoring
        }

        onClose?.(event, 'onAction');
    };

    return (
        <BaseDialog
            {...baseDialogProps}
            classes={{
                ...externalClasses,
                actions: classNames(classes.actions, externalClasses.actions),
                content: classNames(classes.content, externalClasses.content),
                paper: classNames(classes.paper, externalClasses.paper),
            }}
            onClose={onClose}
            title={`Move ${capitalize(entity?.type)}`}
            actions={
                <Button color="primary" variant="contained" onClick={handleMove} disabled={disabled} disableRipple>
                    Move
                </Button>
            }>
            <FormControl variant="outlined">
                <InputLabel htmlFor="component-favorites-view">Select Folder</InputLabel>
                <FavoritesView
                    id="component-favorites-view"
                    classes={{ root: classes.favoritesView }}
                    foldersOnly
                    expanded={expanded}
                    onNodeToggle={onNodeToggle}
                    selected={selectedId || initialFolder?.id.toString()}
                    onNodeSelect={setSelectedId}
                />
            </FormControl>
        </BaseDialog>
    );
};
