import { combineReducers, configureStore, EnhancedStore, Middleware } from '@reduxjs/toolkit';
import { reduxLogger } from '../reduxMiddleware';
import { initializeApp } from './middleware';
import {
    appsMenuReducer,
    appReducer,
    alertDialogsReducer,
    globalStateReducer,
    favoritesReducer,
    helpSectionReducer,
    historyScreenReducer,
    itemScreenDialogsReducer,
    messageBusReducer,
    notificationsReducer,
    overlayDialogReducer,
    persistStateReducer,
    uploadHistoryReducer,
    userScreenReducer,
    configurationReducer,
    powerBIReducer,
    gridsReducer,
} from './slices';
import { persistStorageSave } from './slices/persistState.slice';
import { AppActions, AppState } from './types';

export const reducer = {
    app: appReducer,
    appsMenu: appsMenuReducer,
    favorites: favoritesReducer,
    powerBI: powerBIReducer,
    uuiConfiguration: configurationReducer,
    ui: combineReducers({
        alertDialogs: alertDialogsReducer,
        globalState: globalStateReducer,
        helpSection: helpSectionReducer,
        historyScreen: historyScreenReducer,
        itemScreenDialogs: itemScreenDialogsReducer,
        messageBus: messageBusReducer,
        notifications: notificationsReducer,
        overlayDialog: overlayDialogReducer,
        persistState: persistStateReducer,
        uploadHistory: uploadHistoryReducer,
        userScreen: userScreenReducer,
        grids: gridsReducer,
    }),
};

type MiddlewareCollection<State> = ReadonlyArray<Middleware<Record<string, unknown>, State>>;
export const configureAppStore = (
    initialState?: AppState,
): EnhancedStore<AppState, AppActions, MiddlewareCollection<AppState>> =>
    configureStore<AppState, AppActions, MiddlewareCollection<AppState>>({
        reducer,
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(persistStorageSave(), initializeApp.middleware, reduxLogger),
    });

const store = configureAppStore();

export default store;
