import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkNextPageIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 12 12">
        <path d="M3.2,0l6.3,6l-6.2,6l-0.7-0.7L8,6L2.5,0.7L3.2,0z" />
    </SvgIcon>
);

export default WkNextPageIcon;
