import { isString, lowerFirst } from 'lodash';

// noinspection JSUnusedGlobalSymbols
enum SearchQueryParamName {
    Term = 'searchTerm',
    Subject = 'searchSubject',
    MatchOption = 'searchMatchOption',
    SortOption = 'searchSortOption',
    MaxResults = 'maxResults',
}

export type SearchUrlQueryData = {
    [key in SearchQueryParamName]: string;
};

const isSearchQueryParam = (param: unknown): param is SearchQueryParamName => {
    return isString(param) && Object.values<string>(SearchQueryParamName).includes(param);
};

/**
 * Compiles the search URL given the query data.
 *
 * @param {string} templateUrl Template URL.
 * @param {SearchUrlQueryData} data Search URL query data.
 * @return {string} Search URL.
 */
export const compileSearchUrl = (templateUrl: string, data: SearchUrlQueryData): string => {
    const replacer = (match: string, ...args: unknown[]): string => {
        const [firstCapturingGroup] = args;
        if (isString(firstCapturingGroup)) {
            const key = lowerFirst(firstCapturingGroup);
            if (isSearchQueryParam(key)) {
                return data[key];
            }
        }
        return match;
    };
    return templateUrl.replace(/%(.+?)%/g, replacer);
};
