import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkDownArrowIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkDownArrowIcon" {...props} viewBox="0 0 24 24">
        <g transform="translate(-614.944 -2012)">
            <g transform="translate(630.944 2025.644) rotate(180)" fill="none">
                <path d="M8,0l8,11.2H0Z" stroke="none" />
                <path
                    d="M 7.999996185302734 2.23659610748291 L 2.526139259338379 9.899996757507324 L 13.47385311126709 9.899996757507324 L 7.999996185302734 2.23659610748291 M 7.999996185302734 -3.814697265625e-06 L 15.99999618530273 11.19999694824219 L -3.814697265625e-06 11.19999694824219 L 7.999996185302734 -3.814697265625e-06 Z"
                    stroke="none"
                    fill="#005b92"
                />
            </g>
            <rect width="16" height="15.998" transform="translate(614.944 2012)" fill="none" />
        </g>
    </SvgIcon>
);
