import { IAppsMenu, IPassportContext } from '../reducers/types';
import { apiFetch } from './fetchUtils';
import { getLogger } from './loggingService';

const logger = () => getLogger('t360.passportContextHelper');

export const getPassportContextDefaultLandingPageUrl = (
    passportContext: IPassportContext,
    fallbackLandingPage: string,
): string => {
    return passportContext.defaultLandingPage ?? fallbackLandingPage;
};

export const getActivePassportContext = (appsMenu: IAppsMenu): IPassportContext => {
    return appsMenu.passportContexts.find((pc) => pc.id === appsMenu.activeContext)!;
};

/*
 * Retrieve the App function landing page URL.
 */
export const getPassportContextLandingPageUrl = (
    passportContext: IPassportContext,
    fallbackLandingPage: string,
): string => {
    return passportContext.defaultLandingPage || fallbackLandingPage;
};

export async function getResolvedDeeplink(tempSessionIdFromQueryString: string): Promise<string | undefined> {
    const { apiContextRoot, apiContextPath } = window.Props as Record<string, string>;
    const legacyRootUrlResponse = await apiFetch<DeepLinkDataDto>(`${apiContextRoot}${apiContextPath}/deeplink/${tempSessionIdFromQueryString}`)
        .catch((err) => { logger().error('Deeplink Api fetch error : ', err); });
    return legacyRootUrlResponse?.deepLinkUrl;
};

type DeepLinkDataDto = {
    deepLinkUrl: string;
};