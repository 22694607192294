import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkInvoices = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkAddIcon" viewBox="0 0 25 25" {...props}>
        <defs>
            <clipPath id="wk-invoices-clipPath">
                <rect id="Rectangle_3438" data-name="Rectangle 3438" width="24.576" height="24" fill="none" />
            </clipPath>
        </defs>
        <g id="Group_10970" data-name="Group 10970" transform="translate(-3218 -577)">
            <rect
                id="Rectangle_3446"
                data-name="Rectangle 3446"
                width="25"
                height="25"
                transform="translate(3218 577)"
                fill="none"
            />
            <g id="Group_9365" data-name="Group 9365" transform="translate(3218 577)">
                <g
                    id="Group_9364"
                    data-name="Group 9364"
                    transform="translate(0 0)"
                    clipPath="url(#wk-invoices-clipPath)">
                    <path
                        id="Path_5020"
                        data-name="Path 5020"
                        d="M3.38,5.959H.634S.321,1.249,3.38.626H19.065s1.884-.157,1.568,3.686c.078,4.239-.048,15.1-.048,15.1h3.437s.061,3.256-2.527,4.039H4.87a3.04,3.04,0,0,1-1.019-2.826V5.956Z"
                        transform="translate(-0.036 -0.036)"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.25"
                    />
                    <path
                        id="Path_5021"
                        data-name="Path 5021"
                        d="M5.047,24.6s2.274-.785,1.96-4H24.3"
                        transform="translate(-0.293 -1.194)"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.25"
                    />
                    <path
                        id="Path_5022"
                        data-name="Path 5022"
                        d="M3.464.621S4.276,1.94,4,5.956"
                        transform="translate(-0.201 -0.036)"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.25"
                    />
                    <rect
                        id="Rectangle_3434"
                        data-name="Rectangle 3434"
                        width="11.791"
                        height="1.538"
                        transform="translate(6.841 14.909)"
                        fill="currentColor"
                    />
                    <rect
                        id="Rectangle_3435"
                        data-name="Rectangle 3435"
                        width="4.956"
                        height="1.538"
                        transform="translate(13.676 11.149)"
                        fill="currentColor"
                    />
                    <rect
                        id="Rectangle_3436"
                        data-name="Rectangle 3436"
                        width="4.956"
                        height="1.538"
                        transform="translate(13.676 8.073)"
                        fill="currentColor"
                    />
                    <rect
                        id="Rectangle_3437"
                        data-name="Rectangle 3437"
                        width="4.956"
                        height="1.538"
                        transform="translate(13.676 4.484)"
                        fill="currentColor"
                    />
                    <path
                        id="Path_5023"
                        data-name="Path 5023"
                        d="M11.546,5.256,11.217,6.7s-2.563-.547-2.261.241c.854.329,3.33,1.051,2.717,3.133a8.025,8.025,0,0,1-1.117.942l-.377.109v1.139H8.786V11.123A18.034,18.034,0,0,1,7.011,10.6c.377-.876.547-1.292.547-1.292s1.271.395,1.4.416a1.632,1.632,0,0,0,.986,0c.35-.175.219-.745-.5-.964A3.213,3.213,0,0,1,7.317,7.311,1.805,1.805,0,0,1,8.557,5.08c.04-.011.079-.021.119-.029A8.51,8.51,0,0,0,8.676,4h1.507l.109,1.051Z"
                        transform="translate(-0.406 -0.232)"
                        fill="currentColor"
                    />
                </g>
            </g>
        </g>
    </SvgIcon>
);
