import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import { Observable, Subject, Subscription } from 'rxjs';
import { factory } from '../configLog4J';
import { Locale } from '../locale';
import { ICHMessagingInterface, CHMessageData } from './interface/message.interface';
import { CHMessageType, CHMessagingScope, EventType } from '../enum/enum';
import { Cancel_NW_Switch_Msg, Proceed_with_NW_Switch_Msg, T360_NETWORKSWITCH_EVENT } from '../constants';
import { networkSwitchDialog } from '../utils/network.utils';
import { IParentItemInfo, IRefreshUUIForEntity } from './interface';
import { getQueueCapability, isQueueCapabilityInitialized } from '../capability';
import { IEventService } from './eventContextHandler.service';
import { IOfficeService } from './office.service';
import { isValidDocumentId } from '../utils/main.utils';
import { IBrowserCommunicationService } from './browserCommunication.service';

const log = factory.getLogger('CHMessaging');

@injectable()
export class CHMessagingService implements ICHMessagingInterface {
    private chmessageResponseStream = new Subject<CHMessageData>();

    constructor(
        @inject('EventContextHandler') private eventService: IEventService,
        @inject('OfficeService') private officeService: IOfficeService,
        @inject('BrowserCommunicationService') private browserCommunicationService: IBrowserCommunicationService,
    ) {
        this.startListeningForMessages();
    }

    public getResponseStream(): Observable<CHMessageData> {
        return this.chmessageResponseStream.asObservable();
    }

    /**
     *
     * @param CHMessageData
     * Handle CL Operations to perform before notifying back to UUI
     */
    private async handleMessageData(data: CHMessageData): Promise<void> {
        switch (data.type) {
            case CHMessageType.ProceedWithNWSwitch:
            case CHMessageType.LOGOUT:
            case CHMessageType.T360_LOGOUT: {
                if (!isQueueCapabilityInitialized()) {
                    return;
                }
                const queueCapabilityObj = await getQueueCapability();
                queueCapabilityObj.pauseQueue();
                log.debug('Queue is paused on: ' + data.type);
            }
        }
    }

    public startListeningForMessages(): void {
        log.debug('OC | Start Listening For Messages from OC');
        this.browserCommunicationService.subscribe(async (data) => {
            if (data && data.type === CHMessageType.activateWindow) {
                log.info('OC | Instance path matches to set as active window');
                await this.officeService.checkPathToActivate(data.message);
                return;
            }
            await this.handleMessageData(data);
            this.chmessageResponseStream.next(data);
        });
    }

    public subscribe(observer: (data: CHMessageData) => void): Subscription {
        return this.getResponseStream().subscribe(observer);
    }

    /**
     * Checks for the event from the datat
     * If it is handled returns true
     * else false if ig
     */
    public async checkForEvent(data: CHMessageData): Promise<boolean> {
        if (data.type == T360_NETWORKSWITCH_EVENT) {
            const queueCapabilityObj = await getQueueCapability();

            log.debug('network switch event called');

            log.debug(
                'queueCapability current processing elements' + queueCapabilityObj.currentProcessingElements,
            );
            // this.queueCapability = await this.queueCapabilityProm;
            // if elements in processing
            // log.debug('no of elements currently in process: ' + this.queueCapability.currentProcessingElements);
            if (queueCapabilityObj.currentProcessingElements > 0) {
                // this.networkSwitchDialog();
                await networkSwitchDialog(this, this.eventService);
            } else {
                // if no upload happening so proceed
                await this.notifyNetworkSwitch();
            }
            return true;
        }
        return false;
    }

    public async notify(data: CHMessageData): Promise<void> {
        log.debug('OC | Notify Data ' + JSON.stringify(data));

        // For All instances scope data will be handled after getting resopnse
        if (data.scope === CHMessagingScope.OtherInstances) {
            await this.handleMessageData(data);
        }

        if (!(await this.checkForEvent(data))) {
            this.browserCommunicationService.notify(data);
        }
    }

    public async notifyNetworkSwitch(proceed = true): Promise<void> {
        const data = proceed
            ? {
                  message: Proceed_with_NW_Switch_Msg,
                  type: CHMessageType.ProceedWithNWSwitch,
                  scope: CHMessagingScope.AllInstances,
              }
            : {
                  message: Cancel_NW_Switch_Msg,
                  type: CHMessageType.CancelNWSwitch,
                  scope: CHMessagingScope.AllInstances,
              };

        // TODO: check why need to notify other instances
        this.browserCommunicationService.notify(data);
    }
    /**
     * This refreshes the UUI in this browser for the given document, and if we are in OC, all other OC browsers as well.
     *
     * @param entityTypeId The entity metadata id for the document
     * @param documentId The entity instance id for the document
     */
    notifyRefreshUUIForEntity(
        entityTypeId: string | undefined,
        documentId?: string,
        parentEntityId?: string,
        parentInstanceId?: string,
        folderId?: string,
        isEntityDeleted?: boolean,
        isStaleRefresh?: boolean,
    ): void {
        if (!entityTypeId) {
            return;
        }
        let parentItemInfo: IParentItemInfo | undefined;
        if (parentEntityId && parentInstanceId) {
            parentItemInfo = {
                parentEntityId: parentEntityId.toString(),
                parentInstanceId: parentInstanceId.toString(),
            };
        }
        const messageScope = isStaleRefresh ? CHMessagingScope.AllInstances : CHMessagingScope.OtherInstances;
        const refreshUUIForEntity: IRefreshUUIForEntity = {
            entityTypeId: entityTypeId.toString(),
            entityInstanceId: isValidDocumentId(documentId) ? documentId?.toString() : undefined,
            parentItemInfo,
            folderId: folderId?.toString(),
            isEntityDeleted: isEntityDeleted,
        };
        // this covers the OC case, and refreshes all the other OC windows
        this.browserCommunicationService.notify({
            message: JSON.stringify(refreshUUIForEntity),
            type: CHMessageType.refreshUUI,
            scope: messageScope,
        });
        // this covers the non OC case, and refreshes the current UUI
        // skip deletes for the current instance because the list should have refreshed already
        // as deleting is a synchronous operation.
        if (!refreshUUIForEntity.isEntityDeleted) {
            this.eventService.publish({
                name: EventType.REFRESH_UUI_FOR_ENTITY,
                refreshUUIForEntity,
            });
        }
    }
}

@injectable()
export class MessageService {
    constructor() {
        Object.assign(this, Locale);
    }
    /**
     * Create string from template
     * @method compileTemplate
     * @param {string} template      'Test {field} message'
     * @param {object} values       {field : 'example'}
     * @return {string}             'Test example message'
     */
    public compileTemplate(template: string, values?: Record<string, unknown>): string {
        if (values === undefined || values === null) {
            values = {};
        }
        return template.replace(/{([^{}]*)}/g, (pattern, field) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.isString(values[field]) || this.isNumber(values[field]) ? values[field] : pattern,
        );
    }

    private isString(value: unknown): boolean {
        return typeof value === 'string' || value instanceof String;
    }

    private isNumber(value: unknown): boolean {
        return value !== undefined && value !== null && !isNaN(Number(value));
    }
}
