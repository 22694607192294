import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkLogOutIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="WkLogOutIcon" viewBox="0 0 24 24" {...props}>
        <g transform="translate(0 0)">
            <path
                style={{ fill: '#05659f' }}
                d="M8,16a8.257,8.257,0,0,1-5.645-2.187A7.118,7.118,0,0,1,0,8.571,6.993,6.993,0,0,1,1.75,3.946a8.183,8.183,0,0,1,4.4-2.589V2.571A7.01,7.01,0,0,0,2.625,4.83,5.945,5.945,0,0,0,1.231,8.643,6.033,6.033,0,0,0,3.2,13.1,7.007,7.007,0,0,0,8,14.928a7.006,7.006,0,0,0,4.8-1.83,6.037,6.037,0,0,0,.577-8.321A6.7,6.7,0,0,0,9.846,2.571V1.357a8.183,8.183,0,0,1,4.4,2.589A6.993,6.993,0,0,1,16,8.571a7.119,7.119,0,0,1-2.356,5.241A8.256,8.256,0,0,1,8,16Zm.615-9.714H7.385V0h1.23V6.285Z"
            />
            <rect style={{ fill: 'none' }} width="16" height="16" />
        </g>
    </SvgIcon>
);

export default WkLogOutIcon;
