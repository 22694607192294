import { GridSortDirection } from '@mui/x-data-grid';
import { toPairs, omit, isNil, isArray } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useAppSelector } from '../../../../../store';
import { selectPageState } from '../../../../../store/slices';
import { apiDownload, apiFetch } from '../../../../../utils/fetchUtils';
import {
    GridSourceUrlProps,
    LayoutGridViewData,
    LayoutGridViewResult,
    LayoutGridViewRow,
    UseViewScreenProps,
} from '../common';

const getGridSourceUrl = (props: GridSourceUrlProps): string => {
    const { actionName, ...params } = props;
    const { apiContextRoot, apiContextPath } = window.Props as Record<string, string>;
    const url = `${apiContextRoot}${apiContextPath}/view/component/LayoutGridComponent/${actionName}`;

    const urlParams = toPairs(params)
        .reduce((params, [paramName, value]) => {
            if (!isNil(value)) {
                const paramValue = isArray(value) ? value.join(',') : (value as string);
                params.push(`${paramName}=${paramValue}`);
            }

            return params;
        }, [] as string[])
        .join('&');

    return `${url}?${urlParams}`;
};

export const useLayoutGridView = (props: UseViewScreenProps): LayoutGridViewResult => {
    const { component, sortItem } = props;
    const [data, setData] = useState<LayoutGridViewRow[]>();
    const currency = useAppSelector(selectPageState('currency'));
    const getLayoutGrid = async (dataSourceName: string, fields?: string, sort?: GridSortDirection): Promise<void> => {
        const value = await apiFetch<LayoutGridViewData[][]>(
            getGridSourceUrl({ dataSource: dataSourceName, actionName: 'getSource', fields, sort }),
        );
        const newData = value?.map((items) => {
            const row = items.reduce(
                (agg, item, index) => {
                    const key = component.columns[index]?.fields;
                    if (key) {
                        agg[key] = item;
                    }

                    return agg;
                },
                { id: uuid() } as LayoutGridViewRow,
            );

            return row;
        });

        setData(newData);
    };

    useEffect(() => {
        if (!component.dataSource) {
            return;
        }

        void getLayoutGrid(component.dataSource, sortItem?.field, sortItem?.sort);
    }, [component.dataSource, currency?.enabled]);

    const columns = useMemo(() => {
        return component.columns.map((column) => {
            const key = column.fields;
            return {
                ...omit(column, 'fields', 'sorts'),
                key,
            };
        });
    }, [component.columns]);

    const download = (): void => {
        const url = getGridSourceUrl({
            actionName: 'export',
            dataSource: component.dataSource,
            fields: sortItem?.field,
            sort: sortItem?.sort,
        });

        void apiDownload(url);
    };

    return {
        data,
        columns,
        download,
        getLayoutGrid,
    };
};
