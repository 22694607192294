import { Button, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { head } from 'lodash';
import React, { FormEventHandler, useState, VFC } from 'react';
import { useNavigate } from '../../../../hooks';
import { useAppSelector } from '../../../../store';
import { getApplicationUrls } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { determineRedirectUrl } from '../../../common/screenHelpers';
import { TargetScreenType } from '../../../common/types';
import { IItemScreenOperation } from '../../../itemScreen/types';
import { PopoverToolsProps } from '../itemWithPopover';
import { AdditionalSearchOptions } from './additionalSearchOptions';
import { TermAndSubject } from './termAndSubject';
import { compileSearchUrl } from './utils';

export type MuiRadioGroupChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;

const useStyle = makeStyles<AppTheme>((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: 350,
        paddingTop: theme.spacing(2),
        boxSizing: 'border-box',
        gap: theme.spacing(2),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    buttonLabel: {
        lineHeight: '20px',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1.75, 2),
    },
}));

const DEFAULT_MAX_RESULTS = '10';
const MIN_SEARCH_TERM = 2;

export type QuickSearchProps = PopoverToolsProps;

export const QuickSearch: VFC<QuickSearchProps> = (props) => {
    const { onClose } = props;

    const { searchPageTemplate, searchSubjects, searchMatchOptions, searchSortOptions } = useAppSelector(
        (state) => state.app.hybridSettings.quickSearchSettings,
    );
    const applicationUrls = useAppSelector(getApplicationUrls);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchSubject, setSearchSubject] = useState(() => head(searchSubjects)?.value ?? '');
    const [searchMatch, setSearchMatch] = useState(() => head(searchMatchOptions)?.value ?? '');
    const [searchSort, setSearchSort] = useState(() => head(searchSortOptions)?.value ?? '');
    const [maxResults, setMaxResults] = useState(DEFAULT_MAX_RESULTS);
    const { navigate } = useNavigate();
    const classes = useStyle();
    const disabled = searchTerm.length < MIN_SEARCH_TERM;

    const handleOnSearchTermChange = (value: string): void => setSearchTerm(value);

    const handleOnSearchSubjectChange = (value: string): void => setSearchSubject(value);

    const handleOnMatchChange: MuiRadioGroupChangeEventHandler = (_, value) => setSearchMatch(value);

    const handleOnSortChange: MuiRadioGroupChangeEventHandler = (_, value) => setSearchSort(value);

    const handleOnMaxResultChange = (value: string): void => setMaxResults(value);

    const handleSearch: FormEventHandler = (e) => {
        e.preventDefault();
        const searchUrl = compileSearchUrl(searchPageTemplate, {
            searchTerm,
            searchSubject,
            searchMatchOption: searchMatch,
            searchSortOption: searchSort,
            maxResults,
        });
        const encodedSearchUrl = encodeURIComponent(searchUrl);
        const url = determineRedirectUrl({
            operation: {
                targetScreenType: TargetScreenType.Legacy,
                targetScreenId: -1,
                targetScreenMode: 'Show',
                url: encodedSearchUrl,
            } as IItemScreenOperation,
            applicationUrls,
        });

        if (url) {
            navigate({ to: url, options: { clearBreadcrumbs: true } });
        }

        if (onClose !== undefined) {
            onClose();
        }
    };

    return (
        <form onSubmit={handleSearch} className={classes.container}>
            <div className={classes.form}>
                <TermAndSubject
                    searchTerm={searchTerm}
                    searchSubject={searchSubject}
                    searchSubjects={searchSubjects}
                    onTermChange={handleOnSearchTermChange}
                    onSubjectChange={handleOnSearchSubjectChange}
                />

                <Divider />

                <AdditionalSearchOptions
                    searchMatch={searchMatch}
                    searchMatchOptions={searchMatchOptions}
                    searchSortOption={searchSort}
                    searchSortOptions={searchSortOptions}
                    maxResults={maxResults}
                    onMatchChange={handleOnMatchChange}
                    onSortChange={handleOnSortChange}
                    onMaxResultsChange={handleOnMaxResultChange}
                />
            </div>

            <div className={classes.actions}>
                <Button
                    disableRipple
                    disabled={disabled}
                    variant="contained"
                    color="primary"
                    type="submit"
                    classes={{ root: classes.buttonLabel }}>
                    Search
                </Button>
            </div>
        </form>
    );
};
