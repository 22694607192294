import { getLogger } from '@wk/elm-uui-common';
import { UUIFetch } from '@wk/elm-uui-common';
import { deleteToken, setToken } from '@wk/elm-uui-context-handler';
import { config } from '../config';
import { TokenAuthenticationProvider } from './TokenAuthenticationProvider';
import { OidcStorageHelper } from './oidcStorageHelper';

export class OidcStorage {
    private storage: Storage;
    constructor() {
        this.storage = window.localStorage;
    }

    static isHybridMode() {
        return Boolean(config.get('REACT_APP_LEGACY_URL'));
    }

    getIdTokenHint() {
        return this.storage.getItem('id_token_hint');
    }

    setIdTokenHint(value: string) {
        return this.storage.setItem('id_token_hint', value);
    }

    removeIdTokenHint() {
        return this.storage.removeItem('id_token_hint');
    }

    getNetworkSwitchId() {
        return this.storage.getItem('network_switch_id');
    }

    setNetworkSwitchId(value: string) {
        return this.storage.setItem('network_switch_id', value);
    }

    removeNetworkSwitchId() {
        return this.storage.removeItem('network_switch_id');
    }

    async getItem(key: string) {
        return this.storage.getItem(key);
    }

    async setItem(key: string, value: string) {
        if (key.startsWith('oidc.user') && value !== null) {
            const valueObject = JSON.parse(value);
            const refresh_token = valueObject.refresh_token;
            const networkSwitchId = this.getNetworkSwitchId();
            if (networkSwitchId) {
                valueObject.profile.network_id = networkSwitchId;
            }
            this.removeNetworkSwitchId();
            valueObject.refresh_token = 'dummy';
            value = JSON.stringify(valueObject);
            this.storage.setItem(key, value);

            try {
                await setToken(OidcStorageHelper.getId(), OidcStorageHelper.getSecret(), refresh_token).toPromise();
            } catch (e) {
                this.storage.setItem(OidcStorageHelper.refreshTokenTempKey, refresh_token);
                getLogger('t360.oidcStorage').error('Context Layer Set Token Error: ', e);
            }
        } else {
            this.storage.setItem(key, value);
        }

        if (OidcStorage.isHybridMode()) {
            const request: RequestInit = {};
            const secureRequest = await TokenAuthenticationProvider.addRequestAuthentication(request);
            const url = `${config.get('REACT_APP_LEGACY_URL')}/setAccessToken.ashx`;
            await UUIFetch.fetch(url, secureRequest);
        }
    }

    async removeItem(key: string) {
        this.storage.removeItem(key);
        if (key.startsWith('oidc.user')) {
            this.removeNetworkSwitchId();
            await this.deleteToken();
        }
    }

    async deleteToken() {
        const id = this.storage.getItem(OidcStorageHelper.idKey);
        const secret = this.storage.getItem(OidcStorageHelper.secretKey);
        if (!!id && !!secret) {
            try {
                await deleteToken(id, secret).toPromise();
            } catch (e) {
                this.storage.removeItem(OidcStorageHelper.refreshTokenTempKey);
                getLogger('t360.oidcStorage').error('Context Layer Delete Token Error: ', e);
            }
            this.storage.removeItem(OidcStorageHelper.idKey);
            this.storage.removeItem(OidcStorageHelper.secretKey);
        }
    }

    get length() {
        return this.storage.length;
    }

    key(index: number) {
        return this.storage.key(index);
    }
}
