import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQueueItem, QueueItemStatus } from '@wk/elm-uui-context-handler';

export type QueueItem = IQueueItem;
export type UploadHistoryState = QueueItem[];

const initialState: UploadHistoryState = [];

const uploadHistory = createSlice({
    name: 'upload-history',
    initialState,
    reducers: {
        setUploadHistoryItems: (
            state,
            action: PayloadAction<{ status: QueueItemStatus; uploadHistoryItems: QueueItem[] }>,
        ) => {
            // * Remove all history items that have the given status, as they will be replaced with the given items.
            // * Also remove any cancelled items.
            const availableQueue = state.filter(
                (it) => it.status !== action.payload.status && it.status !== QueueItemStatus.CANCELLED,
            );
            return availableQueue.concat(action.payload.uploadHistoryItems);
        },
        removeHistoryItems: (state, action: PayloadAction<{ items: QueueItem[] }>) =>
            state.filter((item) =>
                action.payload.items.some((removingItem) => removingItem.uniqueId === item.uniqueId),
            ),
        removeAllCancelledHistoryItems: (state) => state.filter((it) => it.status !== QueueItemStatus.CANCELLED),
    },
});

// Actions & Reducer
export type UploadHistoryActions = TakeActions<typeof uploadHistory.actions>;
export const { setUploadHistoryItems, removeHistoryItems, removeAllCancelledHistoryItems } = uploadHistory.actions;
export const uploadHistoryReducer = uploadHistory.reducer;
