import { makeStyles } from '@mui/styles';
import { Logger } from '@wk/elm-uui-common';
import classNames from 'classnames';
import { Report } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { CSSProperties, forwardRef, MouseEvent, useEffect, useRef, useState } from 'react';
import { useResizeObserver } from '../../../../hooks';
import { EmbedTokenStatus, LayoutPowerBIComponent } from '../../../../store/slices';
import { getLogger } from '../../../../utils/loggingService';
import { AppTheme } from '../../../app';
import { FullScreenOverlayIconEnum } from '../../../common/types';
import { MessageView } from '../messageView';
import { ViewHeader } from '../viewHeader';
import { CHART_ADJUST } from './common/constants';
import { ChartStatus } from './common/enums';
import { usePowerBI } from './hooks';

const CONTAINER_MIN_HEIGHT = 300;
const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
    },
    reportContainer: {
        flex: 1,
    },
    report: {
        marginTop: theme.spacing(1.5),
        height: '100%',

        '& iframe': {
            border: 0,
        },
    },
    hiddenReport: {
        display: 'none',
    },
    isServiceDown: {
        border: `1px solid ${theme.palette.grey[300]}`,
        minHeight: 300,
        maxHeight: 300,
    },
    emptyGadgetContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    iconContainer: {
        fontSize: '75px !important',
        width: '100px !important',
    },
}));

const logger = (): Logger => getLogger('PowerBiView');

type LayoutPowerBiViewProps = {
    component: LayoutPowerBIComponent;
    classes: {
        root: string;
    };
    containerSize: {
        width: number;
        height: number;
    };
    onClose?: (props: { event: MouseEvent<HTMLButtonElement> }) => void;
    onChange?: (props: { event: MouseEvent<HTMLButtonElement>; minimized: boolean }) => void;
    className?: string;
    style?: CSSProperties;
};

// eslint-disable-next-line max-lines-per-function
export const LayoutPowerBiView = forwardRef<HTMLDivElement, LayoutPowerBiViewProps>((props, ref) => {
    const { component, classes: externalClasses, containerSize, onClose, onChange, className, style } = props;
    const { embedConfig, chartStatus, fetchDataAndInit, resize, tokenStatus } = usePowerBI({ component });
    const [report, setReport] = useState<Report | undefined>(undefined);
    // `report` methods may require report to be fully rendered, e.g. `getPages` below in `fetchChartPath`
    const [reportRendered, setReportRendered] = useState(false);
    const [reportErrors, setReportErrors] = useState(false);
    const reportRef = useRef<HTMLDivElement>(null);
    const size = useResizeObserver(reportRef);
    const classes = useStyles();
    const isPowerBiServiceDown =
        tokenStatus === EmbedTokenStatus.Failed || tokenStatus === EmbedTokenStatus.NotAvailable || reportErrors;

    useEffect(() => {
        if (!report || !reportRendered) {
            return;
        }

        void fetchDataAndInit(report);
    }, [report, reportRendered]);

    useEffect(() => {
        if (!report || chartStatus !== ChartStatus.Initialized) {
            return;
        }

        const { width, height } = size;

        resize?.(width - CHART_ADJUST, height - CHART_ADJUST);
    }, [size, chartStatus]);

    const close = (event: MouseEvent<HTMLButtonElement>): void => {
        onClose?.({ event });
    };

    const collapse = (event: MouseEvent<HTMLButtonElement>): void => {
        onChange?.({ event, minimized: true });
    };

    const expand = (event: MouseEvent<HTMLButtonElement>): void => {
        onChange?.({ event, minimized: false });
    };
    const { title, description, buttons, isMinimized } = component;
    const powerBifailureMessage = [window.Props.powerBifailureMessage, window.Props.genericRetryMessage];
    return (
        <div
            className={classNames(className, classes.root, {
                [externalClasses.root]: !isPowerBiServiceDown || component.isMinimized,
                [classes.isServiceDown]: isPowerBiServiceDown,
            })}
            style={{ ...style, height: !isPowerBiServiceDown ? containerSize.height : CONTAINER_MIN_HEIGHT }}
            ref={ref}
            data-testid="gadget-power-bi"
            powerbi-print-container-id="powerBiPrintContainer">
            <ViewHeader
                title={title}
                description={description}
                buttons={buttons}
                isMinimized={isMinimized}
                onCollapse={collapse}
                onExpand={expand}
                onClose={close}
            />
            <div className={classes.reportContainer} ref={reportRef}>
                {
                    // FIXME: proper bootstrap config is right above, but for unknown reasons in that case all reports throw an error,
                    //  that is logged by respective event handler below.
                }
                {embedConfig.accessToken && embedConfig.embedUrl && !reportErrors && (
                    <PowerBIEmbed
                        cssClassName={classNames(classes.report, { [classes.hiddenReport]: component.isMinimized })}
                        embedConfig={embedConfig}
                        eventHandlers={
                            new Map([
                                [
                                    'error',
                                    (event): void => {
                                        if (!event) {
                                            return;
                                        }
                                        // had to define this handler to avoid redirect to generic error page
                                        event.stopPropagation();
                                        logger().error(JSON.stringify(event.detail));
                                        setReportErrors(true);
                                    },
                                ],
                                [
                                    'rendered',
                                    (): void => {
                                        setReportRendered(true);
                                    },
                                ],
                            ])
                        }
                        getEmbeddedComponent={(embeddedReport): void => {
                            setReport(embeddedReport as Report);
                        }}
                    />
                )}
                {isPowerBiServiceDown && !component.isMinimized && (
                    <MessageView
                        heading={window.Props.errorOverlayHeading}
                        icon={FullScreenOverlayIconEnum.EXCLAMATION}
                        message={powerBifailureMessage}
                        iconContainer={classes.iconContainer}
                    />
                )}
            </div>
        </div>
    );
});

LayoutPowerBiView.displayName = 'LayoutPowerBiView';
