import { Box, useMediaQuery, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { last, debounce } from 'lodash';
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    forwardRef,
    useImperativeHandle,
} from 'react';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import { useAppSelector } from '../../../../store';
import { ComponentType, LayoutComponent, LayoutGadgetComponent } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { Resizer, ResizerChangeParams } from '../../../common/resizer';
import { LayoutGridView, LayoutPowerBiView } from '../index';

const MIN_WIDTH = 495;
const MIN_HEIGHT = 300;
const MIN_HEIGHT_VIS = 600;
const MAX_HEIGHT = 790;
const COLUMNS_BREAK_POINT = 1300;

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: () => ({
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        flex: 1,

        [theme.breakpoints.up(COLUMNS_BREAK_POINT)]: {
            flexDirection: 'row',
        },
    }),
    column: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: MIN_WIDTH,

        '&:last-of-type': {
            flex: 1,
        },

        [theme.breakpoints.down(COLUMNS_BREAK_POINT)]: {
            width: '100% !important',
            display: 'contents',
        },
    },
    grid: {
        border: `1px solid ${theme.palette.grey[300]}`,
        minHeight: MIN_HEIGHT,
        maxHeight: MAX_HEIGHT,
    },
    powerBi: {
        border: `1px solid ${theme.palette.grey[300]}`,
        minHeight: MIN_HEIGHT_VIS,
        maxHeight: MAX_HEIGHT,
    },
    gadgetMinimized: {
        minHeight: 50,
        maxHeight: 50,
    },
    columnResizer: {
        '&:last-of-type': {
            display: 'none',
        },
    },
    emptyGadgetContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.grey[600],
        minHeight: MIN_HEIGHT,
        width: '100%',
    },
}));

type LayoutProps = {
    component: LayoutComponent;
    onChange?: (props: { component: LayoutComponent }) => void;
};

export type CustomPrintHandle = {
    customToPrint: (printWindow: HTMLIFrameElement) => Promise<void>;
};

// eslint-disable-next-line max-lines-per-function
export const LayoutView = forwardRef<CustomPrintHandle | undefined, LayoutProps>((props, ref) => {
    const { component, onChange } = props;
    const classes = useStyles();
    const isLargeScreen = useMediaQuery<AppTheme>((theme) => theme.breakpoints.up(COLUMNS_BREAK_POINT));
    const [columnsWidth, setColumnsWidth] = useState(new Map<string, number>());
    const [gadgetHeight, setGadgetHeight] = useState(new Map<string, number>());
    const holderRef = useRef<HTMLDivElement>(null);
    const resizerRefs = useRef(new Map<string, HTMLHRElement | null>());
    const gadgetRefs = useRef(new Map<string, HTMLDivElement | null>());
    const totalWidth = holderRef.current?.offsetWidth || 0;
    const windowWidth = useWindowWidth();
    const { mainMenuExpanded } = useAppSelector((state) => state.ui.persistState);

    useImperativeHandle(
        ref,
        (): CustomPrintHandle => ({
            customToPrint,
        }),
    );

    const getColumnComponents = (id: string) => {
        return component.components.filter(({ layoutColumnId }) => layoutColumnId === id);
    };

    const columns = useMemo(
        () =>
            (component.components.length && component.columns.filter(({ id }) => getColumnComponents(id).length)) ||
            (component.columns.length && component.columns.slice(0, 1)) ||
            [],
        [component.columns, component.components],
    );

    const hasMinWidthColumn = (): boolean => {
        const hasMinWidth = [...columnsWidth.entries()].some(([key, value]) => key && value <= MIN_WIDTH);
        return hasMinWidth;
    };

    useEffect(() => {
        setColumnsWidth(new Map<string, number>());
    }, [columns]);

    useEffect(() => {
        if (!columns.length || !totalWidth) {
            return;
        }

        setColumnsWidth((columnsWidth) => {
            const currentColumnsInfo =
                (columnsWidth.size && [...columnsWidth.entries()].map(([id, width]) => ({ id, width }))) || columns;
            const allColumnsWidth = currentColumnsInfo.reduce((agg, { width }) => agg + width, 0);
            const ratio = allColumnsWidth ? totalWidth / allColumnsWidth : 1;
            const columnsSizes = currentColumnsInfo.map((column) => {
                const width = column.width * ratio;
                return {
                    id: column.id,
                    isMinWidth: column.width <= MIN_WIDTH,
                    width,
                    flex: width / totalWidth,
                };
            });

            const resizableColumns = columnsSizes.filter((item) => !item.isMinWidth);
            const resizableWidth = totalWidth - (columnsSizes.length - resizableColumns.length) * MIN_WIDTH;
            const resizableFlex = resizableColumns.reduce((agg, { flex }) => agg + flex, 0);

            if (!resizableColumns.length) {
                return new Map<string, number>([
                    [columnsSizes[0].id, totalWidth - (MIN_WIDTH * currentColumnsInfo.length - 1)],
                    ...columnsSizes.slice(1).map(({ id }) => [id, MIN_WIDTH] as [string, number]),
                ]);
            } else {
                const values = columnsSizes.map((size) => {
                    const width = size.isMinWidth ? MIN_WIDTH : resizableWidth * (size.flex / resizableFlex);
                    return [size.id, width] as [string, number];
                });
                return new Map<string, number>(values);
            }
        });
    }, [columns, totalWidth, windowWidth, mainMenuExpanded]);

    useEffect(() => {
        const data = component.components?.map(({ id, height }) => [id, height] as [string, number]) || [];
        setGadgetHeight(new Map(data));
    }, [component.components]);

    const onColumnsWidthChange = useCallback(
        debounce((params: Map<string, number>) => {
            const updatedComponent = {
                ...component,
                columns: component.columns.map((item) => {
                    const newWidth = params.get(item.id);
                    return newWidth && newWidth !== item.width ? { ...item, width: newWidth } : item;
                }),
            };
            onChange?.({ component: updatedComponent });
        }, 300),
        [component],
    );

    const adjustColumnsWidth = (params: ResizerChangeParams & { id: string }) => {
        const totalWidth = holderRef.current?.offsetWidth || 0;
        setColumnsWidth((columnsWidth) => {
            const checkMinWidth = (width: number) => (width < MIN_WIDTH ? MIN_WIDTH : width);
            const { id, offset } = params;
            const keys = [...columnsWidth.keys()];
            const nextColumnId = keys[keys.indexOf(id) + 1];
            const resizerWidth = resizerRefs.current.get(id)?.offsetWidth || 0;
            const newColumnWidth = checkMinWidth(offset - resizerWidth / 2);
            const newNextColumnsWidth = checkMinWidth(totalWidth - offset - resizerWidth);

            columnsWidth.set(id, newColumnWidth);
            columnsWidth.set(nextColumnId, newNextColumnsWidth);
            onColumnsWidthChange(columnsWidth);

            return new Map(columnsWidth);
        });
    };

    const onGadgetHeightChange = useCallback(
        debounce((params: { id: string; height: number }) => {
            const updatedComponent = {
                ...component,
                components: component.components.map((item) => {
                    return item.id === params.id ? { ...item, height: params.height } : item;
                }),
            };
            onChange?.({ component: updatedComponent });
        }, 300),
        [component],
    );

    const adjustGadgetsHeight = (params: ResizerChangeParams & { id: string }) => {
        const resizerHeight = resizerRefs.current.get(params.id)?.offsetHeight || 0;
        const height = params.offset - resizerHeight / 2;

        setGadgetHeight((currentGadgetHeight) => {
            return new Map([...currentGadgetHeight, [params.id, height]]);
        });

        onGadgetHeightChange({ id: params.id, height });
    };

    const onGadgetComponentChange = (gadgetComponent: LayoutGadgetComponent) => {
        const updatedComponent = {
            ...component,
            components: component.components.map((item) => {
                return (item.id === gadgetComponent.id && gadgetComponent) || item;
            }),
        };
        onChange?.({ component: updatedComponent });
    };

    const onGadgetComponentRemove = (gadgetComponent: LayoutGadgetComponent) => {
        const columnIds = component.columns.map(({ id }) => id);
        const components = component.components
            .filter((item) => item.id !== gadgetComponent.id)
            .map((item, index) => {
                return {
                    ...item,
                    order: Math.floor(index / columnIds.length),
                    layoutColumnId: columnIds[index % columnIds.length],
                };
            });

        const updatedComponent = {
            ...component,
            components,
        };
        onChange?.({ component: updatedComponent });
    };

    const customToPrint = (printWindow: HTMLIFrameElement): Promise<void> => {
        const printContent = printWindow.contentDocument ?? printWindow.contentWindow?.document ?? undefined;
        const printedScrollContainerList = printContent?.querySelectorAll(
            '.MuiDataGrid-virtualScroller, div[announcement-print-scroller="announcementPrintScroller"',
        );
        const originScrollContainerList = document.querySelectorAll(
            '.MuiDataGrid-virtualScroller, div[announcement-print-scroller="announcementPrintScroller"',
        );
        const powerBIContainerList = printContent?.querySelectorAll(
            'div[powerbi-print-container-id="powerBiPrintContainer"]',
        );

        powerBIContainerList?.forEach((element) => {
            element.remove(); // remove powerBi reports from printing
        });

        if (!printedScrollContainerList || !originScrollContainerList) {
            return Promise.resolve();
        }

        const getScrollHeaderText = (scrollContainer: Element): string | null | undefined =>
            scrollContainer
                .closest('div[data-testid="layout-gadget-grid"], div[data-testid="announcementAccordion"]')
                ?.querySelector('div[print-key="printKey"] > span, p[print-key="printKey"]')?.textContent;

        const originScrollContainerRecords: Record<string, Element> = Array.from(originScrollContainerList).reduce(
            (acc, scroll) => {
                const scrollHeaderText = getScrollHeaderText(scroll);
                scrollHeaderText && (acc[scrollHeaderText] = scroll);
                return acc;
            },
            {},
        );

        Array.from(printedScrollContainerList).forEach((printedScrollContainer) => {
            const scrollHeaderText = getScrollHeaderText(printedScrollContainer);
            scrollHeaderText &&
                printedScrollContainer?.scrollTo(0, Number(originScrollContainerRecords[scrollHeaderText]?.scrollTop));
        });

        printWindow?.contentWindow?.print();
        // print must return a Promise
        return Promise.resolve();
    };

    const renderGadget = (columnComponent: LayoutGadgetComponent) => {
        return (
            <Fragment key={columnComponent.code}>
                {columnComponent.type === ComponentType.LayoutGridComponent && (
                    <Resizer
                        ref={(instance) => resizerRefs.current.set(columnComponent.id, instance)}
                        aria-label="Layout Gadget Splitter"
                        disabled={columnComponent.isMinimized}
                        style={{ order: columnComponent.order }}
                        onChange={(params) => {
                            adjustGadgetsHeight({ ...params, id: columnComponent.id });
                        }}>
                        <LayoutGridView
                            ref={(instance) => gadgetRefs.current.set(columnComponent.id, instance)}
                            style={{
                                height: gadgetHeight.get(columnComponent.id),
                                order: columnComponent.order,
                            }}
                            classes={{
                                root: classNames(classes.grid, {
                                    [classes.gadgetMinimized]: columnComponent.isMinimized,
                                }),
                            }}
                            component={columnComponent}
                            onChange={({ minimized }) =>
                                onGadgetComponentChange({ ...columnComponent, isMinimized: minimized })
                            }
                            onClose={() => onGadgetComponentRemove({ ...columnComponent })}
                        />
                    </Resizer>
                )}
                {columnComponent.type === ComponentType.LayoutPowerBiComponent && (
                    <Resizer
                        ref={(instance) => resizerRefs.current.set(columnComponent.id, instance)}
                        aria-label="Layout Gadget Splitter"
                        disabled={columnComponent.isMinimized}
                        style={{ order: columnComponent.order }}
                        onChange={(params) => {
                            adjustGadgetsHeight({ ...params, id: columnComponent.id });
                        }}>
                        <LayoutPowerBiView
                            ref={(instance) => gadgetRefs.current.set(columnComponent.id, instance)}
                            containerSize={{
                                height: gadgetHeight.get(columnComponent.id) || 600,
                                width: columnsWidth.get(columnComponent.layoutColumnId || '') || 0,
                            }}
                            style={{ order: columnComponent.order }}
                            classes={{
                                root: classNames(classes.powerBi, {
                                    [classes.gadgetMinimized]: columnComponent.isMinimized,
                                }),
                            }}
                            component={columnComponent}
                            onChange={({ minimized }) =>
                                onGadgetComponentChange({ ...columnComponent, isMinimized: minimized })
                            }
                            onClose={() => onGadgetComponentRemove({ ...columnComponent })}
                        />
                    </Resizer>
                )}
            </Fragment>
        );
    };

    const renderColumn = (columnId: string) => {
        const columnComponents = getColumnComponents(columnId);
        const width = columnsWidth?.get(columnId) || 0;
        const allColumnsWidth = [...columnsWidth.values()].reduce((agg, width = 0) => agg + width, 0) || 0;
        const resizerWidth = resizerRefs.current.get(columnId)?.offsetWidth || 0;
        const flex = isLargeScreen ? width / (allColumnsWidth + resizerWidth / 2) || 1 : undefined;

        return (
            <div className={classes.column} style={{ flex }} data-testid="layout-column">
                {columnComponents?.map((columnComponent) => renderGadget(columnComponent))}
            </div>
        );
    };

    return (
        <div ref={holderRef} className={classes.root}>
            {columns?.map((column, index) => {
                return (
                    <Resizer
                        ref={(instance) => resizerRefs.current.set(column.id, instance)}
                        onChange={(params) => {
                            adjustColumnsWidth({ ...params, id: column.id });
                        }}
                        orientation="vertical"
                        aria-label="Layout Column Splitter"
                        hidden={last(columns)?.id === column.id || !isLargeScreen}
                        classes={{ root: classes.columnResizer }}
                        key={index}
                        isResizerRestricted={hasMinWidthColumn()}>
                        {renderColumn(column.id)}
                    </Resizer>
                );
            })}
            {!columns.length && (
                <Box className={classes.emptyGadgetContainer}>
                    <Typography variant="h5">{component.emptyContentMessage}</Typography>
                </Box>
            )}
        </div>
    );
});

LayoutView.displayName = 'LayoutView';
