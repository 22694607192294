import { IUserScreenState, UserScreenAction } from '../types';

export const userScreenReducer = (state: IUserScreenState, action: UserScreenAction): IUserScreenState => {
    switch (action.type) {
        // INITIALIZE
        case 'SetSelectedContext':
            return { ...state, selectedContext: action.selectedContext };
        default:
            return state;
    }
};
