import { Autocomplete } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '../../../store';
import { getAppResources } from '../../../store/slices';
import { UUIInput } from '../../common/uuiInput';
import { getValidationMessagesForField } from '../itemScreenHelpers';
import { ControlTypeProps, IDropdownOption } from '../types';
import { ControlLabel } from './controlLabel';
import css from './dropdownListControl.module.scss';
import ReadOnlyControl from './readOnlyControl';

export const DropdownListControl: React.FC<ControlTypeProps> = ({ field, fieldData, readOnly }) => {
    const { register, setValue, errors } = useFormContext();
    const [selectedValue, setSelectedValue] = useState<string | null>(fieldData.displayValue || null);
    const [inputValue, setInputValue] = useState(fieldData.displayValue || '');
    const appResources = useAppSelector(getAppResources);

    useEffect(() => {
        if (!readOnly) {
            register({ name: field.name });
            setValue(field.name, isNaN(parseInt(fieldData.inputValue!)) ? '' : parseInt(fieldData.inputValue!));
        }
    }, [register, setValue, field.name, fieldData.inputValue, readOnly]);

    if (readOnly) {
        return <ReadOnlyControl field={field} fieldData={fieldData} />;
    }

    return (
        <Autocomplete
            id={field.name}
            value={selectedValue}
            noOptionsText={appResources.dropdownControlNoOptionsText}
            onChange={(_, newValue: string | null) => {
                if (newValue === null || newValue === '') {
                    setValue(field.name, '');
                } else {
                    const id = (field.dropDownOptions as IDropdownOption[])!.find((ddo) => ddo.value === newValue)!.id;
                    setValue(field.name, id);
                }
                setSelectedValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(_, newInputValue: string) => {
                setInputValue(newInputValue);
            }}
            options={(field.dropDownOptions as IDropdownOption[])!.map((ddo) => ddo.value)}
            classes={{
                endAdornment: css.endAdornment,
                option: css.option,
                listbox: css.dropdown,
                paper: css.paper,
                clearIndicator: css.clearIndicator,
                popupIndicator: css.popupIndicator,
            }}
            clearOnEscape={true}
            renderInput={(params) => (
                <ControlLabel
                    data-testid="dropDownListControl"
                    field={field}
                    control={
                        <UUIInput
                            {...params.InputProps}
                            inputProps={params.inputProps}
                            id={field.name}
                            name={field.name}
                            error={getValidationMessagesForField(field, errors, 'errors').length > 0}
                            warning={getValidationMessagesForField(field, errors, 'warnings').length > 0}
                            classes={{
                                focused: css.dropDownInputFocused,
                                input: css.dropDownNativeInput,
                            }}
                        />
                    }></ControlLabel>
            )}
        />
    );
};
