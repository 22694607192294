import { MenuItem, MenuProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { memo } from 'react';
import { useNetwork } from '../../../../hooks';
import { INetwork } from '../../../../reducers/types';
import { AppTheme } from '../../../app';
import { Menu } from '../menu';

const useStyles = makeStyles<AppTheme>((theme) => ({
    menuItemRoot: {
        color: theme.palette.primary.dark,
        whiteSpace: 'pre-wrap',
    },
    disabled: {
        color: theme.palette.grey.A700,
        pointerEvents: 'none',
        fontWeight: 500,

        // TODO: remove the below style and create a new class for the disabled menu item when MUI gets updated to V5
        '&.Mui-disabled': {
            opacity: 1,
        },
    },
    current: {
        backgroundColor: theme.palette.action.hover,
        fontWeight: 600,
    },
}));

export type NetworkSwitchProps = MenuProps;

export const NetworkSwitchMenu = memo<NetworkSwitchProps>(function NetworkSwitchMenu(props) {
    const { ...menuProps } = props;
    const classes = useStyles();
    const { networks, switchNetwork } = useNetwork();

    const onSelect = (network: INetwork) => {
        if (network.isCurrent) {
            return;
        }

        switchNetwork?.(network.networkId);
    };

    if (!networks?.length) {
        return null;
    }

    return (
        <Menu {...menuProps}>
            <MenuItem classes={{ root: classes.disabled }} disabled>
                Switch Network
            </MenuItem>
            {networks.map((network) => {
                return (
                    <MenuItem
                        key={network.networkId}
                        onClick={() => onSelect(network)}
                        classes={{ root: classes.menuItemRoot }}
                        className={classNames({ [classes.current]: network.isCurrent })}
                        value={network.networkId}
                        data-testid={`network-switch-select-item-${network.networkId}`}>
                        {network.networkName}
                    </MenuItem>
                );
            })}
        </Menu>
    );
});
