import { isEmpty, isUndefined } from 'lodash';
import React, { memo, FC, ReactElement, useMemo } from 'react';

type NestedItemProps =  {
    onLaunch: () => void;
};

type ChatLauncherProps = {
    nestedItem: (props: NestedItemProps) => ReactElement;
    Url: string;
};

export const ChatLauncher: FC<ChatLauncherProps> = memo((props) => {
    const { nestedItem: NestedItem, Url } = props;

    const handleOnLaunch = (): void => {
        const width = 500;
        const height = 600;
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const left = screenWidth - width;
        const top = screenHeight - height;

        window.open(
            `${Url}`,
            'LiveChatWindow',
            `width=${width}, height=${height},left=${left}, top=${top},noopener,noreferrer`,
        );
    };

    const hasUrl = useMemo(() => !isEmpty(Url) && !isUndefined(Url), [Url]);

    return hasUrl ? <NestedItem onLaunch={handleOnLaunch} /> : null;
});
ChatLauncher.displayName = 'LiveChat';
