import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/WarningRounded';
import { Tooltip, FormControl } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useItemScreenState } from '../context/itemScreenContext';
import { getReactHookFormFieldName, getValidationMessagesForField } from '../itemScreenHelpers';
import { IItemScreenField } from '../types';
import css from './controlLabel.module.scss';

interface IControlLabel {
    field: IItemScreenField;
    'data-testid'?: string;
    control: JSX.Element;
    classes?: string;
}

export const ControlLabel: React.FC<IControlLabel> = (props) => {
    const itemScreenState = useItemScreenState();
    const { errors } = useFormContext();
    const reactHookFormFieldName = getReactHookFormFieldName(props.field);
    const errorValidationMessages = getValidationMessagesForField(props.field, errors, 'errors');
    const warningValidationMessages = getValidationMessagesForField(props.field, errors, 'warnings');

    return (
        <>
            <FormControl className={css.formControl + ' ' + (props.classes || '')} data-testid={props['data-testid']}>
                <LabelTooltip title={props.field.displayName}>
                    <UUILabel
                        required={itemScreenState.mode !== 'show' && props.field.required}
                        htmlFor={reactHookFormFieldName}>
                        {props.field.displayName}
                    </UUILabel>
                </LabelTooltip>
                {props.control}
            </FormControl>
            {errorValidationMessages.map((valMsg, i) => (
                <div key={i} className={css.validationError}>
                    <span className={css.msg}>
                        <ErrorIcon className={css.msgIcon} />
                    </span>
                    <span className={css.msg}>{valMsg}</span>
                </div>
            ))}
            {warningValidationMessages.map((valMsg, i) => (
                <div key={i} className={css.validationWarning}>
                    <span className={css.msg}>
                        <WarningIcon className={css.msgIcon} />
                    </span>
                    <span className={css.msg}>{valMsg}</span>
                </div>
            ))}
        </>
    );
};

interface IUUILabel {
    required?: boolean;
    htmlFor: string;
}

const UUILabel: React.FC<IUUILabel> = ({ required, htmlFor, children }) => {
    return (
        <label htmlFor={htmlFor} className={css.uuiLabel}>
            {children}
            {required && (
                <span aria-hidden="true" className={css.uuiLabelAsterisk}>
                    *
                </span>
            )}
        </label>
    );
};

interface ILabelTooltip {
    title: string;
}

const LabelTooltip: React.FC<ILabelTooltip> = ({ title, children }) => {
    if (title.length > 18) {
        return (
            <Tooltip
                title={title}
                enterDelay={500}
                placement="top-start"
                classes={{
                    tooltipPlacementTop: css.tooltip,
                }}>
                <span>{children}</span>
            </Tooltip>
        );
    } else {
        return <>{children}</>;
    }
};

export default LabelTooltip;
