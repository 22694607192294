import { getAuthToken } from '@wk/elm-uui-doc-component';
import { IQueueServiceInterface } from '@wk/elm-uui-queuemanager';
import {
    Application,
    AutoUpdateCommandType,
    AutoUpdateRequest,
    AutoUpdateRequestCondition,
    AutoUpdateRequestType,
    AutoUpdateStateChangeRequest,
    AutoUpdateStateType,
    AutoUpdateValue,
    AutoUpdateValueType,
} from '@wk/office-companion-js';
import { inject, injectable } from 'inversify';
import { Observable, Subject, Subscription } from 'rxjs';
import { getQueue } from '../capability';
import { factory } from '../configLog4J';
import { CHMessagingScope, EventType, FullScreenOverlayIconEnum } from '../enum/enum';
import { IPropKeys } from '../interfaces/operations';
import { IEventService } from './eventContextHandler.service';
import { OverlayDialogButtonAction } from './interface';
import { CHAutoUpdateStatus, IAutoUpdate } from './interface/autoUpdate.interface';
import { CHMessagingService } from './message.service';
import { CHProperties } from './props.service';

const log = factory.getLogger('AutoUpdateService');

@injectable()
export class AutoUpdateService implements IAutoUpdate {
    private _chAutoUpdateRequestStream!: Subject<AutoUpdateStateChangeRequest>;
    private _chAutoUpdateCommandStream!: Subject<AutoUpdateStateChangeRequest>;
    private _chUIResponseStream = new Subject<CHAutoUpdateStatus>();
    private _eventService: IEventService;
    private _chMessageService: CHMessagingService;
    private OPEN_AUTO_UPDATE_DOWNLOADING_OVERLAY_DIALOG = 'OpenAutoUpdateDownloadingOverlayDialog';
    private OPEN_AUTO_UPDATE_SUCCESSFUL_DOWNLOAD_OVERLAY_DIALOG = 'OpenAutoUpdateSuccessfulDownloadOverlayDialog';

    private _chProperties: CHProperties;
    queueManagerObj!: IQueueServiceInterface;

    constructor(
        @inject('PropsService') propertyService: CHProperties,
        @inject('EventContextHandler') eventService: IEventService,
        @inject('CHMessagingService') chMessageService: CHMessagingService,
    ) {
        this._chProperties = propertyService;
        this._eventService = eventService;
        this._chMessageService = chMessageService;
    }

    public getResponseStream(): Observable<CHAutoUpdateStatus> {
        return this._chUIResponseStream.asObservable();
    }

    public subscribe(observer: (data: CHAutoUpdateStatus) => void): Subscription {
        this.startListeningForAutoUpdateStatus();
        this.startListeningForAutoUpdateMessageBusEvents();
        return this.getResponseStream().subscribe(observer);
    }

    public notifyCommandToOC(): void {
        log.debug('notifyCommandToOC ' + JSON.stringify('data'));
        this._chAutoUpdateCommandStream = new Subject<AutoUpdateStateChangeRequest>();
        Application.version(this._chAutoUpdateCommandStream).subscribe(({ data }) => {
            if (data?.valueType === AutoUpdateValueType.State) {
                if (data?.state?.state === AutoUpdateStateType.AvailableForDownload) {
                    log.debug('Download command sent!');

                    this._chAutoUpdateCommandStream?.next({
                        command: AutoUpdateCommandType.Download,
                    });
                } else if (data?.state?.state === AutoUpdateStateType.ReadyForInstall) {
                    log.debug('Install command sent!');
                    this.pauseQueue();

                    this._chAutoUpdateCommandStream?.next({
                        command: AutoUpdateCommandType.Install,
                    });
                }
            }
        });
    }

    private startListeningForAutoUpdateStatus(): void {
        log.debug('Start Listening For Status From OC');
        this._chAutoUpdateRequestStream = new Subject<AutoUpdateStateChangeRequest>();
        Application.version(this._chAutoUpdateRequestStream).subscribe(({ data }) => {
            if (data?.valueType === AutoUpdateValueType.State) {
                this.manageAutoUpdateState(data);
            }

            if (data?.valueType === AutoUpdateValueType.Request) {
                this.manageAutoUpdateRequest(data);
            }
        });
    }

    private startListeningForAutoUpdateMessageBusEvents(): void {
        log.debug('Start Listening For Auto Update Message Bus Events Other OC instances');
        this._chMessageService.subscribe((data) => {
            switch (data.type) {
                case this.OPEN_AUTO_UPDATE_DOWNLOADING_OVERLAY_DIALOG:
                    this.publishDialog('Downloading', { notifyBus: false });
                    break;
                case this.OPEN_AUTO_UPDATE_SUCCESSFUL_DOWNLOAD_OVERLAY_DIALOG:
                    this.publishDialog('SuccessfulDownload', { notifyBus: false });
                    break;
            }
        });
    }

    private async manageAutoUpdateRequest(data: AutoUpdateValue): Promise<void> {
        log.debug('valueType data recieved from OCJS : ' + JSON.stringify(data));
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const typeOfRequest: AutoUpdateRequest = data.request!;

        if (
            typeOfRequest.type === AutoUpdateRequestType.UpdateServerInfo &&
            (typeOfRequest.condition === AutoUpdateRequestCondition.OnCheckForUpdate ||
                typeOfRequest.condition === AutoUpdateRequestCondition.OnDownload)
        ) {
            let authHeader = {};
            const authToken = await getAuthToken();
            if (authToken) {
                authHeader = {
                    Authorization: 'Bearer ' + authToken,
                };
            }
            // get autoUpdate server address from props service
            const keyName = await this._chProperties.get(IPropKeys.autoUpdateServerURL);

            this._chAutoUpdateRequestStream.next({
                command: AutoUpdateCommandType.ProvideUpdateServerInfo,
                serverData: {
                    url: keyName ? keyName.toString() : '',
                    requestHeaders: authHeader || undefined,
                },
            });
        }
    }

    private manageAutoUpdateState(data: AutoUpdateValue) {
        log.debug('state data recieved from OCJS : ' + JSON.stringify(data));
        switch (
            data?.state?.state // states are listed in triggered order
        ) {
            case AutoUpdateStateType.Initial:
                log.debug('state: ' + data.state?.state);
                this.resumeQueue();
                break;
            case AutoUpdateStateType.ClientsAttached:
                log.debug('state: ' + data.state?.state);
                break;
            case AutoUpdateStateType.CheckingUpdates:
                log.debug('state: ' + data.state?.state);
                break;
            case AutoUpdateStateType.AvailableForDownload:
                log.debug('state: ' + data.state?.state);

                this._chUIResponseStream.next({
                    type: AutoUpdateStateType.AvailableForDownload,
                    state: data.state,
                });

                this._chAutoUpdateRequestStream?.next({
                    command: AutoUpdateCommandType.Download,
                });
                break;
            case AutoUpdateStateType.Downloading:
                log.debug('state: ' + data.state);
                /*
                this._chUIResponseStream.next({
                    type: AutoUpdateStateType.Downloading,
                    state: data.state
                });
                */
                this.publishDialog('Downloading');
                break;
            case AutoUpdateStateType.ReadyForInstall:
                log.debug('state: ' + data.state?.state);

                /*
                this._chUIResponseStream.next({
                    type: AutoUpdateStateType.ReadyForInstall,
                    state: data.state
                });
                */
                this.publishDialog('SuccessfulDownload');
                break;
            case AutoUpdateStateType.InstallationInProgress:
                log.debug('state: ' + data.state);
                break;
            default:
                log.debug('state: ' + data.state);
                break;
        }
    }

    private async pauseQueue(): Promise<void> {
        (await getQueue()).setQueuePaused();
    }

    private async resumeQueue(): Promise<void> {
        (await getQueue()).setQueueResumed();
    }

    private async getQueueManager(): Promise<IQueueServiceInterface> {
        return await getQueue();
    }

    private publishDialog(dialogType: 'Downloading' | 'SuccessfulDownload', opts = { notifyBus: true }) {
        switch (dialogType) {
            case 'Downloading':
                if (opts.notifyBus) {
                    this._chMessageService.notify({
                        type: this.OPEN_AUTO_UPDATE_DOWNLOADING_OVERLAY_DIALOG,
                        scope: CHMessagingScope.OtherInstances,
                        message: 'autoupdatedownloading',
                    });
                }
                this._eventService.publish({
                    name: EventType.OVERLAY,
                    overlay: {
                        heading: 'Downloading software update',
                        icon: FullScreenOverlayIconEnum.DOWNLOAD,
                        message: [
                            'After the update has been downloaded, you can proceed to install it.',
                            'This will take a few minutes.',
                        ],
                        showSpinner: true,
                    },
                });
                break;
            case 'SuccessfulDownload':
                if (opts.notifyBus) {
                    this._chMessageService.notify({
                        type: this.OPEN_AUTO_UPDATE_SUCCESSFUL_DOWNLOAD_OVERLAY_DIALOG,
                        scope: CHMessagingScope.OtherInstances,
                        message: 'autoupdatesuccessfuldownload',
                    });
                }
                this._eventService.publish({
                    name: EventType.OVERLAY,
                    overlay: {
                        heading: 'Update successfully downloaded',
                        icon: FullScreenOverlayIconEnum.CHECKMARK,
                        message: [
                            'Install the software to continue working.',
                            "Please save and close your work before installation to ensure it's not lost.",
                        ],
                        showSpinner: false,
                        button: {
                            text: 'Close Apps and Install Updates',
                            action: OverlayDialogButtonAction.InstallUpdate,
                        },
                    },
                });
                break;
        }
        log.debug('overlay');
    }
}
