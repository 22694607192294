import { SvgIcon, SvgIconProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles({
    mini: {
        display: 'none',
    },
    default: {
        display: 'flex',
    },
    '@media (max-width: 375px)': {
        mini: {
            display: 'flex',
        },
        default: {
            display: 'none',
        },
    },
});

const WkIcon: React.FC<SvgIconProps> = ({ className, ...props }) => {
    const classes = useStyles();

    return (
        <>
            <SvgIcon className={classNames(className, classes.default)} viewBox="0 0 26 26" {...props}>
                <defs>
                    <clipPath id="A">
                        <circle cx="13" cy="13" r="13" fill="#007ac3" />
                    </clipPath>
                </defs>
                <circle cx="13" cy="13" r="13" fill="#007ac3" />
                <g clipPath="url(#A)">
                    <path fill="#85bc20" d="M3.799 3.799h18.237v18.237H3.799z" />
                    <path fill="#a6d1ea" d="M0 9.878h25.836v6.079H0z" />
                    <path fill="#409bd2" d="M9.878 9.878h15.957v6.079H9.878z" />
                    <path fill="#a6d1ea" d="M9.878 0h6.079v25.836H9.878z" />
                    <path fill="#409bd2" d="M9.878 9.878h6.079v15.957H9.878z" />
                    <path fill="#a4cd58" d="M9.878 9.878h12.158v12.158H9.878z" />
                    <path fill="#d4e8b1" d="M3.799 3.799h12.158v12.158H3.799z" />
                    <path fill="#409bd2" d="M0 0h9.878v9.878H0z" />
                    <path fill="#e5202e" d="M9.878 9.878h6.079v6.079H9.878z" />
                    <path fill="#a4cd58" d="M3.799 3.799h6.079v6.079H3.799z" />
                    <path fill="#85bc20" d="M15.957 15.957h6.079v6.079h-6.079z" />
                </g>
                <g fill="#fff">
                    <path d="M24.895 25.433h-.17v-.808h.26a.4.4 0 0 1 .134.019c.095.02.162.103.163.2-.004.111-.087.204-.197.221h0a1.16 1.16 0 0 1 .235.348l.01.019h-.195l-.048-.085c-.083-.146-.133-.233-.162-.236h-.03v.321zm0-.678v.227h.041c.1 0 .164-.043.164-.116.006-.046-.023-.089-.068-.1-.03-.01-.061-.013-.092-.011z" />
                    <path d="M24.999 26a1 1 0 1 1 0-2 1 1 0 1 1 0 2zm0-1.789a.79.79 0 0 0-.789.789.79.79 0 0 0 .789.789.79.79 0 0 0 .789-.789.79.79 0 0 0-.789-.789z" />
                </g>
            </SvgIcon>
            <SvgIcon className={classNames(className, classes.mini)} viewBox="0 0 30 30" {...props}>
                <defs>
                    <clipPath id="clip-path">
                        <circle id="Ellipse_131" data-name="Ellipse 131" cx="15" cy="15" r="15" />
                    </clipPath>
                </defs>
                <g id="WK_Logo" data-name="WK Logo" transform="translate(-630 -459)">
                    <rect
                        id="Rectangle_2218"
                        data-name="Rectangle 2218"
                        width="30"
                        height="30"
                        transform="translate(630 459)"
                        fill="none"
                    />
                    <g id="Logo" transform="translate(630 459)">
                        <g id="Wheel" clipPath="url(#clip-path)">
                            <rect id="Rectangle" width="20" height="20" fill="#a6d1ea" />
                            <rect
                                id="Rectangle-2"
                                width="42.5"
                                height="10"
                                transform="translate(-6.25 10)"
                                fill="#d4e8b1"
                            />
                            <rect
                                id="Rectangle-3"
                                width="26.25"
                                height="10"
                                transform="translate(10 10)"
                                fill="#409bd2"
                            />
                            <rect
                                id="Rectangle-4"
                                width="10"
                                height="42.5"
                                transform="translate(10 -6.25)"
                                fill="#d4e8b1"
                            />
                            <rect
                                id="Rectangle-5"
                                width="10"
                                height="26.25"
                                transform="translate(10 10)"
                                fill="#409bd2"
                            />
                            <rect id="Rectangle-6" width="20" height="20" transform="translate(10 10)" fill="#a4cd58" />
                            <rect id="Rectangle-7" width="10" height="10" transform="translate(10 10)" fill="#e5202e" />
                            <rect id="Rectangle-8" width="10" height="10" transform="translate(20 20)" fill="#007ac3" />
                        </g>
                    </g>
                </g>
            </SvgIcon>
        </>
    );
};

export default WkIcon;
