import { List } from '@mui/material';
import React from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import css from './reorderableList.module.scss';

interface IReorderableList {
    onDragEnd: (result: DropResult) => void;
}

const ReorderableList: React.FC<IReorderableList> = ({ onDragEnd, children }) => (
    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="selectedColumns">
            {(provided) => (
                <List
                    data-testid="reorderableList"
                    className={css.listRoot}
                    ref={provided.innerRef}
                    {...provided.droppableProps}>
                    {children}
                    {provided.placeholder}
                </List>
            )}
        </Droppable>
    </DragDropContext>
);

export default ReorderableList;
