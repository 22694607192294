import { GridSortModel } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
    GridComponent,
    GridComponentSource,
    GridComponentSourceViewData,
    ResponseType,
    WarningActionResponse,
} from '../../../../../store/slices';
import { apiDownload, apiFetch } from '../../../../../utils/fetchUtils';

type GridViewRow = Record<string, GridComponentSourceViewData> & {
    id: string;
};

type UseViewScreenProps = {
    component: GridComponent;
    currentPage: number;
    sortModel: GridSortModel;
    sharedStorage: Record<string, unknown>;
    hasFilter: boolean;
};

type ErrorActionResponse = { message: string; type: ResponseType.Error };
type RedirectActionResponse = { url: string; type: ResponseType.Redirect };

type GridActionResponse = ErrorActionResponse | WarningActionResponse | RedirectActionResponse;

// eslint-disable-next-line max-lines-per-function
export const useGridView = (props: UseViewScreenProps) => {
    const { component, currentPage, sortModel, sharedStorage, hasFilter } = props;
    const { apiContextRoot, apiContextPath } = window.Props as Record<string, string>;
    const [data, setData] = useState<GridViewRow[]>();
    const [rowsCount, setRowsCount] = useState(0);
    const [pagesCount, setPagesCount] = useState(0);
    const [clientScript, setClientScript] = useState<string>();
    const [clientStyle, setClientStyle] = useState<string>();
    const [lastUrl, setLastUrl] = useState('');

    useEffect(() => {
        // if we have filter, wait till it initiate the request
        if (!component.dataSource || (hasFilter && !sharedStorage[`grid.${component.dataSource}.filter`])) {
            return;
        }

        const gridDataUrl = buildQueryParameters(
            `${apiContextRoot}${apiContextPath}/view/component/GridComponent/getSource?dataSource=${component.dataSource}`,
            sharedStorage[`grid.${component.dataSource}.filter`] as Record<string, string>,
        );

        if (lastUrl === gridDataUrl) {
            return;
        }

        setLastUrl(gridDataUrl);
        const fetchData = async (): Promise<void> => {
            let rowId = 1;
            const value = await apiFetch<GridComponentSource>(gridDataUrl);
            const newData = value?.data.map((items) => {
                const row = component.columns.reduce(
                    (agg, item, index) => {
                        const row = items[item.code];
                        const key = component.columns[index]?.code;
                        if (key) {
                            agg[key] = row;
                        }
                        return agg;
                    },
                    { id: (rowId++ as unknown as string) + '-' + uuid() } as GridViewRow,
                );
                return row;
            });
            setPagesCount(value?.pagesCount);
            setRowsCount(value?.rowsCount);
            setClientScript(value?.clientScript);
            setClientStyle(value?.clientStyle);
            setData(newData);
        };

        void fetchData();
    }, [component.dataSource, currentPage, sortModel, sharedStorage[`grid.${component.dataSource}.filter`]]);

    const columns = useMemo(() => {
        return component.columns.map((column) => {
            const key = column.code;
            return {
                ...column,
                key,
            };
        });
    }, [component.columns]);

    const download = (): void => {
        const url = buildQueryParameters(
            `${apiContextRoot}${apiContextPath}/view/component/GridComponent/export?dataSource=${component.dataSource}`,
            sharedStorage[`grid.${component.dataSource}.filter`] as Record<string, string>,
        );
        void apiDownload(url);
    };

    const gridAction = async (
        actionName: string,
        data: Record<string, Record<string, string>> | string,
    ): Promise<void | WarningActionResponse> => {
        const url = addPageParameters(
            `${apiContextRoot}${apiContextPath}/view/component/GridComponent/sourceAction?dataSource=${component.dataSource}&actionName=${actionName}`,
        );
        const response = await apiFetch<GridActionResponse>(url, data, undefined, {
            credentials: 'include',
            mode: 'cors',
        });

        switch (response.type) {
            case ResponseType.Error:
                break;
            case ResponseType.Warning:
                return response;
            case ResponseType.WarningToast:
                return response;
            case ResponseType.Redirect:
                window.location.href = response.url;
                break;
            default:
                break;
        }
    };

    const buildQueryParameters = (baseUrl: string, sharedStorage: Record<string, string>): string => {
        return addPageParameters(
            `${baseUrl}${addPagingParameters()}${prepareFiltersParameters(sharedStorage)}${addSortParameters()}`,
        );
    };

    const addPageParameters = (url: string): string => {
        const queryParameters = new URLSearchParams(window.location.search);
        return `${url}&${queryParameters.toString()}`;
    };

    const addSortParameters = (): string => {
        const sort = sortModel[0];
        if (sort) {
            return `&sort=${sort.field}&desc=${sort.sort == 'desc' ? 'true' : 'false'}`;
        }
        return '';
    };

    const addPagingParameters = (): string => {
        if (currentPage > 1) {
            return `&page=${currentPage}&take=${component.rowsPerPage}`;
        }
        return '';
    };

    const prepareFiltersParameters = (newFilterValues: Record<string, string>): string => {
        let existingFilters = '';
        for (const key in newFilterValues) {
            const value = newFilterValues[key];
            if (value && value !== '') {
                existingFilters = `${existingFilters}&${key}=${value}`;
            }
        }
        return existingFilters;
    };

    return {
        data,
        columns,
        rowsCount,
        pagesCount,
        clientScript,
        clientStyle,
        download,
        gridAction,
    };
};
