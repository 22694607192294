import { Paper } from '@mui/material';
import React from 'react';
import css from './transferBasketPanel.module.scss';

interface ITransferBasketPanel {
    fullHeight?: boolean;
    onBlur?: () => void;
}

const TransferBasketPanel: React.FC<ITransferBasketPanel> = ({ fullHeight, onBlur, children }) => (
    <Paper onBlur={onBlur} className={fullHeight ? css.pickerPaperFullHeight : css.pickerPaper}>
        {children}
    </Paper>
);

export default TransferBasketPanel;
