import { getLogger, initializeLoggerFactory, LogLevel } from '@wk/elm-uui-common';
import { CHMessageData, IOverlayDialog, IRefreshUUIForEntity } from '@wk/elm-uui-context-handler';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { IAppResources, NotificationType } from '../../../reducers/types';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../store';
import {
    closeOverlayDialog,
    dismissNotification,
    getAppResources,
    openOverlayDialog,
    refreshUuiForEntity,
    removeNotification,
    saveLogLevel,
    setFailedUploadFlyoutOpen,
    showNotification,
} from '../../../store/slices';
import { updateBatchUploadFlyoutCount } from '../../../utils/activitiesService';
import { clearLocationDataForOC } from '../../../utils/ocLocationService';
import { getAuthenticationProvider } from '../../authentication/AuthenticationProviderService';
import { clSubscribeMessagingService, isOC } from '../../contextLayerService/contextLayerService';
import { getILogLevelFromKey } from '../../helpSection/helpScreenHelpers';

export const LOGGING_OUT = 'LOGGING_OUT';

// eslint-disable-next-line max-lines-per-function
const messageHandler = (data: CHMessageData, dispatch: AppDispatch, appResources: IAppResources): void => {
    switch (data.type) {
        case 'Logout': {
            clearLocationDataForOC();
            sessionStorage.setItem(LOGGING_OUT, 'true');
            const authProvider = getAuthenticationProvider();
            authProvider.signOut(appResources?.logoutUrl);
            break;
        }
        case 'ShowToast': {
            const payload: { toastType: string; key: string; message: string } = JSON.parse(data.message);

            dispatch(
                showNotification({
                    notification: {
                        key: payload.key,
                        message: payload.message,
                        options: {
                            variant: (['default', 'information'].includes(payload.toastType)
                                ? 'info'
                                : payload.toastType) as NotificationType,
                            persist: payload.toastType.includes('success') ? false : true,
                        },
                    },
                }),
            );

            break;
        }
        case 'RemoveToast': {
            dispatch(removeNotification({ key: data.message }));
            break;
        }
        case 'DismissToast': {
            dispatch(dismissNotification({ key: data.message }));
            break;
        }
        case 'RefreshUUIForEntity': {
            const refreshUUIForEntity: IRefreshUUIForEntity = JSON.parse(data.message);
            getLogger('RefreshUUIForEntity').info(
                'Refresh UUI For Entity Requested: ' + format(new Date(), 'hh:mm:ss.SS a'),
            );
            dispatch(refreshUuiForEntity({ refreshUUIForEntity }));
            break;
        }
        case 'SetLoggingLevel': {
            const updatedLoglevel = data.message as unknown as LogLevel;
            initializeLoggerFactory(updatedLoglevel);
            dispatch(saveLogLevel({ updateLogLevel: getILogLevelFromKey(appResources, updatedLoglevel) }));
            break;
        }
        case 'FailedUploadFlyoutOpen': {
            const isOpen = data.message.toLowerCase() === 'true';
            dispatch(setFailedUploadFlyoutOpen({ isOpen }));
            break;
        }
        case 'OpenOverlayDialog': {
            const payload: IOverlayDialog = JSON.parse(data.message);
            dispatch(
                openOverlayDialog({
                    dialog: {
                        heading: payload.heading,
                        message: payload.message,
                        icon: payload.icon,
                        showSpinner: payload.showSpinner,
                    },
                }),
            );
            break;
        }
        case 'CloseOverlayDialog': {
            dispatch(closeOverlayDialog());
            break;
        }
        case 'FlyoutComplete': {
            updateBatchUploadFlyoutCount(data.message, dispatch);
            break;
        }
        case 'ReloadUUI': {
            location.reload();
        }
    }
};

/**
 * Hook to setup subscriptions for the MessageBus.
 */
export const useMessageBus = (): void => {
    const appResources = useAppSelector(getAppResources);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isOC() || !appResources) {
            return;
        }

        const messageBusSubscription = clSubscribeMessagingService((data) => {
            messageHandler(data, dispatch, appResources);
        });

        return () => {
            messageBusSubscription?.unsubscribe();
        };
    }, [appResources, dispatch]);
};
