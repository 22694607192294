import { CircularProgress, Dialog, DialogContent, Typography } from '@mui/material';
import { CHMessagingScope, OverlayDialogButtonAction } from '@wk/elm-uui-context-handler';
import React, { useState } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useUUIHistory } from '../../hooks';
import { useHomePageUrl } from '../../hooks/useHomePageUrl';
import { useAppDispatch } from '../../store';
import { closeOverlayDialog, OverlayDialogState } from '../../store/slices';
import { hardNavigateToHomePage } from '../../utils/utilities';
import { clNotifyAutoUpdateService } from '../contextLayerService/contextLayerService';
import { messageBusDispatch } from '../contextLayerService/messageBusService';
import IconMapper from './iconMapper';
import css from './overlayDialog.module.scss';
import UUIButton from './uuiButton';

type OverlayDialogProps = OverlayDialogState['entity'] & Partial<FallbackProps>;

export const OverlayDialog: React.FC<OverlayDialogProps> = ({
    heading,
    icon,
    message,
    button,
    showSpinner,
    resetErrorBoundary,
}) => {
    const [isOpen, setIsOpen] = useState(true);
    const homePageUrl = useHomePageUrl();
    const history = useUUIHistory();
    const dispatch = useAppDispatch();

    const installUpdate = (): void => {
        clNotifyAutoUpdateService();
        dispatch(closeOverlayDialog());
    };

    const navigateToHome = (): void => {
        if (resetErrorBoundary) {
            resetErrorBoundary();
        }
        hardNavigateToHomePage(homePageUrl, history);
    };

    const reload = (): void => {
        messageBusDispatch({
            type: 'ReloadUUI',
            scope: CHMessagingScope.OtherInstances,
            message: '',
        });
        location.reload();
    };

    const actions = {
        [OverlayDialogButtonAction.Reload]: reload,
        [OverlayDialogButtonAction.InstallUpdate]: installUpdate,
        [OverlayDialogButtonAction.NavigateToHome]: navigateToHome,
    };

    return (
        <Dialog
            fullScreen
            disableEnforceFocus
            className={css.dialogRoot}
            data-testid={'fullScreenDialog'}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            PaperProps={{ 'aria-label': 'Full Screen Overlay Dialog' }}
            classes={{ paper: css.dialogBody }}
            hideBackdrop={true}>
            <DialogContent className={css.dialogContent}>
                <Typography variant="h5" className={css.heading}>
                    {heading}
                </Typography>
                <div className={css.iconWrapper}>
                    <IconMapper iconName={icon} />
                </div>
                {showSpinner && (
                    <div className={css.spinner}>
                        <CircularProgress aria-label="progressBar" size={30} thickness={5.0} color={'secondary'} />
                    </div>
                )}
                {message.map((msg, index) => (
                    <Typography key={index} variant="body1" className={css.messageBody} data-testid="overlay-message">
                        {msg}
                    </Typography>
                ))}
                {button && (
                    <div className={css.buttonWrapper}>
                        <UUIButton
                            data-testid={'customOverlayButton'}
                            variant="outlined"
                            color="primary"
                            onClick={actions[button.action]}>
                            {button.text}
                        </UUIButton>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};
