import { Checkbox } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { getValidationMessagesForField } from '../itemScreenHelpers';
import { ControlTypeProps } from '../types';
import css from './checkBoxControl.module.scss';
import { ControlLabel } from './controlLabel';
import ReadOnlyControl from './readOnlyControl';

const CheckBoxControl: React.FC<ControlTypeProps> = ({ field, fieldData, readOnly }) => {
    const { register, errors } = useFormContext();

    if (readOnly) {
        return <ReadOnlyControl field={field} fieldData={fieldData} />;
    }

    const getCheckboxClassNames = (): string | undefined => {
        if (getValidationMessagesForField(field, errors, 'errors').length > 0) {
            return css.error;
        }
        if (getValidationMessagesForField(field, errors, 'warnings').length > 0) {
            return css.warning;
        }

        return;
    };

    return (
        <ControlLabel
            field={field}
            control={
                <div className={css.checkBox}>
                    <Checkbox
                        inputRef={register}
                        className={getCheckboxClassNames()}
                        id={field.name}
                        name={field.name}
                        data-testid={`checkbox-${field.name}`}
                        size="small"
                        color="primary"
                        defaultChecked={fieldData.inputValue === 'true'}
                        inputProps={{ 'aria-label': field.name }}
                    />
                </div>
            }></ControlLabel>
    );
};

export default CheckBoxControl;
