import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const wkQueueSuccessIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkQueueSuccessIcon" viewBox="0 0 20 20" {...props}>
        <g>
            <g id="Group_6810" transform="translate(-1247 -18)">
                <path id="Path_4638" d="M1261.1,23.8l-5.9,6l-2.3-2.5l-1.3,1.2l3.6,3.9l7.1-7.3L1261.1,23.8z" />
                <path
                    id="Color_2_"
                    d="M1257,38c-5.5,0-10-4.4-10-9.9h1.5c0,4.6,3.8,8.3,8.4,8.4h0.1c2.8,0,5.5-1.4,7.1-3.8h-4v-1.5
			h6.2v6.1h-1.5v-2.9c-0.9,1.1-2.1,2-3.4,2.7C1260,37.7,1258.5,37.9,1257,38z M1267,28.1h-1.5c0-4.6-3.8-8.3-8.4-8.4h-0.1
			c-2.8,0-5.5,1.4-7.1,3.8h4V25h-6.2v-6.1h1.5v2.9c0.9-1.1,2.1-2,3.4-2.7s2.8-1,4.3-1C1262.4,18.2,1266.9,22.6,1267,28.1z"
                />
            </g>
            <rect x="0" y="0" fill="none" width="20" height="20" />
        </g>
    </SvgIcon>
);

export default wkQueueSuccessIcon;
