import { useAppDispatch, useAppSelector } from '../../../store';
import {
    FavoriteEntity,
    FavoriteFolderEntity,
    FavoriteType,
    RegularFavoriteEntity,
    selectFavoritesTree,
    setFavorites,
    ShortcutFavoriteEntity,
} from '../../../store/slices';
import { apiFetch, HttpMethod } from '../../../utils/fetchUtils';
import {
    addFavoriteOrFolder,
    moveFavoriteOrFolder,
    removeFavoriteOrFolder,
    updateFavoriteOrFolder,
} from '../favoritesTree.utils';

type BookmarkItem = {
    id: number;
    name: string;
    parentId: number;
    type: FavoriteType.Favorite;
    url: string;
};

type FolderItem = {
    id: number;
    name: string;
    parentId: number;
    type: FavoriteType.Folder;
};

type AddFolderData = Omit<Required<FavoriteFolderEntity>, 'id' | 'children'>;
type AddFavoriteData = Omit<RegularFavoriteEntity, 'id'>;
type AddShortcutData = ShortcutFavoriteEntity;

// eslint-disable-next-line max-lines-per-function
export const useFavorites = () => {
    const tree = useAppSelector(selectFavoritesTree());
    const dispatch = useAppDispatch();
    const { apiContextRoot, apiContextPath } = window.Props;
    const bookmarksBaseUrl = `${String(apiContextRoot)}${String(apiContextPath)}/bookmarks`;
    const shortcutsBaseUrl = `${String(apiContextRoot)}${String(apiContextPath)}/shortcuts`;

    const createFavoriteFolder = async (data: AddFolderData): Promise<FavoriteFolderEntity> => {
        const folder = await apiFetch<FolderItem>(`${bookmarksBaseUrl}/folders/${data.parentId}`, data, {
            method: HttpMethod.Put,
        });
        const entity: FavoriteFolderEntity = { ...folder, children: [] };
        addFavoriteToTree(entity);
        return entity;
    };

    const createRegularFavorite = async (data: AddFavoriteData): Promise<RegularFavoriteEntity> => {
        const bookmark = await apiFetch<BookmarkItem>(`${bookmarksBaseUrl}/folders/${data.parentId}/favorite`, data, {
            method: HttpMethod.Put,
        });
        const entity: RegularFavoriteEntity = { ...bookmark };
        addFavoriteToTree(entity);
        return entity;
    };

    const createShortcut = async (data: AddShortcutData): Promise<ShortcutFavoriteEntity> => {
        await apiFetch<void>(shortcutsBaseUrl, { shortcutName: data.id }, { method: HttpMethod.Post });
        const entity: ShortcutFavoriteEntity = { ...data };
        addFavoriteToTree(entity);
        return entity;
    };

    const addFavoriteToTree = (entity: FavoriteEntity): void => {
        // TODO: fix types or handle this case in utils
        if (!tree) {
            throw Error('Unexpected application state: no favorites available.');
        }
        const newTree = addFavoriteOrFolder(tree, entity);
        dispatch(setFavorites({ rootFavorite: newTree }));
    };

    const renameFavoriteFolder = async (data: FavoriteFolderEntity, newName: string): Promise<FavoriteFolderEntity> => {
        const { id, type } = data;
        const folder = await apiFetch<FolderItem>(
            `${bookmarksBaseUrl}/folders/${id}`,
            { id, name: newName, type },
            { method: HttpMethod.Put },
        );
        const entity: FavoriteFolderEntity = { ...data, name: folder.name };
        updateFavoriteInTree(entity);
        return entity;
    };

    const renameRegularFavorite = async (
        data: RegularFavoriteEntity,
        newName: string,
    ): Promise<RegularFavoriteEntity> => {
        const { id, parentId, type } = data;
        const bookmark = await apiFetch<BookmarkItem>(
            `${bookmarksBaseUrl}/folders/${parentId}/favorite`,
            { id, name: newName, parentId, type },
            { method: HttpMethod.Put },
        );
        const entity: RegularFavoriteEntity = { ...data, name: bookmark.name };
        updateFavoriteInTree(entity);
        return entity;
    };

    const renameFavorite = (data: FavoriteEntity, newName: string): Promise<FavoriteEntity> => {
        if (data.type === FavoriteType.Favorite) {
            return renameRegularFavorite(data, newName);
        }
        if (data.type === FavoriteType.Folder) {
            return renameFavoriteFolder(data, newName);
        }
        throw new Error(`Rename of ${data.type} is not supported`);
    };

    const updateFavoriteInTree = (entity: FavoriteEntity): void => {
        // TODO: fix types or handle this case in utils
        if (!tree) {
            throw Error('Unexpected application state: no favorites available.');
        }
        const newTree = updateFavoriteOrFolder(tree, entity);
        dispatch(setFavorites({ rootFavorite: newTree }));
    };

    const deleteFavoriteFolder = async (data: FavoriteFolderEntity): Promise<void> => {
        const { id, type } = data;
        await apiFetch<void>(`${bookmarksBaseUrl}/folders/${id}`, { id, type }, { method: HttpMethod.Delete });
        removeFavoriteFromTree(data);
    };

    const deleteRegularFavorite = async (data: RegularFavoriteEntity): Promise<void> => {
        const { id, parentId, type } = data;
        await apiFetch<void>(
            `${bookmarksBaseUrl}/folders/${parentId}/favorite/${id}`,
            { id, parentId, type },
            {
                method: HttpMethod.Delete,
            },
        );
        removeFavoriteFromTree(data);
    };

    const deleteShortcut = async (data: ShortcutFavoriteEntity): Promise<void> => {
        const { id } = data;
        await apiFetch<void>(`${shortcutsBaseUrl}/${id}`, undefined, {
            method: HttpMethod.Delete,
        });
        removeFavoriteFromTree(data);
    };

    const deleteFavorite = (entity: FavoriteEntity): Promise<void> => {
        switch (entity.type) {
            case FavoriteType.Shortcut:
                return deleteShortcut(entity);
            case FavoriteType.Folder:
                return deleteFavoriteFolder(entity);
            case FavoriteType.Favorite:
                return deleteRegularFavorite(entity);
            default:
                throw new Error('Unsupported entity type during delete favorite operation.');
        }
    };

    const removeFavoriteFromTree = (entity: FavoriteEntity): void => {
        // TODO: fix types or handle this case in utils
        if (!tree) {
            throw Error('Unexpected application state: no favorites available.');
        }
        const newTree = removeFavoriteOrFolder(tree, entity);
        dispatch(setFavorites({ rootFavorite: newTree }));
    };

    const moveFavorite = async (entity: FavoriteEntity, targetFolderId: number): Promise<FavoriteEntity> => {
        const { id, type, parentId, name } = entity;
        const item = await apiFetch<BookmarkItem | FolderItem>(
            `${bookmarksBaseUrl}/folders/${targetFolderId}`,
            { id, name, type, parentId },
            { method: HttpMethod.Put },
        );

        const newEntity: FavoriteEntity = { ...entity, parentId: item.parentId };
        moveFavoriteWithinTree(newEntity, entity);
        return entity;
    };

    const moveFavoriteWithinTree = (newEntity: FavoriteEntity, oldEntity: FavoriteEntity): void => {
        // TODO: fix types or handle this case in utils
        if (!tree) {
            throw Error('Unexpected application state: no favorites available.');
        }
        const newTree = moveFavoriteOrFolder(tree, newEntity, oldEntity);
        dispatch(setFavorites({ rootFavorite: newTree }));
    };

    return {
        createRegularFavorite,
        createFavoriteFolder,
        createShortcut,
        renameFavorite,
        deleteFavorite,
        moveFavorite,
    };
};
