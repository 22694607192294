import { HelpOutlineOutlined, VpnLock, Search, StarOutline, AccountCircleOutlined, ChatBubbleOutlineOutlined } from '@mui/icons-material';
import { AppBar, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { VFC, ReactElement } from 'react';
import { useNetwork } from '../../../hooks';
import { useHomePageUrl } from '../../../hooks/useHomePageUrl';
import { useAppSelector } from '../../../store';
import { getAppResources, selectPersistState } from '../../../store/slices';
import { AppTheme } from '../../app';
import { FavoritesBar } from '../../favorites';
import HistoryScreen from '../../historyScreen/historyScreen';
import UploadQueueView from '../../historyScreen/uploadQueueView';
import UserScreen from '../../userScreen/userScreen';
import { Brand } from './brand';
import { HelpLauncher } from './helpLauncher';
import { HelpMenu } from './helpMenu';
import { InjectedLiveChat } from './injectedLiveChat';
import { Item } from './item';
import { ItemWithMenu } from './itemWithMenu';
import { ItemWithPopover } from './itemWithPopover';
import { MoreVertWrapper } from './moreVertWrapper';
import { NetworkLabel } from './networkLabel/networkLabel.component';
import { NetworkSwitchMenu } from './networkSwitchMenu';
import { QuickSearch } from './quickSearch';
import { UserProfileMenu } from './userProfileMenu';
import { ChatLauncher } from './ChatLauncher/chatLauncher.component';

const useStyles = makeStyles<AppTheme>((theme) => ({
    appBarRoot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 56,
        padding: theme.spacing(0, 2),
        background: theme.palette.view.dark,
    },
    toolbar: {
        minHeight: '100%',
        padding: 0,
        gap: theme.spacing(2),
    },
    moreIcon: {
        width: 4,
        height: 16,
    },
    profileName: {
        display: 'none',

        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
}));

export const UuiAppBar: VFC = () => {
    const classes = useStyles();
    const appResources = useAppSelector(getAppResources);
    const liveChatEnabled = useAppSelector((state) => state.app.hybridSettings.isLiveChatEnabled);
    const userScreenDialogKey = useAppSelector((state) => state.ui.userScreen.userScreenDialogKey);
    const salesforceChatURL = useAppSelector((state) => state.app.hybridSettings.salesforceChatURL);
    const networkLabel = useAppSelector((state) => state.app.networkLabel);
    const favoritesPinned = useAppSelector(selectPersistState('favoritesBarPinned'));
    const homePageUrl = useHomePageUrl();
    const { currentNetwork } = useNetwork();

    return (
        <div id={'uuiappbar'} data-testid="uuiappbar">
            <AppBar className={classes.appBarRoot} position="static">
                <Brand name={appResources.appName} to={homePageUrl} title="Go to Home" />
                {networkLabel && <NetworkLabel networkLabel={networkLabel} />}
                <Toolbar
                    classes={{
                        root: classes.toolbar,
                    }}>
                    {!favoritesPinned && (
                        <ItemWithPopover
                            icon={StarOutline}
                            name="Favorites"
                            iconTitle="Favorite"
                            popoverProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                },
                            }}
                            popoverContent={({ onClose }): ReactElement => <FavoritesBar onClose={onClose} />}
                        />
                    )}
                    <ItemWithPopover
                        icon={Search}
                        iconTitle="Search"
                        name="Search"
                        popoverContent={(props): ReactElement => <QuickSearch {...props} />}
                    />
                    {liveChatEnabled && (
                        <>
                            {salesforceChatURL ? (
                                <ChatLauncher nestedItem={({ onLaunch }): ReactElement => (
                                    <Item icon={ChatBubbleOutlineOutlined} iconTitle="Live Chat" name="Live Chat" onClick={onLaunch} />
                                )} Url={salesforceChatURL} />
                            ) : (
                                <InjectedLiveChat nestedItem={(props): ReactElement => <Item {...props} name="Live Chat" />} />
                            )}
                        </>
                    )}
                    <HelpLauncher
                        nestedItem={({ onLaunch }): ReactElement => (
                            <Item icon={HelpOutlineOutlined} iconTitle="Help" name="Help" onClick={onLaunch} />
                        )}
                    />
                    <ItemWithMenu
                        icon={VpnLock}
                        iconTitle="Network"
                        arrowTitle="View Networks"
                        name={currentNetwork?.networkName || ''}
                        component={NetworkSwitchMenu}
                        arrowed
                        itemTestId="network-switch-select"
                    />
                    <ItemWithMenu
                        icon={AccountCircleOutlined}
                        iconTitle="Profile"
                        arrowTitle="View Profile Menu"
                        name={`${appResources.v3FirstName} ${appResources.v3LastName}`}
                        component={UserProfileMenu}
                        arrowed
                        itemTestId="myProfileOptionsButton"
                        itemIconTestId="userProfileIcon"
                        classes={{ name: classes.profileName }}
                    />

                    {window.Props.showUploadQueue && <UploadQueueView />}

                    <ItemWithMenu
                        iconTitle="More Options"
                        icon={MoreVertWrapper}
                        component={HelpMenu}
                        itemTestId="icon-help-menu"
                    />
                </Toolbar>
            </AppBar>

            <UserScreen key={userScreenDialogKey} />
            <HistoryScreen />
        </div>
    );
};
