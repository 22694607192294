import { Grid } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import { useAppSelector } from '../../store';
import { getAppResources } from '../../store/slices';
import { UUIFlyoutMenuItem, UUIFlyoutSelectMenu } from '../common/uuiFlyoutMenu';
import { useListScreenSavedViews } from './context/listScreenHooks';
import css from './listScreenSearchAndSavedViewBar.module.scss';
import { QuickSearch } from './quickSearch';

export const ListScreenSearchAndSavedViewBar: React.FC = () => {
    const { firstSavedViewValue, orderedSavedViews, selectedSavedViewId, selectedSavedViewName, loadSavedView } =
        useListScreenSavedViews();
    const appResources = useAppSelector(getAppResources);
    const viewName =
        selectedSavedViewId === firstSavedViewValue
            ? appResources.listscreenDefaultDropdownText
            : selectedSavedViewName;

    return (
        <div className={css.container}>
            <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item xs={12} className={css.searchViewRow}>
                    <Grid item xs={8} className={css.quickSearchWrapper}>
                        <QuickSearch />
                    </Grid>

                    <Grid item xs={4} className={css.savedViewsDropdown}>
                        {orderedSavedViews.length > 0 && (
                            <div className={css.savedViewsDropdownContainer}>
                                <span className={css.viewLabel} data-testid="viewLabel">
                                    {appResources.viewLabel}
                                </span>
                                <UUIFlyoutSelectMenu
                                    value={selectedSavedViewId}
                                    name={viewName}
                                    onChange={(e) => loadSavedView(e as SyntheticEvent, e.target.value as number)}
                                    className={css.savedViewsDropdownControl}>
                                    <UUIFlyoutMenuItem
                                        value={firstSavedViewValue}
                                        data-testid={'kabob-saved-view'}
                                        tooltipText={appResources.listscreenDefaultDropdownText}>
                                        {appResources.listscreenDefaultDropdownText}
                                    </UUIFlyoutMenuItem>
                                    {orderedSavedViews.map((sv) => (
                                        <UUIFlyoutMenuItem
                                            key={sv.id}
                                            value={sv.id}
                                            isWordWrapped
                                            data-testid={'kabob-saved-view'}
                                            tooltipText={sv.name}>
                                            {sv.name}
                                        </UUIFlyoutMenuItem>
                                    ))}
                                </UUIFlyoutSelectMenu>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
