import { Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { VFC } from 'react';
import { useNavigate } from '../../../hooks';
import { useMediaQueryExecute } from '../../../hooks/useMediaQueryExecute';
import { IMenuItem } from '../../../reducers/types';
import { useAppSelector } from '../../../store';
import { AppTheme } from '../../app';

const useStyles = makeStyles<AppTheme>((theme) => ({
    holder: {
        display: 'flex',
        justifyContent: 'end',
        flexWrap: 'wrap',
        backgroundColor: theme.palette.background.secondary,
        padding: theme.spacing(1, 2),

        '&>*:not(:first-child)': {
            marginLeft: theme.spacing(3),
        },
    },
    textHolder: {
        overflow: 'hidden',
        textAlign: 'end',
    },
    linkHolder: {
        display: 'flex',
        whiteSpace: 'nowrap',
    },
}));

type PageFooterProps = {
    classes?: {
        root?: string;
    };
};

const currentYear = new Date().getFullYear();
const allRightsReserved = 'All rights reserved.';
const copyrightLong = `© ${currentYear} Wolters Kluwer ELM Solutions, Inc.`;
const copyrightShort = `© ${currentYear} WK-ELM Solutions`;

export const PageFooter: VFC<PageFooterProps> = (props) => {
    const { classes: externalClasses } = props;
    const classes = useStyles();
    const { navigate } = useNavigate();
    const {
        screenType,
        screenId,
        url,
        id,
        displayValue = 'Terms of Use',
    } = useAppSelector((state) => state.app.hybridSettings.termsOfUse) || {};

    const copyRightText = useMediaQueryExecute<string>(
        {
            default: () => copyrightShort,
            sm: () => copyrightLong,
            md: () => `${copyrightLong} ${allRightsReserved}`,
        },
        [copyrightShort, copyrightLong, allRightsReserved],
    );

    const linkClickHandler = (): void => {
        if (!url) {
            return;
        }

        navigate({ menuItem: { screenType, screenId, url, id } as IMenuItem });
    };

    return (
        <div className={classNames(classes.holder, externalClasses?.root)}>
            <Typography className={classes.textHolder} data-testid="copyRightText">
                {copyRightText}
            </Typography>
            <Link
                component="button"
                variant="body1"
                className={classes.linkHolder}
                onClick={linkClickHandler}
                data-testid="termsOfUseLink">
                {displayValue}
            </Link>
        </div>
    );
};
