import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';

export const WkShortcutRemove = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon data-testid="wkShortcutRemoveIcon" viewBox="-2 -2 21 21" {...props} ref={ref}>
        <circle cx="11" cy="11" r="4.5" stroke="currentColor" fill="none" />
        <path
            d="M7.56946 16.008C6.51615 15.9559 5.52691 15.7076 4.60576 15.2671C3.68461 14.8265 2.8836 14.2498 2.20275 13.5329C1.5219 12.82 0.985232 11.987 0.588736 11.0378C0.196245 10.0886 0 9.08335 0 8.01402C0 6.94468 0.212265 5.86333 0.628786 4.89011C1.04931 3.9169 1.62203 3.06783 2.34293 2.34693C3.06383 1.62603 3.91289 1.05732 4.88611 0.632791C5.85932 0.212265 6.90063 0 8.01001 0C10.0005 0 11.7267 0.628786 13.1965 1.88235C14.6663 3.13592 15.5675 4.71389 15.8999 6.60826H14.6783C14.4501 5.48686 13.9775 4.48561 13.2566 3.60451C12.5357 2.7234 11.6345 2.06258 10.5532 1.62203V1.98248C10.5532 2.45106 10.393 2.85557 10.0726 3.20401C9.75219 3.55244 9.3597 3.72466 8.89111 3.72466H7.14894V5.46683C7.14894 5.69512 7.05682 5.87935 6.8806 6.02753C6.70038 6.17572 6.49612 6.24781 6.27184 6.24781H4.60976V8.01001H5.47084V10.5131L1.38173 6.43604C1.31364 6.70438 1.26959 6.96871 1.24155 7.22904C1.21352 7.48936 1.2015 7.75369 1.2015 8.02203C1.2015 9.82428 1.81026 11.3782 3.02378 12.6879C4.2373 13.9975 5.75519 14.7024 7.56946 14.8105V16.012V16.008Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5332 10.7333H14.4665V11.2666H7.5332V10.7333Z"
            fill="currentColor"
        />
    </SvgIcon>
));

WkShortcutRemove.displayName = 'WkShortcutRemove';
