import { getLogger } from '@wk/elm-uui-common';
import { isError, isNil } from 'lodash';
import { Page, Report, VisualDescriptor } from 'powerbi-client';
import { MESSAGES } from '../common/constants';

const logger = getLogger('BaseChartStrategy');

export class BaseChartStrategy {
    protected report?: Report;
    protected page?: Page;
    protected visuals: VisualDescriptor[] = [];
    protected chart?: VisualDescriptor;

    /**
     * Initializes and validates the provided Power BI report.
     *
     * @param report - The Power BI report object.
     */
    protected async initialize(report: Report): Promise<void> {
        try {
            this.report = report;
            this.page = await this.report.getActivePage();
            this.validateEntity(this.page, MESSAGES.NO_ACTIVE_PAGE);

            this.visuals = await this.page.getVisuals();
            this.validateEntity(this.page, MESSAGES.NO_VISUALS);
        } catch (error) {
            if (isError(error)) {
                logger.error('Initialization Error:', error);
            }
            throw error;
        }
    }

    public async resizeFilterLines(width: number): Promise<void> {
        await this.resizeVisualsOfType('shape', 'Horizontal Line', width, 0); 
    }
    
    public async resizeFilterPaneLayout(height: number): Promise<void> {
        await this.resizeVisualsOfType('shape', 'Filter Pane Layout', 0, height); 
    }
    
    public async resizeHiddenCloseFilterButton(width: number, height: number): Promise<void> {
        await this.resizeVisualsOfType('actionButton', 'Transparent Button', width, height);
    }

    public async resizeVisualsOfType(type: string, keyword: string, width: number, height: number): Promise<void> {
        try {
            const matchingVisual = this.visuals.filter(
                (visual) => visual.type === type && visual.title.includes(keyword),
            );

            if (matchingVisual.length === 0) {
                logger.warn(`No matching ${type} found to resize.`);
                return;
            }

            await Promise.all(
                matchingVisual.map(async (visual) => {
                    width = width === 0 ? visual.layout.width || 0 : width;
                    height = height === 0 ? visual.layout.height || 0 : height;
                    if (!isNil(width) && !isNil(height)) {
                        await visual.resizeVisual(width, height);
                    } else {
                        logger.warn(`Visual ${visual.name} does not have defined dimensions. Resize skipped.`);
                    }
                }),
            );
        } catch (error) {
            if (isError(error)) {
                logger.error(`Error resizing ${type}:`, error);
            }
            throw error;
        }
    }

    /**
     * Validate an entity and throw an error if it's not valid.
     *
     * @param entity - The entity to validate.
     * @param errorMessage - The error message to use if the entity is not valid.
     */
    private validateEntity(entity: Page | VisualDescriptor[], errorMessage: string): void {
        if (!entity) {
            logger.error(errorMessage);
            throw new Error(errorMessage);
        }
    }
}
