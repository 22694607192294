import { Grid, Link } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { IQueueItem } from '@wk/elm-uui-context-handler';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useWindowWidth from '../../hooks/useWindowWidth';
import { IActivityRow } from '../../reducers/types';
import { useAppSelector } from '../../store';
import { getAppResources } from '../../store/slices';
import { UUITooltip } from '../common/uuiTooltip';
import WkAppFileIcon from '../icons/wkAppFileIcon';
import WkErrorIcon from '../icons/wkErrorIcon';
import { useResponsiveBreadCrumbs } from '../listScreen/context/listScreenHooks';
import css from './activityItemRow.module.scss';

interface IActivityItemRowProps {
    index: number;
    item: IQueueItem;
    itemData: IActivityRow;
    onClick?: () => void;
}

const ActivityItemRow: React.FC<IActivityItemRowProps> = ({ index, item, itemData, onClick }) => {
    const bcContainerRef = useRef<HTMLDivElement>(null);
    const fullBreadCrumbString = item.location.join(' > ');
    const responsiveBreadCrumbString = useActivityPaneResponsiveBreadcrumbs(item.location, bcContainerRef);

    const appResources = useAppSelector(getAppResources);
    const dateTimeFormat: string = appResources.displayedDateTimeFormat;
    const getDateFormatted = (timeStamp: string) => {
        return format(new Date(timeStamp), dateTimeFormat);
    };

    return (
        <Grid item xs={12} container data-testid={'itemRow-' + index + '-' + item.status}>
            <Grid
                item
                container
                alignItems="center"
                xs={12}
                data-testid={item.status + '-' + item.elementType + '-nameField'}
                className={css.docInfoName}>
                <div data-testid={'progressLoaderContainer-' + item.status} className={css.progressLoaderContainer}>
                    {(item.status === 'STARTED' || item.status === 'PENDING') && (
                        <CircularProgress
                            thickness={7}
                            size={16}
                            color="secondary"
                            data-testid="circularProgressIcon"
                        />
                    )}
                </div>
                <div className={css.docInprogressTooltip}>
                    <div className={css.documentName} data-testid={'history-document-name' + index}>
                        <WkAppFileIcon className={css.wkIcons} viewBox="0 0 16 16" fileName={itemData.documentName!} />
                        <UUITooltip
                            title={itemData.title}
                            placement="bottom-start"
                            data-testid="historyPane-DocumentName-tooltip">
                            <div className={css.documentNameSpan} data-testid={'history-document-name ' + item.status}>
                                {itemData.title}
                            </div>
                        </UUITooltip>
                    </div>
                </div>
                {(item.status === 'FAILED' || item.status === 'NEW') && (
                    <div className={css.actionLinkContainer}>
                        <Link
                            onClick={() => (onClick ? onClick() : null)}
                            data-testid={item.status === 'FAILED' ? 'historyItemClear' : 'historyItemCancel'}>
                            {item.status === 'FAILED' ? appResources.historyItemClear : appResources.buttonCancel}
                        </Link>
                    </div>
                )}
            </Grid>

            <Grid
                item
                container
                style={{ paddingRight: '8px' }}
                className={
                    item.status === 'STARTED' || item.status === 'PENDING' ? css.docInfo : css.docInfoNoProgress
                }>
                <Grid item container xs={12}>
                    <Grid
                        item
                        container
                        className={item.status === 'STARTED' || item.status === 'PENDING' ? css.docInfoNoProgress : ''}
                        data-testid={'itemActivityContainer-' + index + '-' + item.status}>
                        <Grid item container xs={12}>
                            <UUITooltip
                                title={
                                    itemData
                                        ? `${itemData.associatedEntityType} - ${itemData.associatedEntityName}`
                                        : ''
                                }
                                placement="bottom-start"
                                data-testid="historyPane-EntityContext-tooltip"
                                className={css.entityContextTooltip}>
                                <Grid
                                    item
                                    xs={12}
                                    data-testid={'history-matter-name' + index}
                                    className={css.rowContainer}>
                                    <span>
                                        {itemData
                                            ? `${itemData.associatedEntityType} - ${itemData.associatedEntityName}`
                                            : ''}
                                    </span>
                                </Grid>
                            </UUITooltip>
                        </Grid>
                        <Grid item container xs={12} justifyContent="space-between" className={css.breadCrumbWrapper}>
                            <div className={css.breadCrumbContainer} ref={bcContainerRef}>
                                <UUITooltip
                                    title={fullBreadCrumbString}
                                    placement="top"
                                    data-testid="historyPane-Breadcrumb-tooltip"
                                    alwaysShowTooltipOnHover={fullBreadCrumbString !== responsiveBreadCrumbString}>
                                    <div
                                        className={`${css.rowContainer} ${css.breadcrumb} ${
                                            item.status === 'NEW' ? css.responsiveBreadcrumb : undefined
                                        }`}>
                                        {responsiveBreadCrumbString}
                                    </div>
                                </UUITooltip>
                            </div>
                            {item.status === 'COMPLETED' && (
                                <div className={css.hoursDisplay} data-testid="dateTime">
                                    {getDateFormatted(item.created)}
                                </div>
                            )}
                        </Grid>
                        {item.status === 'FAILED' && (
                            <Grid item container xs={12}>
                                <UUITooltip title={item.error} data-testid="historyPane-errorMsg-tooltip">
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        className={css.rowContainer}
                                        justifyContent="space-between">
                                        <Grid
                                            item
                                            xs={12}
                                            className={css.errorMessage}
                                            data-testid={'activity-error-message-' + index}>
                                            <span>
                                                <WkErrorIcon className={css.errorIcon} />
                                            </span>
                                            <span className={css.errorMsgTxt}>{item.error}</span>
                                        </Grid>
                                    </Grid>
                                </UUITooltip>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const useActivityPaneResponsiveBreadcrumbs = (
    itemLocation: string[],
    bcContainerRef: React.RefObject<HTMLDivElement>,
) => {
    // itemLocation is a different object each render, so we have to do a deep object compare
    // easiest way to do this for a string array is to just JSON.stringify it
    const itemLocationString = JSON.stringify(itemLocation);
    const responsiveBreadCrumbs = useMemo(
        () =>
            (JSON.parse(itemLocationString) as string[]).map((breadcrumb) => ({
                folderName: breadcrumb,
            })),
        [itemLocationString],
    );
    const windowWidth = useWindowWidth();
    const [bcContainerWidth, setBcContainerWidth] = useState(windowWidth);
    // this effect watches for window resizes and recalculates the breadcrumb container width.
    // by using useWindowWidth, we get debouncing built in.
    useEffect(() => {
        if (bcContainerRef.current) {
            setBcContainerWidth(bcContainerRef.current.offsetWidth);
        }
    }, [windowWidth, bcContainerRef]);

    const breadcrumbs = useResponsiveBreadCrumbs(responsiveBreadCrumbs, {
        containerWidth: bcContainerWidth,
        fontSize: '12px',
    });

    return breadcrumbs.map((bc) => bc.folderName).join(' > ');
};

export default ActivityItemRow;
