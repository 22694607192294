import { Button, DialogContentText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { MouseEvent, useMemo, VFC } from 'react';
import { NotificationPlacement } from '../../../../reducers/types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { FavoriteEntity, FavoriteType, selectFavoritesTree, showNotification } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { BaseDialog, BaseDialogProps } from '../../../common';
import { getFavoriteOrFolder } from '../../favoritesTree.utils';
import { useFavorites } from '../../hooks';

const useStyles = makeStyles<AppTheme>(() => ({
    content: {},
    actions: {
        justifyContent: 'space-between',
    },
}));

export type RemoveFavoriteDialogProps = Omit<BaseDialogProps, 'title' | 'actions'> & {
    entityId: FavoriteEntity['id'];
};

export const RemoveFavoriteDialog: VFC<RemoveFavoriteDialogProps> = (props) => {
    const classes = useStyles();
    const { entityId, onClose, classes: externalClasses = {}, ...baseDialogProps } = props;
    const dispatch = useAppDispatch();
    const { deleteFavorite } = useFavorites();
    const tree = useAppSelector(selectFavoritesTree());
    const entity = useMemo(() => getFavoriteOrFolder(tree, entityId), [tree, entityId]);
    const title = `Delete ${entity?.type === FavoriteType.Folder ? 'Folder' : 'Favorite'}`;
    const getFormattedEntityName = (entity: FavoriteEntity, folderArticle = true): string =>
        entity.type === FavoriteType.Folder
            ? `${folderArticle ? 'the ' : ''}"${entity.name}" folder`
            : `"${entity.name}"`;
    const question =
        entity &&
        (entity.type === FavoriteType.Folder && entity.children?.length
            ? `"${entity.name}" is not empty. Are you sure you want to delete the folder and all its contents from Favorites?`
            : `Are you sure you want to delete ${getFormattedEntityName(entity)} from Favorites?`);

    const handleRemove = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.stopPropagation();

        if (!tree || !entity) {
            return;
        }

        try {
            await deleteFavorite(entity);

            dispatch(
                showNotification({
                    notification: {
                        message: `${getFormattedEntityName(entity, false)} has been deleted from Favorites.`,
                        options: {
                            variant: 'success',
                        },
                        placement: NotificationPlacement.Top,
                    },
                }),
            );
        } catch (error) {
            // ignored // FIXME: fetch wrappers do a lot of non-generic stuff, requires refactoring
        }

        onClose?.(event, 'onAction');
    };

    return (
        <BaseDialog
            {...baseDialogProps}
            classes={{
                ...externalClasses,
                actions: classNames(classes.actions, externalClasses.actions),
                content: classNames(classes.content, externalClasses.content),
            }}
            onClose={onClose}
            title={title}
            actions={
                <Button color="primary" variant="contained" onClick={handleRemove} disableRipple>
                    Delete
                </Button>
            }>
            <DialogContentText>{question}</DialogContentText>
        </BaseDialog>
    );
};
