import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOverlayDialog } from '@wk/elm-uui-context-handler';

type DialogEntity = IOverlayDialog;
export type OverlayDialogState = {
    entity?: DialogEntity;
};

const initialState: OverlayDialogState = {
    entity: undefined,
};

const overlayDialog = createSlice({
    name: 'overlay-dialog',
    initialState,
    reducers: {
        openOverlayDialog: (state, action: PayloadAction<{ dialog: DialogEntity }>) => {
            state.entity = action.payload.dialog;
        },
        closeOverlayDialog: (state) => {
            state.entity = undefined;
        },
    },
});

// Selector

// const selectDialog = (state: AppState): DialogEntity | undefined => state.overlayDialog.entity;

// Actions
export type OverlayDialogActions = TakeActions<typeof overlayDialog.actions>;
export const { openOverlayDialog, closeOverlayDialog } = overlayDialog.actions;
export const overlayDialogReducer = overlayDialog.reducer;
