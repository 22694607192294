import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkNewQuickFileIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkNewQuickFileIcon" viewBox="0 0 16 16" {...props}>
        <g>
            <path
                id="Color"
                style={{ fill: '#005B92' }}
                d="M16,16H0v-1.2h16V16z M8.6,13.5H7.4V2.4l-4.1,4L2.5,5.5L8,0l5.5,5.6l-0.8,0.8L8.6,2.4V13.5L8.6,13.5  z"
            />
        </g>
    </SvgIcon>
);

export default WkNewQuickFileIcon;
