import { AddCircleOutline, EditOutlined } from '@mui/icons-material';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useState, VFC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useNavigate } from '../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
    FavoriteEntity,
    FavoriteType,
    clearBreadCrumbState,
    getApplicationUrls,
    selectFavoritesTree,
    selectPageState,
    selectPersistState,
    setFavoritesBar,
} from '../../../store/slices';
import { AppRoutes, AppTheme } from '../../app';
import { BaseDialogCloseReason, Tooltip } from '../../common';
import { determineRedirectUrl } from '../../common/screenHelpers';
import { IBaseOperation, TargetScreenType } from '../../common/types';
import { WkPin, WkUnpin } from '../../icons';
import { AddToFavoritesDialog } from '../dialogs';
import { FavoritesMenu } from '../favoritesMenu';

const useStyles = makeStyles<AppTheme, { pinned?: boolean }>((theme) => ({
    root: ({ pinned }) => ({
        backgroundColor: pinned ? theme.palette.background.secondary : 'initial',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0.5, 1),
        boxSizing: 'border-box',
    }),
    favorites: {
        minWidth: 0,
    },
    actionsContainer: {
        display: 'flex',
        marginLeft: theme.spacing(1.5),
    },
    button: {
        minWidth: 'auto',
    },
    startIcon: {
        margin: theme.spacing(0),
    },
}));

export type FavoritesBarProps = {
    onClose?: () => void;
    floating?: boolean;
    classes?: {
        root?: string;
    };
};

// eslint-disable-next-line max-lines-per-function
export const FavoritesBar: VFC<FavoritesBarProps> = (props) => {
    const pinned = useAppSelector(selectPersistState('favoritesBarPinned'));
    const classes = useStyles({ pinned });
    const dispatch = useAppDispatch();
    const { classes: externalClasses = {}, floating, onClose } = props;
    const addBookmarkEnabled = useAppSelector(selectPageState('addBookmarkEnabled'));
    const applicationUrls = useAppSelector(getApplicationUrls);
    const [openDialog, setOpenDialog] = useState<boolean>();
    const visible = !floating || pinned;
    const { navigate } = useNavigate();
    const favoritesPageMatch = useRouteMatch(AppRoutes.Favorites);
    const tree = useAppSelector(selectFavoritesTree());
    const theme = useTheme();
    const showButtonText = useMediaQuery(theme.breakpoints.up('sm'));
    const disabledAddBookmarkTooltip =
        'This page can be added only through the submenu, and only if it is available as a menu item.';

    const handleFavoriteClick = (favorite: FavoriteEntity): void => {
        if (favorite.type === FavoriteType.Folder) {
            return;
        }

        dispatch(clearBreadCrumbState());

        if (favorite.type === FavoriteType.Shortcut) {
            navigate({ menuItem: favorite.data });
            onClose?.();
            return;
        }

        const encodedUrl = encodeURIComponent(favorite.url ?? '');
        const redirectUrl = determineRedirectUrl({
            operation: {
                targetScreenType: TargetScreenType.Legacy,
                targetScreenId: -1,
                targetScreenMode: 'Show',
                url: encodedUrl,
            } as IBaseOperation,
            applicationUrls,
        });

        if (redirectUrl) {
            navigate({ to: redirectUrl, options: { clearBreadcrumbs: true } });
            onClose?.();
        }
    };

    const handleEdit = (): void => {
        navigate({ to: AppRoutes.Favorites });
        onClose?.();
    };

    const handleAddToFavorites = (): void => {
        setOpenDialog(true);
    };

    const handleDialogClose = (reason: BaseDialogCloseReason): void => {
        setOpenDialog(false);
        if (reason === 'onAction') {
            onClose?.();
        }
    };

    return (
        <>
            {visible && (
                <div className={classNames(classes.root, externalClasses.root)}>
                    <FavoritesMenu
                        classes={{ root: classes.favorites }}
                        tree={tree}
                        onClick={(item) => handleFavoriteClick(item)}
                    />
                    <div className={classes.actionsContainer}>
                        <Tooltip
                            title={!addBookmarkEnabled ? disabledAddBookmarkTooltip : ''}
                            placement="bottom"
                            mode="description">
                            <span>
                                <Button
                                    classes={{
                                        root: classes.button,
                                        startIcon: showButtonText ? undefined : classes.startIcon,
                                    }}
                                    color="primary"
                                    startIcon={
                                        <Tooltip title="Add to Favorites" placement="bottom">
                                            <AddCircleOutline />
                                        </Tooltip>
                                    }
                                    onClick={handleAddToFavorites}
                                    disabled={!addBookmarkEnabled}
                                    disableRipple>
                                    {showButtonText && 'Add to Favorites'}
                                </Button>
                            </span>
                        </Tooltip>
                        <Button
                            classes={{
                                root: classes.button,
                                startIcon: showButtonText ? undefined : classes.startIcon,
                            }}
                            color="primary"
                            startIcon={
                                <Tooltip title={pinned ? 'Unpin' : 'Pin'} placement="bottom">
                                    {/* TODO: span is temp workaround to make tooltip to be shown*/}
                                    <span style={{ display: 'flex' }}>{pinned ? <WkUnpin /> : <WkPin />}</span>
                                </Tooltip>
                            }
                            onClick={(): void => {
                                dispatch(setFavoritesBar({ pinned: !pinned }));
                            }}
                            disableRipple>
                            {showButtonText && (pinned ? 'Unpin' : 'Pin')}
                        </Button>
                        <Button
                            classes={{
                                root: classes.button,
                                startIcon: showButtonText ? undefined : classes.startIcon,
                            }}
                            color="primary"
                            startIcon={
                                <Tooltip title="Edit" placement="bottom">
                                    <EditOutlined />
                                </Tooltip>
                            }
                            onClick={handleEdit}
                            disabled={favoritesPageMatch?.isExact}
                            disableRipple>
                            {showButtonText && 'Edit'}
                        </Button>
                    </div>

                    {openDialog && (
                        <AddToFavoritesDialog open={true} onClose={(_, reason): void => handleDialogClose(reason)} />
                    )}
                </div>
            )}
        </>
    );
};
