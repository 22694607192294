import { makeStyles } from '@mui/styles';
import { sanitize } from 'dompurify';
import React from 'react';
import { NotificationType } from '../../reducers/types';
import { useAppSelector } from '../../store';
import { getAppResources } from '../../store/slices';
import { AppTheme } from '../app';
import WkCircleCheckIcon from '../icons/wkCircleCheckIcon';
import WkCircleLetteriIcon from '../icons/wkCircleLetteriIcon';
import WkErrorIcon from '../icons/wkErrorIcon';
import WkWarningIcon from '../icons/wkWarningIcon';

// TODO: Change the below styles when we have updated UX wireframes

const useStyles = makeStyles<AppTheme>((theme) => ({
    icon: {
        fontSize: 21,
    },
    warningIcon: {
        fontSize: 21,
        color: theme.palette.warning.dark,
    },
    toastTitle: {
        fontWeight: 600,
        paddingLeft: theme.spacing(1.25),
        verticalAlign: 'middle',
    },
    toastTitleContainer: {
        ...theme.typography.body1,
        fontSize: theme.typography.fontSize,
        display: 'flex',
        alignItems: 'center',
        marginRight: '31px',
        '& > svg': {
            verticalAlign: 'middle',
        },
    },
    toastMessage: {
        ...theme.typography.body1,
        fontSize: theme.typography.fontSize,
        marginLeft: '31px',
        marginRight: '31px',
    },
}));

interface IToastMessage {
    type: NotificationType;
    message: string;
    classes?: {
        root?: string;
    };
}

export const ToastMessage: React.FC<IToastMessage> = ({ type, message, classes: externalClasses }) => {
    const appResources = useAppSelector(getAppResources);
    const classes = useStyles();
    let icon: React.ReactNode = null;
    let title: string | null = null;

    switch (type) {
        case 'success':
            icon = <WkCircleCheckIcon className={classes.icon} viewBox="-4 -3 22 22" />;
            title = appResources.userscreenSuccessToast;
            break;
        case 'info':
            icon = <WkCircleLetteriIcon className={classes.icon} viewBox="-4 -3 22 22" />;
            title = appResources.userscreenInfoToast;
            break;
        case 'warning':
            icon = <WkWarningIcon className={classes.warningIcon} viewBox="-4 -3 22 22" />;
            title = appResources.userscreenWarningToast;
            break;
        case 'error':
            icon = <WkErrorIcon className={classes.icon} viewBox="-4 -3 22 22" />;
            title = appResources.userscreenErrorToast;
            break;
    }

    // TODO: Remove casts when trusted types are fixed for __html
    //  https://github.com/DefinitelyTyped/DefinitelyTyped/pull/60691
    const safeMessage = sanitize(message, { RETURN_TRUSTED_TYPE: true }) as unknown as string;
    return (
        <div className={externalClasses?.root}>
            <div className={classes.toastTitleContainer}>
                {icon}
                <span className={classes.toastTitle}>{title}</span>
            </div>
            <div
                data-testid={`${title}Snackbar`}
                className={classes.toastMessage}
                dangerouslySetInnerHTML={{ __html: safeMessage }}
            />
        </div>
    );
};
