import { Button, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { isString } from 'lodash';
import React, { ChangeEventHandler, MouseEvent, useEffect, useMemo, useState, VFC } from 'react';
import { useQuery } from '../../../../hooks';
import { NotificationPlacement } from '../../../../reducers/types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { FavoriteType, selectFavoritesTree, selectPageState, showNotification } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { BaseDialog, BaseDialogProps } from '../../../common';
import { getFavoriteOrFolder } from '../../favoritesTree.utils';
import { FavoritesView } from '../../favoritesView';
import { useFavorites } from '../../hooks';

const useStyles = makeStyles<AppTheme>((theme) => ({
    paper: {
        maxWidth: 560,
        width: '100%',
    },
    content: {
        boxSizing: 'content-box',
    },
    favoritesView: {
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        minHeight: 200,
        maxHeight: 400,
        flex: 1,
        flexBasis: 0,
        width: '100%',
    },
    actions: {
        justifyContent: 'space-between',
    },
}));

type AddToFavoritesDialogProps = Omit<BaseDialogProps, 'title' | 'actions'>;

export const AddToFavoritesDialog: VFC<AddToFavoritesDialogProps> = (props) => {
    const classes = useStyles();
    const { onClose, classes: externalClasses = {}, ...baseDialogProps } = props;
    const dispatch = useAppDispatch();
    let { url } = useQuery<{ url: string }>({ isGlobal: true });
    const { createRegularFavorite } = useFavorites();
    const defaultName = useAppSelector(selectPageState('title'));
    const bookmarkPath = useAppSelector(selectPageState('bookmarkPath'));
    const bookmarkTitle = useAppSelector(selectPageState('bookmarkTitle'));
    const tree = useAppSelector(selectFavoritesTree());
    const [name, setName] = useState<string>();
    const [folderId, setFolderId] = useState<string>('');
    const folder = useMemo(() => getFavoriteOrFolder(tree, folderId), [tree, folderId]);
    const [expanded, setExpanded] = useState<string[]>();

    useEffect(() => {
        if (tree?.type === FavoriteType.Folder) {
            setFolderId(String(tree.id));
            setExpanded([String(tree.id)]);
        }
    }, [tree]);

    const onNodeToggle = (ids: string[]): void => {
        setExpanded(ids);
    };

    const handleChangeName: ChangeEventHandler<HTMLInputElement> = (event) => {
        setName(event.currentTarget.value);
    };

    const handleAddToFavorites = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        if (!tree || folder?.type !== FavoriteType.Folder || !name || !isString(url)) {
            return;
        }

        try {
            if(bookmarkPath){
                url = bookmarkPath;
            }
            const favorite = await createRegularFavorite({
                name,
                url,
                parentId: folder.id,
                type: FavoriteType.Favorite,
            });

            dispatch(
                showNotification({
                    notification: {
                        message: `"${favorite.name}" has been added to Favorites.`,
                        options: {
                            variant: 'success',
                        },
                        placement: NotificationPlacement.Top,
                    },
                }),
            );
        } catch (error) {
            // ignored // FIXME: fetch wrappers do a lot of non-generic stuff, requires refactoring
        }

        onClose?.(event, 'onAction');
    };

    useEffect(() => setName(bookmarkTitle?bookmarkTitle: defaultName), [defaultName]);

    return (
        <BaseDialog
            {...baseDialogProps}
            classes={{
                ...externalClasses,
                actions: classNames(classes.actions, externalClasses.actions),
                content: classNames(classes.content, externalClasses.content),
                paper: classNames(classes.paper, externalClasses.paper),
            }}
            onClose={onClose}
            title="Add to Favorites"
            actions={
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleAddToFavorites}
                    disabled={!folderId}
                    disableRipple>
                    Add
                </Button>
            }>
            <FormControl variant="outlined">
                <InputLabel htmlFor="component-favorites-view">Select Folder</InputLabel>
                <FavoritesView
                    id="component-favorites-view"
                    classes={{ root: classes.favoritesView }}
                    foldersOnly
                    selected={folderId}
                    onNodeSelect={setFolderId}
                    expanded={expanded}
                    onNodeToggle={onNodeToggle}
                />
            </FormControl>
            <FormControl variant="outlined">
                <InputLabel htmlFor="component-name">Name</InputLabel>
                <OutlinedInput id="component-name" value={name} onChange={handleChangeName} />
            </FormControl>
        </BaseDialog>
    );
};
