import { UUIFetch } from '@wk/elm-uui-common';
import { CHMessagingScope, EventType, OverlayDialogButtonAction } from '@wk/elm-uui-context-handler';
import { manuallyResetPromiseCounter, trackPromise } from 'react-promise-tracker';
import { getAuthenticationProvider } from '../components/authentication/AuthenticationProviderService';
import { FullScreenOverlayIconEnum } from '../components/common/types';
import { clPublish, isOC } from '../components/contextLayerService/contextLayerService';
import { messageBusDispatch } from '../components/contextLayerService/messageBusService';
import { AppDispatch } from '../store';
import { showNotification, closeOverlayDialog, openOverlayDialog } from '../store/slices';
import { getFileNameFromContentDisposition, downloadFile } from './httpClient.utils';
import { getLogger } from './loggingService';
import { USER_HAS_REFRESHED_OR_TIMED_OUT_SESSION_STORAGE_KEY } from './ocLocationService';

const logger = () => getLogger('FetchUtils');

export const FAILED_FETCH_MESSAGE = 'Failed to fetch';
export const ERROR_400_MESSAGE = '400 response found';
export const ERROR_403_MESSAGE = '403 response found';
export const ERROR_404_MESSAGE = '404 response found';

const getResponseMessage = (text: string): string => {
    try {
        if(text.includes("html"))
        {
            logger().error(`Response text is : ${text}`);
            return '' as string;
        } 
        const json = JSON.parse(text) as { message?: string; Message?: string; errors?: { ErrorMessage: string }[] };
        return json.message || json.Message || json?.errors?.[0].ErrorMessage || '';
    } catch (e) {        
        logger().error(`Response was invalid JSON. Falling back to default message.: ${(e as Error).message} : Response Text is : ${text}`);     
        return '' as string; // FIXME: replace with more generic message
    }
};

const handle401 = (): void => {
    clPublish({ name: EventType.UNAUTHORIZED_ACCESS_ATTEMPT });
};

const handleGenericError = async (response: Response, dispatch: AppDispatch): Promise<void> => {
    // hide global spinner
    manuallyResetPromiseCounter();
    const text = await response.text();
    const message = getResponseMessage(text);

    if(!message || !isOC)
    {
        return;
    }
    dispatch(
        showNotification({
            notification: {
                message,
                options: {
                    variant: 'error',
                    persist: true,
                },
            },
        }),
    );     
};

const handleServerError = (_: Response, dispatch: AppDispatch): void => {
    // hide global spinner
    manuallyResetPromiseCounter();
    dispatch(
        openOverlayDialog({
            dialog: {
                heading: window.Props.errorOverlayHeading,
                icon: FullScreenOverlayIconEnum.EXCLAMATION,
                message: [window.Props.errorOverlayMessage],
                button: {
                    text: window.Props.returnToHomeButton,
                    action: OverlayDialogButtonAction.NavigateToHome,
                },
            },
        }),
    );
};

const handleNetworkDown = (dispatch: AppDispatch): void => {
    clPublish({ name: EventType.NETWORK_DOWN });
    // hide global spinner
    manuallyResetPromiseCounter();
    messageBusDispatch({
        type: 'OpenOverlayDialog',
        scope: CHMessagingScope.AllInstances,
        message: JSON.stringify({
            heading: window.Props.noInternetConnection,
            icon: FullScreenOverlayIconEnum.EXCLAMATION,
            message: [window.Props.reconnecting],
        }),
    });
    dispatch(
        openOverlayDialog({
            dialog: {
                heading: window.Props.noInternetConnection,
                icon: FullScreenOverlayIconEnum.EXCLAMATION,
                message: [window.Props.reconnecting],
            },
        }),
    );
};

const handleNetworkRestored = (dispatch: AppDispatch): void => {
    clPublish({ name: EventType.NETWORK_RESTORED });
    messageBusDispatch({
        type: 'CloseOverlayDialog',
        scope: CHMessagingScope.AllInstances,
    });
    dispatch(closeOverlayDialog());
    location.reload();
};

const handleNetworkFailure = async (dispatch: AppDispatch, heartBeat: () => Promise<void>): Promise<void> => {
    logger().error('Network is down. Initiating heartbeat function');
    handleNetworkDown(dispatch);

    await heartBeat();

    logger().error('Heartbeat function resolved. Network is restored');
    handleNetworkRestored(dispatch);
};

// FIXME: it should not be stored here, but for now just moving the existing logic to a central place...
// eslint-disable-next-line @typescript-eslint/init-declarations
let currentApiVersion: string;
export const setApiVersion = (version: string): void => {
    currentApiVersion = version;
};
const checkApiVersionChanged = async (response: Response, dispatch: AppDispatch): Promise<void> => {
    const responseText = await response.text();
    try {
        const { version: serverApiVersion } = JSON.parse(responseText) as { version?: string };
        if (!serverApiVersion || !currentApiVersion || serverApiVersion.toString() === currentApiVersion) {
            return;
        }

        logger().error(
            `JSON API Version change detected. New version: ${serverApiVersion} Baseline version: ${currentApiVersion}`,
        );
        dispatch(
            openOverlayDialog({
                dialog: {
                    heading: window.Props.serverChangesDetectedHeading,
                    icon: FullScreenOverlayIconEnum.DOWNLOAD,
                    message: [window.Props.serverChangesDetectedMessage],
                    button: {
                        text: window.Props.buttonRefreshNow,
                        action: OverlayDialogButtonAction.Reload,
                    },
                },
            }),
        );
    } catch (e) {
        // this was not a json response, so skip versioning check
    }
};

type ErrorHandlers = {
    [key in number | 'default']: (response: Response, dispatch: AppDispatch) => void | Promise<void>;
};
const defaultErrorHandlers: ErrorHandlers = {
    401: handle401,
    500: handleServerError,
    default: handleGenericError,
};

const interceptFetchResponse = async (
    response: Response,
    dispatch: AppDispatch,
    errorHandlers: ErrorHandlers,
): Promise<void> => {
    if (response.ok) {
        await checkApiVersionChanged(response, dispatch);
        return;
    }
    const errorHandler = errorHandlers[response.status] || errorHandlers.default;
    await errorHandler(response, dispatch);
};

export const initializeUUIFetch = (dispatch: AppDispatch, heartbeat = passportHeartBeatFunction): void => {
    const extendedErrorHandlers: ErrorHandlers = {
        ...defaultErrorHandlers,
        503: (_, dispatch) => {
            void handleNetworkFailure(dispatch, heartbeat);
        },
    };
    UUIFetch.initialize({
        interceptResponse: (response) => interceptFetchResponse(response, dispatch, extendedErrorHandlers),
        handleNetworkFailure: () => {
            void handleNetworkFailure(dispatch, heartbeat);
        },
    });
};

export interface ApiFetchOptions<T> {
    /** will not show a global spinner for this ajax request */
    skipTracking?: boolean;
    responseCallbackFn?: (response: Response) => Promise<T> | T;
    /**
     * Default value is 'GET' if no `bodyData` is provided, 'POST' otherwise.
     */
    method?: HttpMethod | string;
    isExternalServerRequest?: boolean;
}

// TODO: move bodyData inside base options, requires update of all usages of apiFetch
interface InternalApiFetchOptions<T> extends Omit<ApiFetchOptions<T>, 'skipTracking' | 'isExternalServerRequest'> {
    bodyData?: unknown;
}

// noinspection JSUnusedGlobalSymbols
export enum HttpMethod {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Delete = 'DELETE',
}

/**
 * Downloads a file from the specified URL using the Fetch API and saves it with the provided file name.
 * The download is secured with authentication if supported by the authentication provider.
 *
 * @param url The URL from which to download the file.
 * @returns A Promise that resolves when the file download is completed.
 */
export const apiDownload = async (url: string): Promise<void> => {
    // TODO: check in https://jira.wolterskluwer.io/jira/browse/GET-4214 or
    //  https://jira.wolterskluwer.io/jira/browse/GET-5722 if this function is required at all.
    //  Maybe there is a way to use only links in table to download a file

    const options: RequestInit = { method: HttpMethod.Get };
    const authProvider = getAuthenticationProvider();
    const secureOptions = await authProvider.addRequestAuthentication(options);

    try {
        const response = await fetch(url, secureOptions);
        const contentDisposition = response.headers.get('Content-Disposition');
        const fileName = getFileNameFromContentDisposition(contentDisposition);
        const blob = await response.blob();

        downloadFile(blob, fileName);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Download failed:', error);
    }
};

export const apiFetch = <T,>(
    url: string,
    bodyData?: unknown,
    opts?: ApiFetchOptions<T>,
    requestInit?: Omit<RequestInit, 'headers' | 'body' | 'method'>,
): Promise<T> => {
    const fetchCall = apiFetchInternal<T>(url, { ...opts, bodyData }, requestInit, opts?.isExternalServerRequest);
    return opts?.skipTracking ? fetchCall : trackPromise(fetchCall);
};

const defaultResponseCallBack = async <T,>(response: Response): Promise<T> => {
    if (response.status === 400) {
        throw new Error(ERROR_400_MESSAGE);
    }
    if (response.status === 403) {
        throw new Error(ERROR_403_MESSAGE);
    }
    if (response.status === 404) {
        throw new Error(ERROR_404_MESSAGE);
    }

    if (response.redirected) {
        // send a request of the current the browser url so that Passport always redirects
        // back to a non JSON api endpoint after the user logs back in. Do not wait on the response.
        fetch(window.location.href);
        sessionStorage.setItem(USER_HAS_REFRESHED_OR_TIMED_OUT_SESSION_STORAGE_KEY, Props.username);
        window.location.href = response.url + '?timeout=true';
        // sleep for a long time while the redirect happens so an error
        // dialog does not appear. The browser will not wait for this to complete.
        await new Promise((resolve) => setTimeout(resolve, 10000));
    }

    // do not try to parse empty response, it will fail
    // FIXME: need more generic and solid solution?
    if (response.status === 204 || response.headers.get('Content-Length') === '0') {
        return {} as T;
    }
    return (await response.json()) as T;
};

async function apiFetchInternal<T>(
    url: string,
    opts?: InternalApiFetchOptions<T>,
    requestInit: Omit<RequestInit, 'headers' | 'body' | 'method'> = {},
    isExternalServerRequest?: boolean
): Promise<T> {
    const {
        bodyData,
        method = bodyData ? HttpMethod.Post : HttpMethod.Get,
        responseCallbackFn = defaultResponseCallBack,
    } = opts || ({} as InternalApiFetchOptions<T>);
    const request: RequestInit = bodyData
        ? {
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(bodyData),
            method,
            ...requestInit,
        }
        : { method, ...requestInit };

    const authProvider = getAuthenticationProvider();
    const secureRequest = await authProvider.addRequestAuthentication(request);
    const response = !isExternalServerRequest ? await UUIFetch.fetch(url, secureRequest)
        : await UUIFetch.fetchExternalApi(url, secureRequest);

    return responseCallbackFn(response);
}

const passportHeartBeatFunction = async (): Promise<void> => {
    const { apiContextRoot, apiContextPath } = window.Props;
    const systemStatusUrl = `${String(apiContextRoot)}${String(apiContextPath)}/systemStatusCheck/show.do`;

    await poll(
        async () => {
            try {
                const response = await fetch(systemStatusUrl);
                if (response.ok) {
                    return await response.text();
                }
            } catch (err) {
                // ignored
            }
            return Promise.resolve('');
        },
        (result: string) => {
            return result.includes('Application is Available');
        },
        5000,
    );
};
/**
 * Calls fn every ms milliseconds until fnCondition returns true
 *
 * @param fn The function to poll
 * @param fnCondition This function receives the return value of fn() as a parameter and should return true if you want polling to stop
 * @param ms How often to poll
 * @returns the return value of fn once fnCondition becomes true
 */
const poll = async <T,>(fn: () => Promise<T>, fnCondition: (result: T) => boolean, ms: number): Promise<T> => {
    let result = await fn();
    while (!fnCondition(result)) {
        await wait(ms);
        result = await fn();
    }
    return result;
};

const wait = (ms = 5000): Promise<void> => {
    return new Promise<void>((resolve) => {
        setTimeout(resolve, ms);
    });
};
