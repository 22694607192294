import { Link } from '@mui/material';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { CSSProperties, forwardRef } from 'react';
import { useNavigate } from '../../../../hooks';
import { IMenuItem } from '../../../../reducers/types';
import { SummaryComponent } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { TargetScreenType } from '../../../common/types';
import { SummaryAccordion } from './accordion';
import { useSummaryView } from './hooks';
import { TreePath } from './treePath.component';

type SummaryProps = {
    component: SummaryComponent;
    classes?: {
        root?: string;
    };
    style?: CSSProperties;
};

const useStyles = makeStyles<AppTheme>((theme) => ({
    gridRoot: {
        paddingLeft: '0 !important',
        paddingTop: '0px !important',
    },
    summaryCaption: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.grey[600],
        textAlign: 'right',
        fontSize: 13,
        textWrap: 'balance',
        flex: 1,
        paddingRight: 8,
    },
    summaryLabel: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.secondary,
        fontSize: 13,
        fontWeight: 600,
        textWrap: 'balance',
    },
    summaryLink: {
        fontFamily: theme.typography.fontFamily,
        textAlign: 'left',
        fontSize: 13,
        whiteSpace: 'normal',
        textWrap: 'balance',
    },
    summaryItem: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    summaryValue: {
        alignSelf: 'flex-end',
    },
}));

export const SummaryView = forwardRef<HTMLDivElement, SummaryProps>((props, ref) => {
    const classes = useStyles();
    const { component } = props;
    const { data } = useSummaryView({ component });
    const { navigate } = useNavigate();

    const renderLink = (url: string, text: string): JSX.Element => {
        return (
            <Link
                classes={{ root: classes.summaryLink }}
                component="button"
                onClick={(): void => {
                    navigate({
                        menuItem: {
                            screenType: TargetScreenType.Legacy,
                            url: url,
                            id: 0,
                        } as IMenuItem,
                    });
                }}>
                {text}
            </Link>
        );
    };

    return (
        <div data-testid="summary-view" ref={ref}>
            <SummaryAccordion isExpanded={true} title={component.title}>
                <Grid container spacing={{ xs: 2 }} columns={3}>
                    {data?.map((d, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Grid classes={{ root: classes.gridRoot }} item xs={1}>
                                    <div className={classes.summaryItem}>
                                        <div className={classes.summaryCaption}>{d.caption}:</div>
                                        <div className={classes.summaryValue}>
                                            {
                                                {
                                                    Label: <label className={classes.summaryLabel}>{d.value}</label>,
                                                    Link: renderLink(d.link, d.value),
                                                    Tree: <TreePath link={d.link} path={d.value} />,
                                                }[d.controlType]
                                            }
                                        </div>
                                    </div>
                                </Grid>
                            </React.Fragment>
                        );
                    })}
                </Grid>
            </SummaryAccordion>
        </div>
    );
});

SummaryView.displayName = 'SummaryView';
