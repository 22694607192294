import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import React, { VFC } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Clear';
import { AppTheme } from '../../../app';
import { makeStyles } from '@mui/styles';

type ActionButtonProps = {
    action: string;
    payload: string;
    modalcontainer: any;
    data?: any
    OkClick: (action: string, payload: string, description: any) => void;
}

export enum ButtonAction {
    AddNote = 'addnote',
    EditNote = 'editnote',
    DeleteNote = 'deletenote',
    ViewNote = 'viewnote'
}

const useStyles = makeStyles<AppTheme>(() => ({
    button: {
        color: '#fff !important',
        cursor: 'pointer !important',
        borderWidth: '0px !important',
        fontSize: '13px !important',
        fontWeight: 'normal !important',
        WebkitFontSmoothing: 'antialiased !important',
        height: '30px !important',
        width: '122px !important',
        margin: '0 16px 10px 5px !important',
        backgroundColor: '#007ac3 !important',
        borderRadius: '0px !important',
        '&:hover': {
            background: '#003d61 !important',
        },
    },
    buttonSecondary: {
        background: '#fff 0% 0% no-repeat padding-box !important',
        border: '1px solid #005b92 !important',
        color: '#005b92 !important',
        '&:hover': {
            background: '#003d61 !important',
            color: '#fff !important'
        },
    },
    buttondeleteaction: {
        width: '90px !important',
    },
    dialogdeleteaction: {
        justifyContent: 'center !important'
    },
    textarea: {
        height: '250px',
        width: '400px',
        resize: 'none',
        background: '#ffffcc'
    },
    requierdfield: {
        color: '#E5202E !important',
        fontsize: '1.0em !important',
        fontweight: '500 !important'

    },
    dialogtitle: {
        cursor: 'move',
        background: '#f6f6f6',
        height: '45px',
        alignContent: 'center',
        borderRadius: '0px !important'
    },
    closeicon: {
        fontSize: '16px',
        float: 'right',
        marginRight: '-17px',
        alignContent: 'center',
        height: '30px'
    },
    dialogcontenttext: {
        color: '#353535 !important',
        fontWeight: 'bold !important'
    },
    dialogcontenttextsecondary: {
        height: '82px !important',
        width: '337px !important',
        marginTop: '15px !important'
    }
}));

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#notePopupdialog"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export const NoteActionPopup: VFC<ActionButtonProps> = ((props) => {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles();
    const { action, payload, modalcontainer,data, OkClick } = props;

    const handleClose = () => {
        setOpen(false);
        document.body.removeChild(modalcontainer);
    };

    const title = () => {
        switch (action) {
            case ButtonAction.AddNote:
                return 'Add Note';
            case ButtonAction.EditNote:
                return 'Edit Note';
            case ButtonAction.DeleteNote:
                return 'Delete Note';
            default:
                return 'Note';
        }
    };

    const description = () => {
        switch (action) {
            case ButtonAction.EditNote:
            case ButtonAction.ViewNote:
                return data;
            default:
                return '';
        }
    };

    const btnText = () => {
        switch (action) {
            case ButtonAction.DeleteNote:
                return 'No';
            case ButtonAction.ViewNote:
                return 'Close';
            default:
                return 'Cancel';
        }
    };

    const handleButtonClick = async () => {
        const description = document.getElementById('txtdescription') as HTMLSelectElement | null;
        if (description?.value.trim() == '') {
            alert('Note description can not be empty');
            setTimeout(() => {
                description?.focus(); // Focus on the description control after a short delay
            }, 0);
            return;
        }

        await OkClick(action, payload, description?.value);
        setOpen(false);
        document.body.removeChild(modalcontainer);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="notePopupdialog"
                data-testid="notePopupdialog"
            >
                <DialogTitle className={classes.dialogtitle} id="notePopupdialog">
                    <span style={{ fontSize: '16px' }} >{title()}</span>
                    <span className={classes.closeicon} >
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={`${classes.dialogcontenttext} ${action === ButtonAction.DeleteNote ? classes.dialogcontenttextsecondary : ''}`}>
                        {action != ButtonAction.DeleteNote && <span className={classes.requierdfield}>*&nbsp;</span>}
                        {action != ButtonAction.DeleteNote ? 'Description:' : 'Are you sure you want to delete this Note?'}
                    </DialogContentText>
                    {action != ButtonAction.DeleteNote && <textarea id="txtdescription" data-testid="txtDescription" maxLength={4096} className={classes.textarea} autoFocus>{description()}</textarea>}
                </DialogContent>
                <DialogActions className={action == ButtonAction.DeleteNote ? classes.dialogdeleteaction : ''}>
                    {action != ButtonAction.ViewNote && <Button onClick={handleButtonClick} className={`${classes.button} ${action === ButtonAction.DeleteNote ? classes.buttondeleteaction : ''}`}>{action == ButtonAction.DeleteNote ? 'Yes' : 'OK'}</Button>}
                    <Button onClick={handleClose} className={`${classes.button} ${classes.buttonSecondary} ${action === ButtonAction.DeleteNote ? classes.buttondeleteaction : ''}`}>{btnText()}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

