/* eslint-disable max-lines-per-function */
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid, IconButton, Link } from '@mui/material';
import { IQueueItem, IQueueServiceInterface, QueueItemStatus } from '@wk/elm-uui-context-handler';
import { groupBy } from 'lodash';
import React from 'react';
import { IActivityRow, IQueueEmailData, IQueueDocumentData } from '../../reducers/types';
import { useAppDispatch, useAppSelector } from '../../store';
import {
    closeHistoryScreenDialog,
    getAppResources,
    removeAllCancelledHistoryItems,
    setUploadHistoryItems,
} from '../../store/slices';
import { closeFailedUploadFlyout } from '../../utils/activitiesService';
import FullScreenDialog, { FullScreenDialogContent } from '../common/fullScreenDialog';
import { clGetQueue } from '../contextLayerService/contextLayerService';
import WkCircleCheckIcon from '../icons/wkCircleCheckIcon';
import WkCircleLetteriIcon from '../icons/wkCircleLetteriIcon';
import WkErrorIcon from '../icons/wkErrorIcon';
import ActivityItemRow from './activityItemRow';
import css from './historyScreen.module.scss';

const HistoryScreen: React.FC = () => {
    const isHistoryDialogOpen = useAppSelector((state) => state.ui.historyScreen.isHistoryDialogOpen);
    const appResources = useAppSelector(getAppResources);
    const dispatch = useAppDispatch();
    const uploadHistoryItems = useAppSelector((state) => state.ui.uploadHistory);
    const data = groupBy(uploadHistoryItems, 'status');

    const getActivityRow = (item: IQueueItem) => {
        let activityRow: IActivityRow;
        if (item.elementType === 'Email') {
            // email
            const itemData = item.data as unknown as IQueueEmailData;
            activityRow = {
                documentName: itemData.filePath ? itemData.filePath : 'email.msg',
                title: itemData.props.emailSubject,
                associatedEntityType: itemData.associatedEntityType,
                associatedEntityName: itemData.associatedEntityName,
                isEmail: true,
            };
        } else {
            // document
            const itemData = item.data as unknown as IQueueDocumentData;
            activityRow = {
                documentName: itemData.payload.documentName.value,
                title: itemData.payload.documentName.value,
                associatedEntityType: itemData.associatedEntityType,
                associatedEntityName: itemData.associatedEntityName,
                isEmail: false,
            };
        }
        return activityRow;
    };

    // Merge STARTED and PENDING items into a common array
    // so that all such items may be easily ordered, chronologically
    let processingStartedPendingItems: IQueueItem[] | undefined;
    const newQueueItems = data[QueueItemStatus.NEW] ? data[QueueItemStatus.NEW].length : 0;
    let processingQueueItems = 0;
    let startedQueueItems = 0;
    let pendingQueueItems = 0;

    if (data[QueueItemStatus.PROCESSING]) {
        processingQueueItems = data[QueueItemStatus.PROCESSING].length;
        processingStartedPendingItems = [...data[QueueItemStatus.PROCESSING]];
    }

    if (data[QueueItemStatus.STARTED]) {
        startedQueueItems = data[QueueItemStatus.STARTED].length;
        if (processingStartedPendingItems) {
            processingStartedPendingItems = processingStartedPendingItems.concat(data[QueueItemStatus.STARTED]);
        } else {
            processingStartedPendingItems = [...data[QueueItemStatus.STARTED]];
        }
    }

    if (data[QueueItemStatus.PENDING]) {
        pendingQueueItems = data[QueueItemStatus.PENDING].length;
        if (processingStartedPendingItems) {
            processingStartedPendingItems = processingStartedPendingItems.concat(data[QueueItemStatus.PENDING]);
        } else {
            processingStartedPendingItems = [...data[QueueItemStatus.PENDING]];
        }
    }

    const uploadingItemsCount = newQueueItems + processingQueueItems + startedQueueItems + pendingQueueItems;

    const handleClear = (items: IQueueItem[]) => {
        clGetQueue().then((queueManager: IQueueServiceInterface) => {
            queueManager.updateCleared(items);
            dispatch(removeAllCancelledHistoryItems());
        });
    };

    const handleCancelAll = () => {
        clGetQueue().then((queueManager: IQueueServiceInterface) => {
            queueManager.updateCancelAll();
            dispatch(removeAllCancelledHistoryItems());
        });
    };

    const handleCancel = (items: IQueueItem[]) => {
        clGetQueue().then((queueManager: IQueueServiceInterface) => {
            queueManager.updateCancelled(items);
            dispatch(setUploadHistoryItems({ status: QueueItemStatus.CANCELLED, uploadHistoryItems: items }));
        });
    };

    return (
        <FullScreenDialog
            testId="historyscreen-dialog"
            isOpen={isHistoryDialogOpen || false}
            onClose={(): void => {
                dispatch(closeHistoryScreenDialog());
            }}
            AppBarProps={{ elevation: 0 }}
            showAppHeader={true}>
            <FullScreenDialogContent className={css.fullScreenDialogContent}>
                <div className={css.headerContainer}>
                    <IconButton
                        size="small"
                        data-testid="historyPaneBackChevron"
                        onClick={(): void => {
                            dispatch(closeHistoryScreenDialog());
                        }}>
                        <ArrowBackIosIcon color="primary" viewBox="0 0 16 24" className={css.backChevron} />
                    </IconButton>
                    <div className={css.header}>{appResources.historyItemActivity}</div>
                </div>
                {uploadHistoryItems.length === 0 ? (
                    <div className={css.emptyDialog} data-testid="emptyDialogContainer">
                        {appResources.historyItemEmptyDialog}
                    </div>
                ) : (
                    <div className={css.statusBlocks} data-testid="statusContainer">
                        {/* Failed Activity */}
                        {data[QueueItemStatus.FAILED] && (
                            <div className={css.outerFailedRowContainer} data-testid={'failedContainer'}>
                                <Grid className={css.messageSuccess}>
                                    <WkErrorIcon className={css.successIcon} />
                                    <span className={css.statusMessage} data-testid="failedBox">
                                        {data[QueueItemStatus.FAILED].length > 1
                                            ? appResources.historyItemFailedMessage.replace(
                                                  '{items}',
                                                  `${data[QueueItemStatus.FAILED].length}`,
                                              )
                                            : appResources.historyItemFailedMessage
                                                  .replace('{items}', `${data[QueueItemStatus.FAILED].length}`)
                                                  .replace('items', 'item')}
                                    </span>
                                    <div>
                                        <Link
                                            className={css.clearAllLink}
                                            onClick={() => {
                                                handleClear(data[QueueItemStatus.FAILED]);
                                                closeFailedUploadFlyout(dispatch);
                                            }}
                                            data-testid="clearAllFailedLink">
                                            {appResources.historyItemClearAll}
                                        </Link>
                                    </div>
                                </Grid>
                                {data[QueueItemStatus.FAILED]
                                    .sort((a: any, b: any) => (b.created > a.created ? 1 : -1))
                                    .map((item: IQueueItem, index: number) => {
                                        return (
                                            <ActivityItemRow
                                                key={index}
                                                index={index}
                                                item={item}
                                                itemData={getActivityRow(item)}
                                                onClick={() => handleClear([item])}
                                            />
                                        );
                                    })}
                            </div>
                        )}

                        {/* In Progress Activity */}
                        {data[QueueItemStatus.STARTED] ||
                        data[QueueItemStatus.PENDING] ||
                        data[QueueItemStatus.NEW] ||
                        data[QueueItemStatus.PROCESSING] ? (
                            <div
                                className={data[QueueItemStatus.NEW] ? css.newElement : css.outerInfoRowContainer}
                                data-testid={'inProgressContainer'}>
                                <Grid className={css.messageSuccess}>
                                    <WkCircleLetteriIcon className={css.successIcon} />
                                    <span className={css.statusMessage} data-testid="startedBox">
                                        {uploadingItemsCount > 1
                                            ? appResources.historyItemUploading.replace(
                                                  `{queueCount}`,
                                                  `${uploadingItemsCount}`,
                                              )
                                            : appResources.historyItemUploading
                                                  .replace(`{queueCount}`, `${uploadingItemsCount}`)
                                                  .replace('items', 'item')}
                                    </span>
                                    <div>
                                        <Link
                                            className={css.clearAllLink}
                                            onClick={() => handleCancelAll()}
                                            data-testid={'cancelAllLink'}>
                                            {appResources.historyItemCancelAll}
                                        </Link>
                                    </div>
                                </Grid>
                                {processingStartedPendingItems
                                    ?.sort((a: any, b: any) => (b.created > a.created ? 1 : -1))
                                    .map((item: IQueueItem, index: number) => {
                                        return (
                                            <ActivityItemRow
                                                key={index}
                                                index={index}
                                                item={item}
                                                itemData={getActivityRow(item)}
                                                onClick={() => handleCancel([item])}
                                            />
                                        );
                                    })}

                                {data[QueueItemStatus.NEW]
                                    ?.sort((a: any, b: any) => (b.created > a.created ? 1 : -1))
                                    .map((item: IQueueItem, index: number) => {
                                        return (
                                            <ActivityItemRow
                                                key={index}
                                                index={index}
                                                item={item}
                                                itemData={getActivityRow(item)}
                                                onClick={() => handleCancel([item])}
                                            />
                                        );
                                    })}
                            </div>
                        ) : null}

                        {/* Completed Activity */}
                        {data[QueueItemStatus.COMPLETED] && (
                            <div className={css.outerRowContainer} data-testid={'completedContainer'}>
                                <Grid className={css.messageSuccess}>
                                    <WkCircleCheckIcon className={css.successIcon} />
                                    <span className={css.statusMessage}>
                                        {data[QueueItemStatus.COMPLETED].length > 1
                                            ? appResources.historyItemSuccessfullUploadedMessage.replace(
                                                  '{items}',
                                                  `${data[QueueItemStatus.COMPLETED].length}`,
                                              )
                                            : appResources.historyItemSuccessfullUploadedMessage
                                                  .replace('{items}', `${data[QueueItemStatus.COMPLETED].length}`)
                                                  .replace('items', 'item')}{' '}
                                        {}
                                    </span>
                                    <div>
                                        <Link
                                            className={css.clearAllLink}
                                            onClick={() => handleClear(data[QueueItemStatus.COMPLETED])}>
                                            {appResources.historyItemClearAll}
                                        </Link>
                                    </div>
                                </Grid>

                                {data[QueueItemStatus.COMPLETED]?.length > 0 && (
                                    <Grid container direction="column" spacing={2}>
                                        {data[QueueItemStatus.COMPLETED]
                                            .sort((a: any, b: any) => (b.created > a.created ? 1 : -1))
                                            .map((item: IQueueItem, index: number) => (
                                                <ActivityItemRow
                                                    key={index}
                                                    index={index}
                                                    item={item}
                                                    itemData={getActivityRow(item)}
                                                />
                                            ))}
                                    </Grid>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </FullScreenDialogContent>
        </FullScreenDialog>
    );
};
export default HistoryScreen;
