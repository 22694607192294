import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';

export const WkTaskDefault: React.FC<SvgIconProps> = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon data-testid="wkTaskDefault" fill="none" viewBox="0 0 16 17" {...props} ref={ref}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.79961 8.67712C4.21051 8.67712 3.73294 9.15468 3.73294 9.74378C3.73294 10.3329 4.21051 10.8104 4.79961 10.8104C5.38871 10.8104 5.86628 10.3329 5.86628 9.74378C5.86628 9.15468 5.38871 8.67712 4.79961 8.67712ZM6.93294 10.2771H12.2663V9.21045H6.93294V10.2771ZM4.79961 11.8771C4.21051 11.8771 3.73294 12.3547 3.73294 12.9438C3.73294 13.5329 4.21051 14.0104 4.79961 14.0104C5.38871 14.0104 5.86628 13.5329 5.86628 12.9438C5.86628 12.6609 5.7539 12.3896 5.55386 12.1895C5.35382 11.9895 5.08251 11.8771 4.79961 11.8771ZM6.93294 13.4771H12.2663V12.4104H6.93294V13.4771ZM4.79961 5.47712C4.21051 5.47712 3.73294 5.95468 3.73294 6.54378C3.73294 7.13289 4.21051 7.61045 4.79961 7.61045C5.38871 7.61045 5.86628 7.13289 5.86628 6.54378C5.86628 5.95468 5.38871 5.47712 4.79961 5.47712ZM6.93294 7.07712H12.2663V6.01045H6.93294V7.07712ZM12.2663 2.27712V1.21045H9.59961V2.27712H6.39961V1.21045H3.73294V2.27712H1.59961V16.1438H14.3996V2.27712H12.2663ZM10.6663 2.27712H11.1996V3.34378H10.6663V2.27712ZM4.79961 2.27712H5.33294V3.34378H4.79961V2.27712ZM13.3329 15.0771H2.66628V3.34378H3.73294V4.41045H6.39961V3.34378H9.59961V4.41045H12.2663V3.34378H13.3329V15.0771Z"
            fill="currentColor"
        />
    </SvgIcon>
));

WkTaskDefault.displayName = 'WkTaskDefault';
