import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { TypeBackground, Shadows } from '@mui/material/styles';
import { StyleRules } from '@mui/styles/withStyles';
import { createSpacing, SpacingOptions } from '@mui/system';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import '@mui/x-data-grid/themeAugmentation';
import { mergeWith } from 'lodash';
import { useMemo } from 'react';
import { DeepPartial } from 'redux';
import { config } from '../../../target/t360/config/index';
import shadows from './defaults/shadows';
import { styles, getComponentsStyles } from './variables';

interface ExtendedOptions {
    leftMenu: {
        collapsedWidth: number;
        expandedWidth: number;
        paneWidth: number;
    };
    appBarHeight: number;
    favoritesShortcutBarHeight: number;
}

type Styles = typeof styles & ReturnType<typeof getComponentsStyles>;

export interface AppTheme extends Theme, ExtendedOptions {
    palette: Theme['palette'] & {
        background: Partial<TypeBackground> & {
            secondary: string;
        };
        view: {
            main: string;
            dark: string;
            light: string;
        };
    };

    styles: Styles;
}

export type StyleProps = StyleRules[keyof StyleRules];

interface AppThemeOptions extends ThemeOptions, ExtendedOptions {
    palette: ThemeOptions['palette'] & {
        background: Partial<TypeBackground> & {
            secondary: string;
        };
        view: {
            main: string;
            dark: string;
            light: string;
        };
    };
}

// map for material design shadows
// https://material.io/archive/guidelines/material-design/elevation-shadows.html#elevation-shadows-elevation-android
const getThemeShadows = (patch: Record<number, string> = {}): Shadows => {
    const themeShadows: Shadows = [...shadows];

    Object.entries(patch).forEach(([key, value]) => {
        themeShadows[key] = value;
    });

    return themeShadows;
};

LicenseInfo.setLicenseKey(config.get('REACT_APP_MUI_PRO_LICENSE_KEY') || '');

// eslint-disable-next-line max-lines-per-function
export const useAppTheme = (patch?: DeepPartial<AppTheme>): AppTheme => {
    // eslint-disable-next-line max-lines-per-function
    return useMemo<AppTheme>(() => {
        const primary = {
            main: '#007ac3',
            dark: '#005B92',
            light: '#003D61',
        };

        const common = {
            black: '#000',
            white: '#fff',
        };

        const action = {
            hover: '#E6F2F9',
            selected: '#E6F2F9',
            focus: '#E6F2F9',
            active: '#005B92',
            disabled: '#80BDE1',
        };

        const success = {
            light: '#85bc20',
            main: '#4caf50',
            dark: '#007261',
        };

        const text = {
            primary: '#232323',
            secondary: '#353535',
        };

        const background = {
            default: '#fff',
            secondary: '#f6f6f6',
        };

        const spacingOptions: SpacingOptions = 8;
        const spacing = createSpacing(spacingOptions);

        const defaultThemeOptions: AppThemeOptions = {
            components: {
                MuiCssBaseline: {
                    styleOverrides: {
                        html: {
                            fontSize: 14,
                        },
                        body: {
                            backgroundColor: common.white,
                            overflowY: 'hidden',
                            margin: 0,
                            fontSize: '0.875rem',
                            fontWeight: 400,
                            lineHeight: 1.43,
                        },
                    },
                },
                MuiLink: {
                    styleOverrides: {
                        root: {
                            color: primary.dark,
                        },
                    },
                    defaultProps: {
                        underline: 'hover',
                    },
                },
                MuiListItem: {
                    styleOverrides: {
                        root: {
                            '&.Mui-focusVisible': styles.focus({ color: common.black }),
                        },
                        gutters: {
                            paddingLeft: spacing(1),
                        },
                    },
                },
                MuiDataGrid: {
                    styleOverrides: {
                        root: {
                            border: `1px solid ${background.secondary}`,
                        },
                        columnHeaders: {
                            borderBottom: 0,
                            backgroundColor: background.secondary,
                        },
                        columnHeader: {
                            padding: spacing(0, 0, 0, 1.25),

                            '& .MuiDataGrid-columnHeaderTitleContainer': {
                                padding: 0,

                                '& .MuiIconButton-sizeSmall': {
                                    padding: 0,
                                },
                            },
                            '&:focus-within': {
                                ...styles.focus({ color: common.black }),
                            },
                        },
                        cell: {
                            borderBottom: 0,

                            '&:focus-within': {
                                outline: 'none',
                            },
                        },
                    },
                },
                MuiMenu: {
                    styleOverrides: {
                        paper: {
                            maxWidth: 320,
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            minHeight: 'auto',

                            '&+.MuiDivider-root': {
                                marginTop: 0,
                                marginBottom: 0,
                            },
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            border: `1px solid ${success.dark}`,
                        },
                    },
                },
                MuiDialog: {
                    styleOverrides: {
                        paperFullScreen: {
                            border: 0,
                        },
                    },
                },
                MuiDrawer: {
                    styleOverrides: {
                        paper: {
                            border: 0,
                        },
                    },
                },
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            border: 0,
                        },
                    },
                },
                MuiButtonBase: {
                    styleOverrides: {
                        root: {
                            '&.Mui-focusVisible': styles.focus({ color: common.black }),
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            lineHeight: 1.5,
                            whiteSpace: 'nowrap',
                            '@media print': { display: 'none;' },
                        },
                        iconSizeMedium: {
                            '& > *:first-child': {
                                fontSize: '1.5rem',
                            },
                        },
                        startIcon: {
                            marginRight: 4,
                        },
                        endIcon: {
                            marginLeft: 4,
                        },
                        containedPrimary: {
                            backgroundColor: primary.main,
                            color: common.white,
                            height: 32,
                            padding: spacing(0, 2),

                            '&:hover': {
                                backgroundColor: primary.light,
                            },
                            '&:focus': {
                                backgroundColor: primary.dark,
                            },
                            '&:disabled': {
                                backgroundColor: '#80BDE1',
                                color: common.white,
                            },
                        },
                        outlinedPrimary: {
                            backgroundColor: common.white,
                            border: `1px solid ${primary.dark}`,
                            color: primary.dark,
                            height: 32,
                            padding: spacing(0, 2),

                            '&:hover': {
                                backgroundColor: primary.light,
                                border: `1px solid ${primary.light}`,
                                color: common.white,
                            },
                            '&:active': {
                                backgroundColor: primary.dark,
                                border: `1px solid ${primary.dark}`,
                                color: common.white,
                            },
                            '&:disabled': {
                                opacity: 0.5,
                                borderColor: primary.dark,
                                color: primary.dark,
                            },
                        },
                        textPrimary: {
                            color: primary.dark,

                            '&:hover': {
                                backgroundColor: 'transparent',
                                color: primary.light,
                                textDecoration: 'underline',
                            },
                            '&:active': {
                                backgroundColor: 'transparent',
                                color: text.secondary,
                                textDecoration: 'none',
                            },
                            '&:disabled': {
                                opacity: 0.5,
                                color: primary.dark,
                            },
                        },
                    },
                },
                MuiIconButton: {
                    styleOverrides: {
                        root: {
                            padding: spacing(1),
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            padding: spacing(1),
                        },
                        input: {
                            padding: spacing(0),
                        },
                    },
                },
                MuiInputLabel: {
                    styleOverrides: {
                        outlined: {
                            lineHeight: 1.5,
                            fontWeight: 500,
                            transition: 'none',
                            transform: 'none !important',
                            position: 'relative',
                            padding: spacing(0.5, 0),
                        },
                    },
                },
                MuiAccordionSummary: {
                    styleOverrides: {
                        root: {
                            '&, &.Mui-expanded': {
                                minHeight: 0,
                            },
                        },
                        content: {
                            '&, &.Mui-expanded': {
                                margin: 0,
                            },
                        },
                    },
                },
            },
            breakpoints: {
                values: {
                    xs: 375,
                    sm: 690, // TODO: should revert back to 600 when OC form-factor comes back
                    md: 900,
                    lg: 1200,
                    xl: 1536,
                },
            },
            palette: {
                view: { ...primary },
                primary: { ...primary },
                secondary: {
                    main: '#353535',
                },
                warning: {
                    main: '#AC1822',
                    dark: '#EA8F00',
                    light: '#005B92',
                },
                text,
                grey: {
                    200: '#EDEDED',
                    300: '#DADADA',
                    500: '#979797',
                    600: '#757575',
                    900: '#232323',
                    A700: '#383838',
                },
                background,
                action,
                success,
                error: {
                    main: '#E5202E',
                },
            },
            spacing: spacingOptions,
            typography: {
                fontFamily: 'Fira Sans,Helvetica,Arial,sans-serif',
                fontSize: 14,
                button: {
                    fontSize: 14,
                    fontWeight: 'normal',
                    textTransform: 'none',
                },
                subtitle1: {
                    fontSize: 16,
                },
                h4: {
                    fontSize: '2rem',
                },
                h5: {
                    fontSize: 20,
                    lineHeight: 1.3,
                },
                caption: {
                    fontSize: 12,
                },
                body1: {
                    lineHeight: '16px',
                },
            },
            shape: {
                borderRadius: 0,
            },
            shadows: getThemeShadows({
                8: '0 6px 12px rgba(0,0,0,0.1)',
                24: '0 12px 24px 0 rgba(0,0,0,0.1)',
            }),
            leftMenu: {
                collapsedWidth: 56,
                expandedWidth: 272,
                paneWidth: 290,
            },
            appBarHeight: 56,
            favoritesShortcutBarHeight: 40,
        };

        const themeOptions = mergeWith(defaultThemeOptions, patch, (objValue: unknown, srcValue: unknown) => {
            if (!srcValue) {
                return objValue;
            }
        });

        const theme = createTheme(themeOptions) as AppTheme;

        return Object.assign(theme, {
            styles: {
                ...styles,
                divider: styles.divider(theme),
                ...getComponentsStyles(theme),
            },
        });
    }, [patch]);
};

// FIXME: this exists for visual tests only
export const useOldAppTheme = (): AppTheme => {
    const themeOptions: AppThemeOptions = {
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    html: {
                        fontSize: 16,
                    },
                    body: {
                        backgroundColor: '#ffffff',
                        overflowY: 'hidden',
                        margin: 0,
                        fontSize: '0.875rem',
                        fontWeight: 400,
                        lineHeight: 1.43,
                    },
                },
            },
        },
        palette: {
            view: {
                main: '#007ac3',
                dark: '#1565c0',
                light: '#42a5f5',
            },
            primary: {
                main: '#007ac3',
            },
            secondary: {
                main: '#474747',
            },
            text: {
                secondary: 'rgba(0,0,0,0.64)',
            },
            background: {
                default: '#fff',
                secondary: '#f6f6f6',
            },
        },
        typography: {
            fontFamily: 'Fira Sans,Helvetica,Arial,sans-serif',
            button: {
                textTransform: 'none',
            },
        },
        leftMenu: {
            collapsedWidth: 56,
            expandedWidth: 270,
            paneWidth: 260,
        },
        appBarHeight: 56,
        favoritesShortcutBarHeight: 40,
    };

    const theme = createTheme(themeOptions) as AppTheme;

    return Object.assign(theme, {
        styles: {
            ...styles,
            ...getComponentsStyles(theme),
        },
    });
};
