import { LocationDescriptor, Path } from 'history';
import { TargetScreenType, UUIHistoryState } from '../components/common/types';
import { IItemScreenProps } from '../components/itemScreen/itemScreen';
import { getItemScreenPropsFromUrl } from '../components/itemScreen/itemScreenHelpers';
import { IMenuItem } from '../reducers/types';
import { useAppDispatch } from '../store';
import { clearBreadCrumbState, openItemScreenDialog, setActiveScreenId } from '../store/slices';
import { useUUIHistory } from './useUUIHistory';

export type NavigateParams = {
    menuItem: IMenuItem;
    to: Path | LocationDescriptor<UUIHistoryState>;
    options: {
        replace?: boolean;
        state?: UUIHistoryState;
        clearBreadcrumbs?: boolean;
    };
};

type GetScreenUrlOrPathOptions = Pick<IMenuItem, 'screenType' | 'screenId' | 'id' | 'url' | 'queryString'>;

export const useNavigate = () => {
    const dispatch = useAppDispatch();
    const history = useUUIHistory();

    const navigate = (params: Partial<NavigateParams>): void => {
        const { menuItem, to, options } = params;

        if (to) {
            routeNavigate(to, options);
            return;
        }

        if (menuItem) {
            menuNavigate(menuItem, options);
            return;
        }
    };

    const routeNavigate = (to: NavigateParams['to'], options?: NavigateParams['options']): void => {
        if (options?.clearBreadcrumbs) {
            dispatch(clearBreadCrumbState());
        }
        if (options?.replace) {
            location.replace(to as string);
            return;
        }
        history.push(to, options?.state);
        return;
    };

    const menuNavigate = (item: IMenuItem, options?: NavigateParams['options']): void => {
        const { screenType, screenId, url, id, isNewWindow, isPopup, displayValue, queryString } = item;
        const to = getScreenUrlOrPath({ screenType, screenId, url, id, queryString });

        if (isNewWindow) {
            // Supports only an absolute URL.
            window.open(to, '_blank');
            return;
        }

        if (isPopup) {
            const itemScreenProps: IItemScreenProps = {
                ...getItemScreenPropsFromUrl(to),
                popupTitle: displayValue,
            };
            dispatch(openItemScreenDialog({ itemScreen: itemScreenProps }));
            return;
        }

        dispatch(setActiveScreenId({ screenId: id }));
        routeNavigate(to, options);
    };

    const getScreenUrlOrPath = (options: GetScreenUrlOrPathOptions): string => {
        const { screenType, screenId, url, id, queryString = '' } = options;

        if (screenType === TargetScreenType.Legacy) {
            return `/enduser/${screenType}screens/show.do?screenId=${id}&url=${encodeURIComponent(url)}`;
        }

        if (screenType === TargetScreenType.View) {
            const query = queryString && `${queryString}&`;
            return !query
                ? `/enduser/${screenType}screens${url}`
                : `/enduser/${screenType}screens/${url}?${query}screenId=${id}`;
        }

        if (url) {
            return url;
        }

        if (screenType && screenId) {
            return `/enduser/${screenType}screens/show.do?id=${screenId}`;
        }

        throw new Error(`Cannot build a valid url with provided options: ${JSON.stringify(options)}`);
    };

    return { navigate };
};
