import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import React, { VFC, useEffect, useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Clear';
import { AppTheme } from '../../../app';
import { makeStyles } from '@mui/styles';
import { NumericFormat } from 'react-number-format';

export type ActionProps = {
    action: string;
    payload: string;
    modalcontainer: any;
    data?: any
    OkClick: (action: string, payload: string, description?: any) => void;
}

interface ActionParametersProps {
    Narrative?: string;
    BillCodeGroupBillCodeId: string;
    AdjustMentAmount?: string;
    IsNegative?: boolean;
    CurrencySymbol?: string;
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export enum Action {
    Adjustment = 'adjust',
    Reject = 'reject',
}

export enum ReviewActionType {
    AdjustSelectedLineItems,
    AdjustSelectedAiLineItems,
    AdjustLineItem,
    AdjustFees,
    AdjustExpenses,
    Reject,
    RejectLineItem,
    AdjustNetTo
}

const useStyles = makeStyles<AppTheme>(() => ({
    button: {
        color: '#fff !important',
        cursor: 'pointer !important',
        borderWidth: '0px !important',
        fontSize: '13px !important',
        fontWeight: 'normal !important',
        WebkitFontSmoothing: 'antialiased !important',
        height: '30px !important',
        width: '122px !important',
        margin: '0 16px 10px 5px !important',
        backgroundColor: '#007ac3 !important',
        borderRadius: '0px !important',
        '&:hover': {
            background: '#003d61 !important',
        },
    },
    buttonSecondary: {
        background: '#fff 0% 0% no-repeat padding-box !important',
        border: '1px solid #005b92 !important',
        color: '#005b92 !important',
        '&:hover': {
            background: '#003d61 !important',
            color: '#fff !important'
        },
    },
    textarea: {
        height: '120px',
        width: '95%',
        resize: 'none',
        '&:focus': {
            border: '1px solid #000 !important',
            outline: 0,
            boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6)',
        },
    },
    dialogtitle: {
        cursor: 'move',
        background: '#f6f6f6',
        height: '45px',
        alignContent: 'center !important',
        borderRadius: '0px !important',
        width: '525px !important',
        paddingTop: '9px !important'
    },
    closeicon: {
        fontSize: '16px',
        float: 'right',
        marginRight: '-17px',
        alignContent: 'center',
        height: '30px'
    },
    dialogcontenttext: {
        color: '#353535 !important',
        padding: '7px 0px 7px 10px !important'
    },
    innerContent: {
        backgroundcolor: 'white',
        border: '1px solid #ccc',
        borderRadius: '5px 5px 0px 0px',
        height: '200px',
        width: '400px',
        boxsizing: 'border-box'
    },
    Column1: {
        fontFamily: 'Fira Sans, Arial, Helvetica, sans-serif',
        fontSize: '13px',
        fontWeight: 500,
        textAlign: 'right',
        float: 'right'
    },
    dropdownfield: {
        border: '1px solid #757575',
        backgroundColor: '#fff',
        height: '23px',
        width: '95%',
        color: '#353535',
        padding: '2px 6px 2px 2px',
        '&:hover': {
            border: '2px solid #005b92 !important',
            opacity: 1,
        },
        '&:focus': {
            border: '1px solid #000 !important',
            outline: 0,
            boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6)',
        }
    },
    nestedtable: {
        width: '95%',
        borderCollapse: 'collapse'
    },
    textfield: {
        borderRadius: '0px',
        padding: '0px',
        width: '230px !important',
        height: '23px',
        borderColor: 'black',
        borderWidth: 'thin',
        '&:focus': {
            border: '1px solid #000 !important',
            outline: 0,
            boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6) !important',
        },
    },
}));

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#actionPopupdialog"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export const ModifyAdjustmentPopup: VFC<ActionProps> = ((props) => {
    const [open, setOpen] = useState(true);
    const classes = useStyles();
    const [options, setOptions] = useState<any>([]);
    const { action, payload, modalcontainer, OkClick, data } = props;
    console.log(payload);
    console.log(OkClick);

    const handleClose = () => {
        setOpen(false);
        document.body.removeChild(modalcontainer);
    };

    useEffect(() => {
        const fetchDropDownSource = async () => {
            setOptions(data.ddReasonSource);
        };
        fetchDropDownSource();
    }, []);

    const handleButtonClick = async () => {

        const txtAdjustmentAmount = document.getElementById('txtAdjustmentAmount') as HTMLSelectElement | null;
        if (txtAdjustmentAmount?.value.trim() == '') {
            alert('Please enter an Amount.');
            txtAdjustmentAmount?.focus();
            return;
        }

        const reasonsCtrl = document.getElementById("ddlReasons") as HTMLSelectElement | null;
        const narrative = document.getElementById('txtdescription') as HTMLSelectElement | null;
        const ddlNegation = document.getElementById('ddlNegation') as HTMLSelectElement | null;

        const actionParameters: ActionParametersProps = {
            BillCodeGroupBillCodeId: reasonsCtrl?.value ?? '',
            Narrative: narrative?.value,
            AdjustMentAmount: txtAdjustmentAmount?.value ?? '',
            IsNegative: ddlNegation?.value === 'true',
            CurrencySymbol: data.CurrencySymbol,
        };

        await OkClick(action, payload, actionParameters);
        setOpen(false);
        document.body.removeChild(modalcontainer);
    };

    const handleBlur = (event: any) => {
        const value = event.target.value;

        if (value.trim() != '' && value.indexOf('.') == -1) {
            event.target.value = `${value}.00`;
        }
    };

    const NumericFormatCustom = React.forwardRef<HTMLInputElement, CustomProps>(
        function NumericFormatCustom(props, ref) {
            const { onChange, ...other } = props;


            return (
                <NumericFormat
                    {...other}
                    getInputRef={ref}
                    thousandSeparator=","
                    decimalScale={2}
                    valueIsNumericString
                    onBlur={handleBlur}
                    maxLength={22}
                />
            );
        },
    );



    const renderSection = () => {

        const hasPositiveAdjustmentsAction = data.HasPositiveAdjustmentsAction;
        const Amount = data.Amount.toFixed(2);
        return (
            <table style={{ width: '100%' }}>
                <colgroup>
                    <col style={{ width: '35%' }}></col>
                    <col style={{ width: '65%' }}></col>
                </colgroup>
                <tr>
                    <td className={classes.Column1}>
                        Amount:
                    </td>
                    <td>
                        <table className={classes.nestedtable}>
                            <tr>
                                <td>
                                    <select id='ddlNegation' className={classes.dropdownfield} defaultValue={data.ddlNegationSelectedValue} >
                                        <option value="true">-</option>
                                        {hasPositiveAdjustmentsAction && <option value="false">+</option>}
                                    </select>
                                </td>
                                <td>
                                    <TextField id="txtAdjustmentAmount"
                                        data-testid="adjustmentAmount"
                                        value={Amount}
                                        InputProps={{
                                            inputComponent: NumericFormatCustom as any,
                                            className: classes.textfield,
                                            style: { height: '25px', padding: '0px', borderRadius: '0px', direction: 'rtl' }
                                        }}
                                    />
                                </td>
                                <td id='calculatedAmountCell'>
                                    <label id='lblAdjustmentCurrencySymbol' title={data.CurrencyCode}>{data.CurrencySymbol}</label>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td className={classes.Column1}>
                        Reason:
                    </td>
                    <td>
                        <select id='ddlReasons' data-testid='ddlReasons' className={classes.dropdownfield} defaultValue={data.bill_code_group_bill_code_id}>
                            {options.map((option: any, index: any) => (
                                <option key={index} value={option.bill_code_group_bill_code_id}>
                                    {option.code_description}
                                </option>
                            ))}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td className={classes.Column1}>
                        Narrative:
                    </td>
                    <td>
                        <textarea id="txtdescription" data-testid="txtDescription" title='Enter narrative' maxLength={4000} className={classes.textarea}>{data.Narrative}</textarea>
                    </td>
                </tr>
            </table>
        );
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="actionPopupdialog"
                data-testid="actionPopupdialog"
            >
                <DialogTitle className={classes.dialogtitle} id="actionPopupdialog">
                    <span style={{ fontSize: '16px' }} >Invoice Review Action</span>
                    <span className={classes.closeicon} >
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.dialogcontenttext}>
                        <span >&#x25a0; Modify Adjustment</span>
                    </DialogContentText>
                    <div className={classes.innerContent} style={{ width: '100%' }}>
                        {renderSection()}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} className={`${classes.button} ${classes.buttonSecondary}`}>Cancel</Button>
                    <Button onClick={handleButtonClick} data-testid='btnOk' className={classes.button}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

