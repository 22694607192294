import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { ReactElement, Ref, VFC } from 'react';
import { Link } from 'react-router-dom';
import { AppTheme } from '../../../app/appTheme';
import { Tooltip } from '../../../common';
import WkIcon from '../../../icons/wkIcon';
import { ConditionalWrapper } from './conditionalWrapper';

const useStyles = makeStyles<AppTheme>((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        height: '100%',
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none !important',
        whiteSpace: 'nowrap',

        '& $name': {
            color: '#fff !important',
        },
    },
    logo: {
        width: 26,
        height: 26,
    },
    name: {
        fontSize: 22,
        lineHeight: '20px',
        userSelect: 'none',
    },
    tooltipPlacementBottom: {
        marginTop: -parseInt(theme.spacing(1)),
    },
}));

type BrandProps = {
    /**
     * Application name.
     */
    name: string;

    /**
     * URL address for the react routing.
     */
    to?: string;

    /**
     * Title to be used when {@link to} is defined.
     */
    title?: string;
};

export const Brand: VFC<BrandProps> = (props) => {
    const { name, to, title } = props;

    const classes = useStyles();

    const hasLink = !isEmpty(to);

    return (
        <Tooltip title={title || name} classes={{ tooltipPlacementBottom: classes.tooltipPlacementBottom }}>
            <ConditionalWrapper
                className={classes.container}
                condition={hasLink}
                truthyWrapper={(children, props, ref: Ref<HTMLAnchorElement>): ReactElement => (
                    <Link
                        {...props}
                        innerRef={ref}
                        className={classNames(props.className, classes.link)}
                        to={to as string}>
                        {children}
                    </Link>
                )}
                falsyWrapper={(children, props, ref: Ref<HTMLDivElement>): ReactElement => (
                    <div {...props} ref={ref}>
                        {children}
                    </div>
                )}>
                <WkIcon className={classes.logo} />
                <span className={classes.name}>{name}</span>
            </ConditionalWrapper>
        </Tooltip>
    );
};
