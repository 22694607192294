import { useEffect } from 'react';
import {
    manuallyIncrementPromiseCounter,
    manuallyDecrementPromiseCounter,
    manuallyResetPromiseCounter,
    usePromiseTracker,
} from 'react-promise-tracker';

export enum LoaderScope {
    ExternalUI = 'externalUIScreen',
    ViewUI = 'viewUIScreen',
    // add more scopes here when required
}

type UseLoaderProps = {
    scope?: string;
};

const preventMap = new Map<string | 'any', number>();
export const useLoader = ({ scope }: UseLoaderProps) => {
    const { promiseInProgress: isLoading } = usePromiseTracker({ area: scope });
    const preventScope = scope || 'any';

    const preventLoadingOnce = () => {
        const currentPreventValue = preventMap.get(preventScope) || 0;
        preventMap.set(preventScope, currentPreventValue + 1);
    };

    const incrementLoadingCounter = () => {
        const currentPreventValue = preventMap.get(preventScope);
        if (currentPreventValue) {
            preventMap.set(preventScope, currentPreventValue - 1);
            return;
        }
        manuallyIncrementPromiseCounter(scope);
    };

    const decrementLoadingCounter = () => {
        manuallyDecrementPromiseCounter(scope);
    };

    const resetLoadingCounter = () => {
        manuallyResetPromiseCounter(scope);
    };

    useEffect(() => {
        return () => {
            resetLoadingCounter();
        };
    }, []);

    return {
        isLoading,
        incrementLoadingCounter,
        decrementLoadingCounter,
        resetLoadingCounter,
        preventLoadingOnce,
    };
};
