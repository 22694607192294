import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkSpinnerIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkSpinnerIcon" {...props} viewBox="0 0 24 24" x="0px" y="0px">
        <path d="M11.2,6.4h1.6V0h-1.6V6.4z M11.2,24h1.6v-6.4h-1.6V24z M21.1,4.1l-1.1-1.1l-4.5,4.5l1.1,1.1L21.1,4.1z M2.9,19.9l1.1,1.1 l4.5-4.5l-1.1-1.1L2.9,19.9z M2.9,4.1l4.5,4.5l1.1-1.1L4.1,2.9L2.9,4.1z M15.4,16.5l4.5,4.5l1.1-1.1l-4.5-4.5L15.4,16.5z M17.6,11.2 v1.6H24v-1.6H17.6z M6.4,11.2H0v1.6h6.4V11.2z" />
    </SvgIcon>
);

export default WkSpinnerIcon;
