import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { VFC } from 'react';
import { AnnouncementPriority } from '../../../reducers/types';
import { AppTheme } from '../../app';
import { Tooltip } from '../../common';
import WkWarningIcon from '../../icons/wkWarningIcon';

interface AnnouncementPriorityIconProps {
    priority: AnnouncementPriority;
}

const useStyles = makeStyles<AppTheme>((theme) => ({
    iconTooltip: {
        marginTop: theme.spacing(2),
    },
    iconContainer: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(0.25),
        alignSelf: 'baseline',
    },
    highPriorityIcon: {
        fontSize: 16,
        color: theme.palette.warning.main,
    },
    lowPriorityIcon: {
        fontSize: 16,
        color: theme.palette.warning.light,
    },

    mediumPriorityIcon: {
        fontSize: 16,
        color: theme.palette.warning.dark,
    },
}));

export const AnnouncementPriorityIcon: VFC<AnnouncementPriorityIconProps> = (props) => {
    const classes = useStyles();
    const { priority } = props;
    const iconTooltipText =
        (priority === AnnouncementPriority.High && 'High priority') ||
        (priority === AnnouncementPriority.Medium && 'Medium priority') ||
        'Low priority';
    return (
        <Tooltip title={iconTooltipText} placement="bottom" classes={{ tooltipPlacementTop: classes.iconTooltip }}>
            <div className={classes.iconContainer} data-testid="announcement-priorityIcon">
                <WkWarningIcon
                    classes={{
                        root: classNames({
                            [classes.highPriorityIcon]: priority === AnnouncementPriority.High,
                            [classes.mediumPriorityIcon]: priority === AnnouncementPriority.Medium,
                            [classes.lowPriorityIcon]: priority === AnnouncementPriority.Low,
                        }),
                    }}
                />
            </div>
        </Tooltip>
    );
};
