import React, { FC, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorOverlay } from '../../common/errorOverlay';
import { useGlobalErrorHandler } from './useGlobalErrorHandler.hook';

export const AppErrorBoundary: FC = (props) => {
    const GlobalErrorHandler: FC = useCallback(() => {
        useGlobalErrorHandler();

        return <>{props.children}</>;
    }, []);

    return (
        <ErrorBoundary FallbackComponent={ErrorOverlay}>
            <GlobalErrorHandler />
        </ErrorBoundary>
    );
};
