import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { UUIInput } from '../../common/uuiInput';
import { getValidationMessagesForField } from '../itemScreenHelpers';
import { ControlTypeProps } from '../types';
import { ControlLabel } from './controlLabel';
import ReadOnlyControl from './readOnlyControl';

const useStyles = makeStyles({
    radioGroupContainer: {
        marginTop: 10,
    },
    radioGroup: {
        paddingTop: 8,
    },
    radioContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    radioIcon: {
        paddingTop: 1,
        paddingRight: 9,
        paddingBottom: 4,
        paddingLeft: 9,
    },
    radioText: {
        fontSize: 14,
        color: '#474747',
    },
    radioLabelContainer: {
        marginBottom: 3,
    },
    textfieldContainer: {
        marginLeft: 32,
        marginRight: 10,
        marginBottom: 10,
        flexDirection: 'row',
        display: 'grid',
    },
    label: {
        fontWeight: 500,
        marginBottom: 5,
        marginTop: 5,
    },
    root: {
        marginTop: 0,
        width: '50%',
    },
    valueContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 5,
    },
});

enum AmountPickerOptions {
    DecreaseByAmount = 'decreaseByAmount',
    DecreaseByPercentage = 'decreaseByPercentage',
    IncreaseByAmount = 'increaseByAmount',
    IncreaseByPercentage = 'increaseByPercentage',
    SetToAmount = 'setToAmount',
}

const HUNDRED = 100;
type AmountStrategy = (initialValue: number, newValue: number) => number;
const amountPickerData = new Map<AmountPickerOptions, { action: AmountStrategy; title: string; subTitle: string }>([
    [
        AmountPickerOptions.DecreaseByAmount,
        {
            action: (initialValue, newValue) => initialValue - newValue,
            title: 'Decrease by amount',
            subTitle: 'Amount',
        },
    ],
    [
        AmountPickerOptions.DecreaseByPercentage,
        {
            action: (initialValue, newValue) => initialValue - initialValue * (newValue / HUNDRED),
            title: 'Decrease by percentage',
            subTitle: 'Percentage (%)',
        },
    ],
    [
        AmountPickerOptions.IncreaseByAmount,
        {
            action: (initialValue, newValue) => initialValue + newValue,
            title: 'Increase by amount',
            subTitle: 'Amount',
        },
    ],
    [
        AmountPickerOptions.IncreaseByPercentage,
        {
            action: (initialValue, newValue) => initialValue + initialValue * (newValue / HUNDRED),
            title: 'Increase by percentage',
            subTitle: 'Percentage (%)',
        },
    ],
    [
        AmountPickerOptions.SetToAmount,
        {
            action: (_, newValue) => newValue,
            title: 'Set to amount',
            subTitle: 'Amount',
        },
    ],
]);

// eslint-disable-next-line max-lines-per-function
export const RadioAmountChangerControl: FC<ControlTypeProps> = ({ field, fieldData, readOnly }) => {
    const css = useStyles();
    const { register, setValue, errors } = useFormContext();
    const [newNetAmount, setNewNetAmount] = useState(fieldData.displayValue || 0);
    const [radioSelection, setRadioSelection] = useState(AmountPickerOptions.DecreaseByAmount);

    if (readOnly) {
        return <ReadOnlyControl field={field} fieldData={fieldData} />;
    }

    useEffect(() => {
        if (!readOnly) {
            register({ name: field.name });
            setValue(field.name, newNetAmount);
        }
    }, [register, setValue, field.name, readOnly, newNetAmount]);

    const onInputChange = (value: string, action: AmountStrategy): void => {
        setValue(fieldData.inputValue as string, value);
        if (value.length > 0) {
            const initialValue = fieldData.inputValue ? parseFloat(fieldData.inputValue) : 0;
            const newValue = parseFloat(value);
            const result = action(initialValue, newValue);
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            setNewNetAmount(result?.toFixed(2) || 0);
        } else {
            setNewNetAmount(fieldData.displayValue || 0);
        }
    };

    const handleRadioChange = (value: AmountPickerOptions): void => {
        setValue(fieldData.inputValue!, '');
        setRadioSelection(value);
        setNewNetAmount(fieldData.displayValue || 0);
    };

    return (
        <ControlLabel
            field={field}
            control={
                <div className={css.radioGroupContainer}>
                    <RadioGroup
                        data-testid="radio-selector"
                        aria-label="radio-options"
                        value={radioSelection}
                        onChange={(e) => handleRadioChange(e.target.value as AmountPickerOptions)}
                        className={css.radioGroup}>
                        {[...amountPickerData.entries()].map(([key, value], index) => (
                            <div key={index} className={css.radioContainer}>
                                <FormControlLabel
                                    value={key}
                                    control={
                                        <Radio
                                            color="primary"
                                            data-testid={`radio- ${value?.title}`}
                                            className={css.radioIcon}
                                        />
                                    }
                                    label={value?.title}
                                    classes={{
                                        label: css.radioText,
                                        root: css.radioLabelContainer,
                                    }}
                                />
                                {radioSelection === key && (
                                    <FormControl>
                                        <div className={css.textfieldContainer}>
                                            <span className={css.label}>{value?.subTitle}</span>
                                            <UUIInput
                                                ref={register}
                                                name="inputValue"
                                                type="number"
                                                error={Boolean(
                                                    getValidationMessagesForField(field, errors, 'errors').length,
                                                )}
                                                inputProps={{ min: 0 }}
                                                classes={{
                                                    root: css.root,
                                                }}
                                                onChange={(e) => onInputChange(e.target.value, value.action)}
                                            />
                                        </div>
                                    </FormControl>
                                )}
                            </div>
                        ))}
                    </RadioGroup>
                    <div className={css.valueContainer}>
                        <span>New {field.displayName.toString()}</span>
                        <span className={css.label}>{newNetAmount}</span>
                    </div>
                </div>
            }
        />
    );
};
