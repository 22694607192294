import { createBrowserHistory, createMemoryHistory, History, LocationState } from 'history';
import { isUndefined } from 'lodash';
import { getBasePath } from './utils/uriResolver';

// looks like hack workaround. check and remove in the future
// in total it is better to avoid using window global properties
if (isUndefined(window.Props)) {
    window.Props = {};
}

const isJestEnvironment = Props['uiContextPath'] === 'jest';

let _history: History<LocationState>;

// todo: looks like hack workaround. check and remove in the future
//  history should not be created manually. Routing should resolved via decorators
//  https://v5.reactrouter.com/web/api/Router (should be BrowserRouter or MemoryRouter)
export const getHistory = <S extends LocationState>(): History<S> => {
    if (!_history) {
        if (!isJestEnvironment) {
            const basename = getBasePath();

            _history = createBrowserHistory<S>({ basename });
        } else {
            _history = createMemoryHistory<S>();
        }
    }

    return _history as History<S>;
};
