/* eslint-disable max-lines-per-function */
import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, parseISO } from 'date-fns';
import { sanitize } from 'dompurify';
import { last } from 'lodash';
import React, { VFC, MouseEvent, useState, useEffect } from 'react';
import { Announcement, AnnouncementType } from '../../../reducers/types';
import { useAppSelector } from '../../../store';
import { getAppResources } from '../../../store/slices';
import { AppTheme } from '../../app';
import { BaseDialog, BaseDialogProps } from '../../common';
import { AnnouncementPriorityIcon } from '../announcementPriorityIcon';

const useStyles = makeStyles<AppTheme>((theme) => ({
    dialogTitleRoot: {
        fontWeight: 500,
        color: theme.palette.text.secondary,
        wordWrap: 'break-word',
    },
    dialogDateTime: {
        display: 'flex',
        cursor: 'default',
        justifyContent: 'flex-end',
    },
    remainingAnnouncements: {
        marginRight: 'auto',
        cursor: 'default',
    },
    announcementMessageContainer: {
        display: 'inline-flex',
        cursor: 'default',
        flexFlow: 'column',
    },

    announcementAdvancedDesc: {
        paddingTop: theme.spacing(1),
    },

    announcementMessage: {
        height: 329,
        overflow: 'auto',
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        fontSize: theme.typography.fontSize,
    },
}));

export type AnnouncementDialogProps = Omit<BaseDialogProps, 'title' | 'actions'> & {
    announcements: Announcement[];
    onAction?: (announcement: Announcement) => Promise<void>;
};

export const AnnouncementDialog: VFC<AnnouncementDialogProps> = (props) => {
    const classes = useStyles();
    const { announcements, open, onClose, classes: externalClasses, onAction, ...baseDialogProps } = props;
    const { displayedDateTimeFormat } = useAppSelector(getAppResources);
    const [selected, setSelected] = useState<Announcement>(announcements[0]);
    const { subject, priority, body, description, type, date } = selected || {};
    const currentIndex = (selected && announcements.indexOf(selected)) || 0;
    const showDescription = Boolean(type === AnnouncementType.Advanced && description && body);
    const isLastSelected = selected === last(announcements);
    const openDialog = open && Boolean(announcements.length);

    useEffect(() => {
        setSelected(announcements[0]);
    }, [announcements]);

    const buttonAction = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        await onAction?.(selected);

        if (isLastSelected) {
            onClose?.(event, 'onAction');
        } else {
            setSelected(announcements[currentIndex + 1]);
        }
    };

    const renderActionButton = () =>
        !isLastSelected ? (
            <Button
                disableRipple
                onClick={buttonAction}
                color="primary"
                variant="contained"
                data-testid="announcement-buttonNext">
                Next
            </Button>
        ) : (
            <Button
                disableRipple
                color="primary"
                variant="contained"
                onClick={buttonAction}
                data-testid="announcement-buttonDone">
                Done
            </Button>
        );

    const message = sanitize((type === AnnouncementType.Advanced && body) || description, {
        RETURN_TRUSTED_TYPE: true,
    }) as unknown as string;

    return (
        <BaseDialog
            {...baseDialogProps}
            fullWidth
            open={openDialog}
            classes={{
                ...externalClasses,
            }}
            hideCancel={true}
            data-testid={`${type}-announcementDialog`}
            onClose={onClose}
            title={'Announcements'}
            actions={
                <>
                    {announcements.length > 1 && (
                        <Typography
                            classes={{
                                root: classes.remainingAnnouncements,
                            }}
                            data-testid="announcement-count">
                            {`${currentIndex + 1} of ${announcements.length}`}
                        </Typography>
                    )}
                    {renderActionButton()}
                </>
            }>
            <Grid container className={classes.announcementMessageContainer}>
                <Grid item classes={{ root: classes.dialogDateTime }}>
                    <Typography data-testid="announcement-dateTime">
                        {date && format(parseISO(date), displayedDateTimeFormat)}
                    </Typography>
                </Grid>
                <Grid container direction="row" wrap="nowrap">
                    <Grid item>
                        <AnnouncementPriorityIcon priority={priority} />
                    </Grid>
                    <Grid item zeroMinWidth>
                        <Typography classes={{ root: classes.dialogTitleRoot }} data-testid="announcement-title">
                            {subject}
                        </Typography>
                    </Grid>
                </Grid>

                {showDescription && (
                    <Grid item classes={{ root: classes.announcementAdvancedDesc }}>
                        <Typography data-testid="announcement-advancedDescription">{description}</Typography>
                    </Grid>
                )}
            </Grid>
            <Box dangerouslySetInnerHTML={{ __html: message }} className={classes.announcementMessage} />
        </BaseDialog>
    );
};
