import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';

export const WkZoomInMagnifyGlass = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon data-testid="wkZoomInMagnifyGlass" viewBox="-3 -3 21 21" {...props} ref={ref}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9231 10.0769C11.7692 9 12.3077 7.69231 12.3077 6.15385C12.3077 2.76923 9.53846 0 6.15385 0C2.76923 0 0 2.76923 0 6.15385C0 9.53846 2.76923 12.3077 6.15385 12.3077C7.61538 12.3077 9 11.7692 10.0769 10.9231L15.1538 16L16 15.1538L10.9231 10.0769ZM6.15286 11.077C3.46055 11.077 1.22978 8.8462 1.22978 6.15389C1.22978 3.46158 3.46055 1.23081 6.15286 1.23081C8.84516 1.23081 11.0759 3.46158 11.0759 6.15389C11.0759 8.8462 8.84516 11.077 6.15286 11.077ZM6.76725 5.53843V2.46151H5.53648V5.53843H2.45956V6.7692H5.53648V9.84613H6.76725V6.7692H9.84418V5.53843H6.76725Z"
            fill="currentColor"
        />
    </SvgIcon>
));

WkZoomInMagnifyGlass.displayName = 'WkZoomInMagnifyGlass';
