import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import CommentIcon from '@mui/icons-material/Comment';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import MailIcon from '@mui/icons-material/Mail';
import React from 'react';

interface IListScreenIconProps {
    iconName: string;
    screenId?: number;
    documentId?: number;
    fileName?: string;
    applicationDownloadDocumentUrl?: string;
}

const ListScreenIcon: React.FC<IListScreenIconProps> = ({ iconName }) => {
    switch (iconName) {
        case 'iconError':
            return <ErrorIcon />;
        case 'iconComment':
            return <CommentIcon />;
        case 'iconPencil':
            return <EditIcon />;
        case 'iconEmail':
            return <MailIcon />;
        default:
            return <BrokenImageIcon />;
    }
};

export default ListScreenIcon;
