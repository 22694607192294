import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../types';

export type PowerBIState = {
    embedTokenStatus: EmbedTokenStatus;
    embedToken?: EmbedToken;
    reportKeys?: string[];
    reportFiltersData?: ReportFiltersData;
    dashboardKeys?: string[];
};

export type EmbedToken = {
    token: string;
    expirationUtc: string;
    reportKeys: string[];
};

export enum EmbedTokenStatus {
    Idle,
    PendingRequest,
    Requested,
    Obtained,
    NotAvailable,
    Failed,
}

export type ReportDetail = {
    key: string;
    title: string;
    filters: ReportFilters[];
    metadata: ReportMetadata;
};

export type ReportFilters = {
    name: string;
    valueFetchType: string;
    valueType: string;
    dataType: string;
    targetColumn: string;
    targetTable: string;
};

export type ReportMetadata = {
    embedUrl: string;
    workspaceId: string;
    workspaceName: string;
    reportType: string;
    serverReportId: string;
};

export type ReportFiltersData = {
    invoiceStatusIds: number[];
    invoiceDateType: string;
    currencyId: number;
    timeZone: string;
};

const initialState: PowerBIState = { embedTokenStatus: EmbedTokenStatus.Idle };

const powerBI = createSlice({
    name: 'power-bi',
    initialState,
    reducers: {
        setEmbedToken: (state, action: PayloadAction<{ token: EmbedToken }>) => {
            state.embedToken = action.payload.token;
            state.embedTokenStatus = EmbedTokenStatus.Obtained;
        },
        setEmbedTokenStatus: (state, action: PayloadAction<{ status: EmbedTokenStatus }>) => {
            state.embedTokenStatus = action.payload.status;
        },
        setReportKeys: (state, action: PayloadAction<{ reportKeys: string[] | undefined }>) => {
            state.reportKeys = action.payload.reportKeys;
        },
        setReportFiltersData: (state, action: PayloadAction<{ reportFiltersData: ReportFiltersData | undefined }>) => {
            state.reportFiltersData = action.payload.reportFiltersData;
        },
        setDashboardKeys: (state, action: PayloadAction<{ dashboardKeys: string[] | undefined }>) => {
            state.dashboardKeys = action.payload.dashboardKeys;
        },
    },
});

export const selectEmbedToken = (state: AppState): EmbedToken | undefined => state.powerBI.embedToken;
export const selectEmbedTokenStatus = (state: AppState): EmbedTokenStatus => state.powerBI.embedTokenStatus;
export const selectReportKeys = (state: AppState): string[] | undefined => state.powerBI.reportKeys;
export const selectReportFiltersData = (state: AppState): ReportFiltersData | undefined =>
    state.powerBI.reportFiltersData;
export const selectDashboardKeys = (state: AppState): string[] | undefined => state.powerBI.dashboardKeys;

// Actions & Reducer
export type PowerBIActions = TakeActions<typeof powerBI.actions>;
export const { setEmbedToken, setEmbedTokenStatus, setReportKeys, setReportFiltersData, setDashboardKeys } =
    powerBI.actions;
export const powerBIReducer = powerBI.reducer;
