import { makeStyles } from '@mui/styles';
import React, { FC, useEffect, useState } from 'react';
import { ComponentType, ContentHolder } from '../../store/slices';
import { AppTheme } from '../app';
import { SearchView } from '../viewScreen/views/filterView';
import { MultiNestingGridView } from '../viewScreen/views/multiNestingGridView/multiNestingGridView.component';
import { useGridScreen } from './hooks/useGridScreen.hook';

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        ...theme.styles.page.root,
        overflowY: 'auto',
        display: 'contents',
    },
    header: {
        ...theme.styles.page.header,
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    homeContent: {
        ...theme.styles.page.content,
        flexDirection: 'column',
        opacity: 0,
        padding: 'initial',
        border: 'none',
        backgroundColor: 'transparent',
        zIndex: 0,
        minHeight: 0,

        '&>*:not(:first-child)': {
            marginTop: theme.spacing(2),
        },
    },
    regularContent: {
        ...theme.styles.page.content,
        flexDirection: 'column',
        opacity: 0,
        padding: 'initial',
        borderWidth: '1px',
        borderColor: '#DADADA',
        borderStyle: 'solid',
        backgroundColor: 'white',
        minHeight: 0,
        minWidth: 1000,

        '&>*:not(:first-child)': {
            marginTop: theme.spacing(2),
        },
    },
    contentLoaded: {
        opacity: 1,
    },
    headerLinkContainer: {
        paddingRight: theme.spacing(2),
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        marginRight: theme.spacing(2),
    },
    headerLink: {
        cursor: 'pointer',
        fontSize: theme.typography.fontSize,
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
}));

// eslint-disable-next-line max-lines-per-function
export const GridScreen: FC = () => {
    const classes = useStyles();
    const { pageState } = useGridScreen();
    const { title, data: pageData } = pageState;
    const [sharedStorage, setSharedStorage] = useState<Record<string, unknown>>({});

    useEffect(() => {
        document.title = title;
    }, [title]);

    const onSetSharedStorage = (key: string, value: unknown): void => {
        const newSharedStorage = { ...sharedStorage };
        newSharedStorage[key] = value;
        setSharedStorage(newSharedStorage);
    };

    const renderComponents = (contentHolder: ContentHolder): JSX.Element => (
        <>
            {pageData?.components
                ?.filter((component) => component.contentHolder === contentHolder)
                ?.map((component, index) => {
                    const { type } = component;

                    if (type === ComponentType.GridComponent) {
                        return (
                            <MultiNestingGridView
                                component={component}
                                sharedStorage={sharedStorage}
                                key={index}
                                hasFilter={Boolean(
                                    pageData.components?.find(
                                        (item) =>
                                            item.type === ComponentType.FilterComponent &&
                                            item.dataSource === component.dataSource,
                                    ),
                                )}
                            />
                        );
                    }
                    if (type === ComponentType.FilterComponent) {
                        return <SearchView component={component} onSetSharedStorage={onSetSharedStorage} key={index} />;
                    }
                    return;
                })}
        </>
    );

    return (
        <>
            <div className={classes.root}>{renderComponents(ContentHolder.PageContent)}</div>
        </>
    );
};
