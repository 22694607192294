import { forwardRef, HTMLAttributes, PropsWithChildren, ReactElement, ReactNode, Ref } from 'react';

type WrapperFn = (
    children: ReactNode,
    props: Pick<HTMLAttributes<HTMLElement>, 'className'>,
    ref: Ref<never>,
) => ReactElement;

export type ConditionalWrapperProps = {
    /**
     * Condition for choosing a wrapper.
     */
    condition: boolean;

    /**
     * The wrapper that is used when condition is `true`.
     */
    truthyWrapper: WrapperFn;

    /**
     * The wrapper that is used when condition is `false`.
     */
    falsyWrapper: WrapperFn;
} & Pick<HTMLAttributes<HTMLElement>, 'className'>;

export const ConditionalWrapper = forwardRef<never, PropsWithChildren<ConditionalWrapperProps>>((props, ref) => {
    const { condition, truthyWrapper, falsyWrapper, children, ...childrenProps } = props;

    const foundWrapperFn = condition ? truthyWrapper : falsyWrapper;
    return foundWrapperFn(children, childrenProps, ref);
});
ConditionalWrapper.displayName = 'ConditionalWrapper';
