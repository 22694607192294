import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import {
    AppBar,
    Dialog,
    IconButton,
    Slide,
    Toolbar,
    Typography,
    DialogContent,
    AppBarProps,
    DialogActions,
    Tooltip,
    SlideProps,
} from '@mui/material';
import React from 'react';
import css from './fullScreenDialog.module.scss';
import UUIButton from './uuiButton';

interface IFullScreenDialog {
    testId?: string;
    isOpen: boolean;
    // function prop to set isOpen to false
    onClose: () => void;
    // prop to actually remove the dialog from the dom if needed after close animation finishes
    onExited?: () => void;
    headerText?: string;
    AppBarProps?: Partial<AppBarProps>;
    className?: string;
    showAppHeader?: boolean;
    variant?: 'normal' | 'green';
}

const FullScreenDialog: React.FC<IFullScreenDialog> = ({
    testId,
    isOpen,
    onClose,
    onExited,
    headerText,
    children,
    AppBarProps,
    className,
    showAppHeader = false,
    variant = 'normal',
}) => (
    <Dialog
        data-testid={testId}
        fullScreen
        disableEnforceFocus
        className={`${css.dialogRoot} ${showAppHeader ? css.appHeader : ''}`}
        open={isOpen}
        TransitionProps={{
            onExited: () => onExited?.(),
        }}
        onClose={onClose}
        TransitionComponent={Transition}
        PaperProps={{ 'aria-label': 'Full Screen Dialog' }}
        classes={{ paper: `${css.dialogBody} ${variant === 'green' ? css.dialogPaperGreen : ''} ${className}` }}
        hideBackdrop={true}>
        {!showAppHeader && (
            <AppBar
                className={variant === 'green' ? css.greenDialogAppBar : css.dialogAppBar}
                elevation={variant === 'green' ? 0 : undefined}
                {...AppBarProps}>
                <Toolbar className={css.toolbar}>
                    <IconButton
                        className={css.iconStartButton}
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                        data-testid="dialogLeftChevronBtn"
                        size="large">
                        <ChevronLeftIcon className={css.iconChevron} />
                    </IconButton>
                    <Tooltip
                        enterDelay={500}
                        placement="bottom-start"
                        title={headerText || ''}
                        classes={{
                            tooltip: css.tooltip,
                        }}>
                        <Typography variant="h6" className={css.dialogTitle} data-testid="fullscreendialog-title">
                            {headerText}
                        </Typography>
                    </Tooltip>
                    <IconButton
                        className={css.iconCloseButton}
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                        data-testid="dialogCloseBtn"
                        size="large">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
        )}
        {children}
    </Dialog>
);

interface IFullScreenDialogButton {
    variant: 'contained' | 'outlined';
    onClick: () => void;
    testId: string;
    className?: string;
}

interface IFullScreenDialogContent {
    className?: string;
}

export const FullScreenDialogContent: React.FC<IFullScreenDialogContent> = ({ children, className }) => (
    <DialogContent className={css.dialogContent} classes={{ root: className }}>
        {children}
    </DialogContent>
);

export const FullScreenDialogActions: React.FC = ({ children }) => (
    <DialogActions className={css.dialogActions}>{children}</DialogActions>
);

export const FullScreenDialogButton: React.FC<IFullScreenDialogButton> = ({ variant, onClick, children, testId }) => (
    <UUIButton
        data-testid={testId}
        variant={variant}
        color="primary"
        size="large"
        className={css.button}
        onClick={onClick}>
        {children}
    </UUIButton>
);

const Transition = React.forwardRef(function Transition(props: SlideProps, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default FullScreenDialog;
