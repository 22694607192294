import { Tooltip } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip';
import React from 'react';
import { useListScreenState } from './context/listScreenContext';
import { getBulkOperations } from './listScreenHelpers';
import { TOOLTIP_CHARACTER_LENGTH } from './listViewHelpers';
import css from './listViewItemTextRow.module.scss';
import { LeftColumnWidth } from './types';

type TextRowClasses = {
    textContent: string;
};

interface IListItemTextRowProps {
    rowIndex: number;
    leftComponent?: React.ReactElement;
    leftText: string;
    leftTextId?: string;
    rightComponent?: React.ReactElement;
    rightText: string;
    fontSize?: string;
    leftColumnWidth: LeftColumnWidth;
    classes?: TextRowClasses;
}

const ListViewItemTextRow: React.FC<IListItemTextRowProps> = React.memo(
    ({ rowIndex, leftComponent, leftText, leftTextId, rightComponent, rightText, leftColumnWidth, classes }) => {
        // we will display a &nbsp; when blank text is passed in to preserve layout.
        const leftTextContent = leftText.length == 0 ? '\u00A0' : leftText;
        const rightTextContent = rightText.length == 0 ? '\u00A0' : rightText;
        const listScreenState = useListScreenState();
        const metadata = listScreenState.metadata!;
        const bulkOperations = getBulkOperations(metadata, listScreenState.mode);

        let leftTextRowClasses;
        let rightTextRowClasses = css.rightTextContent;

        if (leftColumnWidth === '100%') {
            leftTextRowClasses = css.textContent;
        } else {
            leftTextRowClasses = css.leftTextContent;
        }

        if (classes && classes.textContent) {
            leftTextRowClasses = classes.textContent + ' ' + leftTextRowClasses;
            rightTextRowClasses = classes.textContent + ' ' + rightTextRowClasses;
        }

        const labelId = leftTextId ? { id: leftTextId } : {};
        let rightColumnWidth;
        switch (leftColumnWidth) {
            case '100%':
                rightColumnWidth = '0%';
                break;
            case '50%':
                rightColumnWidth = '50%';
                break;
            default:
                rightColumnWidth = '50%';
        }

        const defaultTestId = 0;
        return (
            <div className={bulkOperations.length > 0 ? css.rowContainer : css.noCheckBoxRowContainer}>
                <div
                    {...labelId}
                    className={leftTextRowClasses}
                    style={{ width: leftColumnWidth }}
                    data-testid={`listViewText_${
                        rowIndex == 0
                            ? `${defaultTestId}`
                            : rowIndex == 1
                            ? `${defaultTestId + 2}`
                            : `${defaultTestId + 4}`
                    }`}>
                    <ListItemTooltip title={leftText} placement={rowIndex == 0 ? 'top-start' : 'bottom-start'}>
                        {leftComponent ? leftComponent : leftTextContent}
                    </ListItemTooltip>
                </div>
                {rightColumnWidth !== '0%' && (
                    <div
                        className={rightTextRowClasses}
                        style={{ width: rightColumnWidth }}
                        data-testid={`listViewText_${
                            rowIndex == 0
                                ? `${defaultTestId + 1}`
                                : rowIndex == 1
                                ? `${defaultTestId + 3}`
                                : `${defaultTestId + 5}`
                        }`}>
                        <ListItemTooltip title={rightText} placement={rowIndex == 0 ? 'top-start' : 'bottom-start'}>
                            {rightComponent ? rightComponent : rightTextContent}
                        </ListItemTooltip>
                    </div>
                )}
            </div>
        );
    },
);
ListViewItemTextRow.defaultProps = {
    fontSize: '16px',
};
ListViewItemTextRow.displayName = 'ListItemTextRow';

interface IListItemTooltip {
    title: string;
    placement: TooltipProps['placement'];
    children: React.ReactElement | string;
}

export const ListItemTooltip: React.FC<IListItemTooltip> = ({ title, placement, children }) => (
    <Tooltip
        enterDelay={500}
        title={title.length > TOOLTIP_CHARACTER_LENGTH ? title.substring(0, TOOLTIP_CHARACTER_LENGTH) + '...' : title}
        classes={{
            tooltip: css.tooltip,
        }}
        placement={placement}>
        <span>{children}</span>
    </Tooltip>
);

export default ListViewItemTextRow;
