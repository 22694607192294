import { ItemScreenViewState } from '../itemScreen/types';
import {
    IFolderedListBreadCrumb,
    IListScreenPageData,
    IListScreenPostObject,
    IParentItemInfo,
} from '../listScreen/types';

export type ScreenMode = 'add' | 'edit' | 'show';
export type TargetScreenMode = 'Add' | 'Edit' | 'Show';

export enum TargetScreenType {
    List = 'list',
    Item = 'item',
    Legacy = 'legacy',
    View = 'view',
}

export enum ActionType {
    Server = 'Server',
    Client = 'Client',
}

export enum PagingType {
    None = 'None',
    Pages = 'Pages',
    Scrolling = 'Scrolling',
}

export enum ControlType {
    TextBox = 'TextBox',
    DropDown = 'DropDown',
    DropDownWithTextBox = 'DropDownWithTextBox',
    DateWithDropDown = 'DateWithDropDown',
    CheckBox = 'CheckBox',
}

// add new valid context layer operation names to both the type and the array
export type ContextLayerOperationName =
    | 'UUI Lock Document'
    | 'UUI Unlock Document'
    | 'UUI Download Document'
    | 'UUI Show Document'
    | 'UUI Add Document'
    | 'UUI Check In Document'
    | 'UUI Update Document'
    | 'UUI Edit Document'
    | 'UUI Edit Folder'
    | 'UUI Add Folder Command'
    | 'UUI Delete Document'
    | 'UUI Delete Folder'
    | 'UUI Delete Email';

export const CONTEXT_LAYER_OPERATION_NAMES: ContextLayerOperationName[] = [
    'UUI Lock Document',
    'UUI Unlock Document',
    'UUI Download Document',
    'UUI Show Document',
    'UUI Add Document',
    'UUI Check In Document',
    'UUI Update Document',
    'UUI Edit Document',
    'UUI Edit Folder',
    'UUI Add Folder Command',
    'UUI Delete Document',
    'UUI Delete Folder',
    'UUI Delete Email',
];

// this should not be directly used. Use IListScreenOperation or IItemScreenOperation instead
export interface IBaseOperation {
    name: string;
    isDisplayOnAdd: boolean;
    isDisplayOnEdit: boolean;
    isDisplayOnShow: boolean;
    displayName: string;
    id: number;
    targetScreenId?: number;
    targetScreenName?: string;
    targetScreenMode?: TargetScreenMode;
    targetScreenRenderingStyle?: TargetScreenRenderingStyle;
    targetScreenType?: TargetScreenType | 'list' | 'item';
    isLinkToParent?: boolean;
    iconName?: string | null;
    linkUrl?: string;
    desktopLink?: string;
    contextLayerOperationName?: ContextLayerOperationName;
    // the following are not used in the UUI yet but are here to make typescript happy for Passport mocks
    type?: string;
    confirmationMessage?: string;
    isCloseModal?: boolean;
    operationContext?: string;
    isBrowserBackOnComplete?: boolean;
    noOp?: boolean; // to capture operations like Navigate and Switch to Edit mode so we dont refresh UI
    url?: string;
}

export type UUIHistoryState = IUUIHistoryState | undefined;

export interface IUUIHistoryState {
    listScreen?: UUIHistoryListState;
    itemScreen?: UUIHistoryItemState;
    isChangingTabs?: boolean;
}

export interface UUIHistoryListState {
    postObject: IListScreenPostObject | undefined;
    listMetadataUrl?: string;
    listDataUrl?: string;
    parentItemInfo?: IParentItemInfo;
    baselinePageData?: IListScreenPageData;
    breadCrumbs?: IFolderedListBreadCrumb[];
}

export interface UUIHistoryItemState {
    viewState: ItemScreenViewState;
}

export type CancellablePromise<T> = {
    promise: Promise<T>;
    cancel: () => void;
};

export type TargetScreenRenderingStyle = 'Normal' | 'PopUp' | 'Inline' | 'MainWindow';
export type ScreenRenderingStyle = 'normal' | 'popup' | 'inline' | 'mainwindow';

export interface IAssociatedEntityInfo {
    associatedEntityType?: string;
    associatedEntityId?: string;
    associatedEntityTypeId?: string;
    associatedEntityName?: string;
}

export enum FullScreenOverlayIconEnum {
    DOWNLOAD = 'iconclouddownarrow',
    CHECKMARK = 'iconcloudcheckmark',
    EXCLAMATION = 'icontriangleexclamation',
}
