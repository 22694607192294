import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { FC } from 'react';
import { AppTheme } from '../../../../app';

const useStyles = makeStyles<AppTheme>((theme) => ({
    field: {
        display: 'grid',
        gridTemplateColumns: '160px 1fr',
        gap: theme.spacing(),
        padding: theme.spacing(0, 2),
        boxSizing: 'border-box',
    },
    name: {
        fontSize: '0.95rem',
        fontFamily: theme.typography.fontFamily,
        lineHeight: '18px',
        color: '#757575',
        textAlign: 'right',
        minWidth: '160px',
        minHeight: '23px',
        marginTop: '4px',
    },
}));

export type FieldProps = {
    name?: string;
};

export const Field: FC<FieldProps> = (props) => {
    const { name, children } = props;
    const classes = useStyles();
    return (
        <div className={classNames(classes.field)}>
            {name && <div className={classNames(classes.name)}>{name + ':'}</div>}
            {children}
        </div>
    );
};
