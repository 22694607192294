import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkWorkToDo = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkAddIcon" viewBox="0 0 25 25" {...props}>
        <g id="Group_10965" data-name="Group 10965" transform="translate(-3216 -911)">
            <rect
                id="Rectangle_3452"
                data-name="Rectangle 3452"
                width="25"
                height="25"
                transform="translate(3216 911)"
                fill="none"
            />
            <path
                id="Work_to_do_icon"
                data-name="Work to do icon"
                d="M17.5,5.333V2H7.5V5.333H0V23.667H25V5.333ZM9.167,3.667h6.667V5.333H9.167ZM19.167,7V22H5.833V7ZM1.667,7h2.5V22h-2.5ZM23.333,22h-2.5V7h2.5Z"
                transform="translate(3216 911)"
                fill="currentColor"
            />
        </g>
    </SvgIcon>
);
