import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkDragHandle: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="WkDragHandle" viewBox="-3 0 21 21" width="20" {...props}>
        <path d="M0 7.19238H16" stroke="currentColor" />
        <path d="M0 10.2891H16" stroke="currentColor" />
        <path d="M0 13.3867H16" stroke="currentColor" />
        <path d="M7.78603 0L13.094 3H2.47803L7.78603 0Z" fill="currentColor" />
        <path d="M7.78599 20.6729L2.47799 17.6729H13.094L7.78599 20.6729Z" fill="currentColor" />
    </SvgIcon>
);
