/* eslint-disable max-lines-per-function */
import { Accordion, AccordionDetails, AccordionSummary, Grid, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, parseISO } from 'date-fns';
import { orderBy, take } from 'lodash';
import React, { useRef, useState, VFC } from 'react';
import { Announcement } from '../../../reducers/types';
import { useAppSelector } from '../../../store';
import { getAppResources } from '../../../store/slices';
import { AppTheme } from '../../app';
import { AnnouncementDialog } from '../announcementDialog';
import { AnnouncementPriorityIcon } from '../announcementPriorityIcon';

const useStyles = makeStyles<AppTheme>((theme) => {
    return {
        root: {
            border: `1px solid ${theme.palette.grey[300]}`,
            boxShadow: 'none',
            background: theme.palette.background.secondary,
        },
        announcementDetails: {
            maxWidth: '1080px',
            flexWrap: 'nowrap',
            paddingTop: theme.spacing(2.75),
            '&:first-child': {
                paddingTop: theme.spacing(1.75),
            },
        },
        announcements: {
            padding: theme.spacing(0),
            margin: theme.spacing(1, 2),
            background: theme.palette.background.secondary,
        },
        announcementsHeading: {
            cursor: 'default',
            whiteSpace: 'nowrap',
            alignItems: 'center',
        },
        totalAnnouncements: {
            fontWeight: 500,
            fontSize: theme.typography.subtitle1.fontSize,
            width: '100%',
            color: theme.palette.text.secondary,
            lineHeight: 1.5,
        },
        link: {
            cursor: 'pointer',
            fontSize: theme.typography.fontSize,
        },
        announcementDetailsContainer: {
            backgroundColor: theme.palette.background.default,
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(2.5),
            paddingLeft: theme.spacing(3),
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            maxHeight: 200,
            overflow: 'auto',
            borderTop: `1px solid ${theme.palette.grey[300]}`,
        },
        announcementDateTime: {
            marginLeft: 'auto',
            whiteSpace: 'nowrap',
            fontWeight: 500,
            fontSize: theme.typography.fontSize,
        },
        expanded: {
            minHeight: 0,
        },
        announcementMessage: {
            overflow: 'hidden',
            display: '-webkit-box',
            boxOrient: 'vertical',
            lineClamp: 3,
            overflowWrap: 'anywhere',
            marginTop: theme.spacing(0.5),
        },
        announcementTitle: {
            cursor: 'pointer',
            fontWeight: 500,
            fontSize: theme.typography.fontSize,
            wordWrap: 'break-word',
        },
    };
});

const MAX_ANNOUNCEMENTS_TO_SHOW = 10;

type AnnouncementProps = {
    announcements?: Announcement[];
};

export const AnnouncementsPane: VFC<AnnouncementProps> = (props) => {
    const [expand, setExpand] = useState(false);
    const classes = useStyles();
    const { announcements } = props;
    const { displayedDateTimeFormat } = useAppSelector(getAppResources);
    const ref = useRef({});
    const toggleAnnouncements = () => {
        setExpand((open) => !open);
    };
    const [modalAnnouncements, setModalAnnouncements] = useState<Announcement[]>([]);
    const sortedAnnouncements = take(orderBy(announcements, 'date', 'desc'), MAX_ANNOUNCEMENTS_TO_SHOW);

    return (
        <>
            <Accordion classes={{ root: classes.root }} expanded={expand} data-testid="announcementAccordion">
                <AccordionSummary
                    classes={{
                        root: classes.announcements,
                        content: classes.announcementsHeading,
                        expanded: classes.expanded,
                    }}>
                    <Typography classes={{ root: classes.totalAnnouncements }} print-key="printKey" data-testid="announcementsTotalCount">
                        {`Announcements (${sortedAnnouncements.length})`}
                    </Typography>
                    {Boolean(sortedAnnouncements.length) && (
                        <Link
                            onClick={toggleAnnouncements}
                            classes={{ root: classes.link }}
                            data-testid="announcementAccordionToggler">
                            {expand ? 'Hide All' : 'Show All'}
                        </Link>
                    )}
                </AccordionSummary>
                <AccordionDetails
                    classes={{ root: classes.announcementDetailsContainer }}
                    announcement-print-scroller="announcementPrintScroller">
                    {sortedAnnouncements.map((item, index) => (
                        <Grid key={index} container classes={{ root: classes.announcementDetails }} direction="row">
                            <Grid item>
                                <AnnouncementPriorityIcon priority={item.priority} key={index} />
                            </Grid>
                            <Grid container direction="column">
                                <Grid container wrap="nowrap">
                                    <Grid item zeroMinWidth>
                                        <Link
                                            onClick={() => {
                                                setModalAnnouncements([item]);
                                            }}
                                            classes={{ root: classes.announcementTitle }}
                                            data-testid="announcementTitle">
                                            {item.subject}
                                        </Link>
                                    </Grid>

                                    <Typography
                                        classes={{ root: classes.announcementDateTime }}
                                        data-testid="announcementDateTime">
                                        {format(parseISO(item.date), displayedDateTimeFormat)}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        classes={{ root: classes.announcementMessage }}
                                        ref={(el) => (ref.current[index] = el)}>
                                        {item.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </AccordionDetails>
            </Accordion>
            <AnnouncementDialog
                open={Boolean(modalAnnouncements.length)}
                announcements={modalAnnouncements}
                onClose={() => setModalAnnouncements([])}
            />
        </>
    );
};
