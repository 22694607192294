import { getLogger } from '@wk/elm-uui-common';
import { CHMessageData, CHMessagingScope } from '@wk/elm-uui-context-handler';
import { useEffect, useState } from 'react';
import { manuallyIncrementPromiseCounter } from 'react-promise-tracker';
import { useNetwork } from '../../../../hooks';
import { apiFetch } from '../../../../utils/fetchUtils';
import { getBasePath } from '../../../../utils/uriResolver';
import { config } from '../../config';
import { MessageService, MessageType, NetworkStateMessage } from '../../infrastructure/messageService';
import { SignInArgs, getUserManager } from '../../infrastructure/oidc';
import { OidcStorage } from '../../infrastructure/oidcStorage';

const getLog = () => getLogger('t360.useNetworkSwitch.hook');

export const useNetworkSwitch = () => {
    const [isSwitchTriggered, setIsSwitchTriggered] = useState<boolean>();
    const [messageService, setMessageService] = useState<MessageService>();
    const { currentNetwork } = useNetwork();

    useEffect(() => {
        setMessageService(MessageService.getInstance);
    }, []);

    useEffect(() => {
        const networkMessageHandler = (data: CHMessageData): void => {
            getLog().debug(`Receiving message: ${JSON.stringify(data)}`);

            switch (data.type) {
                case MessageType.NetworkSwitch:
                case MessageType.NetworkState: {
                    const message = JSON.parse(data.message) as NetworkStateMessage;
                    checkNetworkState(message?.networkId);
                    return;
                }
                case MessageType.ProceedWithNetworkSwitch: {
                    void continueSwitchNetwork();
                    return;
                }
                case MessageType.CancelNetworkSwitch: {
                    cancelSwitchNetwork();
                    return;
                }
            }
        };
        const subscription = messageService?.subscribe(networkMessageHandler);

        return () => {
            subscription?.unsubscribe();
        };
    }, [messageService, currentNetwork, isSwitchTriggered]);

    useEffect(() => {
        if (!messageService || !currentNetwork) {
            return;
        }

        messageService.notify({
            message: { networkId: currentNetwork.networkId },
            type: MessageType.NetworkState,
            scope: CHMessagingScope.OtherInstances,
        });
    }, [messageService, currentNetwork]);

    const getNewNetworkSingInArgs = (networkId: string, tempLoginSessionId: string): SignInArgs => {
        const type = OidcStorage.isHybridMode() ? `login_from_environment:hybrid` : `login_from_environment:oc`;
        const acrValues = [
            type,
            `action:networkSwitch`,
            `network_id:${networkId}`,
            `temp_param_id:${tempLoginSessionId}`,
        ];

        return {
            prompt: 'login',
            acr_values: acrValues.join(' '),
        };
    };

    const responseHandler = async (response: Response): Promise<void> => {
        const networkId = new OidcStorage().getNetworkSwitchId();
        if (!networkId) {
            return;
        }

        const responseData = await response.text();
        const singInArgs = getNewNetworkSingInArgs(networkId, responseData);
        await getUserManager().signinRedirect(singInArgs);
    };

    const continueSwitchNetwork = async (): Promise<void> => {
        if (!isSwitchTriggered) {
            return;
        }

        setIsSwitchTriggered(false);
        const accessToken = (await getUserManager().getUser())?.access_token;
        if (!accessToken) {
            return;
        }

        const requestUrl = `${config.get('REACT_APP_IDENTITY_URL')}/Account/NetworkSwitchTo`;
        await apiFetch<void>(requestUrl, accessToken, { responseCallbackFn: responseHandler });
    };

    const cancelSwitchNetwork = () => {
        setIsSwitchTriggered(false);
        new OidcStorage().removeNetworkSwitchId();
    };

    const checkNetworkState = (networkId: number): void => {
        if (currentNetwork && currentNetwork?.networkId !== networkId) {
            // TODO: react router must be used by the component instead
            window.location.replace(getBasePath());
        }
    };

    const switchNetwork = (networkId: number): void => {
        getLog().debug('switchNetwork triggered by UUI');

        manuallyIncrementPromiseCounter();
        new OidcStorage().setNetworkSwitchId(networkId.toString());
        setIsSwitchTriggered(true);
        messageService?.notify({ type: MessageType.NetworkSwitch });
    };

    return {
        switchNetwork,
    };
};
