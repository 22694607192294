import { useEffect, useState } from 'react';
import { SummaryComponent, SummaryComponentSource, SummaryComponentSourceViewData } from '../../../../../store/slices';
import { apiFetch } from '../../../../../utils/fetchUtils';

type UseViewScreenProps = {
    component: SummaryComponent;
};

export const useSummaryView = (props: UseViewScreenProps) => {
    const { component } = props;
    const { apiContextRoot, apiContextPath } = window.Props as Record<string, string>;
    const [data, setData] = useState<SummaryComponentSourceViewData[]>();
    useEffect(() => {
        if (!component.dataSource) {
            return;
        }

        const summaryDataUrl = `${apiContextRoot}${apiContextPath}/view/component/SummaryComponent/getSource?dataSource=${component.dataSource}&entityId=${component.entityId}`;
        const fetchData = async (): Promise<void> => {
            const value = await apiFetch<SummaryComponentSource>(summaryDataUrl);
            setData(value.items);
        };

        void fetchData();
    }, [component.dataSource]);

    return { data };
};
