import { IDocTransformer } from '../../interfaces/transformer/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, IEditDocument } from '../../types';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { IEditDocument_T360 } from '../types';
import { T360AdapterEditDocumentActionableEntity } from '../actionableEntity/editDocumentActionableEntity';

const log = factory.getLogger('T360AdapterEditDocumentEntityTransformer');

@injectable()
export class T360AdapterEditDocumentEntityTransformer implements IDocTransformer {
    transformIntoActionableEntity(
        nodeObj: IEditDocument,
        _matterDoc: IMatterDoc,
        _urlPattern: IDocOperationInvocation,
    ): T360AdapterEditDocumentActionableEntity {
        log.debug('Calling T360AdapterEditDocumentActionableEntity ');
        const obj = {} as T360AdapterEditDocumentActionableEntity;
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc

        const nodeObj1 = nodeObj as IEditDocument_T360;
        if (nodeObj1.nameOnly !== undefined || nodeObj1.nameOnly) {
            obj.nameOnly = nodeObj1.nameOnly;
        }

        if (nodeObj1.description !== undefined || nodeObj1.description) {
            obj.description = nodeObj1.description;
        }

        if (nodeObj1.editAccessType !== undefined || nodeObj1.editAccessType) {
            obj.editAccessType = nodeObj1.editAccessType;
        }

        if (nodeObj1.viewAccessType !== undefined || nodeObj1.viewAccessType) {
            obj.viewAccessType = nodeObj1.viewAccessType;
        }

        return obj;
    }
}
