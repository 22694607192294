import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { getAppResources, getApplicationUrls } from '../../store/slices';
import IconMapper, { iconMapping } from '../common/iconMapper';
import { UUIFlyoutMenu, UUIFlyoutMenuButton, UUIFlyoutMenuItem } from '../common/uuiFlyoutMenu';
import { dispatchListScreenRowOperation } from './context/listScreenAsyncActions';
import { useListScreenDispatch, useListScreenState } from './context/listScreenContext';
import { useRefreshList } from './context/listScreenHooks';
import { checkListHasRowOperations } from './listScreenHelpers';
import { IListScreenRow } from './types';

interface IListItemMoreMenu {
    row: IListScreenRow;
}

const ListItemMoreMenu: React.FC<IListItemMoreMenu> = ({ row }) => {
    const applicationUrls = useAppSelector(getApplicationUrls);
    const appResources = useAppSelector(getAppResources);
    const history = useHistory();
    const dispatch = useAppDispatch();
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const refreshList = useRefreshList();
    const rowOperations = row._metadata.rowOperations;
    const listHasOperations = checkListHasRowOperations(listScreenState.listData?.list);

    return rowOperations?.length > 0 ? (
        <UUIFlyoutMenu anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} isNarrow={true}>
            <UUIFlyoutMenuButton
                aria-label="row operations"
                data-testid={`listscreen-row-kebob-${row.id}`}
                edge="end"
                rootClass="flyoutMenuIcon"
            />
            {rowOperations.map((operation, index) => {
                const displayTxt = operation.displayName || operation.iconName;
                return (
                    <UUIFlyoutMenuItem
                        key={index}
                        icon={
                            operation.iconName &&
                            iconMapping[operation.iconName.trim().toLocaleLowerCase()] !== undefined ? (
                                <div>
                                    <IconMapper iconName={operation.iconName} />
                                </div>
                            ) : undefined
                        }
                        data-testid={`kabob-menu-row-operation${index}`}
                        onClick={() => {
                            void dispatchListScreenRowOperation(
                                operation,
                                row,
                                appResources,
                                applicationUrls,
                                history,
                                listScreenState,
                                dispatch,
                                refreshList,
                                listScreenDispatch,
                            );
                        }}
                        isWordWrapped={true}>
                        {displayTxt}
                    </UUIFlyoutMenuItem>
                );
            })}
        </UUIFlyoutMenu>
    ) : (
        <div style={{ width: listHasOperations ? '34px' : '5px', float: 'right' }} />
    );
};

export default ListItemMoreMenu;
