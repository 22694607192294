import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkAdministration = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkAddIcon" width="25" height="25" viewBox="0 0 25 25" {...props}>
        <defs>
            <clipPath id="clipPath-wk-administration">
                <rect id="Rectangle_3439" data-name="Rectangle 3439" width="15.867" height="25" fill="currentColor" />
            </clipPath>
        </defs>
        <g id="Group_10972" data-name="Group 10972" transform="translate(-3249 -295)">
            <g id="Group_10682" data-name="Group 10682" transform="translate(3253.688 295)">
                <g id="Group_9366" data-name="Group 9366" clipPath="url(#clipPath-wk-administration)">
                    <path
                        id="Path_5024"
                        data-name="Path 5024"
                        d="M11.115,10.088a.745.745,0,0,0-.763.459,2.622,2.622,0,0,1-4.839,0,.733.733,0,0,0-.683-.463.61.61,0,0,0-.077,0A5.222,5.222,0,0,0,0,15.308v3.275a.748.748,0,0,0,.747.745H15.121a.746.746,0,0,0,.745-.745V15.308a5.221,5.221,0,0,0-4.752-5.22m3.261,7.748H1.491V15.31a3.744,3.744,0,0,1,2.8-3.63l.111-.028.063.1a4.117,4.117,0,0,0,6.945,0l.063-.1.112.028a3.746,3.746,0,0,1,2.8,3.63Z"
                        transform="translate(-0.001 5.672)"
                        fill="currentColor"
                    />
                    <path
                        id="Path_5025"
                        data-name="Path 5025"
                        d="M.165,7.677A2.85,2.85,0,0,0,1.317,9.966a.826.826,0,0,1,.283.764A2.854,2.854,0,0,0,2.42,13.1a2.818,2.818,0,0,0,2.367.817,1.114,1.114,0,0,1,.123-.008.792.792,0,0,1,.642.294,2.859,2.859,0,0,0,4.583,0,.85.85,0,0,1,.766-.284,2.864,2.864,0,0,0,3.188-3.188.816.816,0,0,1,.283-.762,2.859,2.859,0,0,0,0-4.584.839.839,0,0,1-.283-.767A2.861,2.861,0,0,0,10.9,1.43a.823.823,0,0,1-.764-.284,2.868,2.868,0,0,0-4.589,0,.854.854,0,0,1-.762.283,2.873,2.873,0,0,0-2.37.817A2.841,2.841,0,0,0,1.6,4.62a.826.826,0,0,1-.283.766A2.843,2.843,0,0,0,.165,7.677m2.047-1.1a2.311,2.311,0,0,0,.869-2.138A1.363,1.363,0,0,1,3.47,3.3,1.386,1.386,0,0,1,4.6,2.912a2.289,2.289,0,0,0,2.137-.867,1.371,1.371,0,0,1,2.2,0,2.287,2.287,0,0,0,2.136.869A1.375,1.375,0,0,1,12.21,3.3,1.356,1.356,0,0,1,12.6,4.436a2.311,2.311,0,0,0,.869,2.138,1.37,1.37,0,0,1,0,2.2,2.315,2.315,0,0,0-.869,2.139,1.36,1.36,0,0,1-.389,1.134,1.382,1.382,0,0,1-1.134.389,2.3,2.3,0,0,0-2.137.867,1.371,1.371,0,0,1-2.2,0,2.29,2.29,0,0,0-2.136-.869,1.37,1.37,0,0,1-1.136-.391,1.351,1.351,0,0,1-.387-1.133,2.317,2.317,0,0,0-.87-2.139,1.37,1.37,0,0,1,0-2.2"
                        transform="translate(0.093)"
                        fill="currentColor"
                    />
                    <path
                        id="Path_5026"
                        data-name="Path 5026"
                        d="M6.35,9.72A3.536,3.536,0,1,0,2.814,6.182,3.542,3.542,0,0,0,6.35,9.72m0-5.581A2.044,2.044,0,1,1,4.306,6.182,2.047,2.047,0,0,1,6.35,4.139"
                        transform="translate(1.583 1.49)"
                        fill="currentColor"
                    />
                </g>
            </g>
            <rect
                id="Rectangle_3441"
                data-name="Rectangle 3441"
                width="25"
                height="25"
                transform="translate(3249 295)"
                fill="none"
            />
        </g>
    </SvgIcon>
);
