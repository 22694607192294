import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkViewProfileIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkViewProfileIcon" viewBox="0 0 24 24" {...props}>
        <path d="M8.7,0h-7v16h12.6V5.7L8.7,0z M12.9,7v7.7H3.1V1.4h4.2V7L12.9,7z M8.7,5.6V2l3.6,3.6H8.7V5.6z" />
    </SvgIcon>
);

export default WkViewProfileIcon;
