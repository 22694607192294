/**
 * Extracts the filename from the Content-Disposition header.
 *
 * @param {string | null} contentDisposition - The Content-Disposition header value.
 * @returns {string} - The extracted filename.
 */
export const getFileNameFromContentDisposition = (contentDisposition: string | null): string => {
    const defaultFileName = 'download';
    if (!contentDisposition) {
        return defaultFileName;
    }

    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(contentDisposition);

    const matchIndex = 1;
    if (matches != null && matches[matchIndex]) {
        return matches[matchIndex].replace(/['"]/g, '');
    }

    return defaultFileName;
};

/**
 * Downloads a file by creating a download link for the given file data represented as a Blob object
 * with the specified file name.
 *
 * @param blob The Blob object representing the file content.
 * @param fileName The desired file name for the downloaded file.
 */
export const downloadFile = (blob: Blob, fileName: string): void => {
    const fileUrl = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = fileUrl;
    downloadLink.download = fileName;

    downloadLink.click();

    URL.revokeObjectURL(fileUrl);
};
