import { SvgIconProps } from '@mui/material';
import React from 'react';

const WkEllipsisIcon: React.FC<SvgIconProps> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" id="ellipsisSVG" data-testid="ellipsisSVG" {...props}>
        <g id="Large_elipses" data-name="Large elipses" transform="translate(-13.5 -103)">
            <g id="Group_6817" data-name="Group 6817" transform="translate(3 -21)">
                <g id="Group_6816" data-name="Group 6816" transform="translate(0.5 1)">
                    <circle
                        id="Ellipse_124"
                        data-name="Ellipse 124"
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(10 123)"
                    />
                    <circle
                        id="Ellipse_125"
                        data-name="Ellipse 125"
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(15 123)"
                    />
                    <circle
                        id="Ellipse_126"
                        data-name="Ellipse 126"
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(20 123)"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default WkEllipsisIcon;
