import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { DELAY_300MS } from '../common/constants';

type Size = {
    width: number;
    height: number;
};

/**
 * A hook to observe resize events on a specified HTML element.
 *
 * @param {React.RefObject<HTMLElement>} ref - A ref object pointing to the element to be observed.
 * @return {Size} The current size of the observed element.
 */
export const useResizeObserver = (ref: React.RefObject<HTMLElement>): Size => {
    const [size, setSize] = useState<Size>({ width: 0, height: 0 });
    const resizeObserver = useMemo(
        () =>
            debounce((entries: ResizeObserverEntry[]) => {
                const entry = entries[0];
                setSize({
                    width: entry.contentRect.width,
                    height: entry.contentRect.height,
                });
            }, DELAY_300MS),
        [],
    );
    const observer = useMemo(() => new ResizeObserver(resizeObserver), [ref.current]);

    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current);
        }

        return (): void => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [observer, ref.current]);

    return size;
};
