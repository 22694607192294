import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkPreviousPageIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 12 12">
        <path d="M8.8,0L2.5,6l6.2,6l0.7-0.7L4,6l5.5-5.3L8.8,0z" />
    </SvgIcon>
);

export default WkPreviousPageIcon;
