import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkDoubleArrowRight = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkDoubleArrowRight" viewBox="15 15 25 25" {...props}>
        <defs>
            <clipPath id="clipPath-wk-doubleArrowRight">
                <rect id="Rectangle_9426" data-name="Rectangle 9426" width="56" height="45" fill="currentColor" />
            </clipPath>
        </defs>
        <g id="Group_910971" transform="translate(-3249 -250)">
            <g id="Group_99352" transform="translate(3249 255.785)">
                <g id="Group_99351" clipPath="url(#clipPath-wk-doubleArrowRight)">
                    <path
                        d="M19.6 30.5L28 22.5L19.733 14.5L18.8 15.433L26 22.5L18.667 29.567L19.6 30.5Z"
                        transform="translate(0.926 0.295)"
                        fill="currentColor"
                    />
                    <path
                        d="M28.933 30.5L37.333 22.5L29.066 14.5L28.133 15.433L35.333 22.5L28 29.567L28.933 30.5Z"
                        transform="translate(0 0)"
                        fill="currentColor"
                    />
                </g>
            </g>
            <rect id="Rectangle_9440" width="56" height="45" transform="translate(3249 250)" fill="none" />
        </g>
    </SvgIcon>
);
