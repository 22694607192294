import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { isNil } from 'lodash';
import React, { FC } from 'react';
import { AppTheme } from '../../../../app';

const useStyles = makeStyles<AppTheme>((theme) => ({
    field: {
        display: 'grid',
        gridTemplateColumns: '80px 1fr',
        gap: theme.spacing(),
        padding: theme.spacing(0, 2),
        boxSizing: 'border-box',
    },
    withoutName: {
        gridTemplateColumns: '1fr',
    },
    closely: {
        gridTemplateColumns: 'min-content 1fr',
    },
    name: {
        fontSize: theme.typography.fontSize,
        lineHeight: '18px',
        fontWeight: 500,
    },
    centered: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export type FieldProps = {
    /**
     * Field name.
     */
    name?: string;

    /**
     * Value indicating whether the field name is centered vertically.
     */
    centered?: boolean;

    /**
     * Value indicating whether the filed name block is equal to the inner content.
     */
    closely?: boolean;
};

export const Field: FC<FieldProps> = (props) => {
    const { name, centered, closely, children } = props;

    const classes = useStyles();

    const hasName = !isNil(name);

    return (
        <div
            className={classNames(classes.field, {
                [classes.withoutName]: !hasName,
                [classes.closely]: hasName && closely,
            })}>
            {name && (
                <div
                    className={classNames(classes.name, {
                        [classes.centered]: centered,
                    })}>
                    {name}
                </div>
            )}
            {children}
        </div>
    );
};
