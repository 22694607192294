import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import useIsMounted from './useIsMounted';

/**
 * returns the window width in a debounced manor. By default, it will debounce for 300ms
 * @param delay 300 is the default
 * @returns the current width of the window
 */
const useWindowWidth = (delay = 300): number => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMounted = useIsMounted();

    useEffect(() => {
        const handleResize = () => {
            if (!isMounted.current) {
                return;
            }
            setWidth(window.innerWidth);
        };
        const debouncedHandleResize = debounce(handleResize, delay);
        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, [delay, isMounted]);

    return width;
};

export default useWindowWidth;
