import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAlertDialog } from '../../components/common/alertDialog';

export type AlertDialog = IAlertDialog;
export type AlertDialogsState = AlertDialog[];

const initialState: AlertDialogsState = [];

const alertDialogs = createSlice({
    name: 'alert-dialogs',
    initialState,
    reducers: {
        openDialog: (state, action: PayloadAction<{ dialog: IAlertDialog }>) => {
            state.push(action.payload.dialog);
        },
        closeDialog: (state, action: PayloadAction<{ id: string }>) => {
            return state.filter((alertDialog) => alertDialog.id !== action.payload.id);
        },
    },
});

// Actions
export type AlertDialogsActions = TakeActions<typeof alertDialogs.actions>;
export const { openDialog, closeDialog } = alertDialogs.actions;
export const alertDialogsReducer = alertDialogs.reducer;
