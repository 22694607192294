import { Radio, FormControlLabel, RadioGroup, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { VFC } from 'react';
import { SelectionOption } from '../../../../../reducers/types';
import { AppTheme } from '../../../../app';
import { Field } from '../field';
import { MuiRadioGroupChangeEventHandler } from '../quickSearch.component';
import { Select } from '../select';

const useStyles = makeStyles<AppTheme>((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    radioGroup: {
        gap: theme.spacing(),
    },
    radio: {
        padding: 0,
        marginRight: theme.spacing(),

        '&.Mui-focusVisible svg:first-child path': {
            outline: '2px solid #000',
            borderRadius: '50%',
        },

        '& svg': {
            fontSize: 19,
            fill: '#005b92',
        },
    },
    formControl: {
        marginRight: 0,
        marginLeft: 0,
        height: 20,
    },
    formControlLabel: {
        fontSize: 14,
        lineHeight: '20px',
    },
    maxResults: {
        width: 84,
    },
}));

const MAX_RESULTS_OPTIONS = ['10', '50', '100', '200'];

export type AdditionalOptionsProps = {
    /**
     * Search match value.
     */
    searchMatch: string;

    /**
     * List of available search match options.
     */
    searchMatchOptions: SelectionOption[];

    /**
     * Search sort value.
     */
    searchSortOption: string;

    /**
     * List of available search sort options.
     */
    searchSortOptions: SelectionOption[];

    /**
     * Maximum search results per query.
     */
    maxResults: string;

    /**
     * Event handler that fires when a search match is selected.
     */
    onMatchChange: MuiRadioGroupChangeEventHandler;

    /**
     * Event handler that fires when a search sort is selected.
     */
    onSortChange: MuiRadioGroupChangeEventHandler;

    /**
     * Event handler that fires when a search maximum results is selected.
     */
    onMaxResultsChange: (value: string) => void;
};

export const AdditionalSearchOptions: VFC<AdditionalOptionsProps> = (props) => {
    const {
        searchMatch,
        searchMatchOptions,
        searchSortOption,
        searchSortOptions,
        maxResults,
        onMatchChange,
        onSortChange,
        onMaxResultsChange,
    } = props;

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Field name={'Match Keywords'}>
                <RadioGroup
                    className={classes.radioGroup}
                    row
                    aria-label="matchKeywords"
                    name="matchKeywords"
                    value={searchMatch}
                    onChange={onMatchChange}>
                    {searchMatchOptions.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            classes={{
                                root: classes.formControl,
                                label: classes.formControlLabel,
                            }}
                            value={option.value}
                            control={
                                <Radio className={classes.radio} disableFocusRipple disableRipple color="primary" />
                            }
                            label={option.displayValue}
                        />
                    ))}
                </RadioGroup>
            </Field>
            <Field name={'Sort By'}>
                <RadioGroup
                    className={classes.radioGroup}
                    row
                    aria-label="sortBy"
                    name="sortBy"
                    value={searchSortOption}
                    onChange={onSortChange}>
                    {searchSortOptions.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            classes={{
                                root: classes.formControl,
                                label: classes.formControlLabel,
                            }}
                            value={option.value}
                            control={
                                <Radio className={classes.radio} disableFocusRipple disableRipple color="primary" />
                            }
                            label={option.displayValue}
                        />
                    ))}
                </RadioGroup>
            </Field>
            <Field name={'Max. Results'} centered>
                <Select
                    variant="standard"
                    className={classes.maxResults}
                    value={maxResults}
                    onChange={(event): void => onMaxResultsChange(event.target.value as string)}>
                    {MAX_RESULTS_OPTIONS.map((value, index) => (
                        <MenuItem key={index} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </Field>
        </div>
    );
};
