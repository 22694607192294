import { ITransformer } from './types';
import { ICHEditDocument } from '../interfaces/operations';

import 'reflect-metadata';
import { injectable } from 'inversify';
import { IEditDocument } from '@wk/elm-uui-doc-component/dist/types';

import { factory } from '../configLog4J';
import { IFieldValue } from '../contextManager';
const log = factory.getLogger('CtxEditDocumentTransformer');

@injectable()
export class CtxEditDocumentTransformer implements ITransformer {
    data!: ICHEditDocument;

    transform(): IEditDocument {
        const nodeObj = {} as IEditDocument;
        nodeObj.entityContext = {
            name: this.data.associatedEntityName || '',
            id: this.data.associatedEntityId,
            type: this.data.associatedEntityType,
        };

        const extendedProps = <IEditDocument['extendedProps']>{};
        log.debug('test printing data: ' + JSON.stringify(this.data));

        const { nameOnly, comments, editAccessType, viewAccessType }: Record<string, IFieldValue> = this.data.payload
            ? this.data.payload
            : {};

        if (nameOnly?.value !== undefined || nameOnly?.value) {
            nodeObj.nameOnly = this.data?.payload?.nameOnly?.value ?? '';
        }

        if (comments?.value !== undefined || comments?.value) {
            nodeObj.description = this.data?.payload?.comments?.value;
        }

        if (editAccessType?.value !== undefined || editAccessType?.value) {
            nodeObj.editAccessType = this.data?.payload?.editAccessType?.value;
        }

        if (viewAccessType?.value !== undefined || viewAccessType?.value) {
            nodeObj.viewAccessType = this.data?.payload?.viewAccessType?.value;
        }

        nodeObj.docId = this.data?.documentId;

        extendedProps['admittedMembers'] = this.data?.props?.admittedMembers;

        log.debug('printing nodeObj: ' + JSON.stringify(nodeObj));
        return nodeObj;
    }
}
