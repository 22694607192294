import { GridSortItem } from '@mui/x-data-grid';
import { createSlice, EmptyObject, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../types';

export type GridsState = {
    gridSortItems: Record<string, GridSortItem | EmptyObject | undefined>;
};

const KEY = 'gridSortItems';

const initialState: GridsState = {
    gridSortItems: JSON.parse(sessionStorage.getItem(KEY) ?? '{}') as Record<string, GridSortItem>,
};

const grids = createSlice({
    name: 'grids',
    initialState,
    reducers: {
        setGridSortByCode: (state, action: PayloadAction<{ code: string; sortItem: GridSortItem | EmptyObject }>) => {
            state.gridSortItems = {
                ...state.gridSortItems,
                [action.payload.code]: action.payload.sortItem,
            };
            sessionStorage.setItem(KEY, JSON.stringify(state.gridSortItems));
        },
    },
});

export const selectSortItem =
    (code: string) =>
    (state: AppState): GridSortItem | EmptyObject | undefined =>
        state.ui.grids.gridSortItems[code];

// Actions & Reducer
export type GridsActions = TakeActions<typeof grids.actions>;
export const { setGridSortByCode } = grids.actions;
export const gridsReducer = grids.reducer;
