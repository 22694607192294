import { IconButton, Autocomplete } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
import { useAppSelector } from '../../../store';
import { getAppResources } from '../../../store/slices';
import { apiFetch } from '../../../utils/fetchUtils';
import { UUIInput } from '../../common/uuiInput';
import WkCircleLoadingIcon from '../../icons/wkCircleLoadingIcon';
import WkSpyglassIcon from '../../icons/wkSpyglassIcon';
import { getValidationMessagesForField } from '../itemScreenHelpers';
import { ControlTypeProps, IAutoCompleteItemsResponse } from '../types';
import { ControlLabel } from './controlLabel';
import ReadOnlyControl from './readOnlyControl';
import css from './singleAutocomplete.module.scss';

export const SingleAutoComplete: React.FC<ControlTypeProps> = ({ field, fieldData, readOnly }) => {
    const { register, setValue, errors } = useFormContext();
    const [selectedValue, setSelectedValue] = useState<string | null>(fieldData.displayValue || null);
    const [inputValue, setInputValue] = useState(fieldData.displayValue || '');
    const [options, setOptions] = useState<IAutoCompleteItemsResponse[]>([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const appResources = useAppSelector(getAppResources);
    const debounceFetch = useDebouncedCallback(
        (newInputValue) => {
            fetchAutocompleteItems(newInputValue);
        },
        300,
        { maxWait: 1000 },
    );

    useEffect(() => {
        if (!readOnly) {
            register({ name: field.name });
            setValue(field.name, isNaN(parseInt(fieldData.inputValue!)) ? '' : parseInt(fieldData.inputValue!));
        }
    }, [register, setValue, field.name, fieldData.inputValue, readOnly]);

    if (readOnly) {
        return <ReadOnlyControl field={field} fieldData={fieldData} />;
    }

    const handleInputChange = (event: any, newInputValue: string) => {
        setOpen(false);
        setInputValue(newInputValue);

        if (newInputValue.trim().length < 1) {
            setSelectedValue(null);
        }

        if (event && (event.keyCode === 13 || event.button === 0)) {
            return;
        }

        if (newInputValue.trim().length > 1) {
            debounceFetch(newInputValue.trim());
        }
    };

    const fetchAutocompleteItems = (newInputValue: string) => {
        setLoading(true);
        const requestUrl = field.sourceListScreen!.autocompleteItemsUrl!.replace('{value}', newInputValue);
        apiFetch<IAutoCompleteItemsResponse[]>(requestUrl).then((data) => {
            setOptions(data);
            setOpen(true);
            setLoading(false);
        });
    };

    const handleOnChange = (_: ChangeEvent<unknown>, newValue: IAutoCompleteItemsResponse | null) => {
        if (newValue) {
            setValue(field.name, newValue.id);
            setInputValue(newValue.value);
            setSelectedValue(newValue.value);
        } else {
            setValue(field.name, '');
            setInputValue('');
            setSelectedValue(null);
        }
    };

    const handleOnBlur = () => {
        if (selectedValue && inputValue !== selectedValue) {
            setInputValue(selectedValue);
        } else if (!selectedValue && inputValue !== selectedValue) {
            setInputValue('');
        }
        open ? setOpen(false) : null;
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Escape' && open) {
            setOpen(false);
        }
    };

    const handleOnClickStopPropagation = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const renderOptionsList = (props: any, option: any) => {
        const result = option.display.split(/<\/?span>/).map((text: any, index: number) => {
            return (
                <li {...props}>
                    {text === '|' ? (
                        <span key={index} className={css.optionPipe}>
                            &nbsp;
                            {text}&nbsp;
                        </span>
                    ) : (
                        text
                    )}
                </li>
            );
        });
        return result;
    };

    return (
        <Autocomplete
            id={field.name}
            inputValue={inputValue}
            defaultValue={
                fieldData.displayValue!.trim() !== ''
                    ? {
                          display: '',
                          id: 999,
                          value: fieldData.displayValue!,
                      }
                    : null
            }
            open={inputValue != null && inputValue.length > 1 && open}
            noOptionsText={appResources.dropdownControlNoOptionsText}
            onBlur={handleOnBlur}
            onKeyDown={(event) => handleKeyDown(event)}
            options={options}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderOption={renderOptionsList}
            filterOptions={(options) => options}
            onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue)}
            onChange={(event, newValue) => handleOnChange(event, newValue)}
            clearOnBlur={false}
            clearOnEscape={true}
            ListboxProps={{ style: { maxHeight: 165, overflow: 'auto' } }}
            classes={{
                endAdornment: css.endAdornment,
                option: css.option,
                listbox: css.input,
                paper: css.paper,
                clearIndicator: css.clearIndicator,
                popupIndicator: css.popupIndicator,
            }}
            renderInput={(params) => (
                <ControlLabel
                    data-testid="singleAutocompleteControl"
                    field={field}
                    control={
                        <UUIInput
                            {...params.InputProps}
                            inputProps={params.inputProps}
                            data-testid="singleAutocompleteInput"
                            error={getValidationMessagesForField(field, errors, 'errors').length > 0}
                            warning={getValidationMessagesForField(field, errors, 'warnings').length > 0}
                            endAdornment={
                                <>
                                    {loading ? (
                                        <IconButton
                                            data-testid="singleAutocompleteLoadingIcon"
                                            tabIndex={-1}
                                            className={css.iconButton}
                                            onClick={handleOnClickStopPropagation}
                                            aria-label="clear"
                                            size="large">
                                            <WkCircleLoadingIcon className={css.iconLoading} />
                                        </IconButton>
                                    ) : (
                                        params.InputProps.endAdornment
                                    )}
                                    <IconButton
                                        data-testid="singleAutocompleteSpyglassIcon"
                                        tabIndex={-1}
                                        className={css.iconButtonSpyglass}
                                        onClick={handleOnClickStopPropagation}
                                        aria-label="search"
                                        size="large">
                                        <WkSpyglassIcon className={css.iconSpyglass} viewBox="0 0 18 18" />
                                    </IconButton>
                                </>
                            }
                            id={field.name}
                            name={field.name}
                            classes={{
                                focused: css.inputFocused,
                                input: css.inputNative,
                                root: css.singleAutocompleteRoot,
                            }}
                        />
                    }></ControlLabel>
            )}
        />
    );
};
