import { UUIFetch } from '@wk/elm-uui-common';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useUUIHistory } from '../../../hooks';
import { InitializeAppCallback } from '../../../initialize';
import { handleInitializeResponse, handleJsonResponse } from '../../../initializeCommon';
import { useAppDispatch } from '../../../store';
import { initialize } from '../../../store/middleware';
import { ConnectivityService } from '../../../target/t360/infrastructure/connectivityService';
import {
    closeFailedUploadFlyout,
    readClosedFailedUploadFlyoutAtFromLocalStorage,
} from '../../../utils/activitiesService';
import { initializeUUIFetch, setApiVersion } from '../../../utils/fetchUtils';
import { updateLocationDataForOC } from '../../../utils/ocLocationService';
import { getAuthenticationProvider } from '../../authentication/AuthenticationProviderService';
import { clInitialize, isOC } from '../../contextLayerService/contextLayerService';
import { usePowerBI } from '../../viewScreen/views/layoutPowerBiView/hooks';

type UseAppInitializerProps = {
    initializeAppCallback?: InitializeAppCallback;
};

// TODO: should be moved to redux middleware
export const useAppInitializer = (props: UseAppInitializerProps = {}) => {
    const { initializeAppCallback } = props;
    // FIXME: find a proper way to avoid dependency on the specific target
    const heartBeatFunction = ConnectivityService.heartBeatFunction;
    const dispatch = useAppDispatch();
    const history = useUUIHistory();
    usePowerBI({ master: true });
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const callInitializeApp = async () => {
            if (initializeAppCallback) {
                await initializeAppCallback(dispatch, history);
            } else {
                return Promise.resolve();
            }
        };

        const initializeUUI = async () => {
            initializeUUIFetch(dispatch, heartBeatFunction);
            await callInitializeApp();
            const { apiContextRoot, apiContextPath, initializationPath } = window.Props as Record<string, string>;

            const response = await UUIFetch.fetch(
                `${apiContextRoot}${apiContextPath}${initializationPath}`,
                await getAuthenticationProvider().addRequestAuthentication({}),
            );
            const responseData = await handleInitializeResponse(response);
            const initialState = await handleJsonResponse(responseData);

            if (!initialState) {
                return;
            }

            setApiVersion(initialState.version);
            // context layer
            if (isOC()) {
                void clInitialize({
                    props: {
                        autoUpdateURL: initialState.appVersion?.autoUpdateUrl
                            ? initialState.appVersion.autoUpdateUrl
                            : '',
                        currentVersion: '',
                    },
                });
            }

            if (readClosedFailedUploadFlyoutAtFromLocalStorage() === null) {
                closeFailedUploadFlyout(dispatch);
            }

            dispatch(initialize({ externalState: initialState }));
            setInitialized(true);
        };

        void trackPromise(initializeUUI());
    }, [heartBeatFunction, initializeAppCallback, dispatch, history]);

    useEffect(() => {
        const unlisten = history.listen((newLocation, action) => {
            if (!initialized) {
                updateLocationDataForOC(newLocation, action);
            }
        });

        return (): void => unlisten();
    }, [history, initialized]);

    return { initialized: initialized };
};
