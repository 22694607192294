export class DCResponse extends Response {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    outputInfo: () => Promise<any>;
    statusInfo: IStatusInfo = { errors: {} };
}

export interface IStatusInfo {
    errors: Record<string, string>;
    warnings?: Record<string, string>;
}

export class UnExpectedError extends Error {
    constructor(message: string) {
        super(message); // (1)
        this.name = 'UnExpected Error'; // (2)
    }
}
// old changes
export interface IAddDocument extends IDocServiceRequest {
    name?: string;
    folderPath?: string;
    documentName?: string;
    documentType?: string;
    file: Buffer | Blob | File; // T360
    fileId?: string;
    documentFolderId?: string; // T360
    viewAccessType?: string;
    editAccessType?: string;
    extendedProps: {
        uploadGuid?: string;
        contentType?: string;
        description?: string;
        admittedMembers?: IAdmittedMembers[];
    };
}

export interface ICheckIn extends IDocServiceRequest {
    file: Buffer | Blob | File; // T360
    fileId?: string;
    docId: string;
    name?: string;
    folderPath?: string;
    documentName?: string;
    documentType?: string;
    existingDocumentName?: string;
    extendedProps: {
        uploadGuid?: string;
        isVersionable?: string;
        isMajorVersion?: string;
        comment?: string; // T360
        contentType?: string; // T360
        keepCheckedOut?: boolean; // T360
    };
}

// Input interface for the AddEmail
export interface IAddEmail extends IDocServiceRequest {
    name?: string;
    folderPath?: string;
    documentFolderId?: string; // T360
    viewAccessType?: string;
    editAccessType?: string;
    file: Buffer | Blob | File; // T360
    fileId?: string;
    extendedProps: {
        uploadGuid?: string;
        isFolder?: string;
        isEmailMessage?: string;
        senderEmail?: string;
        sentTime?: string;
        senderName?: string;
        receivedTime?: string;
        emailRecipients?: string;
        emailSubject?: string;
        emailBody?: string;
        isAttachmentPresent?: string;
        uniqueId?: string;
        contentType?: string;
        description?: string;
        admittedMembers?: IAdmittedMembers[];
    };
}

// Input interface for the AddFolder
export interface IAddFolder extends IDocServiceRequest {
    name: string; // T360
    viewAccessType?: string;
    editAccessType?: string;
    documentFolderId?: string;
    extendedProps: {
        description?: string;
        admittedMembers?: IAdmittedMembers[];
    };
}

// Input interface for the RenameFolder
export interface IEditFolder extends IDocServiceRequest {
    entityContext: IEntityContext;
    documentFolderId: string;
    name: string;
    viewAccessType?: string;
    editAccessType?: string;
    extendedProps: {
        admittedMembers?: IAdmittedMembers[];
    };
}

// Input interface for the RenameFolder
export interface IEditDocument extends IDocServiceRequest {
    entityContext: IEntityContext;
    docId: string;
    nameOnly: string;
    description?: string;
    viewAccessType?: string;
    editAccessType?: string;
    extendedProps: {
        admittedMembers?: IAdmittedMembers[];
    };
}

// Input interface for the RenameFolder
export interface IRenameFolder extends IDocServiceRequest {
    entityContext: IEntityContext;
    docId: string;
    name: string;
    viewAccessType?: string;
    editAccessType?: string;
    extendedProps: {
        admittedMembers?: IAdmittedMembers[];
    };
}

export interface ICheckOut extends IDocServiceRequest {
    docId: string;
}

// Input interface for the CancelCheckOut
export interface ICancelCheckOut extends IDocServiceRequest {
    docId: string;
}

// Input interface for the RenameDocument && RenameFolder
export interface IRenameObject extends IDocServiceRequest {
    entityContext: IEntityContext;
    docId: string;
    name: string;
    oldName?: string;
    viewAccessType?: string;
    editAccessType?: string;
    extendedProps: {
        admittedMembers?: IAdmittedMembers[];
    };
}

export enum DCEntityTypeEnum {
    Organization = 'Organization',
    Person = 'Person',
    Matter = 'Matter',
    InvoiceHeader = 'InvoiceHeader',
    EmailDocument = 'EmailDocument',
}

export interface IEntityContext {
    name: string;
    type: string; // Enum People ,Matter,Organization,InvoiceHeader
    id: string;
}

export interface IDocServiceRequest {
    entityContext: IEntityContext;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    extendedProps?: any;
}

// to be received from the passport or T360
export interface IMatterDoc {
    parentId: null;
    pabuId: number;
    picId: number;
    relatedEntityName: string;
    path: string;
    docStorId: number;
}

export interface IUserProfile {
    baseUrl: string;
    userId: string;
    userName?: string;
    tenantInfo: ITenantInfo;
}

export interface ITenantInfo {
    id: string;
    name: string;
}

export interface IDocNode {
    name: string;
    docId: string;
    data?: INodeData;
    metdata?: INodeMetaData;
}

export interface INodeData {
    id: string;
    data?: string;
}

export interface INodeMetaData {
    owner: string;
    receiver: string;
}

export interface IDocument extends IDocNode {
    createdBy: string;
    checkOutBy: string;
    pabuId?: string;
    picId?: string;
    regionId?: string;
    categorization: string;
    comments: string;
    lastModifiedBy: string;
    objectId: string;
    objectName: string;
    objectType: string;
    path: string;
}

export interface IEmail extends IDocNode {
    senderName: string;
    checkOutBy: string;
    pabuId?: string;
    picId?: string;
    regionId?: string;
    emailBody: string;
    emailReceivedTime: string;
    emailRecipients: string;
    emailSubject: string;
    emailRoot: string;
    lastModifiedBy: string;
    objectId: string;
}

export type IDocType = IAddDocument & IAddEmail & ICheckOut & ICheckIn & ICancelCheckOut & IDocumentMetadataFileName;

export interface IAdmittedMembers {
    individualId: string;
    canEdit: boolean;
}

export type IUploadType = IAddDocument | ICheckIn | IAddEmail;

export interface IDocumentMetadata extends IDocServiceRequest {
    docId?: string;
}

export interface IDocumentMetadataEmail extends IDocServiceRequest {
    docId?: string;
}

export interface IPollFiles extends IDocServiceRequest {
    guids: Array<string>;
}

export interface IDocumentMetadataFileName extends IDocumentMetadata {
    documentFolderId?: string;
    documentName?: string;
    fileName?: string;
}

// Input interface for the DeleteDocument
export interface IDeleteDocument extends IDocServiceRequest {
    docId: string;
}

export interface IDeleteEmail extends IDocServiceRequest {
    docId: string;
}

// Input interface for the DeleteFolder
export interface IDeleteFolder extends IDocServiceRequest {
    docId: string;
}

// Input interface for the DeleteEmail
export interface IDeleteEmail extends IDocServiceRequest {
    docId: string;
}

// Input interface for the DeleteEmailFolder
export type IDeleteEmailFolder = IDocServiceRequest;

// Input interface for the RenameDocument
export interface IRenameDocument extends IDocServiceRequest {
    entityContext: IEntityContext;
    docId: string;
    name: string;
    file: Buffer | Blob | File;
    viewAccessType?: string;
    editAccessType?: string;
    extendedProps: {
        admittedMembers?: IAdmittedMembers[];
    };
}

// Input interface for the DownloadDocument
export interface IDownloadDocument extends IDocServiceRequest {
    docId: string;
}

// Input interface for the DownloadDocument
export interface IDownloadEmailDocument extends IDocServiceRequest {
    docId: string;
}

// Input interface for the DownloadVersionDocument
export interface IDownloadVersionDocument extends IDocServiceRequest {
    docId: string;
    extendedProps: {
        version: string;
    };
}

export interface DocumentNameSplit {
    name: string;
    extension: string;
}

// Input interface for the DownloadEmail
export type IDownloadEmail = IDocServiceRequest;

// Input interface for the GetNotificationFiles
export type IGetNotificationFiles = IDocServiceRequest;

// Input interface for the GetSupportedEntityTypes
export type IGetSupportedEntityTypes = IDocServiceRequest;
