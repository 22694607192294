import { RegisterApplicationReturnType } from '@wk/elm-core-services';
import { isError } from 'lodash';
import { getLogger, ObjectValues } from './utils';

const logger = getLogger('mfeApps');

export const MFE_APP_NAMES = {
    WK_ELM_URP_APP: 'wk-elm-urp-app',
} as const;

export type MfeAppNames = ObjectValues<typeof MFE_APP_NAMES>;

export const mfeApps: Record<string, RegisterApplicationReturnType<string>> = {
    [MFE_APP_NAMES.WK_ELM_URP_APP]: {
        mount: () => Promise.resolve(),
        unmount: () => Promise.resolve(),
    },
};

export function registerMfeApps(): void {
    const { WK_ELM_URP_APP } = MFE_APP_NAMES;

    System.import(WK_ELM_URP_APP)
        .then(({ registerApp }) => {
            logger.debug(`${WK_ELM_URP_APP} loaded`);

            const app = registerApp({
                appPath: '/urp',
                appName: WK_ELM_URP_APP,
            });

            mfeApps[WK_ELM_URP_APP] = app;
        })
        .catch((e) => {
            if (isError(e)) {
                logger.error(`Error loading ${WK_ELM_URP_APP}`, e);
            }
        });
}

export function getMfeApp(appName: MfeAppNames): RegisterApplicationReturnType<string> {
    return mfeApps[appName];
}
