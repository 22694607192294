import { getLogger } from '@wk/elm-uui-common';
import { Report } from 'powerbi-client';
import { MESSAGES, SEARCH_CHART_WORD } from '../common/constants';
import { BaseChartStrategy } from './baseChart.strategy';
import { ChartStrategy } from './chartStrategy.interface';

const logger = getLogger('dashboard-strategy');

export class DashboardStrategy extends BaseChartStrategy implements ChartStrategy {
    public async resize(_width: number, _height: number): Promise<void> {
        logger.error(MESSAGES.NO_CHARTS);
        throw new Error(MESSAGES.NO_CHARTS);
    }
    public async fetchDataAndInit(report: Report): Promise<void> {
        await this.initialize(report);
        this.chart = this.visuals.find((visual) => visual.type.toLowerCase().includes(SEARCH_CHART_WORD));
    }
}
