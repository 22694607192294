import { ExpandMore } from '@mui/icons-material';
import { Skeleton } from '@mui/lab';
import { Button, ListItemText, Menu } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { memo, MouseEvent, PropsWithChildren, useState } from 'react';
import { IMenuItem } from '../../../../reducers/types';
import { AppTheme } from '../../../app';
import { MultiLevelMenuItem } from '../../multiLevelMenuItem';

const useStyles = makeStyles<AppTheme>((theme) => ({
    menu: {
        minWidth: 300,
        maxWidth: 400,
        maxHeight: 500,
    },
    skeleton: {
        color: 'transparent',
        whiteSpace: 'nowrap',
    },
    notVisible: {
        width: 0,
        visibility: 'hidden',
    },
    listItemText: {
        color: theme.palette.primary.dark,
        margin: 0,
    },
    menuTitle: {
        wordBreak: 'break-word',
        display: 'inline-block',
        boxSizing: 'border-box',
        whiteSpace: 'pre-line',
        '&:focus': {
            outline: 'none',
        },
    },
    caretIcon: {
        color: theme.palette.primary.dark,
        marginLeft: theme.spacing(1.5),
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        transform: 'rotate(180deg)',
    },
}));

export type DynamicMenuItemProps = PropsWithChildren<{
    classes?: {
        button?: string;
        menu?: string;
    };
    item: IMenuItem;
    onMenuClick?: (item: IMenuItem) => void;
    inProgress?: boolean;
}>;

// eslint-disable-next-line max-lines-per-function
export const DynamicMenuItem = memo(function DynamicMenuItemComponent(props: DynamicMenuItemProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const [expanded, setExpanded] = useState(new Set<number>());
    const { item, onMenuClick, classes: externalClasses, inProgress } = props;

    const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(undefined);
    };

    const toggleMenu = ({ id }: IMenuItem) => {
        if (expanded.has(id)) {
            expanded.delete(id);
        } else {
            expanded.add(id);
        }

        setExpanded(new Set(expanded));
    };

    const handleMenuSelection = (item: IMenuItem) => {
        if (item.children?.length) {
            toggleMenu(item);
        } else {
            closeMenu();
        }

        onMenuClick?.(item);
    };

    const renderButtonContent = (props: { item: IMenuItem; expandable?: boolean }) => {
        const { item, expandable } = props;
        const content = (
            <>
                {item.displayValue}
                <ExpandMore className={classNames({ [classes.notVisible]: !expandable })} />
            </>
        );

        return (
            <>
                {inProgress && <Skeleton className={classes.skeleton}>{content}</Skeleton>}
                {!inProgress && content}
            </>
        );
    };

    const renderMenu = () => (
        <>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                color="primary"
                onClick={openMenu}
                className={externalClasses?.button}
                disabled={inProgress}
                disableRipple>
                {renderButtonContent({ item, expandable: true })}
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                variant={'menu'}
                classes={{
                    paper: classNames(classes.menu, externalClasses?.menu),
                }}>
                {item.children?.map((childItem) => (
                    <MultiLevelMenuItem
                        key={childItem.id}
                        getState={(item) => ({
                            ...item,
                            expanded: expanded.has(item.id),
                        })}
                        handleMenuSelection={handleMenuSelection}
                        item={childItem}
                        renderListItem={({ displayValue, children }, { expanded }) => {
                            return (
                                <>
                                    <ListItemText
                                        primary={displayValue}
                                        classes={{
                                            root: classes.listItemText,
                                            primary: classes.menuTitle,
                                        }}
                                        data-testid={`${displayValue}-menu-item`}
                                    />
                                    {Boolean(children?.length) && (
                                        <ExpandMore
                                            className={classNames(classes.caretIcon, {
                                                [classes.expanded]: expanded,
                                            })}
                                        />
                                    )}
                                </>
                            );
                        }}
                    />
                ))}
            </Menu>
        </>
    );

    const renderButton = () => (
        <Button
            color="primary"
            disabled={inProgress}
            disableRipple
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={() => handleMenuSelection(item)}
            className={externalClasses?.button}>
            {renderButtonContent({ item })}
        </Button>
    );

    return item.children?.length ? renderMenu() : renderButton();
});
