import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { getNodeByType, sortFavorites, SortFavoritesProps } from '../../components/favorites/favoritesTree.utils';
import { IMenuItem } from '../../reducers/types';
import { AppState } from '../types';

export enum FavoriteStatus {
    Idle = 'idle',
    Loading = 'loading',
}

// noinspection JSUnusedGlobalSymbols
export enum FavoriteType {
    Folder = 'folder',
    Favorite = 'favorite',
    Shortcut = 'shortcut',
}

export type FavoriteEntity = RegularFavoriteEntity | FavoriteFolderEntity | ShortcutFavoriteEntity;

export type RegularFavoriteEntity = {
    id: number;
    name: string;
    type: FavoriteType.Favorite;
    parentId: number;
    url: string;
};

export type FavoriteFolderEntity = {
    id: number;
    name: string;
    type: FavoriteType.Folder;
    parentId?: number;
    children?: FavoriteEntity[];
};

export type ShortcutFavoriteEntity = {
    id: string;
    name: string;
    type: FavoriteType.Shortcut;
    parentId: number;
    data: IMenuItem;
};

export type FavoritesState = {
    entity?: FavoriteEntity;
    status: FavoriteStatus;
};

const initialState: FavoritesState = {
    status: FavoriteStatus.Idle,
};

const favorites = createSlice({
    name: 'favorites',
    initialState,
    reducers: {
        setFavorites: (state, action: PayloadAction<{ rootFavorite: FavoriteEntity }>) => {
            state.entity = action.payload.rootFavorite;
        },
        setStatus: (state, action: PayloadAction<{ status: FavoriteStatus }>) => {
            state.status = action.payload.status;
        },
    },
});

// Selectors
type SelectFavoritesTreeProps = {
    type?: FavoriteType;
};

const selectFavorites = (state: AppState): FavoritesState => state.favorites;
const selectFavoritesTreeProps = (_: AppState, props: SelectFavoritesTreeProps): SelectFavoritesTreeProps => props;
const selectFavoritesTreeWithProps = createSelector(
    [selectFavorites, selectFavoritesTreeProps],
    ({ entity }, { type }) => {
        return useMemo(() => {
            const favorites = type ? entity && getNodeByType(entity, type) : entity;
            const orderBy: SortFavoritesProps = [
                ['type', 'desc'],
                ['name', 'asc'],
            ];

            return sortFavorites(favorites, orderBy);
        }, [entity, type]);
    },
);
export const selectFavoritesTree =
    (props: SelectFavoritesTreeProps = {}) =>
    (state: AppState) =>
        selectFavoritesTreeWithProps(state, props);

// Actions & Reducer
export type FavoritesActions = TakeActions<typeof favorites.actions>;
export const { setFavorites, setStatus } = favorites.actions;
export const favoritesReducer = favorites.reducer;
