import { Popover, PopoverProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { VFC, MouseEventHandler, useState, ReactElement, useRef } from 'react';
import { AppTheme } from '../../../app';
import { ItemProps, Item } from '../item';

const useStyles = makeStyles<AppTheme>(() => ({
    container: {
        display: 'flex',
    },
}));

export type PopoverToolsProps = {
    /**
     * Closes the popover.
     */
    onClose?: () => void;
};

export type ItemWithPopoverProps = {
    /**
     * Mounts the popover component and passes states and control handlers.
     */
    popoverContent: (props: PopoverToolsProps) => ReactElement | null;
    popoverProps?: Omit<PopoverProps, 'open' | 'anchorEl' | 'onClose'>;
} & Omit<ItemProps, 'onClick' | 'active'>;

export const ItemWithPopover: VFC<ItemWithPopoverProps> = (props) => {
    const { popoverContent: PopoverContent, popoverProps = {}, ...itemProps } = props;
    const { classes: popoverClasses = {}, anchorOrigin, transformOrigin, ...restPopoverProps } = popoverProps;

    const classes = useStyles();
    const itemRef = useRef<HTMLButtonElement | null>(null);
    const [isActiveItem, setIsActiveItem] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOnOpenPopover: MouseEventHandler<HTMLElement> = (event) => {
        if (itemRef.current !== null) {
            itemRef.current.blur();
        }

        setIsActiveItem(true);
        setAnchorEl(event.currentTarget);
    };

    const handleOnClosePopover = (): void => {
        setIsActiveItem(false);
        setAnchorEl(null);
    };

    return (
        <div className={classes.container}>
            <Item {...itemProps} ref={itemRef} active={isActiveItem} onClick={handleOnOpenPopover} />

            <Popover
                {...restPopoverProps}
                classes={{
                    ...popoverClasses,
                    paper: classNames(popoverClasses.paper),
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleOnClosePopover}
                anchorOrigin={
                    anchorOrigin || {
                        vertical: 'bottom',
                        horizontal: 'right',
                    }
                }
                transformOrigin={
                    transformOrigin || {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                }>
                <PopoverContent onClose={handleOnClosePopover} />
            </Popover>
        </div>
    );
};
