import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem } from '@mui/lab';
import TreeView from '@mui/lab/TreeView';
import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useNavigate } from '../../../../hooks';
import { IMenuItem } from '../../../../reducers/types';
import { AppTheme } from '../../../app';
import { TargetScreenType } from '../../../common/types';

type TreePathProps = {
    path: string;
    link: string;
};

const useStyles = makeStyles<AppTheme>((theme) => ({
    pathbox: {
        display: 'flex',
        '& .MuiTreeItem-iconContainer': {
            marginRight: 0,
            width: 0,
        },
    },
    label: {
        paddingLeft: 0,
        fontFamily: theme.typography.fontFamily,
        fontSize: '13px !important',
        fontWeight: 600,
        textWrap: 'balance',
        color: theme.palette.text.secondary,
    },
    link: {
        textWrap: 'balance',
        fontFamily: theme.typography.fontFamily,
        fontSize: '13px !important',
    },
    chevron: {
        color: theme.palette.action.active,
    },
}));
export const TreePath: React.FC<TreePathProps> = (props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { navigate } = useNavigate();
    const classes = useStyles();
    const handleOnClick = (): void => {
        setIsExpanded(!isExpanded);
    };

    const renderLink = (text: string): JSX.Element => {
        return (
            <Link
                className={classes.link}
                component="button"
                onClick={(): void => {
                    navigate({
                        menuItem: {
                            screenType: TargetScreenType.Legacy,
                            url: props.link,
                            id: 0,
                        } as IMenuItem,
                    });
                }}>
                {text}
            </Link>
        );
    };
    const createItemsFromNodes = (nodes: string[], idx: number): JSX.Element => {
        if (nodes.length > 1) {
            return (
                <TreeItem key={idx} nodeId={idx.toString()} label={nodes[0]} classes={{ label: classes.label }}>
                    {createItemsFromNodes(nodes.slice(1), ++idx)}
                </TreeItem>
            );
        }
        return renderLink(nodes[0]);
    };

    const createTreeView = (): JSX.Element => {
        if (props.path) {
            const nodes = props.path.split('\\').slice(0, -1);
            if (nodes.length == 0) {
                return <></>;
            }
            if (!isExpanded) {
                return renderLink(nodes[nodes.length - 1]);
            }
            const expanded = Array.from({ length: nodes.length }, (_, i) => i.toString());
            return (
                <TreeView expanded={expanded} disableSelection className={classes.tree}>
                    {createItemsFromNodes(nodes, 0)}
                </TreeView>
            );
        }
        return <></>;
    };

    const chevronRight = (): JSX.Element => <ChevronRightIcon className={classes.chevron} />;
    const chevronDown = (): JSX.Element => <ExpandMoreIcon className={classes.chevron} />;

    return props.path ? (
        <div className={classes.pathbox}>
            <span onClick={handleOnClick}>{isExpanded ? chevronDown() : chevronRight()}</span>
            {createTreeView()}
        </div>
    ) : null;
};
