import DragHandleIcon from '@mui/icons-material/DragHandle';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import composeRefs from '@seznam/compose-react-refs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Draggable, DropResult } from 'react-beautiful-dnd';
import { useAppSelector } from '../../../store';
import { getAppResources } from '../../../store/slices';
import { useListScreenDispatch, useListScreenState } from '../context/listScreenContext';
import { getSavedViewTabName } from '../listScreenHelpers';
import ReorderableList from '../reorderableList';
import { IListScreenMetadata, ISavedView } from '../types';
import { RemoveTabActionDialog } from './removeTabActionDialog';
import { ResetToDefaultActionDialog } from './resetToDefaultActionDialog';
import css from './tabOptions.module.scss';
import { TabOptionsActionDialog } from './tabOptionsActionDialog';
import TabOptionsItemMoreMenu from './tabOptionsItemMoreMenu';

const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    // if no destination, or dropped in the original position
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
        return;
    }
};

const TabOptions: React.FC = () => {
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const metadata = listScreenState.metadata!;
    const [selectedTabName, setSelectedTabName] = useState('');
    const [selectedTabId, setSelectedTabId] = useState(-1);

    const editActionHandler = (savedViewId: number, tabName: string) => {
        listScreenDispatch({ type: 'OpenTabOptionsRenameDialog' });
        setSelectedTab(savedViewId, tabName);
    };

    const setSelectedTab = (savedViewId: number, tabName: string) => {
        setSelectedTabName(tabName);
        setSelectedTabId(savedViewId);
    };

    const useSystemDefault = metadata.savedViews.find((item) => item.isUserDefault == true) ? false : true;
    let savedViews: Array<ISavedView> = [];

    if (useSystemDefault) {
        savedViews.push(metadata.savedViews.find((item) => item.isSystemDefault == true)!);
    } else {
        savedViews.push(metadata.savedViews.find((item) => item.isUserDefault == true)!);
    }

    const orderedSavedViews = metadata.savedViews
        .filter((sv) => !sv.isSystemDefault && !sv.isUserDefault)
        .sort((sv1, sv2) => {
            const sv1Name = sv1.name ? sv1.name : '';
            const sv2Name = sv2.name ? sv2.name : '';
            return sv1Name.localeCompare(sv2Name);
        });

    savedViews = savedViews.concat(orderedSavedViews);

    return (
        <div className={css.container} data-testid="taboptions">
            <ReorderableList onDragEnd={onDragEnd}>
                {savedViews.map((currentItem, index) => (
                    <ReorderableListItem
                        currentItem={currentItem}
                        key={currentItem.id}
                        index={index}
                        metadata={metadata}
                        editActionHandler={editActionHandler}
                        setSelectedTabId={setSelectedTabId}
                    />
                ))}
            </ReorderableList>
            {listScreenState.tabOptionsRenameDialogOpen && (
                <TabOptionsActionDialog tabName={selectedTabName} tabId={selectedTabId} />
            )}
            {listScreenState.resetToDefaultDialogOpen && <ResetToDefaultActionDialog />}
            {listScreenState.tabOptionsRemoveTabDialogOpen && <RemoveTabActionDialog selectedTabId={selectedTabId} />}
        </div>
    );
};

interface IReorderableListItem {
    currentItem: ISavedView;
    index: number;
    metadata: IListScreenMetadata;
    onSelectItem?: (item: ISavedView) => void;
    onDoubleClickItem?: (item: ISavedView) => void;
    isSelected?: boolean | undefined;
    className?: string;
    editActionHandler: (item: number, tabName: string) => void;
    setSelectedTabId: (item: number) => void;
}

export const ReorderableListItem: React.FC<IReorderableListItem> = ({
    currentItem,
    index,
    metadata,
    isSelected,
    className,
    editActionHandler,
    setSelectedTabId,
}) => {
    const listItemRef = useRef<HTMLLIElement>(null);

    useEffect(() => {
        if (isSelected) {
            listItemRef.current!.focus();
        }
    }, [isSelected]);

    const appResources = useAppSelector(getAppResources);
    const isTabRenamable = !currentItem.isSystemDefault && !currentItem.isUserDefault && currentItem.isEditable;
    const listScreenState = useListScreenState();
    const tabName = useMemo(() => {
        const tabName = getSavedViewTabName(currentItem, metadata);
        return tabName === listScreenState.metadata!.screenDisplayName && listScreenState.isEmbeddedList
            ? appResources.listscreenDefaultDropdownText
            : tabName;
    }, [listScreenState, currentItem, metadata, appResources]);

    return (
        <Draggable draggableId={currentItem.id + ''} index={index}>
            {(provided, snapshot) => (
                <ListItem
                    {...provided.draggableProps}
                    ref={composeRefs<HTMLLIElement>(listItemRef, provided.innerRef)}
                    data-testid="selectedTransferBasketItem"
                    component="li"
                    className={`${css.listItem} ${snapshot.isDragging ? css.listItemDragging : className}`}
                    selected={isSelected}>
                    <ListItemText
                        primary={
                            <Tooltip
                                placement="top-start"
                                arrow
                                title={tabName}
                                classes={{
                                    tooltip: css.tooltip,
                                }}>
                                <Typography noWrap className={css.tabName}>
                                    {tabName}
                                </Typography>
                            </Tooltip>
                        }
                    />
                    <ListItemIcon className={css.listItemIcon}>
                        {isTabRenamable && (
                            <IconButton
                                data-testid={'rename-icon-' + tabName}
                                color="primary"
                                aria-label="edit"
                                onClick={() => editActionHandler(currentItem.id!, tabName)}
                                size="large">
                                <EditIcon />
                            </IconButton>
                        )}
                    </ListItemIcon>
                    {/* TODO: remove aria-hidden=true when draggable saved views is implemented */}
                    <Box {...provided.dragHandleProps} className={css.dragHandle} aria-hidden="true">
                        <DragHandleIcon />
                    </Box>
                    <TabOptionsItemMoreMenu currentItem={currentItem} setSelectedTabId={setSelectedTabId} />
                </ListItem>
            )}
        </Draggable>
    );
};

export default TabOptions;
