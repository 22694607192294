import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React, { FC } from 'react';
import './app.scss';
import { AppContainer, AppErrorBoundary, useAppTheme } from './components/app';
import { InitializeAppCallback, CustomComponents } from './initialize';
import { useAppSelector } from './store';

export type AppProps = {
    customComponents: CustomComponents;
    initializeAppCallback?: InitializeAppCallback;
};

export const App: FC<AppProps> = (props: AppProps) => {
    const { AppProvider } = props.customComponents;
    const colorTheme = useAppSelector((state) => state.app.hybridSettings.colorTheme);
    const appTheme = useAppTheme({ palette: { view: colorTheme } });

    return (
        <React.StrictMode>
            <AppProvider>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={appTheme}>
                        <CssBaseline />
                        <AppErrorBoundary>
                            <AppContainer {...props} />
                        </AppErrorBoundary>
                    </ThemeProvider>
                </StyledEngineProvider>
            </AppProvider>
        </React.StrictMode>
    );
};
