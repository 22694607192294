import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';

export const WkZoomOutMagnifyGlass = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon data-testid="wkZoomOutMagnifyGlass" viewBox="-3 -3 21 21" {...props} ref={ref}>
        <path
            d="M 15.6542 16.2861 L 10.5662 11.1981 C 9.4687 12.0941 8.0973 12.587 6.6805 12.5946 C 5.8742 12.6025 5.0742 12.4512 4.3264 12.1494 C 3.5786 11.8477 2.8977 11.4013 2.3226 10.8361 C 1.7476 10.2708 1.2897 9.5976 0.9751 8.8551 C 0.6606 8.1126 0.4956 7.3153 0.4897 6.5089 C 0.4762 5.6936 0.6284 4.8841 0.9371 4.1294 C 1.2457 3.3746 1.7043 2.6904 2.2851 2.1181 C 3.4367 0.968 4.9976 0.3221 6.6251 0.3221 C 8.2526 0.3221 9.8135 0.968 10.9651 2.1181 C 12.0803 3.2724 12.7213 4.8037 12.7605 6.4084 C 12.7528 7.8252 12.2599 9.1965 11.3639 10.2941 L 16.4519 15.3821 L 15.6519 16.2803 L 15.6542 16.2861 Z M 6.6748 1.5204 C 5.3792 1.5228 4.1373 2.0385 3.2211 2.9547 C 2.305 3.8708 1.7892 5.1127 1.7868 6.4084 C 1.7784 7.0627 1.9073 7.7115 2.1649 8.3131 C 2.4226 8.9146 2.8034 9.4555 3.2828 9.9009 C 4.1957 10.8139 5.434 11.3268 6.7251 11.3268 C 8.0162 11.3268 9.2544 10.8139 10.1674 9.9009 C 11.0803 8.988 11.5933 7.7498 11.5933 6.4587 C 11.5933 5.1675 11.0803 3.9293 10.1674 3.0164 C 9.7162 2.5443 9.1742 2.1684 8.574 1.9113 C 7.9738 1.6542 7.3278 1.5212 6.6748 1.5204 Z M 5.9754 7.1067 H 2.9834 V 5.8084 H 10.3662 V 7.1067 H 5.9754 Z"
            fill="currentColor"
        />
    </SvgIcon>
));

WkZoomOutMagnifyGlass.displayName = 'WkZoomOutMagnifyGlass';
