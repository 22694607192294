import { apiFetch } from '../../../../../utils/fetchUtils';

export const useConfirmAction = () => {
    const { apiContextRoot, apiContextPath } = window.Props;
    const apiBaseUrl = `${String(apiContextRoot)}${String(apiContextPath).replace("views", "")}`;
    const params = new URLSearchParams(window.location.search)
    const invoiceId = params.get('matter_invoice_id');

    const adjustData = async (lineItemId: string, actionParameters: any): Promise<any> => {
        const url = `${apiBaseUrl}invoices/${invoiceId}/lineitems/${lineItemId}/adjust`;
        const amount = {
            Amount: actionParameters.AdjustMentAmount,
            CurrencyCode: 'USD'
        };
        const data = {
            BillCodeGroupBillCodeId: actionParameters.BillCodeGroupBillCodeId,
            Narrative: actionParameters.Narrative,
            Amount: amount,
            AdjustmentStyle: actionParameters.AdjustmentStyle,
            AdjustmentType: actionParameters.AdjustmentType
        };
        return await apiFetch<any>(url, data);
    };

    const rejectData = async (lineItemId: string, actionParameters: any): Promise<any> => {
        const url = `${apiBaseUrl}invoices/${invoiceId}/lineitems/${lineItemId}/reject`;

        const data = {
            BillCodeGroupBillCodeId: actionParameters.BillCodeGroupBillCodeId,
            Narrative: actionParameters.Narrative,
        };
        return await apiFetch<any>(url, data);
    };

    const undoAdjustments = async (lineItemId: string, action: string): Promise<any> => {
        const url = `${apiBaseUrl}invoices/${invoiceId}/lineitems/${lineItemId}/${action}`;

        return await apiFetch<any>(url);
    };

    const getMetaData = async () => {
        const url = `${apiBaseUrl}action/getMetaData/${invoiceId}`;
        const response = await apiFetch(url);
        return response;
    };

    const getdDropDownSource = async (reviewActionType: number, isFeesOrExpenses: string): Promise<any> => {
        const url = `${apiBaseUrl}action/${reviewActionType}/getreasoncodesource/${isFeesOrExpenses}`;
        const response = await apiFetch<any>(url);
        return response.Table;
    };
    return { adjustData, rejectData, undoAdjustments, getMetaData, getdDropDownSource };
};