import React from 'react';
import { useUUIHistory } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../store';
import { closeUserScreenDialog, getAppResources, setActiveContextId } from '../../store/slices';
import { getPassportContextLandingPageUrl } from '../../utils/passportContextHelper';
import FullScreenDialog, {
    FullScreenDialogActions,
    FullScreenDialogButton,
    FullScreenDialogContent,
} from '../common/fullScreenDialog';
import UUIButton from '../common/uuiButton';
import { UserScreenProvider, useUserScreenState } from './context/userScreenContext';
import css from './userScreen.module.scss';
import UserScreenTabsView from './userScreenTabsView';

const UserScreen: React.FC = () => (
    <UserScreenProvider>
        <UserScreenComponent />
    </UserScreenProvider>
);

const UserScreenComponent: React.FC = () => {
    const appResources = useAppSelector(getAppResources);
    const passportContexts = useAppSelector((state) => state.appsMenu.passportContexts);
    const { v3HomeUrl: fallbackLandingPage } = useAppSelector(getAppResources);
    const history = useUUIHistory();
    const [userScreenState] = useUserScreenState();
    const isUserScreenDialogOpen = useAppSelector((state) => state.ui.userScreen.isUserScreenDialogOpen);

    const dispatch = useAppDispatch();

    function onDone(): void {
        const selectedPassportContext = passportContexts.find(
            (passportContext) => passportContext.id === userScreenState.selectedContext,
        )!;
        window.sessionStorage.activeContext = userScreenState.selectedContext;
        dispatch(closeUserScreenDialog());
        dispatch(setActiveContextId({ context: userScreenState.selectedContext }));
        history.push(getPassportContextLandingPageUrl(selectedPassportContext, fallbackLandingPage));
    }

    return (
        <FullScreenDialog
            testId="userscreen-dialog"
            isOpen={isUserScreenDialogOpen || false}
            onClose={(): void => {
                dispatch(closeUserScreenDialog());
            }}
            AppBarProps={{ elevation: 0 }}
            showAppHeader={true}>
            <FullScreenDialogContent className={css.dialogContent}>
                <div className={css.root}>
                    <div className={css.useNameContainer}>
                        <span className={css.userName}>{appResources.v3DisplayName}</span>
                    </div>
                    <div>
                        <UserScreenTabsView />
                    </div>
                </div>
            </FullScreenDialogContent>
            <FullScreenDialogActions>
                <UUIButton
                    data-testid={'done-button'}
                    variant="contained"
                    color="primary"
                    size="large"
                    className={css.button}
                    onClick={onDone}>
                    {appResources.buttonDone}
                </UUIButton>

                <FullScreenDialogButton
                    testId="cancel-button"
                    variant="outlined"
                    onClick={(): void => {
                        dispatch(closeUserScreenDialog());
                    }}>
                    {appResources.dialogCancel}
                </FullScreenDialogButton>
            </FullScreenDialogActions>
        </FullScreenDialog>
    );
};

export default UserScreen;
