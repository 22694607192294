import { Button, MenuItem, Input, FormControlLabel, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { CSSProperties, forwardRef, useState, useEffect, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useAppDispatch } from '../../../../../src/store';
import { NotificationPlacement } from '../../../../reducers/types';
import { FilterComponent, FilterComponentItem, showNotification } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { Tooltip } from '../../../common';
import { ControlType } from '../../../common/types';
import { FilterAccordion } from './accordion';
import { Field } from './field';
import { useFilterView } from './hooks';
import { Select } from './select';

const useStyles = makeStyles<AppTheme>((theme) => ({
    filterArea: {
        margin: '0px !important;',
    },
    filterDropDown: {
        padding: 4,
        height: 23,
        width: 244,
    },
    customFilterDropDown: {
        padding: 4,
        width: 120,
        maxWidth: 120,
    },
    filterCheckbox: {
        position: 'relative',
        left: 170,
        border: `0px solid ${theme.palette.grey[500]}`,
        fontSize: '0.95rem',
        marginLeft: 0,
    },
    filterTextBox: {
        padding: 4,
        height: 23,
        width: 244,
        border: `1px solid ${theme.palette.grey[500]}`,
        fontSize: '0.95rem',
        '&:hover': {
            borderColor: theme.palette.primary.dark,
            borderWidth: 2,
        },
        '& .MuiInput-input:focus': {
            borderColor: theme.palette.common.black,
            borderWidth: 2,
        },
    },
    customTextBox: {
        padding: 4,
        height: 23,
        width: 120,
        border: `1px solid ${theme.palette.grey[500]}`,
        fontSize: '0.95rem',
        '& .MuiInput-input': {
            textAlign: 'right',
        },
        '&:hover': {
            borderColor: theme.palette.primary.dark,
            borderWidth: 2,
        },
        '& .MuiInput-input:focus': {
            borderColor: theme.palette.common.black,
            borderWidth: 2,
        },
    },
    filterSection: {
        display: 'inline-block',
        verticalAlign: 'top',
    },
    searchButton: {
        display: 'block;',
        marginBottom: '8px',
        lineHeight: '20px',
        boxShadow: 'none',
        width: 130,
    },
    customWidth: {
        padding: 4,
        width: 120,
    },
    reactDatePicker: {
        height: 23,
        padding: 4,
        width: 120,
    },
}));

type FilterProps = {
    component: FilterComponent;
    onSetSharedStorage?: (key: string, value: unknown) => void;
    classes?: {
        root?: string;
    };
    style?: CSSProperties;
};

// eslint-disable-next-line max-lines-per-function
export const SearchView = forwardRef<HTMLDivElement, FilterProps>((props, ref) => {
    const classes = useStyles();
    const { component, onSetSharedStorage } = props;
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [checkbox, setCheckbox] = useState<boolean>(false);
    const initialValues: Record<string, string> = {};
    component.filters.forEach((filter) => {
        initialValues[filter.filterCode] = filter.selectedValue;
    });
    initialValues['onLoad'] = 'true';
    const [filterValues, setFilterValues] = useState<Record<string, string>>(initialValues);
    const { dropDownSources, updateDependentSources } = useFilterView({
        component,
        filterValues,
    });
    const startDatePickerRef = useRef<ReactDatePicker>(null);
    const endDatePickerRef = useRef<ReactDatePicker>(null);

    useEffect(() => {
        doGridSearch(filterValues);
    }, []);

    const [selectedDropdownValue, setSelectedDropdownValue] = useState<string>('1');

    const filterSelectedValueChange = (value: string, code: string) => {
        const newFilterValues = { ...filterValues, [code]: value };
        setFilterValues(newFilterValues);
        void updateDependentSources(code, newFilterValues);
    };
    const dispatch = useAppDispatch();
    const handleSearchButtonClick = (): void => {
        const errorMessage = validateDate();
        if (errorMessage.length > 0) {
            dispatch(
                showNotification({
                    notification: {
                        message: errorMessage.map((error) => `- ${error}`).join('<br/>'),
                        options: {
                            variant: 'warning',
                            persist: true,
                        },
                        placement: NotificationPlacement.Top,
                    },
                }),
            );
            return;
        }
        filterValues['onLoad']='false';
        doGridSearch(filterValues);
    };

    const validateDate = (): string[] => {
        const errors: string[] = [];

        if (startDate && !isValidDateFormat(startDate)) {
            errors.push("'Date'- wrong range used or invalid date format. Allowed date format is 'M/d/yyyy'");
        }
        if (endDate && !isValidDateFormat(endDate)) {
            errors.push("'Date'- wrong range used or invalid date format. Allowed date format is 'M/d/yyyy'");
        }
        if (startDate && endDate && isDateGreaterThan(startDate, endDate)) {
            errors.push("First date must be less than Second date in 'Date' date between filter");
        }
        return errors;
    };

    const isValidDateFormat = (date: Date): boolean => {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        const dateString = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;

        const pattern = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
        return pattern.test(dateString);
    };

    const isDateGreaterThan = (startDate: Date, endDate: Date): boolean => {
        return startDate.getTime() > endDate.getTime();
    };

    const handleResetButtonClick = (): void => {
        setStartDate(null);
        setEndDate(null);
        setCheckbox(false);
        setSelectedDropdownValue('');
        setFilterValues({});
    };

    const doGridSearch = (filterValues: Record<string, string>): void => {
        onSetSharedStorage?.(`grid.${component.dataSource}.filter`, filterValues);
    };

    const totalColumnIndex = component.columnIndex + 1;

    const renderTextbox = (filter: FilterComponentItem): JSX.Element => {
        return (
            <Tooltip title={'Enter ' + filter.title}>
                <Input
                    className={classes.filterTextBox}
                    disableUnderline
                    value={filterValues[filter.filterCode] ?? ''}
                    onKeyDown={(event): void => {
                        event.key.toLowerCase() === 'enter' && handleSearchButtonClick();
                    }}
                    onChange={(event): void => filterSelectedValueChange(event.target.value, filter.filterCode)}
                />
            </Tooltip>
        );
    };

    const renderDropdown = (filter: FilterComponentItem): JSX.Element => {
        return (
            <Tooltip title={'Select ' + filter.title}>
                <div>
                    <Select
                        variant="standard"
                        className={classes.filterDropDown}
                        value={filterValues[filter.filterCode] ?? ''}
                        onChange={(event) =>
                            filterSelectedValueChange(event.target.value as string, filter.filterCode)
                        }>
                        {dropDownSources && dropDownSources[filter.filterCode] ? (
                            dropDownSources[filter.filterCode].map((dropDownItem, index) => (
                                <MenuItem key={index} value={dropDownItem.id}>
                                    {dropDownItem.name}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem value={''}></MenuItem>
                        )}
                    </Select>
                </div>
            </Tooltip>
        );
    };

    const renderCheckbox = (filter: FilterComponentItem, index: string | number): JSX.Element => {
        return (
            <Field name={filter.title ? `${filter.title}:` : ''} key={index}>
                <FormControlLabel
                    className={classes.filterCheckbox}
                    control={
                        <Checkbox
                            size="small"
                            onChange={(event) => {
                                setCheckbox(event.target.checked);
                                filterSelectedValueChange(event.target.checked.toString(), filter.filterCode);
                            }}
                            checked={checkbox}
                        />
                    }
                    label={filter.checkBoxLabel}
                />
            </Field>
        );
    };

    const renderDropdownTextbox = (filter: FilterComponentItem): JSX.Element => {
        return (
            <Tooltip title={`Select ${filter.title}`}>
                <div>
                    <Select
                        variant="standard"
                        className={(classes.filterDropDown, classes.customWidth)}
                        value={filterValues[filter.filterCode] ?? filter.selectedValue}
                        onChange={(event) =>
                            filterSelectedValueChange(event.target.value as string, filter.filterCode)
                        }>
                        {filter.dropdownSource.map(({ value, displayValue }, index) => (
                            <MenuItem key={index} value={value}>
                                {displayValue}
                            </MenuItem>
                        ))}
                    </Select>
                    <Input
                        className={classes.customTextBox}
                        disableUnderline
                        value={filterValues[filter?.customFiltersCode?.[0]] ?? ''}
                        type="number"
                        inputMode="decimal"
                        style={{ textAlign: 'right' }}
                        onKeyDown={(event) => {
                            if (event.key.toLowerCase() === 'enter') {
                                handleSearchButtonClick();
                            }
                        }}
                        onChange={(event) =>
                            filterSelectedValueChange(event.target.value, filter?.customFiltersCode?.[0])
                        }
                    />
                </div>
            </Tooltip>
        );
    };

    const handleStartDateKeyDown = (event: any, code: string) => {
        if (event.key === 'Backspace' || event.key === 'Delete') {
            setStartDate(null);
            filterSelectedValueChange('', code);
        }
    };

    const handleEndDateKeyDown = (event: any, code: string) => {
        if (event.key === 'Backspace' || event.key === 'Delete') {
            setEndDate(null);
            filterSelectedValueChange('', code);
        }
    };

    const handleDatePicker = (ref: React.Ref<ReactDatePicker>, code: string) => (date: Date | [Date, Date] | null) => {
        if (date instanceof Date) {
            const setDate = ref === startDatePickerRef ? setStartDate : setEndDate;
            setDate(date);
            const dateFormat =
                date?.toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                }) || '';
            filterSelectedValueChange(dateFormat, code);
        }
    };

    const renderDateWithDropDown = (filter: FilterComponentItem): JSX.Element => {
        return (
            <Tooltip title={`Select ${filter.title}`}>
                <div>
                    <div>
                        <Select
                            variant="standard"
                            className={(classes.filterDropDown, classes.customWidth)}
                            value={filterValues[filter.filterCode] ?? filter.selectedValue}
                            onChange={(event) => {
                                filterSelectedValueChange(event.target.value as string, filter.filterCode);
                                setSelectedDropdownValue(event.target.value as string);
                            }}>
                            {filter.dropdownSource.map(({ value, displayValue }, index) => (
                                <MenuItem key={index} value={value}>
                                    {displayValue}
                                </MenuItem>
                            ))}
                        </Select>
                        <ReactDatePicker
                            ref={startDatePickerRef}
                            selected={startDate}
                            className={classes.reactDatePicker}
                            onChange={handleDatePicker(startDatePickerRef, filter?.customFiltersCode?.[0])}
                            dateFormat="MM/dd/yyyy"
                            showYearDropdown={true}
                            showMonthDropdown={true}
                            onKeyDown={(e) => handleStartDateKeyDown(e, filter?.customFiltersCode?.[0])}
                        />
                    </div>
                    <div
                        id={`data-testId-${filter.filterCode}`}
                        style={{ display: selectedDropdownValue === '4' ? 'block' : 'none' }}>
                        <span style={{ paddingRight: '2px', marginLeft: '87px' }}>and: </span>
                        <ReactDatePicker
                            ref={endDatePickerRef}
                            selected={endDate}
                            className={classes.reactDatePicker}
                            onChange={handleDatePicker(endDatePickerRef, filter?.customFiltersCode?.[1])}
                            dateFormat="MM/dd/yyyy"
                            showYearDropdown={true}
                            showMonthDropdown={true}
                            onKeyDown={(e) => handleEndDateKeyDown(e, filter?.customFiltersCode?.[1])}
                        />
                    </div>
                </div>
            </Tooltip>
        );
    };

    return (
        <div data-testid="page-grid-filter" className={classes.filterArea} ref={ref}>
            <FilterAccordion isExpanded={true}>
                {[...Array(totalColumnIndex)].map((_, sectionIndex) => (
                    <div className={classes.filterSection}>
                        {component.filters
                            .filter((filterComponent) => filterComponent.columnIndex === sectionIndex)
                            .map((filter, index) => {
                                if (filter.type === ControlType.TextBox) {
                                    return (
                                        <Field name={filter.title} key={index}>
                                            {renderTextbox(filter)}
                                        </Field>
                                    );
                                }
                                if (filter.type === ControlType.DropDown) {
                                    return (
                                        <Field name={filter.title} key={index}>
                                            {renderDropdown(filter)}
                                        </Field>
                                    );
                                }
                                if (filter.type === ControlType.CheckBox) {
                                    return <div>{renderCheckbox(filter, index)}</div>;
                                }
                                if (filter.type === ControlType.DateWithDropDown) {
                                    return (
                                        <Field name={filter.title} key={index}>
                                            {renderDateWithDropDown(filter)}
                                        </Field>
                                    );
                                }
                                if (filter.type === ControlType.DropDownWithTextBox) {
                                    return (
                                        <Field name={filter.title} key={index}>
                                            {renderDropdownTextbox(filter)}
                                        </Field>
                                    );
                                }
                                return;
                            })}
                    </div>
                ))}
                <div className={classes.filterSection}>
                    {component.filterButtons.map((filterButton, index) => {
                        return (
                            <Tooltip title={filterButton.text}>
                                <Button
                                    key={index}
                                    disableRipple
                                    variant={filterButton.style}
                                    color="primary"
                                    className={classes.searchButton}
                                    onClick={
                                        filterButton.action === 'Search'
                                            ? () => handleSearchButtonClick()
                                            : () => handleResetButtonClick()
                                    }>
                                    {filterButton.text}
                                </Button>
                            </Tooltip>
                        );
                    })}
                </div>
            </FilterAccordion>
        </div>
    );
});

SearchView.displayName = 'SearchView';
