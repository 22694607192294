import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { forwardRef, useState, VFC } from 'react';
import { IMenuItem, NotificationPlacement } from '../../../reducers/types';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
    FavoriteFolderEntity,
    FavoriteType,
    selectFavoritesTree,
    ShortcutFavoriteEntity,
    showNotification,
} from '../../../store/slices';
import { AppTheme } from '../../app';
import { Tooltip } from '../../common';
import { WkShortcut, WkShortcutAdd, WkShortcutAddFilled, WkShortcutRemove, WkShortcutRemoveFilled } from '../../icons';
import { RemoveFavoriteDialog, RemoveFavoriteDialogProps } from '../dialogs';
import { OpenDialogType } from '../favoritesActions';
import { useFavorites } from '../hooks';

const useStyles = makeStyles<AppTheme>((theme) => ({
    button: {
        padding: theme.spacing(0.5),
        minWidth: 'auto',

        '&:hover, &:focus, &.Mui-focusVisible': {
            color: 'inherit',
            backgroundColor: theme.palette.action.hover,

            '& $action': {
                visibility: 'hidden',
            },
            '& $filledAction': {
                visibility: 'visible',
            },
            '&+$shortcut': {
                display: 'none',
            },
        },
    },
    shortcut: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5),
        top: 0,
        bottom: 0,
        position: 'absolute',

        '&:hover, &:focus': {
            display: 'none',
        },
    },
    filledAction: {
        visibility: 'hidden',
    },
    action: {
        position: 'absolute',
        visibility: 'hidden',
    },
}));

type ShortcutProps = {
    item: IMenuItem;
    classes?: {
        shortcut?: string;
        action?: string;
    };
};

export const ShortcutAction: VFC<ShortcutProps> = forwardRef<HTMLButtonElement, ShortcutProps>(function ShortcutAction(
    props,
    ref,
) {
    const classes = useStyles();
    const { item, classes: externalClasses } = props;
    const dispatch = useAppDispatch();
    const [openDialog, setOpenDialog] = useState<OpenDialogType>();
    const { createShortcut } = useFavorites();
    const shortcutsTree = useAppSelector(selectFavoritesTree({ type: FavoriteType.Shortcut })) as
        | FavoriteFolderEntity
        | undefined;
    const shortcutsIds = (shortcutsTree?.children as ShortcutFavoriteEntity[] | undefined)?.map(
        ({ data }) => data.code,
    );

    const onClose: RemoveFavoriteDialogProps['onClose'] = (): void => {
        setOpenDialog(undefined);
    };

    const addHandler = async (): Promise<void> => {
        if (!item.code || !shortcutsTree) {
            return;
        }

        try {
            await createShortcut({
                id: item.code,
                parentId: shortcutsTree.id,
                name: item.name,
                type: FavoriteType.Shortcut,
                data: item,
            });

            dispatch(
                showNotification({
                    notification: {
                        message: `"${item.name}" has been added to Favorites.`,
                        options: {
                            variant: 'success',
                        },
                        placement: NotificationPlacement.Top,
                    },
                }),
            );
        } catch (error) {
            // ignored // FIXME: fetch wrappers do a lot of non-generic stuff, requires refactoring
        }
    };

    const removeHandler = (): void => {
        setTimeout(() => setOpenDialog(OpenDialogType.Remove), 100);
    };

    // TODO: use IconButton and apply common WK Design styles on mui theme level for it
    return (
        <>
            {shortcutsIds?.includes(item.code) ? (
                <>
                    <Tooltip title="Delete from Favorites" placement="right">
                        <Button
                            ref={ref}
                            onClick={removeHandler}
                            color="primary"
                            disableRipple
                            classes={{
                                root: classes.button,
                            }}>
                            <WkShortcutRemove className={classNames(classes.action, externalClasses?.action)} />
                            <WkShortcutRemoveFilled className={classes.filledAction} />
                        </Button>
                    </Tooltip>

                    <div className={classNames(classes.shortcut, externalClasses?.shortcut)}>
                        <WkShortcut />
                    </div>
                </>
            ) : (
                <Tooltip title="Add to Favorites" placement="right">
                    <Button
                        ref={ref}
                        onClick={addHandler}
                        color="primary"
                        disableRipple
                        classes={{
                            root: classes.button,
                        }}>
                        <WkShortcutAdd className={classNames(classes.action, externalClasses?.action)} />
                        <WkShortcutAddFilled className={classes.filledAction} />
                    </Button>
                </Tooltip>
            )}
            {openDialog === OpenDialogType.Remove && item.code && (
                <RemoveFavoriteDialog open={true} entityId={item.code} onClose={onClose} />
            )}
        </>
    );
});
