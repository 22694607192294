import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkLegalEngagement = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkAddIcon" viewBox="0 0 20 24" {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4032 0H0V24H19.0726V8.57143L10.4032 0ZM16.5804 8.57174H10.4034V2.4646L16.5804 8.57174ZM17.339 22.286H1.73413V1.7146H8.66962V10.286H17.339V22.286Z" fill="white" />
        <rect x="8.74023" y="10.9492" width="7.56358" height="1.04956" fill="white" />
        <rect x="8.74023" y="12.5234" width="3.45005" height="1.04956" fill="white" />
        <rect x="8.74023" y="14.1016" width="5.97124" height="1.04956" fill="white" />
        <rect x="13.6504" y="12.5234" width="2.65389" height="1.04956" fill="white" />
        <path d="M14.8657 20.0941C15.0641 19.5874 15.121 19.0363 15.0303 18.4998C14.9395 17.9633 14.7046 17.4615 14.3506 17.0483L13.668 17.633C13.916 17.9224 14.0806 18.2738 14.1441 18.6496C14.2077 19.0254 14.1678 19.4114 14.0288 19.7663L14.8657 20.0941Z" fill="white" />
        <path d="M14.5382 20.7086C14.2873 21.0707 13.9597 21.3731 13.5788 21.5943C13.1978 21.8155 12.7728 21.9501 12.334 21.9886C11.8951 22.027 11.4532 21.9683 11.0396 21.8167C10.626 21.665 10.2508 21.4241 9.94081 21.1111C9.6308 20.7981 9.39353 20.4207 9.24585 20.0057C9.09817 19.5906 9.04371 19.1482 9.08635 18.7097C9.12898 18.2712 9.26765 17.8475 9.49251 17.4687C9.71737 17.0899 10.0229 16.7652 10.3874 16.5178L10.8996 17.2724C10.6459 17.4446 10.4333 17.6705 10.2767 17.9342C10.1202 18.1979 10.0237 18.4928 9.99406 18.7979C9.96438 19.1031 10.0023 19.4111 10.1051 19.7C10.2079 19.9888 10.373 20.2515 10.5888 20.4694C10.8045 20.6872 11.0656 20.8549 11.3535 20.9604C11.6414 21.0659 11.949 21.1068 12.2544 21.0801C12.5599 21.0533 12.8557 20.9596 13.1208 20.8056C13.386 20.6517 13.6139 20.4412 13.7886 20.1892L14.5382 20.7086Z" fill="white" />
        <path d="M13.6762 16.4648C13.2656 16.205 12.798 16.0488 12.3137 16.0097C11.8293 15.9706 11.3428 16.0498 10.8958 16.2403L11.2478 17.0661C11.561 16.9325 11.902 16.8771 12.2414 16.9045C12.5808 16.9319 12.9085 17.0413 13.1963 17.2234L13.6762 16.4648Z" fill="white" />
    </SvgIcon>
);
