import { MenuListProps, MenuProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { VFC, MouseEventHandler, useRef, useState, FC } from 'react';
import { Item, ItemProps } from '../item';

const useStyles = makeStyles({
    container: {
        display: 'flex',
    },
});

const menuListProps: Partial<MenuListProps> = {
    'aria-labelledby': 'basic-button',
};

export type ItemWithMenuProps = Omit<ItemProps, 'onClick' | 'active'> & {
    /**
     * Mounts a menu content and passes states and control handlers.
     */
    component: FC<MenuProps>;

    /**
     * Value indicating whether the menu keep mounted.
     */
    keepMounted?: boolean;
};

export const ItemWithMenu: VFC<ItemWithMenuProps> = (props) => {
    const { component: MenuComponent, keepMounted = false, ...itemProps } = props;

    const classes = useStyles();
    const itemRef = useRef<HTMLButtonElement | null>(null);
    const [isActiveItem, setIsActiveItem] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOnOpenMenu: MouseEventHandler<HTMLElement> = (event) => {
        itemRef.current?.blur();

        setIsActiveItem(true);
        setAnchorEl(event.currentTarget);
    };

    const handleOnCloseMenu = (_?: object, reason?: 'escapeKeyDown' | 'backdropClick' | 'tabKeyDown'): void => {
        if (reason === 'tabKeyDown' || reason === 'escapeKeyDown') {
            setTimeout(() => itemRef.current?.focus(), 0);
        }

        setIsActiveItem(false);
        setAnchorEl(null);
    };

    return (
        <div className={classes.container}>
            <Item {...itemProps} ref={itemRef} active={isActiveItem} onClick={handleOnOpenMenu} />

            <MenuComponent
                keepMounted={keepMounted}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleOnCloseMenu}
                onClick={() => handleOnCloseMenu()}
                MenuListProps={menuListProps}
            />
        </div>
    );
};
