import { FormControlLabel, Radio, RadioGroup, Typography, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { IPassportContext } from '../../reducers/types';
import { useAppSelector } from '../../store';
import { getAppResources } from '../../store/slices';
import { useUserScreenState } from './context/userScreenContext';
import css from './functionViewScreen.module.scss';

const FunctionViewScreen: React.FC = () => {
    const appResources = useAppSelector(getAppResources);
    const passportContexts = useAppSelector((state) => state.appsMenu.passportContexts);
    const menuActiveContext = useAppSelector((state) => state.appsMenu.activeContext);

    const [userScreenState, userScreenDispatch] = useUserScreenState();

    useEffect(() => {
        if (userScreenState.selectedContext === 0) {
            userScreenDispatch({ type: 'SetSelectedContext', selectedContext: menuActiveContext });
        }
    }, [menuActiveContext, userScreenDispatch, userScreenState.selectedContext]);

    const getRadioGroup = (contexts: IPassportContext[]) => (
        <RadioGroup
            value={userScreenState.selectedContext}
            onChange={(e) =>
                userScreenDispatch({ type: 'SetSelectedContext', selectedContext: parseInt(e.target.value) })
            }>
            {contexts.map((passportContext, index) => (
                <FormControlLabel
                    key={index}
                    value={passportContext.id}
                    data-testid="app-function-radio-item"
                    control={<Radio color="primary" />}
                    label={passportContext.displayName}
                    name={passportContext.displayName}
                />
            ))}
        </RadioGroup>
    );

    return (
        <div className={css.radioContainer}>
            <Grid item xs={6} className={css.grid}>
                <Typography variant="inherit" className={css.functionTabTitle}>
                    {appResources.userscreenFunctionLabel}
                </Typography>
                {passportContexts.length > 1 ? (
                    getRadioGroup(passportContexts)
                ) : (
                    <p>{passportContexts[0].displayName}</p>
                )}
            </Grid>
        </div>
    );
};

export default FunctionViewScreen;
