import { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { ERROR_403_MESSAGE, FAILED_FETCH_MESSAGE } from '../../../utils/fetchUtils';

export const useGlobalErrorHandler = (): void => {
    const errorHandler = useErrorHandler();

    useEffect(() => {
        const ignoredErrorMessages = [FAILED_FETCH_MESSAGE, ERROR_403_MESSAGE];
        const errorIsHandledByDifferentOverlay = (e: ErrorEvent) =>
            ignoredErrorMessages.find((m) => e.message.includes(m) || m.includes(e.message)) !== undefined;

        const errorEventListener = (e: ErrorEvent) => {
            if (!errorIsHandledByDifferentOverlay(e)) {
                // TODO: Identify an optimal solution to address this issue. Reference: https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
                if (e.message.includes('ResizeObserver loop')) {
                    return;
                }
                errorHandler(e);
            }
        };

        const unhandledRejectionListener = (e: PromiseRejectionEvent) => {
            if (!errorIsHandledByDifferentOverlay(e.reason as ErrorEvent)) {
                errorHandler(e.reason);
            }
        };

        window.addEventListener('error', errorEventListener);
        window.addEventListener('unhandledrejection', unhandledRejectionListener);

        return () => {
            window.removeEventListener('error', errorEventListener);
            window.removeEventListener('unhandledrejection', unhandledRejectionListener);
        };
    }, [errorHandler]);
};
