import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import useUniqueId from '../../hooks/useUniqueId';

const WkPowerPointIcon: React.FC<SvgIconProps> = (props) => {
    const lgPowerPointId = useUniqueId('lgPowerPoint_');
    const lgPowerPointId2 = useUniqueId('lgPowerPoint2_');
    return (
        <SvgIcon data-testid="wkPowerPointIcon" viewBox="0 0 16 16" {...props}>
            <defs>
                <linearGradient id={lgPowerPointId} y1=".054" x2="1.109" y2="1.083" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#741f0e" />
                    <stop offset="1" stopColor="#b83011" />
                </linearGradient>
                <linearGradient
                    id={lgPowerPointId2}
                    x1=".295"
                    y1=".5"
                    x2=".98"
                    y2=".5"
                    gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#f0f0f0" />
                    <stop offset="1" stopColor="#fff" />
                </linearGradient>
            </defs>
            <g transform="translate(-1862 -966)">
                <rect width="16" height="16" rx="1" transform="translate(1862 966)" fill={`url(#${lgPowerPointId})`} />
                <g>
                    <path
                        d="M30.672 28.35v2.812H29V23h3.38q3.085 0 3.085 2.6a2.5 2.5 0 01-.885 1.989 3.506 3.506 0 01-2.365.76zm0-4.105v2.711h1.47q1.554 0 1.554-1.371 0-1.34-1.554-1.34z"
                        transform="translate(1837.52 946.83)"
                        fill={`url(#${lgPowerPointId2})`}
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

export default WkPowerPointIcon;
