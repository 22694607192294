// https://developers.pendo.io/docs/?bash#introduction
import { AppStore } from '../reducers/types';

function initialize(initialState: AppStore): void {
    const enablePendo = initialState.appResources.enablePendo;
    const pendoId = initialState.appResources.pendoId;
    const pendoPath = initialState.applicationUrls.pendoPath;
    const pendoCDNBaseUrl = initialState.applicationUrls.pendoCDNBaseUrl;

    if (enablePendo === 'true' && pendoId !== null && pendoId !== '') {
        (function (apiKey) {
            (function (p, e, n, d, o) {
                // @ts-ignore
                var v, w, x, y, z;
                o = p[d] = p[d] || {};
                // @ts-ignore
                o._q = [];
                v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
                for (w = 0, x = v.length; w < x; ++w) {
                    (function (m) {
                        // @ts-ignore
                        o[m] =
                            // @ts-ignore
                            o[m] ||
                            function () {
                                // eslint-disable-next-line prefer-rest-params
                                // @ts-ignore
                                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                            };
                    })(v[w]);
                }
                y = e.createElement(n);
                // @ts-ignore
                y.async = !0;
                // @ts-ignore
                y.src = pendoCDNBaseUrl.replace('{apiKey}', apiKey);
                z = e.getElementsByTagName(n)[0];
                // @ts-ignore
                z.parentNode.insertBefore(y, z);
            })(window, document, 'script', 'pendo');

            window
                .fetch(window.Props['apiContextRoot'] + window.Props['apiContextPath'] + pendoPath, {
                    credentials: 'same-origin',
                })
                .then((response) => response.json())
                .then((json) => {
                    // @ts-ignore
                    window.pendo.initialize(json);
                });
        })(pendoId);
    }
}

export const pendoAgent = {
    initialize,
};
