import { merge } from 'lodash';
import React, { useReducer, createContext, useContext } from 'react';
import { IUserScreenState, UserScreenDispatch } from '../types';
import { userScreenReducer } from './userScreenReducer';

const defaultInitialState: IUserScreenState = {
    selectedContext: 0,
};

const UserScreenContext = createContext<[IUserScreenState, UserScreenDispatch]>(
    {} as [IUserScreenState, UserScreenDispatch],
);

// initial state override should be used in jest tests only
interface IUserScreenProvider {
    initialStateOverride?: Partial<IUserScreenState>;
}

export const UserScreenProvider: React.FC<IUserScreenProvider> = ({ children, initialStateOverride }) => {
    const initialState = initialStateOverride
        ? merge({}, defaultInitialState, initialStateOverride)
        : defaultInitialState;
    const [state, dispatch] = useReducer(userScreenReducer, initialState);
    return <UserScreenContext.Provider value={[state, dispatch]}>{children}</UserScreenContext.Provider>;
};

export const useUserScreenState = (): [IUserScreenState, UserScreenDispatch] => useContext(UserScreenContext);
