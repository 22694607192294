import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { memo, PropsWithChildren } from 'react';
import { IMenuItem } from '../../../reducers/types';
import { AppTheme } from '../../app';
import { DynamicMenu } from '../../menu';
import { Tooltip } from '../tooltip';
import { useAppSelector } from '../../../store';
import { selectPageState } from '../../../store/slices';

const useStyles = makeStyles<AppTheme>((theme) => ({
    holder: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.secondary,
        '&>*': {
            marginTop: theme.spacing(0.5),
        },
    },
    primary: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        whiteSpace: 'nowrap',
    },
    secondary: {
        display: 'flex',
    },
    chevron: {
        display: 'inline-block',
        fontSize: '2rem',
    },
    tooltip: {
        whiteSpace: 'break-spaces',
    },
    tooltipPlacementBottom: {
        marginTop: theme.spacing(1),
    },
    primaryText: {
        fontWeight: 700,
        whiteSpace: 'pre',
        lineHeight: 'normal',
        width: '100%',
    },
}));

type PageHeader = PropsWithChildren<{
    title?: string;
    pageMenu?: IMenuItem[];
    onPageMenuItemClick?: (item: IMenuItem) => void;
    onBackClick?: () => void;
    inProgress?: boolean;
    classes?: {
        root?: string;
        primary?: string;
        secondary?: string;
    };
}>;

const tooltipMinLength = 50;

export const PageHeader = memo<PageHeader>(function PageHeader(props) {
    const {
        title = '',
        pageMenu = [],
        onPageMenuItemClick,
        onBackClick,
        classes: externalClasses,
        inProgress,
        children,
    } = props;
    const classes = useStyles();
    const bookmarkTitle = useAppSelector(selectPageState('bookmarkTitle'));
    const headerTitle = (inProgress && 'Loading...') || (bookmarkTitle ? bookmarkTitle : title);

    return (
        <div className={classNames(classes.holder, externalClasses?.root)}>
            <div className={classNames(classes.primary, externalClasses?.primary)}>
                {onBackClick && (
                    <IconButton
                        className={classes.chevron}
                        edge="start"
                        size="small"
                        color="primary"
                        aria-label="back"
                        onClick={onBackClick}
                        data-testid="leftChevron">
                        <NavigateBeforeIcon fontSize="large" />
                    </IconButton>
                )}
                <Tooltip
                    title={headerTitle}
                    enterDelay={500}
                    placement="bottom"
                    disableHoverListener={headerTitle.length <= tooltipMinLength}
                    classes={{
                        tooltip: classes.tooltip,
                        tooltipPlacementBottom: classes.tooltipPlacementBottom,
                    }}>
                    <Typography className={classes.primaryText} variant="h4" noWrap data-testid="pageTitle">
                        {headerTitle}
                    </Typography>
                </Tooltip>
                {children}
            </div>
            <div className={classNames(classes.secondary, externalClasses?.secondary)}>
                <DynamicMenu
                    items={pageMenu}
                    inProgress={inProgress}
                    onMenuClick={(item) => onPageMenuItemClick?.(item)}
                />
            </div>
        </div>
    );
});
