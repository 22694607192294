import { Input, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { VFC } from 'react';
import { SelectionOption } from '../../../../../reducers/types';
import { AppTheme } from '../../../../app';
import { Field } from '../field';
import { Select } from '../select';

const useStyles = makeStyles<AppTheme>((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    inputRoot: {
        height: 32,
        boxSizing: 'border-box',
        border: `1px solid ${theme.palette.grey[500]}`,
        padding: '1px 9px',
        fontSize: theme.typography.subtitle1.fontSize,

        '&:hover': {
            borderWidth: 2,
            borderColor: theme.palette.primary.dark,
            padding: theme.spacing(0, 1),
        },
    },
    inputFocused: {
        borderWidth: 2,
        borderColor: theme.palette.common.black,
        padding: theme.spacing(0, 1),
    },
    input: {
        padding: 0,
    },
    subject: {
        width: 176,
    },
}));

export type TermAndSubjectProps = {
    /**
     * Search term value.
     */
    searchTerm: string;

    /**
     * Search subject value.
     */
    searchSubject: string;

    /**
     * List of available search subjects.
     */
    searchSubjects: SelectionOption[];

    /**
     * Event handler that fires when a search term is changed.
     */
    onTermChange: (value: string) => void;

    /**
     * Event handler that fires when a search subject is selected.
     */
    onSubjectChange: (value: string) => void;
};

export const TermAndSubject: VFC<TermAndSubjectProps> = (props) => {
    const { searchTerm, searchSubject, searchSubjects, onTermChange, onSubjectChange } = props;

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Field>
                <Input
                    classes={{
                        root: classes.inputRoot,
                        input: classes.input,
                        focused: classes.inputFocused,
                    }}
                    disableUnderline
                    value={searchTerm}
                    onChange={(event): void => onTermChange(event.target.value)}
                    placeholder="Search"
                />
            </Field>
            <Field name={'in'} centered closely>
                <Select
                    variant="standard"
                    className={classes.subject}
                    value={searchSubject}
                    onChange={(event): void => onSubjectChange(event.target.value as string)}>
                    {searchSubjects.map(({ value, displayValue }, index) => (
                        <MenuItem key={index} value={value}>
                            {displayValue}
                        </MenuItem>
                    ))}
                </Select>
            </Field>
        </div>
    );
};
