import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';

export type ConfigurationState = Partial<{
    isSingleTabSavedViewMode: boolean;
    minRefreshUUIDebounceWaitTime: number;
    maxRefreshUUIDebounceWaitTime: number;
    minRefreshUUIThrottleWaitTime: number;
    maxRefreshUUIThrottleWaitTime: number;
}>;

const initialState: ConfigurationState = {
    isSingleTabSavedViewMode: false,
};

const configuration = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        initializeApp: (state, action: PayloadAction<{ state: ConfigurationState }>) => {
            return merge(state, action.payload.state) as unknown as typeof state;
        },
        setIsSingleTabSavedViewMode: (state, action: PayloadAction<{ isSingle: boolean }>) => {
            state.isSingleTabSavedViewMode = action.payload.isSingle;
        },
    },
});

// Actions & Reducer
export type ConfigurationActions = TakeActions<typeof configuration.actions>;
export const { setIsSingleTabSavedViewMode, initializeApp } = configuration.actions;
export const configurationReducer = configuration.reducer;
