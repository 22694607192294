import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { FC, useEffect, useRef } from 'react';
import { useQuery, useUUIHistory } from '../../hooks';
import { IMenuItem } from '../../reducers/types';
import { LegacyActionType } from '../../reducers/types.legacy';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectPageState, setPageScriptToExecute } from '../../store/slices';
import { AppTheme } from '../app';
import { BreadCrumbs } from '../common/breadCrumbs/breadCrumbs.component';
import { CurrencyToggle } from '../common/pageHeader/currency/currencyToggle.component';
import { PageHeader } from '../common/pageHeader/pageHeader';
import { LoaderScope, useLoader } from '../core/blockUi/useLoader.hook';
import { PageFooter } from '../core/pageFooter';
import { useExternalUICommunication, useExternalUIState, useExternalUISync } from './hooks';

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        ...theme.styles.page.root,
    },
    header: {
        ...theme.styles.page.header,
    },
    content: {
        ...theme.styles.page.content,
        flexDirection: 'column',
        opacity: 0,
        padding: 'initial',
    },
    contentLoaded: {
        opacity: 1,
    },
    iframe: {
        flex: 1,
        border: 0,
    },
}));

// eslint-disable-next-line max-lines-per-function
export const ExternalUIScreen: FC = () => {
    const classes = useStyles();
    const history = useUUIHistory();
    const { pageMenu, pageScript, title } = useAppSelector(selectPageState());
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const { screenId } = useQuery<{ screenId: string }>();
    const { currentUrl, isLoading } = useExternalUIState({ iframeRef });
    const dispatch = useAppDispatch();
    const { preventLoadingOnce } = useLoader({
        scope: LoaderScope.ExternalUI,
    });
    const { send } = useExternalUICommunication({ iframeRef });
    useExternalUISync({ currentUrl, iframeRef });

    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        const { current: iframe } = iframeRef;
        const { scriptToExecute } = pageScript || {};
        if (!scriptToExecute) {
            return;
        }

        const message = {
            sender: 'clientActionScript',
            message: scriptToExecute,
        };
        iframe?.contentWindow?.postMessage(message, '*');

        dispatch(setPageScriptToExecute({ script: undefined }));
    }, [pageScript?.scriptToExecute]);

    const onPageMenuItemClick = (item: IMenuItem) => {
        const { children, legacyActionDetails, screenId: itemScreenId, screenType, url, isNewWindow } = item;

        if (children?.length) {
            return;
        }

        if (isNewWindow) {
            window.open(url, '_blank');
            return;
        }

        const base = `/enduser/${String(screenType)}screens/show.do`;

        if (screenType !== 'legacy') {
            const url = `${base}?id=${String(itemScreenId)}`;
            history.push(url);
            return;
        }

        if (!legacyActionDetails) {
            return;
        }

        const { clientActionScript, actionType, formActionDetails } = legacyActionDetails;

        if (actionType === LegacyActionType.Url) {
            const legacyUrl = `${base}?screenId=${String(screenId)}&url=${encodeURIComponent(url)}`;
            history.push(legacyUrl);
        }

        if (actionType === LegacyActionType.Form && formActionDetails) {
            if (iframeRef?.current?.contentWindow) {
                formActionDetails.formActions.forEach((formAction) => {
                    const inputElement = iframeRef?.current?.contentWindow?.document.getElementById(
                        formAction.paramName,
                    ) as HTMLInputElement;
                    if (inputElement) {
                        inputElement.value = formAction.paramValue;
                    }
                });
                const mainForm = formActionDetails.formName
                    ? (iframeRef?.current?.contentWindow.document.getElementById(
                          formActionDetails.formName,
                      ) as HTMLFormElement)
                    : iframeRef?.current?.contentWindow.document.forms[0];
                if (mainForm) {
                    preventLoadingOnce();
                    mainForm.requestSubmit();
                    const actionNames = formActionDetails?.formActions.map(({ paramValue }) => paramValue);
                    send({ actionNames });
                }
            }
        }

        if (actionType === LegacyActionType.Client && clientActionScript) {
            if (iframeRef?.current?.contentWindow) {
                preventLoadingOnce();
                iframeRef?.current?.contentWindow.postMessage(
                    {
                        sender: 'clientActionScript',
                        message: clientActionScript,
                    },
                    '*',
                );
            }
        }
    };

    return (
        <>
            <div className={classes.root}>
                <BreadCrumbs />
                <PageHeader
                    classes={{ root: classes.header }}
                    title={title}
                    pageMenu={pageMenu}
                    onPageMenuItemClick={onPageMenuItemClick}
                    inProgress={isLoading}>
                    <CurrencyToggle />
                </PageHeader>
                <div className={classNames(classes.content, { [classes.contentLoaded]: !isLoading })}>
                    <iframe
                        referrerPolicy="same-origin"
                        id="legacy-iframe"
                        ref={iframeRef}
                        className={classNames(classes.iframe)}
                        src={currentUrl}
                    />
                </div>
            </div>
            <PageFooter />
        </>
    );
};
