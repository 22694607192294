import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import useUniqueId from '../../hooks/useUniqueId';

const WkExcelIcon: React.FC<SvgIconProps> = (props) => {
    const lgExcelId = useUniqueId('lgExcel_');
    const lgExcelId2 = useUniqueId('lgExcel2_');

    return (
        <SvgIcon data-testid="wkExcelIcon" viewBox="0 0 16 16" {...props}>
            <defs>
                <linearGradient id={lgExcelId} y1="0.054" x2="1.109" y2="1.083" gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#004e20" />
                    <stop offset="1" stopColor="#017e35" />
                </linearGradient>
                <linearGradient
                    id={lgExcelId2}
                    x1="0.295"
                    y1="0.5"
                    x2="0.98"
                    y2="0.5"
                    gradientUnits="objectBoundingBox">
                    <stop offset="0" stopColor="#f0f0f0" />
                    <stop offset="1" stopColor="#fff" />
                </linearGradient>
            </defs>
            <g transform="translate(-1862 -858)">
                <g transform="translate(1862.19 858)">
                    <rect
                        data-name="Rectangle 1038"
                        fill={`url(#${lgExcelId})`}
                        width="16"
                        height="16"
                        rx="1"
                        transform="translate(-0.19)"
                    />
                </g>
                <g transform="translate(1866.19 861.498)">
                    <path
                        data-name="Path 3149"
                        fill={`url(#${lgExcelId2})`}
                        d="M25,32.391l3.14-4.9L25.295,23h2.169l1.843,3.392L31.112,23h2.15L30.4,27.561l3.141,4.83H31.306l-2.037-3.623-2.044,3.623Z"
                        transform="translate(-25 -23)"
                    />
                </g>
            </g>
        </SvgIcon>
    );
};

export default WkExcelIcon;
