import { AppTheme, StyleProps } from '../useAppTheme.hook';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const appContainer = (_: AppTheme) => {
    const root: StyleProps = {
        minWidth: 360,
    };

    return { root };
};
