import { IDocTransformer } from '../../interfaces/transformer/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, IAddDocument } from '../../types';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { IAddDocument_T360 } from '../types';
import { T360AdapterValidateDocumentActionableEntity } from '../actionableEntity/validateDocumentActionableEntity';

const log = factory.getLogger('T360AdapterValidateDocumentEntityTransformer');

@injectable()
export class T360AdapterValidateDocumentEntityTransformer implements IDocTransformer {
    transformIntoActionableEntity(
        nodeObj: IAddDocument,
        _matterDoc: IMatterDoc,
        _urlPattern: IDocOperationInvocation,
    ): T360AdapterValidateDocumentActionableEntity {
        log.debug('Calling T360AdapterValidateDocumentActionableEntity');
        const obj = {} as T360AdapterValidateDocumentActionableEntity;
        // TO CREATE ACTIONABLE ENTITY FROM nodeObj and matterDoc

        const nodeObj1 = nodeObj as IAddDocument_T360;
        obj.documentName = nodeObj1.name;

        obj.documentType = nodeObj1.documentType;

        obj.editAccessType = nodeObj1.editAccessType;

        obj.viewAccessType = nodeObj1.viewAccessType;

        obj.description = nodeObj1.extendedProps?.description;

        obj.documentFolderId = nodeObj1.documentFolderId;
        return obj;
    }
}
