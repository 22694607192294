import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkPlusCircle: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkPlusCircle" viewBox="-3 -3 21 21" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 14.9333C4.2 14.9333 1.06667 11.8 1.06667 8C1.06667 4.2 4.2 1.06667 8 1.06667C11.8 1.06667 14.9333 4.2 14.9333 8C14.9333 11.8 11.8 14.9333 8 14.9333ZM8.53333 3.2H7.46667V7.46667H3.2V8.53333H7.46667V12.8H8.53333V8.53333H12.8V7.46667H8.53333V3.2Z"
            fill="currentColor"
        />
    </SvgIcon>
);
