import { parseISO, differenceInMinutes } from 'date-fns';
import { debounce, isEmpty, concat } from 'lodash';
import { models, Report, IReportEmbedConfiguration } from 'powerbi-client';
import { EmbedType } from 'powerbi-client-react';
import { useEffect, useMemo, useState } from 'react';
import { config } from '../../../../../../../../apps/client/src/target/t360/config';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
    EmbedToken,
    EmbedTokenStatus,
    LayoutPowerBIComponent,
    ReportDetail,
    selectEmbedToken,
    selectEmbedTokenStatus,
    setEmbedToken,
    setEmbedTokenStatus,
    selectReportKeys,
    selectReportFiltersData,
    ReportFiltersData,
    DashboardPowerBIComponent,
    ComponentType,
    selectDashboardKeys,
} from '../../../../../store/slices';
import { apiFetch } from '../../../../../utils/fetchUtils';
import { getLogger } from '../../../../../utils/loggingService';
import { MESSAGES } from '../common/constants';
import { ChartStatus, ChartType, ValueFetchType } from '../common/enums';
import { getChart } from '../common/powerBI.utils';
import { ChartContext, createStrategy } from '../strategy';

const logger = () => getLogger('usePowerBI');

const INTERVAL_TIME = 30000; // 30 sec
const MINUTES_BEFORE_EXPIRATION = 1;

type UsePowerBiProps = {
    component?: LayoutPowerBIComponent | DashboardPowerBIComponent;
    master?: boolean;
};

// eslint-disable-next-line max-lines-per-function
export const usePowerBI = (props: UsePowerBiProps) => {
    const dispatch = useAppDispatch();
    const { component, master } = props;
    const bffContextRoot = config.get('REACT_APP_BFF_URL');
    const embedToken = useAppSelector(selectEmbedToken);
    const embedTokenStatus = useAppSelector(selectEmbedTokenStatus);
    const reportKeys = useAppSelector(selectReportKeys);
    const reportFiltersData = useAppSelector(selectReportFiltersData);
    const dashboardKeys = useAppSelector(selectDashboardKeys);
    const [reportDetail, setReportDetail] = useState<ReportDetail>();
    const [chartStatus, setChartStatus] = useState(ChartStatus.Idle);
    const [chartContext, setChartContext] = useState<ChartContext | undefined>(undefined);
    const bffBaseUrl = `${String(bffContextRoot)}/urp/`;

    useEffect(() => {
        if (!component?.reportKey) {
            return;
        }

        void getReportDetail(component.reportKey);

        return (): void => {
            setReportDetail(undefined);
        };
    }, [component?.reportKey]);

    useEffect(() => {
        if (!master) {
            return;
        }

        if (embedTokenStatus === EmbedTokenStatus.PendingRequest) {
            void getEmbedToken();
            return;
        }

        if (embedToken && embedTokenStatus === EmbedTokenStatus.Obtained) {
            const intervalId = setInterval(() => {
                const expiration = parseISO(embedToken.expirationUtc);
                const now = Date.now();
                const diffInMinutes = differenceInMinutes(expiration, now);
                if (diffInMinutes > MINUTES_BEFORE_EXPIRATION || embedTokenStatus !== EmbedTokenStatus.Obtained) {
                    return;
                }
                void getEmbedToken();
            }, INTERVAL_TIME);
            return (): void => {
                clearInterval(intervalId);
            };
        }

        return;
    }, [embedToken, embedTokenStatus]);

    useEffect(() => {
        if (master || embedTokenStatus !== EmbedTokenStatus.Idle) {
            return;
        }

        dispatch(setEmbedTokenStatus({ status: EmbedTokenStatus.PendingRequest }));
    }, [embedTokenStatus]);

    const fetchDataAndInit = async (report: Report): Promise<void> => {
        let typeOfChart = ChartType.Dashboard;
        if (component?.type != ComponentType.DashboardPowerBiComponent) {
            const chart = await getChart(report);
            typeOfChart = chart.type as ChartType;
        }
        const strategy = createStrategy(typeOfChart);
        const chartContext = new ChartContext(strategy);
        await chartContext.executeFetchDataAndInit(report);

        setChartContext(chartContext);
        setChartStatus(ChartStatus.Initialized);
    };

    const resize = useMemo(
        () => debounce((width: number, height: number): void => void chartContext?.executeResize(width, height), 300),
        [chartContext],
    );

    const handlePowerBIErrors = (token: EmbedToken) => {
        if (Object.values(token).includes('An error occurred while processing your request.')) {
            logger().warn('URP-PowerBI service is down.');
            dispatch(setEmbedTokenStatus({ status: EmbedTokenStatus.Failed }));
            return;
        }

        if (!token || isEmpty(token.token)) {
            logger().warn('PowerBI embed token is empty.');
            dispatch(setEmbedTokenStatus({ status: EmbedTokenStatus.NotAvailable }));
            return;
        }
        dispatch(setEmbedToken({ token }));
    };

    const getEmbedToken = async (): Promise<void> => {
        dispatch(setEmbedTokenStatus({ status: EmbedTokenStatus.Requested }));
        try {
            const mergedKeys = concat(reportKeys, dashboardKeys);
            const token = await apiFetch<EmbedToken>(`${bffBaseUrl}embedtokens`, mergedKeys, {
                skipTracking: true,
                isExternalServerRequest: true,
            });
            handlePowerBIErrors(token);
        } catch (e) {
            logger().error(`Failed to fetch PowerBI embed token: ${(e as Error).message}`);
            dispatch(setEmbedTokenStatus({ status: EmbedTokenStatus.Failed }));
        }
    };

    const getReportDetail = async (reportKey: string): Promise<void> => {
        if (!reportKey) {
            return;
        }

        dispatch(setEmbedTokenStatus({ status: EmbedTokenStatus.Idle }));

        const reportDetailUrl = `${bffBaseUrl}reportdetails/${reportKey}`;
        try {
            const reportDetail = await apiFetch<ReportDetail>(reportDetailUrl, undefined, {
                isExternalServerRequest: true,
            });

            setReportDetail(reportDetail);
        } catch (e) {
            setReportDetail(undefined);
            logger().error(`Failed to fetch PowerBI report : ${(e as Error).message}`);
            dispatch(setEmbedTokenStatus({ status: EmbedTokenStatus.Failed }));
        }
    };

    const createFilters = (
        reportDetail: ReportDetail,
        reportFiltersData: ReportFiltersData | undefined,
    ): models.ReportLevelFilters[] => {
        if (!reportDetail?.filters) {
            return [] as models.ReportLevelFilters[];
        }

        return reportDetail?.filters?.map((filter) => {
            if (!reportFiltersData) {
                throw new Error(MESSAGES.NO_FILTER_DATA);
            }

            const filterValue: (string | number)[] = [];

            if (filter.valueFetchType === ValueFetchType.UserCurrencyId) {
                filterValue.push(reportFiltersData.currencyId as string | number);
            } else if (filter.valueFetchType === ValueFetchType.PreferredInvoiceStatusIds) {
                filterValue.push(...(reportFiltersData.invoiceStatusIds as (string | number)[]));
            } else if (filter.valueFetchType === ValueFetchType.PreferredInvoiceDateField) {
                filterValue.push(reportFiltersData.invoiceDateType as string | number);
            } else if (filter.valueFetchType === ValueFetchType.PreferredTimeZone) {
                filterValue.push(reportFiltersData.timeZone as string | number);
            }

            const { targetColumn, targetTable } = filter;
            return {
                $schema: 'http://powerbi.com/product/schema#basic',
                target: {
                    table: targetTable,
                    column: targetColumn,
                },
                filterType: models.FilterType.Basic,
                operator: 'In',
                values: filterValue,
            };
        });
    };

    const reportSettings = {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        layoutType: models.LayoutType.Custom,
        customLayout: {
            displayOption: models.DisplayOption.ActualSize,
        },
    };

    const dashboardSettings = {
        panes: {
            pageNavigation: {
                visible: true,
                position: models.PageNavigationPosition.Left,
            },
        },
    };

    const embedConfig: IReportEmbedConfiguration =
        embedToken && reportDetail
            ? {
                  type: EmbedType.Report,
                  id: reportDetail?.metadata?.serverReportId, // Seems like not really required despite what the documentation says, left it just in case
                  embedUrl: reportDetail?.metadata?.embedUrl,
                  accessToken: embedToken.token, // Keep as empty string, null or undefined
                  tokenType: models.TokenType.Embed,
                  settings:
                      component?.type == ComponentType.LayoutPowerBiComponent ? reportSettings : dashboardSettings,
                  filters: createFilters(reportDetail, reportFiltersData),
              }
            : {
                  type: EmbedType.Report,
                  id: undefined,
                  embedUrl: undefined,
                  accessToken: undefined,
                  tokenType: models.TokenType.Embed,
              };

    return {
        embedConfig,
        chartStatus,
        fetchDataAndInit,
        resize,
        tokenStatus: embedTokenStatus,
    };
};
