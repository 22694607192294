import { Report } from 'powerbi-client';
import { ChartStrategy } from './chartStrategy.interface';

export class ChartContext {
    constructor(private strategy: ChartStrategy) {}

    public async executeResize(width: number, height: number): Promise<void> {
        await this.strategy.resize(width, height);
    }

    public async executeFetchDataAndInit(report: Report): Promise<void> {
        await this.strategy.fetchDataAndInit(report);
    }
}
