import { ITransformer } from './types';
import { ICHAddFolder } from '../interfaces/operations';
import { IAddFolder } from '@wk/elm-uui-doc-component';
import 'reflect-metadata';
import { injectable } from 'inversify';

@injectable()
export class CtxAddFolderTransformer implements ITransformer {
    data!: ICHAddFolder;

    transform(): IAddFolder {
        const nodeObj = {} as IAddFolder;
        nodeObj.entityContext = {
            name: this.data.associatedEntityName || '',
            id: this.data.associatedEntityId,
            type: this.data.associatedEntityType,
        };

        const extendedProps = <IAddFolder['extendedProps']>{};
        nodeObj.name = (this.data?.payload?.document_folder_name?.value || this.data?.payload?.name?.value) ?? '';
        nodeObj.documentFolderId = this.data?.folderId ?? undefined;

        nodeObj.editAccessType = this.data?.payload?.folder_edit_access_type_name?.value;
        nodeObj.viewAccessType = this.data?.payload?.folder_view_access_type_name?.value;

        extendedProps['admittedMembers'] = this.data?.props?.admittedMembers;
        return nodeObj;
    }
}
