import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const wkAddIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkAddIcon" {...props}>
        <g transform="translate(-40 -204.422)">
            <rect width="16" height="16" transform="translate(40 204.422)" />
            <path
                d="M14.967,6.92H9.173V1.127a1.127,1.127,0,0,0-2.253,0h0v5.74H1.127A1.22,1.22,0,0,0,0,8.047,1.133,1.133,0,0,0,1.127,9.173h5.74v5.74a1.127,1.127,0,1,0,2.253,0V9.173h5.74a1.133,1.133,0,0,0,1.127-1.127A.989.989,0,0,0,14.967,6.92Z"
                transform="translate(40 204.422)"
            />
        </g>
    </SvgIcon>
);

export default wkAddIcon;
