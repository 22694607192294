import { Tab, TabProps } from '@mui/material';
import { merge, omit } from 'lodash';
import React from 'react';
import css from './uuiTab.module.scss';

export interface UUITabProps extends TabProps {
    isSubTab?: boolean;
}

const UUITab: React.FC<UUITabProps> = (props) => {
    let tabClasses;

    if (props.isSubTab) {
        tabClasses = merge({}, props.classes, { selected: css.subTabSelected, root: css.subTabRoot });
    } else {
        tabClasses = merge({}, props.classes, { selected: css.tabSelected, root: css.tabRoot });
    }

    const newProps = omit(props, 'isSubTab');

    return (
        <Tab {...newProps} disableFocusRipple classes={tabClasses}>
            {props.children}
        </Tab>
    );
};

export default UUITab;
