/* eslint-disable max-lines-per-function */
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useUUIHistory, useUUILocation } from '../../hooks';
import { useAppSelector } from '../../store';
import { getAppResources, getApplicationUrls } from '../../store/slices';
import ActionDialog from '../common/actionDialog';
import { dispatchAddAndSelectSavedView, dispatchUpdateAndSelectSavedView } from './context/listScreenAsyncActions';
import { useListScreenDispatch, useListScreenState } from './context/listScreenContext';
import { ACTION_DIALOG_REPLACE, ACTION_DIALOG_SAVEAS, saveViewIsValid } from './listScreenActionDialogHelpers';
import { getReplacementTabs, getSavedViewTabName, saveCurrentView } from './listScreenHelpers';
import css from './saveAsActionDialog.module.scss';
import { ISavedView } from './types';

export const SaveAsActionDialog: React.FC = () => {
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const metadata = listScreenState.metadata!;
    const listData = listScreenState.listData!;
    const [saveAsRadioSelection, setSaveAsRadioSelection] = useState('saveAsNewTab');
    const [saveAsReplaceTabId, setSaveAsReplaceTabId] = useState<number>(-1);
    const [newTabName, setNewTabName] = useState('');
    const [newTabNameError, setNewTabNameError] = useState('');
    const [saveAsInputIsValid, setSaveAsInputIsValid] = useState(true);
    const [saveAsSelectError, setSaveAsSelectError] = useState('');
    const [saveAsSelectIsValid, setSaveAsSelectIsValid] = useState(true);
    const [isSaved, setIsSaved] = useState(false);
    const applicationUrls = useAppSelector(getApplicationUrls);
    const appResources = useAppSelector(getAppResources);
    const location = useUUILocation();
    const history = useUUIHistory();

    const handleSaveAsInputFocus = () => {
        setSaveAsReplaceTabId(-1);
        setSaveAsSelectError('');
        setSaveAsSelectIsValid(true); // form switch, no longer requires validation
        setSaveAsRadioSelection('saveAsNewTab');
    };

    const handleSaveAsReplaceTabSelectChange = (event: any) => {
        setSaveAsReplaceTabId(event.target.value);
    };

    const handleNewTabChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewTabName(event.target.value);
    };

    const handleSaveAsSelectFocus = () => {
        setNewTabName('');
        setNewTabNameError('');
        setSaveAsInputIsValid(true); // form switch, no longer requires validation
        setSaveAsRadioSelection('saveAsReplaceTab');
    };

    const handleSaveAsRadioChange = (event: any) => {
        const radioSelect = event.target.value;
        if (radioSelect === 'saveAsReplaceTab') {
            setNewTabName('');
            setNewTabNameError('');
            setSaveAsInputIsValid(true); // form switch, no longer requires validation
        } else if (radioSelect === 'saveAsNewTab') {
            setSaveAsReplaceTabId(-1);
            setSaveAsSelectError('');
            setSaveAsSelectIsValid(true);
        }
        setSaveAsRadioSelection(radioSelect);
    };

    const actionButtonClickHandler = () => {
        const errorValidation = saveViewIsValid(
            metadata,
            appResources,
            saveAsRadioSelection,
            newTabName,
            saveAsReplaceTabId,
            listScreenState.isEmbeddedList,
        );
        const isSaveViewValid = errorValidation === '';
        if (saveAsRadioSelection === 'saveAsReplaceTab') {
            setSaveAsSelectIsValid(isSaveViewValid);
            setSaveAsSelectError(errorValidation);
        } else {
            setSaveAsInputIsValid(isSaveViewValid);
            setNewTabNameError(errorValidation);
        }
        errorValidation === appResources.dialogContentInputValidation ? setNewTabName('') : null;

        if (!isSaveViewValid) {
            return;
        }

        if (!isSaved) {
            setIsSaved(true);
            const savedViewOverrides: Partial<ISavedView> = {
                isSystemDefault: false,
                columns: [listData.page['column-data']],
                filters: listData.page.filters,
                sort: listData.page.sortInfo,
            };

            let savedViewPost: ISavedView = {
                ...listData.page.savedView,
                ...(savedViewOverrides as ISavedView),
            };

            let savedViewToReplace: ISavedView;
            let action = ACTION_DIALOG_SAVEAS;
            if (saveAsRadioSelection === 'saveAsNewTab') {
                savedViewPost.isUserDefault = false;
                savedViewPost.isPublic = false;
                savedViewPost.isEditable = true;
                savedViewPost.name = newTabName;
                delete savedViewPost.id;
            } else if (saveAsRadioSelection === 'saveAsReplaceTab') {
                action = ACTION_DIALOG_REPLACE;
                savedViewToReplace = metadata.savedViews.find((sv) => sv.id === saveAsReplaceTabId)!;
                savedViewPost = {
                    ...savedViewToReplace,
                    ...(savedViewOverrides as ISavedView),
                };
                if (savedViewToReplace.isSystemDefault || savedViewToReplace.isUserDefault) {
                    savedViewPost.isUserDefault = true;
                    savedViewPost.name = metadata.screenDisplayName;
                    if (savedViewToReplace?.isUserDefault) {
                        savedViewPost.id = listData.page.savedView.id;
                    } else {
                        delete savedViewPost.id;
                    }
                }
            }

            saveCurrentView(
                savedViewPost,
                metadata.screenId,
                listScreenState.dataUrl,
                applicationUrls.applicationSavedViewUrl,
            ).then((json) => {
                const savedView: ISavedView = {
                    ...savedViewPost,
                    id: json.id,
                    name: json.name,
                };
                if (action == ACTION_DIALOG_REPLACE) {
                    if (savedViewToReplace.isSystemDefault) {
                        // If saving a system default view, then add new view as user default
                        const existingMatchingView = metadata.savedViews.find((sv) => sv.id == savedView.id);
                        if (!existingMatchingView) {
                            dispatchAddAndSelectSavedView(
                                listScreenState,
                                listScreenDispatch,
                                savedView,
                                location,
                                history,
                            );
                        }
                    } else {
                        dispatchUpdateAndSelectSavedView(
                            listScreenState,
                            listScreenDispatch,
                            savedView,
                            location,
                            history,
                        );
                    }
                } else if (action == ACTION_DIALOG_SAVEAS) {
                    dispatchAddAndSelectSavedView(listScreenState, listScreenDispatch, savedView, location, history);
                }
                listScreenDispatch({ type: 'CloseEditsAppliedSaveAsDialog' });
            });
        }
    };

    const closeClickHandler = () => {
        listScreenDispatch({ type: 'CloseEditsAppliedSaveAsDialog' });
    };

    const displayTabname = (sv: ISavedView) => {
        const tabName = getSavedViewTabName(sv, metadata);
        return tabName === metadata.screenDisplayName && listScreenState.isEmbeddedList
            ? appResources.listscreenDefaultDropdownText
            : tabName;
    };

    return (
        <ActionDialog
            open={listScreenState.editsAppliedSaveAsDialogOpen}
            handleActionClose={closeClickHandler}
            actionButtonClickHandler={actionButtonClickHandler}
            title={appResources.dialogTitleSaveAs}
            actionButtonText={appResources.buttonSave}
            disableActionButton={isSaved}>
            <React.Fragment>
                <RadioGroup
                    aria-label="save as options"
                    value={saveAsRadioSelection}
                    onChange={handleSaveAsRadioChange}>
                    <FormControlLabel
                        htmlFor="newViewName"
                        value="saveAsNewTab"
                        control={<Radio color="primary" />}
                        label={appResources.dialogContentSaveAsNewTab}
                    />
                    <TextField
                        id="newViewName"
                        value={newTabName}
                        InputProps={{ classes: { root: css.saveAsInputControls } }}
                        variant="outlined"
                        onFocus={handleSaveAsInputFocus}
                        onChange={handleNewTabChange}
                        helperText={newTabNameError}
                        FormHelperTextProps={{
                            className: css.saveAsNewTabHelperText,
                        }}
                        error={!saveAsInputIsValid ? true : false}
                        fullWidth
                    />
                    <FormControlLabel
                        value="saveAsReplaceTab"
                        control={<Radio color="primary" data-testid="saveas-replace-radio" />}
                        label={appResources.dialogContentSaveAsReplaceTab}
                    />
                    <FormControl variant="outlined" error={!saveAsSelectIsValid ? true : false}>
                        <Select
                            variant="standard"
                            classes={{
                                select:
                                    saveAsReplaceTabId === -1
                                        ? css.saveAsInputControlsSelectTab
                                        : css.saveAsInputControls,
                            }}
                            value={saveAsReplaceTabId}
                            onChange={handleSaveAsReplaceTabSelectChange}
                            onFocus={handleSaveAsSelectFocus}
                            error={!saveAsSelectIsValid ? true : false}
                            data-testid="saveas-replace-select">
                            <MenuItem className={css.replaceTabItem} dense value={-1}>
                                {appResources.dialogContentSaveAsReplaceTabSelectTab}
                            </MenuItem>
                            {getReplacementTabs(metadata).map((sv) => {
                                return (
                                    <MenuItem className={css.replaceTabItem} dense key={sv.id} value={sv.id}>
                                        {displayTabname(sv)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {!saveAsSelectIsValid && <FormHelperText>{saveAsSelectError}</FormHelperText>}
                    </FormControl>
                </RadioGroup>
            </React.Fragment>
        </ActionDialog>
    );
};
