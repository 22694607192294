import { getLogger } from '@wk/elm-uui-common';
import { UserManager } from 'oidc-client';
import { AppProps, initialize, InitializeAppCallback } from '../../../initialize';
import { registerMfeApps } from '../../../mfe';
import { config } from '../config';
import { TokenAuthenticationProvider } from '../infrastructure/TokenAuthenticationProvider';
import { ConnectivityService } from '../infrastructure/connectivityService';
import {
    NETWORK_ID_QUERY_PARAMETER,
    ONE_TIME_TOKEN_QUERY_PARAMETER,
    collectQueryParams,
    getSignInArgs,
    getUserManager,
} from '../infrastructure/oidc';
import { RouteManagementService } from '../infrastructure/routeManagementService';
import { UrlHelper } from '../infrastructure/urlHelper';
import { About } from './about';
import { AppProvider } from './appProvider';
import { Contacts } from './contacts';

declare global {
    interface Window {
        Props: AppProps & { userManager: UserManager };
    }
}

function setupProps(): void {
    window.Props = {
        apiContextRoot: `${config.get('REACT_APP_API_URL')}/api`,
        apiContextPath: '/v4/views',
        uiContextRoot: config.get('REACT_APP_UI_URL'),
        staticContext: config.get('REACT_APP_UI_URL'),
        legacyContextRoot: config.get('REACT_APP_LEGACY_URL'),
        bffContextRoot: config.get('REACT_APP_BFF_URL'),
        token: 'foobar',
        initializationPath: '/initialize',
        iconPath: '/icons/icons.svg',
        timeoutPath: '/timeout',
        basePath: process.env.PUBLIC_URL,
        pageNotFoundParam: 'pageNotFoundParam',
        encryptionKey: 'key',
        showUploadQueue: false, // TODO: bring upload queue back as soon as at least one page allows document upload
        userManager: getUserManager(),
        noInternetConnection: 'Unable to connect to server',
        reconnecting:
            'Application will automatically reconnect when the connection resumes. Please check your internet connection. If the problem persists, contact your administrator.',
        serverChangesDetectedHeading: 'Server Changes Detected',
        serverChangesDetectedMessage:
            'To retrieve the server changes and refresh all of your OC instances, click Refresh Now.',
        buttonRefreshNow: 'Refresh Now',
        errorOverlayHeading: 'Something went wrong',
        errorOverlayMessage:
            'If the problem persists, please contact your Network Administrator. You can return to the Home section from here.',
        returnToHomeButton: 'Go to Home',
        unauthorizedAccess: 'You do not have access to this object. Contact Admin to get permission.',
        username: 'user',
        liveChatPath: '/scripts/livechat.js',
        toggleCurrencyPath: '/preferencecurrency',
        powerBifailureMessage: 'This component is currently unavailable.',
        genericRetryMessage: 'Please try again later.',
        loginDetails: loginDetails,
    };
}

let isOidcCallbackHandled = false;
let userState: any; // tslint:disable-line
async function beforeInitializePassport() {
    collectQueryParams();
    // Handle oidccallback before Passport initialize as it can change urls during init
    if (window.location.pathname.indexOf('oidccallback') > -1) {
        const user = await getUserManager().signinRedirectCallback();
        isOidcCallbackHandled = true;
        userState = user.state;
        loginDetails = JSON.parse(user.profile.login_details as string) as LoginDetails;
    }
}

let loginDetails: LoginDetails;

export const InitializePassport = () => {
    beforeInitializePassport().then(() => {
        setupProps();
        initialize({
            UUICustomComponents: {
                CustomContactsComponent: Contacts,
                CustomAboutComponent: About,
                AppProvider,
            },
            applicationAuthProvider: TokenAuthenticationProvider,
            initializeAppCallback,
        });
    });
};

// This callback stops UUI execution until get's resolved
const initializeAppCallback: InitializeAppCallback = (_, history) => {
    const getLog = () => getLogger('t360.InitializePassport');

    async function handleOidcCallback() {
        try {
            const userManager = getUserManager();
            const authorizedUser = await userManager.getUser();
            if (authorizedUser != null) {
                return await handleRouteManagment(authorizedUser.state);
            }
            const user = await userManager.signinRedirectCallback();
            // if we here then auth is done. need to redirect out from oidccallback endpoint
            return await handleRouteManagment(user.state);
        } catch (error) {
            getLog().error('Error: ', error);
            await ConnectivityService.handleOidcNetworkError(error);
        }
        return false;
    }

    // tslint:disable-next-line
    async function handleRouteManagment(userStateProps: any) {
        try {
            if (userStateProps != null && userStateProps.deepLink != null && userStateProps.deepLink.pathname !== '/') {
                // Hide authentication attributes in oidccallback for better back button support.
                if (await RouteManagementService.isSupported()) {
                    await RouteManagementService.setDefaultUrl(window.location.href);
                    await RouteManagementService.reloadUrl();
                }
                // Replace url to original deep link url.
                history.replace(userStateProps.deepLink || '/');
                history.go(0);
            } else {
                if (await RouteManagementService.isSupported()) {
                    await RouteManagementService.clearDefaultUrl();
                    await RouteManagementService.reloadUrl();
                }
                history.push('/');
                return true;
            }
        } catch (error) {
            getLog().error('Error: ', error);
            await ConnectivityService.handleOidcNetworkError(error);
        }
        return false;
    }

    async function handleOidc() {
        const userManager = getUserManager();

        const user = await userManager.getUser();
        // Need to check if we in callback endpoint
        if (window.location.pathname.indexOf('oidccallback') > -1) {
            getLog().debug('Handling oidc response.');
            return await handleOidcCallback();
        } else if (isOidcCallbackHandled) {
            isOidcCallbackHandled = false;
            return await handleRouteManagment(userState);
        }

        const networkIdQueryParam = UrlHelper.getUrlParameterByName(NETWORK_ID_QUERY_PARAMETER);
        const oneTimeTokenQueryParam = UrlHelper.getUrlParameterByName(ONE_TIME_TOKEN_QUERY_PARAMETER);

        if (oneTimeTokenQueryParam) {
            getLog().debug('Force one time token usage.');
            await userManager.signinRedirect(getSignInArgs());
            return false;
        }
        const needSwitchNetwork =
            user && networkIdQueryParam?.length && user.profile.network_id !== networkIdQueryParam;
        if (user && !user.expired && !needSwitchNetwork) {
            getLog().debug('User is OK, loading application.');
            return true;
        }

        getLog().debug('User is not OK. Need auth.');
        try {
            if (user?.refresh_token && !needSwitchNetwork) {
                // Stopping silent renew to disable refresh token errors event
                // This is needed to have different handling (start auth) on app start
                userManager.stopSilentRenew();

                getLog().debug('Trying to authenticate using existing refresh token.');
                const signedIdUser = await userManager.signinSilent();
                if (signedIdUser) {
                    getLog().debug('Successfully authenticated using refresh token.');
                    location.reload();
                    return false; // doesn't matter what we return here since this will refresh the page.
                }
            }
        } catch (error) {
            getLog().error('Error: ', error);
            await ConnectivityService.handleOidcNetworkError(error);
        }

        try {
            getLog().debug('No refresh token or refresh token is invalid. Need to authenticate via redirect.');
            await userManager.signinRedirect(getSignInArgs());
        } catch (error) {
            getLog().error('Error: ', error);
            await ConnectivityService.handleOidcNetworkError(error);
        }
        return false;
    }

    return new Promise((resolve) => {
        handleOidc().then((canContinue) => {
            if (canContinue) {
                registerMfeApps();
                resolve();
            }
        });
    });
};

interface LoginDetails {
    deepLink?: string;
}
