import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkContracts = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkAddIcon" viewBox="0 0 25 25" {...props}>
        <g id="Group_10966" data-name="Group 10966" transform="translate(-3217 -417)">
            <rect
                id="Rectangle_3444"
                data-name="Rectangle 3444"
                width="25"
                height="25"
                transform="translate(3217 417)"
                fill="none"
            />
            <g id="Group_9353" data-name="Group 9353" transform="translate(3220 417)">
                <path
                    id="Path_5003"
                    data-name="Path 5003"
                    d="M10.4,20.882h5.136v-8.75H10.4Zm1.524-7.219h2.089v5.694H11.929Z"
                    transform="translate(0.434 0.506)"
                    fill="currentColor"
                />
                <path
                    id="Path_5004"
                    data-name="Path 5004"
                    d="M8.605,15.608H3.469v5.129H8.605Zm-1.525,3.6H4.992V17.124H7.08Z"
                    transform="translate(0.145 0.65)"
                    fill="currentColor"
                />
                <path
                    id="Path_5005"
                    data-name="Path 5005"
                    d="M10.556,2.073,17.51,9.032H10.556ZM1.524,1.524H9.032v9.032h9.031v12.92H1.524ZM0,0V25H19.58V8.949L10.639,0Z"
                    fill="currentColor"
                />
            </g>
        </g>
    </SvgIcon>
);
