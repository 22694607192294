import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';

export const WkPriority: React.FC<SvgIconProps> = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon data-testid="wkPriority" fill="none" viewBox="-1 0 14 17" {...props} ref={ref}>
        <path d="M7 0.838196L14 8.8382L7 16.8382L0 8.8382L7 0.838196Z" />
        <path
            d="M7.80873 4.59466L7.62873 9.95866H6.34473L6.15273 4.59466H7.80873ZM6.99273 11.1227C7.26473 11.1227 7.49273 11.2187 7.67673 11.4107C7.86873 11.5947 7.96473 11.8227 7.96473 12.0947C7.96473 12.3587 7.86873 12.5867 7.67673 12.7787C7.49273 12.9707 7.26473 13.0667 6.99273 13.0667C6.72073 13.0667 6.48873 12.9707 6.29673 12.7787C6.11273 12.5867 6.02073 12.3587 6.02073 12.0947C6.02073 11.8227 6.11273 11.5947 6.29673 11.4107C6.48873 11.2187 6.72073 11.1227 6.99273 11.1227Z"
            fill="white"
        />
    </SvgIcon>
));

WkPriority.displayName = 'WkPriority';
