import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TargetScreenType, PagingType, ControlType, ActionType } from '../../components/common/types';
import { Announcement, BreadCrumb, IMenuItem } from '../../reducers/types';
import { AppState } from '../types';

export interface GadgetCatalog {
    catalogName: string;
    categories: GadgetCategory[];
}

export interface GadgetCategory {
    name: string;
    code: string;
    gadgets: GadgetItem[];
}

export interface GadgetItem {
    code: string;
    title: string;
    description: string;
}

export interface Currency {
    enabled: boolean;
    preference: string;
    origCode: string;
}

export interface PageScript {
    emailPage?: string;
    printPage?: string;
    scriptToExecute?: string;
}

export interface PagePrint {
    execute?: boolean;
    visible?: boolean;
}
export interface PageData {
    components?: PageComponent[];
    gadgetCatalog?: GadgetCatalog;
    defaultGadgets?: GadgetItem[];
    pageHeaderLinks?: PageHeaderLinks[];
    pageStyle?: ViewScreenPageStyle;
}

export interface PageHeaderLinks {
    name?: string;
    dialogId?: DialogIds;
}

export enum DialogIds {
    CustomizeGadget = 'CustomizeGadgetDialog',
}

export enum ComponentType {
    AnnouncementsComponent = 'AnnouncementsComponent',
    LayoutComponent = 'LayoutComponent',
    LayoutGridComponent = 'LayoutGridComponent',
    LayoutPowerBiComponent = 'LayoutPowerBiComponent',
    GridComponent = 'GridComponent',
    FilterComponent = 'FilterComponent',
    SummaryComponent = 'SummaryComponent',
    FacetMenuComponent = 'FacetMenuComponent',
    DashboardPowerBiComponent = 'DashboardPowerBiComponent',
}

export enum ViewScreenPageStyle {
    Regular = 'Regular',
    Home = 'Home',
}

export enum ContentHolder {
    PageContent = 0,
    LeftPanel = 1,
}

export type PageComponent =
    | AnnouncementsComponent
    | LayoutComponent
    | GridComponent
    | FilterComponent
    | LayoutGadgetComponent
    | SummaryComponent
    | FacetMenuComponent
    | DashboardPowerBIComponent;
export type LayoutGadgetComponent = LayoutGridComponent | LayoutPowerBIComponent;

export const isLayoutComponent = (component: PageComponent): component is LayoutComponent =>
    component.type === ComponentType.LayoutComponent;

interface BasicComponent {
    id: string;
    type: ComponentType;
    layoutColumnId?: string;
    contentHolder: ContentHolder;
    emptyContentMessage?: string;
}

export interface AnnouncementsComponent extends BasicComponent {
    type: ComponentType.AnnouncementsComponent;
    data?: Announcement[];
    contentHolder: ContentHolder.PageContent;
}

export interface LayoutComponent extends BasicComponent {
    type: ComponentType.LayoutComponent;
    catalogName: string;
    columns: LayoutComponentColumn[];
    components: LayoutGadgetComponent[];
    contentHolder: ContentHolder.PageContent;
}

interface GadgetComponent extends BasicComponent {
    type:
        | ComponentType.LayoutGridComponent
        | ComponentType.LayoutPowerBiComponent
        | ComponentType.DashboardPowerBiComponent;
    code: string;
    title: string;
    description: string;
    height: number;
    isMinimized: boolean;
    buttons?: LayoutComponentButton[];
    order: number;
    contentHolder: ContentHolder.PageContent;
}

export interface LayoutGridComponent extends GadgetComponent {
    type: ComponentType.LayoutGridComponent;
    columns: LayoutGridComponentColumn[];
    dataSource: string;
    pinnedColumn?: string[];
}

export interface LayoutPowerBIComponent extends GadgetComponent {
    type: ComponentType.LayoutPowerBiComponent;
    reportId: string;
    reportKey: string;
}

export interface DashboardPowerBIComponent extends GadgetComponent {
    type: ComponentType.DashboardPowerBiComponent;
    reportId: string;
    reportKey: string;
}

export interface LayoutGridComponentColumn {
    name: string;
    width?: number;
    fields: string;
    sort?: string;
}

export interface LayoutComponentColumn {
    id: string;
    width: number;
}

export interface LayoutComponentButton {
    name: string;
    screenType?: TargetScreenType;
    url?: string;
}

export interface GridComponentColumn {
    name: string;
    code: string;
    widthPercent: number | null;
    isDefaultSort: boolean;
    defaultSortDirectionDesc: boolean;
    isSortable: boolean;
}

export interface GridComponentButton {
    text: string;
    actionType: ActionType;
    action: string;
    style: 'text' | 'outlined' | 'contained' | undefined;
}

export interface GridComponent extends BasicComponent {
    type: ComponentType.GridComponent;
    columns: GridComponentColumn[];
    buttons: GridComponentButton[];
    dataSource: string;
    rowsPerPage: number;
    rowHeight?: number;
    pagingType: PagingType;
    contentHolder: ContentHolder.PageContent;
    entityId: number;
    isNestedGrid: boolean;
}

export interface FilterComponent extends BasicComponent {
    type: ComponentType.FilterComponent;
    filters: FilterComponentItem[];
    dataSource: string;
    contentHolder: ContentHolder.PageContent;
    columnIndex: number;
    dropdownParameters: string;
    filterButtons: FilterButton[];
}

export interface FilterComponentItem {
    dependentFilters: string[];
    filterCode: string;
    selectedValue: string;
    title: string;
    type: ControlType;
    checkBoxLabel: string;
    columnIndex: number;
    customFiltersCode: string[];
    dropdownSource: SelectionOption[];
}

export type SelectionOption = {
    value: string;
    displayValue: string;
};

export interface FilterButton {
    text: string;
    actionType: string;
    action: string;
    style: 'text' | 'outlined' | 'contained' | undefined;
}

export interface FacetMenuComponent extends BasicComponent {
    type: ComponentType.FacetMenuComponent;
    dataSource: string;
    entityId: number;
    contentHolder: ContentHolder.LeftPanel;
}

export interface SummaryComponent extends BasicComponent {
    type: ComponentType.SummaryComponent;
    dataSource: string;
    title: string;
    entityId: number;
    contentHolder: ContentHolder.PageContent;
}

export interface DashboardComponent extends BasicComponent {
    type: ComponentType.DashboardPowerBiComponent;
    title: string;
    contentHolder: ContentHolder.PageContent;
}

export interface SummaryComponentSource {
    dataSource: string;
    items: SummaryComponentSourceViewData[];
}

export interface SummaryComponentSourceViewData {
    caption: string;
    link: string;
    value: string;
    controlType: string;
}

export interface GridComponentSource {
    rowsCount: number;
    pagesCount: number;
    data: Record<string, GridComponentSourceViewData>[];
    clientScript?: string;
    clientStyle?: string;
}

export interface GridActionButton {
    icon: string;
    toolTip: string;
    actionType: ActionType;
    action: string;
    color: string;
    size: number;
    payload: string;
    disabled: boolean;
    extendArgs?: any;
}

export interface GridComponentSourceViewData {
    link: string;
    value: string;
    html: string;
    controlType?: GridComponentControlType;
    code: string;
    id: string;
    toolTip?: string;
    actionButtons: GridActionButton[];
    style?: string;
}

export enum GridComponentControlType {
    ActionButtons = 'ActionButtons',
    CheckBox = 'CheckBox',
    Link = 'Link',
    Html = 'Html',
    Text = 'Text',
}

export interface WarningActionResponse {
    message: string;
    type: ResponseType.Warning | ResponseType.None | ResponseType.WarningToast;
    url: string;
    buttons: GridComponentButton[];
    payload?: string;
}

export enum ResponseType {
    Error = 'Error',
    Warning = 'Warning',
    Redirect = 'Redirect',
    None = 'None',
    WarningToast = 'WarningToast',
}

export interface PageState {
    title: string;
    addBookmarkEnabled?: boolean;
    pageMenu?: IMenuItem[];
    helpUrl?: string;
    currency?: Currency;
    pageScript?: PageScript;
    data?: PageData;
    bookmarkPath?: string;
    bookmarkTitle?: string;
    allowPrint?: boolean;
    pagePrint?: PagePrint;
}

export interface BookMark {
    title?: string;
    bookmarkPath?: string;
}

export interface GlobalState {
    activelyDraggedFilesCount: number;
    draggedFileIsOverValidDropTarget: boolean;
    activeScreenId: number;
    pageState: PageState;
    breadcrumbs: BreadCrumb[];
}

const initialState: GlobalState = {
    activelyDraggedFilesCount: 0,
    draggedFileIsOverValidDropTarget: false,
    activeScreenId: 0,
    pageState: {
        title: 'Loading...',
    },
    breadcrumbs: [],
};

type SelectValue<T, TState> = T extends keyof TState ? TState[T] : TState;

const globalState = createSlice({
    name: 'global-state',
    initialState,
    reducers: {
        setPageState: (state, action: PayloadAction<PageState>) => {
            state.pageState = action.payload;
        },
        cleanPageState: (state) => {
            state.pageState = initialState.pageState;
        },
        setActivelyDraggedFilesCount: (state, action: PayloadAction<{ count: number }>) => {
            state.activelyDraggedFilesCount = action.payload.count;
        },
        setDraggedFileIsOverValidDropTarget: (state, action: PayloadAction<{ isOverValidDropTarget: boolean }>) => {
            state.draggedFileIsOverValidDropTarget = action.payload.isOverValidDropTarget;
        },
        setActiveScreenId: (state, action: PayloadAction<{ screenId: number }>) => {
            state.activeScreenId = action.payload.screenId;
        },
        setPageCurrencyState: (state, action: PayloadAction<{ enabled: boolean }>) => {
            if (state.pageState.currency) {
                state.pageState.currency.enabled = action.payload.enabled;
            }
        },
        setPageScriptToExecute: (state, action: PayloadAction<{ script?: string }>) => {
            if (state.pageState.pageScript) {
                state.pageState.pageScript.scriptToExecute = action.payload.script;
            }
        },
        setPrintScript: (state, action: PayloadAction<string>) => {
            if (state.pageState.pageScript) {
                state.pageState.pageScript.printPage = action.payload;
            }
        },
        setPrintToExecute: (state, action: PayloadAction<{ execute?: boolean }>) => {
            if (state.pageState.pagePrint) {
                state.pageState.pagePrint.execute = action.payload.execute;
            }
        },
        setBookmarkPath: (state, action: PayloadAction<BookMark>) => {
            if (action.payload.bookmarkPath) {
                state.pageState.bookmarkPath = action.payload.bookmarkPath;
            }
            if (action.payload.title) {
                state.pageState.bookmarkTitle = action.payload.title;
            }
        },
        setBreadCrumbState: (state, action: PayloadAction<{ bc: BreadCrumb[] }>) => {
            state.breadcrumbs = action.payload.bc;
        },
        clearBreadCrumbState: (state) => {
            state.breadcrumbs = initialState.breadcrumbs;
        },
    },
});

export const selectGlobalState = <T extends keyof GlobalState | undefined = undefined>(key?: T) => {
    return (state: AppState): SelectValue<T, GlobalState> => {
        if (key) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            return state.ui.globalState[key as keyof GlobalState] as SelectValue<T, GlobalState>;
        }
        return state.ui.globalState as SelectValue<T, GlobalState>;
    };
};

export const selectPageState = <T extends keyof PageState | undefined = undefined>(key?: T) => {
    return (state: AppState): SelectValue<T, PageState> => {
        if (key) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            return state.ui.globalState.pageState[key as keyof PageState] as SelectValue<T, PageState>;
        }
        return state.ui.globalState.pageState as SelectValue<T, PageState>;
    };
};

// Actions
export type GlobalStateActions = TakeActions<typeof globalState.actions>;
export const {
    setPageState,
    cleanPageState,
    setActivelyDraggedFilesCount,
    setDraggedFileIsOverValidDropTarget,
    setActiveScreenId,
    setPageCurrencyState,
    setPageScriptToExecute,
    setPrintScript,
    setBookmarkPath,
    setBreadCrumbState,
    clearBreadCrumbState,
    setPrintToExecute,
} = globalState.actions;
export const globalStateReducer = globalState.reducer;
