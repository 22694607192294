import ClearIcon from '@mui/icons-material/Clear';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ExpandMore from '@mui/icons-material/ExpandMore'
import { SxProps, Theme } from '@mui/material';
import React from 'react';
import { WkAddNoteIcon } from './wkAddNoteIcon';
import { WkActionEditIcon } from './wkActionEditIcon';
import { WkActionDeleteIcon } from './wkActionDeleteIcon';
import { WkExpenseIcon } from './wkExpenseIcon';
import { WkFeeIcon } from './wkFeeIcon';
import { WkLIFlagIcon } from './wkLIFlagIcon';
import { WkVendorAdjIcon } from './wkVendorAdjIcon';
import { WkITPAdjIcon } from './wkITPAdjIcon';
import { WkReviewerAdjIcon } from './wkReviewerAdjIcon';
import { WkTaxIcon } from './wkTaxesIcon';
import { WkNoteInfoIcon } from './wkNotesIcon';
import { WkAppealInfoIcon } from './wkAppealIcon';
import { WkCreditNoteIcon } from './wkCreditNoteIcon';
import { WkUndoIcon } from './wkUndoIcon';

export const getIcon = (iconName: string, theme: SxProps<Theme>): JSX.Element => {
    if (iconName == 'clear') {
        return <ClearIcon sx={theme} />;
    }
    if (iconName == 'expand') {
        return <ExpandMore sx={theme} />;
    }
    if (iconName == 'addnote') {
        return <WkAddNoteIcon />;
    }
    if (iconName == 'editnote' || iconName == 'adjust' || iconName == 'modify') {
        return <WkActionEditIcon />;
    }
    if (iconName == 'deletenote' || iconName == 'reject') {
        return <WkActionDeleteIcon />;
    }
    if (iconName == 'expense') {
        return <WkExpenseIcon />;
    }
    if (iconName == 'fee') {
        return <WkFeeIcon />;
    }
    if (iconName == 'liflag') {
        return <WkLIFlagIcon />;
    }
    if (iconName == 'vendoradjustments') {
        return <WkVendorAdjIcon />;
    }
    if (iconName == 'itpadjustments') {
        return <WkITPAdjIcon />;
    }
    if (iconName == 'revieweradjustments') {
        return <WkReviewerAdjIcon />;
    }
    if (iconName == 'taxes') {
        return <WkTaxIcon />;
    }
    if (iconName == 'notesimage') {
        return <WkNoteInfoIcon />;
    }
    if (iconName == 'appealimage') {
        return <WkAppealInfoIcon />;
    }
    if (iconName == 'creditedimage') {
        return <WkCreditNoteIcon />;
    }
    if (iconName == 'undo') {
        return <WkUndoIcon />;
    }
    return <QuestionMarkIcon />;
};