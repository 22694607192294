import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import useIsMounted from './useIsMounted';

const useWindowHeight = (delay = 300): number => {
    const isMounted = useIsMounted();
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            if (!isMounted.current) {
                return;
            }
            setHeight(window.innerHeight);
        };
        const debouncedHandleResize = debounce(handleResize, delay);
        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, [delay, isMounted]);

    return height;
};

export default useWindowHeight;
