import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useQuery } from '../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
    ComponentType,
    PageComponent,
    PageData,
    PageHeaderLinks,
    selectPageState,
    setPageState,
    ViewScreenPageStyle,
    ContentHolder,
} from '../../../store/slices';
import { apiFetch } from '../../../utils/fetchUtils';
import { LoaderScope, useLoader } from '../../core/blockUi/useLoader.hook';

type ViewScreenComponent =
    | ViewScreenLayoutComponent
    | {
          type: Exclude<ComponentType, ComponentType.LayoutComponent>;
      };

interface ViewScreenLayoutComponent {
    type: ComponentType.LayoutComponent;
    columns: ViewScreenLayoutComponentColumn[];
    catalogName: string;
    contentHolder: ContentHolder;
}

interface ViewScreenLayoutComponentColumn {
    width: number;
    components: ViewScreenComponent[];
}

interface ViewScreenData {
    components: ViewScreenComponent[];
    pageHeaderLinks: PageHeaderLinks[];
    pageStyle?: ViewScreenPageStyle;
}

interface MapComponentParams {
    component: ViewScreenComponent;
    columnId?: string;
    order?: number;
}

// eslint-disable-next-line max-lines-per-function
export const useGridScreen = () => {
    const { apiContextRoot, apiContextPath } = window.Props;
    const apiBaseUrl = `${String(apiContextRoot)}${String(apiContextPath)}/`;
   
    const { url = '' } = useQuery<{ url: string }>();
    const dispatch = useAppDispatch();
   
    const pageState = useAppSelector(selectPageState());
    
    
    const { isLoading, incrementLoadingCounter, resetLoadingCounter } = useLoader({
        scope: LoaderScope.ViewUI,
    });

    const loadPageState = async (): Promise<void> => {
        incrementLoadingCounter();
        const viewName = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
        const viewParams = window.location.search;
        const viewUrl = `${apiBaseUrl}view/${viewName}/${viewParams}`;
        const gridScreenData = await apiFetch<ViewScreenData>(viewUrl);

        const { pageHeaderLinks } = gridScreenData;

        const mapComponent = (props: MapComponentParams): PageComponent => {
            const { component, columnId, order } = props;

            
                return {
                    id: uuid(),
                    ...component,
                    layoutColumnId: columnId,
                    order,
                } as PageComponent;
          
        };

        const pageData: PageData = {
            components: gridScreenData.components.map((component, index) => mapComponent({ component, order: index })),
            
            pageHeaderLinks,
            pageStyle: gridScreenData.pageStyle,
            
        };

        dispatch(
            setPageState({
                data: pageData,
                title: 'Invoice Line Item'
            }),
        );

        resetLoadingCounter();
    };


    useEffect(() => {
        void loadPageState();
    }, [url]);

    return {
        isLoading,
        pageState,
    };
};
