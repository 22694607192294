import { Button, MenuItem, Input } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { CSSProperties, forwardRef, useState, useEffect } from 'react';
import { FilterComponent } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { Tooltip } from '../../../common';
import { ControlType } from '../../../common/types';
import { FilterAccordion } from './accordion';
import { Field } from './field';
import { useFilterView } from './hooks';
import { Select } from './select';

const useStyles = makeStyles<AppTheme>((theme) => ({
    filterArea: {
        margin: '0px !important;',
        '@media print': { display: 'none;' },
    },
    filterDropDown: {
        height: 22,
        width: 300,
    },
    filterTextBox: {
        paddingTop: 4,
        height: 22,
        width: 300,
        border: `1px solid ${theme.palette.grey[500]}`,
        fontSize: '0.95rem',
        '& .MuiInput-input': {
            textIndent: '10px',
        },
        '&:hover': {
            borderColor: theme.palette.primary.dark,
            borderWidth: 2,
        },
        '& .MuiInput-input:focus': {
            borderColor: theme.palette.common.black,
            borderWidth: 2,
        },
    },
    filterSection: {
        display: 'inline-block',
        verticalAlign: 'top',
    },
    searchButton: {
        display: 'block;',
        marginBottom: '8px',
        lineHeight: '20px',
        boxShadow: 'none',
        width: 130,
    },
}));

type FilterProps = {
    component: FilterComponent;
    onSetSharedStorage?: (key: string, value: unknown) => void;
    classes?: {
        root?: string;
    };
    style?: CSSProperties;
};

// eslint-disable-next-line max-lines-per-function
export const FilterView = forwardRef<HTMLDivElement, FilterProps>((props, ref) => {
    const classes = useStyles();
    const { component, onSetSharedStorage } = props;
    const initialValues: Record<string, string> = {};
    component.filters.forEach((filter) => {
        initialValues[filter.filterCode] = filter.selectedValue;
    });
    const [filterValues, setFilterValues] = useState<Record<string, string>>(initialValues);
    const { dropDownSources, updateDependentSources, saveDefault, resetToDefault } = useFilterView({
        component,
        filterValues,
    });

    useEffect(() => {
        doGridSearch(filterValues);
    }, []);

    const filterSelectedValueChange = (value: string, code: string) => {
        const newFilterValues = { ...filterValues, [code]: value };
        setFilterValues(newFilterValues);
        void updateDependentSources(code, newFilterValues);
    };

    const handleSearchButtonClick = (): void => {
        doGridSearch(filterValues);
    };

    const handleResetButtonClick = (): void => {
        setFilterValues({});
    };

    const handleSaveDefaultButtonClick = (): void => {
        void saveDefault(filterValues);
        doGridSearch(filterValues);
    };

    const handleResetDefaultButtonClick = async (): Promise<void> => {
        setFilterValues(
            (await resetToDefault()).reduce((agg, filter) => {
                agg[filter.filterCode] = filter.selectedValue;
                updateDependentSources(filter.filterCode, { [filter.filterCode]: filter.selectedValue });
                return agg;
            }, {}),
        );
    };

    const doGridSearch = (filterValues: Record<string, string>): void => {
        onSetSharedStorage?.(`grid.${component.dataSource}.filter`, filterValues);
    };

    return (
        <div data-testid="page-grid-filter" className={classes.filterArea} ref={ref}>
            <FilterAccordion isExpanded={true}>
                <div className={classes.filterSection}>
                    {component.filters.map((filter, index) => {
                        if (filter.type === ControlType.TextBox) {
                            return (
                                <Field name={filter.title} key={index}>
                                    <Tooltip title={'Enter ' + filter.title}>
                                        <Input
                                            className={classes.filterTextBox}
                                            disableUnderline
                                            value={filterValues[filter.filterCode] ?? ''}
                                            onKeyDown={(event): void => {
                                                event.key.toLowerCase() === 'enter' && handleSearchButtonClick();
                                            }}
                                            onChange={(event): void =>
                                                filterSelectedValueChange(event.target.value, filter.filterCode)
                                            }
                                        />
                                    </Tooltip>
                                </Field>
                            );
                        }
                        if (filter.type === ControlType.DropDown) {
                            return (
                                <Field name={filter.title} key={index}>
                                    <Tooltip title={'Select ' + filter.title}>
                                        <div>
                                            <Select
                                                variant="standard"
                                                className={classes.filterDropDown}
                                                value={filterValues[filter.filterCode] ?? ''}
                                                onChange={(event) =>
                                                    filterSelectedValueChange(
                                                        event.target.value as string,
                                                        filter.filterCode,
                                                    )
                                                }>
                                                {dropDownSources && dropDownSources[filter.filterCode] ? (
                                                    dropDownSources[filter.filterCode].map((dropDownItem, index) => (
                                                        <MenuItem key={index} value={dropDownItem.id}>
                                                            {dropDownItem.name}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem value={''}></MenuItem>
                                                )}
                                            </Select>
                                        </div>
                                    </Tooltip>
                                </Field>
                            );
                        }
                        return;
                    })}
                </div>
                <div className={classes.filterSection}>
                    <Tooltip title="Search">
                        <Button
                            disableRipple
                            variant="contained"
                            color="primary"
                            className={classes.searchButton}
                            onClick={() => handleSearchButtonClick()}>
                            Search
                        </Button>
                    </Tooltip>
                    <Tooltip title="Clear search filter">
                        <Button
                            disableRipple
                            variant="outlined"
                            color="primary"
                            className={classes.searchButton}
                            onClick={() => handleResetButtonClick()}>
                            Reset
                        </Button>
                    </Tooltip>
                    <Tooltip title="Save default search parameters">
                        <Button
                            disableRipple
                            variant="outlined"
                            color="primary"
                            className={classes.searchButton}
                            onClick={() => handleSaveDefaultButtonClick()}>
                            Save Default
                        </Button>
                    </Tooltip>
                    <Tooltip title="Set filter values to default">
                        <Button
                            disableRipple
                            variant="outlined"
                            color="primary"
                            className={classes.searchButton}
                            onClick={() => handleResetDefaultButtonClick()}>
                            Reset to Default
                        </Button>
                    </Tooltip>
                </div>
            </FilterAccordion>
        </div>
    );
});

FilterView.displayName = 'FilterView';
