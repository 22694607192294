import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkWarningIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="WkWarningIcon" viewBox="0 0 16 16" {...props}>
        <g>
            <path
                id="Color"
                style={{ fill: 'currentcolor' }}
                d="M13.9,16H2.1c-0.6,0-1.1-0.2-1.5-0.6C0.2,15,0,14.4,0,13.9c0-0.6,0.2-1.1,0.5-1.6
		c0.1-0.1,0.2-0.3,0.3-0.5L5.9,1.5C6.2,0.7,7.1,0.1,8,0c0.9,0.1,1.8,0.7,2.1,1.5l5.1,10.2c0.1,0.2,0.2,0.4,0.3,0.5
		c0.3,0.5,0.5,1,0.5,1.6c0,0.6-0.2,1.1-0.6,1.5C15,15.8,14.4,16,13.9,16z M7.5,11.7v2.1h1.1v-2.1H7.5z M7.5,4.3v6.4h1.1V4.3L7.5,4.3
		z"
            />
            <rect style={{ fill: 'none' }} width="16" height="16" />
        </g>
    </SvgIcon>
);

export default WkWarningIcon;
