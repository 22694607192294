import { ITransformer } from './types';
import { ICHRenameDocument } from '../interfaces/operations';
import 'reflect-metadata';
import { injectable } from 'inversify';
import { IRenameDocument } from '@wk/elm-uui-doc-component';

@injectable()
export class CtxRenameDocumentTransformer implements ITransformer {
    data!: ICHRenameDocument;

    transform(): IRenameDocument {
        const nodeObj = {} as IRenameDocument;
        nodeObj.entityContext = {
            name: this.data?.associatedEntityName || '',
            id: this.data?.associatedEntityId,
            type: this.data?.associatedEntityType,
        };

        const extendedProps = <IRenameDocument['extendedProps']>{};
        // T360
        // T360
        if (this.data?.editAccessType) {
            nodeObj.editAccessType = this.data?.editAccessType;
        }
        // T360
        if (this.data?.viewAccessType) {
            nodeObj.viewAccessType = this.data?.viewAccessType;
        }

        if (this.data?.props?.admittedMembers) {
            extendedProps.admittedMembers = this.data?.props?.admittedMembers;
        }
        nodeObj.docId = this.data?.documentId;
        nodeObj.extendedProps = extendedProps;
        nodeObj.name = this.data?.name;
        return nodeObj;
    }
}
