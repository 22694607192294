import { apiFetch } from '../../../../../utils/fetchUtils';

export const useNoteAction = () => {
    const { apiContextRoot, apiContextPath } = window.Props;
    const apiBaseUrl = `${String(apiContextRoot)}${String(apiContextPath).replace("views", "")}`;
    const params = new URLSearchParams(window.location.search)
    const entity = params.get('entity');
    const saveData = async (actionType: string, entityId: string, description: any): Promise<void> => {
        try {
            const data = {
                EntityId: entityId,
                Entity: entity,
                Description: description,
                code: entity + '-' + entityId,
            };
            const url = `${apiBaseUrl}notes/${actionType}`;
            await apiFetch<void>(url, data);
        } catch (error) {
            console.error("Error in useNoteAction:", error);
        }
    };

    const getDescription = async (entityId: string) => {
        const url = `${apiBaseUrl}notes/viewnote/${entityId}`;
        const response = await apiFetch(url);
        return response;
    };
    return { saveData, getDescription };
};