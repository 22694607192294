import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useAppDispatch } from '../../store';
import { closeDialog } from '../../store/slices';
import { isPromise } from '../../utils/utilities';
import CloseIcon from '../icons/wkCloseIcon';
import css from './alertDialog.module.scss';
import UUIButton from './uuiButton';

export interface IAlertDialog {
    id: string;
    title: string;
    type?: 'normal' | 'error';
    message: string;
    buttons: IAlertDialogButton[];
    onClose?(): void;
}

export interface IAlertDialogButton {
    label: string;
    onClick(): void | Promise<void>;
}

export const AlertDialog: React.FC<IAlertDialog> = ({ id, type = 'normal', title, message, buttons, onClose }) => {
    const [open, setOpen] = React.useState(true);
    const dispatch = useAppDispatch();

    return (
        <Dialog
            open={open}
            onClose={() => {
                if (onClose) {
                    onClose();
                }
                setOpen(false);
            }}
            BackdropProps={{ classes: { root: type === 'error' ? css.opaqueBackdrop : '' } }}
            TransitionProps={{
                onExited: (): void => {
                    dispatch(closeDialog({ id }));
                },
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{
                paper: css.dialogPaperRoot,
            }}>
            <DialogTitle
                data-testid="alert-dialog-title"
                className={classNames(css.dialogTitleRoot, { [css.dialogTitleErrorRoot]: type === 'error' })}>
                <div className={css.titleTextContainer}>
                    <Typography variant="h6" noWrap id="modal-title" className={css.titleText}>
                        {title}
                    </Typography>
                </div>
                <div className={css.closeIconContainer}>
                    <IconButton
                        size="small"
                        className={css.closeIcon}
                        onClick={() => {
                            if (onClose) {
                                onClose();
                            }
                            setOpen(false);
                        }}
                        data-testid="dialog-close-button"
                        aria-label="Close Dialog">
                        <CloseIcon className={css.closeIconRoot} />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent classes={{ root: css.contentRoot }}>
                <DialogContentText data-testid="alert-dialog-message">{message}</DialogContentText>
            </DialogContent>
            <DialogActions className={css.dialogActions}>
                {buttons.map((button, i) => (
                    <UUIButton
                        key={i}
                        variant={i === 0 ? 'contained' : 'outlined'}
                        onClick={() => {
                            const possiblePromise = button.onClick();
                            if (isPromise(possiblePromise)) {
                                void trackPromise(possiblePromise).then(() => {
                                    setOpen(false);
                                });
                            } else {
                                setOpen(false);
                            }
                        }}
                        color="primary"
                        classes={{
                            root: classNames(
                                buttons.length === 1
                                    ? css.outLinedButton
                                    : i === buttons.length - 1
                                    ? css.outLinedButtonWhite
                                    : i == 0
                                    ? css.containedButtons
                                    : i == 1 && css.outLinedButton0,
                                css.buttonLabel,
                            ),
                        }}
                        data-testid={button.label}>
                        {button.label}
                    </UUIButton>
                ))}
            </DialogActions>
        </Dialog>
    );
};
