import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const wkQueueErrorIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkQueueErrorIcon" viewBox="0 0 20 20" {...props}>
        <g>
            <g>
                <path
                    id="Path_4637"
                    d="M13,7.8L12.1,7L10,9.1L7.8,7L7,7.8L9.1,10l-2.2,2.2L7.8,13l2.2-2.2l2.2,2.2l0.9-0.9L10.9,10
			L13,7.8z"
                />
                <path
                    id="Color_2_"
                    d="M10,20c-5.5,0-10-4.4-10-9.9h1.5c0,4.6,3.8,8.3,8.4,8.4H10c2.8,0,5.5-1.4,7.1-3.8h-4v-1.5h6.2
			v6.1h-1.5v-2.9c-0.9,1.1-2.1,2-3.4,2.7C13,19.7,11.5,19.9,10,20z M20,10.1h-1.5c0-4.6-3.8-8.3-8.4-8.4H10c-2.8,0-5.5,1.4-7.1,3.8
			h4v1.5H0.7V0.8h1.5v2.9c0.9-1.1,2.1-2,3.4-2.7c1.3-0.7,2.8-1,4.3-1C15.4,0.2,19.9,4.6,20,10.1z"
                />
            </g>
            <rect y="0" fill="none" width="20" height="20" />
        </g>
    </SvgIcon>
);

export default wkQueueErrorIcon;
