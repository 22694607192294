import { CircularProgress } from '@mui/material';
import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import css from './blockUi.module.scss';
import { LoaderScope } from './useLoader.hook';

const scopes = [...Object.values(LoaderScope), undefined];

export const BlockUi: React.FC = () => {
    const inProgress = scopes.reduce((agg, scope) => {
        const { promiseInProgress } = usePromiseTracker({ area: scope });
        return agg || promiseInProgress;
    }, false);
    const isHomeScreen = window.location.pathname.includes('homeItemView');
    return (
        <>
            {inProgress && (
                <>
                    {!isHomeScreen && <div className={css.blockUiContainer} aria-hidden="true" />}
                    <div className={css.blockUiMessageContainer}>
                        <CircularProgress data-testid="fullProgressBar" aria-label="progressBar" size={60} />
                    </div>
                </>
            )}
        </>
    );
};
