import { AppTheme } from '../useAppTheme.hook';
import { appContainer } from './appContainer.variables';
import { common } from './common.variables';
import { page } from './page.variables';
import { scrollbar } from './scrollbar.variables';

export const styles = {
    ...common,
};

export const getComponentsStyles = (theme: AppTheme) => {
    return {
        page: page(theme),
        appContainer: appContainer(theme),
        scrollbar: scrollbar(theme),
    };
};
