import { RefObject, useEffect, useState } from 'react';
import { NavigateParams, useNavigate, useQuery } from '../../../hooks';
import { BreadCrumb, IMenuItem } from '../../../reducers/types';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
    Currency,
    getAppResources,
    PageScript,
    setPageState,
    selectPageState,
    cleanPageState,
    setBreadCrumbState,
    selectGlobalState,
} from '../../../store/slices';
import { apiFetch } from '../../../utils/fetchUtils';
import { getLogger } from '../../../utils/loggingService';
import { TargetScreenType } from '../../common/types';
import { LoaderScope, useLoader } from '../../core/blockUi/useLoader.hook';
import { useExternalUICommunication } from './useExternalUIComunication.hook';

const logger = () => getLogger('useExternalUIState');

export interface LegacyPageMetadata {
    pageTitle: string;
    pageMenu: IMenuItem[];
    helpUrl: string;
    currency: Currency;
    pageScript: PageScript;
    pageScriptUrl: string;
    isBookmarkAddEnabled: boolean;
    breadcrumb: BreadCrumb;
}

type UseExternalUIStateProps = {
    iframeRef: RefObject<HTMLIFrameElement>;
};

export const useExternalUIState = (props: UseExternalUIStateProps) => {
    const { iframeRef } = props;
    const { url = '' } = useQuery<{ url: string }>();
    const [currentUrl, setCurrentUrl] = useState<string>();
    const dispatch = useAppDispatch();
    const { legacyContextRoot } = useAppSelector(getAppResources);
    const { isLoading, incrementLoadingCounter, resetLoadingCounter } = useLoader({
        scope: LoaderScope.ExternalUI,
    });
    const currency = useAppSelector(selectPageState('currency'));
    const [prevCurrencyEnabled, setPrevCurrencyEnabled] = useState<boolean>();
    const { send } = useExternalUICommunication({ iframeRef });
    const [bcItem, setBCItem] = useState<BreadCrumb>();
    const breadCrumbItems = useAppSelector(selectGlobalState('breadcrumbs'));
    const { navigate } = useNavigate();

    const dispatchUrlChange = async (url?: string): Promise<void> => {
        if (!url) {
            return;
        }

        incrementLoadingCounter();

        if (redirectOnViewScreenType(navigate, url)) {
            return;
        }

        const metadata = await getMetadata(url);

        const pageScript = await getPageScript(metadata, legacyContextRoot);
        const { pageTitle, pageMenu, helpUrl, currency, isBookmarkAddEnabled, breadcrumb } = metadata;
        dispatch(
            setPageState({
                title: pageTitle,
                addBookmarkEnabled: isBookmarkAddEnabled,
                pageMenu,
                helpUrl,
                currency,
                pageScript,
            }),
        );
        if (breadcrumb?.isRequired) {
            setBCItem({ title: breadcrumb.title, url: url, isRequired: breadcrumb?.isRequired });
        }
        resetLoadingCounter();
    };

    useEffect(() => {
        logger().debug(' ----- CHANGE INTERNAL LEGACY URL ------ ');
        incrementLoadingCounter();
        const newUrl = new URL(url, legacyContextRoot);
        setCurrentUrl(`${legacyContextRoot}${newUrl.toString().replace(newUrl.origin, '')}`);
    }, [legacyContextRoot, url]);

    useEffect(() => {
        const beforeUnloadHandler = () => {
            logger().debug(' ----- IFRAME - BEFORE UNLOAD ------ ');
            incrementLoadingCounter();
        };

        const unloadHandler = () => {
            logger().debug(' ----- IFRAME - UNLOAD ------ ');
            incrementLoadingCounter();
            iframeRef.current?.contentWindow?.removeEventListener('unload', unloadHandler);
        };

        const loadHandler = () => {
            logger().debug(' ----- IFRAME - LOAD ------ ');
            const iFrameUrl = iframeRef.current?.contentWindow?.location.href;
            void dispatchUrlChange(iFrameUrl);

            iframeRef.current?.contentWindow?.removeEventListener('beforeunload', beforeUnloadHandler);
            iframeRef.current?.contentWindow?.removeEventListener('unload', unloadHandler);

            iframeRef.current?.contentWindow?.addEventListener('unload', unloadHandler);
            iframeRef.current?.contentWindow?.addEventListener('beforeunload', beforeUnloadHandler);
        };

        iframeRef.current?.addEventListener('load', loadHandler);

        return () => {
            iframeRef.current?.removeEventListener('load', loadHandler);
            dispatch(cleanPageState());
        };
    }, [iframeRef]);

    useEffect(() => {
        if (!currency) {
            setPrevCurrencyEnabled(undefined);
            return;
        }
        if (prevCurrencyEnabled !== undefined && prevCurrencyEnabled !== Boolean(currency.enabled)) {
            send({ type: 'event', name: 'page-refresh' });
        }
        setPrevCurrencyEnabled(Boolean(currency.enabled));
    }, [currency?.enabled, iframeRef?.current]);

    useEffect(() => {
        if (bcItem?.title === 'Home' && bcItem.isRequired) {
            dispatch(setBreadCrumbState({ bc: [] }));
        } else if (bcItem) {
            const isExist = breadCrumbItems?.some((item) => item.title === bcItem.title);
            if (!isExist) {
                const breadCrumbsToAdd = addBreadCrumb(breadCrumbItems, bcItem);
                dispatch(setBreadCrumbState({ bc: breadCrumbsToAdd }));
            }
        }
    }, [bcItem]);

    return {
        currentUrl,
        isLoading,
    };
};

const addBreadCrumb = (currentBreadCrumbs: BreadCrumb[], newBreadCrumb: BreadCrumb): BreadCrumb[] => {
    const newCrumb = currentBreadCrumbs.concat([newBreadCrumb]);
    return [...new Set(newCrumb)];
};

const getPageScript = async (
    { pageScript, pageScriptUrl }: LegacyPageMetadata,
    legacyContextRoot: string,
): Promise<PageScript> => {
    if (!pageScriptUrl) {
        return pageScript;
    }

    const requestUrl = `${legacyContextRoot}${pageScriptUrl}`;
    const metadata = await getMetadata(requestUrl);

    return metadata.pageScript;
};

const getMetadata = async (url: string): Promise<LegacyPageMetadata> => {
    const metadataUrl = new URL(url);
    const { searchParams } = metadataUrl;

    searchParams.set('spaRequestType', 'pageMetadata');
    searchParams.set('_c', 'n');
    metadataUrl.search = searchParams.toString();

    return await apiFetch<LegacyPageMetadata>(metadataUrl.toString(), undefined);
};

const redirectOnViewScreenType = (navigate: (params: Partial<NavigateParams>) => void, url: string): boolean => {
    const viewScreenUrl = new URL(url);
    const { searchParams, origin, pathname } = viewScreenUrl;
    if (searchParams.get('screenType') === TargetScreenType.View) {
        navigate({ to: origin.concat(pathname).replace(/(?<!:)\/+/g, '/'), options: { replace: true } });
        return true;
    }
    return false;
};
