import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkCloseCircleIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkCloseCircleIcon" viewBox="0 0 24 24" {...props}>
        <g id="Group_9347" data-name="Group 9347" transform="translate(0 -1.801)">
            <g id="Icon_Open_check-circle" data-name="Icon/Open/check-circle" transform="translate(0 1.801)">
                <path
                    id="Color"
                    d="M8,16a8,8,0,1,1,8-8A8.009,8.009,0,0,1,8,16ZM8,1.067A6.86,6.86,0,0,0,1.067,8,6.859,6.859,0,0,0,8,14.933,6.859,6.859,0,0,0,14.933,8,6.859,6.859,0,0,0,8,1.067Z"
                    transform="translate(0 0)"
                    fill="#005b92"
                />
            </g>
            <g id="Path" transform="translate(4 5.801)" fill="#005b92">
                <path
                    d="M 7.089900970458984 7.936700820922852 L 4.039070606231689 4.885870933532715 L 4.003710746765137 4.850520610809326 L 3.968350648880005 4.885870933532715 L 0.9175207614898682 7.936700820922852 L 0.07072074711322784 7.089900970458984 L 3.121550798416138 4.039070606231689 L 3.156900644302368 4.003710746765137 L 3.121550798416138 3.968350648880005 L 0.07072074711322784 0.9175207614898682 L 0.9175207614898682 0.07072074711322784 L 3.968350648880005 3.121550798416138 L 4.003710746765137 3.156900644302368 L 4.039070606231689 3.121550798416138 L 7.089900970458984 0.07072074711322784 L 7.936700820922852 0.9175207614898682 L 4.885870933532715 3.968350648880005 L 4.850520610809326 4.003710746765137 L 4.885870933532715 4.039070606231689 L 7.936700820922852 7.089900970458984 L 7.089900970458984 7.936700820922852 Z"
                    stroke="none"
                />
                <path
                    d="M 0.9175205230712891 0.141420841217041 L 0.141420841217041 0.9175205230712891 L 3.22761058807373 4.003710746765137 L 0.141420841217041 7.089900970458984 L 0.9175205230712891 7.866000652313232 L 4.003710746765137 4.779810905456543 L 7.089900970458984 7.866000652313232 L 7.866000652313232 7.089900970458984 L 4.779810905456543 4.003710746765137 L 7.866000652313232 0.9175205230712891 L 7.089900970458984 0.141420841217041 L 4.003710746765137 3.22761058807373 L 0.9175205230712891 0.141420841217041 M 0.9175205230712891 9.5367431640625e-07 L 4.003710746765137 3.086190700531006 L 7.089900970458984 9.5367431640625e-07 L 8.007420539855957 0.9175205230712891 L 4.921230792999268 4.003710746765137 L 8.007420539855957 7.089900970458984 L 7.089900970458984 8.007420539855957 L 4.003710746765137 4.921230792999268 L 0.9175205230712891 8.007420539855957 L 9.5367431640625e-07 7.089900970458984 L 3.086190700531006 4.003710746765137 L 9.5367431640625e-07 0.9175205230712891 L 0.9175205230712891 9.5367431640625e-07 Z"
                    stroke="none"
                    fill="#fff"
                />
            </g>
        </g>
    </SvgIcon>
);

export default WkCloseCircleIcon;
