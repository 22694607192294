import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

// eslint-disable-next-line react/display-name
const WkChatOffline: React.FC<SvgIconProps> = React.forwardRef((props, ref) => (
    <SvgIcon data-testid="wkChatOffline" {...props} ref={ref} viewBox="0 0 48 48">
        <path d="M41.15 36L38.15 33H41.2V7H12.1L9.1 4H41.2C41.9667 4 42.6583 4.30833 43.275 4.925C43.8917 5.54167 44.2 6.23333 44.2 7V33C44.2 33.7667 43.9083 34.4583 43.325 35.075C42.7417 35.6917 42.0167 36 41.15 36ZM43.2 46.6L32.6 36H12.2L4.2 44V7.55L3 6.4L5.05 4.2L45.35 44.5L43.2 46.6ZM7.2 10.55V36.75L10.95 33H29.6L7.2 10.55Z" />
    </SvgIcon>
));

export default WkChatOffline;
