import { UUIHistory } from '../hooks';
import { getHistoryStackForCurrentOCInstance, HARD_NAVIGATE } from './ocLocationService';

type MeasureStringWidth = (text: string, fontSize?: string, fontWeight?: string) => number;

export const measureStringWidth = ((): MeasureStringWidth => {
    const htmlCanvas = document.createElement('canvas');
    return (text: string, fontSize = '16px', fontWeight = 'normal'): number => {
        const context = htmlCanvas.getContext('2d');
        if (!context) {
            throw new Error('Could not find canvas context');
        }
        context.font = `${fontWeight} ${fontSize} Fira Sans,sans-serif`;
        const metrics = context.measureText(text);
        return Math.ceil(metrics.width);
    };
})();

// eslint-disable-next-line @typescript-eslint/ban-types
export const objectHasProperty = <X extends {}, Y extends PropertyKey>(
    obj: X,
    prop: Y,
): obj is X & Record<Y, unknown> => {
    return prop in obj;
};

export const isPromise = <T>(promise: Promise<T> | undefined | void): promise is Promise<T> => {
    return !!promise && typeof promise.then === 'function' && promise[Symbol.toStringTag] === 'Promise';
};

export const hardNavigateToHomePage = (homePageUrl?: string, history?: UUIHistory) => {
    if (!homePageUrl || !history) {
        return;
    }
    sessionStorage.setItem(HARD_NAVIGATE, 'true');
    const historyStack = getHistoryStackForCurrentOCInstance();
    if (historyStack && historyStack.length > 1) {
        history.goBack();
    } else {
        history.push(homePageUrl);
    }
};
