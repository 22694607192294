import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogLevel } from '@wk/elm-uui-common';
import { ILogLevel } from '../../reducers/types';

export interface HelpSectionState {
    logLevelSelectorDialogOpen: boolean;
    logLevel: ILogLevel;
}

const initialState: HelpSectionState = {
    logLevelSelectorDialogOpen: false,
    logLevel: { key: LogLevel.Error, value: '' },
};

const helpSection = createSlice({
    name: 'help-section',
    initialState,
    reducers: {
        logLevelSelectorDialogOpen: (state, action: PayloadAction<{ isOpen: boolean }>) => {
            state.logLevelSelectorDialogOpen = action.payload.isOpen;
        },
        saveLogLevel: (state, action: PayloadAction<{ updateLogLevel: ILogLevel }>) => {
            state.logLevel = action.payload.updateLogLevel;
        },
    },
});

// Actions & Reducer
export type HelpSectionActions = TakeActions<typeof helpSection.actions>;
export const { logLevelSelectorDialogOpen, saveLogLevel } = helpSection.actions;
export const helpSectionReducer = helpSection.reducer;
