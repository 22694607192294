import { Typography } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../store';
import { getAppResources, getApplicationUrls } from '../../../store/slices';
import ActionDialog from '../../common/actionDialog';
import { useListScreenDispatch, useListScreenState } from '../context/listScreenContext';
import { useRefreshList } from '../context/listScreenHooks';
import { resetUserDefault, generatePagePostObject } from '../listScreenHelpers';

export const ResetToDefaultActionDialog: React.FC = () => {
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const refreshList = useRefreshList();
    const metadata = listScreenState.metadata!;
    const listData = listScreenState.listData!;
    const listScreenUrl = listScreenState.dataUrl;
    const applicationUrls = useAppSelector(getApplicationUrls);
    const appResources = useAppSelector(getAppResources);

    const actionButtonClickHandler = () => {
        resetUserDefault(
            metadata.screenId,
            applicationUrls.applicationResetToSystemDefaultSavedViewUrl,
            listScreenUrl,
        ).then((json) => {
            if (json.result === 'success') {
                listScreenDispatch({
                    type: 'RemoveSavedView',
                    existingSavedViewIndex: metadata.savedViews.findIndex((sv) => sv.isUserDefault),
                });

                if (listData.page.savedView.isSystemDefault || listData.page.savedView.isUserDefault) {
                    // User currently has selected the default tab in listScreen

                    const savedViewToLoad = metadata.savedViews.find((sv) => sv.isSystemDefault);

                    const pageInfo = generatePagePostObject(listData.page, {
                        'column-data': savedViewToLoad!.columns ? savedViewToLoad!.columns[0] : undefined,
                        filters: savedViewToLoad!.filters,
                        sortInfo: savedViewToLoad!.sort,
                        pageNumber: 1,
                        savedView: savedViewToLoad,
                    });
                    refreshList({ postObject: pageInfo, resetBaselinePageData: true });
                } else {
                    listScreenDispatch({ type: 'CloseResetToDefaultDialog' });
                }
            }
        });
    };

    const getContentText = () => {
        let tabName = listData.page ? listData.page.savedView.name : '';

        if (listData.page && (listData.page.savedView.isSystemDefault || listData.page.savedView.isUserDefault)) {
            tabName = listScreenState.isEmbeddedList
                ? appResources.listscreenDefaultDropdownText
                : metadata.screenDisplayName;
        }
        tabName = '"' + tabName + '"';

        const contentBase = appResources.dialogContentResetToDefault;

        if (contentBase) {
            return contentBase.replace('{tabName}', tabName);
        } else {
            return '';
        }
    };

    return (
        <ActionDialog
            open={listScreenState.resetToDefaultDialogOpen}
            handleActionClose={() => listScreenDispatch({ type: 'CloseResetToDefaultDialog' })}
            actionButtonClickHandler={actionButtonClickHandler}
            title={appResources.dialogTitleResetToDefault}
            actionButtonText={appResources.buttonResetToDefault}
            disableActionButton={false}>
            <Typography gutterBottom data-testid="resetToDefault-dialog-content">
                {getContentText()}
            </Typography>
        </ActionDialog>
    );
};
