import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import { FileOperations } from '@wk/office-companion-js';
import { ICHDocumentMetadata, ICHUploadType, ICHUploadResponse, FileUploadControl } from './../interfaces/operations';
import { IDocumentMetadataEmail, documentMetadataEmail, IAddEmail, addEmail } from '@wk/elm-uui-doc-component';
import { DocumentEntity } from '../dto/documentEntity';
import { getTransformedObject } from '../transform/helper';
import { CtxDocumentMetadataTransformer } from '../transform/ctxDocumentMetadataTransformer';
import { CtxAddEmailTransformer } from '../transform/ctxAddEmailTransformer';
import { QueueController } from '../queueController';
import { getSupportedEntityTypesInfo, throwUnExpectedError, deleteFromPersistentStorage } from '../utils/main.utils';
import dayjs from 'dayjs';
import { Locale } from '../locale';
import { CapabiltyEnum, CHSupportedDocumentTypesEnum, EmailType, EmailMessageClass } from '../enum/enum';
import { factory } from '../configLog4J';
import { v4 as uuid } from 'uuid';
import { EmailItem } from '../dto/emailItem';
import MsgReader from '@kenjiuno/msgreader';
import { FileExtensions } from '../enum/file-extensions.enum';
import { implementQueue } from '../capability';
import { OutlookService } from './office';
import { IOfficeService } from './office.service';

const log = factory.getLogger('EmailService');

export interface IEmailService {
    getFiles(inputFileId: string): Promise<FileList | undefined>;
    clearInputFile(inputFileId: string): void;
    getEmailMeta(emailObject: ICHDocumentMetadata): Promise<DocumentEntity | undefined>;
    addEmail(contextObj: ICHUploadType): Promise<ICHUploadResponse[]>;
    performDragAndDrop(contextObj: ICHAddEmailQueue): Promise<ICHUploadResponse[]>;
}
export interface ICHAddEmailQueue extends ICHUploadType {
    filePath?: string;
}
@injectable()
export class EmailService implements IEmailService {
    private _outLookService: OutlookService;
    private _queueController: QueueController;
    private _officeService: IOfficeService;

    constructor(
        @inject('OutlookService') outLookService: OutlookService,
        @inject('QueueController') queueController: QueueController,
        @inject('OfficeService') officeService: IOfficeService,
    ) {
        this._outLookService = outLookService;
        this._queueController = queueController;
        this._officeService = officeService;
    }

    public async getFiles(inputFileId: string): Promise<FileList | undefined> {
        try {
            const fileList = document.getElementById(inputFileId) as HTMLInputElement;
            if (fileList.files && fileList.files.length) {
                return fileList.files;
            }
        } catch (error) {
            log.error(' OC | getFiles | Error : ', error);
        }
        return undefined;
    }

    public clearInputFile(inputFileId: string): void {
        const fileList = document.getElementById(inputFileId) as HTMLInputElement;
        if (fileList.files) {
            for (let index = 0; index < fileList.files?.length; index++) {
                fileList[index] = null;
            }
        }
    }

    public async getEmailMeta(emailObject: ICHDocumentMetadata): Promise<DocumentEntity | undefined> {
        let transformedObject: IDocumentMetadataEmail;
        try {
            log.info('OC | Gets Email Document Meta using ID');
            transformedObject = getTransformedObject<
                ICHDocumentMetadata,
                IDocumentMetadataEmail,
                CtxDocumentMetadataTransformer
            >(CapabiltyEnum.DOCUMENT_METADATA, emailObject);

            const response = await documentMetadataEmail(transformedObject);
            if (response && response.ok) {
                const documentEntity = await response.outputInfo();
                log.info('OC | File Email Meta Response' + JSON.stringify(documentEntity));
                if (documentEntity) {
                    const { id } = documentEntity;
                    if (id != '0' || id != 0) {
                        return documentEntity;
                    } else {
                        log.error('OC | Email not found');
                        return undefined;
                    }
                }
            } else {
                log.error('OC | Email not found');
                return undefined;
            }

            return;
        } catch (err) {
            log.error('OC | Error in Email Meta', err);
            throwUnExpectedError(err);
            return;
        }
    }

    private async performupload(contextObj: ICHAddEmailQueue, error?: string): Promise<ICHUploadResponse> {
        if (!error && this._queueController.AddEmail) {
            log.info(`adding email via queue`);
            return await implementQueue(contextObj, CapabiltyEnum.ADD_EMAIL);
        } else if (error && this._queueController.AddEmail) {
            log.info(`Error message for invalid email via queue: ${error}`);
            return await implementQueue(contextObj, CapabiltyEnum.ADD_EMAIL, error);
        } else {
            log.info(`email upload without queue involvement`);
            return await this.emailUpload(contextObj);
        }
    }

    private async addInvalidEmails(
        contextObj: ICHAddEmailQueue,
        invalidEmailMetada: EmailItem,
    ): Promise<ICHUploadResponse> {
        log.debug(`email type: ${invalidEmailMetada.emailType}`);
        log.debug(`email subject: ${invalidEmailMetada.emailSubject}`);
        contextObj.props = {};
        contextObj.props.emailSubject = invalidEmailMetada.emailSubject;
        contextObj.filePath = invalidEmailMetada.path;
        contextObj.props.uniqueId = this._outLookService.getEmailUniqueId(invalidEmailMetada);
        const errorMessage = invalidEmailMetada.emailType
            ? this.buildErrorMessage(invalidEmailMetada.emailType)
            : 'Error occured during an Email Upload';
        return await this.performupload(contextObj, errorMessage);
    }

    private async addValidEmails(
        contextObj: ICHAddEmailQueue,
        validEmailMetada: EmailItem,
    ): Promise<ICHUploadResponse> {
        log.debug(`email subject: ${validEmailMetada.emailSubject}`);
        contextObj.props = {};
        contextObj.filePath = validEmailMetada.path;
        contextObj.props.senderEmail = validEmailMetada.senderEmailAddress;
        contextObj.props.senderName = validEmailMetada.senderName;
        contextObj.props.receivedTime = dayjs(validEmailMetada.receivedTime, { utc: true }).format(
            'YYYY-MM-DDTHH:mm:ssZZ',
        );
        contextObj.props.emailRecipients = validEmailMetada.emailRecipients;
        contextObj.props.emailSubject = validEmailMetada.emailSubject;
        contextObj.props.emailBody = validEmailMetada.emailBody;
        contextObj.props.sentTime = dayjs(validEmailMetada.sentTime, { utc: true }).format('YYYY-MM-DDTHH:mm:ssZZ');
        contextObj.props.isAttachmentPresent = validEmailMetada.isAttachmentPresent;
        contextObj.props.uniqueId = this._outLookService.getEmailUniqueId(validEmailMetada);
        if (!contextObj.entityTypeId) {
            const supportedEntityTypeMap = await getSupportedEntityTypesInfo();
            for (const [key, val] of supportedEntityTypeMap.entries()) {
                if (val.toLowerCase() === CHSupportedDocumentTypesEnum.EMAIL.toLowerCase()) {
                    contextObj.entityTypeId = key;
                    break;
                }
            }
        }
        return await this.performupload(contextObj);
    }

    public getEmailItems(paths: string[]): string[] {
        log.debug(`filtering dropped items to get only emails`);
        return paths.filter((e) => {
            const fileExtension = this._officeService.getExtension(e.toLowerCase());
            return fileExtension === FileExtensions.Msg || fileExtension === FileExtensions.Eml;
        });
    }

    public async addEmail(contextObj: ICHAddEmailQueue): Promise<ICHUploadResponse[]> {
        const responseObject: ICHUploadResponse[] = [];
        try {
            let emailItemsMetadata: EmailItem[];
            if (contextObj.isDragAndDrop) {
                const paths = Object.assign([], contextObj.filePaths);
                const emailItems = this.getEmailItems(paths);
                emailItemsMetadata = await this.getEmailMetaData(emailItems);
            } else {
                log.debug(`getting email metedata for QF items`);
                emailItemsMetadata = await this._outLookService.getEmailMetadata();
                contextObj.noOfItems = emailItemsMetadata.length;
                log.debug(`no of emails QF: ${emailItemsMetadata.length}`);
            }

            for (const emailMetada of emailItemsMetadata) {
                const queueResponse =
                    emailMetada.emailType === EmailType.VALID
                        ? await this.addValidEmails(contextObj, emailMetada)
                        : await this.addInvalidEmails(contextObj, emailMetada);
                responseObject.push(queueResponse);
            }
        } catch (err) {
            log.error(' OC | Error occured during an Email Upload ' + err);
            deleteFromPersistentStorage(contextObj.filePaths);
            throwUnExpectedError(err);
            const responseText = 'Error occured | Email not uploaded successfully';
            responseObject.push({ responseText });
        }
        return responseObject;
    }

    public async performDragAndDrop(contextObj: ICHAddEmailQueue): Promise<ICHUploadResponse[]> {
        log.debug(`process dropped email(s)`);
        const paths = Object.assign([], contextObj.filePaths);
        if (contextObj.folderArr) {
            this._officeService.showEmailOrDocumentUploadToast(contextObj.folderArr[0], CapabiltyEnum.ADD_EMAIL);
        }
        return await this.addEmail(contextObj);
    }

    private async emailUpload(contextObj: ICHAddEmailQueue): Promise<ICHUploadResponse> {
        try {
            const { filePath } = contextObj;
            const { fileUploadControlId } = this.createFileUploadControl();
            const fileId = fileUploadControlId;
            contextObj.fileId = fileId;
            if (filePath && fileId) {
                const paths: string[] = [];
                paths.push(filePath);
                await FileOperations.setInputFiles(`#${fileId}`, paths);
                const files = await this.getFiles(fileId);
                const transformedEmailObject: IAddEmail = getTransformedObject<
                    ICHUploadType,
                    IAddEmail,
                    CtxAddEmailTransformer
                >(CapabiltyEnum.ADD_EMAIL, contextObj);
                if (files) {
                    transformedEmailObject.file = files[0];
                    const eFileName =
                        files[0].name === Locale.email.default.name_not_available
                            ? Locale.email.default.subject_name + Locale.email.default.extension
                            : files[0].name;
                    transformedEmailObject.name = eFileName;
                    transformedEmailObject.extendedProps.uploadGuid = contextObj.uploadGuid;
                    const response = await addEmail(transformedEmailObject);
                    return { response };
                }
            }
        } catch (error) {
            if (error) {
                log.error('OC | Add Email Error ' + error);
            }
            log.error('OC | emailUpload | Error during an add Email ' + error);
            throwUnExpectedError(error);
            return { responseText: error.message };
        } finally {
            if (contextObj.fileId) {
                this.removeInjectedFile(contextObj.fileId);
            }
        }
        return { responseText: 'Error occured | Email not uploaded successfully' };
    }

    private removeInjectedFile(fileId: string): void {
        const fileList = document.getElementById(fileId) as HTMLInputElement;
        fileList.remove();
    }

    private createFileUploadControl(): FileUploadControl {
        // generate random id for HTML Input File Element
        const fileUploadControlId = 'clFileUpload_' + uuid().toString();
        const fileUploadControl = document.createElement('input');
        fileUploadControl.setAttribute('type', 'file');
        fileUploadControl.setAttribute('style', 'visibility:hidden');
        fileUploadControl.setAttribute('id', fileUploadControlId);
        document.body.appendChild(fileUploadControl);
        return { fileUploadControl, fileUploadControlId };
    }

    private async getFileUploadControlId(fPath: string): Promise<string> {
        const { fileUploadControlId } = this.createFileUploadControl();
        const fileId = fileUploadControlId;
        const paths: string[] = [];
        paths.push(fPath);
        await FileOperations.setInputFiles(`#${fileId}`, paths);
        return fileId;
    }

    private async getParsedEmlMetaData(file: File): Promise<EmailItem> {
        log.debug('EML_Mailparser : ' + file.name);
        const subject = this._officeService.getFileNameWithoutExtension(file.name);
        return {
            emailSubject: subject,
        };
    }

    private async getParsedEmailMetaData(file: File): Promise<EmailItem> {
        log.debug('mailparser : ' + file.name);
        const array_buffer = await file.arrayBuffer();
        const msgReader = new MsgReader(array_buffer);
        const parsedEmailData = msgReader.getFileData();

        let emailRecipients = '';
        parsedEmailData.recipients?.map((recipient) => {
            if (recipient.recipType === 'to') {
                emailRecipients = recipient.name?.toString() ?? '';
            }
        });
        const subjectText = parsedEmailData.subject ? parsedEmailData.subject : Locale.email.default.subject_name;
        return {
            emailSubject: subjectText,
            emailRecipients: emailRecipients,
            emailBody: parsedEmailData.body,
            senderName: parsedEmailData.senderName,
            senderEmailAddress: parsedEmailData.senderEmail,
            receivedTime: dayjs(parsedEmailData.messageDeliveryTime?.toString(), { utc: true }).format(
                'YYYY-MM-DDTHH:mm:ssZZ',
            ),
            sentTime: dayjs(parsedEmailData.lastModificationTime?.toString(), { utc: true }).format(
                'YYYY-MM-DDTHH:mm:ssZZ',
            ),
            creatorSMTPAddress: parsedEmailData.creatorSMTPAddress,
            headers: parsedEmailData.headers,
            messageClass: parsedEmailData.messageClass,
            clientSubmitTime: parsedEmailData.clientSubmitTime,
            messageFlags: parsedEmailData.messageFlags,
        };
    }

    private async getEmailMetaData(paths: string[]): Promise<EmailItem[]> {
        log.debug(`getting email metedata for D&D items`);
        const emailItemArray: EmailItem[] = [];
        for (const path of paths) {
            try {
                let emailEntity: EmailItem;
                const fileId = await this.getFileUploadControlId(path);
                const files = await this.getFiles(fileId);
                if (files) {
                    const file = files[0] as File;
                    if (this._officeService.getExtension(path) === FileExtensions.Eml) {
                        log.debug(`dropped eml file to upload`);
                        emailEntity = await this.getParsedEmlMetaData(file);
                        emailEntity.emailType = EmailType.VALID;
                        emailEntity.path = path;
                        emailItemArray.push(emailEntity);
                    } else {
                        log.debug(`dropped msg file to upload`);
                        emailEntity = await this.getParsedEmailMetaData(file);
                        const emailType = this.emailTypeValidator(emailEntity);
                        log.debug(`email type is ${emailType}`);
                        if (emailType === EmailType.VALID) {
                            emailEntity.emailType = emailType;
                            emailEntity.path = path;
                            emailItemArray.push(emailEntity);
                        } else {
                            const invalidEmailEntity: EmailItem = {
                                emailSubject: emailEntity.emailSubject,
                                emailType: emailType,
                                path: path,
                            };
                            emailItemArray.push(invalidEmailEntity);
                        }
                    }
                }
            } catch (error) {
                log.error(JSON.stringify(error));
                emailItemArray.push({ emailSubject: Locale.email.default.subject_name });
            }
        }
        return emailItemArray;
    }

    private emailTypeValidator(emailEntity: EmailItem): EmailType {
        log.debug(`emailTypeValidator - email type: ${emailEntity.messageClass}`);
        // Notes
        if (emailEntity.messageClass === EmailMessageClass.NOTE) {
            return EmailType.NOT_VALID_EMAIL;
        }
        // Tasks
        if (emailEntity.messageClass === EmailMessageClass.TASK) {
            return EmailType.NOT_VALID_EMAIL;
        }
        // Email Contacts
        if (emailEntity.messageClass === EmailMessageClass.CONTACT) {
            return EmailType.NOT_VALID_EMAIL;
        }
        // Meeting Invite / Meeting Cancellation Email / Appoinment
        if (
            emailEntity.messageClass === EmailMessageClass.MEETING_REQUEST ||
            emailEntity.messageClass === EmailMessageClass.MEETING_CANCELLATION ||
            emailEntity.messageClass === EmailMessageClass.APPOINTMENT
        ) {
            return EmailType.NOT_VALID_EMAIL;
        }
        // Reporting deleivery status of IPM.NOTE (Email)
        if (emailEntity.messageClass === EmailMessageClass.REPORT) {
            return EmailType.NOT_VALID_EMAIL;
        }

        // Draft
        if (emailEntity.senderName === undefined && emailEntity.senderEmailAddress === undefined) {
            return EmailType.DRAFT_EMAIL;
        }
        // Draft
        if (emailEntity.messageFlags) {
            const unSentEmail = (emailEntity.messageFlags & 8) != 0;
            if (unSentEmail) {
                return EmailType.DRAFT_EMAIL;
            }
        }
        return EmailType.VALID;
    }

    private buildErrorMessage(error: EmailType): string | undefined {
        return Locale.email.failure[error?.toLowerCase()];
    }
}
