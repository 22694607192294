import { getLogger } from '@wk/elm-uui-common';
import { ChartType } from '../common/enums';
import { BarChartStrategy } from './barChart.strategy';
import { ChartStrategy } from './chartStrategy.interface';
import { LineChartStrategy } from './lineChart.strategy';
import { DashboardStrategy } from './dashboard.strategy';

const logger = getLogger('create-strategy-factory');

export const createStrategy = (chartType: ChartType): ChartStrategy => {
    switch (chartType) {
        case ChartType.Line:
            return new LineChartStrategy();
        case ChartType.Bar:
            return new BarChartStrategy();
        case ChartType.Dashboard:
            return new DashboardStrategy();
        default: {
            const errorMessage = `Strategy for "${String(chartType)}" is not implemented`;
            logger.error(errorMessage);
            throw new Error(errorMessage);
        }
    }
};
