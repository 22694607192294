import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { VFC, useState, useEffect } from 'react';
import { AppTheme } from '../../../../components/app/appTheme';
import { ResponseType, WarningActionResponse, GridComponent } from '../../../../store/slices';
import { ActionType } from '../../../common/types';
import WkWarningIcon from '../../../icons/wkWarningIcon';
import { useWarningDialog } from './hooks';

const useStyles = makeStyles<AppTheme>((theme) => {
    return {
        header: {
            backgroundColor: theme.palette.warning.dark,
            padding: theme.spacing(1.5, 2),
        },
        dialogTitle: {
            fontWeight: 500,
            color: theme.palette.text.secondary,
        },
        dialogMessage: {
            padding: theme.spacing(2, 1.5, 2, 2),
            display: 'inline-flex',
        },
        dialogContentText: {
            fontSize: theme.typography.subtitle1.fontSize,
            color: theme.palette.grey[900],
            whiteSpace: 'pre-line',
            marginBottom: 0,
        },
        dialogPaper: {
            border: `1px solid ${theme.palette.warning.dark}`,
        },
        dialogActions: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.secondary,
        },
        warningIcon: {
            fontSize: 28,
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            color: theme.palette.warning.dark,
        },

        dialogContents: {
            display: 'inline-flex',
        },

        containedButton: {
            marginLeft: theme.spacing(2),
        },
        dialogButton: {
            marginRight: '16px !important;',
            marginBottom: '16px !important;',
            lineHeight: '20px',
            boxShadow: 'none',
        },
    };
});

export type WarningDialogProps = {
    warningActionResponse: WarningActionResponse;
    components: GridComponent;
    gridValues: Record<string, Record<string, string>> | string;
};

export const WarningAlertDialog: VFC<WarningDialogProps> = (props: WarningDialogProps) => {
    const { warningActionResponse, components, gridValues } = props;
    const classes = useStyles();
    const [open, setDialogOpen] = useState(false);

    useEffect(() => {
        if (warningActionResponse.type == ResponseType.Warning) {
            setDialogOpen(true);
        }
    });

    const onClose = (): void => {
        warningActionResponse.type = ResponseType.None;
        setDialogOpen(false);
    };

    const { dialogAction } = useWarningDialog();

    const handleButtonClick = async (buttonAction: string, payload?: string): Promise<void> => {
        if (payload != null && payload.length > 0) {
            const result = await dialogAction(buttonAction, components, payload);
            if (result === ResponseType.Error) {
                onClose();
            }
        }
        else {
            await dialogAction(buttonAction, components, gridValues);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" classes={{ paper: classes.dialogPaper }}>
            <DialogTitle classes={{ root: classes.header }}>
                <Typography variant="h5" classes={{ root: classes.dialogTitle }}>
                    Warning
                </Typography>
            </DialogTitle>
            <div className={classes.dialogContents}>
                <WkWarningIcon classes={{ root: classes.warningIcon }} />
                <DialogContent classes={{ root: classes.dialogMessage }}>
                    <DialogContentText classes={{ root: classes.dialogContentText }}>
                        {warningActionResponse.message}
                    </DialogContentText>
                </DialogContent>
            </div>
            <div data-testid="warning-dialog">
                <DialogActions disableSpacing classes={{ root: classes.dialogActions }}>
                    {warningActionResponse &&
                        warningActionResponse.buttons &&
                        warningActionResponse.buttons.map((button, index) => {
                            return (
                                <Button
                                    disableRipple
                                    variant={button.style}
                                    key={index}
                                    color="primary"
                                    className={classes.dialogButton}
                                    onClick={
                                        button.actionType == ActionType.Client
                                            ? onClose
                                            : () => handleButtonClick(button.action, warningActionResponse.payload)
                                    }>
                                    {button.text}
                                </Button>
                            );
                        })}
                </DialogActions>
            </div>
        </Dialog>
    );
};
