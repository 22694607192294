import { ChevronRight } from '@mui/icons-material';
import { Breadcrumbs as BreadCrumbsComponent, Typography } from '@mui/material';
import MaterialUiLink from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import { BreadCrumb } from 'apps/client/src/reducers/types';
import { initial, last } from 'lodash';
import React, { VFC, useEffect } from 'react';
import { useUUIHistory } from '../../../hooks';
import { useQuery } from '../../../hooks/useQuery';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getAppResources, selectGlobalState, setBreadCrumbState } from '../../../store/slices';
import { AppTheme } from '../../app';

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        marginTop: '16px',
    },
    title: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.dark,
        fontSize: 12,
        fontWeight: 400,
        lineHeight: '16px',
        paddingRight: 8,
    },
    chevron: {
        color: theme.palette.grey[600],
        width: 8,
        height: 8,
    },
}));

export const BreadCrumbs: VFC = () => {
    const classes = useStyles();
    const history = useUUIHistory();
    const breadcrumbs = useAppSelector(selectGlobalState('breadcrumbs'));
    const { legacyContextRoot } = useAppSelector(getAppResources);
    const dispatch = useAppDispatch();
    const { screenId } = useQuery<{ screenId: number }>({
        parseNumbers: true,
    });

    const isLastBreadcrumb = (bc: BreadCrumb) => {
        const isLastCrumb = last(breadcrumbs);
        return bc === isLastCrumb;
    };

    const loadBreadCrumb = (currentBreadCrumbs: BreadCrumb[], breadCrumbToLoad: BreadCrumb): BreadCrumb[] => {
        return currentBreadCrumbs.slice(0, currentBreadCrumbs.findIndex((bc) => bc.url === breadCrumbToLoad.url) + 1);
    };

    const handleOnClick = (breadCrumb: BreadCrumb): void => {
        if (!isLastBreadcrumb(breadCrumb)) {
            const screenType = 'legacy';
            const base = `/enduser/${String(screenType)}screens/show.do`;
            const urlPath = breadCrumb.url.replace(legacyContextRoot, '');
            const encodedlegacyUrl = `${base}?screenId=${String(screenId)}&url=${encodeURIComponent(urlPath)}`;

            history.push(encodedlegacyUrl);

            const breadCrumbToLoad = loadBreadCrumb(breadcrumbs, breadCrumb);
            dispatch(setBreadCrumbState({ bc: breadCrumbToLoad }));
        }
    };

    const backButtonClickHandler = () => {
        const filteredList = initial(breadcrumbs);
        filteredList ? dispatch(setBreadCrumbState({ bc: filteredList })) : dispatch(setBreadCrumbState({ bc: [] }));
    };

    useEffect(() => {
        window.onpopstate = () => backButtonClickHandler();
    }, []);

    return (
        <BreadCrumbsComponent
            aria-label="breadcrumbs"
            aria-describedby="breadcrumbs navigation"
            separator={<ChevronRight className={classes.chevron} />}
            classes={{
                root: classes.root,
            }}
            role="navigation">
            {breadcrumbs?.map((breadCrumb, index) => {
                if (breadCrumb.title != null) {
                    return isLastBreadcrumb(breadCrumb) ? (
                        <Typography
                            component="span"
                            className={classes.title}
                            aria-label={breadCrumb.title}
                            data-testid="current-Crumb"
                            key={index}>{breadCrumb.title}</Typography>
                    ) : (
                        <MaterialUiLink
                            component="button"
                            className={classes.title}
                            onClick={() => handleOnClick(breadCrumb)}
                            key={index}
                            aria-label={breadCrumb.title}
                            data-testid="previous-Crumb">
                            {breadCrumb.title}
                        </MaterialUiLink>                        
                    );
                }
                return null;
            })}
        </BreadCrumbsComponent>
    );
};
