import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { forwardRef } from 'react';

export const WkShortcut = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon data-testid="wkAddIcon" viewBox="-2 -2 21 21" {...props} ref={ref}>
        <path
            d="M7.56946 16C6.51615 15.9479 5.52691 15.6996 4.60576 15.259C3.68461 14.8185 2.8836 14.2418 2.20275 13.5249C1.5219 12.812 0.985232 11.9789 0.588736 11.0297C0.196245 10.0806 0 9.0753 0 8.00596C0 6.93662 0.212265 5.85527 0.628786 4.88206C1.04931 3.90884 1.62203 3.05978 2.34293 2.33888C3.06383 1.61798 3.91289 1.04926 4.88611 0.624734C5.85932 0.204209 6.90063 -0.00805664 8.01001 -0.00805664C10.0005 -0.00805664 11.7267 0.620729 13.1965 1.8743C14.6663 3.12786 15.5675 4.70584 15.8999 6.6002H14.6783C14.4501 5.4788 13.9775 4.47755 13.2566 3.59645C12.5357 2.71535 11.6345 2.05452 10.5532 1.61397V1.97442C10.5532 2.44301 10.393 2.84751 10.0726 3.19595C9.75219 3.54438 9.3597 3.7166 8.89111 3.7166H7.14894V5.45878C7.14894 5.68706 7.05682 5.87129 6.8806 6.01948C6.70038 6.16766 6.49612 6.23975 6.27184 6.23975H4.60976V8.00196H5.47084V10.5051L1.38173 6.42799C1.31364 6.69632 1.26959 6.96065 1.24155 7.22098C1.21352 7.48131 1.2015 7.74564 1.2015 8.01397C1.2015 9.81622 1.81026 11.3702 3.02378 12.6798C4.2373 13.9894 5.75519 14.6943 7.56946 14.8025V16.004V16ZM15.8839 10.3969L12.4476 10.1006L11.1139 6.94063L9.78023 10.1006L6.34393 10.3969L8.94318 12.6518L8.16621 16.004L11.1099 14.2257L14.0536 16.004L13.2766 12.6518L15.8758 10.3969H15.8839Z"
            fill="currentColor"
        />
    </SvgIcon>
));

WkShortcut.displayName = 'WkShortcut';
