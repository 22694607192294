import { apiFetch } from '../../../../../utils/fetchUtils';
import { LayoutGridViewColumn } from '../common/types';

export const useGridColumnResize = () => {
    const gridColumnResize = async (
        dataSource: string,
        field: string,
        width: number,
        columns: LayoutGridViewColumn[],
    ): Promise<LayoutGridViewColumn[]> => {
        const { apiContextRoot, apiContextPath } = (window.Props || {}) as Record<string, string>;
        const url = `${apiContextRoot}${apiContextPath}/view/component/LayoutGridComponent/updateHomeGridPreference?dataSource=${dataSource}`;

        if (!columns.some((column) => column.key === field)) {
            return columns;
        }

        const updatedColumnWidth = columns.map((column) =>
            column.key === field ? { ...column, width } : column,
        ) as LayoutGridViewColumn[];

        const data = {
            gridColumnPreference: updatedColumnWidth?.map(({ key, width }) => ({ field: key, width })),
        };
        await apiFetch<void>(url, data, { skipTracking: true });
        return updatedColumnWidth;
    };

    return {
        gridColumnResize,
    };
};
