import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkLegalHolds = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkAddIcon" viewBox="0 0 25 25" {...props}>
        <defs>
            <clipPath id="wk-legal-holds-clipPath">
                <rect
                    id="Rectangle_3433"
                    data-name="Rectangle 3433"
                    width="24.502"
                    height="18.961"
                    transform="translate(0 0)"
                    fill="none"
                />
            </clipPath>
        </defs>
        <g id="Group_10969" data-name="Group 10969" transform="translate(-3219 -634)">
            <rect
                id="Rectangle_3447"
                data-name="Rectangle 3447"
                width="25"
                height="25"
                transform="translate(3219 634)"
                fill="none"
            />
            <g id="Group_9363" data-name="Group 9363" transform="translate(3219 637)">
                <g id="Group_9362" data-name="Group 9362" clipPath="url(#wk-legal-holds-clipPath)">
                    <rect
                        id="Rectangle_3430"
                        data-name="Rectangle 3430"
                        width="17.565"
                        height="13.488"
                        transform="translate(3.468 4.723)"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.5"
                    />
                    <rect
                        id="Rectangle_3431"
                        data-name="Rectangle 3431"
                        width="23.002"
                        height="3.973"
                        transform="translate(0.75 0.75)"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.5"
                    />
                    <rect
                        id="Rectangle_3432"
                        data-name="Rectangle 3432"
                        width="9.096"
                        height="3.45"
                        rx="1.614"
                        transform="translate(8.069 9.533)"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.5"
                    />
                </g>
            </g>
        </g>
    </SvgIcon>
);
