import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { VFC } from 'react';
import { AppTheme } from '../../../../components/app/appTheme';
import css from '../../../common/overlayDialog.module.scss';
import IconMapper from '../../../common/iconMapper';

const useStyles = makeStyles<AppTheme>(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    }
}));
export type messageViewProps = {
    heading: string;
    icon: string;
    message: string[];
    iconContainer: string;
};

export const MessageView: VFC<messageViewProps> = (props: messageViewProps) => {
    const { heading, icon, message, iconContainer } = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography variant="h4" className={css.heading}>
                {heading}
            </Typography>
            <div className={css.iconWrapper}>
                <IconMapper iconName={icon} customClass={iconContainer} />
            </div>
            {message?.map((msg, index) => (
                    <Typography key={index} variant="body1" className={css.messageBody}>
                        {msg}
                    </Typography>
                ))}
        </Box>
    );
};
