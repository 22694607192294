/* eslint-disable max-lines */
import { ChevronRight, ExpandMore, Search } from '@mui/icons-material';
import { TreeItem, TreeView } from '@mui/lab';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { clone, debounce, omit } from 'lodash';
import React, { Key, MouseEvent, ReactElement, useEffect, useState, VFC, SyntheticEvent, ChangeEvent } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useAppSelector } from '../../../../store';
import {
    GadgetCatalog,
    GadgetCategory,
    GadgetItem,
    LayoutComponent,
    LayoutGadgetComponent,
    selectPageState,
} from '../../../../store/slices';
import { config } from '../../../../target/t360/config';
import { AppTheme } from '../../../app';
import { BaseDialog, BaseDialogProps } from '../../../common';
import { WkClear, WkDragHandle, WkMinusCircle, WkPlusCircle } from '../../../icons';

// eslint-disable-next-line max-lines-per-function
const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        flex: 1,
        minHeight: 0,
        border: `1px solid ${theme.palette.grey[600]}`,
        boxSizing: 'border-box',
        padding: theme.spacing(1, 0),
        marginTop: theme.spacing(2),
        overflow: 'auto',
    },
    droppableOver: {
        backgroundColor: theme.palette.grey[200],
    },
    paper: {
        maxWidth: 800,
        width: 375,

        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
    },
    content: {
        boxSizing: 'content-box',
        padding: theme.spacing(0, 2),
        overflow: 'hidden',
        display: 'flex',

        '&>*': {
            minHeight: 0,
        },
    },
    actions: {
        flexDirection: 'row',
    },
    searchInput: {
        width: '100%',
        height: 32,
    },
    customizeContainer: {
        display: 'flex',
        minHeight: 0,
        flexDirection: 'column',
        gap: theme.spacing(1),

        '& label.Mui-focused': {
            color: theme.palette.secondary.main,
        },
        [theme.breakpoints.up('sm')]: {
            gap: 0,
            flexDirection: 'row',
            padding: theme.spacing(2, 0),
        },
    },
    divider: {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        margin: theme.spacing(0, 2),
        display: 'none',

        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    addSelectionContent: {
        flex: 1,
        minWidth: 0,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    selectionContent: {
        flex: 1,
        minWidth: 0,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
    },
    selectionContentHeader: {
        display: 'flex',
        flexDirection: 'row',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'column',
        },
    },
    gadgetSelectionContainer: {
        flex: 1,
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    gadgetSelectionList: {
        padding: theme.spacing(1, 0),
        height: '100%',
        overflowY: 'auto',
        boxSizing: 'border-box',
        border: `1px solid ${theme.palette.grey[600]}`,
    },
    gadgetSelectionListItem: {
        padding: theme.spacing(0.75, 1.25),

        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },

        '&:active, &.dragging': {
            backgroundColor: theme.palette.action.selected,
        },
    },
    gadgetSelectionIcon: {
        minWidth: 20,
    },
    gadgetClearIcon: {
        color: theme.palette.primary.main,
        margin: theme.spacing(-0.75, 0),

        '& svg': {
            fontSize: 13,
        },
    },
    gadgetSelectionText: {
        paddingLeft: theme.spacing(0.75),
        color: theme.palette.secondary.main,
        margin: 0,
    },
    gadgetSelectionTitle: {
        flex: 1,
        display: 'flex',
    },
    gadgetSelectionIndicator: {
        fontSize: theme.spacing(1.5),
        color: theme.palette.secondary.main,
        fontStyle: 'italic',
        height: 32,
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.up('sm')]: {
            alignItems: 'flex-end',
        },
    },
    treeItem: {
        color: theme.palette.primary.dark,
    },
    treeGroup: {
        marginLeft: theme.spacing(),
        paddingLeft: theme.spacing(1.75),
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
    },
    treeLabel: {
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-word',
        minHeight: theme.spacing(4),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    treeSelected: {
        '& .MuiTreeItem-label': {
            backgroundColor: `${theme.palette.action.hover}`,
        },
    },
    treeContent: {
        '&:hover': {
            backgroundColor: `transparent !important`,
        },

        '& .MuiTreeItem-label:hover': {
            backgroundColor: `${theme.palette.action.hover}`,
        },
    },
    accordion: {
        border: 'none',
        boxShadow: 'none',
        padding: 0,
        backgroundColor: 'transparent',
        '&:before': {
            height: 0,
        },
        '&.Mui-expanded': { margin: 0 },
    },
    accordionSummary: {
        flexDirection: 'row-reverse',
        padding: 0,
    },
    accordionExpandIcon: {
        padding: 0,
        '&.Mui-expanded': {
            transform: 'rotate(90deg)',
            padding: 0,
        },
        '& svg': {
            fontSize: 18,
        },
    },
    accordionContent: {
        paddingLeft: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 14,

        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },

        '&:active, &.dragging': {
            backgroundColor: theme.palette.action.selected,
        },
    },
    accordionContentText: {
        paddingTop: theme.spacing(1),
        color: theme.palette.secondary.main,
    },
    accordionDetails: {
        color: theme.palette.grey[600],
        padding: theme.spacing(0, 0, 0, 3.5),
        fontSize: 14,
    },
    gadgetRemoveIcon: {
        color: '#CB4450',
    },
}));

export type CustomizeGadgetsDialogProps = Omit<BaseDialogProps, 'title' | 'actions' | 'component' | 'onChange'> & {
    component: LayoutComponent;
    onChange: (layout: LayoutComponent, selected: SelectedGadget[]) => void;
    onUseDefault: (layout: LayoutComponent) => Promise<void>;
};

export type SelectedGadget = Pick<
    LayoutGadgetComponent,
    'code' | 'title' | 'height' | 'isMinimized' | 'layoutColumnId'
>;
export interface DroppableItem extends Pick<LayoutGadgetComponent, 'code' | 'title' | 'height' | 'isMinimized'> {
    droppableId: DroppableColumn;
}

enum DroppableColumn {
    Selection1 = 'gadgetSelectionContainer1',
}

const DEFAULT_GADGET_HEIGHT = 300;

// eslint-disable-next-line max-lines-per-function
export const CustomizeGadgetsDialog: VFC<CustomizeGadgetsDialogProps> = (props) => {
    const classes = useStyles();
    const {
        classes: externalClasses = {},
        component,
        onClose,
        onChange,
        onUseDefault,
        open,
        ...baseDialogProps
    } = props;
    const { gadgetCatalog, defaultGadgets } = useAppSelector(selectPageState('data')) ?? {};
    const [selectedGadgets, setSelectedGadgets] = useState(new Map<DroppableColumn, DroppableItem[]>());
    const columnIds = component.columns.map(({ id }) => id);
    const defaultGadgetsEnabled = Boolean(defaultGadgets?.length);
    const [expandedAccordion, setExpandedAccordion] = useState<string | boolean>(false);
    const [filteredCategories, setFilteredCategories] = useState<GadgetCatalog>();
    const [expanded, setExpanded] = useState<string[]>([]);

    useEffect(() => {
        const gadgets = component.components.map((item, index) => ({
            ...item,
            droppableId: DroppableColumn.Selection1,
            order: index,
        }));
        setSelectedGadgets(clone(selectedGadgets).set(DroppableColumn.Selection1, gadgets));
        setFilteredCategories(clone(gadgetCatalog));
        setExpanded([]);
        setExpandedAccordion(false);
        setFilterStateValue([]);
    }, [component.components, open]);

    const handleAddSelection = (
        { code, title }: GadgetItem,
        event: MouseEvent<HTMLButtonElement>,
        droppableId = DroppableColumn.Selection1,
    ): void => {
        event.stopPropagation();
        setSelectedGadgets((previousSelections) => {
            const selectedGadgets = clone(previousSelections);
            const gadgets = selectedGadgets.get(DroppableColumn.Selection1) || [];
            selectedGadgets.set(DroppableColumn.Selection1, [
                ...gadgets,
                {
                    code,
                    title,
                    height: DEFAULT_GADGET_HEIGHT,
                    isMinimized: false,
                    droppableId,
                },
            ]);

            return selectedGadgets;
        });
    };

    const handleRemoveSelection = (code: string, event: MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        setSelectedGadgets((previousSelections) => {
            const selectedGadgets = clone(previousSelections);
            selectedGadgets.forEach((gadgets, droppableId) => {
                selectedGadgets.set(
                    droppableId,
                    gadgets.filter((gadget) => gadget.code !== code),
                );
            });

            return selectedGadgets;
        });
    };

    const handleCancel = (event: MouseEvent<HTMLButtonElement>): void => {
        onClose?.(event, 'onCancel');
    };

    const handleUseDefault = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
        await onUseDefault?.(component);
        if (defaultGadgetsEnabled) {
            selectedGadgets.clear();
            (defaultGadgets as GadgetItem[]).forEach((gadget) => handleAddSelection(gadget, event));
        }
    };

    const handleDone = (): void => {
        const selected: SelectedGadget[] =
            selectedGadgets?.get(DroppableColumn.Selection1)?.map((item, index) => {
                return {
                    ...omit(item, 'droppableId'),
                    order: Math.floor(index / columnIds.length),
                    layoutColumnId: columnIds[index % columnIds.length],
                };
            }) || [];
        onChange(component, selected);
    };

    const onDragEnd = (result: DropResult): void => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        setSelectedGadgets((previousSelections) => {
            const selectedGadgets = clone(previousSelections);
            const sourceColumn = selectedGadgets.get(source.droppableId as DroppableColumn);
            const targetColumn = selectedGadgets.get(destination?.droppableId as DroppableColumn);
            const [dragged] = sourceColumn?.splice(source.index, 1) || ([] as DroppableItem[]);
            targetColumn?.splice(destination?.index || 0, 0, dragged);

            return selectedGadgets;
        });
    };

    const getSelectedId = (item: SelectedGadget): string => `selected-${item.code}`;

    const renderCategories = (): JSX.Element[] | undefined =>
        filteredCategories?.categories?.map(({ name, code, gadgets }) => (
            <TreeItem
                data-testid="customize-gadget-categories"
                key={code}
                classes={{
                    root: classes.treeItem,
                    group: classes.treeGroup,
                    label: classes.treeLabel,
                    selected: classes.treeSelected,
                    content: classes.treeContent,
                }}
                nodeId={code}
                collapseIcon={<ExpandMore />}
                expandIcon={<ChevronRight />}
                label={highlightText(name, filterStateValue)}
                TransitionProps={{ unmountOnExit: false }}>
                {gadgets.map((gadget, index) => renderGadgetCatalogItem(gadget, index))}
            </TreeItem>
        ));

    const handleAccordionChange = (code: string) => (_e: SyntheticEvent, isExpanded: boolean) => {
        setExpandedAccordion(isExpanded ? code : false);
    };

    const escapeRegExp = (searchText: string): string => {
        return searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    const highlightText = (text: string, keywords: string[]) => {
        if (keywords.length === 0) {
            return <span>{text}</span>;
        }
        const sortByLengthDescending = (a: string, b: string) => b.length - a.length;
        const sortedKeywords = keywords.sort(sortByLengthDescending);
        const escapedKeywords = sortedKeywords.map(escapeRegExp);
        const regex = new RegExp(`(${escapedKeywords.join('|')})`, 'gi');

        const parts = text.split(regex);
        return (
            <span>
                {parts.map((part: string, index: number) =>
                    regex.test(part) ? (
                        <span key={index} style={{ background: 'yellow' }}>
                            {part}
                        </span>
                    ) : (
                        <span key={index}>{part}</span>
                    ),
                )}
            </span>
        );
    };

    const renderGadgetCatalogItem = (gadget: GadgetItem, key: Key): JSX.Element => {
        const { code, title, description } = gadget;
        const isSelected = [...selectedGadgets.values()].find((gadgets) =>
            gadgets.some(({ code: selectedGadgetCode }) => selectedGadgetCode === code),
        );
        return (
            <Accordion
                classes={{ root: classes.accordion }}
                key={key}
                data-testid="customize-gadget-items"
                expanded={expandedAccordion === true || expandedAccordion === code}
                onChange={handleAccordionChange(code)}>
                <AccordionSummary
                    classes={{
                        root: classes.accordionSummary,
                        expandIconWrapper: classes.accordionExpandIcon,
                        content: classes.accordionContent,
                    }}
                    expandIcon={<ChevronRight />}>
                    <Box className={classes.accordionContentText}>{highlightText(title, filterStateValue)}</Box>
                    {isSelected ? (
                        <IconButton
                            classes={{ root: classes.gadgetRemoveIcon }}
                            data-testid={`customize-remove-gadget-icon-${title}`}
                            onClick={(event): void => handleRemoveSelection(code, event)}
                            size="large">
                            <WkMinusCircle />
                        </IconButton>
                    ) : (
                        <IconButton
                            data-testid={`customize-add-gadget-icon-${title}`}
                            onClick={(event): void => handleAddSelection(gadget, event)}
                            size="large">
                            <WkPlusCircle />
                        </IconButton>
                    )}
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.accordionDetails }}>
                    {highlightText(description, filterStateValue)}
                </AccordionDetails>
            </Accordion>
        );
    };

    const renderSelectedGadgets = (): JSX.Element => (
        <Box className={classes.gadgetSelectionContainer}>
            {Object.values(DroppableColumn).map((droppableId) => (
                <Droppable droppableId={droppableId} key={droppableId}>
                    {(droppableProvided, droppableState): JSX.Element => (
                        <List
                            data-testid="customize-selected-gadget-list"
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}
                            classes={{
                                root: classNames(classes.gadgetSelectionList, {
                                    [classes.droppableOver]: droppableState.isDraggingOver,
                                }),
                            }}>
                            {selectedGadgets.get(droppableId)?.map((gadget, index) => (
                                <Draggable key={gadget.code} draggableId={getSelectedId(gadget)} index={index}>
                                    {(draggableProvided, draggableState): JSX.Element => (
                                        <ListItem
                                            data-testid="customize-selected-gadget-list-item"
                                            {...draggableProvided.draggableProps}
                                            {...draggableProvided.dragHandleProps}
                                            ref={draggableProvided.innerRef}
                                            classes={{
                                                root: classNames(classes.gadgetSelectionListItem, {
                                                    [classes.dragging]: draggableState.isDragging,
                                                }),
                                            }}
                                            style={draggableProvided.draggableProps.style}
                                            key={gadget.code}>
                                            <ListItemIcon classes={{ root: classes.gadgetSelectionIcon }}>
                                                <WkDragHandle />
                                            </ListItemIcon>
                                            <ListItemText
                                                className={classes.gadgetSelectionText}
                                                primary={gadget.title}
                                            />
                                            <IconButton
                                                classes={{ root: classes.gadgetClearIcon }}
                                                onClick={(event): void => handleRemoveSelection(gadget.code, event)}
                                                size="large">
                                                <WkClear />
                                            </IconButton>
                                        </ListItem>
                                    )}
                                </Draggable>
                            ))}
                            {droppableProvided.placeholder}
                        </List>
                    )}
                </Droppable>
            ))}
        </Box>
    );

    const selectedCount = [...selectedGadgets.values()].reduce((agg, item) => agg + item.length, 0);
    const filterGadget = clone(gadgetCatalog);

    const filteredGadgets = (filterValues: string[]): GadgetCatalog | undefined => {
        if (filterGadget?.categories) {
            const filterWithSearch: GadgetCatalog = {
                ...filterGadget,
                categories: filterGadget?.categories
                    .map((category: GadgetCategory) => ({
                        ...category,
                        gadgets: category.gadgets.filter((gadget: GadgetItem) =>
                            filterValues.some(
                                (keyword) =>
                                    gadget.title.toLowerCase().includes(keyword.toLowerCase()) ||
                                    gadget.description.toLowerCase().includes(keyword.toLowerCase()),
                            ),
                        ),
                    }))
                    .filter((category: GadgetCategory) => category.gadgets.length > 0),
            };
            return filterWithSearch;
        } else {
            return gadgetCatalog;
        }
    };

    const [filterStateValue, setFilterStateValue] = useState<string[]>([]);

    const handleInputChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.trim();
        if (inputValue && inputValue.length > 1) {
            const filterValues = inputValue.split(' ').filter((keyword) => keyword.trim().length > 1);
            setFilterStateValue(filterValues);
            if (filterValues.length > 0) {
                setExpandedAccordion(true);
                setFilteredCategories(filteredGadgets(filterValues));
                filteredCategories &&
                    filteredCategories?.categories.forEach((category: GadgetCategory) => {
                        setExpanded((expanded) => [...expanded, category.code]);
                    });
            }
        } else {
            setExpandedAccordion(false);
            setExpanded([]);
            setFilterStateValue([]);
            setFilteredCategories(gadgetCatalog);
        }
    }, 600);

    const handleToggle = (_e: SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    const renderTreePanel = (): JSX.Element => (
        <Box className={classes.addSelectionContent}>
            <InputLabel>Add</InputLabel>
            <OutlinedInput
                placeholder="Search"
                id="component-customize"
                inputProps={{ 'data-testid': 'component-customize-test' }}
                autoFocus
                className={classes.searchInput}
                endAdornment={
                    <InputAdornment position="end">
                        <Search />
                    </InputAdornment>
                }
                onChange={handleInputChange}
                type="search"
            />
            <TreeView
                classes={{
                    ...externalClasses,
                    root: classNames(classes.root, externalClasses.root),
                }}
                disableSelection={true}
                multiSelect={false}
                expanded={expanded}
                onNodeToggle={handleToggle}>
                {renderCategories()}
            </TreeView>
        </Box>
    );

    const renderSelectionPanel = (): ReactElement => (
        <Box className={classes.selectionContent}>
            <Box className={classes.selectionContentHeader}>
                <Box className={classes.gadgetSelectionTitle}>
                    <InputLabel>Your Selections</InputLabel>
                </Box>
                <Box className={classes.gadgetSelectionIndicator}>
                    <span>{`${selectedCount} gadget${selectedCount !== 1 ? 's' : ''} selected`}</span>
                </Box>
            </Box>
            {renderSelectedGadgets()}
        </Box>
    );

    return (
        <BaseDialog
            {...baseDialogProps}
            onClose={onClose}
            open={open}
            classes={{
                ...externalClasses,
                actions: classNames(classes.actions, externalClasses.actions),
                content: classNames(classes.content, externalClasses.content),
                paper: classNames(classes.paper, externalClasses.paper),
            }}
            title="Customize"
            hideCancel={true}
            actions={
                <>
                    <Button
                        variant="outlined"
                        color="primary"
                        data-testid="customize-gadget-use-default-button"
                        disabled={!defaultGadgetsEnabled}
                        onClick={handleUseDefault}
                        disableRipple>
                        Use Default
                    </Button>
                    <Button variant="outlined" color="primary" onClick={handleCancel} disableRipple>
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" onClick={handleDone} disableRipple>
                        Done
                    </Button>
                </>
            }>
            {gadgetCatalog && (
                <FormControl variant="outlined" data-testid="customize-gadget-form">
                    <DragDropContext nonce={config.get('REACT_APP_CSP_NONCE')} onDragEnd={onDragEnd}>
                        <Box className={classes.customizeContainer}>
                            {renderTreePanel()}
                            <div className={classes.divider} />
                            {renderSelectionPanel()}
                        </Box>
                    </DragDropContext>
                </FormControl>
            )}
        </BaseDialog>
    );
};
