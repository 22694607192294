import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUuiHistoryScreen } from '../../reducers/types';

export type HistoryScreenState = IUuiHistoryScreen;

const initialState: HistoryScreenState = {
    isHistoryDialogOpen: false,
    isFailedUploadFlyoutOpen: false,
    batchUploadFlyoutCount: '',
    isNewUploadCountUpdated: false,
    isUploadingFlyoutOpen: false,
};

const historyScreen = createSlice({
    name: 'history-screen',
    initialState,
    reducers: {
        openHistoryScreenDialog: (state) => {
            state.isHistoryDialogOpen = true;
        },
        closeHistoryScreenDialog: (state) => {
            state.isHistoryDialogOpen = false;
        },
        setFailedUploadFlyoutOpen: (state, action: PayloadAction<{ isOpen: boolean }>) => {
            state.isFailedUploadFlyoutOpen = action.payload.isOpen;
        },
        setBatchUploadFlyoutCount: (state, action: PayloadAction<{ count: string }>) => {
            state.batchUploadFlyoutCount = action.payload.count;
        },
        setNewUploadCountUpdated: (state, action: PayloadAction<{ isUpdated: boolean }>) => {
            state.isNewUploadCountUpdated = action.payload.isUpdated;
        },
        setIsUploadingFlyoutOpen: (state, action: PayloadAction<{ isOpen: boolean }>) => {
            state.isUploadingFlyoutOpen = action.payload.isOpen;
        },
    },
});

// Actions & Reducer
export type HistoryScreenActions = TakeActions<typeof historyScreen.actions>;
export const {
    openHistoryScreenDialog,
    closeHistoryScreenDialog,
    setFailedUploadFlyoutOpen,
    setBatchUploadFlyoutCount,
    setNewUploadCountUpdated,
    setIsUploadingFlyoutOpen,
} = historyScreen.actions;
export const historyScreenReducer = historyScreen.reducer;
