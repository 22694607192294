import { manuallyDecrementPromiseCounter, manuallyIncrementPromiseCounter } from 'react-promise-tracker';
import { apiFetch } from '../../../../utils/fetchUtils';
import { ITransferBasketItem } from '../../../transferBasket/types';
import { getColumnHeaderDisplayName } from '../../listScreenHelpers';
import { IListScreenMetadata } from '../../types';
import { IColumnPickerState, ListScreenEditDialogAction, ListScreenEditDialogDispatch, IRawTreeData } from '../types';
import { convertRawTreeDataToTransferBasketItems } from './listScreenEditDialogReducer';

export const columnPickerReducer = (
    state: IColumnPickerState,
    action: ListScreenEditDialogAction,
): IColumnPickerState => {
    switch (action.type) {
        case 'SaveColumnPickerItems': {
            return { ...state, transferBasketItems: action.transferBasketItems };
        }
        default: {
            return state;
        }
    }
};

// column picker async actions and helpers
export const dispatchInitializeColumnPicker = (
    dispatch: ListScreenEditDialogDispatch,
    screenMetadata: IListScreenMetadata,
    isMounted: React.MutableRefObject<boolean>,
    childAttributesURL: string,
): void => {
    const queryStringParams = {
        type: 'columns',
        screenId: screenMetadata.screenId,
        context: screenMetadata.context || '',
        ajax: true,
        root: screenMetadata.entityId,
        isRoot: true,
        chainIDs: '',
    };
    manuallyIncrementPromiseCounter();
    apiFetch<IRawTreeData[]>(
        childAttributesURL
            .replace('{screenId}', queryStringParams.screenId.toString())
            .replace('{context}', queryStringParams.context)
            .replace('{entityMetadataId}', queryStringParams.root.toString())
            .replace('{type}', queryStringParams.type)
            .replace('{isRoot}', queryStringParams.isRoot.toString())
            .replace('{chainIDs}', queryStringParams.chainIDs),
        undefined,
        { skipTracking: true },
    ).then((rawTreeData) => {
        const items = convertRawTreeDataToTransferBasketItems(rawTreeData);
        // now inspect the column metadata collect info about the columns
        // now find the backing attributes and mark them as selected
        const itemsToAdd = [] as ITransferBasketItem[];
        const itemUnselectable = (itemValue: string) => (screenMetadata.isDocumentEntity ? itemValue !== 'Name' : true);

        let listOrderIndex = 0;
        screenMetadata.columns.map((column) => {
            const columnId = column.attributeIds.join(',');
            const itemToSelect = items.find((item) => item.id === columnId);
            if (column.iconName.length > 0) {
                // in this case, it's an icon, so create a new transferBasketItem for it and mark it hidden.
                itemsToAdd.push({
                    id: columnId,
                    availableDisplayValue: 'icon',
                    selectedDisplayValue: 'icon',
                    disabled: false,
                    isSelected: true,
                    isHidden: true,
                    hideFromAvailable: true,
                    isItemUnselectable: itemUnselectable(column.displayName),
                    metadata: { screenFieldId: column.screenFieldId },
                } as ITransferBasketItem);
            } else if (itemToSelect) {
                // we found the backing attribute, just mark it selected
                itemToSelect.isSelected = true;
                itemToSelect.selectedDisplayValue = getColumnHeaderDisplayName(column);
                itemToSelect.listOrder = listOrderIndex++;
                itemToSelect.isItemUnselectable = itemUnselectable(itemToSelect.selectedDisplayValue);
                itemToSelect.metadata = { ...itemToSelect.metadata, screenFieldId: column.screenFieldId };
            } else {
                // in this case, the screen field backs a nested attribute, so we just create a new transferBasketItem for it.
                itemsToAdd.push({
                    id: columnId,
                    availableDisplayValue: getColumnHeaderDisplayName(column),
                    selectedDisplayValue: getColumnHeaderDisplayName(column),
                    disabled: false,
                    isSelected: true,
                    listOrder: listOrderIndex++,
                    hideFromAvailable: true,
                    isItemUnselectable: itemUnselectable(column.displayName),
                    metadata: { screenFieldId: column.screenFieldId },
                } as ITransferBasketItem);
            }
        });
        if (isMounted.current) {
            manuallyDecrementPromiseCounter();
            dispatch({ type: 'SaveColumnPickerItems', transferBasketItems: items.concat(itemsToAdd) });
        }
    });
};

export const dispatchToggleColumnPickerTreeNode = (
    treeItemClicked: ITransferBasketItem,
    expanded: boolean,
    state: IColumnPickerState,
    screenMetadata: IListScreenMetadata,
    dispatch: ListScreenEditDialogDispatch,
    isMounted: React.MutableRefObject<boolean>,
    childAttributesURL: string,
): void => {
    // the api requires a comma delimited list of all entity ids in the attribute path.
    const calculateChainIds = () => {
        let currentTreeItem = treeItemClicked;
        const chainIds = [treeItemClicked.metadata!.relatedEntityId];
        while (currentTreeItem.parentId) {
            currentTreeItem = state.transferBasketItems.find((td) => td.id === currentTreeItem.parentId)!;
            chainIds.unshift(currentTreeItem.metadata!.relatedEntityId);
        }
        return chainIds.join(',');
    };

    // this walks up the tree and crafts the display value for when an item is selected.
    const calculateSelectedDisplayValue = (item: ITransferBasketItem) => {
        const displayValueParts = [item.selectedDisplayValue];
        let currentItem = item;
        currentItem.parentId = treeItemClicked.id;
        while (currentItem.parentId) {
            currentItem = state.transferBasketItems.find((i) => i.id === currentItem.parentId)!;
            displayValueParts.unshift(currentItem.availableDisplayValue);
        }
        return displayValueParts.join(' > ');
    };

    // only do the ajax call if we haven't already expanded this node
    if (expanded && state.transferBasketItems.filter((td) => td.parentId === treeItemClicked.id).length == 0) {
        const queryStringParams = {
            type: 'columns',
            screenId: screenMetadata.screenId,
            context: screenMetadata.context || '',
            root: treeItemClicked.metadata!.relatedEntityId,
            isRoot: false,
            chainIDs: calculateChainIds(),
        };
        apiFetch<IRawTreeData[]>(
            childAttributesURL
                .replace('{screenId}', queryStringParams.screenId.toString())
                .replace('{context}', queryStringParams.context)
                .replace('{entityMetadataId}', queryStringParams.root!.toString())
                .replace('{type}', queryStringParams.type)
                .replace('{isRoot}', queryStringParams.isRoot.toString())
                .replace('{chainIDs}', queryStringParams.chainIDs.toString()),
        ).then((rawChildTreeData) => {
            const childTransferBasketItems = convertRawTreeDataToTransferBasketItems(
                rawChildTreeData,
                treeItemClicked.id,
            );

            // find the duplicate screen fields (they are duplicates now because they backed a nested attribute which is now included in the ajax response)
            // we originally created a transfer basket item for them when the column picker was loaded
            const duplicateScreenFields = state.transferBasketItems.filter(
                (item) => childTransferBasketItems.find((c) => c.id === item.id) != null,
            );

            // now remove those duplicates from the transfer basket item array
            const filteredTransferBasketItems = state.transferBasketItems.filter(
                (item) => duplicateScreenFields.indexOf(item) < 0,
            );

            // now append the new nested attributes onto the filtered transferbasketitems array
            const updatedTransferBasketItems = filteredTransferBasketItems
                // add the children and re-add the screen field backed attribute
                .concat(
                    childTransferBasketItems.map((ctd) => {
                        // see if this attribute had a matching screen field that was filtered out above
                        const matchingScreenField = duplicateScreenFields.find((dup) => dup.id === ctd.id);
                        const itemHadMatchingScreenField = matchingScreenField != null;
                        return {
                            ...ctd,
                            parentId: treeItemClicked.id,
                            isSelected: itemHadMatchingScreenField && matchingScreenField.isSelected,
                            selectedDisplayValue: itemHadMatchingScreenField
                                ? matchingScreenField.selectedDisplayValue
                                : calculateSelectedDisplayValue(ctd),
                            listOrder: itemHadMatchingScreenField ? matchingScreenField.listOrder : 0,
                            metadata: itemHadMatchingScreenField
                                ? { ...ctd.metadata, ...matchingScreenField.metadata }
                                : ctd.metadata,
                        } as ITransferBasketItem;
                    }),
                );
            if (isMounted.current) {
                dispatch({ type: 'SaveColumnPickerItems', transferBasketItems: updatedTransferBasketItems });
            }
        });
    }
};

export const calculateColumnData = (state: IColumnPickerState, originalColumnData: string): string => {
    // if there are no transfer basket items, then the column picker tab was never clicked so just use original column data
    if (state.transferBasketItems.length == 0) {
        return originalColumnData;
    }
    // if only hidden columns are selected(or nothing is selected), revert back to original state
    if (state.transferBasketItems.filter((item) => item.isSelected && !item.isHidden).length == 0) {
        return originalColumnData;
    }
    // if only icon columns are selected, revert back to original state.
    const columnData = state.transferBasketItems
        .filter((item) => item.isSelected)
        .slice()
        .sort((a, b) => {
            // push icon objects to the front, otherwise sort by listOrder value
            return !a.listOrder ? -1 : a.listOrder - b.listOrder;
        })
        .map((item) => {
            if (item.metadata && item.metadata.screenFieldId) {
                return `id:${item.metadata.screenFieldId}`;
            } else {
                // the transfer basket item id IS the full comma delimited list of embedded attribute ids.
                return item.id;
            }
        });
    return columnData.join('|');
};
