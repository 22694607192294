/* eslint-disable max-lines-per-function */
import { List, ListItem, ListItemText } from '@mui/material';
import Divider from '@mui/material/Divider';
import { ICHUploadResponse } from '@wk/elm-uui-context-handler';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import useCancellablePromise from '../../hooks/useCancellablePromise';
import { useAppSelector } from '../../store';
import { getAppResources } from '../../store/slices';
import FileDragAndDrop from '../common/fileDragAndDrop';
import { handleContextLayerPerformFileDragAndDrop } from '../contextLayerService/contextLayerHelpers';
import { isOC } from '../contextLayerService/contextLayerService';
import { useListScreenDispatch, useListScreenState } from './context/listScreenContext';
import { useRefreshList } from './context/listScreenHooks';
import { ListScreenBreadCrumbs } from './listScreenBreadCrumbs';
import { generatePagePostObject } from './listScreenHelpers';
import ListScreenPager from './listScreenPager';
import { ListScreenSearchAndSavedViewBar } from './listScreenSearchAndSavedViewBar';
import css from './listView.module.scss';
import ListViewHeader from './listViewHeader';
import { parseSortData, stringifySortData } from './listViewHelpers';
import ListViewItem from './listViewItem';
import { IListScreenColumn, ISortEntry } from './types';

export interface IListViewProps {
    stickyTopPosition?: number;
}

const ListView: React.FC<IListViewProps> = ({ stickyTopPosition }) => {
    const { listscreenMessageNodata: noMatchingResultsText } = useAppSelector(getAppResources);
    const [totalStickyHeight, setTotalStickyHeight] = useState(stickyTopPosition ? stickyTopPosition : 0);
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const refreshList = useRefreshList();
    const pagerRef = useRef<HTMLLIElement>(null);
    const cancellablePromise = useCancellablePromise<ICHUploadResponse | ICHUploadResponse[]>();
    const appResources = useAppSelector(getAppResources);

    const metadata = listScreenState.metadata!;
    const listData = listScreenState.listData!;
    const mode = listScreenState.mode;

    const calcStickyHeight = useCallback(() => {
        const currStickyHeight = stickyTopPosition ? stickyTopPosition : 0;
        let pagerHeight = 0;
        if (pagerRef.current) {
            pagerHeight = pagerRef.current.getBoundingClientRect().height;
        }
        setTotalStickyHeight(Math.floor(currStickyHeight + pagerHeight));
    }, [stickyTopPosition]);

    useLayoutEffect(() => {
        calcStickyHeight();
    });

    const nonIconColumns = useMemo(() => metadata.columns.filter((c) => c.iconName === ''), [metadata.columns]);

    const allRowIds = listData.list.map((row) => row.id);
    let allChecked = true;
    let someChecked = false;
    if (allRowIds.length === 0) {
        allChecked = false;
    } else {
        allRowIds.map((rowId) => {
            if (!listScreenState.checkedRows.find((id) => id === rowId)) {
                allChecked = false;
            } else {
                someChecked = true;
            }
        });
    }

    const toggleCheckForListItem = useCallback(
        (id: number) => {
            listScreenDispatch({ type: 'ToggleCheckedRow', id });
        },
        [listScreenDispatch],
    );

    const dtkSorts = useMemo(() => metadata.columns.filter((c) => c.sorted), [metadata]);

    const sortList = useCallback(
        (sortColumn: IListScreenColumn) => {
            let sortEntries = parseSortData(listData.page.sortInfo);
            const flipSortDirection = (currentSortDirection: string): string => {
                return currentSortDirection === 'ASCENDING' ? 'DESCENDING' : 'ASCENDING';
            };

            if (sortEntries[0] && sortEntries[0].path === sortColumn.path) {
                // if the sortColumn selected is already the first in the list
                sortEntries[0].direction = flipSortDirection(sortEntries[0].direction);
            } else {
                // make it the first in the list by removing it then adding to the head
                const filtered = sortEntries
                    .filter((elem) => elem.path != sortColumn.path) // take out the clicked column
                    .filter((elem) => dtkSorts.map((e) => e.path).indexOf(elem.path) >= 0); // only keep dtk columns

                const dtkSortColumn = dtkSorts.find((e) => e.path == sortColumn.path);
                const sortOrder = dtkSortColumn ? dtkSortColumn.sortOrder : 'ASCENDING';

                filtered.unshift({
                    path: sortColumn.path,
                    direction: sortOrder,
                } as ISortEntry);

                sortEntries = filtered;
            }
            const fetchPostData = generatePagePostObject(listData.page, {
                pageNumber: 1,
                sortInfo: stringifySortData(sortEntries),
            });

            refreshList({ postObject: fetchPostData });
        },
        [listData.page, refreshList, dtkSorts],
    );

    const toggleCheckAll = useCallback(() => {
        if (allChecked || someChecked) {
            listScreenDispatch({ type: 'ToggleCheckedRowsOff', ids: allRowIds });
        } else {
            listScreenDispatch({ type: 'ToggleCheckedRowsOn', ids: allRowIds });
        }
    }, [listScreenDispatch, allRowIds, allChecked, someChecked]);

    const stickyStyles = stickyTopPosition ? { top: stickyTopPosition + 'px' } : undefined;

    const getCheckboxType = () => {
        if (allChecked) {
            return 'checked';
        }
        if (someChecked) {
            return 'minus';
        }
        return 'unchecked';
    };

    const actionFileDropHandler = (filePaths: []) => {
        if (listScreenState.breadCrumbs?.length > 0) {
            const parentInfo = listScreenState.breadCrumbs[0].parentItemInfo;
            if (parentInfo) {
                handleContextLayerPerformFileDragAndDrop({
                    associatedEntityType: parentInfo.parentEntityName,
                    associatedEntityId: parentInfo.parentInstanceId,
                    associatedEntityName: parentInfo.parentInstanceDisplayName,
                    cancellablePromise,
                    entityTypeId: undefined, // Cl decides where the dragged item goes. we do not need to pass this value
                    folderId:
                        listScreenState.breadCrumbs?.length > 1
                            ? listScreenState.breadCrumbs[listScreenState.breadCrumbs.length - 1].folderId
                            : undefined,
                    folderArr: listScreenState.breadCrumbs.map((bc) => bc.folderName),
                    loggedInUser: appResources.username,
                    associatedEntityTypeId: parentInfo.parentEntityId,
                    filePaths,
                });
            }
        }
    };

    const isDropZoneEnabled = () => {
        return (
            (isOC() &&
                mode === 'show' &&
                listScreenState.isEmbeddedList &&
                listScreenState.metadata?.enableFileDropzone &&
                listScreenState.metadata?.isDocumentEntity &&
                listScreenState.metadata?.entityName !== 'DocumentVersion') ||
            false
        );
    };

    return (
        <List className={css.listViewRoot}>
            <li
                ref={pagerRef}
                className={listScreenState.isEmbeddedList ? css.pagerSticky : css.mainListPagerSticky}
                style={stickyStyles}>
                <FileDragAndDrop
                    actionFileDropHandler={actionFileDropHandler}
                    isFileDropZoneEnabled={isDropZoneEnabled()}
                    isThreeLinesLabel={true}
                    areaLabel={
                        listScreenState.breadCrumbs && listScreenState.breadCrumbs.length > 0
                            ? listScreenState.breadCrumbs[listScreenState.breadCrumbs.length - 1].folderName
                            : ''
                    }>
                    {listScreenState.isEmbeddedList && <ListScreenSearchAndSavedViewBar />}
                    <ListScreenBreadCrumbs />
                    <ListScreenPager />
                </FileDragAndDrop>
            </li>
            <ListViewHeader
                checkboxType={getCheckboxType()}
                toggleCheckAll={toggleCheckAll}
                nonIconColumns={nonIconColumns}
                checkAllDisabled={listData.list.length == 0}
                sortDisabled={listData.list.length == 0}
                leftColumnWidth="50%"
                onClick={sortList}
                pageData={listData.page}
                isEmbeddedList={listScreenState.isEmbeddedList}
                stickyTopPosition={totalStickyHeight}
            />
            <li className={css.listViewItems} data-testid={`listViewItems-${metadata.screenId}`}>
                {listData.list.length == 0 ? (
                    <React.Fragment>
                        <ListItem className={css.noResults} component="div">
                            <ListItemText primary={noMatchingResultsText} />
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ) : (
                    listData.list.map((currentRow) => (
                        <ListViewItem
                            key={currentRow.id}
                            isChecked={listScreenState.checkedRows.indexOf(currentRow.id) != -1}
                            onCheck={toggleCheckForListItem}
                            row={currentRow}
                            columns={nonIconColumns}
                            mode={mode}
                            leftColumnWidth="50%"
                        />
                    ))
                )}
            </li>
        </List>
    );
};

export default ListView;
