import { Collapse, Divider, MenuItem, MenuItemProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { omit } from 'lodash';
import React, { ForwardedRef, forwardRef, memo, ReactElement } from 'react';
import { AppTheme } from '../../app';

export interface MenuItemState<T> {
    id: string | number;
    displayValue: string;
    expanded?: boolean;
    selected?: boolean;
    isDivider?: boolean;
    children?: T[];
}

const useStyles = makeStyles<AppTheme, { level: number }>((theme) => ({
    subHolder: {
        paddingLeft: (props) => {
            return parseInt(theme.spacing(2)) * props.level;
        },
    },
    selected: {
        fontWeight: 600,
        backgroundColor: `${theme.palette.action.hover} !important`,
        '&:hover': {
            backgroundColor: `${theme.palette.action.hover} !important`,
        },
    },
    divider: {
        '&:last-child': {
            display: 'none',
        },
    },
}));

interface MultiLevelMenuItemProps<T> extends Omit<MenuItemProps, 'selected' | 'divider'> {
    classes?: MenuItemProps['classes'] & {
        group?: string;
        collapse?: string;
    };
    getState: (item: T) => MenuItemState<T>;
    item: T;
    handleMenuSelection: (item: T) => void;
    level?: number;
    renderListItem: (item: T, state: MenuItemState<T>) => ReactElement;
    ['data-testid']?: string;
}

function MultiLevelMenuItemComponent<T>(props: MultiLevelMenuItemProps<T>, ref: ForwardedRef<HTMLLIElement>) {
    const {
        item,
        handleMenuSelection,
        classes: externalClasses = {},
        getState,
        level = 1,
        renderListItem,
        ['data-testid']: dataTestId,
        ...menuItemProps
    } = props;
    const classes = useStyles({ level });
    const state = getState(item);
    const { expanded, selected, isDivider, children, id } = state;

    return (
        <>
            <MenuItem
                {...menuItemProps}
                ref={ref}
                // button
                classes={{
                    ...omit(externalClasses, 'collapse'),
                    selected: classNames(classes.selected, externalClasses.selected),
                    root: classNames(
                        classes.subHolder,
                        externalClasses.root,
                        (children?.length || '') && externalClasses.group,
                    ),
                }}
                tabIndex={0}
                onClick={() => handleMenuSelection(item)}
                selected={selected}
                data-testid={dataTestId}>
                {renderListItem(item, state)}
            </MenuItem>
            {Boolean(children?.length) && (
                <Collapse in={expanded} timeout="auto" unmountOnExit classes={{ root: externalClasses.collapse }}>
                    {children?.map((child: typeof item, index: number) => {
                        return (
                            <MultiLevelMenuItem
                                {...menuItemProps}
                                key={index}
                                getState={getState}
                                handleMenuSelection={handleMenuSelection}
                                item={child}
                                level={level + 1}
                                classes={externalClasses}
                                renderListItem={renderListItem}
                                data-testid={`${id}-submenu-item`}
                            />
                        );
                    })}
                </Collapse>
            )}
            {isDivider && <Divider classes={{ root: classes.divider }} />}
        </>
    );
}

export const MultiLevelMenuItem = memo(forwardRef(MultiLevelMenuItemComponent)) as typeof MultiLevelMenuItemComponent;
