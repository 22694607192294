import { ChevronRight, ExpandMore, FiberManualRecord } from '@mui/icons-material';
import { SingleSelectTreeViewProps, TreeItem, TreeView } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useState, VFC, MouseEvent } from 'react';
import { useAppSelector } from '../../../store';
import { FavoriteEntity, FavoriteType, selectFavoritesTree } from '../../../store/slices';
import { AppTheme } from '../../app';
import { WkFolderOpen, WkShortcut } from '../../icons';

const useStyles = makeStyles<AppTheme>((theme) => {
    const icon = {
        marginRight: theme.spacing(0.5),
        width: 21,
        marginLeft: theme.spacing(0.25),
        color: theme.palette.primary.dark,
    };

    return {
        root: {
            gap: 5,
            overflow: 'auto',
        },
        group: {
            marginLeft: theme.spacing(),
            paddingLeft: theme.spacing(1.75),
            borderLeft: `1px solid ${theme.palette.grey[300]}`,
        },
        item: {
            color: theme.palette.primary.dark,
        },
        label: {
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            wordBreak: 'break-word',
            minHeight: theme.spacing(4),
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            color: theme.palette.text.primary,
        },
        blockSelection: {
            userSelect: 'none',
        },
        icon,
        iconFolder: {
            ...icon,
            marginLeft: theme.spacing(0.25),
        },
        iconFavorite: {
            ...icon,
            fontSize: theme.spacing(0.75),
            color: theme.palette.common.black,
        },
        selected: {
            '& .MuiTreeItem-label': {
                // todo: double check if font should be thicker
                fontWeight: 500,
                backgroundColor: `${theme.palette.action.hover}`,
            },
        },
        content: {
            '&, &:hover, &$selected': {
                backgroundColor: `transparent !important`,
            },

            '& .MuiTreeItem-label:hover': {
                backgroundColor: `${theme.palette.action.hover}`,
            },
        },
    };
});

export type FavoritesTreeProps = Omit<
    SingleSelectTreeViewProps,
    'defaultParentIcon' | 'multiSelect' | 'onNodeSelect' | 'onNodeToggle' | 'children'
> & {
    foldersOnly?: boolean;
    onNodeSelect?: (id: string) => void;
    onNodeToggle?: (ids: string[]) => void;
};

export const FavoritesView: VFC<FavoritesTreeProps> = (props) => {
    const {
        classes: externalClasses = {},
        foldersOnly,
        onNodeSelect,
        expanded: externalExpanded,
        selected,
        onNodeToggle,
        ...treeViewProps
    } = props;
    const classes = useStyles();
    const tree = useAppSelector(selectFavoritesTree({ type: (foldersOnly || undefined) && FavoriteType.Folder }));
    const [internalExpanded, setInternalExpanded] = useState<string[]>([]);
    const expanded = externalExpanded || internalExpanded;

    const handleNodeSelect = (_: unknown, id: string): void => {
        if (!tree) {
            return;
        }

        onNodeSelect?.(id);
    };

    const toggleExpanded = (event: MouseEvent, nodeId: string) => {
        const index = expanded.indexOf(nodeId);
        const updatedItems = index !== -1 ? expanded.filter((id) => id !== nodeId) : [...expanded, nodeId];

        event.stopPropagation();
        setInternalExpanded(updatedItems);
        onNodeToggle?.(updatedItems);
    };

    const clearTextSelection = () => {
        window.getSelection?.()?.removeAllRanges();
    };

    const renderTree = (favorites?: FavoriteEntity[]) =>
        favorites?.map((favorite) => {
            const { type, id, name } = favorite;
            const renderIcon = () => {
                if (type === FavoriteType.Folder) {
                    return <WkFolderOpen className={classes.iconFolder} />;
                }
                if (type === FavoriteType.Shortcut) {
                    return <WkShortcut classes={{ root: classes.icon }} />;
                }
                return <FiberManualRecord className={classes.iconFavorite} />;
            };

            return (
                <TreeItem
                    key={String(id)}
                    classes={{
                        root: classes.item,
                        group: classes.group,
                        label: classes.label,
                        selected: classes.selected,
                        content: classes.content,
                    }}
                    nodeId={String(id)}
                    collapseIcon={<ExpandMore onClick={(event) => toggleExpanded(event, String(id))} />}
                    expandIcon={<ChevronRight onClick={(event) => toggleExpanded(event, String(id))} />}
                    label={
                        <>
                            {renderIcon()}
                            {name}
                        </>
                    }
                    onDoubleClick={(event) => {
                        toggleExpanded(event, String(id));
                        clearTextSelection();
                    }}>
                    {type === FavoriteType.Folder && renderTree(favorite.children)}
                </TreeItem>
            );
        });

    return tree ? (
        <TreeView
            {...treeViewProps}
            classes={{ ...externalClasses, root: classNames(classes.root, externalClasses.root) }}
            expanded={expanded}
            multiSelect={false}
            selected={selected}
            onNodeSelect={handleNodeSelect}>
            {renderTree([tree])}
        </TreeView>
    ) : null;
};
