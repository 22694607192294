import { Button, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { VFC, MouseEvent } from 'react';
import { useNavigate } from '../../../../hooks';
import { IMenuItem } from '../../../../reducers/types';
import { LayoutComponentButton } from '../../../../store/slices';
import { AppTheme } from '../../../app/appTheme';
import { Tooltip } from '../../../common';
import { TextEllipsis } from '../../../common/textEllipsis';
import { TargetScreenType } from '../../../common/types';
import { WkClear, WkExcel, WkInfo, WkZoomInMagnifyGlass, WkZoomOutMagnifyGlass } from '../../../icons';

const useStyles = makeStyles<AppTheme>((theme) => ({
    header: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        marginTop: -parseInt(theme.spacing(1)),
        marginBottom: -parseInt(theme.spacing(1)),
        justifyContent: 'space-between',
    },
    left: {
        display: 'flex',
        alignItems: 'center',
    },
    right: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 10,
    },
    title: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.fontSize + 2,
        fontWeight: theme.typography.fontWeightBold,
        cursor: 'pointer',
    },
    link: {
        marginLeft: theme.spacing(2),
        fontSize: 14,
        marginTop: 3,
        whiteSpace: 'nowrap',
        textDecoration: 'none',
    },
    info: {
        marginLeft: theme.spacing(),
        color: theme.palette.primary.dark,
    },
    button: {
        padding: theme.spacing(0.75),
        color: theme.palette.primary.dark,
        minWidth: 'auto',

        '&:last-of-type': {
            marginRight: -parseInt(theme.spacing(0.5)),
        },
    },
    tooltip: {
        marginBottom: theme.spacing(1),
    },
}));

export type ViewHeaderProps = {
    title: string;
    description: string;
    buttons?: LayoutComponentButton[];
    isMinimized: boolean;
    onCollapse: (event: MouseEvent<HTMLButtonElement>) => void;
    onExpand: (event: MouseEvent<HTMLButtonElement>) => void;
    onClose: (event: MouseEvent<HTMLButtonElement>) => void;
    onDownloadExcel?: () => void;
};

export const ViewHeader: VFC<ViewHeaderProps> = (props) => {
    const { title, description, buttons, isMinimized, onExpand, onCollapse, onClose, onDownloadExcel } = props;

    const { navigate } = useNavigate();
    const classes = useStyles();

    return (
        <div className={classes.header} print-key="printKey">
            <TextEllipsis text={title} classes={{ content: classes.title }} />

            <div className={classes.actions}>
                <div className={classes.left}>
                    <Tooltip
                        title={description}
                        placement="top"
                        classes={{ tooltipPlacementTop: classes.buttonTooltip }}>
                        <WkInfo classes={{ root: classes.info }} />
                    </Tooltip>

                    {buttons?.map((button) => (
                        <Link
                            key={button.name}
                            classes={{ root: classes.link }}
                            component="button"
                            onClick={(): void => {
                                navigate({
                                    // TODO: set proper id base on menu items match
                                    menuItem: {
                                        screenType: TargetScreenType.Legacy,
                                        url: button.url,
                                        id: 0,
                                    } as IMenuItem,
                                });
                            }}>
                            {button.name}
                        </Link>
                    ))}
                </div>

                <div className={classes.right}>
                    {onDownloadExcel && (
                        <Button
                            onClick={onDownloadExcel}
                            disableRipple
                            data-testid="gadget-export-to-excel-button"
                            classes={{
                                root: classes.button,
                            }}>
                            <Tooltip
                                title="Excel"
                                placement="top"
                                classes={{ tooltipPlacementTop: classes.buttonTooltip }}>
                                <WkExcel />
                            </Tooltip>
                        </Button>
                    )}

                    <Button
                        onClick={isMinimized ? onExpand : onCollapse}
                        disableRipple
                        data-testid="gadget-collapse-button"
                        classes={{
                            root: classes.button,
                        }}>
                        <Tooltip
                            title={isMinimized ? 'Expand' : 'Collapse'}
                            placement="top"
                            classes={{ tooltipPlacementTop: classes.tooltip }}>
                            {isMinimized ? <WkZoomInMagnifyGlass /> : <WkZoomOutMagnifyGlass />}
                        </Tooltip>
                    </Button>

                    <Button
                        onClick={onClose}
                        disableRipple
                        data-testid="gadget-close-button"
                        classes={{
                            root: classes.button,
                        }}>
                        <Tooltip title="Close" placement="top" classes={{ tooltipPlacementTop: classes.tooltip }}>
                            <WkClear viewBox="-3 -3 19.5 19.5" />
                        </Tooltip>
                    </Button>
                </div>
            </div>
        </div>
    );
};
