import { makeStyles } from '@mui/styles';
import React, { VFC, useState, useMemo, useEffect } from 'react';
import { useAppSelector } from '../../store';
import { FavoriteType, selectFavoritesTree } from '../../store/slices';
import { AppTheme } from '../app';
import { BaseDialogCloseReason } from '../common';
import { PageHeader } from '../common/pageHeader/pageHeader';
import { PageFooter } from '../core/pageFooter';
import { CreateFavoriteFolderDialog, MoveFavoriteDialog, RemoveFavoriteDialog, RenameFavoriteDialog } from './dialogs';
import { FavoritesActionsMenu, OpenDialogType } from './favoritesActions';
import { getFavoriteOrFolder } from './favoritesTree.utils';
import { FavoritesView } from './favoritesView';

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        ...theme.styles.page.root,
    },
    header: {
        ...theme.styles.page.header,
    },
    favoritesView: {
        ...theme.styles.page.content,
        flex: 1,
        flexBasis: 0,
        width: '100%',
        flexDirection: 'column',
    },
}));

export const Favorites: VFC = () => {
    const classes = useStyles();
    const [entityId, setEntityId] = useState<string>('');
    const [openDialog, setOpenDialog] = useState<OpenDialogType>();
    const tree = useAppSelector(selectFavoritesTree());
    const entity = useMemo(() => getFavoriteOrFolder(tree, entityId), [tree, entityId]);
    const [expanded, setExpanded] = useState<string[]>();

    useEffect(() => {
        if (tree && !entityId) {
            setEntityId(String(tree.id));
        }
    }, [tree, entityId]);

    useEffect(() => {
        if (tree?.type === FavoriteType.Folder && !expanded?.length) {
            setExpanded([String(tree.id)]);
        }
    }, [tree, expanded]);

    const onClose = () => {
        setOpenDialog(undefined);
    };

    const onRemoveClose = (reason: BaseDialogCloseReason) => {
        setOpenDialog(undefined);

        if (reason === 'onAction') {
            setEntityId('');
        }
    };

    const onNodeToggle = (ids: string[]): void => {
        setExpanded(ids);
    };

    return (
        <div className={classes.root}>
            <PageHeader classes={{ root: classes.header }} title="Edit Favorites">
                <FavoritesActionsMenu item={entity} onAction={(type) => setOpenDialog(type)} />
            </PageHeader>
            <FavoritesView
                classes={{ root: classes.favoritesView }}
                selected={entityId}
                onNodeSelect={setEntityId}
                expanded={expanded}
                onNodeToggle={onNodeToggle}
            />
            {entity && (
                <>
                    {openDialog === OpenDialogType.CreateFolder && entity.type === FavoriteType.Folder && (
                        <CreateFavoriteFolderDialog open={true} parentEntityId={entity.id} onClose={onClose} />
                    )}
                    {openDialog === OpenDialogType.Remove && (
                        <RemoveFavoriteDialog
                            open={true}
                            entityId={entity.id}
                            onClose={(_, reason) => onRemoveClose(reason)}
                        />
                    )}
                    {openDialog === OpenDialogType.Move && (
                        <MoveFavoriteDialog open={true} entityId={entity.id} onClose={onClose} />
                    )}
                    {openDialog === OpenDialogType.Rename && (
                        <RenameFavoriteDialog open={true} entityId={entity.id} onClose={onClose} />
                    )}
                </>
            )}
            <PageFooter />
        </div>
    );
};
