import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Paper, Typography } from '@mui/material';
import Popper from '@mui/material/Popper';
import { IQueueItem, QueueItemStatus } from '@wk/elm-uui-context-handler';
import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { getAppResources, setNewUploadCountUpdated } from '../../store/slices';
import {
    closeFailedUploadFlyout,
    updateBatchUploadFlyoutCount,
    updateUploadingFlyoutState,
} from '../../utils/activitiesService';
import WkErrorIcon from '../icons/wkErrorIcon';
import css from './progressIndicatorFlyout.module.scss';

interface IPopper {
    anchorEl: any;
}

// eslint-disable-next-line max-lines-per-function
const ProgressIndicatorFlyout: React.FC<IPopper> = ({ anchorEl }) => {
    const [arrowRef, setArrowRef] = React.useState(null);
    const appResources = useAppSelector(getAppResources);
    const uploadHistoryItems = useAppSelector((state) => state.ui.uploadHistory);
    const isFailedUploadFlyoutOpen = useAppSelector((state) => state.ui.historyScreen.isFailedUploadFlyoutOpen);
    const isUploadingFlyoutOpen = useAppSelector((state) => state.ui.historyScreen.isUploadingFlyoutOpen);
    const dispatch = useAppDispatch();

    const failedItemsCount = uploadHistoryItems.filter(
        (item: IQueueItem) => item.status === QueueItemStatus.FAILED,
    ).length;
    const itemsUploadingCount = useAppSelector((state) => state.ui.historyScreen.batchUploadFlyoutCount);

    const isNewUploadCountUpdated = useAppSelector((state) => state.ui.historyScreen.isNewUploadCountUpdated);

    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        if (itemsUploadingCount === '' || !isNewUploadCountUpdated) {
            return;
        }
        dispatch(setNewUploadCountUpdated({ isUpdated: false }));
        if (timer.current) {
            clearTimeout(timer.current);
        }

        if (parseInt(itemsUploadingCount) > 0) {
            updateUploadingFlyoutState(true, dispatch);
            timer.current = setTimeout(() => {
                updateUploadingFlyoutState(false, dispatch);
                updateBatchUploadFlyoutCount('', dispatch);
            }, 5000);
        }
    }, [itemsUploadingCount, isNewUploadCountUpdated, timer, dispatch]);

    const handleArrowRef = (node: any) => {
        setArrowRef(node);
    };

    const handleClose = () => {
        closeFailedUploadFlyout(dispatch);
    };

    return (
        anchorEl && (
            <Popper
                placement="bottom-end"
                open={isFailedUploadFlyoutOpen || isUploadingFlyoutOpen}
                anchorEl={anchorEl}
                className={css.popper}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: true,
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            boundariesElement: 'scrollParent',
                        },
                    },
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef,
                        },
                    },
                ]}
                data-testid="message-popper">
                <span className={css.arrow} ref={handleArrowRef} />
                <Paper className={css.paper}>
                    {isFailedUploadFlyoutOpen && failedItemsCount > 0 && (
                        <div
                            className={
                                isFailedUploadFlyoutOpen && isUploadingFlyoutOpen
                                    ? css.errorMessageContainer
                                    : undefined
                            }
                            data-testid="error-message-popper">
                            <div className={css.errorMessage}>
                                <WkErrorIcon fontSize="small" className={css.errorIcon} />
                                <Typography className={css.message} data-testid="failed-upload-popper">
                                    {failedItemsCount > 1
                                        ? appResources.historyItemFailedMessage.replace(
                                              '{items}',
                                              failedItemsCount.toString(),
                                          )
                                        : appResources.historyItemFailedMessage
                                              .replace('{items}', failedItemsCount.toString())
                                              .replace('items', 'item')}
                                </Typography>
                                <IconButton
                                    size="small"
                                    className={css.closeIconButton}
                                    onClick={() => handleClose()}
                                    data-testid="flyout-close-button"
                                    aria-label="Close Flyout">
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                    )}
                    {isUploadingFlyoutOpen ? (
                        <Typography
                            className={
                                isFailedUploadFlyoutOpen && isUploadingFlyoutOpen
                                    ? css.uploadMessageWithError
                                    : css.uploadingMessage
                            }
                            data-testid="upload-message-popper">
                            {parseInt(itemsUploadingCount) > 1
                                ? appResources.historyItemProgressIndicatorUploading.replace(
                                      '{0}',
                                      itemsUploadingCount.toString(),
                                  )
                                : appResources.historyItemProgressIndicatorUploading
                                      .replace('{0}', itemsUploadingCount.toString())
                                      .replace('items', 'item')}
                        </Typography>
                    ) : null}
                </Paper>
            </Popper>
        )
    );
};

export default ProgressIndicatorFlyout;
