import { FormControl, FormLabel, Typography } from '@mui/material';
import React from 'react';
import css from './formTextControl.module.scss';
import { UUITooltip } from './uuiTooltip';

export interface IFormTextControl {
    title: string;
    content: string;
}

export const FormTextControl: React.FC<IFormTextControl> = ({ title, content }) => {
    return (
        <FormControl className={css.formRoot}>
            <FormLabel className={css.title}>{title}</FormLabel>
            <UUITooltip title={content} enterDelay={500} placement="top-start">
                <Typography noWrap className={css.content}>
                    {content}
                </Typography>
            </UUITooltip>
        </FormControl>
    );
};
