import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppStore } from '../../reducers/types';
import { AppState } from '../types';

export type AppAdditionalState = Partial<Omit<AppStore, 'appsMenu' | 'ui'>> &
    Pick<AppStore, 'appResources' | 'applicationUrls' | 'hybridSettings'>;

const initialState: AppAdditionalState = {
    appResources: {},
    applicationUrls: {},
    hybridSettings: {
        // TODO: Need to set the default state.
    } as AppStore['hybridSettings'],
};

const app = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        setAppState: (_, action: PayloadAction<{ state: AppAdditionalState }>) => action.payload.state,
    },
});

// TODO: rename to selectThing https://redux.js.org/style-guide/#name-selector-functions-as-selectthing
// Selectors
const appSelector = (state: AppState): AppAdditionalState => state.app;
export const getAppResources = createSelector(appSelector, (app) => app.appResources);
export const getApplicationUrls = createSelector(appSelector, (app) => app.applicationUrls);

// Actions &  Reducer
export type ApplicationActions = TakeActions<typeof app.actions>;
export const { setAppState } = app.actions;
export const appReducer = app.reducer;
