import {
    ComponentType,
    PageHeaderLinks,
    ViewScreenPageStyle,
    ContentHolder,
} from '../../../../../store/slices';
import { apiFetch } from '../../../../../utils/fetchUtils';

type ViewScreenComponent =
    | ViewScreenLayoutComponent
    | {
        type: Exclude<ComponentType, ComponentType.LayoutComponent>;
    };

interface ViewScreenLayoutComponent {
    type: ComponentType.LayoutComponent;
    columns: ViewScreenLayoutComponentColumn[];
    catalogName: string;
    contentHolder: ContentHolder;
}

interface ViewScreenLayoutComponentColumn {
    width: number;
    components: ViewScreenComponent[];
}

interface ViewScreenData {
    components: ViewScreenComponent[];
    pageHeaderLinks: PageHeaderLinks[];
    pageStyle?: ViewScreenPageStyle;
}

const getTreeData = async (viewName: any, entityId: any) => {
    const { apiContextRoot, apiContextPath } = window.Props;
    const apiBaseUrl = `${String(apiContextRoot)}${String(apiContextPath)}/`;
    const viewParams = window.location.search;
    const viewUrl = `${apiBaseUrl}view/${viewName}/${viewParams}&entityId=${entityId}`;
    const gridScreenData = await apiFetch<ViewScreenData>(viewUrl);
    return gridScreenData;

};

export default getTreeData;