import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkDataExchange = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkAddIcon" viewBox="0 0 24 24" {...props}>
        <g>
            <path
                id="Path_8563"
                className="st0"
                d="M1.9,2.7h7.7l1.7,1.7h10.9v16.9H1.9V2.7z M0,0.9v22.3h24V2.6H12l-1.7-1.7H0z"
            />
            <path
                id="Path_8564"
                className="st0"
                d="M10.8,15.3v1.3h6.4l-1.5,1.5l0.9,0.9l3-3l0.1-0.1l-3.1-3.1l-0.9,0.9l1.5,1.5L10.8,15.3z"
            />
            <path
                id="Path_8565"
                className="st0"
                d="M7.3,13.2l0.9-0.9l-1.5-1.5h6.4V9.5H6.7L8.1,8L8.2,8L7.3,7.1l-3,3l-0.1,0.1L7.3,13.2L7.3,13.2z
		"
            />
        </g>
    </SvgIcon>
);
