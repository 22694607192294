export enum ChartStatus {
    Idle,
    Initialized,
}

export enum ChartType {
    Bar = 'barChart',
    Line = 'lineChart',
    Dashboard = 'dashboard',
}

export enum ValueFetchType {
    UserCurrencyId = 'UserCurrencyId',
    PreferredInvoiceStatusIds = 'PreferredInvoiceStatusIds',
    PreferredInvoiceDateField = 'PreferredInvoiceDateField',
    PreferredTimeZone = 'PreferredTimeZone',
}
