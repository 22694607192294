import { Menu as MuiMenu, MenuProps as MuiMenuProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { AppTheme } from '../../../app';

const useStyles = makeStyles<AppTheme>(() => ({
    paper: {
        paddingRight: '0px',
    },
}));

type MenuProps = Omit<MuiMenuProps, 'classes' | 'anchorOrigin' | 'transformOrigin' | 'getContentAnchorEl'>;

export const Menu: FC<MenuProps> = (props) => {
    const classes = useStyles();

    return (
        <MuiMenu
            {...props}
            classes={{
                paper: classes.paper,
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        />
    );
};
Menu.displayName = 'Menu';
