import { useMediaQuery, useTheme } from '@mui/material';
import { Breakpoint } from '@mui/material/styles';
import { isFunction } from 'lodash';
import { DependencyList, useMemo } from 'react';

interface UseMediaQueryExecuteParams<T, TValue = T | (() => T)> extends Partial<Record<Breakpoint, TValue>> {
    default: TValue;
    xs?: TValue;
    sm?: TValue;
    md?: TValue;
    lg?: TValue;
    xl?: TValue;
}

export function useMediaQueryExecute<T = unknown>(
    queries: UseMediaQueryExecuteParams<T>,
    deps: DependencyList = [],
): T {
    const theme = useTheme();
    const mediaQueryCheck = new Map<Breakpoint, boolean>([
        ['xs', useMediaQuery(theme.breakpoints.up('xs'))],
        ['sm', useMediaQuery(theme.breakpoints.up('sm'))],
        ['md', useMediaQuery(theme.breakpoints.up('md'))],
        ['lg', useMediaQuery(theme.breakpoints.up('lg'))],
        ['xl', useMediaQuery(theme.breakpoints.up('xl'))],
    ]);

    return useMemo(() => {
        const getQueryValue = (query: T | (() => T)): { value: T } => {
            const value = isFunction(query) ? query() : query;
            return { value };
        };

        const getValue = (key: keyof UseMediaQueryExecuteParams<T>): { value: T } | undefined => {
            const query = queries[key];

            if (!query) {
                return;
            }

            if (key === 'default') {
                return getQueryValue(query);
            }

            if (query && mediaQueryCheck.get(key)) {
                return getQueryValue(query);
            }

            return;
        };

        const { value } =
            getValue('xl') || //
            getValue('lg') ||
            getValue('md') ||
            getValue('sm') ||
            getValue('xs') ||
            (getValue('default') as { value: T });

        return value;
    }, [...deps, ...mediaQueryCheck.values()]);
}
