import CloseIcon from '@mui/icons-material/Close';
import { IconButton, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CHMessagingScope } from '@wk/elm-uui-context-handler';
import classNames from 'classnames';
import { SnackbarOrigin, SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { NotificationPlacement } from '../../../reducers/types';
import { useAppDispatch, useAppSelector } from '../../../store';
import { dismissNotification, removeNotification } from '../../../store/slices';
import { AppTheme, StyleProps } from '../../app';
import { messageBusDispatch } from '../../contextLayerService/messageBusService';
import { ToastMessage } from '../toastMessage';

const useStyles = makeStyles<AppTheme>((theme) => {
    const snackbarBase: StyleProps = {
        backgroundColor: `${theme.palette.common.white} !important`,
        color: '#353535 !important',
        borderRadius: '0 !important',
        padding: '20px 20px 15px 20px !important',
        wordBreak: 'break-word',
        border: '1px solid',
        borderLeft: '4px solid',
        '& > div': {
            padding: '0px !important',
        },
    };

    return {
        containerRoot: {
            marginTop: 126, // TODO: maintain 32px between pinned fav bar and a toast!
        },
        snackbarSuccess: {
            ...snackbarBase,
            borderColor: '#648d18',
        },
        snackbarInfo: {
            ...snackbarBase,
            borderColor: theme.palette.primary.main,
        },
        snackbarWarning: {
            ...snackbarBase,
            borderColor: theme.palette.warning.dark,
        },
        snackbarError: {
            ...snackbarBase,
            borderColor: '#e5202e',
        },

        messageMobile: {
            width: '93vw',
        },
        message: {
            width: 300,
        },
        closeToastButton: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1.25),
        },
        closeToastIcon: {
            fontSize: 18,
            color: '#757575',
            position: 'absolute',
            cursor: 'pointer',
        },
    };
});

let displayed: string[] = [];

export const Notifier: React.FC = () => {
    const classes = useStyles();

    return (
        <SnackbarProvider
            classes={{
                variantSuccess: classes.snackbarSuccess,
                variantInfo: classes.snackbarInfo,
                variantWarning: classes.snackbarWarning,
                variantError: classes.snackbarError,
                containerRoot: classes.containerRoot,
            }}
            dense
            hideIconVariant
            maxSnack={8}
            disableWindowBlurListener={true}>
            <NotifierComponent />
        </SnackbarProvider>
    );
};

const NotifierComponent: React.FC = () => {
    const classes = useStyles();
    const notifications = useAppSelector((state) => state.ui.notifications);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();
    const isMobile = !useMediaQuery<AppTheme>((theme) => theme.breakpoints.up('sm'));

    const storeDisplayed = (id: string) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id: string) => {
        displayed = [...displayed.filter((key) => id !== key)];
    };

    useEffect(() => {
        const invokeDismissToast = (key: string) => {
            messageBusDispatch({
                type: 'DismissToast',
                scope: CHMessagingScope.OtherInstances,
                message: key,
            });
            dispatch(dismissNotification({ key }));
        };
        const invokeRemoveToast = (key: string) => {
            messageBusDispatch({
                type: 'RemoveToast',
                scope: CHMessagingScope.OtherInstances,
                message: key,
            });
            dispatch(removeNotification({ key }));
        };
        notifications.forEach((notification) => {
            const { key, message, dismissed = false, options, placement } = notification;

            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }
            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) {
                return;
            }

            const defaultPosition: SnackbarOrigin = { horizontal: 'center', vertical: 'bottom' };
            const anchorOrigin: SnackbarOrigin =
                (placement === NotificationPlacement.TopLeft && { horizontal: 'left', vertical: 'top' }) ||
                (placement === NotificationPlacement.TopRight && { horizontal: 'right', vertical: 'top' }) ||
                (placement === NotificationPlacement.Top && { horizontal: 'center', vertical: 'top' }) ||
                (placement === NotificationPlacement.BottomLeft && { horizontal: 'left', vertical: 'bottom' }) ||
                (placement === NotificationPlacement.BottomRight && { horizontal: 'right', vertical: 'bottom' }) ||
                (placement === NotificationPlacement.Bottom && { horizontal: 'center', vertical: 'bottom' }) ||
                (!isMobile && { horizontal: 'center', vertical: 'top' }) ||
                defaultPosition;
            // display snackbar using notistack
            enqueueSnackbar(
                <ToastMessage
                    type={options.variant}
                    message={message}
                    classes={{
                        root: classNames({
                            [classes.messageMobile]: isMobile,
                            [classes.message]: !isMobile,
                        }),
                    }}
                />,
                {
                    key,
                    ...options,
                    // eslint-disable-next-line react/display-name
                    action: (key) => {
                        return (
                            <IconButton
                                className={classes.closeToastButton}
                                aria-label="close"
                                data-testid="closeIcon"
                                onClick={() => {
                                    invokeDismissToast(key as string);
                                }}
                                size="large">
                                <CloseIcon aria-label="close" className={classes.closeToastIcon} />
                            </IconButton>
                        );
                    },
                    onClose: (event, reason, myKey) => {
                        if (options.onClose) {
                            options.onClose(event, reason, myKey);
                        }
                    },
                    onExited: (_, key: string | number) => {
                        // remove this snackbar from redux store
                        dispatch(removeNotification({ key: String(key) }));
                        invokeRemoveToast(String(key));
                        removeDisplayed(String(key));
                    },
                    anchorOrigin,
                },
            );

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [closeSnackbar, enqueueSnackbar, notifications, dispatch]);

    return null;
};
