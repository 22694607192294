import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IItemScreenProps } from '../../components/itemScreen/itemScreen';

type ItemScreen = IItemScreenProps;
export type ItemScreenDialogsState = ItemScreen[];

const initialState: ItemScreenDialogsState = [];

const itemScreenDialogs = createSlice({
    name: 'item-screen-dialog',
    initialState,
    reducers: {
        openItemScreenDialog: (state, action: PayloadAction<{ itemScreen: ItemScreen }>) => {
            state.push(action.payload.itemScreen);
        },
        removeLastItemScreenDialog: (state) => {
            state.pop();
        },
    },
});

// Actions
export type ItemScreenDialogsActions = TakeActions<typeof itemScreenDialogs.actions>;
export const { openItemScreenDialog, removeLastItemScreenDialog } = itemScreenDialogs.actions;
export const itemScreenDialogsReducer = itemScreenDialogs.reducer;
