import { isArray, isNil, toPairs } from 'lodash';
import { useEffect, useState } from 'react';
import { FacetMenuComponent } from '../../../../../store/slices';
import { apiFetch } from '../../../../../utils/fetchUtils';
import { TargetScreenType } from '../../../../common/types';

type UseViewScreenProps = {
    component: FacetMenuComponent;
    selectedCode?: string;
};

export interface FacetMenuComponentSource {
    dataSource: string;
    items: FacetMenuComponentSourceViewData;
    isCollapsed: boolean;
    topPaneHeight: number;
}

export interface FacetMenuComponentSourceViewData {
    primaryFacets: FacetMenuComponentSourceFacetsViewData[];
    secondaryFacets: FacetMenuComponentSourceFacetsViewData[];
}

export interface FacetMenuComponentSourceFacetsViewData {
    code: string;
    name: string;
    title: string;
    url: string;
    isSelected: boolean;
    screenType: TargetScreenType;
}

export type UseFacetMenuView = {
    data: FacetMenuComponentSourceViewData | undefined;
    isCollapsed: boolean;
    toggleCollapse: () => Promise<void>;
};

type BaseSourceUrlProps = {
    dataSource: string;
    action: string;
    entityId: number;
};

type MenuSourceUrlProps = BaseSourceUrlProps & {
    selectedCode?: string;
};

type SetCollapseUrlProps = BaseSourceUrlProps & {
    actionName: string;
    isCollapsed: string;
};

// TODO: Move the function as global to use anywhere
const getMenuSourceUrl = <TProps extends BaseSourceUrlProps>(props: TProps): string => {
    const { action, ...params } = props;
    const { apiContextRoot, apiContextPath } = window.Props as Record<string, string>;
    const url = `${apiContextRoot}${apiContextPath}/view/component/FacetMenuComponent/${action}`;

    const urlParams = toPairs(params)
        .reduce((params, [paramName, value]) => {
            if (!isNil(value)) {
                const paramValue = isArray(value) ? value.join(',') : (value as string);
                params.push(`${paramName}=${paramValue}`);
            }

            return params;
        }, [] as string[])
        .join('&');

    return `${url}?${urlParams}`;
};

export const useFacetMenuView = (props: UseViewScreenProps): UseFacetMenuView => {
    const { component, selectedCode } = props;
    const [data, setData] = useState<FacetMenuComponentSourceViewData>();
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        if (!component.dataSource) {
            return;
        }

        const queryParameters = new URLSearchParams(window.location.search);

        const dataUrl = getMenuSourceUrl<MenuSourceUrlProps>({
            action: 'getSource',
            dataSource: component.dataSource,
            entityId: component.entityId,
            selectedCode: selectedCode ?? (queryParameters.get('selectedCode') as string),
        });

        const fetchData = async (): Promise<void> => {
            const value = await apiFetch<FacetMenuComponentSource>(dataUrl);
            setData(value.items);
            setIsCollapsed(value.isCollapsed);
        };

        void fetchData();
    }, [component.dataSource]);

    const toggleCollapse = async (): Promise<void> => {
        await setCollapse();
        setIsCollapsed((isCollapse) => !isCollapse);
    };

    const setCollapse = async (): Promise<void> => {
        const url = getMenuSourceUrl<SetCollapseUrlProps>({
            action: 'sourceAction',
            actionName: 'setCollapse',
            dataSource: component.dataSource,
            entityId: component.entityId,
            isCollapsed: (!isCollapsed).toString(),
        });
        await apiFetch(url);
    };

    return { data, isCollapsed, toggleCollapse };
};
