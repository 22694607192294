import { TooltipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useState, useEffect, FC, useRef } from 'react';
import { AppTheme } from '../../app';
import { Tooltip } from '../tooltip';

const useStyles = makeStyles<AppTheme>((theme) => ({
    tooltip: {
        ...theme.styles.ellipsis,
    },
}));

type TextEllipsisProps = {
    /**
     * The text content to be displayed with ellipsis if it overflows.
     */
    text: string;

    /**
     * Optional. Additional CSS classes to be applied to the TextEllipsis component.
     * Inherits classes from TooltipProps and can include a 'content' class.
     */
    classes?: TooltipProps['classes'] & {
        /**
         * Optional. CSS class for the content of the tooltip.
         */
        content?: string;
    };

    /**
     * Optional. The placement of the tooltip relative to the TextEllipsis component.
     */
    placement?: TooltipProps['placement'];
};

export const TextEllipsis: FC<TextEllipsisProps> = ({ text, placement = 'top', classes: externalClasses }) => {
    const [isOverflowing, setIsOverflowing] = useState(false);
    const titleRef = useRef<HTMLSpanElement>(null);
    const classes = useStyles();
    const { content: contentClassName, ...tooltipClasses } = externalClasses || {};

    useEffect(() => {
        const updateOverflow = (): void =>
            setIsOverflowing(titleRef.current !== null && titleRef.current.scrollWidth > titleRef.current.clientWidth);
        const observer = new ResizeObserver(updateOverflow);

        if (titleRef.current) {
            observer.observe(titleRef.current);
            updateOverflow();
        }

        return (): void => {
            observer.disconnect();
        };
    }, [text]);

    return (
        <Tooltip title={isOverflowing ? text : ''} classes={tooltipClasses} placement={placement}>
            <span ref={titleRef} className={classNames(classes.tooltip, contentClassName)}>
                {text}
            </span>
        </Tooltip>
    );
};
