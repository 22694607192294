import { format, parse, isEqual, isValid } from 'date-fns';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '../../../store';
import { getAppResources } from '../../../store/slices';
import { DatePicker } from '../../common/datePicker';
import { getValidationMessagesForField } from '../itemScreenHelpers';
import { ControlTypeProps } from '../types';
import { ControlLabel } from './controlLabel';
import css from './dateTimePicker.module.scss';
import ReadOnlyControl from './readOnlyControl';

export const DateTimePickerControl: React.FC<ControlTypeProps> = ({ field, fieldData, readOnly }) => {
    const { register, setValue, errors } = useFormContext();

    const appResources = useAppSelector(getAppResources);
    const dateFormat: string = appResources.displayedDateFormat;
    const dateTimeFormat: string = appResources.displayedDateTimeFormat;
    const isDatePicker = field.controlType === 'Date Picker';
    const formatToUse = isDatePicker ? dateFormat : dateTimeFormat;
    const [selectedDate, setSelectedDate] = useState<Date | null>(() => {
        if (!fieldData.displayValue) {
            return null;
        }
        return parse(fieldData.displayValue, formatToUse, new Date());
    });

    useEffect(() => {
        if (!readOnly) {
            register({ name: field.name });
            setValue(field.name, fieldData.displayValue);
        }
    }, [register, setValue, field.name, fieldData.displayValue, readOnly]);

    const onChange = useCallback(
        (date) => {
            setSelectedDate((currSelectedDate) => {
                if (date === null) {
                    setValue(field.name, '');
                    return null;
                } else if (!isValid(date)) {
                    return date;
                } else if (currSelectedDate == null || !isEqual(currSelectedDate, date)) {
                    const formattedDate = format(date, formatToUse);
                    setValue(field.name, formattedDate);
                    return date;
                } else {
                    return currSelectedDate;
                }
            });
        },
        [field.name, formatToUse, setValue],
    );

    const inputProps = useMemo(
        () => ({
            'aria-label': field.name,
            id: field.name,
            'data-testid': 'dateTimePicker',
        }),
        [field.name],
    );

    if (readOnly) {
        return <ReadOnlyControl field={field} fieldData={fieldData} />;
    }

    return (
        <ControlLabel
            field={field}
            control={
                <div className={css.root}>
                    <DatePicker
                        key={field.defaultId + 'DatePicker'}
                        inputProps={inputProps}
                        selected={selectedDate}
                        onChange={onChange}
                        showTimeSelect={!isDatePicker}
                        error={getValidationMessagesForField(field, errors, 'errors').length > 0}
                        warning={getValidationMessagesForField(field, errors, 'warnings').length > 0}
                    />
                </div>
            }></ControlLabel>
    );
};

export default DateTimePickerControl;
