import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WkSpyglassIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkSpyglassIcon" {...props}>
        <path
            d="M13.3,14L13.3,14L8.8,9.6c-1,0.8-2.2,1.2-3.4,1.2c-3,0-5.4-2.4-5.4-5.4C0,4,0.6,2.6,1.6,1.6
      c2.1-2.1,5.5-2.1,7.6,0c1,1,1.6,2.4,1.6,3.8c0,1.3-0.4,2.5-1.2,3.4l4.4,4.4L13.3,14z M5.4,1.1C3,1.1,1.1,3,1.1,5.4
      c0,1.1,0.5,2.2,1.3,3c1.6,1.8,4.3,1.9,6.1,0.3s1.9-4.3,0.3-6.1C8.6,2.5,8.5,2.4,8.4,2.3C7.6,1.5,6.5,1.1,5.4,1.1z"
        />
    </SvgIcon>
);

export default WkSpyglassIcon;
