import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkAccruals = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkAddIcon" viewBox="0 0 25 25" {...props}>
        <defs>
            <clipPath id="clipPath-wk-accruals">
                <rect id="Rectangle_3426" data-name="Rectangle 3426" width="25" height="19.947" fill="currentColor" />
            </clipPath>
        </defs>
        <g id="Group_10971" transform="translate(-3249 -250)">
            <g id="Group_9352" transform="translate(3249 252.785)">
                <g id="Group_9351" clipPath="url(#clipPath-wk-accruals)">
                    <path
                        id="Path_5000"
                        d="M19.153,8.705h.024a1.533,1.533,0,0,0,1.528-1.551,1.553,1.553,0,1,0-1.553,1.551"
                        transform="translate(0.926 0.295)"
                        fill="currentColor"
                    />
                    <path
                        id="Path_5001"
                        d="M0,19.947H25V0H0ZM23.579,1.421V13.474H15.852l.114-.2a10.929,10.929,0,0,0,1.456-5.824,10.943,10.943,0,0,0-1.456-5.834l-.114-.2Zm-12.724,0H14.04l.039.053a9.181,9.181,0,0,1,1.816,5.981A9.464,9.464,0,0,1,14.08,13.42l-.039.054H10.856l-.04-.053A9.187,9.187,0,0,1,9,7.44a9.479,9.479,0,0,1,1.815-5.965Zm-9.434,0H9.148l-.114.2A10.942,10.942,0,0,0,7.579,7.443a10.949,10.949,0,0,0,1.456,5.833l.114.2H1.421Zm0,13.474H23.579V16H1.421Zm0,2.526H23.579v1.106H1.421Z"
                        transform="translate(0 0)"
                        fill="currentColor"
                    />
                    <path
                        id="Path_5002"
                        d="M6.305,7.155A1.553,1.553,0,1,0,4.753,8.705h.025A1.533,1.533,0,0,0,6.305,7.155"
                        transform="translate(0.168 0.295)"
                        fill="currentColor"
                    />
                </g>
            </g>
            <rect id="Rectangle_3440" width="24.683" height="24.683" transform="translate(3249 250)" fill="none" />
        </g>
    </SvgIcon>
);
