//var currencySymbol: string;
//var currentCurrencySymbol: string;
var actionParameters: any;

export function initParameters(data: any) {
    actionParameters = data;
}

export function updateCalculatedValue() {
    var adjustmentAmountCtrl = document.getElementById("txtAdjustmentAmount") as HTMLSelectElement | null;
    var adjustmentAmount = changeCurToJSSeparators(adjustmentAmountCtrl?.value);

    var adjustmentTypeCtrl = document.getElementById("ddlAdjustType") as HTMLSelectElement | null;
    var adjustmentType = adjustmentTypeCtrl?.value;
    var AdjustByOrToCtrl = document.getElementById("ddlAdjustByOrTo") as HTMLSelectElement | null;
    var AdjustByOrTo = AdjustByOrToCtrl?.value;
    var netTotal = parseFloat(actionParameters.NetAmount);
    var percentOfResponsibility = actionParameters.PercentOfResponsibility;
    var rate = parseFloat(actionParameters.Rate);
    var unit = parseFloat(actionParameters.Unit);
    //currencySymbol = data.CurrencySymbol;
    //currentCurrencySymbol = data.CurrentCurrencySymbol;
    var lblCalculatedAmount = document.getElementById("lblCalculatedAmount") as HTMLSelectElement | null;
    var lblNetAmount = document.getElementById("lblNetAmount") as HTMLSelectElement | null;

    var selectedItemsCount = actionParameters.SelectedItemsCount;
    if (selectedItemsCount == null || selectedItemsCount === 'undefined') {
        selectedItemsCount = 1;
    }

    var nonZeroUnitsNetTotal = actionParameters.NonZeroUnitsNetTotal;
    if (nonZeroUnitsNetTotal == null || nonZeroUnitsNetTotal === 'undefined') {
        nonZeroUnitsNetTotal = netTotal;
    }

    var nonZeroRateNetTotal = actionParameters.NonZeroRateNetTotal;
    if (nonZeroRateNetTotal == null || nonZeroRateNetTotal === 'undefined') {
        nonZeroRateNetTotal = netTotal;
    }

    var negationCtrl = document.getElementById("ddlNegation") as HTMLSelectElement | null;
    var negation = negationCtrl ? parseFloat(negationCtrl.value) : 0;
    var calculatedAmount = 0;
    var netAmount = 0;

    if (AdjustByOrTo == "0") { //Adjust BY
        switch (adjustmentType) {
            case "fixed":
                netAmount = negation * adjustmentAmount * selectedItemsCount + netTotal;
                break;
            case "percent":
                calculatedAmount = negation * netTotal * adjustmentAmount / 100;
                calculatedAmount = roundup(calculatedAmount, 2);
                netAmount = calculatedAmount + netTotal;
                break;
            case "unit":
                calculatedAmount = negation * rate * adjustmentAmount;
                netAmount = calculatedAmount + nonZeroRateNetTotal;
                break;
            case "rate":
                calculatedAmount = negation * unit * adjustmentAmount;
                netAmount = calculatedAmount + nonZeroUnitsNetTotal;
                break;
        }

        if (lblCalculatedAmount) {
            if (adjustmentType == "fixed") {

                lblCalculatedAmount.innerHTML = "";
                lblCalculatedAmount.title = "";


            } else {

                lblCalculatedAmount.innerHTML = formatCurr(calculatedAmount, 2);
                lblCalculatedAmount.title = "Calculated Adjustment Amount: " + formatCurr(calculatedAmount, 9);

            }
        }
    }

    else if (AdjustByOrTo == "1") { //Adjust TO
        switch (adjustmentType) {
            case "fixed":
                netAmount = netTotal;
                calculatedAmount = negation * adjustmentAmount * selectedItemsCount - netAmount;
                break;
            case "percent":
                netAmount = netTotal;
                calculatedAmount = netAmount * (negation * adjustmentAmount - 100) / 100;
                calculatedAmount = roundup(calculatedAmount, 2);
                break;
            case "unit":
                netAmount = netTotal;
                calculatedAmount = negation * rate * adjustmentAmount - netAmount;
                break;
            case "rate":
                netAmount = nonZeroUnitsNetTotal;
                calculatedAmount = negation * unit * adjustmentAmount - netAmount;
                break;
        }
        if (lblCalculatedAmount) {
            lblCalculatedAmount.innerHTML = formatCurr(calculatedAmount, 2);
            lblCalculatedAmount.title = "Calculated Adjustment Amount: " + formatCurr(calculatedAmount, 9);
        }
    }
    if (lblNetAmount) {
        lblNetAmount.innerHTML = formatCurr(netAmount, 2);
        lblNetAmount.title = formatCurr(netAmount, 9);
    }

    if (percentOfResponsibility != null) {
        var NetAmountWithPoR = formatCurr(netAmount * percentOfResponsibility, 2)
        var lblNetAmountWithPoR = document.getElementById('lblNetAmountWithPoR') as HTMLSelectElement | null;
        if (lblNetAmountWithPoR) {
            lblNetAmountWithPoR.textContent = NetAmountWithPoR;
        }
    }

    var cssClassName = "ff-calculatedAmount";
    var calculatedAmountCell = document.getElementById('calculatedAmountCell') as HTMLTableCellElement | null;
    if (calculatedAmountCell) {
        if (adjustmentType == "fixed") {
            calculatedAmountCell.classList.remove(cssClassName);
        } else {
            calculatedAmountCell.classList.add(cssClassName);
        }
    }

    //moveFocusTo();
}

function CorrectDelimiter(num: any) {
    var result = num;

    var chkMetacharacters = new Array(".", "*", "+", "?", "^", "$");

    for (var i = 0; i < chkMetacharacters.length; i++) {
        if (num == chkMetacharacters[i]) {
            result = "\\" + num;
            break;
        }
    }

    return result;
}

export function changeCurToJSSeparators(num: any) {
    var array = num.split(actionParameters.CurDecSep);
    var separator = CorrectDelimiter(actionParameters.CurGroupSep);
    var re = new RegExp(separator, "g");
    array[0] = array[0].replace(re, "");
    return array.join(actionParameters.InvDecSep);
}

function roundup(num: any, dec: any) {
    dec = dec || 0;
    var s = String(num);
    if (parseFloat(s) % 1) {
        var countDecimals = s.toString().split(".")[1].length;
        if (countDecimals == 3)
            s = s.replace(/5$/, '6');
    }
    return Number((+s).toFixed(dec));
}

export function formatCurr(value: any, NumDigitsAfterDecimal: any) {
    if (value == null || value === undefined) {
        value = 0;
    }
    if (typeof value !== 'number') {
        value = parseFloat(value);
    }
    var currencySymbol = actionParameters.CurrencySymbol;
    if (currencySymbol == "") {
        currencySymbol = actionParameters.CurrentCurrencySymbol;
        const optionElement = document.querySelector('option[value="fixed"]');
        if (optionElement) {
            optionElement.innerHTML = currencySymbol;
        }
    }
    return currencySymbol + value.toFixed(NumDigitsAfterDecimal).toLocaleString();
}


export default updateCalculatedValue;

export function validationSuccess() {

    var adjustmentAmountCtrl = document.getElementById("txtAdjustmentAmount") as HTMLSelectElement | null;
    var adjustmentAmount = changeCurToJSSeparators(adjustmentAmountCtrl?.value);
    // var adjustmentAmount = (document.getElementById("txtAdjustmentAmount") as HTMLSelectElement | null)?.value;
    var adjustmentType = (document.getElementById("ddlAdjustType") as HTMLSelectElement | null)?.value;
    var adjustByOrTo = (document.getElementById("ddlAdjustByOrTo") as HTMLSelectElement | null)?.value;
    var negationCtrl = document.getElementById("ddlNegation") as HTMLSelectElement | null;
    var negation = negationCtrl ? parseFloat(negationCtrl.value) : 0;
    // var negation = (document.getElementById("ddlNegation") as HTMLSelectElement | null)?.value;
    var netTotal = parseFloat(actionParameters.NetAmount);
    var unit = parseFloat(actionParameters.Unit);
    var rate = parseFloat(actionParameters.Rate);

    if (adjustmentAmount <= 0) {
        alert('Please enter an  Amount');
        adjustmentAmountCtrl?.focus();
        return false;
    }

    if (isNaN(adjustmentAmount)) {
        alert('Invalid amount!');
        adjustmentAmountCtrl?.focus();
        return false;
    }

    if (adjustmentType == 'percent') {
        if (adjustmentAmount > 100) {
            alert('The maximum amount for a percentage adjustment is 100');
            adjustmentAmountCtrl?.focus();
            return false;
        }
    }

    var selectedItemsCount = actionParameters.SelectedItemsCount;
    if (selectedItemsCount == null || selectedItemsCount == 'undefined') {
        selectedItemsCount = 1;
    }

    var nonZeroUnitsNetTotal = actionParameters.NonZeroUnitsNetTotal;
    if (nonZeroUnitsNetTotal == null || nonZeroUnitsNetTotal == 'undefined') {
        nonZeroUnitsNetTotal = netTotal;
    }

    var amount = 0;
    if (adjustByOrTo == '0') {
        switch (adjustmentType) {
            case 'rate':
                amount = negation * adjustmentAmount * unit;
                break;
            case 'unit':
                amount = negation * adjustmentAmount * rate;
                break;
            case 'percent':
                amount = negation * adjustmentAmount * netTotal / 100;
                break;
            case 'fixed':
                amount = negation * adjustmentAmount;
                break;
            default:
                return;
        }
    }
    else if (adjustByOrTo == '1') {
        switch (adjustmentType) {
            case 'rate':
                amount = (negation * adjustmentAmount * unit) - nonZeroUnitsNetTotal;
                break;
            case 'unit':
                amount = (negation * adjustmentAmount * rate) - netTotal;
                break;
            case 'percent':
                amount = (negation * adjustmentAmount * netTotal - 100) / 100;
                break;
            case 'fixed':
                amount = negation * adjustmentAmount - netTotal;
                break;
            default:
                return;
        }
    }

    if (adjustByOrTo == '0') {
        var totals = actionParameters.ArrayTotals;
        if (totals != undefined && totals != null && (adjustmentType == 'rate' || adjustmentType == 'unit')) {
            const fieldName = adjustmentType == 'rate' ? 'units' : 'rate';
            for (var index = 0; index < totals.length; ++index) {
                if ((totals[index].netTotal + negation * adjustmentAmount * totals[index][fieldName]) < 0) {
                    alert('You cannot adjust by an amount greater than the smallest selected line item');
                    adjustmentAmountCtrl?.focus();
                    return false;
                }
            }
        }

        var minAmount = parseFloat(actionParameters.MinAmount);
        if (!isNaN(minAmount) && adjustmentType == 'fixed' && (minAmount + negation * adjustmentAmount) < 0) {
            alert('You cannot adjust by an amount greater than the smallest selected line item');
            adjustmentAmountCtrl?.focus();
            return false;
        }
    }

    if ((amount < -999999999999.99) || (amount > 999999999999.99)) {
        alert('Invalid adjustment amount value. Valid range is -999,999,999,999.99 to 999,999,999,999.99.');
        adjustmentAmountCtrl?.focus();
        return false;
    }
    return true;
}