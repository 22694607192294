import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get, merge } from 'lodash';
import { load, save } from 'redux-localstorage-simple';
import { AppState } from '../types';

export interface PersistState {
    mainMenuExpanded?: boolean;
    favoritesBarPinned?: boolean;
}

export const key = 'uui-persist-ui-state';
export const statePath = 'ui.persistState';

export const persistStorageSave = (): ReturnType<typeof save> =>
    save({
        states: [statePath],
        namespace: key,
    });

export const persistStorageLoad = (): ReturnType<typeof load> =>
    load({
        states: [statePath],
        namespace: key,
    });

const initialState: PersistState = merge({}, get(persistStorageLoad(), statePath) as object);

const persistState = createSlice({
    name: 'persist-state',
    initialState,
    reducers: {
        setMainMenu: (state, action: PayloadAction<{ expanded: boolean }>) => {
            state.mainMenuExpanded = action.payload.expanded;
        },
        setFavoritesBar: (state, action: PayloadAction<{ pinned: boolean }>) => {
            state.favoritesBarPinned = action.payload.pinned;
        },
    },
});

// Selectors
// TODO: allow key to be optional
export const selectPersistState =
    <T extends keyof PersistState>(key: T) =>
    (state: AppState): PersistState[T] =>
        state.ui.persistState[key];

// Actions & Reducer
export type PersistStateActions = TakeActions<typeof persistState.actions>;
export const { setFavoritesBar, setMainMenu } = persistState.actions;
export const persistStateReducer = persistState.reducer;
