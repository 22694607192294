import { getLogger } from '@wk/elm-uui-common';
import { Report } from 'powerbi-client';
import { PageSizeType } from 'powerbi-models';
import { MESSAGES } from '../common/constants';
import { ChartType } from '../common/enums';
import { BaseChartStrategy } from './baseChart.strategy';
import { ChartStrategy } from './chartStrategy.interface';

const logger = getLogger('line-chart-strategy');

export class LineChartStrategy extends BaseChartStrategy implements ChartStrategy {
    public async resize(width: number, height: number): Promise<void> {
        const adjustedHeight = height - Math.ceil(this.chart?.layout.y ?? 0);

        await this.report?.resizeActivePage(PageSizeType.Custom, width, height);
        await this.chart?.resizeVisual(width, adjustedHeight);
        await this.resizeFilterLines(width);
        await this.resizeFilterPaneLayout(height)
        await this.resizeHiddenCloseFilterButton(width,height)
    }

    public async fetchDataAndInit(report: Report): Promise<void> {
        await this.initialize(report);
        this.chart = this.visuals.find((visual) => visual.type === ChartType.Line);
        if (!this.chart) {
            logger.error(MESSAGES.NO_CHARTS);
            throw new Error(MESSAGES.NO_CHARTS);
        }
    }
}
