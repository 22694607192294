import { CHMessagingScope } from '@wk/elm-uui-context-handler';
import { messageBusDispatch } from '../components/contextLayerService/messageBusService';
import { AppDispatch } from '../store';
import {
    setBatchUploadFlyoutCount,
    setFailedUploadFlyoutOpen,
    setIsUploadingFlyoutOpen,
    setNewUploadCountUpdated,
} from '../store/slices/historyScreen.slice';

export const readClosedFailedUploadFlyoutAtFromLocalStorage = (): string | null => {
    return localStorage.getItem('closeFailedUploadFlyoutAt');
};

export const openFailedUploadFlyout = (dispatch: AppDispatch): void => {
    updateFailedUploadFlyoutState(true, dispatch);
    failedUploadFlyoutMessageBus('true');
};

export const closeFailedUploadFlyout = (dispatch: AppDispatch): void => {
    localStorage.setItem('closeFailedUploadFlyoutAt', new Date().toJSON());
    updateFailedUploadFlyoutState(false, dispatch);
    failedUploadFlyoutMessageBus('false');
};

const updateFailedUploadFlyoutState = (isOpen: boolean, dispatch: AppDispatch): void => {
    dispatch(setFailedUploadFlyoutOpen({ isOpen }));
};

const failedUploadFlyoutMessageBus = (isOpen: string): void => {
    messageBusDispatch({
        type: 'FailedUploadFlyoutOpen',
        scope: CHMessagingScope.OtherInstances,
        message: isOpen,
    });
};

export const updateBatchUploadFlyoutCount = (count: string, dispatch: AppDispatch): void => {
    dispatch(setBatchUploadFlyoutCount({ count }));
    dispatch(setNewUploadCountUpdated({ isUpdated: true }));
};

export const updateUploadingFlyoutState = (isOpen: boolean, dispatch: AppDispatch): void => {
    dispatch(setIsUploadingFlyoutOpen({ isOpen }));
};
