import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkHome = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkAddIcon" viewBox="0 0 25 25" {...props}>
        <g id="Group_10962" data-name="Group 10962" transform="translate(-3217 -521)">
            <rect
                id="Rectangle_3445"
                data-name="Rectangle 3445"
                width="25"
                height="25"
                transform="translate(3217 521)"
                fill="none"
            />
            <path
                id="Home_icon"
                data-name="Home icon"
                d="M24,12,19.2,7.2V1.6h-4V3.2L12,0,0,12l1.125,1.125L2.4,11.85V24h8V16h3.2v8h8V11.85l1.275,1.275ZM16.8,3.2h.8V5.6l-.8-.8ZM20,22.4H15.2v-8H8.8v8H4V10.25l8-8,8,8Z"
                transform="translate(3218 522)"
                fill="currentColor"
            />
        </g>
    </SvgIcon>
);
