import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';
import { DashboardPowerBIComponent } from '../../../../store/slices';
import { AppTheme } from '../../../app';
import { usePowerBI } from '../layoutPowerBiView/hooks';
import { PowerBIEmbedView } from '../powerBIEmbedView';

const useStyles = makeStyles<AppTheme>((theme) => ({
    reportContainer: {
        flex: 1,
        height: '73vh',
        margin: 8,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    report: {
        marginTop: theme.spacing(1),
        height: '100%',

        '& iframe': {
            border: 0,
        },
    },
    hiddenReport: {
        display: 'none',
    },
}));

type DashboardProps = {
    component: DashboardPowerBIComponent;
};

export const DashboardPowerBiView: FC<DashboardProps> = (props) => {
    const { component } = props;
    const { embedConfig, fetchDataAndInit, tokenStatus } = usePowerBI({ component });

    const classes = useStyles();

    return (
        <PowerBIEmbedView
            fetchMethod={fetchDataAndInit}
            embedConfig={embedConfig}
            classes={classes}
            isMinimized={false}
            testId="dashboard-power-bi"
            tokenStatus={tokenStatus}
        />
    );
};
