import { useEffect, useState, useRef } from 'react';
import { FilterComponent, FilterComponentItem } from '../../../../../store/slices';
import { apiFetch } from '../../../../../utils/fetchUtils';
import { ControlType } from '../../../../common/types';

type UseViewScreenProps = {
    component: FilterComponent;
    filterValues: Record<string, string>;
};

export interface DropDownFilterItem {
    id: string | undefined;
    name: string;
}

export const useFilterView = (props: UseViewScreenProps) => {
    const { component, filterValues } = props;
    const { apiContextRoot, apiContextPath } = window.Props as Record<string, string>;
    const [dropDownSources, setDropDownSources] = useState<Record<string, DropDownFilterItem[]>>({});
    const dropDownSourcesState = useRef<Record<string, DropDownFilterItem[]>>({});

    useEffect(() => {
        if (!component.dataSource) {
            return;
        }
        const fetchData = () => {
            const defaultFilters = prepareFiltersParameters(filterValues);
            component.filters.map(async (filter) => {
                if (filter.type === ControlType.DropDown) {
                    await getDropDownSource(filter.filterCode, defaultFilters);
                }
            });
        };

        void fetchData();
    }, [component.dataSource]);

    const updateDependentSources = (filterCode: string, newFilterValues: Record<string, string>) => {
        const existingFilters = prepareFiltersParameters(newFilterValues);
        const filter = component.filters.find((filter) => filter.filterCode === filterCode);
        filter?.dependentFilters?.forEach(async (dependentFilter) => {
            if (filter.type === ControlType.DropDown) {
                await getDropDownSource(dependentFilter, existingFilters);
            }
        });
    };

    const getDropDownSource = async (filterCode: string, existingFilters: string) => {
        const url = `${apiContextRoot}${apiContextPath}/view/component/FilterComponent/getSource?dataSource=${component.dataSource}&filterCode=${filterCode}${existingFilters}${component.dropdownParameters}`;
        const value = await apiFetch<DropDownFilterItem[]>(url);
        value.unshift({ id: '', name: '' });
        dropDownSourcesState.current[filterCode] = value;
        const newDropDownSources = { ...dropDownSourcesState.current };
        setDropDownSources(newDropDownSources);
    };

    const saveDefault = async (newFilterValues: Record<string, string>) => {
        const existingFilters = prepareFiltersParameters(newFilterValues);
        const url = `${apiContextRoot}${apiContextPath}/view/component/FilterComponent/saveDefaults?dataSource=${component.dataSource}${existingFilters}`;
        await apiFetch<DropDownFilterItem[]>(url);
    };

    const resetToDefault = async (): Promise<FilterComponentItem[]> => {
        const url = `${apiContextRoot}${apiContextPath}/view/component/FilterComponent/resetDefaults?dataSource=${component.dataSource}`;
        return await apiFetch<FilterComponentItem[]>(url);
    };

    const prepareFiltersParameters = (newFilterValues: Record<string, string>) => {
        let existingFilters = '';
        for (const key in newFilterValues) {
            const value = newFilterValues[key];
            if (value && value !== '') {
                existingFilters = `${existingFilters}&${key}=${value}`;
            }
        }
        return existingFilters;
    };

    return {
        dropDownSources,
        saveDefault,
        resetToDefault,
        updateDependentSources,
    };
};
    