import { LogLevel } from '@wk/elm-uui-common';
import { IFieldValue, IQueueItem, IRefreshUUIForEntity } from '@wk/elm-uui-context-handler';
import { CHMessagingScope, IOverlayDialog } from '@wk/elm-uui-context-handler';
import { TransitionCloseHandler } from 'notistack';
import { IAlertDialog } from '../components/common/alertDialog';
import { TargetScreenType } from '../components/common/types';
import { IItemScreenProps } from '../components/itemScreen/itemScreen';
import { Configuration } from '../initialize';
import { FavoriteEntity, GlobalState, HelpSectionState, PersistState, UserScreenState } from '../store/slices';
import { ILegacyActionDetails } from './types.legacy';

export type SelectionOption = {
    value: string;
    displayValue: string;
};

export interface AppStore {
    appsMenu: IAppsMenu;
    appResources: IAppResources;
    applicationUrls: IApplicationUrls;
    appVersion?: IAppVersion;
    comparisonTypes: IComparisonTypes;
    quickLinks: IQuickLinks;
    megaMenu: IMenuItem[];
    menuShortcuts: string[];
    iconToolbarLinks?: IMenuItem[];
    ui: UiState;
    contextLayerConfig: IContextLayerConfig;
    uuiConfiguration: Configuration;
    availableCurrencies: string[];
    version: string;
    // will fill these in later
    alerts?: any;
    favorites?: any;
    systemMessages?: any;
    moduleHelp?: any;
    isSSOLogin?: boolean;
    enableUnifiedUI?: boolean;
    currencySelector?: any;
    globalSearch?: any;
    hybridSettings: IHybridSettings;
    mappedNetworks?: INetwork[];
    userMenu?: IMenuItem[];
    bookmarks: FavoriteEntity;
    networkLabel:string;
}

export interface IContextLayerConfig {
    quickFileAddDocumentScreenId: number;
    checkInScreenId: number;
}

export interface INetwork {
    networkId: number;
    networkName: string;
    isCurrent: boolean;
}
// breadCrumbs
export interface BreadCrumb {
    title: string;
    url: string;
    isRequired: boolean;
}

// appsMenu
export interface IAppsMenu {
    activeContext: number;
    passportContexts: IPassportContext[];
}

export interface IPassportContext {
    defaultLandingPage: string | null;
    displayName: string;
    id: number;
    isCaseSensitive: boolean;
    iconClass: string | null;
    hideSessionCurrencySelector: boolean;
}

// appResources
export interface IAppResources {
    [key: string]: string;
}

// appVersion
export interface IAppVersion {
    autoUpdateUrl?: string;
}

// applicationUrls
export interface IApplicationUrls {
    [key: string]: string;
}

// comparisonTypes
export interface IComparisonTypes {
    Boolean: string[];
    ComparisonTypeMap: Record<string, string>;
    Currency: string[];
    Date: string[];
    DateTime: string[];
    Decimal: string[];
    Enum: string[];
    File: string[];
    Integer: string[];
    Money: string[];
    MultiAssociation: string[];
    Number: string[];
    Rating: string[];
    RelativeDateRanges: string[][];
    SingleAssociation: string[];
    String: string[];
    Text: string[];
}

// quickLinks
export interface IQuickLinks {
    menuItems: IMenuItem[];
}

// uui
export interface UiState {
    globalState: GlobalState;
    userScreen: UserScreenState;
    itemScreenDialogs: IItemScreenProps[];
    notifications: INotification[];
    alertDialogs: IAlertDialog[];
    historyScreen: IUuiHistoryScreen;
    messageBus: IMessageBus;
    uploadHistoryItems: IQueueItem[];
    helpSection: HelpSectionState;
    overlayDialog?: IOverlayDialog;
    persistState: PersistState;
}

export interface IMessageBus {
    // when this is defined, it will trigger the list screen and item screen to refresh for this entityMetadataId/entityInstanceId key
    refreshUUIForEntity?: IRefreshUUIForEntityWithKey;
    refreshUUIForEntityIsProcessed?: boolean;
    suppressNextRefresh?: boolean;
}

export interface IRefreshUUIForEntityWithKey extends IRefreshUUIForEntity {
    key: string;
}

export enum NotificationPlacement {
    BottomLeft = 'bottom-left',
    BottomRight = 'bottom-right',
    Bottom = 'bottom',
    TopLeft = 'top-left',
    TopRight = 'top-left',
    Top = 'top',
}

export interface INotification {
    key: string;
    message: string;
    dismissed?: boolean;
    options: INotificationOptions;
    scope?: CHMessagingScope;
    placement?: NotificationPlacement;
}

export type NotificationType = 'success' | 'error' | 'warning' | 'info';

export interface INotificationOptions {
    variant: NotificationType;
    persist?: boolean;
    onClose?: TransitionCloseHandler;
}

export interface ILogLevel {
    key: LogLevel;
    value: string;
}

// shared types
export interface IMenuItem {
    children?: IMenuItem[];
    displayValue: string;
    hasCounts: boolean;
    description: string;
    contexts: number[];
    icon: string;
    id: number;
    isDivider: boolean;
    isNewWindow: boolean;
    isPopup: boolean;
    name: string;
    passportContexts: number[];
    queryString?: string;
    screenId?: number | null;
    screenType?: TargetScreenType | null;
    url: string;
    legacyActionDetails?: ILegacyActionDetails;
    selected?: boolean;
    code?: string;
    isSubMenuExternal?:boolean;
    subMenuApiKey?: string;
}

export interface IUuiHistoryScreen {
    isHistoryDialogOpen: boolean;
    isFailedUploadFlyoutOpen: boolean;
    batchUploadFlyoutCount: string;
    isNewUploadCountUpdated: boolean;
    isUploadingFlyoutOpen: boolean;
}

export interface Error {
    name?: string;
    message?: string;
    stack?: string;
}

export interface IQueueData {
    documentFolderId: string;
    documentName: string;
    entityContext: IQueueEntityContext;
    file: string | null;
    fileId: string;
    filePath: string;
    folderPath: string;
    name: string;
    payload: Record<string, IFieldValue>;
    associatedEntityName: string;
    associatedEntityType: string;
    associatedEntityId: string;
}

export interface IQueueDocumentData {
    payload: Record<string, IFieldValue>;
    associatedEntityName: string;
    associatedEntityType: string;
}

export interface IQueueEmailData {
    filePath: string;
    props: Record<string, any>;
    associatedEntityType: string;
    associatedEntityName: string;
}

export interface IActivityRow {
    documentName: string;
    title: string;
    associatedEntityType: string;
    associatedEntityName: string;
    isEmail: boolean;
}

export interface IQueueEntityContext {
    id?: string;
    name?: string;
    type?: string;
}

export enum AnnouncementPriority {
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
}

export enum AnnouncementType {
    Advanced = 'Advanced',
    Common = 'Common',
}

export interface Announcement {
    body?: string;
    date: string;
    description: string;
    id: number;
    priority: AnnouncementPriority;
    scopeType: string;
    subject: string;
    type: AnnouncementType;
}
export interface IHybridSettings {
    announcements: Announcement[];
    helpMenu: IMenuItem[] | null;
    isLiveChatEnabled: boolean;
    inactivitySettings: {
        inactivityWarningTimeout: number;
        sessionTimeout: number;
    };
    quickSearchSettings: {
        searchPageTemplate: string;
        searchSubjects: SelectionOption[];
        searchMatchOptions: SelectionOption[];
        searchSortOptions: SelectionOption[];
    };
    colorTheme: {
        main: string;
        dark: string;
        light: string;
    };
    termsOfUse: IMenuItem;
    salesforceChatURL?: string;
}


export interface IExternalMenu {
    id: number;
    title: string;
    order: number;
    menuItems: IExternalMenuItem[];
    showTitle: boolean;
    name: string;
}

export interface IExternalMenuItem {
    name: string;
    path: string;
    id: number;
    order: number;
}
