import { Logger } from '@wk/elm-uui-common';
import { MD5, AES, enc } from 'crypto-js';
import { Action } from 'history';
import { uniqueId } from 'lodash';
import { LOGGING_OUT } from '../components/app/appContainer/useMessageBus.hook';
import { clClearDefaultUrl, clSetDefaultUrl, isOC } from '../components/contextLayerService/contextLayerService';
import { getHistory } from '../history.service';
import { UUILocation } from '../hooks/useUUILocation';
import { getLogger } from './loggingService';
import { getBasePath } from './uriResolver';

export const USER_HAS_REFRESHED_OR_TIMED_OUT_SESSION_STORAGE_KEY = 'UUIUserHasRefreshedOrTimedOut';
export const HARD_NAVIGATE = 'HARD_NAVIGATE';

const UUI_HISTORY_MAP_KEY = 'UUIHistoryMap';
const UUI_HISTORY_USER_HASH = 'UUIHistoryUserHash';
const UUI_MAX_OC_INSTANCES = Props.maxOCInstanceHistory ? parseInt(Props.maxOCInstanceHistory, 10) : 20;

type IOCHistoryData = {
    lastAccessed: number;
    historyStack: UUILocation[];
};

type OCHistoryMap = Record<string, IOCHistoryData>;

export const logger = (): Logger => {
    return getLogger('OCLocationService');
};

export const clearLocationDataForOC = (): void => {
    if (!isOC()) {
        return;
    }
    logger().info('Clearing all Location Data from Local Storage.');
    localStorage.removeItem(UUI_HISTORY_MAP_KEY);
    localStorage.removeItem(UUI_HISTORY_USER_HASH);
    sessionStorage.removeItem(USER_HAS_REFRESHED_OR_TIMED_OUT_SESSION_STORAGE_KEY);
    sessionStorage.removeItem(HARD_NAVIGATE);
    clClearDefaultUrl();
};

const getCurrTime = (): number => {
    return new Date().getTime();
};

/**
 * The ID of an OC instance is kept in sessionStorage.
 * That ID is retrieved using this method.  If not present
 * in sessionStorage, it is created, added to sessionStorage,
 * and returned.
 */
export const getOCInstanceId = (): string => {
    const OC_INSTANCE_ID_KEY = 'OCInstanceID';
    let ocInstanceId = sessionStorage.getItem(OC_INSTANCE_ID_KEY);
    if (ocInstanceId == null) {
        ocInstanceId = getCurrTime().toString();
        sessionStorage.setItem(OC_INSTANCE_ID_KEY, ocInstanceId);
    }
    return ocInstanceId;
};

/**
 * Update the history map with the last accessed time for this OC instance.
 *
 * @param uuiHistoryMap   Full history map.
 */
export const updateLastAccessed = (uuiHistoryMap: OCHistoryMap): void => {
    const uuiHistoryData = uuiHistoryMap[getOCInstanceId()];
    if (!uuiHistoryData) {
        return;
    }

    uuiHistoryData['lastAccessed'] = getCurrTime();
    // Limit to 10 history entries to save memory
    if (uuiHistoryData.historyStack.length > 10) {
        logger().debug(
            'more than 10 history entries found for this OC instance. Slicing the array to the most recent 10 entries.',
        );
        uuiHistoryData.historyStack = uuiHistoryData.historyStack.slice(uuiHistoryData.historyStack.length - 10);
    }

    // Save the full history map to local storage
    saveUUIHistoryMap(uuiHistoryMap);
};

export const getHistoryStackForCurrentOCInstance = (): UUILocation[] | undefined => {
    return getHistoryStack(getOCInstanceId());
};

export const getHistoryStack = (ocInstanceId: string): UUILocation[] | undefined => {
    // Retrieve the full history map from local storage
    const uuiHistoryMap = getUUIHistoryMap();
    if (!uuiHistoryMap) {
        return undefined;
    }

    // Update the last accessed time for this OC instance
    updateLastAccessed(uuiHistoryMap);

    // Retrieve the history data for the given OC instance
    const uuiHistoryData = uuiHistoryMap[ocInstanceId];
    if (!uuiHistoryData) {
        return undefined;
    }

    const uuiHistoryStack = uuiHistoryData['historyStack'];
    if (!uuiHistoryStack) {
        return undefined;
    }

    return uuiHistoryStack;
};

export const restoreLocationDataForOC = (): void => {
    if (!isOC()) {
        return;
    }

    const uuiHistoryStack = getHistoryStack(getOCInstanceId());
    if (!uuiHistoryStack) {
        return;
    }

    if (uuiHistoryStack.length > 0) {
        // Replace top of history stack with current location.
        // This will also make sure that the default URL for OC
        // is set to the current location of this OC instance.
        logger().debug('restoreLocationDataForOC is setting location data');
        updateLocationDataForOC(uuiHistoryStack[uuiHistoryStack.length - 1], 'REPLACE');
    }
};

export const updateLocationDataForOC = (location: UUILocation, action: Action): void => {
    if (!isOC()) {
        return;
    }

    // Retreive the full history map from local storage
    let currentHistoryMapJsonStr = localStorage.getItem(UUI_HISTORY_MAP_KEY);

    // If no full history map is found, create an empty history map
    // containing data for this OC instance.
    if (currentHistoryMapJsonStr == null) {
        logger().debug('New history map is being created');
        localStorage.setItem(UUI_HISTORY_USER_HASH, MD5(Props.username).toString());
        currentHistoryMapJsonStr =
            '{"' + getOCInstanceId() + '": {"lastAccessed": ' + getCurrTime() + ', "historyStack": []}}';
    } else {
        currentHistoryMapJsonStr = decrypt(currentHistoryMapJsonStr);
    }

    // parse the json string into a real javascript object
    let currentHistoryMap: OCHistoryMap = JSON.parse(currentHistoryMapJsonStr);
    if (currentHistoryMap == null) {
        currentHistoryMap = {};
        currentHistoryMap[getOCInstanceId()] = { lastAccessed: getCurrTime(), historyStack: [] };
    }

    if (!currentHistoryMap[getOCInstanceId()]) {
        currentHistoryMap[getOCInstanceId()] = { lastAccessed: getCurrTime(), historyStack: [] };
    }

    // Find the history stack for this OC instance
    let currentHistoryStack = currentHistoryMap[getOCInstanceId()]['historyStack'];

    if (!currentHistoryStack) {
        currentHistoryMap[getOCInstanceId()]['historyStack'] = [];
        currentHistoryStack = currentHistoryMap[getOCInstanceId()]['historyStack'];
    }

    // If we are popping or replacing, then remove the top entry in the stack
    if (action === 'REPLACE' || action === 'POP') {
        currentHistoryStack.pop();
    }

    // If we are pushing or replacing, create the new history entry in localStorage
    if (action !== 'POP') {
        currentHistoryStack.push(location);
    }

    // Update the last accessed time for this OC instance and save it to local storage
    updateLastAccessed(currentHistoryMap);

    // Update last accessed from location object to avoid incorrect redirection
    const locationObject =
        action === 'PUSH'
            ? { search: location.search || '', pathname: location.pathname || '' }
            : { search: window.location.search, pathname: window.location.pathname };

    // Set the default URL for OC with the OC instance ID as a query string parameter.
    const searchParams = new URLSearchParams(locationObject.search);
    searchParams.set('_oc', getOCInstanceId());
    clSetDefaultUrl(`${window.location.origin}${locationObject.pathname}?${searchParams}`);
};

/**
 * Replays history stack into the browser history
 * @param ocInstanceIdToLoad optional - if provided it will load the history for the given id, otherwise it will search for _oc query string parameter
 * @returns
 */
export const loadLocationDataFromOC = (ocInstanceIdToLoad?: string): void => {
    if (!isOC()) {
        return;
    }
    // Retrieve the query params from the location URL of the location to which we need to route
    const searchParams = new URLSearchParams(window.location.search);
    // Get the OC instance that saved the location
    // to which we need to route, from query params
    let ocKey = ocInstanceIdToLoad || searchParams.get('_oc');
    if (ocKey === undefined || ocKey === null) {
        // To handle special where history stack exists and not able to find oc params
        const uuiHistoryMap = getUUIHistoryMap();
        if (!uuiHistoryMap) {
            logger().debug('no _oc query parameter & uuiHistoryMap not found.');
            return;
        }
        logger().debug('no _oc query parameter found and setting ocKey from uuiHistoryMap.');
        const uuiHistoryArray = transformAndSortHistoryMap(uuiHistoryMap);
        ocKey = uuiHistoryArray[0].ocInstanceId;
    }

    let uuiHistoryStack = getHistoryStack(ocKey);
    if (!uuiHistoryStack) {
        return;
    }

    // Push all history entries of the OC instance that saved the
    // location into the browser history for this OC instance.
    // Also push it into this new OC Instance's history stack so it's in sync.
    // This has to be done here because UUI has not been mounted yet, so the history
    // listener has not been initialized.
    // Make sure we only ever restore a maximum of 10 history entries for performance reasons
    if (uuiHistoryStack.length > 10) {
        uuiHistoryStack = uuiHistoryStack.slice(uuiHistoryStack.length - 10);
    }
    logger().debug(`Pushing ${uuiHistoryStack.length} history entries into local storage`);
    for (let i = 0; i < uuiHistoryStack.length; i++) {
        getHistory().push(uuiHistoryStack[i]);
        updateLocationDataForOC(uuiHistoryStack[i], 'PUSH');
    }
};

export const getUUIHistoryMap = (): OCHistoryMap | undefined => {
    // check to see if the user has changed. If so, clear away
    // the location data for the other user.
    const userNameHash = localStorage.getItem(UUI_HISTORY_USER_HASH);
    if (userNameHash != null && MD5(Props.username).toString() !== userNameHash) {
        logger().debug('username hash did not match. Clearing location data.');
        clearLocationDataForOC();
        return undefined;
    }

    const uuiHistoryMapStr = localStorage.getItem(UUI_HISTORY_MAP_KEY);
    if (uuiHistoryMapStr == null) {
        return undefined;
    }

    const uuiHistoryMap: OCHistoryMap = JSON.parse(decrypt(uuiHistoryMapStr));
    if (uuiHistoryMap == null) {
        return undefined;
    }

    return uuiHistoryMap;
};

export const saveUUIHistoryMap = (uuiHistoryMap: OCHistoryMap): void => {
    localStorage.setItem(UUI_HISTORY_MAP_KEY, encrypt(JSON.stringify(uuiHistoryMap)));
};

export const encrypt = (data: string): string => {
    return AES.encrypt(data, Props.encryptionKey).toString();
};

export const decrypt = (data: string): string => {
    const decrypted = AES.decrypt(data, Props.encryptionKey);
    return decrypted.toString(enc.Utf8);
};

/**
 * Must be called before UUI Mounts.
 * This handles refreshes by temporarily storing the current OC Instance
 * history stack into sessionStorage (which persists across refreshes),
 * and saves it back into local storage on load.
 */
export const setupOCLocationService = (): void => {
    if (!isOC()) {
        return;
    }

    // Set up the onbeforeunload event, which will indicate if a user is refreshing the page
    window.onbeforeunload = () => {
        if (sessionStorage.getItem(LOGGING_OUT) !== null) {
            // if user is logging out, just clear the sessionStorage.
            sessionStorage.clear();
        } else if (sessionStorage.getItem(HARD_NAVIGATE) === null) {
            sessionStorage.setItem(USER_HAS_REFRESHED_OR_TIMED_OUT_SESSION_STORAGE_KEY, Props.username);
        }
        // Clear the default url and rely on focus event to re-set it somewhere else or
        // it will get reset if this is a refresh below during restoreLocationDataForOC()
        clClearDefaultUrl();
    };

    // This code runs when a refresh completes:

    // Detect if user just did a hard refresh
    const currentUserIsRefreshingPageOrLoggedBackIn = sessionStorage.getItem(
        USER_HAS_REFRESHED_OR_TIMED_OUT_SESSION_STORAGE_KEY,
    );
    if (currentUserIsRefreshingPageOrLoggedBackIn == null) {
        // if there is nothing in session storage, this was not a refresh, so
        // it is most likely a fresh login, so if there is no _oc search param,
        // push the page the user landed on into the history stack.
        const searchParams = new URLSearchParams(window.location.search);
        const ocKey = searchParams.get('_oc');
        if (ocKey === null) {
            // To handle special where history stack exists and not able to find oc params
            // Check if historyStack exists and do not update default url in history stack.
            const uuiHistoryMapStr = localStorage.getItem(UUI_HISTORY_MAP_KEY);
            if (uuiHistoryMapStr) {
                logger().debug('history stack found and not updating default url');
                return;
            }
            // this is probably a login attempt, insert the history entry
            // for this landing page into localStorage
            logger().debug('most likely a fresh login just occurred. Inserting current location into history stack');
            const basePath = getBasePath();
            updateLocationDataForOC(
                {
                    pathname: window.location.pathname.replace(basePath, ''),
                    search: window.location.search,
                    key: uniqueId('loc'),
                    state: undefined,
                    hash: '',
                } as UUILocation,
                'REPLACE',
            );
        }
    } else {
        if (currentUserIsRefreshingPageOrLoggedBackIn == Props.username) {
            logger().debug('Refresh or timeout occurred. Replaying history into OC browser');
            loadLocationDataFromOC(getOCInstanceId());
        }
        sessionStorage.removeItem(USER_HAS_REFRESHED_OR_TIMED_OUT_SESSION_STORAGE_KEY);
        sessionStorage.removeItem(HARD_NAVIGATE);
        // call this function to make sure setDefaultUrl gets reset after a refresh
        restoreLocationDataForOC();
    }
};

const transformAndSortHistoryMap = (uuiHistoryMap: OCHistoryMap) => {
    // Convert the history map into an array of history objects
    const uuiHistoryArray = Object.keys(uuiHistoryMap).map((key) => ({
        ...uuiHistoryMap[key],
        ocInstanceId: key,
    }));

    // Sort the array of history objects in descending order by lastAccessed
    uuiHistoryArray.sort((a, b) => b.lastAccessed - a.lastAccessed);
    return uuiHistoryArray;
};

/**
 * Remove old history data from the history map.
 */
export const purgeOldOCLocationHistory = (): void => {
    if (!isOC()) {
        return;
    }

    // Retrieve the full history map from local storage
    const uuiHistoryMap = getUUIHistoryMap();
    if (!uuiHistoryMap) {
        return;
    }

    logger().debug('There are ' + Object.keys(uuiHistoryMap).length + ' OC instances in the history map.');
    logger().debug('Max allowed history map items: ' + UUI_MAX_OC_INSTANCES);

    if (Object.keys(uuiHistoryMap).length > UUI_MAX_OC_INSTANCES) {
        // Purge OC instances from the history map so that there
        // are at most UUI_MAX_OC_INSTANCES OC instances in the history map.
        // The oldest OC instances, based on lastAccessed, are purged.

        logger().debug(
            'There are more than ' + UUI_MAX_OC_INSTANCES + ' OC instances in the history map. Purging oldest.',
        );

        const uuiHistoryArray = transformAndSortHistoryMap(uuiHistoryMap);
        // Purge all but the most recent UUI_MAX_OC_INSTANCES history objects
        const uuiPurgedHistoryArray = uuiHistoryArray.slice(0, UUI_MAX_OC_INSTANCES);

        // Convert the purged history array back into a history map
        const uuiPurgedHistoryMap = uuiPurgedHistoryArray.reduce((obj, item) => {
            const newItem = { lastAccessed: item['lastAccessed'], historyStack: item['historyStack'] };
            return {
                ...obj,
                [item['ocInstanceId']]: newItem,
            };
        }, {});

        // Save the purged map of history stacks into localstorage
        saveUUIHistoryMap(uuiPurgedHistoryMap);
    }
};
