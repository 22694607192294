import React, { VFC, useEffect, useState } from 'react';
import { useAppSelector } from '../../store';
import { AnnouncementDialog } from './announcementDialog';
import { useAnnouncements } from './hooks';

export const Announcements: VFC = () => {
    const announcements = useAppSelector((state) => state.app.hybridSettings.announcements);
    const { markAnnouncementRead } = useAnnouncements();
    const [dialogOpen, setDialogOpen] = useState(false);
    useEffect(() => {
        setDialogOpen(Boolean(announcements));
    }, [announcements]);

    return (
        <AnnouncementDialog
            open={dialogOpen}
            announcements={announcements}
            onAction={({ id }): Promise<void> => markAnnouncementRead(id)}
            onClose={() => setDialogOpen(false)}
        />
    );
};
