import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkEditIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon data-testid="wkEditIcon" {...props} viewBox="0 0 24 24">
        <path
            d="M0,16L0,16l0.6-5L10.8,0.9C11.3,0.3,12.1,0,12.9,0C14.6,0,16,1.4,16,3.1c-0.1,0.8-0.4,1.5-0.9,2.1
	L5,15.4L0,16z M1.8,11.8l-0.4,2.7l2.8-0.3L1.8,11.8z M10.8,2.6L10.8,2.6l-8.3,8.3l2.6,2.6l8.3-8.3C13.4,5.2,10.8,2.6,10.8,2.6z
	 M12.9,1.2c-0.4,0-0.8,0.2-1.2,0.5l2.6,2.6c0.3-0.3,0.4-0.8,0.4-1.2C14.7,2,13.9,1.2,12.9,1.2C12.9,1.2,12.9,1.2,12.9,1.2z"
        />
    </SvgIcon>
);

export default WkEditIcon;
