import { getLogger } from '@wk/elm-uui-common';
import { isError } from 'lodash';
import { Report, VisualDescriptor } from 'powerbi-client';
import { MESSAGES, SEARCH_CHART_WORD } from './constants';

const logger = getLogger('power-bi-utils');

export const getChart = async (report: Report): Promise<VisualDescriptor> => {
    try {
        const page = await report.getActivePage();
        if (!page) {
            logger.error(MESSAGES.NO_ACTIVE_PAGE);
            throw new Error(MESSAGES.NO_ACTIVE_PAGE);
        }

        const visuals = await page.getVisuals();
        if (visuals.length === 0) {
            logger.error(MESSAGES.NO_VISUALS);
            throw new Error(MESSAGES.NO_VISUALS);
        }

        const chart = visuals.find((visual) => visual.type.toLowerCase().includes(SEARCH_CHART_WORD));
        if (!chart) {
            logger.error(MESSAGES.NO_MATCHING_VISUAL);
            throw new Error(MESSAGES.NO_MATCHING_VISUAL);
        }

        return chart;
    } catch (error) {
        if (isError(error)) {
            logger.error(`Error occurred in getChart: ${error.message}`);
        }

        throw error;
    }
};
