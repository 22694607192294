import { cloneDeep, head, orderBy, sortBy } from 'lodash';
import { FavoriteEntity, FavoriteType } from '../../store/slices';

export type SortFavoritesProps = [keyof FavoriteEntity, 'asc' | 'desc'][];

const cloneTreeWith = (
    rootNode: FavoriteEntity | undefined,
    customizer: (node: FavoriteEntity) => FavoriteEntity,
): FavoriteEntity | undefined => {
    const cloneWith = (favorites?: FavoriteEntity[]): FavoriteEntity[] | undefined => {
        return favorites?.map((favorite) => {
            const customized = customizer(favorite);
            if (customized.type !== FavoriteType.Folder) {
                return customized;
            }

            return {
                ...customized,
                children: cloneWith(customized.children),
            };
        });
    };

    return rootNode && head(cloneWith([rootNode]));
};

const breadthFirstSearch = (
    rootNode: FavoriteEntity,
    condition: (node: FavoriteEntity) => boolean,
    callback: (node: FavoriteEntity) => void,
) => {
    let queue = [rootNode];
    while (queue.length !== 0) {
        const node = queue.shift();
        if (node === undefined) {
            break;
        }

        if (condition(node)) {
            callback(node);
        }

        if (node.type === FavoriteType.Folder && node.children && node.children.length !== 0) {
            queue = queue.concat(node.children);
        }
    }
};

export const getNodeByType = (root: FavoriteEntity, type: FavoriteType): FavoriteEntity => {
    const tree = cloneDeep(root);

    breadthFirstSearch(
        tree,
        (node) => node.type === FavoriteType.Folder && node.children !== undefined,
        (node) => {
            if (node.type !== FavoriteType.Folder) {
                return;
            }
            const folders = node.children?.filter((node) => node.type === type);
            if (folders?.length === 0) {
                delete node.children;
            } else {
                node.children = folders;
            }
        },
    );

    return tree;
};

export const sortFavorites = (
    rootNode: FavoriteEntity | undefined,
    props: [keyof FavoriteEntity, 'asc' | 'desc'][],
): FavoriteEntity | undefined => {
    if (!rootNode) {
        return undefined;
    }

    const iteratees = props.map(([iteratee]) => iteratee);
    const orders = props.map(([, order]) => order);

    return cloneTreeWith(rootNode, (favorite) => {
        if (favorite.type !== FavoriteType.Folder) {
            return favorite;
        }

        return {
            ...favorite,
            children: orderBy(favorite.children, iteratees, orders),
        };
    });
};

export const moveFavoriteOrFolder = (
    rootNode: FavoriteEntity,
    newNode: FavoriteEntity,
    oldNode: FavoriteEntity,
): FavoriteEntity => {
    const tree = cloneDeep(rootNode);

    breadthFirstSearch(
        tree,
        (parentNode) => parentNode.id === newNode.parentId || parentNode.id === oldNode.parentId,
        (parentNode) => {
            if (parentNode.type !== FavoriteType.Folder) {
                return;
            }

            if (!parentNode.children) {
                parentNode.children = [];
            }

            if (parentNode.id === newNode.parentId) {
                if (newNode.type === 'folder') {
                    parentNode.children.unshift(newNode);
                    const favorites = parentNode.children.filter(({ type }) => type !== FavoriteType.Folder);
                    parentNode.children = [
                        ...sortBy(
                            parentNode.children.filter(({ type }) => type === FavoriteType.Folder),
                            'name',
                        ),
                        ...favorites,
                    ];
                } else {
                    parentNode.children.push(newNode);
                }
            }

            if (parentNode.id === oldNode.parentId) {
                parentNode.children = parentNode.children.filter((node) => node.id !== oldNode.id);
            }
        },
    );

    return tree;
};

export const addFavoriteOrFolder = (rootNode: FavoriteEntity, node: FavoriteEntity): FavoriteEntity => {
    const tree = cloneDeep(rootNode);

    breadthFirstSearch(
        tree,
        (parentNode) => parentNode.id === node.parentId,
        (parentNode) => {
            if (parentNode.type !== FavoriteType.Folder) {
                return;
            }

            node.parentId = parentNode.id;
            parentNode.children = [...(parentNode.children || []), node];
        },
    );

    return tree;
};

export const getFavoriteOrFolder = (
    rootNode?: FavoriteEntity,
    id?: FavoriteEntity['id'],
): FavoriteEntity | undefined => {
    if (!rootNode || !id) {
        return undefined;
    }

    const tree = cloneDeep(rootNode);

    let foundNode: FavoriteEntity | undefined;
    // need == for condition, because sometimes number is passed as string (from selection)
    // TODO: map number ids to string at the very beginning?
    breadthFirstSearch(
        tree,
        (node) => node.id == id,
        (node) => (foundNode = node),
    );

    return foundNode;
};

export const updateFavoriteOrFolder = (rootNode: FavoriteEntity, node: FavoriteEntity): FavoriteEntity => {
    const tree = cloneDeep(rootNode);

    breadthFirstSearch(
        tree,
        (oldNode) => oldNode.id === node.id,
        (oldNode) => {
            for (const property in node) {
                oldNode[property] = node[property];
            }
        },
    );

    return tree;
};

export const removeFavoriteOrFolder = (rootNode: FavoriteEntity, node: FavoriteEntity): FavoriteEntity => {
    const tree = cloneDeep(rootNode);

    breadthFirstSearch(
        tree,
        (treeNode) => treeNode.id === node.parentId,
        (parentNode) => {
            if (parentNode.type !== FavoriteType.Folder) {
                return;
            }
            parentNode.children = parentNode.children?.filter((n) => n.id !== node.id);
        },
    );

    return tree;
};
