import { isEmpty, isUndefined } from 'lodash';
import React, { memo, FC, ReactElement } from 'react';
import { useAppSelector } from '../../../../store';
import { getAppResources, selectPageState } from '../../../../store/slices';

type NestedItemProps = {
    /**
     * Launches the online help window.
     */
    onLaunch: () => void;
};

type HelpLauncherProps = {
    /**
     * Mounts a toolbar item and passes states and control handlers.
     */
    nestedItem: (props: NestedItemProps) => ReactElement;
};

export const HelpLauncher: FC<HelpLauncherProps> = memo((props) => {
    const { nestedItem: NestedItem } = props;
    const url = useAppSelector(selectPageState('helpUrl'));
    const { legacyContextRoot } = useAppSelector(getAppResources);

    const handleOnLaunch = (): void => {
        window.open(
            `${legacyContextRoot}${url as string}`,
            'OnlineHelpWindow',
            'width=850, height=550, resizable=yes, location=yes',
        );
    };

    const hasUrl = !isEmpty(url) && !isUndefined(url);

    return hasUrl ? <NestedItem onLaunch={handleOnLaunch} /> : null;
});
HelpLauncher.displayName = 'HelpLauncher';
