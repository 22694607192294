/* eslint-disable max-lines-per-function */
import { Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getLogger } from '@wk/elm-uui-common';
import classNames from 'classnames';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { manuallyDecrementPromiseCounter } from 'react-promise-tracker';
import { useDebouncedCallback } from 'use-debounce';
import useIsMounted from '../../hooks/useIsMounted';
import { useRandomInteger } from '../../hooks/useRandomInteger';
import { useUUILocation } from '../../hooks/useUUILocation';
import useWindowHeight from '../../hooks/useWindowHeight';
import { useAppDispatch, useAppSelector } from '../../store';
import {
    getAppResources,
    getApplicationUrls,
    setActiveScreenId,
    markRefreshUUIForEntityAsProcessed,
} from '../../store/slices';
import { AppTheme } from '../app/appTheme';
import BottomDrawer from '../common/bottomDrawer';
import { ScreenMode } from '../common/types';
import { useItemScreenState, useParentItemScreenDispatch } from '../itemScreen/context/itemScreenContext';
import { getActiveParentAndSubTabMeta } from '../itemScreen/itemScreenHelpers';
import { IItemScreenState } from '../itemScreen/types';
import { dispatchInitializeListScreen } from './context/listScreenAsyncActions';
import { ListScreenProvider, useListScreenDispatch, useListScreenState } from './context/listScreenContext';
import { useRefreshList } from './context/listScreenHooks';
import EditsAppliedWidget from './editsAppliedWidget';
import ListScreenButtonRow from './listScreenButtonRow';
import ListScreenEditDialog from './listScreenEditDialog/listScreenEditDialog';
import { generatePagePostObject, isEditsApplied } from './listScreenHelpers';
import ListScreenSavedViewTabs from './listScreenSavedViewTabs';
import ListView from './listView';
import MultipleSelectionBar from './multipleSelectionBar';
import TableView from './tableView';
import { IParentItemInfo, IRefreshListOptions } from './types';

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        marginTop: 'auto',
        backgroundColor: theme.palette.background.secondary,
    },
    listScreenRoot: {
        overflow: 'auto',
    },

    listScreenView: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
}));

interface IListScreenProps {
    screenId?: number;
    screenUrl?: string;
    screenMetadataUrl?: string;
    mode?: ScreenMode;
    isEmbeddedList?: boolean;
    stickyTopPosition?: number;
    parentItemInfo?: IParentItemInfo;
    tabIndex?: number;
    parentTabIndex?: number;
}
const ListScreen: React.FC<IListScreenProps> = (props) => (
    <ListScreenProvider>
        <ListScreenComponent {...props} />
    </ListScreenProvider>
);
export const ListScreenComponent: React.FC<IListScreenProps> = ({
    screenId,
    screenUrl,
    screenMetadataUrl,
    mode,
    isEmbeddedList = false,
    stickyTopPosition,
    parentItemInfo,
    tabIndex,
    parentTabIndex,
}) => {
    const isMobileViewportSize = useMediaQuery((theme: Theme) => theme.breakpoints.down(4000));
    const listScreenState = useListScreenState();
    const bottomDrawerRef = useRef<HTMLDivElement>(null);
    const [totalStickyHeight, setTotalStickyHeight] = useState(stickyTopPosition ? stickyTopPosition : 0);
    const [originalHeight, setOriginalHeight] = useState(0);
    const listScreenRef = useRef<HTMLDivElement>(null);
    const windowHeight = useWindowHeight(100);
    const itemScreenState = useItemScreenState();
    const parentItemScreenDispatch = useParentItemScreenDispatch();
    const classes = useStyles();
    useInitializeListScreen({
        screenId,
        screenUrl,
        screenMetadataUrl,
        mode,
        isEmbeddedList,
        parentItemInfo,
        tabIndex,
        parentTabIndex,
    });
    useMessageBusToRefreshList();

    const { metadata, listData, baselinePageData } = listScreenState;
    const screenIsLoaded = metadata != null && listData != null;
    const pageNumber = listScreenState.listData?.page?.pageNumber;

    useEffect(() => {
        // this tracks when paging occurs, and scrolls to the top
        if (listScreenRef.current) {
            listScreenRef.current.scrollTop = 0;
        }
    }, [pageNumber]);

    const msoBarVisible = listScreenState.checkedRows.length > 0;
    let editsApplied = false;

    if (screenIsLoaded) {
        editsApplied = isEditsApplied(baselinePageData, metadata, listData.page);
    }

    useEffect(() => {
        if (screenIsLoaded && parentItemScreenDispatch) {
            parentItemScreenDispatch({
                type: 'SetTabEmbeddedListMetadata',
                metadata: {
                    entityName: listScreenState.metadata!.entityName,
                    isDocumentOrEmailTab: listScreenState.metadata!.isDocumentEntity,
                    entityTypeId: listScreenState.metadata?.entityId?.toString(),
                    folderId:
                        listScreenState.breadCrumbs.length > 1 &&
                        listScreenState.breadCrumbs[listScreenState.breadCrumbs.length - 1].folderId
                            ? parseInt(listScreenState.breadCrumbs[listScreenState.breadCrumbs.length - 1].folderId!)
                            : undefined,
                    breadCrumbs: listScreenState.breadCrumbs.map((b) => b.folderName),
                },
            });
        }
    }, [listScreenState.breadCrumbs, listScreenState.metadata, parentItemScreenDispatch, screenIsLoaded]);

    // todo: comment for main list screen
    useEffect(() => {
        const uuiAppBar = document.getElementById('uuiappbar');
        if (screenIsLoaded && uuiAppBar && !isEmbeddedList) {
            if (bottomDrawerRef.current) {
                const appBarHeight = uuiAppBar.getBoundingClientRect().height;
                const bottomDrawerHeight = bottomDrawerRef.current.offsetHeight;
                const calculatedListHeight = windowHeight - (appBarHeight + bottomDrawerHeight);
                listScreenRef.current!.style.setProperty('height', `${calculatedListHeight}px`, 'important');
            }
        }
    }, [screenIsLoaded, isMobileViewportSize, windowHeight, isEmbeddedList, msoBarVisible, editsApplied]);

    // todo: comment for itemscreen
    useLayoutEffect(() => {
        const uuiAppBar = document.getElementById('uuiappbar');
        const itemScreenBox = document.getElementById('itemscreenView');
        const headerBar = document.getElementById('itemscreenheader');

        if (screenIsLoaded && headerBar && itemScreenBox && isEmbeddedList) {
            const headerBarHeight = headerBar.getBoundingClientRect().height;
            let maxHeight = windowHeight - headerBarHeight;
            maxHeight = bottomDrawerRef.current?.offsetHeight
                ? maxHeight - bottomDrawerRef.current?.offsetHeight
                : maxHeight;

            if (uuiAppBar) {
                const appBarHeight = uuiAppBar.getBoundingClientRect().height;
                maxHeight = maxHeight - appBarHeight;
            }
            itemScreenBox.style.setProperty('max-height', `${maxHeight}px`, 'important');

            if (
                uuiAppBar &&
                matchesTrackedIndexes(itemScreenState, parentTabIndex, tabIndex) &&
                (!originalHeight ||
                    originalHeight !== windowHeight - (uuiAppBar.getBoundingClientRect().height + headerBarHeight))
            ) {
                const appBarHeight = uuiAppBar.getBoundingClientRect().height;
                const calculatedHeight = windowHeight - (appBarHeight + headerBarHeight);
                setOriginalHeight(calculatedHeight);
                return;
            }

            if (originalHeight && matchesTrackedIndexes(itemScreenState, parentTabIndex, tabIndex)) {
                if (
                    (bottomDrawerRef.current?.offsetHeight &&
                        itemScreenBox.scrollHeight > itemScreenBox.clientHeight) ||
                    itemScreenBox.getBoundingClientRect().bottom > bottomDrawerRef.current!.getBoundingClientRect().top
                ) {
                    const drawerRect = bottomDrawerRef.current!.getBoundingClientRect();
                    const calculatedHeight = originalHeight - (drawerRect.height + 3); // +3 grace pixels

                    itemScreenBox.style.setProperty('height', `${calculatedHeight}px`, 'important');
                } else if (!bottomDrawerRef.current?.offsetHeight) {
                    itemScreenBox.style.removeProperty('height');
                }
            } else {
                setOriginalHeight(0);
            }
        }
    }, [
        isEmbeddedList,
        screenIsLoaded,
        listScreenState.metadata,
        originalHeight,
        msoBarVisible,
        editsApplied,
        itemScreenState.activeTab,
        isMobileViewportSize,
        windowHeight,
        parentTabIndex,
        tabIndex,
        itemScreenState,
    ]);

    if (metadata == null || listData == null) {
        return null;
    }

    const onScrollHandler = () => {
        // Remove any tooltips currently displayed
        const tooltips = document.getElementsByClassName('MuiTooltip-popper');
        if (tooltips.length > 0) {
            const tooltip = tooltips.item(0) as HTMLElement;
            if (!tooltip.classList.contains('_removed')) {
                tooltip.style.display = 'none';
                tooltip.classList.add('_removed');
            }
        }
    };
    const completedButtonBarRender = () => {
        const currStickyHeight = stickyTopPosition ? stickyTopPosition : 0;
        let buttonBarHeight = 0;
        if (listScreenRef.current && listScreenRef.current.querySelector('.buttonBar')) {
            buttonBarHeight = listScreenRef.current.querySelector('.buttonBar')!.getBoundingClientRect().height;
        }
        setTotalStickyHeight(currStickyHeight + buttonBarHeight);
    };
    return (
        <>
            <div
                ref={listScreenRef}
                onScroll={onScrollHandler}
                data-testid="listScreenComponent"
                className={
                    !isEmbeddedList
                        ? classNames(classes.listScreenRoot, classes.listScreenView)
                        : classes.listScreenView
                }>
                {!isEmbeddedList && (
                    <>
                        <ListScreenButtonRow />
                        <ListScreenSavedViewTabs />
                    </>
                )}
                {isMobileViewportSize ? <ListView stickyTopPosition={totalStickyHeight} /> : <TableView />}
                <ListScreenBottomDrawer ref={bottomDrawerRef} />
                <ListScreenEditDialog completedRender={completedButtonBarRender} />

                {
                    // TODO: fix stories for visual tests
                    /* <PageFooter classes={{ root: classes.root }} />*/
                }
            </div>
        </>
    );
};

export const ListScreenBottomDrawer = React.forwardRef<HTMLDivElement, { children?: React.ReactNode }>(
    (_, ref: React.Ref<HTMLDivElement>) => {
        return (
            <BottomDrawer ref={ref}>
                <MultipleSelectionBar />
                <EditsAppliedWidget />
            </BottomDrawer>
        );
    },
);

ListScreenBottomDrawer.displayName = 'ListScreenBottomDrawer';

const useInitializeListScreen = ({
    screenId,
    screenUrl,
    screenMetadataUrl,
    mode,
    isEmbeddedList,
    parentItemInfo,
    tabIndex,
    parentTabIndex,
}: IListScreenProps) => {
    const dispatch = useAppDispatch();
    const listScreenState = useListScreenState();
    const listScreenDispatch = useListScreenDispatch();
    const itemScreenState = useItemScreenState();
    const isMounted = useIsMounted();
    const applicationUrls = useAppSelector(getApplicationUrls);
    const apiPath = Props['apiContextRoot'] + Props['apiContextPath'];
    const location = useUUILocation();
    const [initializeCalled, setInitializedCalled] = useState(false);
    const [isEmbeddedListLoaded, setIsEmbeddedListLoaded] = useState(true); // skip the 1st load
    const appResources = useAppSelector(getAppResources);

    const refreshList = useRefreshList();
    if (screenId && !screenUrl) {
        screenUrl =
            apiPath +
            applicationUrls.listScreenPath.replace('{screenId}', screenId.toString()).replace('{mode}', mode || '');
    } else {
        // for foldering, check the dataUrl from state because state should take preference over props.
        screenUrl = listScreenState.dataUrl || screenUrl!;
    }
    if (screenId && !screenMetadataUrl) {
        screenMetadataUrl = apiPath + applicationUrls.listScreenMetaPath.replace('{screenId}', screenId.toString());
    }
    // hack to do deep equality check for parentItemInfo so initialize doesn't get called every rerender.
    // see https://twitter.com/dan_abramov/status/1104414272753487872?s=20
    const parentItemInfoJSON = parentItemInfo ? JSON.stringify(parentItemInfo) : undefined;
    useEffect(() => {
        dispatch(setActiveScreenId({ screenId: screenId ?? 0 }));
    });

    useEffect(() => {
        if (!initializeCalled) {
            setInitializedCalled(true);
            dispatchInitializeListScreen(
                screenMetadataUrl!,
                screenUrl!,
                isEmbeddedList || false,
                mode,
                listScreenDispatch,
                dispatch,
                isMounted,
                parentItemInfoJSON ? JSON.parse(parentItemInfoJSON) : undefined,
                location.state?.listScreen,
                appResources,
            );
        } else if (
            isEmbeddedList &&
            matchesTrackedIndexes(itemScreenState, parentTabIndex, tabIndex) &&
            listScreenState.listData
        ) {
            if (!isEmbeddedListLoaded) {
                setIsEmbeddedListLoaded(true);
                const pageInfo = generatePagePostObject(listScreenState.listData.page);
                refreshList({ postObject: pageInfo, fetchAll: true });
            }
        } else if (isEmbeddedList && !matchesTrackedIndexes(itemScreenState, parentTabIndex, tabIndex)) {
            setIsEmbeddedListLoaded(false);
        }
    }, [
        initializeCalled,
        isEmbeddedList,
        listScreenDispatch,
        dispatch,
        screenMetadataUrl,
        screenUrl,
        isMounted,
        mode,
        parentItemInfoJSON,
        location.state?.listScreen,
        appResources,
        tabIndex,
        listScreenState.listData,
        refreshList,
        isEmbeddedListLoaded,
        parentTabIndex,
        itemScreenState,
    ]);
};

export const matchesTrackedIndexes = (
    itemScreenState: IItemScreenState,
    parentTabIndex?: number,
    tabIndex?: number,
): boolean => {
    const [trackedMainTab] = getActiveParentAndSubTabMeta(itemScreenState);
    let isMatch = false;

    // if the tracked subTab exists, we are in a sub tab
    // then if we match indexes across the board, set matches to true
    if (trackedMainTab) {
        if (
            trackedMainTab.activeSubTab !== undefined &&
            trackedMainTab.index === parentTabIndex &&
            trackedMainTab.activeSubTab === tabIndex
        ) {
            isMatch = true;
        } else if (trackedMainTab.activeSubTab === undefined && trackedMainTab.index === tabIndex && !parentTabIndex) {
            // else we are in a main tab. match ONLY the main index and the assigned tab index, and ensure no assigned parent is present
            isMatch = true;
        }
    }

    return isMatch;
};

/**
 * This is for when the message bus sends a RefreshUUIForEntity action
 * This will watch for that and check to see if we match the entityId with a row
 * that has the document id sent in the action OR if no entityInstanceId is provided, then it was an add/remove
 * If so, refresh the list. For example, OC 1 checks out a document, OC 2 should update the row to show it's checked out.
 **/
const useMessageBusToRefreshList = () => {
    const refreshUUIForEntity = useAppSelector((state) => state.ui.messageBus?.refreshUUIForEntity);
    const refreshUUIForEntityIsProcessed = useAppSelector(
        (state) => state.ui.messageBus?.refreshUUIForEntityIsProcessed,
    );
    const listScreenState = useListScreenState();
    const dispatch = useAppDispatch();
    const refreshList = useRefreshList();
    const uuiConfig = useAppSelector((state) => state.uuiConfiguration);
    const minDebounceWait = uuiConfig.minRefreshUUIDebounceWaitTime || 3000;
    const maxDebounceWait = uuiConfig.maxRefreshUUIDebounceWaitTime || 8000;
    const minThrottleWait = uuiConfig.minRefreshUUIThrottleWaitTime || 6000;
    const maxThrottleWait = uuiConfig.maxRefreshUUIThrottleWaitTime || 16000;
    const debounceWait = useRandomInteger(minDebounceWait, maxDebounceWait);
    const throttleWait = useRandomInteger(minThrottleWait, maxThrottleWait);
    const [currentRefreshUUIForEntityKey, setCurrentRefreshUUIForEntityKey] = useState<string | undefined>();
    const logger = () => getLogger('RefreshUUIForEntity');

    const refreshListWithLogging = useCallback(
        async (options?: IRefreshListOptions) => {
            logger().info('RefreshUUIForEntity is triggering a refreshList: ' + format(new Date(), 'hh:mm:ss.SS a'));
            await refreshList(options);
        },
        [refreshList],
    );

    const debouncedRefreshList = useDebouncedCallback(refreshListWithLogging, debounceWait, {
        // leading: true makes the first request NOT debounced but fire immediately
        leading: true,
        // make sure we fire refreshList at least every throttleWait milliseconds
        maxWait: throttleWait,
    });

    const setKeyIfListShouldBeRefreshed = () => {
        if (refreshUUIForEntity && !refreshUUIForEntityIsProcessed) {
            const entityIdsMatch = listScreenState.metadata?.entityId?.toString() === refreshUUIForEntity.entityTypeId;
            const listHasNoParentInfo = listScreenState.parentItemInfo === undefined;

            const parentInfoMatches =
                refreshUUIForEntity.entityInstanceId === undefined &&
                listScreenState.parentItemInfo !== undefined &&
                // either this is a direct parent info match,
                ((listScreenState.parentItemInfo.parentEntityId ===
                    refreshUUIForEntity.parentItemInfo?.parentEntityId &&
                    listScreenState.parentItemInfo.parentInstanceId ===
                        refreshUUIForEntity.parentItemInfo?.parentInstanceId) ||
                    // or we are refreshing a subfolder, and the folder id matches the parent instance id
                    (listScreenState.parentItemInfo.parentEntityId === refreshUUIForEntity.entityTypeId &&
                        listScreenState.parentItemInfo.parentInstanceId === refreshUUIForEntity.folderId));
            const entityInstanceIsOnThisList =
                listScreenState.listData?.list.find(
                    (row) => row.id.toString() === refreshUUIForEntity.entityInstanceId,
                ) !== undefined;

            if (
                entityIdsMatch &&
                (listHasNoParentInfo || parentInfoMatches || entityInstanceIsOnThisList) &&
                currentRefreshUUIForEntityKey !== refreshUUIForEntity.key
            ) {
                // new refreshList should occur, so update the current key
                // so the dependency list of the useEffect below triggers to refresh the list.
                setCurrentRefreshUUIForEntityKey(refreshUUIForEntity.key);
            }
        }
    };

    setKeyIfListShouldBeRefreshed();

    // this is just an effect to log some initial values
    useEffect(() => {
        logger().info('RefreshUUIForEntity debounce wait time for this instance is: ' + debounceWait);
        logger().info('RefreshUUIForEntity throttle wait time for this instance is: ' + throttleWait);
    }, [debounceWait, throttleWait]);

    // this effect watches when the window becomes activated and if there are pending refreshes, then flush them
    useEffect(() => {
        const activate = () => {
            logger().info('OC browser activated: ' + format(new Date(), 'hh:mm:ss.SS a'));

            if (debouncedRefreshList.isPending()) {
                logger().info(
                    'OC Browser has been activated and there are pending RefreshUUIForEntity requests. Flushing immediately.',
                );
                debouncedRefreshList.flush();
            }
        };

        window.addEventListener('mousedown', activate, true);

        return () => {
            window.removeEventListener('mousedown', activate, true);
        };
    }, [debouncedRefreshList]);

    useEffect(
        () => {
            let isCancelled = false;
            const getIsCancelled = () => isCancelled;

            // since these are debounced requests, this tracks if this particular request is actually executed.
            let requestIsActive = false;
            const setRequestIsActive = (value: boolean) => {
                requestIsActive = value;
            };

            if (currentRefreshUUIForEntityKey) {
                logger().info('RefreshUUIForEntity key: ' + currentRefreshUUIForEntityKey);
                debouncedRefreshList({ setRequestIsActive, getIsCancelled })?.then(() => {
                    if (isCancelled) {
                        logger().info(
                            'RefreshUUIForEntity encountered a race condition and cancelled a request for key: ' +
                                currentRefreshUUIForEntityKey,
                        );
                    } else {
                        dispatch(markRefreshUUIForEntityAsProcessed());
                    }
                });
            }
            // this runs before the effect is run to clean up the previous effect. This prevents
            // race conditions from happening.
            return () => {
                if (requestIsActive) {
                    logger().info('Active request was cancelled. Decrementing promise counter');
                    requestIsActive = false;
                    manuallyDecrementPromiseCounter();
                }
                isCancelled = true;
            };
        },
        // this dependency list is VERY sensitive. We cannot have any extra calls to this effect
        // or else promises will get cancelled that we don't want to get cancelled. Make sure we keep
        // all useEffect dependency arrays around this very tight.
        [debouncedRefreshList, dispatch, currentRefreshUUIForEntityKey],
    );
};

export default ListScreen;
