/* eslint-disable max-lines-per-function */
import { getLogger } from '@wk/elm-uui-common';
import {
    CHMessagingScope,
    EventType,
    IDialog,
    IItemScreen,
    IOverlayDialog,
    IQueueItem,
    IQueueServiceInterface,
    IRefreshUUIForEntity,
    IToast,
    QueueItemStatus,
    ScreenName,
    unsubscribe,
} from '@wk/elm-uui-context-handler';
import { format } from 'date-fns';
import { uniqueId, groupBy } from 'lodash';
import { useEffect } from 'react';
import {
    manuallyDecrementPromiseCounter,
    manuallyIncrementPromiseCounter,
    manuallyResetPromiseCounter,
} from 'react-promise-tracker';
import { IAlertDialog, IAlertDialogButton } from '../components/common/alertDialog';
import { clGetQueue, clSubscribe } from '../components/contextLayerService/contextLayerService';
import { messageBusDispatch } from '../components/contextLayerService/messageBusService';
import { UUIMessageBusAction } from '../components/contextLayerService/types';
import { IItemScreenProps } from '../components/itemScreen/itemScreen';
import { IContextLayerConfig, NotificationType } from '../reducers/types';
import { AppDispatch, useAppDispatch, useAppSelector } from '../store';
import {
    getAppResources,
    openDialog,
    openItemScreenDialog,
    openOverlayDialog,
    refreshUuiForEntity,
    setUploadHistoryItems,
    showNotification,
} from '../store/slices';

export const useContextLayerSubscriptions = (): void => {
    const dispatch = useAppDispatch();
    const contextLayerConfig = useAppSelector((state) => state.app.contextLayerConfig!);
    const { logoutUrl } = useAppSelector(getAppResources);

    useEffect(() => {
        const toastSubId = subscribeToToastMessages(dispatch);
        const dialogSubId = subscribeToDialogs(dispatch);
        const itemScreenSubId = subscribeToItemScreens(dispatch, contextLayerConfig);
        const refreshUUIForEntitySubId = subscribeToRefreshUUIForEntity(dispatch);
        const overlaySubId = subscribeToOverlays(dispatch);
        const globalSpinnerSubId = subscribeToGlobalSpinner();

        let queuedId: string;
        let startedId: string;
        let completedId: string;
        let failedId: string;
        let processingId: string;

        clGetQueue().then((queueManager: IQueueServiceInterface) => {
            if (!queueManager) {
                return;
            }
            failedId = queueManager.elementsFailed
                .subscribe((queueItems: IQueueItem[]) => {
                    dispatch(setUploadHistoryItems({ status: QueueItemStatus.FAILED, uploadHistoryItems: queueItems }));
                })
                .toString();

            queuedId = queueManager.elementsQueued
                .subscribe((queueItems: IQueueItem[]) => {
                    dispatch(setUploadHistoryItems({ status: QueueItemStatus.NEW, uploadHistoryItems: queueItems }));
                })
                .toString();

            processingId = queueManager.elementsProcessing
                .subscribe((queueItems: IQueueItem[]) => {
                    dispatch(
                        setUploadHistoryItems({ status: QueueItemStatus.PROCESSING, uploadHistoryItems: queueItems }),
                    );
                })
                .toString();

            startedId = queueManager.elementsStarted
                .subscribe((queueItems: IQueueItem[]) => {
                    const data = groupBy(queueItems, 'status');

                    if (data[QueueItemStatus.STARTED]) {
                        dispatch(
                            setUploadHistoryItems({
                                status: QueueItemStatus.STARTED,
                                uploadHistoryItems: data[QueueItemStatus.STARTED],
                            }),
                        );
                    }

                    if (data[QueueItemStatus.PENDING]) {
                        dispatch(
                            setUploadHistoryItems({
                                status: QueueItemStatus.PENDING,
                                uploadHistoryItems: data[QueueItemStatus.PENDING],
                            }),
                        );
                    }
                })
                .toString();

            completedId = queueManager.elementsCompleted
                .subscribe((queueItems: IQueueItem[]) => {
                    dispatch(
                        setUploadHistoryItems({ status: QueueItemStatus.COMPLETED, uploadHistoryItems: queueItems }),
                    );
                })
                .toString();
        });

        return () => {
            unsubscribe(toastSubId);
            unsubscribe(dialogSubId);
            unsubscribe(itemScreenSubId);
            unsubscribe(queuedId);
            unsubscribe(startedId);
            unsubscribe(completedId);
            unsubscribe(failedId);
            unsubscribe(processingId);
            unsubscribe(refreshUUIForEntitySubId);
            unsubscribe(overlaySubId);
            unsubscribe(globalSpinnerSubId);
        };
    }, [contextLayerConfig, logoutUrl, dispatch]);
};

const subscribeToRefreshUUIForEntity = (dispatch: AppDispatch): string => {
    return clSubscribe(EventType.REFRESH_UUI_FOR_ENTITY, (refreshUUIForEntity: IRefreshUUIForEntity) => {
        getLogger('RefreshUUIForEntity').info(
            'Refresh UUI For Entity Requested: ' + format(new Date(), 'hh:mm:ss.SS a'),
        );
        dispatch(refreshUuiForEntity({ refreshUUIForEntity }));
    });
};

const subscribeToOverlays = (dispatch: AppDispatch): string => {
    return clSubscribe(EventType.OVERLAY, (overlay: IOverlayDialog) => {
        manuallyResetPromiseCounter();
        dispatch(openOverlayDialog({ dialog: overlay }));
    });
};

const subscribeToGlobalSpinner = (): string => {
    return clSubscribe(EventType.GLOBAL_SPINNER, (isOpen: boolean) => {
        if (isOpen) {
            manuallyIncrementPromiseCounter();
        } else {
            manuallyDecrementPromiseCounter();
        }
    });
};

const subscribeToToastMessages = (dispatch: AppDispatch): string => {
    return clSubscribe(EventType.TOAST, (toast: IToast, restrictToCurrInstance = false) => {
        const uniqueKey = uniqueId(Date.now().toString());
        const busAction = getShowToastBusAction(toast, uniqueKey);
        let message = '';
        if (typeof toast.toastMessage === 'string') {
            message = toast.toastMessage;
        } else {
            message = JSON.stringify(toast.toastMessage);
        }

        if (!restrictToCurrInstance) {
            messageBusDispatch(busAction);
        }

        dispatch(
            showNotification({
                notification: {
                    key: uniqueKey,
                    message: message,
                    options: {
                        variant: (['default', 'information'].includes(toast.type)
                            ? 'info'
                            : toast.type) as NotificationType,
                        persist: !toast.type.includes('success'),
                    },
                },
            }),
        );
    });
};

export const getShowToastBusAction = (toast: IToast, uniqueKey: string): UUIMessageBusAction => {
    const toastType = toast.type.toString();
    let message = '';
    if (typeof toast.toastMessage === 'string') {
        message = toast.toastMessage;
    } else {
        message = JSON.stringify(toast.toastMessage);
    }
    const toastObjString = JSON.stringify({ toastType: toastType, key: uniqueKey, message: message });
    const messageBusObj: UUIMessageBusAction = {
        type: 'ShowToast',
        scope: CHMessagingScope.OtherInstances,
        message: toastObjString,
        options: {
            variant: (['default', 'information'].includes(toastType) ? 'info' : toastType) as NotificationType,
            persist: !toastType.includes('success'),
        },
    };
    return messageBusObj;
};

const subscribeToDialogs = (dispatch: AppDispatch): string => {
    return clSubscribe(EventType.DIALOG, (dialog: IDialog) => {
        const alertDialogButtons: IAlertDialogButton[] = dialog.buttons.map((button) => ({
            label: button.label,
            onClick: button.callback,
        }));
        const alertDialog: IAlertDialog = {
            id: uniqueId('dialog_'),
            title: dialog.title,
            message: dialog.message,
            buttons: alertDialogButtons,
            onClose: dialog.onCloseCallback,
        };
        // hide global spinner if there is one.
        manuallyResetPromiseCounter();
        dispatch(openDialog({ dialog: alertDialog }));
    });
};

const subscribeToItemScreens = (dispatch: AppDispatch, contextLayerConfig: IContextLayerConfig): string => {
    return clSubscribe(EventType.ITEMSCREEN, (itemScreen: IItemScreen) => {
        const itemScreenProps = convertSubscribeArgToItemScreenProps(itemScreen, contextLayerConfig);
        // hide global spinner if there is one.
        manuallyResetPromiseCounter();
        dispatch(openItemScreenDialog({ itemScreen: itemScreenProps }));
    });
};

export const convertSubscribeArgToItemScreenProps = (
    itemScreen: IItemScreen,
    contextLayerConfig: IContextLayerConfig,
): IItemScreenProps => {
    let screenId = -1;
    switch (itemScreen.screenName) {
        case ScreenName.QUICKFILE_ADD_DOCUMENT:
            screenId = contextLayerConfig.quickFileAddDocumentScreenId;
            break;
        case ScreenName.CHECKIN_DOCUMENT:
            screenId = contextLayerConfig.checkInScreenId;
            break;
    }
    if (!screenId || screenId === -1) {
        throw `No cooresponding screen id found for context layer item screen request: ${itemScreen.screenName}`;
    }

    return {
        mode: 'add',
        screenId: screenId,
        popupTitle: itemScreen.title,
        initialValues: itemScreen.payload,
        contextLayerInfo: itemScreen.context
            ? {
                  associatedEntityId: itemScreen.context.associatedEntityId,
                  associatedEntityType: itemScreen.context.associatedEntityType,
                  associatedEntityTypeId: itemScreen.context.associatedEntityTypeId!,
                  associatedEntityName: itemScreen.context.associatedEntityName!,
                  folderId: itemScreen.context.folderId,
                  folderArr: itemScreen.context.folderArr,
                  documentId: itemScreen.context.documentId,
                  isQuickFile: itemScreen.context.isQuickFile,
                  isDragAndDrop: itemScreen.context.isDragAndDrop,
                  filePaths: itemScreen.context.filePaths,
              }
            : undefined,
    };
};

export default useContextLayerSubscriptions;
