import { isEqual } from 'lodash';
import { apiFetch } from '../../utils/fetchUtils';
import { ScreenMode, TargetScreenType } from '../common/types';
// eslint-disable-next-line css-modules/no-unused-class
import css from './listViewItem.module.scss';
import {
    IListScreenColumn,
    IListScreenMetadata,
    IListScreenPageData,
    IListScreenPostObject,
    IListScreenRow,
    ISavedView,
    ISavedViewSaveResponse,
    ISelectedSavedView,
    IResetUserDefaultResponse,
    IRemoveTabResponse,
    IListScreenOperation,
    IListScreenDataElement,
    IListScreenState,
} from './types';

export const getColumnHeaderDisplayName = (column: IListScreenColumn): string => {
    if (column.iconName !== '') {
        return '';
    }
    return column.displayName ? column.displayName : column.attributeName;
};

export const getColumnKey = (column: IListScreenColumn): string => {
    return column.name;
};

export const getCellDisplayValue = (row: IListScreenRow, column: IListScreenColumn): string => {
    const columnKey = getColumnKey(column);
    if (column.iconName && column.iconName.length > 0) {
        return column.iconName;
    }
    if (columnKey === 'id') {
        return row.id.toString();
    }
    const cell = getCell(row, columnKey);
    return cell ? cell.displayValue.replace(/\n/g, ' ').replace(/\s/g, ' ') : '';
};

export const getCell = (row: IListScreenRow, columnKey: string): IListScreenDataElement | undefined => {
    return row[columnKey] as IListScreenDataElement;
};

export const isFieldALink = (row: IListScreenRow, column: IListScreenColumn): boolean => {
    const url = getListLinkPath(row, column);
    const urlExists = url && url.trim().length > 0 ? true : false;
    const columnKey = getColumnKey(column);
    if (columnKey === 'id') {
        return row.link! && urlExists;
    }
    const cell = getCell(row, columnKey);
    return cell !== undefined && cell.link !== undefined && urlExists;
};

export const isFieldAShowDocumentLink = (row: IListScreenRow, column: IListScreenColumn): boolean => {
    const cell = getCell(row, getColumnKey(column));
    return cell !== undefined && !!cell.isShowDocumentLink;
};

export const getListLinkPath = (row: IListScreenRow, column: IListScreenColumn): string => {
    const columnKey = getColumnKey(column);
    return columnKey === 'id' ? row.url! : getCell(row, getColumnKey(column))!.linkUrl!;
};

export const isFolderRow = (metadata: IListScreenMetadata, row: IListScreenRow): boolean => {
    return !!metadata.isDocumentEntity && !!row._metadata.isFolder;
};

export const isDocumentRow = (metadata: IListScreenMetadata, row: IListScreenRow): boolean => {
    return !!metadata.isDocumentEntity && !row._metadata.isFolder;
};

export const generatePagePostObject = (
    currentPageObject: IListScreenPageData,
    pageObjectPropertiesToMerge: Partial<IListScreenPageData> = {},
): IListScreenPostObject => {
    return {
        page: {
            ...currentPageObject,
            ...pageObjectPropertiesToMerge,
        },
    };
};

export const isEditsApplied = (
    originalPageData: IListScreenPageData | undefined,
    currentMetadata: IListScreenMetadata,
    currentPageData: IListScreenPageData,
): boolean => {
    if (!originalPageData) {
        return false;
    }
    // for now, we don't muck with icon columns, but they get in the way,
    // so filter out icon columns when checking if edits are applied
    const iconScreenFields = currentMetadata.columns.filter((c) => c.iconName.length > 0);
    let originalColumnDataWithoutIcons =
        originalPageData && originalPageData['column-data'] ? originalPageData['column-data'].split('|') : [];
    let currentColumnDataWithoutIcons =
        currentPageData && currentPageData['column-data'] ? currentPageData['column-data'].split('|') : [];
    iconScreenFields.map((iconScreenField) => {
        originalColumnDataWithoutIcons = originalColumnDataWithoutIcons.filter(
            (item) => item !== 'id:' + iconScreenField.screenFieldId,
        );
        currentColumnDataWithoutIcons = currentColumnDataWithoutIcons.filter(
            (item) => item !== 'id:' + iconScreenField.screenFieldId,
        );
    });
    const originalColumnDataWithoutIconsString = originalColumnDataWithoutIcons.join('|');
    const currentColumnDataWithoutIconsString = currentColumnDataWithoutIcons.join('|');
    return (
        originalColumnDataWithoutIconsString !== currentColumnDataWithoutIconsString ||
        originalPageData.sortInfo !== currentPageData.sortInfo ||
        !isEqual(originalPageData.filters, currentPageData.filters)
    );
};

export const getListPageIconColumnOperations = (
    row: IListScreenRow,
    columns: IListScreenColumn[],
): IListScreenOperation[] => {
    const iconColumns = columns.filter((c) => c.iconName.length > 0);
    const newOperationsList = [] as IListScreenOperation[];
    iconColumns.map((iconCol, index) => {
        const currentRow = row[iconCol.name] as IListScreenDataElement;
        const newOp = {} as IListScreenOperation;
        if (currentRow.link) {
            newOp.displayName = currentRow.targetDescription || 'default';
            newOp.iconName = 'MK Icon';
            newOp.linkUrl = currentRow.linkUrl;
            newOp.name = currentRow.targetDescription!;
            newOp.isDisplayOnAdd = currentRow.displayLinkOnAdd!;
            newOp.isDisplayOnEdit = currentRow.displayLinkOnEdit!;
            newOp.isDisplayOnShow = currentRow.displayLinkOnShow!;
            newOp.id = index;
            newOperationsList.push(newOp);
        }
    });
    return newOperationsList;
};

export const saveCurrentView = (
    savedView: ISavedView,
    screenId: number,
    listScreenUrl: string | undefined,
    applicationSavedViewUrl: string,
): Promise<ISavedViewSaveResponse> => {
    const apiPath = Props['apiContextRoot'] + Props['apiContextPath'];
    const listScreenQuerystring =
        listScreenUrl !== undefined ? listScreenUrl.substring(listScreenUrl.lastIndexOf('?') + 1) : '';
    const saveCurrentViewUrl =
        apiPath +
        applicationSavedViewUrl
            .replace('{screenId}', screenId.toString())
            .replace('{queryString}', listScreenQuerystring)
            .trim();
    return apiFetch<ISavedViewSaveResponse>(saveCurrentViewUrl, savedView);
};

export const resetUserDefault = (
    screenId: number,
    applicationResetUserDefaultUrl: string,
    listScreenUrl: string | undefined,
): Promise<IResetUserDefaultResponse> => {
    const apiPath = Props['apiContextRoot'] + Props['apiContextPath'];
    const listScreenQuerystring =
        listScreenUrl !== undefined ? listScreenUrl.substring(listScreenUrl.lastIndexOf('?') + 1) : '';
    const resetUserDefaultUrl =
        apiPath +
        applicationResetUserDefaultUrl
            .replace('{screenId}', screenId.toString())
            .replace('{queryString}', listScreenQuerystring)
            .trim();
    return apiFetch<IResetUserDefaultResponse>(resetUserDefaultUrl, {});
};

export const removeSavedViewAsync = (
    screenId: number,
    savedViewId: number,
    applicationDeleteSavedViewUrl: string,
): Promise<IRemoveTabResponse> => {
    const apiPath = Props['apiContextRoot'] + Props['apiContextPath'];

    const removeSavedViewUrl =
        apiPath +
        applicationDeleteSavedViewUrl
            .replace('{screenId}', screenId.toString())
            .replace('{savedSearchId}', savedViewId.toString());

    return apiFetch<IRemoveTabResponse>(removeSavedViewUrl, {});
};

export const getReplacementTabs = (metadata: IListScreenMetadata): ISavedView[] => {
    const userDefaultExists = metadata.savedViews.filter((sv) => sv.isUserDefault);
    return metadata.savedViews
        .filter(
            (sv) =>
                (sv.isEditable && !sv.isSystemDefault) ||
                sv.isUserDefault ||
                (userDefaultExists.length == 0 && sv.isSystemDefault),
        )
        .sort((sv1, sv2) => {
            const sv1Name = sv1.isUserDefault || sv1.isSystemDefault ? metadata.screenDisplayName : sv1.name;
            const sv2Name = sv2.isUserDefault || sv2.isSystemDefault ? metadata.screenDisplayName : sv2.name;
            return sv1Name.localeCompare(sv2Name);
        });
};

export const getIsListRowBreadCrumbVisible = (listScreenState: IListScreenState): boolean => {
    return (
        listScreenState.metadata?.isDocumentEntity === true &&
        listScreenState.listData!.list.find(
            (row) => row._metadata.folderPath !== undefined && row._metadata.folderPath.length > 0,
        ) !== undefined
    );
};

export const getSavedViewTabName = (
    savedView: ISavedView | ISelectedSavedView,
    metadata: IListScreenMetadata,
): string => {
    return savedView.isSystemDefault || savedView.isUserDefault ? metadata.screenDisplayName : savedView.name;
};

export const getBulkOperations = (
    metadata: IListScreenMetadata | undefined,
    mode: ScreenMode | undefined,
): IListScreenOperation[] => {
    if (!metadata) {
        return [];
    }
    return metadata.operations.filter((op) => isBulkOperation(op, metadata) && isOperationDisplayed(op, mode));
};

export const getNonBulkOperations = (
    metadata: IListScreenMetadata | undefined,
    mode: ScreenMode | undefined,
): IListScreenOperation[] => {
    if (!metadata) {
        return [];
    }
    return metadata.operations.filter((op) => !isBulkOperation(op, metadata) && isOperationDisplayed(op, mode));
};

export const getDocumentDownloadUrl = (screenId: number, documentId: number, downloadUrl: string): string => {
    const apiPath = Props['apiContextRoot'] + Props['apiContextPath'];
    return (
        apiPath +
        downloadUrl
            .replace('{screenId}', screenId.toString())
            .replace('{documentId}', documentId.toString())
            .replace('{downloadFile}', 'downloadFile')
    );
};

// private functions
const isBulkOperation = (op: IListScreenOperation, metadata: IListScreenMetadata): boolean => {
    return (
        !op.targetScreenName ||
        op.targetScreenName === metadata.screenName ||
        (op.targetScreenType === TargetScreenType.Item && op.targetScreenMode?.toLowerCase() === 'edit')
    );
};

const isOperationDisplayed = (op: IListScreenOperation, mode: ScreenMode | undefined): boolean => {
    return !op.isDisplayOnEdit || (op.isDisplayOnEdit && (!mode || mode === 'edit'));
};

export const checkListHasRowOperations = (list: Array<IListScreenRow> | undefined): boolean => {
    if (!list) {
        return false;
    }
    return list.some((row: IListScreenRow) => row._metadata.rowOperations.length > 0);
};

export const getListItemActionClassName = (listScreenState: IListScreenState): string => {
    const listHasOperations = checkListHasRowOperations(listScreenState.listData?.list);
    const actionItemClassName = listHasOperations
        ? listScreenState.quickFileVisibleForRows
            ? css.secondaryActionOC
            : css.secondaryAction
        : listScreenState.quickFileVisibleForRows
        ? css.secondaryAction
        : css.noAction;
    return actionItemClassName;
};

export const getBreadCrumbsForRow = (listScreenState: IListScreenState, row: IListScreenRow): string[] | undefined => {
    if (listScreenState.breadCrumbs.length < 1) {
        return undefined;
    }
    const breadCrumbs = listScreenState.breadCrumbs.map((bc) => bc.folderName);
    if (isFolderRow(listScreenState.metadata!, row)) {
        const nameColumn = listScreenState.metadata!.columns.find((c) => c.name === 'name')!;
        breadCrumbs.push(getCellDisplayValue(row, nameColumn));
    }
    return breadCrumbs;
};

export const scrollToTopOfEmbeddedList = (): void => {
    const itemscreenView = document.getElementById('itemscreenView');
    const tabs = document.getElementById('tabs');
    if (itemscreenView !== null && tabs !== null && itemscreenView.scrollTop >= tabs.offsetTop) {
        itemscreenView.scrollTop = tabs.offsetTop;
    }
};
