import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkUnpin = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkFolderOpen" viewBox="-2 -2 20 20" {...props}>
        <g>
            <g>
                <path
                    fill="currentColor"
                    d="M7,2.1C7,2.1,7,2.1,7,2.1c0-0.6,0.5-1.1,1.1-1h2.2c0,0,0,0,0,0c0.6,0,1.1,0.5,1.1,1v5.6
c0.8,0.3,1.5,0.9,1.9,1.7h-1.2l1,1h1.5c-0.1-1.4-0.9-2.7-2.2-3.3v-5c0,0,0,0,0,0c0-1.2-1-2.1-2.2-2.1H8.1c0,0,0,0,0,0
C6.9,0,5.9,0.9,5.9,2.1v1L7,4.2V2.1z"
                />
                <path
                    fill="currentColor"
                    d="M15.6,15L1.4,0.7l-1,1l5.4,5.4c-1.2,0.7-2,1.9-2.1,3.3h5v5.2h1.1v-4.6l4.8,4.8L15.6,15z M5,9.4
c0.3-0.6,0.9-1.1,1.5-1.5L8,9.4H5z"
                />
            </g>
            <rect fill="none" width="16" height="16" />
        </g>
    </SvgIcon>
);
