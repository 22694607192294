export enum LegacyActionType {
    Client = 'legacyClientAction',
    Form = 'legacyFormAction',
    Url = 'legacyUrlAction',
}

export interface ILegacyActionDetails {
    actionType?: LegacyActionType;
    formActionDetails: ILegacyFormActionDetails;
    clientActionScript: string;
}

export interface ILegacyFormActionDetails {
    formName: string;
    formActions: ILegacyFormAction[];
}

export interface ILegacyFormAction {
    paramName: string;
    paramValue: string;
}
