import { getLogger } from '@wk/elm-uui-common';
import { flatten, mergeWith, omit } from 'lodash';
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useQuery } from '../../../hooks';
import { IMenuItem } from '../../../reducers/types';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
    ComponentType,
    Currency,
    GadgetCatalog,
    isLayoutComponent as isPageLayoutComponent,
    LayoutComponent,
    LayoutComponentColumn,
    PageComponent,
    PageData,
    PageHeaderLinks,
    PageScript,
    ReportFiltersData,
    selectPageState,
    selectReportKeys,
    setPageState,
    setReportKeys,
    setReportFiltersData,
    selectReportFiltersData,
    ViewScreenPageStyle,
    ContentHolder,
    GadgetItem,
    setDashboardKeys,
    PagePrint,
} from '../../../store/slices';
import { apiFetch } from '../../../utils/fetchUtils';
import { LoaderScope, useLoader } from '../../core/blockUi/useLoader.hook';
import { SelectedGadget } from '../../home/gadgets/customize/customizeGadgetsDialog';

const logger = () => getLogger('useViewScreen');
interface ViewScreenMetadata {
    pageTitle: string;
    pageMenu: IMenuItem[];
    helpUrl: string;
    currency: Currency;
    pageScript: PageScript;
    isBookmarkAddEnabled: boolean;
    pagePrint: PagePrint;
}

type ViewScreenComponent =
    | ViewScreenLayoutComponent
    | {
          type: Exclude<ComponentType, ComponentType.LayoutComponent>;
      };

interface ViewScreenLayoutComponent {
    type: ComponentType.LayoutComponent;
    columns: ViewScreenLayoutComponentColumn[];
    catalogName: string;
    contentHolder: ContentHolder;
}

interface ViewScreenLayoutComponentColumn {
    width: number;
    components: ViewScreenComponent[];
}

interface ViewScreenData {
    components: ViewScreenComponent[];
    pageHeaderLinks: PageHeaderLinks[];
    pageStyle?: ViewScreenPageStyle;
}

interface MapComponentParams {
    component: ViewScreenComponent;
    columnId?: string;
    order?: number;
}

const isLayoutComponent = (component: ViewScreenComponent): component is ViewScreenLayoutComponent =>
    component.type === ComponentType.LayoutComponent;

// eslint-disable-next-line max-lines-per-function
export const useViewScreen = () => {
    const { apiContextRoot, apiContextPath } = window.Props;
    const apiBaseUrl = `${String(apiContextRoot)}${String(apiContextPath)}/`;
    const layoutBaseUrl = `${apiBaseUrl}view/component/LayoutComponent/`;
    const LayoutPowerBIComponent = `${apiBaseUrl}view/component/LayoutPowerBiComponent/`;
    const DashboardPowerBIComponent = `${apiBaseUrl}view/component/DashboardPowerBiComponent/`;
    const getGadgetCatalogUrl = `${layoutBaseUrl}getGadgetCatalog`;
    const updatePreferencesUrl = `${layoutBaseUrl}updatePreferences`;
    const updateGadgetCatalogAccessUrl = `${layoutBaseUrl}updateGadgetCatalogAccess`;
    const getDefaultGadgetSelectionsUrl = `${layoutBaseUrl}getDefaultGadgetSelections`;
    const getPowerBiReportKeysUrl = `${LayoutPowerBIComponent}getPowerBiReportKeys`;
    const getPowerBiReportFiltersUrl = `${LayoutPowerBIComponent}getPowerBiFilters`;
    const getPowerBiDashboardKeysUrl = `${DashboardPowerBIComponent}getPowerBiDashboardKeys`;
    const { url = '' } = useQuery<{ url: string }>();
    const dispatch = useAppDispatch();
    const reportKeys = useAppSelector(selectReportKeys);
    const pageState = useAppSelector(selectPageState());
    const reportFiltersData = useAppSelector(selectReportFiltersData);
    const defaultGadgetSelections = useAppSelector(selectPageState('data'))?.defaultGadgets;
    const { isLoading, incrementLoadingCounter, resetLoadingCounter } = useLoader({
        scope: LoaderScope.ViewUI,
    });
    const layout = pageState.data?.components?.find(isPageLayoutComponent);

    const updateLayoutPreferences = async (
        layout: LayoutComponent,
        selectedGadgets: SelectedGadget[],
        withReload = false,
    ): Promise<void> => {
        const data = {
            catalogName: layout.catalogName,
            newPreferences: {
                columns: layout.columns.map(({ id, width }) => ({
                    width,
                    gadgets: selectedGadgets
                        .filter(({ layoutColumnId }) => layoutColumnId === id)
                        .map(({ code, height, isMinimized }) => ({ id: code, height, isMinimized })),
                })),
            },
        };
        await apiFetch<void>(updatePreferencesUrl, data, { skipTracking: !withReload });
        if (withReload) {
            await loadPageState(); // should minimize to a single request only?
        }
    };

    const updateGadgetCatalogAccess = async (catalogName: string): Promise<void> => {
        await apiFetch<void>(`${updateGadgetCatalogAccessUrl}?catalogName=${catalogName}`);
    };

    const getDefaultGadgetSelections = async (catalogName?: string): Promise<GadgetItem[] | undefined> => {
        if (!catalogName) {
            return;
        }

        return await apiFetch<GadgetItem[]>(`${getDefaultGadgetSelectionsUrl}?catalogName=${catalogName}`);
    };

    const getGadgetCatalog = async (catalogName: string | undefined): Promise<GadgetCatalog | undefined> => {
        if (!catalogName) {
            return;
        }

        const gadgetCatalogUrl = `${getGadgetCatalogUrl}?catalogName=${catalogName}`;
        return await apiFetch<GadgetCatalog>(gadgetCatalogUrl);
    };

    const getPowerBiReportKeys = async (): Promise<string[] | undefined> => {
        return await apiFetch<string[]>(getPowerBiReportKeysUrl);
    };

    const getPowerBiReportFiltersData = async (): Promise<ReportFiltersData | undefined> => {
        try {
            return await apiFetch<ReportFiltersData>(getPowerBiReportFiltersUrl);
        } catch (e) {
            logger().error(`Failed to fetch PowerBI Report Filters Data: ${(e as Error).message}`);
            return;
        }
    };

    const getPowerBiDashboardKeys = async (): Promise<string[] | undefined> => {
        return await apiFetch<string[]>(getPowerBiDashboardKeysUrl);
    };

    const loadPageState = async (): Promise<void> => {
        incrementLoadingCounter();
        const viewName = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
        const viewParams = window.location.search;
        const viewUrl = `${apiBaseUrl}view/${viewName}/${viewParams}`;
        const viewMetadataUrl = `${apiBaseUrl}view/${viewName}/metadata/${viewParams}`;

        const viewScreenData = await apiFetch<ViewScreenData>(viewUrl);
        const metadata = await apiFetch<ViewScreenMetadata>(viewMetadataUrl);
        const layoutComponent = viewScreenData.components?.find(isLayoutComponent);
        const gadgetCatalog = await getGadgetCatalog(layoutComponent?.catalogName);
        const defaultGadgets =
            defaultGadgetSelections ?? (await getDefaultGadgetSelections(layoutComponent?.catalogName));

        const { pageHeaderLinks } = viewScreenData;

        const mapComponent = (props: MapComponentParams): PageComponent => {
            const { component, columnId, order } = props;

            if (!isLayoutComponent(component)) {
                return {
                    id: uuid(),
                    ...component,
                    layoutColumnId: columnId,
                    order,
                } as PageComponent;
            }

            const { components, columns } = component.columns.reduce(
                (agg, column) => {
                    const columnId = uuid();
                    const pageComponent = column.components.map((component, index) =>
                        mapComponent({ component, columnId, order: index }),
                    );

                    agg.components = mergeWith(
                        agg.components,
                        pageComponent,
                        (a: PageComponent[][] = [], b: PageComponent[] = []) => [...a, b],
                    );
                    agg.columns.push({
                        id: columnId,
                        ...column,
                    } as LayoutComponentColumn);

                    return agg;
                },
                { components: [] as PageComponent[][], columns: [] as LayoutComponentColumn[] },
            );
            return {
                id: uuid(),
                ...omit(component, 'columns'),
                components: flatten(components),
                columns,
                layoutColumnId: columnId,
                order,
            } as LayoutComponent;
        };

        const { pageTitle, pageMenu, helpUrl, currency, pageScript, isBookmarkAddEnabled, pagePrint } = metadata;
        const pageData: PageData = {
            components: viewScreenData.components.map((component, index) => mapComponent({ component, order: index })),
            gadgetCatalog,
            pageHeaderLinks,
            pageStyle: viewScreenData.pageStyle,
            defaultGadgets,
        };

        dispatch(
            setPageState({
                title: pageTitle,
                addBookmarkEnabled: isBookmarkAddEnabled,
                pageMenu,
                helpUrl,
                currency,
                pageScript,
                data: pageData,
                pagePrint,
            }),
        );

        resetLoadingCounter();
    };

    const updatePageData = (data: PageData) => {
        dispatch(
            setPageState({
                ...omit(pageState, 'currency.enabled'),
                data,
            }),
        );
    };

    const loadPowerBiState = async (): Promise<void> => {
        if (!reportKeys) {
            const reportKeysData = await getPowerBiReportKeys();
            dispatch(setReportKeys({ reportKeys: reportKeysData }));

            const dashboardKeysData = await getPowerBiDashboardKeys();
            dispatch(setDashboardKeys({ dashboardKeys: dashboardKeysData }));
        }
        if (!reportFiltersData) {
            const filtersData = await getPowerBiReportFiltersData();
            dispatch(setReportFiltersData({ reportFiltersData: filtersData }));
        }
    };

    useEffect(() => {
        void loadPageState();
        void loadPowerBiState();
    }, [url]);

    return {
        isLoading,
        pageState,
        layout,
        updateLayoutPreferences,
        updatePageData,
        updateGadgetCatalogAccess,
    };
};
