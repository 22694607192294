import { ToastType } from './../enum/enum';
import { Locale } from './../locale';
import { ICHUploadType, ICHUploadResponse } from './../interfaces/operations';
import { injectable, inject } from 'inversify';
import 'reflect-metadata';
import { factory } from '../configLog4J';
import { v4 as uuid } from 'uuid';
import { deleteFromPersistentStorage, throwUnExpectedError } from '../utils/main.utils';
import { IOfficeService } from './office.service';
import { DocumentUploadService } from './documentUpload.service';
import { DocumentSummaryCheckInService } from './documentSummaryCheckIn.service';
import { EmailService } from './email.service';
import { DocumentsService } from './documents.service';
import { SplitDocumentsAndEmails } from '../interfaces/interface';

const log = factory.getLogger('DragAndDropService');

@injectable()
export class DragAndDropService {
    private _officeService: IOfficeService;
    private _documentUploadService: DocumentUploadService;
    private _documentSummaryService: DocumentSummaryCheckInService;
    private _emailService: EmailService;
    private _documentsService: DocumentsService;
    private paths: string[];
    constructor(
        @inject('OfficeService') officeService: IOfficeService,
        @inject('DocumentSummaryCheckInService') documentSummaryService: DocumentSummaryCheckInService,
        @inject('DocumentUploadService') documentUploadService: DocumentUploadService,
        @inject('EmailService') emailService: EmailService,
        @inject('DocumentsService') documentService: DocumentsService,
    ) {
        this._officeService = officeService;
        this._documentUploadService = documentUploadService;
        this._documentSummaryService = documentSummaryService;
        this._emailService = emailService;
        this._documentsService = documentService;
        this.paths = [];
    }
    private async isExcelBusy(contextObj: ICHUploadType): Promise<boolean> {
        const isExcelBusy = await this._officeService.isBusy();
        if (isExcelBusy) {
            const message = Locale.documents.drag_and_drop;
            await this._documentsService.handleIsApplicationBusy(message);
            await deleteFromPersistentStorage(contextObj.filePaths);
            return true;
        }
        return false;
    }

    private async processEmailUpload(
        contextObj: ICHUploadType,
        splitDocumentsAndEmails: SplitDocumentsAndEmails,
    ): Promise<ICHUploadResponse> {
        log.debug('One or More email item');
        // A combination of emails and documents cannot be supported
        // Todo: Remove this error toast once supported
        if (splitDocumentsAndEmails.documents.length > 0) {
            this._documentsService.showToast(ToastType.ERROR, Locale.documents.upload_document_and_email_combination);
        }
        await deleteFromPersistentStorage(splitDocumentsAndEmails.documents);
        if (contextObj.documentId) {
            await deleteFromPersistentStorage(splitDocumentsAndEmails.emails);
            return {
                responseText: this._documentsService.showToast(ToastType.ERROR, Locale.documents.dnd_not_supported),
            };
        }
        this._emailService.performDragAndDrop(contextObj);
        // Don't wait till the return value from add email
        // Resolving promise to close the spinner ASAP
        return { responseText: Locale.email.responseText.add_email };
    }

    private async persistAllToStorage(contextObj: ICHUploadType): Promise<void> {
        log.debug(`Persist all dropped items, no of items: ${contextObj.filePaths?.length}`);
        if (contextObj.filePaths && contextObj.filePaths.length > 0) {
            for (let index = 0; index < contextObj.filePaths.length; index++) {
                const parts = ['dnd', uuid().toString()];
                const documentPath = contextObj.filePaths && contextObj.filePaths[index];
                contextObj.filePaths[index] = await this._officeService.persistToStorage(parts, documentPath);
            }
        }
    }

    private async handleMultipleDocumentUpload(contextObj: ICHUploadType): Promise<ICHUploadResponse> {
        log.debug(`handle multiple email upload`);
        const message = this._documentsService.showToast(ToastType.ERROR, Locale.documents.multiple_dnd_document);
        await deleteFromPersistentStorage(contextObj.filePaths);
        return { responseText: message };
    }

    private async processDocumentUpload(contextObj: ICHUploadType): Promise<ICHUploadResponse> {
        try {
            if (contextObj.filePaths && contextObj.filePaths.length > 0) {
                if (contextObj.documentId) {
                    log.info('OC | DragAndDrop | Update Existing Document');
                    return await this._documentSummaryService.processDocumentCheckIn(contextObj);
                } else if (!contextObj.documentId) {
                    log.info('OC | DragAndDrop | Upload Document');
                    return await this._documentUploadService.processDocumentUpload(contextObj, false);
                }
            }
            log.warn(Locale.documents.dnd_no_document);
            return { responseText: Locale.documents.dnd_no_document };
        } catch (err) {
            log.error('processDocumentUpload' + JSON.stringify(err));
            await deleteFromPersistentStorage(contextObj.filePaths);
            throwUnExpectedError(err);
            return { responseText: JSON.stringify(err) };
        }
    }

    public async processDroppedItems(contextObj: ICHUploadType): Promise<ICHUploadResponse> {
        log.info('OC | Drag and Drop a document & email Item');
        await this.persistAllToStorage(contextObj);
        this.paths = Object.assign([], contextObj.filePaths);
        const splitDocumentsAndEmails = this._documentsService.splitDocumentsAndEmails(this.paths);
        contextObj.isDragAndDrop = true;
        contextObj.noOfItems = this.paths.length;

        if (await this.isExcelBusy(contextObj)) {
            return { responseText: Locale.responseText.failures.application_busy };
        }
        if (this.paths.length == 0) {
            return {
                responseText: this._documentsService.showToast(ToastType.ERROR, Locale.documents.dnd_no_document),
            };
        }
        if (splitDocumentsAndEmails.emails.length > 0) {
            return this.processEmailUpload(contextObj, splitDocumentsAndEmails);
        }
        // Support only one document for DnD
        if (splitDocumentsAndEmails.documents.length > 1) {
            return await this.handleMultipleDocumentUpload(contextObj);
        }
        return await this.processDocumentUpload(contextObj);
    }
}
