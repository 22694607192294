import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useRef, VFC } from 'react';
import { AppTheme } from '../../app';

const useStyles = makeStyles<AppTheme>((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.grey[900],
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.typography.caption.fontWeight,
    },

    descriptionTooltip: {
        maxWidth: 224,
        padding: theme.spacing(1, 2),
    },

    tooltipArrow: {
        color: theme.palette.grey[900],
    },

    tooltipPlacementLeftRight: {
        margin: theme.spacing(0, 0.5),
    },
    tooltipPlacementBottom: {
        marginTop: theme.spacing(2),
    },
    tooltipPlacementTop: {
        marginBottom: theme.spacing(0.5),
    },
}));

// eslint-disable-next-line @typescript-eslint/ban-types
type Mode = 'indicator' | 'description';

type TooltipProps = MuiTooltipProps & {
    dynamic?: boolean;
    mode?: Mode;
};

export const Tooltip: VFC<TooltipProps> = (props) => {
    const classes = useStyles();
    const { classes: externalClasses, open, mode, ...tooltipProps } = props;
    const tooltipRef = useRef<HTMLElement>(null);

    return (
        <MuiTooltip
            {...tooltipProps}
            open={open}
            ref={tooltipRef}
            arrow
            classes={{
                tooltip: classNames(
                    classes.tooltip,
                    { [classes.descriptionTooltip]: mode === 'description' },
                    externalClasses?.tooltip,
                ),
                arrow: classNames(classes.tooltipArrow, externalClasses?.tooltipArrow),
                tooltipPlacementRight: classNames(
                    classes.tooltipPlacementLeftRight,
                    externalClasses?.tooltipPlacementRight,
                ),
                tooltipPlacementLeft: classNames(
                    classes.tooltipPlacementLeftRight,
                    externalClasses?.tooltipPlacementLeft,
                ),
                tooltipPlacementBottom: classNames(
                    classes.tooltipPlacementBottom,
                    externalClasses?.tooltipPlacementBottom,
                ),
                tooltipPlacementTop: classNames(classes.tooltipPlacementTop, externalClasses?.tooltipPlacementTop),
            }}
        />
    );
};
Tooltip.defaultProps = {
    mode: 'indicator',
};
