import { IDocTransformer } from '../../interfaces/transformer/type';
import { injectable } from 'inversify';
import { factory } from '../../ConfigLog4j';
import { IMatterDoc, IRenameFolder } from '../../types';
import { T360AdapterRenameFolderActionableEntity } from '../actionableEntity/renameFolderActionableEntity';
import { IDocOperationInvocation } from '../../interfaces/urlPattern/types';
import { IRenameFolder_T360 } from '../types';

const log = factory.getLogger('T360AdapterRenameFolderEntityTransformer');

@injectable()
export class T360AdapterRenameFolderEntityTransformer implements IDocTransformer {
    transformIntoActionableEntity(
        nodeObj: IRenameFolder,
        _matterDoc: IMatterDoc,
        _urlPattern: IDocOperationInvocation,
    ): T360AdapterRenameFolderActionableEntity {
        log.debug('Calling transformIntoActionableEntity');
        const obj = {} as T360AdapterRenameFolderActionableEntity;

        const nodeObj1 = nodeObj as IRenameFolder_T360;
        if (nodeObj1?.name) {
            obj.name = nodeObj1.name;
        }

        if (nodeObj1?.editAccessType) {
            obj.editAccessType = nodeObj1.editAccessType;
        }

        if (nodeObj1?.viewAccessType) {
            obj.viewAccessType = nodeObj1.viewAccessType;
        }

        if (nodeObj?.extendedProps?.admittedMembers) {
            obj.admittedMembers = nodeObj1.extendedProps.admittedMembers;
        }
        return obj;
    }
}
