import { MoreVert } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import React, { forwardRef } from 'react';
import { ItemIconProps } from '../item';

const useStyles = makeStyles({
    icon: {
        width: 4,
        height: 16,
    },
});

export const MoreVertWrapper = forwardRef<SVGSVGElement, ItemIconProps>((props, ref) => {
    const classes = useStyles();

    return <MoreVert ref={ref} {...props} className={classes.icon} viewBox="10 3 4 17" />;
});
MoreVertWrapper.displayName = 'MoreVertWrapper';
