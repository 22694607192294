import React from 'react';
import useContextLayerSubscriptions from '../../../hooks/useContextLayerSubscriptions';
import { useAppSelector } from '../../../store';
import { Notifier } from '../../common';
import { AlertDialog } from '../../common/alertDialog';
import { OverlayDialog } from '../../common/overlayDialog';
import ItemScreen from '../../itemScreen/itemScreen';

export const UUIDialogs: React.FC = () => {
    useContextLayerSubscriptions();
    return (
        <>
            <ItemScreenDialogs />
            <AlertDialogs />
            <Notifier />
            <FullScreenOverlayDialog />
        </>
    );
};

export const FullScreenOverlayDialog: React.FC = () => {
    const overlayDialog = useAppSelector((state) => state.ui.overlayDialog.entity);
    if (!overlayDialog) {
        return null;
    }
    return <OverlayDialog {...overlayDialog} />;
};

export const AlertDialogs: React.FC = () => {
    const alertDialogs = useAppSelector((state) => state.ui.alertDialogs);
    return (
        <>
            {alertDialogs.map((alertDialogProps) => (
                <AlertDialog key={alertDialogProps.id} {...alertDialogProps} />
            ))}
        </>
    );
};

// you can import this into tests to test item screen popup
export const ItemScreenDialogs: React.FC = () => {
    const itemScreenDialogs = useAppSelector((state) => state.ui.itemScreenDialogs);
    return (
        <>
            {itemScreenDialogs.map((itemScreenDialogProps, i) => (
                <ItemScreen key={i} {...itemScreenDialogProps} renderingStyle="popup" />
            ))}
        </>
    );
};
