import { DialogActions } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../store';
import { getAppResources, getApplicationUrls } from '../../../store/slices';
import { apiFetch } from '../../../utils/fetchUtils';
import FullScreenDialog, { FullScreenDialogButton, FullScreenDialogContent } from '../../common/fullScreenDialog';
import TransferBasketTreeView from '../../transferBasket/transferBasketTreeView';
import { ITransferBasketItem } from '../../transferBasket/types';
import css from './attributeSelectionDialog.module.scss';
import { convertRawTreeDataToTransferBasketItems } from './context/listScreenEditDialogReducer';
import { IRawTreeData } from './types';

export interface IFieldSelectionDialog {
    open: boolean;
    onClose: () => void;
    screenMetadata: any;
    onSelected: (
        selectedItem: ITransferBasketItem,
        names: string[],
        attributeIds: number[],
        attributePath: string,
    ) => void;
}

const AttributeSelectionDialog: React.FC<IFieldSelectionDialog> = ({ open, onClose, screenMetadata, onSelected }) => {
    const [transferBasketItems, setTransferBasketItems] = useState<ITransferBasketItem[]>([]);
    const [selectedItem, setSelectedItem] = useState<ITransferBasketItem>();
    const applicationUrls = useAppSelector(getApplicationUrls);
    const appResources = useAppSelector(getAppResources);
    const apiPath = Props['apiContextRoot'] + Props['apiContextPath'];
    const childAttributesURL = apiPath + applicationUrls.listScreenChildAttributesPath;

    useEffect(() => {
        apiFetch<IRawTreeData[]>(
            childAttributesURL
                .replace('{screenId}', screenMetadata.screenId.toString())
                .replace('{context}', screenMetadata.context || '')
                .replace('{entityMetadataId}', screenMetadata.entityId)
                .replace('{type}', 'filters')
                .replace('{isRoot}', 'true')
                .replace('{chainIDs}', ''),
        ).then((rawTreeData) => {
            const items = convertRawTreeDataToTransferBasketItems(rawTreeData);
            setTransferBasketItems(items);
        });
    }, [childAttributesURL, screenMetadata.entityId, screenMetadata.screenId, screenMetadata.context]);

    const onToggleTreeNode = (treeItemClicked: ITransferBasketItem, expanded: boolean) => {
        // the api requires a comma delimited list of all entity ids in the attribute path.
        const calculateChainIds = () => {
            let currentTreeItem = treeItemClicked;
            const chainIds = [treeItemClicked.metadata!.relatedEntityId];
            while (currentTreeItem.parentId) {
                currentTreeItem = transferBasketItems.find((td) => td.id === currentTreeItem.parentId)!;
                chainIds.unshift(currentTreeItem.metadata!.relatedEntityId);
            }
            return chainIds.join(',');
        };

        if (expanded && transferBasketItems.filter((td) => td.parentId === treeItemClicked.id).length == 0) {
            apiFetch<IRawTreeData[]>(
                childAttributesURL
                    .replace('{screenId}', screenMetadata.screenId.toString())
                    .replace('{context}', screenMetadata.context || '')
                    .replace('{entityMetadataId}', treeItemClicked.metadata!.relatedEntityId!.toString())
                    .replace('{type}', 'filters')
                    .replace('{isRoot}', 'false')
                    .replace('{chainIDs}', calculateChainIds()),
            ).then((rawChildTreeData) => {
                const childTransferBasketItems = convertRawTreeDataToTransferBasketItems(
                    rawChildTreeData,
                    treeItemClicked.id,
                );
                setTransferBasketItems(
                    transferBasketItems.concat(
                        childTransferBasketItems.map((ctd) => {
                            return {
                                ...ctd,
                                parentId: treeItemClicked.id,
                            } as ITransferBasketItem;
                        }),
                    ),
                );
            });
        }
    };

    const calculateNames = (selectedItem: ITransferBasketItem) => {
        let currentTreeItem = selectedItem;
        const result = [selectedItem.availableDisplayValue];
        while (currentTreeItem.parentId) {
            currentTreeItem = transferBasketItems.find((td) => td.id === currentTreeItem.parentId)!;
            result.unshift(currentTreeItem.availableDisplayValue);
        }
        return result;
    };

    const calculatePath = (selectedItem: ITransferBasketItem) => {
        let currentTreeItem = selectedItem;
        const result = [selectedItem.attributePath];
        while (currentTreeItem.parentId) {
            currentTreeItem = transferBasketItems.find((td) => td.id === currentTreeItem.parentId)!;
            result.unshift(currentTreeItem.attributePath);
        }
        return result.join('.');
    };

    const calculateAttributeIds = (selectedItem: ITransferBasketItem) => {
        return selectedItem.id.split(',').map((id) => parseInt(id));
    };

    return (
        <FullScreenDialog
            testId="listscreen-edit-select-attr-dialog"
            isOpen={open}
            onClose={onClose}
            headerText={appResources.listscreenEditSelectAttributeTxt}
            variant="green">
            <FullScreenDialogContent>
                <div style={{ paddingTop: '30px' }}>
                    <TransferBasketTreeView
                        items={transferBasketItems}
                        onToggleItem={onToggleTreeNode}
                        onSelectItem={setSelectedItem}
                        onDoubleClickItem={(doubleClickedItem) => {
                            onSelected(
                                doubleClickedItem,
                                calculateNames(doubleClickedItem),
                                calculateAttributeIds(doubleClickedItem),
                                calculatePath(doubleClickedItem),
                            );
                            onClose();
                        }}
                        fullHeight={true}
                    />
                </div>
            </FullScreenDialogContent>
            <DialogActions className={css.dialogActions}>
                <FullScreenDialogButton
                    testId="select-button"
                    variant="contained"
                    onClick={() => {
                        if (selectedItem) {
                            onSelected(
                                selectedItem,
                                calculateNames(selectedItem),
                                calculateAttributeIds(selectedItem),
                                calculatePath(selectedItem),
                            );
                        }
                        onClose();
                    }}>
                    {appResources.listscreenEditSelectAttribute}
                </FullScreenDialogButton>
                <FullScreenDialogButton testId="cancel-button" variant="outlined" onClick={onClose}>
                    {appResources.dialogCancel}
                </FullScreenDialogButton>
            </DialogActions>
        </FullScreenDialog>
    );
};

export default AttributeSelectionDialog;
