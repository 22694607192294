import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const WkOutsideCounsel = (props: SvgIconProps) => (
    <SvgIcon data-testid="wkAddIcon" viewBox="0 0 25 25" {...props}>
        <g id="Group_10963" data-name="Group 10963" transform="translate(-3217 -755)">
            <rect
                id="Rectangle_3449"
                data-name="Rectangle 3449"
                width="25"
                height="25"
                transform="translate(3217 755)"
                fill="none"
            />
            <g id="Outside_counsel_icon" data-name="Outside counsel icon" transform="translate(3217.5 755)">
                <path
                    id="Subtraction_2"
                    data-name="Subtraction 2"
                    d="M14.974,21.32H5.427V20h8.082c-.471-2.069-3.335-5.261-5.284-5.889a4.611,4.611,0,0,1-3.621,0,7.978,7.978,0,0,0-2.863,2.017H0A10.14,10.14,0,0,1,3.271,13.24,4.562,4.562,0,0,1,1.806,9.876a4.609,4.609,0,0,1,9.218,0A4.56,4.56,0,0,1,9.558,13.24a10.493,10.493,0,0,1,3.616,3.4,8.886,8.886,0,0,1,1.8,4.677ZM6.415,6.584A3.292,3.292,0,1,0,9.706,9.876,3.3,3.3,0,0,0,6.415,6.584Zm12.51,7.9H13.149a8.531,8.531,0,0,0-1.3-1.317h5.606a5.954,5.954,0,0,0-3.967-4.321,4.6,4.6,0,0,1-1.2.325,5.855,5.855,0,0,0-.311-1.3A3.283,3.283,0,1,0,8.42,4.307,5.887,5.887,0,0,0,7.119,4a4.607,4.607,0,0,1,9.172.613,4.562,4.562,0,0,1-1.467,3.365,7.281,7.281,0,0,1,2.959,2.618,7.184,7.184,0,0,1,1.141,3.893Z"
                    transform="translate(5.387)"
                    fill="currentColor"
                />
                <path
                    id="Path_5018"
                    data-name="Path 5018"
                    d="M.875,2.153H1.6V7.665H.875Zm1.6,0H7.346V7.665H2.473Zm5.748,0h.722V7.665H8.221ZM3.75.876H6.068v.4H3.746ZM2.875,0V1.278H0V8.542H9.819V1.278H6.945V0Z"
                    transform="translate(0.5 16.455)"
                    fill="currentColor"
                />
            </g>
        </g>
    </SvgIcon>
);
