import { MenuItem, MenuProps, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IQueueServiceInterface } from '@wk/elm-uui-context-handler';
import classNames from 'classnames';
import React, { memo } from 'react';
import { useNavigate } from '../../../../hooks';
import { IMenuItem } from '../../../../reducers/types';
import { useAppSelector } from '../../../../store';
import { getAppResources } from '../../../../store/slices';
import { clearLocationDataForOC } from '../../../../utils/ocLocationService';
import { AppTheme } from '../../../app';
import { LOGGING_OUT } from '../../../app/appContainer/useMessageBus.hook';
import { getAuthenticationProvider } from '../../../authentication/AuthenticationProviderService';
import { clGetQueue } from '../../../contextLayerService/contextLayerService';
import { Menu } from '../menu';

const useStyles = makeStyles<AppTheme>((theme) => {
    return {
        menuItemRoot: {
            color: theme.palette.primary.dark,
            whiteSpace: 'pre-wrap',
        },

        profileNameItem: {
            color: theme.palette.grey.A700,
            pointerEvents: 'none',
            fontWeight: 500,

            // TODO: remove the below style and create a new class for the disabled menu item when MUI gets updated to V5
            '&.Mui-disabled': {
                opacity: 1,
            },
        },

        divider: {
            ...theme.styles.divider,
        },
    };
});

type UserProfileProps = MenuProps;

export const UserProfileMenu = memo<UserProfileProps>(function UserProfileMenu(props) {
    const { ...menuProps } = props;
    const classes = useStyles();
    const appResources = useAppSelector(getAppResources);
    const userProfileMenu = useAppSelector((state) => state.app.userMenu);
    const { navigate } = useNavigate();
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

    const handleOnLogOut = (): void => {
        // TODO: Change to async/await if possible.
        clGetQueue().then((queueManager: IQueueServiceInterface) => {
            queueManager?.setQueuePaused();
            clearLocationDataForOC();
            sessionStorage.setItem(LOGGING_OUT, 'true');
            const authProvider = getAuthenticationProvider();
            authProvider.signOut(appResources.logoutUrl, true, false);
        });
    };

    const handleOnClick = (item: IMenuItem) => (): void => {
        navigate({ menuItem: item, options: { clearBreadcrumbs: true } });
    };

    return (
        <Menu {...menuProps}>
            {isMobile && (
                <MenuItem data-testid="profileName" classes={{ root: classes.profileNameItem }} disabled>
                    {`${appResources.v3FirstName} ${appResources.v3LastName}`}
                </MenuItem>
            )}
            {userProfileMenu?.map((item) => (
                <MenuItem
                    key={item.id}
                    onClick={handleOnClick(item)}
                    classes={{ root: classNames(classes.menuItemRoot, { [classes.divider]: item.isDivider }) }}>
                    {item.displayValue}
                </MenuItem>
            ))}
            <MenuItem data-testid="logOutButton" onClick={handleOnLogOut} classes={{ root: classes.menuItemRoot }}>
                {appResources.applicationLinkLogout}
            </MenuItem>
        </Menu>
    );
});
