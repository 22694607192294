import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { AppProps } from './app';
import { ScreenType } from './components/core';
import { getHistory } from './history.service';
import { InitializeAppCallback, CustomComponents } from './initialize';
import { AppStore } from './reducers/types';
import store from './store';
import { TARGET_URL_QUERY_PARAMETER, TEMP_LOGIN_SESSION_ID_QUERY_PARAMETER } from './target/t360/infrastructure/oidc';
import {
    getActivePassportContext,
    getPassportContextDefaultLandingPageUrl,
    getResolvedDeeplink,
} from './utils/passportContextHelper';
import { pendoAgent } from './utils/pendoAgent.service';
import { getBasePath } from './utils/uriResolver';
import { updateMegaMenu } from './components/common/screenHelpers';

export async function handleInitializeResponse(response: Response): Promise<AppStore | undefined> {
    if (response.status != 401) {
        return (await response.json()) as AppStore;
    }

    return;
}

export async function handleJsonResponse(json?: AppStore): Promise<AppStore | undefined> {
    if (json == null) {
        window.location.href = getBasePath();
        return;
    } else {
        return await setupJsonAndRedirectIfNeeded(json);
    }
}

async function setupJsonAndRedirectIfNeeded(json: AppStore): Promise<AppStore> {
    if (sessionStorage.activeContext) {
        json.appsMenu.activeContext = parseInt(sessionStorage.activeContext);
    }

    // Default any missing data from window.Props
    if (!json.appResources.apiContextRoot) {
        json.appResources.apiContextRoot = window.Props['apiContextRoot'];
    }
    if (!json.appResources.apiContextPath) {
        json.appResources.apiContextPath = window.Props['apiContextPath'];
    }
    if (!json.appResources.uiContextRoot) {
        json.appResources.uiContextRoot = window.Props['uiContextRoot'];
    }
    if (!json.appResources.legacyContextRoot) {
        json.appResources.legacyContextRoot = window.Props['legacyContextRoot'];
    }
    if (!json.appResources.staticContext) {
        json.appResources.staticContext = window.Props['staticContext'];
    }

    const updatedMegaMenu = await Promise.all(json?.megaMenu?.map(updateMegaMenu));
    const initialState = { ...json, megaMenu: updatedMegaMenu };

    let href = window.location.href;

    if (href.indexOf('/viewport/show.do') > 0 && sessionStorage.getItem('v3RedirectHash')) {
        // @ts-ignore
        window.location.hash = sessionStorage.getItem('v3RedirectHash');
        sessionStorage.removeItem('v3RedirectHash');
        href = window.location.href;
    }

    const baseUrlPath = `${window.Props['basePath']}/`;
    const pathname = window.location.pathname;

    if (window.location.search.indexOf(window.Props['pageNotFoundParam']) < 0) {
        if (pathname.substring(pathname.length - baseUrlPath.length) === baseUrlPath) {
            const deepLinkFromIdentity = window.Props['loginDetails']?.deepLink as string;
            const queryParams = new URLSearchParams(window.location.search);
            const deepLink = await getDeeplink(deepLinkFromIdentity, queryParams);
            if (deepLink?.length) {
                getHistory().replace(`/enduser/${ScreenType.Legacy}/show.do?url=${encodeURIComponent(deepLink)}`);
            } else {
                const landingPage = getPassportContextDefaultLandingPageUrl(
                    getActivePassportContext(initialState.appsMenu),
                    initialState.appResources.v3HomeUrl,
                );
                getHistory().replace(landingPage);
            }
        }
    }

      pendoAgent.initialize(initialState);

    return initialState;
}

async function getDeeplink(deepLinkFromIdentity?: string, queryParams?: URLSearchParams): Promise<string | undefined> {
    if (deepLinkFromIdentity?.length) {
        window.Props['loginDetails'].deepLink = null;
        return deepLinkFromIdentity;
    }

    const deepLinkFromQueryString = queryParams?.get(TARGET_URL_QUERY_PARAMETER) as string;
    if (deepLinkFromQueryString?.length) {
        return deepLinkFromQueryString;
    }
    const tempSessionIdFromQueryString = queryParams?.get(TEMP_LOGIN_SESSION_ID_QUERY_PARAMETER) as string;
    if (tempSessionIdFromQueryString?.length) {
        return await getResolvedDeeplink(tempSessionIdFromQueryString);
    }
    return;
}

export function loadApp(
    applicationToolbar: CustomComponents,
    AppComponent: FC<AppProps>,
    initializeAppCallback?: InitializeAppCallback,
): void {
    ReactDOM.render(
        <Provider store={store}>
            <Router history={getHistory()}>
                <AppComponent customComponents={applicationToolbar} initializeAppCallback={initializeAppCallback} />
            </Router>
        </Provider>,
        document.getElementById('app'),
    );
}
