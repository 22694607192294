import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useRouteMatch, Route, Switch } from 'react-router-dom';
import { useQuery, useUUIHistory } from '../../../hooks';
import { getLogger } from '../../../utils/loggingService';
import { updateLocationDataForOC } from '../../../utils/ocLocationService';
import { ScreenMode } from '../../common/types';
import { ExternalUIScreen } from '../../externalUiScreen/externalUI.component';
import ItemScreen from '../../itemScreen/itemScreen';
import { getItemScreenPropsFromUrl } from '../../itemScreen/itemScreenHelpers';
import ListScreen from '../../listScreen/listScreen';
import { ViewScreen } from '../../viewScreen';
import {  GridScreen  } from '../../gridscreen';

export enum ScreenType {
    List = 'listscreens',
    Item = 'itemscreens',
    Legacy = 'legacyscreens',
    View = 'viewscreens',
    GridScreen='gridscreen'
}

type UuiPageParams = { screenType: ScreenType };

// eslint-disable-next-line max-lines-per-function
export const UuiPage: React.FC<RouteComponentProps<UuiPageParams>> = ({ location }) => {
    const qs = useQuery<{ id: number; screenId: number; mode: ScreenMode }>({ parseNumbers: true });
    const screenId = qs.id || qs.screenId;
    const history = useUUIHistory();
    const [locationKey, setLocationKey] = useState(location.key);
    const { path } = useRouteMatch<UuiPageParams>();
    const itemScreenProps = useMemo(() => getItemScreenPropsFromUrl(location.search), [location.search]);

    // this effect listens for history changes, and if it is a replace (like switching tabs)
    // then do not update the key so that we render the same instance of the screen
    useEffect(() => {
        const unlisten = history.listen((newLocation, action) => {
            if (action !== 'REPLACE') {
                setLocationKey(newLocation.key);
            }
            updateLocationDataForOC(newLocation, action);
            getLogger('history').debug(`${action}: ${newLocation.pathname + newLocation.search}`);
        });

        return (): void => unlisten();
    }, [history]);

    return (
        <Switch>
            <Route path={`${path}/${ScreenType.View}`}>
                <ViewScreen key={locationKey} />
            </Route>
            <Route path={`${path}/${ScreenType.Legacy}`}>
                <ExternalUIScreen key={locationKey} />
            </Route>
            <Route path={`${path}/${ScreenType.List}`}>
                <ListScreen key={locationKey} screenId={screenId} mode={qs.mode} />
            </Route>
            <Route path={`${path}/${ScreenType.Item}`}>
                <ItemScreen key={locationKey} {...itemScreenProps} />
            </Route>
            <Route path={`${path}/${ScreenType.GridScreen}`}>
                <GridScreen key={locationKey}/>
            </Route>
        </Switch>
    );
};
